const MPosVsSelfOrderQueries = {
  MPosVsSelfOrder:{
    query: {
      "measures": [
        "MPosSelfOrderRate.mpos",
        "MPosSelfOrderRate.selfOrder"
      ],
      "timeDimensions": [
      ],
      "order": {
        "MPosSelfOrderRate.storeCode": "asc",
      },
      "filters": [
      ],
      "dimensions": [
        "MPosSelfOrderRate.storeCode",
      ],
      "limit": 50000,
      "renewQuery": true,
    }
  },
  OutstandingTableNetSales:{
    query: {
      "measures": [
        "CartTransactions.sumLineTotal"
      ],
      "timeDimensions": [
      ],
      "order": {
      },
      "filters": [
        {
          "member": "Carts.state",
          "operator": "equals",
          "values": [
            "isolated"
          ]
        },
        {
          "and": [
            {
              "member": "Carts.dineInTableNumber",
              "operator": "notContains",
              "values": [
                "test"
              ]
            },
            {
            "member": "Carts.dineInTableNumber",
              "operator": "set"
            }
          ]
        },
        {
          "member": "Carts.commerceChannelCode",
          "operator": "equals",
          "values": [
            "dine_in_pos_hkd",
            "dine_in_hkd"
          ]
        }
      ],
      "dimensions": [
      ],
      "limit": 50000,
      "renewQuery": true,
    }
  }
}

export default MPosVsSelfOrderQueries