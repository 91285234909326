import React, { useState } from "react";
import Header from '../../components/Header';
import TableComponent from '../../components/TableComponent';
import Dashboard from '../../components/Dashboard';
import PageHeaderComponent from '../../components/PageHeaderComponent';
import OmnitechQueries from '../../queries/OmnitechQueries'
import { useTranslation } from 'react-i18next';
import { useCubeQuery } from '@cubejs-client/react';
import { format } from 'date-fns'


const RSMCheckingQuery = (isRefresh, setRefresh) => {
  const { t } = useTranslation();
  
  let item = {
    name: t('page.fnb.rsmCheckingPage.name'),
    tableHeaders: {
      "RSMChecking.id": { name: t('page.fnb.rsmCheckingPage.tableHeader.id'), type: 'number'},
      "RSMChecking.code": { name: t('page.fnb.rsmCheckingPage.tableHeader.code'), type: 'string'},
      "RSMChecking.status": { name: t('page.fnb.rsmCheckingPage.tableHeader.status'), type: 'string'},
      "RSMChecking.rsm_category_count": { name: t('page.fnb.rsmCheckingPage.tableHeader.rsmCategoryCount'), type: 'number'},
      "RSMChecking.rsm_category": { name: t('page.fnb.rsmCheckingPage.tableHeader.rsmCategory'), type: 'string'},
    },
    grid: {xs: 12, lg: 12},
    export: { allowExport: true, filename: 'rsm_checking_export_' + format(new Date(), 'yyyyMMddHHmmss') }
  }
  let query = {
    ...OmnitechQueries.products.RSMChecking.query,
  }
  if(isRefresh)
    query = {}
  const result = useCubeQuery(query, {resetResultSetOnChange: true})
  if(isRefresh)
    setRefresh(false)
  return { item, result }
}

const RSMCheckingPage = () => {
  const { t } = useTranslation();
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  const [ refresh1, setRefresh1 ] = useState(false);

  const { item, result }  = RSMCheckingQuery(refresh1, setRefresh1)

  const refresh = () => {
    setRefresh1(true)
    setPage(0)
  }

  return ([
    <Header />,
    <PageHeaderComponent onClickFunc={refresh} refreshlabel={t('component.refresh')} headerTitle={t('component.header.menu.rsmChecking')} />,
    <Dashboard>
      <TableComponent item={item} result={result} rowsPerPage={rowsPerPage} 
        setRowsPerPage={setRowsPerPage} page={page} setPage={setPage}/>
    </Dashboard>
  ]) 
};

export default RSMCheckingPage;