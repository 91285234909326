import React, { useState } from "react";
import Header from '../../components/Header';
import NumberComponent from '../../components/NumberComponent';
import DatePickerComponent from '../../components/DatePickerComponent';
import TextFieldComponent from '../../components/TextFieldComponent';
import Dashboard from '../../components/Dashboard';
import PageHeaderComponent from '../../components/PageHeaderComponent';
import OmnitechQueries from '../../queries/OmnitechQueries'
import { useTranslation } from 'react-i18next';
import { useCubeQuery } from '@cubejs-client/react';

const ActiveMemberQueries = (startDateFilter, endDateFilter, dayAgo, isRefresh, setRefresh) => {
  let dayAgoInt = Math.abs(parseInt(dayAgo))|| 1;
  let newStartDateFilter = new Date(startDateFilter.getTime())
  newStartDateFilter.setDate(newStartDateFilter.getDate() - dayAgoInt);
  var result = {}
  let query1 = {
    ...OmnitechQueries.userLoyaltyPointBalances.ActiveMemberPoint.query,
    "timeDimensions": [
      {
        "dimension": "ActiveMemberPoint.updatedAt",
        "dateRange": [ newStartDateFilter, endDateFilter ]
      }
    ]
  }
  if(isRefresh)
    query1 = {}
  result["point"] = useCubeQuery(query1, {resetResultSetOnChange: true})

  let query2 = {
    ...OmnitechQueries.users.ActiveMemberLogin.query,
    "timeDimensions": [
      {
        "dimension": "ActiveMemberLogin.lastSeenAt",
        "dateRange": [ newStartDateFilter, endDateFilter ]
      }
    ]
  }
  if(isRefresh)
    query2 = {}
  result["login"] = useCubeQuery(query2, {resetResultSetOnChange: true})

  let query3 = {
    ...OmnitechQueries.orders.ActiveMemberOrder.query,
    "timeDimensions": [
      {
        "dimension": "ActiveMemberOrder.lastOrderCompletedAt",
        "dateRange": [ newStartDateFilter, endDateFilter ]
      }
    ]
  }
  if(isRefresh)
    query3 = {}
  result["order"] = useCubeQuery(query3, {resetResultSetOnChange: true})

  if(isRefresh)
    setRefresh(false)
  return result
}

const ActiveMemberPage = () => {
  const { t } = useTranslation();
  let filterStartDate = new Date()
  filterStartDate.setHours(0,0,0,0);
  let filterEndDate = new Date()
  filterEndDate.setHours(23,59,59,999);
  const [dateRangeFilter, setDateRangeFilter] = useState([filterStartDate, filterEndDate])
  const [startDateFilter, endDateFilter] = dateRangeFilter
  const [dayAgoFilter, setDayAgoFilter ] = useState('1');
  const item = { grid: { xs: 12, sm: 6, md: 4 }}

  const [ refresh1, setRefresh1 ] = useState(false);

  const resultSets = ActiveMemberQueries(startDateFilter, endDateFilter, dayAgoFilter, refresh1, setRefresh1)

  const refresh = () => {
    setRefresh1(true)
  }

  return ([
    <Header />,
    <PageHeaderComponent onClickFunc={refresh} refreshlabel={t('component.refresh')} headerTitle={t('component.header.menu.activeMember')} />,
    <Dashboard>
      <DatePickerComponent state={dateRangeFilter} stateChanger={setDateRangeFilter} 
        pageChangerArr={[]} 
        dateLabel={t('page.membership.ActiveMemberPage.filter.start.date')}/>
      <TextFieldComponent id={"day_ago_filter"} state={dayAgoFilter} pageChangerArr={[]} 
        stateChanger={setDayAgoFilter} textLabel={t('page.membership.ActiveMemberPage.filter.dayAgo')}/>
    </Dashboard>,
    <Dashboard>
      <NumberComponent item={item} query={resultSets["point"]}
        titleName={t('page.membership.ActiveMemberPage.titleMapping.activePoint')}
        fieldName="ActiveMemberPoint.count"/>
      <NumberComponent item={item} query={resultSets["login"]}
        titleName={t('page.membership.ActiveMemberPage.titleMapping.activeLogin')}
        fieldName="ActiveMemberLogin.count"/>
      <NumberComponent item={item} query={resultSets["order"]}
        titleName={t('page.membership.ActiveMemberPage.titleMapping.activeOrder')}
        fieldName="ActiveMemberOrder.count"/>
    </Dashboard>
  ]) 
};

export default ActiveMemberPage;