import React, { useContext } from "react";
import { Route, Redirect } from 'react-router-dom';
import { CubeJSContext } from '../context/CubeJSProvider';
import { CubeContext } from '@cubejs-client/react';
import cubejs from '@cubejs-client/core';

const PrivateRoute = ({component: Component, pageACL, ...rest}) => {
  const context = useContext(CubeContext);
  const context2 = useContext(CubeJSContext);
  let pageACLArr = pageACL.split('.')
  let isAuth = false
  let isAllow = false

  const checkIsNotExpired = (expiryTime) => {
    if (new Date().getTime() > expiryTime.getTime()){
      context2.expired = true
      return false
    }
    return true
  }

  const isAllowAccess = () => {
    let tmpHash = context2.acl
    for(let i = 0; i < pageACLArr.length; i++){
      if(tmpHash['*'])
        return true
      else if(tmpHash[pageACLArr[i]] && i === (pageACLArr.length - 1))
        return true
      else if(tmpHash[pageACLArr[i]])
        tmpHash = tmpHash[pageACLArr[i]]
      else
        return false
    }
    return false
  }
  
  if (context2.login !== null && context2.login){
    isAuth = checkIsNotExpired(context2.expiredAt)
    if(isAuth)
      isAllow = isAllowAccess()
  }
  else{
    if(localStorage.getItem('rememberMe')){
      if(localStorage.getItem('storeCodes') && localStorage.getItem('cubeJSToken') &&
        localStorage.getItem('cubeJSUrl') && localStorage.getItem('login') &&
        localStorage.getItem('acl') && localStorage.getItem('expiredAt') && localStorage.getItem('propertyAc') && localStorage.getItem('companyName')){
        let expiredAt = new Date(localStorage.getItem('expiredAt'))
        if(checkIsNotExpired(expiredAt)){
          context2.storeCodes = JSON.parse(localStorage.getItem('storeCodes'))
          context2.cubeJSToken = localStorage.getItem('cubeJSToken')
          context2.cubeJSUrl = localStorage.getItem('cubeJSUrl')
          context2.login = localStorage.getItem('login')
          context2.propertyAc = JSON.parse(localStorage.getItem('propertyAc'))
          context2.acl = JSON.parse(localStorage.getItem('acl'))
          context2.expiredAt = expiredAt
          context2.breakfastStart = parseInt(localStorage.getItem('breakfastStart'))
          context2.breakfastEnd = parseInt(localStorage.getItem('breakfastEnd'))
          context2.lunchStart = parseInt(localStorage.getItem('lunchStart'))
          context2.lunchEnd = parseInt(localStorage.getItem('lunchEnd'))
          context2.teaStart = parseInt(localStorage.getItem('teaStart'))
          context2.teaEnd = parseInt(localStorage.getItem('teaEnd'))
          context2.dinnerStart = parseInt(localStorage.getItem('dinnerStart'))
          context2.dinnerEnd = parseInt(localStorage.getItem('dinnerEnd'))
          context2.companyName = localStorage.getItem('companyName')
          context.cubejsApi = cubejs(context2.cubeJSToken, {
            apiUrl: `${context2.cubeJSUrl}/cubejs-api/v1`,
          });
          isAuth = true
          isAllow = isAllowAccess()
        }
      }
    }
  }
  
  return (

    // Show the component only when the user is logged in
    // Otherwise, redirect the user to /signin page
    <Route {...rest} render={props => (
      isAuth ? isAllow ? <Component {...props} /> : <Redirect to="/dashboard" /> 
        : <Redirect to="/sign_in" />
    )} />
  );
};

export default PrivateRoute;