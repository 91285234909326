import React, { useState, useContext } from "react";
import Header from '../../components/Header';
import TableComponent from '../../components/TableComponent';
import NumberComponent from '../../components/NumberComponent';
import MultiSelectComponent from '../../components/MultiSelectComponent';
import DateRangePickerComponent from '../../components/DateRangePickerComponent';
import TextComponent from '../../components/TextComponent';
import Dashboard from '../../components/Dashboard';
import OmnitechQueries from '../../queries/OmnitechQueries'
import PageHeaderComponent from '../../components/PageHeaderComponent';
import { CubeJSContext } from '../../context/CubeJSProvider';
import { useTranslation } from 'react-i18next';
import { useCubeQuery } from '@cubejs-client/react';
import { setHours, setMinutes, setSeconds, setMilliseconds, format } from 'date-fns'

const applyFilter = (query, storeFilter, storeMember, languageFilter, languageMember) => {
  let storeCodeFilterObj = {
    member: storeMember,
    operator: "contains",
    values: storeFilter
  }

  query = {
    ...query,
    filters: (query.filters || []).concat(storeCodeFilterObj)
  }

  if (languageFilter && languageMember){
    let locateFilterObj = {
      member: languageMember,
      operator: "equals",
      values: [languageFilter]
    }
    query = {
      ...query,
      filters: (query.filters || []).concat(locateFilterObj)
    }
  }

  return query
}

const DayEndSummaryHistQueries = (storeFilter, startDateFilter, endDateFilter, isRefresh, setRefresh) => {
  if(startDateFilter == null && endDateFilter == null){
    startDateFilter = new Date()
    startDateFilter.setHours(0,0,0,0);
    endDateFilter = new Date()
    endDateFilter.setHours(23,59,59,999);
  }
  else if(startDateFilter == null){
    startDateFilter = setHours(endDateFilter, 0)
    startDateFilter = setMinutes(startDateFilter, 0)
    startDateFilter = setSeconds(startDateFilter, 0)
    startDateFilter = setMilliseconds(startDateFilter, 0)
  }
  else if (endDateFilter == null){
    endDateFilter = setHours(startDateFilter, 23)
    endDateFilter = setMinutes(endDateFilter, 59)
    endDateFilter = setSeconds(endDateFilter, 59)
    endDateFilter = setMilliseconds(endDateFilter, 999)
  }
  var result = {}
  let query1 = {
    ...OmnitechQueries.mvOrdersSummary.OrderSummaryHist.query,
    "timeDimensions": OmnitechQueries.mvOrdersSummary.OrderSummaryHist.query.timeDimensions.concat(
      {
        "dimension": "MvOrdersSummary.ordersCompletedAt",
        "dateRange": [ startDateFilter, endDateFilter ]
      })
  }
  query1 = applyFilter(query1, storeFilter, "MvOrdersSummary.shipFromStoreCode", null, null)
  if(isRefresh)
    query1 = {}
  result["summary"] = useCubeQuery(query1, {resetResultSetOnChange: true})

  let query2 = {
    ...OmnitechQueries.mvOrdersSummaryEM.OrderAverageSummaryHist.query,
    "timeDimensions": OmnitechQueries.mvOrdersSummaryEM.OrderAverageSummaryHist.query.timeDimensions.concat(
      {
        "dimension": "MvOrdersSummaryEM.ordersCompletedAt",
        "dateRange": [ startDateFilter, endDateFilter ]
      })
  }
  query2 = applyFilter(query2, storeFilter, "MvOrdersSummaryEM.shipFromStoreCode", null, null)
  if(isRefresh)
    query2 = {}
  result["average"] = useCubeQuery(query2, {resetResultSetOnChange: true})

  let query3 = {
    ...OmnitechQueries.mvOrdersSummary.VoidOrderSummaryHist.query,
    "timeDimensions": OmnitechQueries.mvOrdersSummary.VoidOrderSummaryHist.query.timeDimensions.concat(
      {
        "dimension": "MvOrdersSummary.ordersCompletedAt",
        "dateRange": [ startDateFilter, endDateFilter ]
      })
  }
  query3 = applyFilter(query3, storeFilter, "MvOrdersSummary.shipFromStoreCode", null, null)
  if(isRefresh)
    query3 = {}
  result["voidOrder"] = useCubeQuery(query3, {resetResultSetOnChange: true})

  let query4 = {
    ...OmnitechQueries.mvCartLineProperties.VoidItemCount.query,
    "timeDimensions": OmnitechQueries.mvCartLineProperties.VoidItemCount.query.timeDimensions.concat(
      {
        "dimension": "MvCartLineProperties.createdAt",
        "dateRange": [ startDateFilter, endDateFilter ]
      })
  }
  query4 = applyFilter(query4, storeFilter, "MvCartLineProperties.storeCode", null, null)
  if(isRefresh)
    query4 = {}
  result["voidItem"] = useCubeQuery(query4, {resetResultSetOnChange: true})

  let query5 = {
    ...OmnitechQueries.mvPaymentRequests.Tip.query,
    "timeDimensions": OmnitechQueries.mvPaymentRequests.Tip.query.timeDimensions.concat(
      {
        "dimension": "MvPaymentRequests.ordersCompletedAt",
        "dateRange": [ startDateFilter, endDateFilter ]
      })
  }
  query5 = applyFilter(query5, storeFilter, "MvPaymentRequests.shipmentsFromStoreCode", null, null)
  if(isRefresh)
    query5 = {}
  result["tip"] = useCubeQuery(query5, {resetResultSetOnChange: true})

  let query6 = {
    ...OmnitechQueries.mvCartTransactions.OutstandingTableNetSales.query,
    "timeDimensions": OmnitechQueries.mvCartTransactions.OutstandingTableNetSales.query.timeDimensions.concat(
      {
        "dimension": "MvCartTransactions.cartCreatedAt",
        "dateRange": [ startDateFilter, endDateFilter ]
      })
  }
  query6 = applyFilter(query6, storeFilter, "MvCartTransactions.storeCode", null, null)
  if(isRefresh)
    query6 = {}
  result["outstanding"] = useCubeQuery(query6, {resetResultSetOnChange: true})

  let query7 = {
    ...OmnitechQueries.mvOrderTransactions.TotalFoodGrossSales.query,
    "timeDimensions": OmnitechQueries.mvOrderTransactions.TotalFoodGrossSales.query.timeDimensions.concat(
      {
        "dimension": "MvOrderTransactions.ordersCompletedAt",
        "dateRange": [ startDateFilter, endDateFilter ]
      })
  }
  query7 = applyFilter(query7, storeFilter, "MvOrderTransactions.shipFromStoreCode", null, null)
  if(isRefresh)
    query7 = {}
  result["food"] = useCubeQuery(query7, {resetResultSetOnChange: true})


  let query8 = {
    ...OmnitechQueries.mvOrderTransactions.TotalBeverageGrossSales.query,
    "timeDimensions": OmnitechQueries.mvOrderTransactions.TotalBeverageGrossSales.query.timeDimensions.concat(
      {
        "dimension": "MvOrderTransactions.ordersCompletedAt",
        "dateRange": [ startDateFilter, endDateFilter ]
      })
  }
  query8 = applyFilter(query8, storeFilter, "MvOrderTransactions.shipFromStoreCode", null, null)
  if(isRefresh)
    query8 = {}
  result["beverage"] = useCubeQuery(query8, {resetResultSetOnChange: true})


  let query9 = {
    ...OmnitechQueries.mvOrderTransactions.TotalOtherGrossSales.query,
    "timeDimensions": OmnitechQueries.mvOrderTransactions.TotalOtherGrossSales.query.timeDimensions.concat(
      {
        "dimension": "MvOrderTransactions.ordersCompletedAt",
        "dateRange": [ startDateFilter, endDateFilter ]
      })
  }
  query9 = applyFilter(query9, storeFilter, "MvOrderTransactions.shipFromStoreCode", null, null)
  if(isRefresh)
    query9 = {}
  result["other"] = useCubeQuery(query9, {resetResultSetOnChange: true})
  
  if(isRefresh)
    setRefresh(false)
  return result
}


const NetSalesByStoreQuery = (storeFilter, startDateFilter, endDateFilter, isRefresh, setRefresh) => {
  const { t } = useTranslation();
  if(startDateFilter == null && endDateFilter == null){
    startDateFilter = new Date()
    startDateFilter.setHours(0,0,0,0);
    endDateFilter = new Date()
    endDateFilter.setHours(23,59,59,999);
  }
  else if(startDateFilter == null){
    startDateFilter = setHours(endDateFilter, 0)
    startDateFilter = setMinutes(startDateFilter, 0)
    startDateFilter = setSeconds(startDateFilter, 0)
    startDateFilter = setMilliseconds(startDateFilter, 0)
  }
  else if (endDateFilter == null){
    endDateFilter = setHours(startDateFilter, 23)
    endDateFilter = setMinutes(endDateFilter, 59)
    endDateFilter = setSeconds(endDateFilter, 59)
    endDateFilter = setMilliseconds(endDateFilter, 999)
  }
  let item = {
    name: t('page.fnb.dailyOperationHist.netSalesByStore.name'),
    tableHeaders: {
      "MvOrdersSummary.count": { name: t('page.fnb.dailyOperationHist.netSalesByStore.tableHeader.count'), type: "number" },
      "MvOrdersSummary.sumOrderTotal":  { name: t('page.fnb.dailyOperationHist.netSalesByStore.tableHeader.sumOrderTotal'), type: "number" },
      "MvOrdersSummary.shipFromStoreCode":  { name: t('page.fnb.dailyOperationHist.netSalesByStore.tableHeader.shipFromStoreCode'), type: "string" },
      "MvOrdersSummary.orderSource":  { name: t('page.fnb.dailyOperationHist.netSalesByStore.tableHeader.orderSource'), type: "string" }
    },
    grid: { xs: 12, md: 6 },
    export: { allowExport: true, filename: 'net_sales_by_store_export_' + format(startDateFilter, 'yyyyMMddHHmmss') + 
      '_' + format(endDateFilter, 'yyyyMMddHHmmss') }
  }
  let query = {
    ...OmnitechQueries.mvOrdersSummary.NetSalesByStoreHist.query,
    "timeDimensions": [
      {
        "dimension": "MvOrdersSummary.ordersCompletedAt",
        "dateRange": [ startDateFilter, endDateFilter ]
      }
    ],
  }
  query = applyFilter(query, storeFilter, "MvOrdersSummary.shipFromStoreCode", null, null)
  if(isRefresh)
    query = {}
  const result = useCubeQuery(query, {resetResultSetOnChange: true})
  if(isRefresh)
    setRefresh(false)
  return { item, result }
}

const NetSalesByStoreEMQuery = (storeFilter, startDateFilter, endDateFilter, isRefresh, setRefresh) => {
  const { t } = useTranslation();
  if(startDateFilter == null && endDateFilter == null){
    startDateFilter = new Date()
    startDateFilter.setHours(0,0,0,0);
    endDateFilter = new Date()
    endDateFilter.setHours(23,59,59,999);
  }
  else if(startDateFilter == null){
    startDateFilter = setHours(endDateFilter, 0)
    startDateFilter = setMinutes(startDateFilter, 0)
    startDateFilter = setSeconds(startDateFilter, 0)
    startDateFilter = setMilliseconds(startDateFilter, 0)
  }
  else if (endDateFilter == null){
    endDateFilter = setHours(startDateFilter, 23)
    endDateFilter = setMinutes(endDateFilter, 59)
    endDateFilter = setSeconds(endDateFilter, 59)
    endDateFilter = setMilliseconds(endDateFilter, 999)
  }
  let item = {
    name: t('page.fnb.dailyOperationHist.netSalesByStoreEM.name'),
    tableHeaders: {
      "MvOrdersSummaryEM.count": { name: t('page.fnb.dailyOperationHist.netSalesByStoreEM.tableHeader.count'), type: "number" },
      "MvOrdersSummaryEM.sumNetSales":  { name: t('page.fnb.dailyOperationHist.netSalesByStoreEM.tableHeader.sumNetSales'), type: "number" },
      "MvOrdersSummaryEM.shipFromStoreCode":  { name: t('page.fnb.dailyOperationHist.netSalesByStoreEM.tableHeader.shipFromStoreCode'), type: "string" },
      "MvOrdersSummaryEM.orderSource":  { name: t('page.fnb.dailyOperationHist.netSalesByStoreEM.tableHeader.orderSource'), type: "string" }
    },
    grid: { xs: 12, md: 6 },
    export: { allowExport: true, filename: 'net_sales_by_store_exclude_membership_export_' + format(startDateFilter, 'yyyyMMddHHmmss') + 
      '_' + format(endDateFilter, 'yyyyMMddHHmmss') }
  }
  let query = {
    ...OmnitechQueries.mvOrdersSummaryEM.NetSalesByStoreEMHist.query,
    "timeDimensions": [
      {
        "dimension": "MvOrdersSummaryEM.ordersCompletedAt",
        "dateRange": [ startDateFilter, endDateFilter ]
      }
    ],
  }
  query = applyFilter(query, storeFilter, "MvOrdersSummaryEM.shipFromStoreCode", null, null)
  if(isRefresh)
    query = {}
  const result = useCubeQuery(query, {resetResultSetOnChange: true})
  if(isRefresh)
    setRefresh(false)
  return { item, result }
}

const PaymentByStoreTypeQuery = (storeFilter, startDateFilter, endDateFilter, languageFilter, isRefresh, setRefresh) => {
  const { t } = useTranslation();
  if(startDateFilter == null && endDateFilter == null){
    startDateFilter = new Date()
    startDateFilter.setHours(0,0,0,0);
    endDateFilter = new Date()
    endDateFilter.setHours(23,59,59,999);
  }
  else if(startDateFilter == null){
    startDateFilter = setHours(endDateFilter, 0)
    startDateFilter = setMinutes(startDateFilter, 0)
    startDateFilter = setSeconds(startDateFilter, 0)
    startDateFilter = setMilliseconds(startDateFilter, 0)
  }
  else if (endDateFilter == null){
    endDateFilter = setHours(startDateFilter, 23)
    endDateFilter = setMinutes(endDateFilter, 59)
    endDateFilter = setSeconds(endDateFilter, 59)
    endDateFilter = setMilliseconds(endDateFilter, 999)
  }
  let item = {
    name: t('page.fnb.dailyOperationHist.paymentByStoreType.name'),
    tableHeaders: {
      "MvPaymentRequests.sumAmount": { name: t('page.fnb.dailyOperationHist.paymentByStoreType.tableHeader.sumAmount'), type: "number" },
      "MvPaymentRequests.sumTip": { name: t('page.fnb.dailyOperationHist.paymentByStoreType.tableHeader.sumTip'), type: "number" },
      "MvPaymentRequests.grandTotal": { name: t('page.fnb.dailyOperationHist.paymentByStoreType.tableHeader.grandTotal'), type: "number" },
      "MvPaymentRequests.countOrderId": { name: t('page.fnb.dailyOperationHist.paymentByStoreType.tableHeader.countOrderId'), type: "number" },
      "MvPaymentRequests.shipmentsFromStoreCode": { name: t('page.fnb.dailyOperationHist.paymentByStoreType.tableHeader.shipmentsFromStoreCode'), type: "string" },
      "MvPaymentRequests.paymentProviderCode": { name: t('page.fnb.dailyOperationHist.paymentByStoreType.tableHeader.paymentProviderCode'), type: "string" },
      "PaymentProviderTranslations.name": { name: t('page.fnb.dailyOperationHist.paymentByStoreType.tableHeader.name'), type: "string" },
      "MvPaymentRequests.currencyCode": { name: t('page.fnb.dailyOperationHist.paymentByStoreType.tableHeader.currencyCode'), type: "string" },
      "MvPaymentRequests.state": { name: t('page.fnb.dailyOperationHist.paymentByStoreType.tableHeader.state'), type: "string" },
    },
    grid: { xs: 12 },
    export: { allowExport: true, filename: 'payment_by_store_and_type_export_' + format(startDateFilter, 'yyyyMMddHHmmss') + 
      '_' + format(endDateFilter, 'yyyyMMddHHmmss') }
  }
  let query = {
    ...OmnitechQueries.mvPaymentRequests.PaymentByStoreTypeHist.query,
    "timeDimensions": [
      {
        "dimension": "MvPaymentRequests.ordersCompletedAt",
        "dateRange": [ startDateFilter, endDateFilter ]
      }
    ],
  }
  query = applyFilter(query, storeFilter, "MvPaymentRequests.shipmentsFromStoreCode", languageFilter, "PaymentProviderTranslations.locale")
  if(isRefresh)
    query = {}
  const result = useCubeQuery(query, {resetResultSetOnChange: true})
  if(isRefresh)
    setRefresh(false)
  return { item, result }
}

const TenderByStoreStaffQuery = (storeFilter, startDateFilter, endDateFilter, languageFilter, isRefresh, setRefresh) => {
  const { t } = useTranslation();
  if(startDateFilter == null && endDateFilter == null){
    startDateFilter = new Date()
    startDateFilter.setHours(0,0,0,0);
    endDateFilter = new Date()
    endDateFilter.setHours(23,59,59,999);
  }
  else if(startDateFilter == null){
    startDateFilter = setHours(endDateFilter, 0)
    startDateFilter = setMinutes(startDateFilter, 0)
    startDateFilter = setSeconds(startDateFilter, 0)
    startDateFilter = setMilliseconds(startDateFilter, 0)
  }
  else if (endDateFilter == null){
    endDateFilter = setHours(startDateFilter, 23)
    endDateFilter = setMinutes(endDateFilter, 59)
    endDateFilter = setSeconds(endDateFilter, 59)
    endDateFilter = setMilliseconds(endDateFilter, 999)
  }
  let item = {
    name: t('page.fnb.dailyOperationHist.tenderByStoreStaff.name'),
    tableHeaders: {
      "MvPaymentRequests.sumAmount": { name: t('page.fnb.dailyOperationHist.tenderByStoreStaff.tableHeader.sumAmount'), type: "number" },
      "MvPaymentRequests.sumTip": { name: t('page.fnb.dailyOperationHist.tenderByStoreStaff.tableHeader.sumTip'), type: "number" },
      "MvPaymentRequests.countOrderId": { name: t('page.fnb.dailyOperationHist.tenderByStoreStaff.tableHeader.countOrderId'), type: "number" },
      "MvPaymentRequests.shipmentsFromStoreCode": { name: t('page.fnb.dailyOperationHist.tenderByStoreStaff.tableHeader.shipmentsFromStoreCode'), type: "string" },
      "MvPaymentRequests.staffNumber": { name: t('page.fnb.dailyOperationHist.tenderByStoreStaff.tableHeader.staffNumber'), type: "string" },
      "MvPaymentRequests.firstName": { name: t('page.fnb.dailyOperationHist.tenderByStoreStaff.tableHeader.firstName'), type: "string" },
      "MvPaymentRequests.lastName": { name: t('page.fnb.dailyOperationHist.tenderByStoreStaff.tableHeader.lastName'), type: "string" },
      "MvPaymentRequests.paymentProviderCode": { name: t('page.fnb.dailyOperationHist.tenderByStoreStaff.tableHeader.paymentProviderCode'), type: "string" },
      "PaymentProviderTranslations.name": { name: t('page.fnb.dailyOperationHist.tenderByStoreStaff.tableHeader.name'), type: "string" },
      "MvPaymentRequests.currencyCode": { name: t('page.fnb.dailyOperationHist.tenderByStoreStaff.tableHeader.currencyCode'), type: "string" },
      "MvPaymentRequests.state": { name: t('page.fnb.dailyOperationHist.tenderByStoreStaff.tableHeader.state'), type: "string" },
    },
    grid: { xs: 12 },
    export: { allowExport: true, filename: 'payment_by_store_and_staff_export_' + format(startDateFilter, 'yyyyMMddHHmmss') + 
      '_' + format(endDateFilter, 'yyyyMMddHHmmss') }
  }
  let query = {
    ...OmnitechQueries.mvPaymentRequests.TenderByStoreStaffHist.query,
    "timeDimensions": [
      {
        "dimension": "MvPaymentRequests.ordersCompletedAt",
        "dateRange": [ startDateFilter, endDateFilter ]
      }
    ],
  }
  query = applyFilter(query, storeFilter, "MvPaymentRequests.shipmentsFromStoreCode", languageFilter, "PaymentProviderTranslations.locale")
  if(isRefresh)
    query = {}
  const result = useCubeQuery(query, {resetResultSetOnChange: true})
  if(isRefresh)
    setRefresh(false)
  return { item, result }
}

const DiscountBreakdownQuery = (storeFilter, startDateFilter, endDateFilter, isRefresh, setRefresh) => {
  const { t } = useTranslation();
  if(startDateFilter == null && endDateFilter == null){
    startDateFilter = new Date()
    startDateFilter.setHours(0,0,0,0);
    endDateFilter = new Date()
    endDateFilter.setHours(23,59,59,999);
  }
  else if(startDateFilter == null){
    startDateFilter = setHours(endDateFilter, 0)
    startDateFilter = setMinutes(startDateFilter, 0)
    startDateFilter = setSeconds(startDateFilter, 0)
    startDateFilter = setMilliseconds(startDateFilter, 0)
  }
  else if (endDateFilter == null){
    endDateFilter = setHours(startDateFilter, 23)
    endDateFilter = setMinutes(endDateFilter, 59)
    endDateFilter = setSeconds(endDateFilter, 59)
    endDateFilter = setMilliseconds(endDateFilter, 999)
  }
  let item = {
    name: t('page.fnb.dailyOperationHist.discountBreakdown.name'),
    tableHeaders: {
      "MvOrderTransactions.shipFromStoreCode": { name: t('page.fnb.dailyOperationHist.discountBreakdown.tableHeader.shipFromStoreCode'), type: 'string'},
      "MvOrderTransactions.discountCode": { name: t('page.fnb.dailyOperationHist.discountBreakdown.tableHeader.discountCode'), type: 'string'},
      "MvOrderTransactions.discountNameEnHk": { name: t('page.fnb.dailyOperationHist.discountBreakdown.tableHeader.discountNameEnHk'), type: 'string'},
      "MvOrderTransactions.discountNameZhHk":  { name: t('page.fnb.dailyOperationHist.discountBreakdown.tableHeader.discountNameZhHk'), type: "string" },
      "MvOrderTransactions.sumLineTotal": { name: t('page.fnb.dailyOperationHist.discountBreakdown.tableHeader.lineTotal'), type: 'number'},
      "MvOrderTransactions.sumQuantity": { name: t('page.fnb.dailyOperationHist.discountBreakdown.tableHeader.quantity'), type: 'number'},
      "Orders.totalPrice": { name: t('page.fnb.dailyOperationHist.discountBreakdown.tableHeader.totalPrice'), type: 'number'},
    },
    grid: { xs: 12 },
    export: { allowExport: true, filename: 'discount_breakdown_export_' + format(startDateFilter, 'yyyyMMddHHmmss') +
      '_' + format(endDateFilter, 'yyyyMMddHHmmss') }
  }
  let query = {
    ...OmnitechQueries.mvOrderTransactions.DiscountBreakdown.query,
    "timeDimensions": [
      {
        "dimension": "MvOrderTransactions.ordersCompletedAt",
        "dateRange": [ startDateFilter, endDateFilter ]
      }
    ],
  }
  query = applyFilter(query, storeFilter, "MvOrderTransactions.shipFromStoreCode", null, null)
  if(isRefresh)
    query = {}
  const result = useCubeQuery(query, {resetResultSetOnChange: true})
  if(isRefresh)
    setRefresh(false)
  return { item, result }
}

const SKUSalesDetailsHistQuery = (storeFilter, startDateFilter, endDateFilter, isRefresh, setRefresh) => {
  const { t } = useTranslation();
  if(startDateFilter == null && endDateFilter == null){
    startDateFilter = new Date()
    startDateFilter.setHours(0,0,0,0);
    endDateFilter = new Date()
    endDateFilter.setHours(23,59,59,999);
  }
  else if(startDateFilter == null){
    startDateFilter = setHours(endDateFilter, 0)
    startDateFilter = setMinutes(startDateFilter, 0)
    startDateFilter = setSeconds(startDateFilter, 0)
    startDateFilter = setMilliseconds(startDateFilter, 0)
  }
  else if (endDateFilter == null){
    endDateFilter = setHours(startDateFilter, 23)
    endDateFilter = setMinutes(endDateFilter, 59)
    endDateFilter = setSeconds(endDateFilter, 59)
    endDateFilter = setMilliseconds(endDateFilter, 999)
  }
  let item = {
    name: t('page.fnb.dailyOperationHist.SKUSalesDetails.name'),
    tableHeaders: {
      "MvOrderTransactions.skuCode": { name: t('page.fnb.dailyOperationHist.SKUSalesDetails.tableHeader.skuCode'), type: 'string'},
      "MvOrderTransactions.skuInternalUseTitle": { name: t('page.fnb.dailyOperationHist.SKUSalesDetails.tableHeader.skuInternalUseTitle'), type: 'string'},
      "MvOrderTransactions.skuCost": { name: t('page.fnb.dailyOperationHist.SKUSalesDetails.tableHeader.skuCost'), type: 'number'},
      "MvOrderTransactions.productTitleEnHk": { name: t('page.fnb.dailyOperationHist.SKUSalesDetails.tableHeader.productTitleEnHk'), type: 'string'},
      "MvOrderTransactions.productTitleZhHk":  { name: t('page.fnb.dailyOperationHist.SKUSalesDetails.tableHeader.productTitleZhHk'), type: "string" },
      "ViewReportCategoryProductsFlatten.code":  { name: t('page.fnb.dailyOperationHist.SKUSalesDetails.tableHeader.code'), type: "string" },
      "MvOrderTransactions.sumLineTotal": { name: t('page.fnb.dailyOperationHist.SKUSalesDetails.tableHeader.lineTotal'), type: 'number'},
      "MvOrderTransactions.sumQuantity": { name: t('page.fnb.dailyOperationHist.SKUSalesDetails.tableHeader.quantity'), type: 'number'},
    },
    grid: { xs: 12 },
    export: { allowExport: true, filename: 'sku_sales_details_export_' + format(startDateFilter, 'yyyyMMddHHmmss') +
      '_' + format(endDateFilter, 'yyyyMMddHHmmss') }
  }
  let query = {
    ...OmnitechQueries.mvOrderTransactions.SKUSalesDetails.query,
    "timeDimensions": [
      {
        "dimension": "MvOrderTransactions.ordersCompletedAt",
        "dateRange": [ startDateFilter, endDateFilter ]
      }
    ],
  }
  query = applyFilter(query, storeFilter, "MvOrderTransactions.shipFromStoreCode", null, null)
  if(isRefresh)
    query = {}
  const result = useCubeQuery(query, {resetResultSetOnChange: true})
  if(isRefresh)
    setRefresh(false)
  return { item, result }
}

const HighlightCategorySalesQuery = (storeFilter, startDateFilter, endDateFilter, isRefresh, setRefresh) => {
  const { t } = useTranslation();
  if(startDateFilter == null && endDateFilter == null){
    startDateFilter = new Date()
    startDateFilter.setHours(0,0,0,0);
    endDateFilter = new Date()
    endDateFilter.setHours(23,59,59,999);
  }
  else if(startDateFilter == null){
    startDateFilter = setHours(endDateFilter, 0)
    startDateFilter = setMinutes(startDateFilter, 0)
    startDateFilter = setSeconds(startDateFilter, 0)
    startDateFilter = setMilliseconds(startDateFilter, 0)
  }
  else if (endDateFilter == null){
    endDateFilter = setHours(startDateFilter, 23)
    endDateFilter = setMinutes(endDateFilter, 59)
    endDateFilter = setSeconds(endDateFilter, 59)
    endDateFilter = setMilliseconds(endDateFilter, 999)
  }
  let item = {
    name: t('page.fnb.dailyOperationHist.HighlightCategorySales.name'),
    tableHeaders: {
      "MvOrderTransactions.shipFromStoreCode": { name: t('page.fnb.dailyOperationHist.HighlightCategorySales.tableHeader.shipFromStoreCode'), type: 'string'},
      "MvOrderTransactions.skuCode": { name: t('page.fnb.dailyOperationHist.HighlightCategorySales.tableHeader.skuCode'), type: 'string'},
      "MvOrderTransactions.skuInternalUseTitle": { name: t('page.fnb.dailyOperationHist.HighlightCategorySales.tableHeader.skuInternalUseTitle'), type: 'string'},
      "MvOrderTransactions.productTitleEnHk": { name: t('page.fnb.dailyOperationHist.HighlightCategorySales.tableHeader.productTitleEnHk'), type: 'string'},
      "MvOrderTransactions.productTitleZhHk":  { name: t('page.fnb.dailyOperationHist.HighlightCategorySales.tableHeader.productTitleZhHk'), type: "string" },
      "ViewReportCategoryProductsFlatten.code":  { name: t('page.fnb.dailyOperationHist.HighlightCategorySales.tableHeader.code'), type: "string" },
      "Categories.code": { name: t('page.fnb.dailyOperationHist.HighlightCategorySales.tableHeader.type'), type: "string" },
      "MvOrderTransactions.sumLineTotal": { name: t('page.fnb.dailyOperationHist.HighlightCategorySales.tableHeader.lineTotal'), type: 'number'},
      "MvOrderTransactions.sumQuantity": { name: t('page.fnb.dailyOperationHist.HighlightCategorySales.tableHeader.quantity'), type: 'number'},
    },
    grid: { xs: 12 },
    export: { allowExport: true, filename: 'highlight_category_sales_export_' + format(startDateFilter, 'yyyyMMddHHmmss') +
      '_' + format(endDateFilter, 'yyyyMMddHHmmss') }
  }
  let query = {
    ...OmnitechQueries.mvOrderTransactions.HighlightCategorySales.query,
    "timeDimensions": [
      {
        "dimension": "MvOrderTransactions.ordersCompletedAt",
        "dateRange": [ startDateFilter, endDateFilter ]
      }
    ],
  }
  query = applyFilter(query, storeFilter, "MvOrderTransactions.shipFromStoreCode", null, null)
  if(isRefresh)
    query = {}
  const result = useCubeQuery(query, {resetResultSetOnChange: true})
  if(isRefresh)
    setRefresh(false)
  return { item, result }
}

const MembershipSalesByTypeQuery = (storeFilter, startDateFilter, endDateFilter, isRefresh, setRefresh) => {
  const { t } = useTranslation();
  if(startDateFilter == null && endDateFilter == null){
    startDateFilter = new Date()
    startDateFilter.setHours(0,0,0,0);
    endDateFilter = new Date()
    endDateFilter.setHours(23,59,59,999);
  }
  else if(startDateFilter == null){
    startDateFilter = setHours(endDateFilter, 0)
    startDateFilter = setMinutes(startDateFilter, 0)
    startDateFilter = setSeconds(startDateFilter, 0)
    startDateFilter = setMilliseconds(startDateFilter, 0)
  }
  else if (endDateFilter == null){
    endDateFilter = setHours(startDateFilter, 23)
    endDateFilter = setMinutes(endDateFilter, 59)
    endDateFilter = setSeconds(endDateFilter, 59)
    endDateFilter = setMilliseconds(endDateFilter, 999)
  }
  let item = {
    name: t('page.fnb.dailyOperationHist.MembershipSalesByType.name'),
    tableHeaders: {
      "MvOrderTransactions.shipFromStoreCode": { name: t('page.fnb.dailyOperationHist.MembershipSalesByType.tableHeader.shipFromStoreCode'), type: 'string'},
      "MvOrderTransactions.skuCode": { name: t('page.fnb.dailyOperationHist.MembershipSalesByType.tableHeader.skuCode'), type: 'string'},
      "MvOrderTransactions.skuInternalUseTitle": { name: t('page.fnb.dailyOperationHist.MembershipSalesByType.tableHeader.skuInternalUseTitle'), type: 'string'},
      "MvOrderTransactions.sumLineTotal": { name: t('page.fnb.dailyOperationHist.MembershipSalesByType.tableHeader.lineTotal'), type: 'number'},
      "MvOrderTransactions.sumQuantity": { name: t('page.fnb.dailyOperationHist.MembershipSalesByType.tableHeader.quantity'), type: 'number'},
    },
    grid: { xs: 12 },
    export: { allowExport: true, filename: 'membership_sales_by_type_export_' + format(startDateFilter, 'yyyyMMddHHmmss') +
      '_' + format(endDateFilter, 'yyyyMMddHHmmss') }
  }
  let query = {
    ...OmnitechQueries.mvOrderTransactions.MembershipSalesByType.query,
    "timeDimensions": [
      {
        "dimension": "MvOrderTransactions.ordersCompletedAt",
        "dateRange": [ startDateFilter, endDateFilter ]
      }
    ],
  }
  query = applyFilter(query, storeFilter, "MvOrderTransactions.shipFromStoreCode", null, null)
  if(isRefresh)
    query = {}
  const result = useCubeQuery(query, {resetResultSetOnChange: true})
  if(isRefresh)
    setRefresh(false)
  return { item, result }
}

const MembershipSalesDetailQuery = (storeFilter, startDateFilter, endDateFilter, isRefresh, setRefresh) => {
  const { t } = useTranslation();
  if(startDateFilter == null && endDateFilter == null){
    startDateFilter = new Date()
    startDateFilter.setHours(0,0,0,0);
    endDateFilter = new Date()
    endDateFilter.setHours(23,59,59,999);
  }
  else if(startDateFilter == null){
    startDateFilter = setHours(endDateFilter, 0)
    startDateFilter = setMinutes(startDateFilter, 0)
    startDateFilter = setSeconds(startDateFilter, 0)
    startDateFilter = setMilliseconds(startDateFilter, 0)
  }
  else if (endDateFilter == null){
    endDateFilter = setHours(startDateFilter, 23)
    endDateFilter = setMinutes(endDateFilter, 59)
    endDateFilter = setSeconds(endDateFilter, 59)
    endDateFilter = setMilliseconds(endDateFilter, 999)
  }
  let item = {
    name: t('page.fnb.dailyOperationHist.MembershipSalesDetails.name'),
    tableHeaders: {
      "MvOrderTransactions.shipFromStoreCode": { name: t('page.fnb.dailyOperationHist.MembershipSalesDetails.tableHeader.shipFromStoreCode'), type: 'string'},
      "MvOrderTransactions.skuCode": { name: t('page.fnb.dailyOperationHist.MembershipSalesDetails.tableHeader.skuCode'), type: 'string'},
      "MvOrderTransactions.skuInternalUseTitle": { name: t('page.fnb.dailyOperationHist.MembershipSalesDetails.tableHeader.skuInternalUseTitle'), type: 'string'},
      "MvOrderTransactions.staffNumber": { name: t('page.fnb.dailyOperationHist.MembershipSalesDetails.tableHeader.staffNumber'), type: 'string'},
      "MvOrderTransactions.firstName": { name: t('page.fnb.dailyOperationHist.MembershipSalesDetails.tableHeader.firstName'), type: 'string'},
      "MvOrderTransactions.lastName": { name: t('page.fnb.dailyOperationHist.MembershipSalesDetails.tableHeader.lastName'), type: 'string'},
      "MvOrderTransactions.ordersCompletedAt": { name: t('page.fnb.dailyOperationHist.MembershipSalesDetails.tableHeader.ordersCompletedAt'), type: 'dateTime'},
      "MvOrderTransactions.unitAmount": { name: t('page.fnb.dailyOperationHist.MembershipSalesDetails.tableHeader.unitAmount'), type: 'number'},
      "MvOrderTransactions.quantity": { name: t('page.fnb.dailyOperationHist.MembershipSalesDetails.tableHeader.quantity'), type: 'number'},
      "MvOrderTransactions.lineTotal": { name: t('page.fnb.dailyOperationHist.MembershipSalesDetails.tableHeader.lineTotal'), type: 'number'},
    },
    grid: { xs: 12 },
    export: { allowExport: true, filename: 'membership_sales_details_export_' + format(startDateFilter, 'yyyyMMddHHmmss') +
      '_' + format(endDateFilter, 'yyyyMMddHHmmss') }
  }
  let query = {
    ...OmnitechQueries.mvOrderTransactions.MembershipSalesDetails.query,
    "timeDimensions": [
      {
        "dimension": "MvOrderTransactions.ordersCompletedAt",
        "dateRange": [ startDateFilter, endDateFilter ]
      }
    ],
  }
  query = applyFilter(query, storeFilter, "MvOrderTransactions.shipFromStoreCode", null, null)
  if(isRefresh)
    query = {}
  const result = useCubeQuery(query, {resetResultSetOnChange: true})
  if(isRefresh)
    setRefresh(false)
  return { item, result }
}

const MembershipTenderSummariesQuery = (storeFilter, startDateFilter, endDateFilter, isRefresh, setRefresh) => {
  const { t } = useTranslation();
  if(startDateFilter == null && endDateFilter == null){
    startDateFilter = new Date()
    startDateFilter.setHours(0,0,0,0);
    endDateFilter = new Date()
    endDateFilter.setHours(23,59,59,999);
  }
  else if(startDateFilter == null){
    startDateFilter = setHours(endDateFilter, 0)
    startDateFilter = setMinutes(startDateFilter, 0)
    startDateFilter = setSeconds(startDateFilter, 0)
    startDateFilter = setMilliseconds(startDateFilter, 0)
  }
  else if (endDateFilter == null){
    endDateFilter = setHours(startDateFilter, 23)
    endDateFilter = setMinutes(endDateFilter, 59)
    endDateFilter = setSeconds(endDateFilter, 59)
    endDateFilter = setMilliseconds(endDateFilter, 999)
  }
  let item = {
    name: t('page.fnb.dailyOperationHist.MembershipTenderSummaries.name'),
    tableHeaders: {
      "MvOrderTransactions.shipFromStoreCode": { name: t('page.fnb.dailyOperationHist.MembershipTenderSummaries.tableHeader.shipFromStoreCode'), type: 'string'},
      "MvPaymentRequests.paymentProviderCode": { name: t('page.fnb.dailyOperationHist.MembershipTenderSummaries.tableHeader.paymentProviderCode'), type: 'string'},
      "MvOrderTransactions.sumLineTotal": { name: t('page.fnb.dailyOperationHist.MembershipTenderSummaries.tableHeader.lineTotal'), type: 'number'},
      "MvOrderTransactions.sumQuantity": { name: t('page.fnb.dailyOperationHist.MembershipTenderSummaries.tableHeader.quantity'), type: 'number'},
    },
    grid: { xs: 12 },
    export: { allowExport: true, filename: 'membership_tender_summaries_export_' + format(startDateFilter, 'yyyyMMddHHmmss') +
      '_' + format(endDateFilter, 'yyyyMMddHHmmss') }
  }
  let query = {
    ...OmnitechQueries.mvOrderTransactions.MembershipTenderSummaries.query,
    "timeDimensions": [
      {
        "dimension": "MvOrderTransactions.ordersCompletedAt",
        "dateRange": [ startDateFilter, endDateFilter ]
      }
    ],
  }
  query = applyFilter(query, storeFilter, "MvOrderTransactions.shipFromStoreCode", null, null)
  if(isRefresh)
    query = {}
  const result = useCubeQuery(query, {resetResultSetOnChange: true})
  if(isRefresh)
    setRefresh(false)
  return { item, result }
}

const DailyOperationHistPage = () => {
  const { t, i18n } = useTranslation();
  let languageFilter = i18n.language
  if(languageFilter !== 'en-HK' && languageFilter !== 'zh-HK')
    languageFilter = 'en-HK'
  const context = useContext(CubeJSContext);
  let storeCodeSelect = []
  if(context.storeCodes)
    storeCodeSelect = context.storeCodes
  const [ storeFilter, setStoreFilter ] = useState([]);
  let filterStartDate = new Date()
  filterStartDate.setHours(0,0,0,0);
  let filterEndDate = new Date()
  filterEndDate.setHours(23,59,59,999);
  const [dateRangeFilter, setDateRangeFilter] = useState([filterStartDate, filterEndDate])
  const [startDateFilter, endDateFilter] = dateRangeFilter
  const item = { grid: { xs: 12, sm: 6, md: 4 }}
  const [rowsPerPage1, setRowsPerPage1] = useState(10);
  const [page1, setPage1] = useState(0);
  const [rowsPerPage2, setRowsPerPage2] = useState(10);
  const [page2, setPage2] = useState(0);
  const [rowsPerPage3, setRowsPerPage3] = useState(10);
  const [page3, setPage3] = useState(0);
  const [rowsPerPage4, setRowsPerPage4] = useState(10);
  const [page4, setPage4] = useState(0);
  const [rowsPerPage5, setRowsPerPage5] = useState(10);
  const [page5, setPage5] = useState(0);
  const [rowsPerPage6, setRowsPerPage6] = useState(10);
  const [page6, setPage6] = useState(0);
  const [rowsPerPage7, setRowsPerPage7] = useState(10);
  const [page7, setPage7] = useState(0);
  const [rowsPerPage8, setRowsPerPage8] = useState(10);
  const [page8, setPage8] = useState(0);
  const [rowsPerPage9, setRowsPerPage9] = useState(10);
  const [page9, setPage9] = useState(0);
  const [rowsPerPage10, setRowsPerPage10] = useState(10);
  const [page10, setPage10] = useState(0);

  const [ refresh1, setRefresh1 ] = useState(false);
  const [ refresh2, setRefresh2 ] = useState(false);
  const [ refresh3, setRefresh3 ] = useState(false);
  const [ refresh4, setRefresh4 ] = useState(false);
  const [ refresh5, setRefresh5 ] = useState(false);
  const [ refresh6, setRefresh6 ] = useState(false);
  const [ refresh7, setRefresh7 ] = useState(false);
  const [ refresh8, setRefresh8 ] = useState(false);
  const [ refresh9, setRefresh9 ] = useState(false);
  const [ refresh10, setRefresh10 ] = useState(false);
  const [ refresh11, setRefresh11 ] = useState(false);

  const resultSets = DayEndSummaryHistQueries(storeFilter, startDateFilter, endDateFilter, refresh1, setRefresh1)
  const query1 = NetSalesByStoreQuery(storeFilter, startDateFilter, endDateFilter, refresh2, setRefresh2)
  const query2 = NetSalesByStoreEMQuery(storeFilter, startDateFilter, endDateFilter, refresh3, setRefresh3)
  const query3 = PaymentByStoreTypeQuery(storeFilter, startDateFilter, endDateFilter, languageFilter, refresh4, setRefresh4)
  const query4 = TenderByStoreStaffQuery(storeFilter, startDateFilter, endDateFilter, languageFilter, refresh5, setRefresh5)
  const query5 = DiscountBreakdownQuery(storeFilter, startDateFilter, endDateFilter, refresh6, setRefresh6)
  const query6 = SKUSalesDetailsHistQuery(storeFilter, startDateFilter, endDateFilter, refresh7, setRefresh7)
  const query7 = HighlightCategorySalesQuery(storeFilter, startDateFilter, endDateFilter, refresh8, setRefresh8)
  const query8 = MembershipSalesByTypeQuery(storeFilter, startDateFilter, endDateFilter, refresh9, setRefresh9)
  const query9 = MembershipSalesDetailQuery(storeFilter, startDateFilter, endDateFilter, refresh10, setRefresh10)
  const query10 = MembershipTenderSummariesQuery(storeFilter, startDateFilter, endDateFilter, refresh11, setRefresh11)

  const refresh = () => {
    setRefresh1(true)
    setRefresh2(true)
    setRefresh3(true)
    setRefresh4(true)
    setRefresh5(true)
    setRefresh6(true)
    setRefresh7(true)
    setRefresh8(true)
    setRefresh9(true)
    setRefresh10(true)
    setRefresh11(true)
    setPage1(0)
    setPage2(0)
    setPage3(0)
    setPage4(0)
    setPage5(0)
    setPage6(0)
    setPage7(0)
    setPage8(0)
    setPage9(0)
    setPage10(0)
  }

  return ([
    <Header />,
    <PageHeaderComponent onClickFunc={refresh} refreshlabel={t('component.refresh')} headerTitle={t('component.header.menu.dailyOperationHist')} />,
    <Dashboard>
      <MultiSelectComponent id={"store_code_filter"} state={storeFilter} pageChangerArr={[setPage1,setPage2,setPage3,setPage4,setPage5,setPage6,setPage7,setPage8,setPage9,setPage10]}
          stateChanger={setStoreFilter} textLabel={t('page.fnb.dailyOperationHist.filter.store.name')} selectArray={storeCodeSelect}/>
      <DateRangePickerComponent state={dateRangeFilter} stateChanger={setDateRangeFilter} 
        pageChangerArr={[setPage1,setPage2,setPage3,setPage4,setPage5,setPage6,setPage7,setPage8,setPage9,setPage10]}
        startDateLabel={t('page.fnb.dailyOperationHist.filter.orderCompletedAt.startDate')} 
        toLabel={t('page.fnb.dailyOperationHist.filter.orderCompletedAt.to')} 
        endDateLabel={t('page.fnb.dailyOperationHist.filter.orderCompletedAt.endDate')}/>
    </Dashboard>,
    <Dashboard>
      <TextComponent title={t('component.text.historical')} xs={12} />
      <NumberComponent item={item} query={resultSets["summary"]}
        titleName={t('page.fnb.dailyOperationHist.titleMapping.sumOrderTotal')}
        fieldName="MvOrdersSummary.sumOrderTotal" />
      <NumberComponent item={item} query={resultSets["summary"]}
        titleName={t('page.fnb.dailyOperationHist.titleMapping.sumMembershipCharges')}
        fieldName="MvOrdersSummary.sumMembershipCharges" />
       <NumberComponent item={item} query={resultSets["summary"]}
        titleName={t('page.fnb.dailyOperationHist.titleMapping.sumNetSales')}
        fieldName="MvOrdersSummary.sumNetSales" />
      <NumberComponent item={item} query={resultSets["average"]}
        titleName={t('page.fnb.dailyOperationHist.titleMapping.orderCount')}
        fieldName="MvOrdersSummaryEM.count" />
      <NumberComponent item={item} query={resultSets["average"]}
        titleName={t('page.fnb.dailyOperationHist.titleMapping.avgBillAmount')}
        fieldName="MvOrdersSummaryEM.avgBillAmount" />
      <NumberComponent item={item} query={resultSets["average"]}
        titleName={t('page.fnb.dailyOperationHist.titleMapping.sumDineInNumberOfPeople')}
        fieldName="MvOrdersSummaryEM.sumDineInNumberOfPeople" />
      <NumberComponent item={item} query={resultSets["average"]}
        titleName={t('page.fnb.dailyOperationHist.titleMapping.perPersonSpending')}
        fieldName="MvOrdersSummaryEM.perPersonSpending" />
      <NumberComponent item={item} query={resultSets["summary"]}
        titleName={t('page.fnb.dailyOperationHist.titleMapping.sumGrossSales')}
        fieldName="MvOrdersSummary.sumGrossSales" />
      <NumberComponent item={item} query={resultSets["summary"]}
        titleName={t('page.fnb.dailyOperationHist.titleMapping.sumDiscounts')}
        fieldName="MvOrdersSummary.sumDiscounts" />
      <NumberComponent item={item} query={resultSets["tip"]}
        titleName={t('page.fnb.dailyOperationHist.titleMapping.sumTip')}
        fieldName="MvPaymentRequests.sumTip" />
      <NumberComponent item={item} query={resultSets["voidItem"]}
        titleName={t('page.fnb.dailyOperationHist.titleMapping.voidItemCount')}
        fieldName="MvCartLineProperties.sumCalculationQuantity" />
      <NumberComponent item={item} query={resultSets["voidOrder"]}
        titleName={t('page.fnb.dailyOperationHist.titleMapping.voidOrderCount')}
        fieldName="MvOrdersSummary.count" />
      <NumberComponent item={item} query={resultSets["voidOrder"]}
        titleName={t('page.fnb.dailyOperationHist.titleMapping.voidSumOrderTotal')}
        fieldName="MvOrdersSummary.sumOrderTotal" />
      <NumberComponent item={item} query={resultSets["summary"]}
        titleName={t('page.fnb.dailyOperationHist.titleMapping.sumShippingFees')}
        fieldName="MvOrdersSummary.sumShippingFees" />
      <NumberComponent item={item} query={resultSets["summary"]}
        titleName={t('page.fnb.dailyOperationHist.titleMapping.sumServiceCharges')}
        fieldName="MvOrdersSummary.sumServiceCharges" />
      <NumberComponent item={item} query={resultSets["summary"]}
        titleName={t('page.fnb.dailyOperationHist.titleMapping.sumOrderAmountRounding')}
        fieldName="MvOrdersSummary.sumOrderAmountRounding" />
      <NumberComponent item={item} query={resultSets["outstanding"]}
        titleName={t('page.fnb.dailyOperationHist.titleMapping.outStandingNetsales')}
        fieldName="MvCartTransactions.sumLineTotal" />
      <NumberComponent item={item} query={resultSets["food"]}
        titleName={t('page.fnb.dailyOperationHist.titleMapping.totalFoodGrossSales')}
        fieldName="MvOrderTransactions.sumLineTotal" />
      <NumberComponent item={item} query={resultSets["beverage"]}
        titleName={t('page.fnb.dailyOperationHist.titleMapping.totalBeverageGrossSales')}
        fieldName="MvOrderTransactions.sumLineTotal" />
      <NumberComponent item={item} query={resultSets["other"]}
        titleName={t('page.fnb.dailyOperationHist.titleMapping.totalOtherGrossSales')}
        fieldName="MvOrderTransactions.sumLineTotal" />
      <TextComponent title={""} xs={12} lg={8} transparent={true}/>
      <TableComponent item={query1.item} result={query1.result} rowsPerPage={rowsPerPage1} 
        setRowsPerPage={setRowsPerPage1} page={page1} setPage={setPage1}/>
      <TableComponent item={query2.item} result={query2.result} rowsPerPage={rowsPerPage2} 
        setRowsPerPage={setRowsPerPage2} page={page2} setPage={setPage2}/>
      <TableComponent item={query3.item} result={query3.result} rowsPerPage={rowsPerPage3} 
        setRowsPerPage={setRowsPerPage3} page={page3} setPage={setPage3}/>
      <TableComponent item={query4.item} result={query4.result} rowsPerPage={rowsPerPage4} 
        setRowsPerPage={setRowsPerPage4} page={page4} setPage={setPage4}/>
      <TableComponent item={query5.item} result={query5.result} rowsPerPage={rowsPerPage5} 
        setRowsPerPage={setRowsPerPage5} page={page5} setPage={setPage5}/>
      <TableComponent item={query6.item} result={query6.result} rowsPerPage={rowsPerPage6} 
        setRowsPerPage={setRowsPerPage6} page={page6} setPage={setPage6}/>
      <TableComponent item={query7.item} result={query7.result} rowsPerPage={rowsPerPage7} 
        setRowsPerPage={setRowsPerPage7} page={page7} setPage={setPage7}/>
      <TextComponent title={t('page.fnb.dailyOperationHist.text.tenderWarning')} xs={12} />
      <TableComponent item={query8.item} result={query8.result} rowsPerPage={rowsPerPage8} 
        setRowsPerPage={setRowsPerPage8} page={page8} setPage={setPage8}/>
      <TableComponent item={query9.item} result={query9.result} rowsPerPage={rowsPerPage9} 
        setRowsPerPage={setRowsPerPage9} page={page9} setPage={setPage9}/>
      <TextComponent title={t('component.text.paymentBreakdownMapping')} 
        contentArray={[t('page.fnb.dailyOperationHist.text.paymentCodeMapping.vsm'),
        t('page.fnb.dailyOperationHist.text.paymentCodeMapping.msm'),
        t('page.fnb.dailyOperationHist.text.paymentCodeMapping.CV'),
        t('page.fnb.dailyOperationHist.text.paymentCodeMapping.CU'),
        t('page.fnb.dailyOperationHist.text.paymentCodeMapping.aem'),
        t('page.fnb.dailyOperationHist.text.paymentCodeMapping.CASH_HKD'),
        t('page.fnb.dailyOperationHist.text.paymentCodeMapping.octopus'),
        t('page.fnb.dailyOperationHist.text.paymentCodeMapping.alipay_hkd_manual'),
        t('page.fnb.dailyOperationHist.text.paymentCodeMapping.wechat_pay_hkd_manual')]} xs={12} />
      <TableComponent item={query10.item} result={query10.result} rowsPerPage={rowsPerPage10} 
        setRowsPerPage={setRowsPerPage10} page={page10} setPage={setPage10}/>
    </Dashboard>
  ]) 
};

export default DailyOperationHistPage;