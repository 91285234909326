const StaffAttendanceQueries = {
  StaffAttendance:{
    query: {
      "measures": [
      ],
      "timeDimensions": [
      ],
      "order": {
        "StaffAttendance.onDutyStartAt": "asc",
        "StaffAttendance.staffNumber": "asc"
      },
      "filters": [
      ],
      "dimensions": [
        "StaffAttendance.storeCode",
        "StaffAttendance.staffNumber",
        "StaffAttendance.staffFirstName",
        "StaffAttendance.staffLastName",
        "StaffAttendance.onDutyStartAt",
        "StaffAttendance.onDutyEndAt",
        "StaffAttendance.dutyTime",
      ],
      "limit": 50000,
      "renewQuery": true,
    }
  },
  StaffAttendanceByDay:{
    query: {
      "measures": [
      ],
      "timeDimensions": [
      ],
      "order": {
        "StaffAttendanceByDay.date": "asc",
        "StaffAttendanceByDay.storeCode": "asc",
        "StaffAttendanceByDay.staffNumber": "asc"
      },
      "filters": [
      ],
      "dimensions": [
        "StaffAttendanceByDay.date",
        "StaffAttendanceByDay.staffNumber",
        "StaffAttendanceByDay.storeCode",
        "StaffAttendanceByDay.staffFirstName",
        "StaffAttendanceByDay.staffLastName",
        "StaffAttendanceByDay.onDutyStartAt",
        "StaffAttendanceByDay.onDutyEndAt",
        "StaffAttendanceByDay.dutyTime",
      ],
      "limit": 50000,
      "renewQuery": true,
    }
  }
}

export default StaffAttendanceQueries