const PerPersionSpendingQueries = {
  PerPersionSpendingByStaff: {
    query: {
      "measures": [
        "TimePeriodSummary.perPersonSpending"
      ],
      "timeDimensions": [
      ],
      "order": {
        "InternalAppOperators.staffNumber": "asc"
      },
      "dimensions": [
        "InternalAppOperators.staffNumber",
        "InternalAppOperators.firstName",
        "InternalAppOperators.lastName"
      ],
      "filters": [
        {
          "member": "TimePeriodSummary.state",
          "operator": "equals",
          "values": [
            "completed"
          ]
        },
        {
          "member": "TimePeriodSummary.commerceChannelCode",
          "operator": "notEquals",
          "values": [
            "membership_upgrade"
          ]
        },
        {
          "member": "InternalAppOperators.staffNumber",
          "operator": "set"
        },
      ],
      "limit": 50000,
      "renewQuery": true,
    }
  }
}

export default PerPersionSpendingQueries