const commonZhHK = {
  NewMemberPage: {
    "name": "新會員統計",
    "tableHeader": {
      "count": "會員人數",
      "cartsCreatedAt": "加入日期",
    },
    "filter": {
      "createdDate": {
        "startDate": "開始日期",
        "to": "至",
        "endDate": "結束日期"
      }
    }
  },
  PMCampaignRatePage: {
    "name": "活動推送消息讀取率",
    "tableHeader": {
      "pushMessageCampaignCode": "活動推送編號",
      "campaignStartDate": "活動推送時間",
      "scheduleHour": "安排時間",
      "totalMessages": "總推送數",
      "readCount": "讀取數",
      "readPercentage": "讀取比率"
    },
  },
  MemberSpendingPage: {
    "memberNetSpending": {
      "name": "會員平均凈消費統計",
      "tableHeader": {
        "member": "會員平均凈消費",
        "nonMember": "非會員平均凈消費",
        "ordersCompleteDate": "訂單完成日期"
      },
    },
    "memberSumNetSpending": {
      "name": "會員合計凈消費統計",
      "tableHeader": {
        "member": "會員合計凈消費",
        "nonMember": "非會員合計凈消費",
        "ordersCompleteDate": "訂單完成日期"
      },
    },
    "memberGrossSpending": {
      "name": "會員平均總消費統計",
      "tableHeader": {
        "member": "會員平均總消費",
        "nonMember": "非會員平均總消費",
        "ordersCompleteDate": "訂單完成日期"
      },
    },
    "memberSumGrossSpending": {
      "name": "會員合計總消費統計",
      "tableHeader": {
        "member": "會員合計總消費",
        "nonMember": "非會員合計總消費",
        "ordersCompleteDate": "訂單完成日期"
      },
    },
    "memberDiscount": {
      "name": "會員平均折扣統計",
      "tableHeader": {
        "member": "會員平均折扣",
        "nonMember": "非會員平均折扣",
          "memberRatio": "會員平均折扣比率(百分比)",
        "nonMemberRatio": "非會員平均折扣比率(百分比)",
        "ordersCompleteDate": "訂單完成日期"
      },
    },
    "memberSumDiscount": {
      "name": "會員合計折扣統計",
      "tableHeader": {
        "member": "會員合計折扣",
        "nonMember": "非會員合計折扣",
        "ordersCompleteDate": "訂單完成日期"
      },
    },
    "memberDiscountRatio": {
      "name": "會員折扣比率統計(百分比)",
    },
    "memberOrder": {
      "name": "會員平均訂單統計",
      "tableHeader": {
        "member": "會員平均訂單",
        "nonMember": "非會員平均訂單",
          "memberRatio": "會員平均訂單比率(百分比)",
        "nonMemberRatio": "非會員平均訂單比率(百分比)",
        "ordersCompleteDate": "訂單完成日期"
      },
    },
    "memberOrderRatio": {
      "name": "會員訂單比率統計(百分比)",
    },
    "filter": {
      "ordersCompleteDate": {
        "startDate": "開始日期",
        "to": "至",
        "endDate": "結束日期"
      }
    }
  },
  ActiveMemberPage: {
    "name": "活躍會員統計",
    "titleMapping": {
      "activePoint": "活躍會員(積分最後更新時間)",
      "activeLogin": "活躍會員(最後登錄時間)",
      "activeOrder": "活躍會員(最後訂單時間)",
    },
    "filter": {
      "start": {
        "date": "日期"
      },
      "dayAgo": "日前"
    }
  },
  MemberStorePage: {
    "name": "會員分店消費統計",
    "countName": "會員分店消費統計",
    "ratioName": "會員分店消費比率統計(百分比)",
    "tableHeader": {
      "ordersCompleteDate": "訂單完成日期",
      "member": "平均會員",
      "nonMember": "平均非會員",
      "memberRatio": "平均會員比率(百分比)",
      "nonMemberRatio": "平均非會員比率(百分比)",
    },
    "filter": {
      "ordersCompleteDate": {
        "startDate": "開始日期",
        "to": "至",
        "endDate": "結束日期"
      },
      "store": {
        "all": "全部",
        "name": "分店編號",
      },
    }
  },
  couponUsagePage: {
    "publicCouponUsage": {
      "name": "公眾優惠卷使用統計",
      "tableHeader": {
        "code": "優惠卷編號",
        "used": "已使用",
      },
    },
    "memberCouponUsage": {
      "name": "會員優惠卷使用統計",
      "tableHeader": {
        "code": "優惠卷編號",
        "used": "已使用",
        "redeemed": "已換領",
        "usedRatio": "已使用比率(百分比)",
      },
    },
  },
  "membershipPurchaseExporterPage": {
    "name": "會員購買記錄報告",
    "tableHeader": {
      "shipFromStoreCode": "分店編號",
      "lineTotal": "行總計",
      "quantity": "數量",
      "membershipCode": "會員編號",
      "userName": "顧客姓名",
      "firstName": "員工姓名",
      "phone": "電話",
      "ordersCompletedAt": "訂單完成時間",
      "email": "電郵地址",
      "referenceNumber": "訂單參考編號",
      "staffNumber": "員工編號",
      "commerceChannelCode": "商業頻道編號",
      "skuCode": "SKU編號",
      "skuInternalUseTitle": "SKU內部名稱",
      "productCode": "產品編號",
      "productTitleEnHk": "產品名稱 (英文)",
      "productTitleZhHk": "產品名稱 (中文)",
      "unitAmount": "單位金額",
    },
    "filter": {
      "store": {
        "all": "全部",
        "name": "分店編號",
      },
      "channel":{
        "name": "渠道"
      },
      "orderCompletedAt": {
        "startDate": "訂單完成開始日期",
        "to": "至",
        "endDate": "訂單完成結束日期"
      },
      "membershipCode": "會員編號",
      "email": "電郵地址",
      "phone": "電話",
      "userName": "顧客姓名",
      "skuCode": "SKU編號",
      "skuTitle": "SKU內部名稱"
    }
  },
  couponUsageByDateRangePage: {
    "name": "優惠卷使用統計",
    "tableHeader": {
      "code": "優惠卷編號",
      "used": "已使用",
    },
    "filter": {
      "createdAt": {
        "startDate": "使用開始日期",
        "to": "至",
        "endDate": "使用結束日期"
      },
    }
  },
  repeatedMembers:{
    "name": "Repeated Members",
    "tableHeader": {
      "shipFromStoreCode": "分店編號",
      "membershipCode": "會員編號",
      "firstName": "顧客姓",
      "lastName": "顧客名",
      "phone": "電話",
      "email": "電郵地址",
      "sex": "性別",
      "rankCode": "會員等級編號",
      "count": "消費次數",
      "sumTotalPrice": "消費總金額",
    },
    "filter": {
      "orderCompletedAt": {
        "startDate": "訂單完成開始日期",
        "to": "至",
        "endDate": "訂單完成結束日期"
      },
      "storeCode": "分店編號",
    },
  },
}

export default commonZhHK