import React, { useState, useContext, useMemo } from "react";
import Header from '../../components/Header';
import TableComponent from '../../components/TableComponent';
import NumberComponent from '../../components/NumberComponent';
import MultiSelectComponent from '../../components/MultiSelectComponent';
import DateRangePickerComponent from '../../components/DateRangePickerComponent';
import TextComponent from '../../components/TextComponent';
import ChartComponent from '../../components/ChartComponent';
import Dashboard from '../../components/Dashboard';
import PageHeaderComponent from '../../components/PageHeaderComponent';
import OmnitechQueries from '../../queries/OmnitechQueries'
import { CubeJSContext } from '../../context/CubeJSProvider';
import { useTranslation } from 'react-i18next';
import { useCubeQuery } from '@cubejs-client/react';
import { setHours, setMinutes, setSeconds, setMilliseconds, format } from 'date-fns'

const applyFilter = (query, storeFilter, storeMember, languageFilter, languageMember) => {
  let storeCodeFilterObj = {
    member: storeMember,
    operator: "contains",
    values: storeFilter
  }

  query = {
    ...query,
    filters: (query.filters || []).concat(storeCodeFilterObj)
  }

  if (languageFilter && languageMember){
    let locateFilterObj = {
      member: languageMember,
      operator: "equals",
      values: [languageFilter]
    }
    query = {
      ...query,
      filters: (query.filters || []).concat(locateFilterObj)
    }
  }

  return query
}

const DayEndSummaryHistQueries = (storeFilter, startDateFilter, endDateFilter, isRefresh, setRefresh) => {
  if(startDateFilter == null && endDateFilter == null){
    startDateFilter = new Date()
    startDateFilter.setHours(0,0,0,0);
    endDateFilter = new Date()
    endDateFilter.setHours(23,59,59,999);
  }
  else if(startDateFilter == null){
    startDateFilter = setHours(endDateFilter, 0)
    startDateFilter = setMinutes(startDateFilter, 0)
    startDateFilter = setSeconds(startDateFilter, 0)
    startDateFilter = setMilliseconds(startDateFilter, 0)
  }
  else if (endDateFilter == null){
    endDateFilter = setHours(startDateFilter, 23)
    endDateFilter = setMinutes(endDateFilter, 59)
    endDateFilter = setSeconds(endDateFilter, 59)
    endDateFilter = setMilliseconds(endDateFilter, 999)
  }
  var result = {}
  let query1 = {
    ...OmnitechQueries.mvOrdersSummary.OrderSummaryHist.query,
    "timeDimensions": OmnitechQueries.mvOrdersSummary.OrderSummaryHist.query.timeDimensions.concat(
      {
        "dimension": "MvOrdersSummary.ordersCompletedAt",
        "dateRange": [ startDateFilter, endDateFilter ]
      })
  }
  query1 = applyFilter(query1, storeFilter, "MvOrdersSummary.shipFromStoreCode", null, null)
  if(isRefresh)
    query1 = {}
  result["summary"] = useCubeQuery(query1, {resetResultSetOnChange: true})

  let query2 = {
    ...OmnitechQueries.mvOrdersSummaryEM.OrderAverageSummaryHist.query,
    "timeDimensions": OmnitechQueries.mvOrdersSummaryEM.OrderAverageSummaryHist.query.timeDimensions.concat(
      {
        "dimension": "MvOrdersSummaryEM.ordersCompletedAt",
        "dateRange": [ startDateFilter, endDateFilter ]
      })
  }
  query2 = applyFilter(query2, storeFilter, "MvOrdersSummaryEM.shipFromStoreCode", null, null)
  if(isRefresh)
    query2 = {}
  result["average"] = useCubeQuery(query2, {resetResultSetOnChange: true})

  let query3 = {
    ...OmnitechQueries.mvOrdersSummary.VoidOrderSummaryHist.query,
    "timeDimensions": OmnitechQueries.mvOrdersSummary.VoidOrderSummaryHist.query.timeDimensions.concat(
      {
        "dimension": "MvOrdersSummary.ordersCompletedAt",
        "dateRange": [ startDateFilter, endDateFilter ]
      })
  }
  query3 = applyFilter(query3, storeFilter, "MvOrdersSummary.shipFromStoreCode", null, null)
  if(isRefresh)
    query3 = {}
  result["voidOrder"] = useCubeQuery(query3, {resetResultSetOnChange: true})

  let query4 = {
    ...OmnitechQueries.mvCartLineProperties.VoidItemCount.query,
    "timeDimensions": OmnitechQueries.mvCartLineProperties.VoidItemCount.query.timeDimensions.concat(
      {
        "dimension": "MvCartLineProperties.createdAt",
        "dateRange": [ startDateFilter, endDateFilter ]
      })
  }
  query4 = applyFilter(query4, storeFilter, "MvCartLineProperties.storeCode", null, null)
  if(isRefresh)
    query4 = {}
  result["voidItem"] = useCubeQuery(query4, {resetResultSetOnChange: true})

  let query5 = {
    ...OmnitechQueries.mvPaymentRequests.Tip.query,
    "timeDimensions": OmnitechQueries.mvPaymentRequests.Tip.query.timeDimensions.concat(
      {
        "dimension": "MvPaymentRequests.ordersCompletedAt",
        "dateRange": [ startDateFilter, endDateFilter ]
      })
  }
  query5 = applyFilter(query5, storeFilter, "MvPaymentRequests.shipmentsFromStoreCode", null, null)
  if(isRefresh)
    query5 = {}
  result["tip"] = useCubeQuery(query5, {resetResultSetOnChange: true})

  let query6 = {
    ...OmnitechQueries.mvCartTransactions.OutstandingTableNetSales.query,
    "timeDimensions": OmnitechQueries.mvCartTransactions.OutstandingTableNetSales.query.timeDimensions.concat(
      {
        "dimension": "MvCartTransactions.cartCreatedAt",
        "dateRange": [ startDateFilter, endDateFilter ]
      })
  }
  query6 = applyFilter(query6, storeFilter, "MvCartTransactions.storeCode", null, null)
  if(isRefresh)
    query6 = {}
  result["outstanding"] = useCubeQuery(query6, {resetResultSetOnChange: true})

  let query7 = {
    ...OmnitechQueries.mvOrderTransactions.TotalFoodGrossSales.query,
    "timeDimensions": OmnitechQueries.mvOrderTransactions.TotalFoodGrossSales.query.timeDimensions.concat(
      {
        "dimension": "MvOrderTransactions.ordersCompletedAt",
        "dateRange": [ startDateFilter, endDateFilter ]
      })
  }
  query7 = applyFilter(query7, storeFilter, "MvOrderTransactions.shipFromStoreCode", null, null)
  if(isRefresh)
    query7 = {}
  result["food"] = useCubeQuery(query7, {resetResultSetOnChange: true})


  let query8 = {
    ...OmnitechQueries.mvOrderTransactions.TotalBeverageGrossSales.query,
    "timeDimensions": OmnitechQueries.mvOrderTransactions.TotalBeverageGrossSales.query.timeDimensions.concat(
      {
        "dimension": "MvOrderTransactions.ordersCompletedAt",
        "dateRange": [ startDateFilter, endDateFilter ]
      })
  }
  query8 = applyFilter(query8, storeFilter, "MvOrderTransactions.shipFromStoreCode", null, null)
  if(isRefresh)
    query8 = {}
  result["beverage"] = useCubeQuery(query8, {resetResultSetOnChange: true})

  let query9 = {
    ...OmnitechQueries.mvOrderTransactions.FoodBeverageSalesPercentage.query,
    "timeDimensions": OmnitechQueries.mvOrderTransactions.FoodBeverageSalesPercentage.query.timeDimensions.concat(
      {
        "dimension": "MvOrderTransactions.ordersCompletedAt",
        "dateRange": [ startDateFilter, endDateFilter ]
      })
  }
  query9 = applyFilter(query9, storeFilter, "MvOrderTransactions.shipFromStoreCode", null, null)
  if(isRefresh)
    query9 = {}
  result["percentage"] = useCubeQuery(query9, {resetResultSetOnChange: true})

  let query10 = {
    ...OmnitechQueries.mvOrderTransactions.TotalOtherGrossSales.query,
    "timeDimensions": OmnitechQueries.mvOrderTransactions.TotalOtherGrossSales.query.timeDimensions.concat(
      {
        "dimension": "MvOrderTransactions.ordersCompletedAt",
        "dateRange": [ startDateFilter, endDateFilter ]
      })
  }
  query10 = applyFilter(query10, storeFilter, "MvOrderTransactions.shipFromStoreCode", null, null)
  if(isRefresh)
    query10 = {}
  result["other"] = useCubeQuery(query10, {resetResultSetOnChange: true})
  
  if(isRefresh)
    setRefresh(false)
  return result
}

const NetSalesByStoreEMQuery = (storeFilter, startDateFilter, endDateFilter, isRefresh, setRefresh) => {
  const { t } = useTranslation();
  if(startDateFilter == null && endDateFilter == null){
    startDateFilter = new Date()
    startDateFilter.setHours(0,0,0,0);
    endDateFilter = new Date()
    endDateFilter.setHours(23,59,59,999);
  }
  else if(startDateFilter == null){
    startDateFilter = setHours(endDateFilter, 0)
    startDateFilter = setMinutes(startDateFilter, 0)
    startDateFilter = setSeconds(startDateFilter, 0)
    startDateFilter = setMilliseconds(startDateFilter, 0)
  }
  else if (endDateFilter == null){
    endDateFilter = setHours(startDateFilter, 23)
    endDateFilter = setMinutes(endDateFilter, 59)
    endDateFilter = setSeconds(endDateFilter, 59)
    endDateFilter = setMilliseconds(endDateFilter, 999)
  }
  let item = {
    name: t('page.fnb.dailyOperationHist.netSalesByStoreEM.name'),
    tableHeaders: {
      "MvOrdersSummaryEM.count": { name: t('page.fnb.dailyOperationHist.netSalesByStoreEM.tableHeader.count'), type: "number" },
      "MvOrdersSummaryEM.sumNetSales":  { name: t('page.fnb.dailyOperationHist.netSalesByStoreEM.tableHeader.sumNetSales'), type: "number" },
      "MvOrdersSummaryEM.shipFromStoreCode":  { name: t('page.fnb.dailyOperationHist.netSalesByStoreEM.tableHeader.shipFromStoreCode'), type: "string" },
      "MvOrdersSummaryEM.orderSource":  { name: t('page.fnb.dailyOperationHist.netSalesByStoreEM.tableHeader.orderSource'), type: "string" }
    },
    grid: { xs: 12 },
    export: { allowExport: true, filename: 'net_sales_by_store_exclude_membership_export_' + format(startDateFilter, 'yyyyMMddHHmmss') + 
      '_' + format(endDateFilter, 'yyyyMMddHHmmss') }
  }
  let query = {
    ...OmnitechQueries.mvOrdersSummaryEM.NetSalesByStoreEMHist.query,
    "timeDimensions": [
      {
        "dimension": "MvOrdersSummaryEM.ordersCompletedAt",
        "dateRange": [ startDateFilter, endDateFilter ]
      }
    ],
  }
  query = applyFilter(query, storeFilter, "MvOrdersSummaryEM.shipFromStoreCode", null, null)
  if(isRefresh)
    query = {}
  const result = useCubeQuery(query, {resetResultSetOnChange: true})
  if(isRefresh)
    setRefresh(false)
  return { item, result }
}

const PaymentByStoreTypeQuery = (storeFilter, startDateFilter, endDateFilter, languageFilter, isRefresh, setRefresh) => {
  const { t } = useTranslation();
  if(startDateFilter == null && endDateFilter == null){
    startDateFilter = new Date()
    startDateFilter.setHours(0,0,0,0);
    endDateFilter = new Date()
    endDateFilter.setHours(23,59,59,999);
  }
  else if(startDateFilter == null){
    startDateFilter = setHours(endDateFilter, 0)
    startDateFilter = setMinutes(startDateFilter, 0)
    startDateFilter = setSeconds(startDateFilter, 0)
    startDateFilter = setMilliseconds(startDateFilter, 0)
  }
  else if (endDateFilter == null){
    endDateFilter = setHours(startDateFilter, 23)
    endDateFilter = setMinutes(endDateFilter, 59)
    endDateFilter = setSeconds(endDateFilter, 59)
    endDateFilter = setMilliseconds(endDateFilter, 999)
  }
  let item = {
    name: t('page.fnb.dailyOperationHist.paymentByStoreType.name'),
    tableHeaders: {
      "MvPaymentRequests.sumAmount": { name: t('page.fnb.dailyOperationHist.paymentByStoreType.tableHeader.sumAmount'), type: "number" },
      "MvPaymentRequests.sumTip": { name: t('page.fnb.dailyOperationHist.paymentByStoreType.tableHeader.sumTip'), type: "number" },
      "MvPaymentRequests.grandTotal": { name: t('page.fnb.dailyOperationHist.paymentByStoreType.tableHeader.grandTotal'), type: "number" },
      "MvPaymentRequests.countOrderId": { name: t('page.fnb.dailyOperationHist.paymentByStoreType.tableHeader.countOrderId'), type: "number" },
      "MvPaymentRequests.shipmentsFromStoreCode": { name: t('page.fnb.dailyOperationHist.paymentByStoreType.tableHeader.shipmentsFromStoreCode'), type: "string" },
      "MvPaymentRequests.paymentProviderCode": { name: t('page.fnb.dailyOperationHist.paymentByStoreType.tableHeader.paymentProviderCode'), type: "string" },
      "PaymentProviderTranslations.name": { name: t('page.fnb.dailyOperationHist.paymentByStoreType.tableHeader.name'), type: "string" },
      "MvPaymentRequests.currencyCode": { name: t('page.fnb.dailyOperationHist.paymentByStoreType.tableHeader.currencyCode'), type: "string" },
      "MvPaymentRequests.state": { name: t('page.fnb.dailyOperationHist.paymentByStoreType.tableHeader.state'), type: "string" },
    },
    grid: { xs: 12 },
    export: { allowExport: true, filename: 'payment_by_store_and_type_export_' + format(startDateFilter, 'yyyyMMddHHmmss') + 
      '_' + format(endDateFilter, 'yyyyMMddHHmmss') }
  }
  let query = {
    ...OmnitechQueries.mvPaymentRequests.PaymentByStoreTypeHist.query,
    "timeDimensions": [
      {
        "dimension": "MvPaymentRequests.ordersCompletedAt",
        "dateRange": [ startDateFilter, endDateFilter ]
      }
    ],
  }
  query = applyFilter(query, storeFilter, "MvPaymentRequests.shipmentsFromStoreCode", languageFilter, "PaymentProviderTranslations.locale")
  if(isRefresh)
    query = {}
  const result = useCubeQuery(query, {resetResultSetOnChange: true})
  if(isRefresh)
    setRefresh(false)
  return { item, result }
}

const DiscountBreakdownQuery = (storeFilter, startDateFilter, endDateFilter, isRefresh, setRefresh) => {
  const { t } = useTranslation();
  if(startDateFilter == null && endDateFilter == null){
    startDateFilter = new Date()
    startDateFilter.setHours(0,0,0,0);
    endDateFilter = new Date()
    endDateFilter.setHours(23,59,59,999);
  }
  else if(startDateFilter == null){
    startDateFilter = setHours(endDateFilter, 0)
    startDateFilter = setMinutes(startDateFilter, 0)
    startDateFilter = setSeconds(startDateFilter, 0)
    startDateFilter = setMilliseconds(startDateFilter, 0)
  }
  else if (endDateFilter == null){
    endDateFilter = setHours(startDateFilter, 23)
    endDateFilter = setMinutes(endDateFilter, 59)
    endDateFilter = setSeconds(endDateFilter, 59)
    endDateFilter = setMilliseconds(endDateFilter, 999)
  }
  let item = {
    name: t('page.fnb.dailyOperationHist.discountBreakdown.name'),
    tableHeaders: {
      "MvOrderTransactions.shipFromStoreCode": { name: t('page.fnb.dailyOperationHist.discountBreakdown.tableHeader.shipFromStoreCode'), type: 'string'},
      "MvOrderTransactions.discountCode": { name: t('page.fnb.dailyOperationHist.discountBreakdown.tableHeader.discountCode'), type: 'string'},
      "MvOrderTransactions.discountNameEnHk": { name: t('page.fnb.dailyOperationHist.discountBreakdown.tableHeader.discountNameEnHk'), type: 'string'},
      "MvOrderTransactions.discountNameZhHk":  { name: t('page.fnb.dailyOperationHist.discountBreakdown.tableHeader.discountNameZhHk'), type: "string" },
      "MvOrderTransactions.sumLineTotal": { name: t('page.fnb.dailyOperationHist.discountBreakdown.tableHeader.lineTotal'), type: 'number'},
      "MvOrderTransactions.sumQuantity": { name: t('page.fnb.dailyOperationHist.discountBreakdown.tableHeader.quantity'), type: 'number'},
      "Orders.totalPrice": { name: t('page.fnb.dailyOperationHist.discountBreakdown.tableHeader.totalPrice'), type: 'number'},
    },
    grid: { xs: 12 },
    export: { allowExport: true, filename: 'discount_breakdown_export_' + format(startDateFilter, 'yyyyMMddHHmmss') +
      '_' + format(endDateFilter, 'yyyyMMddHHmmss') }
  }
  let query = {
    ...OmnitechQueries.mvOrderTransactions.DiscountBreakdown.query,
    "timeDimensions": [
      {
        "dimension": "MvOrderTransactions.ordersCompletedAt",
        "dateRange": [ startDateFilter, endDateFilter ]
      }
    ],
  }
  query = applyFilter(query, storeFilter, "MvOrderTransactions.shipFromStoreCode", null, null)
  if(isRefresh)
    query = {}
  const result = useCubeQuery(query, {resetResultSetOnChange: true})
  if(isRefresh)
    setRefresh(false)
  return { item, result }
}

const MembershipSalesByTypeQuery = (storeFilter, startDateFilter, endDateFilter, isRefresh, setRefresh) => {
  const { t } = useTranslation();
  if(startDateFilter == null && endDateFilter == null){
    startDateFilter = new Date()
    startDateFilter.setHours(0,0,0,0);
    endDateFilter = new Date()
    endDateFilter.setHours(23,59,59,999);
  }
  else if(startDateFilter == null){
    startDateFilter = setHours(endDateFilter, 0)
    startDateFilter = setMinutes(startDateFilter, 0)
    startDateFilter = setSeconds(startDateFilter, 0)
    startDateFilter = setMilliseconds(startDateFilter, 0)
  }
  else if (endDateFilter == null){
    endDateFilter = setHours(startDateFilter, 23)
    endDateFilter = setMinutes(endDateFilter, 59)
    endDateFilter = setSeconds(endDateFilter, 59)
    endDateFilter = setMilliseconds(endDateFilter, 999)
  }
  let item = {
    name: t('page.fnb.dailyOperationHist.MembershipSalesByType.name'),
    tableHeaders: {
      "MvOrderTransactions.shipFromStoreCode": { name: t('page.fnb.dailyOperationHist.MembershipSalesByType.tableHeader.shipFromStoreCode'), type: 'string'},
      "MvOrderTransactions.skuCode": { name: t('page.fnb.dailyOperationHist.MembershipSalesByType.tableHeader.skuCode'), type: 'string'},
      "MvOrderTransactions.skuInternalUseTitle": { name: t('page.fnb.dailyOperationHist.MembershipSalesByType.tableHeader.skuInternalUseTitle'), type: 'string'},
      "MvOrderTransactions.sumLineTotal": { name: t('page.fnb.dailyOperationHist.MembershipSalesByType.tableHeader.lineTotal'), type: 'number'},
      "MvOrderTransactions.sumQuantity": { name: t('page.fnb.dailyOperationHist.MembershipSalesByType.tableHeader.quantity'), type: 'number'},
    },
    grid: { xs: 12 },
    export: { allowExport: true, filename: 'membership_sales_by_type_export_' + format(startDateFilter, 'yyyyMMddHHmmss') +
      '_' + format(endDateFilter, 'yyyyMMddHHmmss') }
  }
  let query = {
    ...OmnitechQueries.mvOrderTransactions.MembershipSalesByType.query,
    "timeDimensions": [
      {
        "dimension": "MvOrderTransactions.ordersCompletedAt",
        "dateRange": [ startDateFilter, endDateFilter ]
      }
    ],
  }
  query = applyFilter(query, storeFilter, "MvOrderTransactions.shipFromStoreCode", null, null)
  if(isRefresh)
    query = {}
  const result = useCubeQuery(query, {resetResultSetOnChange: true})
  if(isRefresh)
    setRefresh(false)
  return { item, result }
}

const MembershipTenderSummariesQuery = (storeFilter, startDateFilter, endDateFilter, isRefresh, setRefresh) => {
  const { t } = useTranslation();
  if(startDateFilter == null && endDateFilter == null){
    startDateFilter = new Date()
    startDateFilter.setHours(0,0,0,0);
    endDateFilter = new Date()
    endDateFilter.setHours(23,59,59,999);
  }
  else if(startDateFilter == null){
    startDateFilter = setHours(endDateFilter, 0)
    startDateFilter = setMinutes(startDateFilter, 0)
    startDateFilter = setSeconds(startDateFilter, 0)
    startDateFilter = setMilliseconds(startDateFilter, 0)
  }
  else if (endDateFilter == null){
    endDateFilter = setHours(startDateFilter, 23)
    endDateFilter = setMinutes(endDateFilter, 59)
    endDateFilter = setSeconds(endDateFilter, 59)
    endDateFilter = setMilliseconds(endDateFilter, 999)
  }
  let item = {
    name: t('page.fnb.dailyOperationHist.MembershipTenderSummaries.name'),
    tableHeaders: {
      "MvOrderTransactions.shipFromStoreCode": { name: t('page.fnb.dailyOperationHist.MembershipTenderSummaries.tableHeader.shipFromStoreCode'), type: 'string'},
      "MvPaymentRequests.paymentProviderCode": { name: t('page.fnb.dailyOperationHist.MembershipTenderSummaries.tableHeader.paymentProviderCode'), type: 'string'},
      "MvOrderTransactions.sumLineTotal": { name: t('page.fnb.dailyOperationHist.MembershipTenderSummaries.tableHeader.lineTotal'), type: 'number'},
      "MvOrderTransactions.sumQuantity": { name: t('page.fnb.dailyOperationHist.MembershipTenderSummaries.tableHeader.quantity'), type: 'number'},
    },
    grid: { xs: 12 },
    export: { allowExport: true, filename: 'membership_tender_summaries_export_' + format(startDateFilter, 'yyyyMMddHHmmss') +
      '_' + format(endDateFilter, 'yyyyMMddHHmmss') }
  }
  let query = {
    ...OmnitechQueries.mvOrderTransactions.MembershipTenderSummaries.query,
    "timeDimensions": [
      {
        "dimension": "MvOrderTransactions.ordersCompletedAt",
        "dateRange": [ startDateFilter, endDateFilter ]
      }
    ],
  }
  query = applyFilter(query, storeFilter, "MvOrderTransactions.shipFromStoreCode", null, null)
  if(isRefresh)
    query = {}
  const result = useCubeQuery(query, {resetResultSetOnChange: true})
  if(isRefresh)
    setRefresh(false)
  return { item, result }
}

const DailyOperationHistPage = () => {
  const { t, i18n } = useTranslation();
  let languageFilter = i18n.language
  if(languageFilter !== 'en-HK' && languageFilter !== 'zh-HK')
    languageFilter = 'en-HK'
  const context = useContext(CubeJSContext);
  let storeCodeSelect = []
  if(context.storeCodes)
    storeCodeSelect = context.storeCodes
  const [ storeFilter, setStoreFilter ] = useState([]);
  let filterStartDate = new Date()
  filterStartDate.setHours(0,0,0,0);
  let filterEndDate = new Date()
  filterEndDate.setHours(23,59,59,999);
  const [dateRangeFilter, setDateRangeFilter] = useState([filterStartDate, filterEndDate])
  const [startDateFilter, endDateFilter] = dateRangeFilter
  const item3 = {grid: {xs: 3}}
  const item6 = {grid: {xs: 6}}
  const [rowsPerPage1, setRowsPerPage1] = useState(10);
  const [page1, setPage1] = useState(0);
  const [rowsPerPage2, setRowsPerPage2] = useState(10);
  const [page2, setPage2] = useState(0);
  const [rowsPerPage3, setRowsPerPage3] = useState(1000);
  const [page3, setPage3] = useState(0);
  const [rowsPerPage4, setRowsPerPage4] = useState(10);
  const [page4, setPage4] = useState(0);
  const [rowsPerPage5, setRowsPerPage5] = useState(10);
  const [page5, setPage5] = useState(0);

  const getTotalSalesPercentageChart = (resultSet) => {  
    if (!resultSet) return {}
    
    const dataSeries = resultSet?.seriesNames();
    const data = resultSet?.chartPivot()[0];
    return {
      data: dataSeries
        .map((seriesName, index) => {
          // only support number for pie chart data, converting the string to number 
          // by adding a "+" sign at beginning
          return {
            x: data[seriesName.key],
            name: t(`page.fnb.dailyOperationHistPrint.totalGrossSalesChart.labels.${seriesName.key}`),
          }
        }),
      dataSeries,
    }
  }

  const [ refresh1, setRefresh1 ] = useState(false);
  const [ refresh2, setRefresh2 ] = useState(false);
  const [ refresh3, setRefresh3 ] = useState(false);
  const [ refresh4, setRefresh4 ] = useState(false);
  const [ refresh5, setRefresh5 ] = useState(false);
  const [ refresh6, setRefresh6 ] = useState(false);

  const resultSets = DayEndSummaryHistQueries(storeFilter, startDateFilter, endDateFilter, refresh1, setRefresh1)
  const query1 = NetSalesByStoreEMQuery(storeFilter, startDateFilter, endDateFilter, refresh2, setRefresh2)
  const query2 = PaymentByStoreTypeQuery(storeFilter, startDateFilter, endDateFilter, languageFilter, refresh3, setRefresh3)
  const query3 = DiscountBreakdownQuery(storeFilter, startDateFilter, endDateFilter, refresh4, setRefresh4)
  const query4 = MembershipSalesByTypeQuery(storeFilter, startDateFilter, endDateFilter, refresh5, setRefresh5)
  const query5 = MembershipTenderSummariesQuery(storeFilter, startDateFilter, endDateFilter, refresh6, setRefresh6)

  const refresh = () => {
    setRefresh1(true)
    setRefresh2(true)
    setRefresh3(true)
    setRefresh4(true)
    setRefresh5(true)
    setRefresh6(true)
    setPage1(0)
    setPage2(0)
    setPage3(0)
    setPage4(0)
    setPage5(0)
  }


  // cache the chart massaged data
  const percentageResultSet = resultSets["percentage"]?.resultSet
  const totalSalesPercentageChart = useMemo(() => {
    if (percentageResultSet === null) return {data: [], dataSeries: []}
    
    return getTotalSalesPercentageChart(percentageResultSet)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [percentageResultSet, i18n.language])

  return ([
    <Header />,
    <PageHeaderComponent onClickFunc={refresh} refreshlabel={t('component.refresh')} headerTitle={t('component.header.menu.dailyOperationHistPrint')} />,
    <Dashboard>
      <MultiSelectComponent id={"store_code_filter"} state={storeFilter} pageChangerArr={[setPage1,setPage2,setPage3,setPage4,setPage5]}
          stateChanger={setStoreFilter} textLabel={t('page.fnb.dailyOperationHistPrint.filter.store.name')} selectArray={storeCodeSelect}/>
      <DateRangePickerComponent state={dateRangeFilter} stateChanger={setDateRangeFilter} 
        pageChangerArr={[setPage1,setPage2,setPage3,setPage4,setPage5]}
        startDateLabel={t('page.fnb.dailyOperationHistPrint.filter.orderCompletedAt.startDate')} 
        toLabel={t('page.fnb.dailyOperationHistPrint.filter.orderCompletedAt.to')} 
        endDateLabel={t('page.fnb.dailyOperationHistPrint.filter.orderCompletedAt.endDate')}/>
    </Dashboard>,
    <Dashboard>
      <TextComponent title={t('component.text.historical')} xs={12} lg={12}/>
      <TextComponent title={t('page.fnb.dailyOperationHistPrint.text.salesBalances')} xs={3} lg={3} transparent={true}/>
      <TextComponent title={t('page.fnb.dailyOperationHistPrint.text.OperatingStatistics')} xs={6} lg={6} transparent={true}/>
      <TextComponent title={t('page.fnb.dailyOperationHistPrint.text.auditTrail')} xs={3} lg={3} transparent={true}/>
      <NumberComponent item={item3} query={resultSets["summary"]}
        titleName={t('page.fnb.dailyOperationHistPrint.titleMapping.sumGrossSales')}
        fieldName="MvOrdersSummary.sumGrossSales" />
      <NumberComponent item={item6} query={resultSets["summary"]}
        titleName={t('page.fnb.dailyOperationHistPrint.titleMapping.sumNetSales')}
        fieldName="MvOrdersSummary.sumNetSales" />
      <NumberComponent item={item3} query={resultSets["voidOrder"]}
        titleName={t('page.fnb.dailyOperationHistPrint.titleMapping.voidOrderCount')}
        fieldName="MvOrdersSummary.count" />
      <NumberComponent item={item3} query={resultSets["summary"]}
        titleName={t('page.fnb.dailyOperationHistPrint.titleMapping.sumMembershipCharges')}
        fieldName="MvOrdersSummary.sumMembershipCharges" />
      <NumberComponent item={item3} query={resultSets["average"]}
        titleName={t('page.fnb.dailyOperationHistPrint.titleMapping.orderCount')}
        fieldName="MvOrdersSummaryEM.count" />
      <NumberComponent item={item3} query={resultSets["average"]}
        titleName={t('page.fnb.dailyOperationHistPrint.titleMapping.avgBillAmount')}
        fieldName="MvOrdersSummaryEM.avgBillAmount" />
      <NumberComponent item={item3} query={resultSets["voidOrder"]}
        titleName={t('page.fnb.dailyOperationHistPrint.titleMapping.voidSumOrderTotal')}
        fieldName="MvOrdersSummary.sumOrderTotal" />
      <NumberComponent item={item3} query={resultSets["summary"]}
        titleName={t('page.fnb.dailyOperationHistPrint.titleMapping.sumDiscounts')}
        fieldName="MvOrdersSummary.sumDiscounts" />
      <NumberComponent item={item3} query={resultSets["average"]}
        titleName={t('page.fnb.dailyOperationHistPrint.titleMapping.sumDineInNumberOfPeople')}
        fieldName="MvOrdersSummaryEM.sumDineInNumberOfPeople" />
      <NumberComponent item={item3} query={resultSets["average"]}
        titleName={t('page.fnb.dailyOperationHistPrint.titleMapping.perPersonSpending')}
        fieldName="MvOrdersSummaryEM.perPersonSpending" />
      <NumberComponent item={item3} query={resultSets["voidItem"]}
        titleName={t('page.fnb.dailyOperationHistPrint.titleMapping.voidItemCount')}
        fieldName="MvCartLineProperties.sumCalculationQuantity" />
      <NumberComponent item={item3} query={resultSets["summary"]}
        titleName={t('page.fnb.dailyOperationHistPrint.titleMapping.sumServiceCharges')}
        fieldName="MvOrdersSummary.sumServiceCharges" />
      <TextComponent title={t('page.fnb.dailyOperationHistPrint.text.salesBreakdown')} 
        xs={6} transparent={true} styles={{marginTop: "60px"}}/>        
      <NumberComponent item={item3} query={resultSets["tip"]}
        titleName={t('page.fnb.dailyOperationHistPrint.titleMapping.sumTip')}
        fieldName="MvPaymentRequests.sumTip" />
      <NumberComponent item={item3} query={resultSets["summary"]}
        titleName={t('page.fnb.dailyOperationHistPrint.titleMapping.sumShippingFees')}
        fieldName="MvOrdersSummary.sumShippingFees" />        
      <NumberComponent item={item3} query={resultSets["food"]}
        titleName={t('page.fnb.dailyOperationHistPrint.titleMapping.totalFoodGrossSales')}
        fieldName="MvOrderTransactions.sumLineTotal" /> 
      <NumberComponent item={item3} query={resultSets["percentage"]} percentage={true}
        titleName={t('page.fnb.dailyOperationHistPrint.titleMapping.foodPercentage')}
        fieldName="MvOrderTransactions.foodPercentage" />
      <TextComponent title={""} xs={12} lg={3} transparent={true}/>
      <NumberComponent item={item3} query={resultSets["summary"]}
        titleName={t('page.fnb.dailyOperationHistPrint.titleMapping.sumOrderAmountRounding')}
        fieldName="MvOrdersSummary.sumOrderAmountRounding" />
      <NumberComponent item={item3} query={resultSets["beverage"]}
        titleName={t('page.fnb.dailyOperationHistPrint.titleMapping.totalBeverageGrossSales')}
        fieldName="MvOrderTransactions.sumLineTotal" />
      <NumberComponent item={item3} query={resultSets["percentage"]} percentage={true}
        titleName={t('page.fnb.dailyOperationHistPrint.titleMapping.beveragePercentage')}
        fieldName="MvOrderTransactions.beveragePercentage"/>
      <TextComponent title={""} xs={3} lg={3} transparent={true}/>
      <NumberComponent item={item3} query={resultSets["summary"]}
        titleName={t('page.fnb.dailyOperationHistPrint.titleMapping.sumNetSales')}
        fieldName="MvOrdersSummary.sumNetSales" />
      <NumberComponent item={item3} query={resultSets["other"]}
        titleName={t('page.fnb.dailyOperationHistPrint.titleMapping.totalOtherGrossSales')}
        fieldName="MvOrderTransactions.sumLineTotal" />
      <NumberComponent item={item3} query={resultSets["percentage"]} percentage={true}
        titleName={t('page.fnb.dailyOperationHistPrint.titleMapping.otherPercentage')}
        fieldName="MvOrderTransactions.otherPercentage"/>
      <TextComponent title={""} xs={3} lg={3} transparent={true}/>
      <TextComponent title={""} xs={3} lg={3} transparent={true}/>
      <ChartComponent 
        chartType="pie" 
        item={item6} 
        result={resultSets["percentage"]}
        data={totalSalesPercentageChart.data}
        dataSeries={totalSalesPercentageChart.dataSeries}
        titleName={t('page.fnb.dailyOperationHistPrint.titleMapping.totalGrossSalesChart')}
        progressType="circular"
        enableTooltip={false}
        percentage
      />
      <TextComponent title={""} xs={3} lg={3} transparent={true}/>

      <TableComponent item={query1.item} result={query1.result} rowsPerPage={rowsPerPage1} 
        setRowsPerPage={setRowsPerPage1} page={page1} setPage={setPage1}/>
      <TableComponent item={query2.item} result={query2.result} rowsPerPage={rowsPerPage2} 
        setRowsPerPage={setRowsPerPage2} page={page2} setPage={setPage2}/>
      <TableComponent item={query3.item} result={query3.result} rowsPerPage={rowsPerPage3} 
        setRowsPerPage={setRowsPerPage3} page={page3} setPage={setPage3}/>
      <TextComponent title={t('page.fnb.dailyOperationHistPrint.text.tenderWarning')} xs={12} lg={12}/>
      <TableComponent item={query4.item} result={query4.result} rowsPerPage={rowsPerPage4} 
        setRowsPerPage={setRowsPerPage4} page={page4} setPage={setPage4}/>
      <TextComponent title={t('component.text.paymentBreakdownMapping')} 
        contentArray={[t('page.fnb.dailyOperationHistPrint.text.paymentCodeMapping.vsm'),
        t('page.fnb.dailyOperationHistPrint.text.paymentCodeMapping.msm'),
        t('page.fnb.dailyOperationHistPrint.text.paymentCodeMapping.CV'),
        t('page.fnb.dailyOperationHistPrint.text.paymentCodeMapping.CU'),
        t('page.fnb.dailyOperationHistPrint.text.paymentCodeMapping.aem'),
        t('page.fnb.dailyOperationHistPrint.text.paymentCodeMapping.CASH_HKD'),
        t('page.fnb.dailyOperationHistPrint.text.paymentCodeMapping.octopus'),
        t('page.fnb.dailyOperationHistPrint.text.paymentCodeMapping.alipay_hkd_manual'),
        t('page.fnb.dailyOperationHistPrint.text.paymentCodeMapping.wechat_pay_hkd_manual')]} xs={12} lg={12}/>
      <TableComponent item={query5.item} result={query5.result} rowsPerPage={rowsPerPage5} 
        setRowsPerPage={setRowsPerPage5} page={page5} setPage={setPage5}/>
    </Dashboard>
  ]) 
};

export default DailyOperationHistPage;