import React, { useContext } from "react";
import { Route, Redirect } from 'react-router-dom';
import { CubeJSContext } from '../context/CubeJSProvider';
import { CubeContext } from '@cubejs-client/react';
import cubejs from '@cubejs-client/core';

const PublicRoute = ({component: Component, restricted, ...rest}) => {
  const context = useContext(CubeContext);
  const context2 = useContext(CubeJSContext);
  let isAuth = false
  let toDashboard = false

  const checkIsNotExpired = (expiryTime) => {
    if (new Date().getTime() > expiryTime.getTime()){
      context2.expired = true
      return false
    }
    return true
  }

  if (context2.login === false){
    if(localStorage.getItem('rememberMe')){
      if(localStorage.getItem('storeCodes') && localStorage.getItem('cubeJSToken') &&
        localStorage.getItem('cubeJSUrl') && localStorage.getItem('login') &&
        localStorage.getItem('acl') && localStorage.getItem('expiredAt') && localStorage.getItem('propertyAc') && localStorage.getItem('companyName')){   
        let expiredAt = new Date(localStorage.getItem('expiredAt'))
        if (checkIsNotExpired(expiredAt)){
          context2.storeCodes = JSON.parse(localStorage.getItem('storeCodes'))
          context2.cubeJSToken = localStorage.getItem('cubeJSToken')
          context2.cubeJSUrl = localStorage.getItem('cubeJSUrl')
          context2.propertyAc = JSON.parse(localStorage.getItem('propertyAc'))
          context2.login = localStorage.getItem('login')
          context2.acl = JSON.parse(localStorage.getItem('acl'))
          context2.expiredAt = expiredAt
          context2.breakfastStart = parseInt(localStorage.getItem('breakfastStart'))
          context2.breakfastEnd = parseInt(localStorage.getItem('breakfastEnd'))
          context2.lunchStart = parseInt(localStorage.getItem('lunchStart'))
          context2.lunchEnd = parseInt(localStorage.getItem('lunchEnd'))
          context2.teaStart = parseInt(localStorage.getItem('teaStart'))
          context2.teaEnd = parseInt(localStorage.getItem('teaEnd'))
          context2.dinnerStart = parseInt(localStorage.getItem('dinnerStart'))
          context2.dinnerEnd = parseInt(localStorage.getItem('dinnerEnd'))
          context2.companyName = localStorage.getItem('companyName')
          context.cubejsApi = cubejs(context2.cubeJSToken, {
            apiUrl: `${context2.cubeJSUrl}/cubejs-api/v1`,
          });
        }
      }
    }
  }

  if(context2.login && context2.expiredAt && restricted)
    isAuth = checkIsNotExpired(context2.expiredAt)
  else if (!restricted){
    isAuth = true
    if(context2.login && rest.path === '/sign_in')
      toDashboard = true
  }

  return (
    // restricted = false meaning public route
    // restricted = true meaning restricted route
    <Route {...rest} render={props => (
      isAuth ? toDashboard? <Redirect to="/dashboard" /> : <Component {...props} /> 
        : <Redirect to="/sign_in" />
    )} />
  );
};

export default PublicRoute;