import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';

import PublicRoute from './components/PublicRoute';
import PrivateRoute from './components/PrivateRoute';

import DashboardPage from './pages/DashboardPage';
import DailyOperationPage from './pages/fnb/DailyOperationPage';
import DiscountBreakdownHistPage from './pages/fnb/DiscountBreakdownHistPage';
import DiscountBreakdownByOrderPage from './pages/fnb/DiscountBreakdownByOrderPage';
import DiscountBreakdownByItemPage from './pages/fnb/DiscountBreakdownByItemPage';
import RtUserDeviceRegisterPage from './pages/rt/RtUserDeviceRegisterPage'
import RtTakeawayDeliveryOrderApprovalTimeTracePage from './pages/rt/RtTakeawayDeliveryOrderApprovalTimeTracePage'
import MembershipPurchaseExporterPage from './pages/membership/MembershipPurchaseExporterPage'
import VoidItemDetailHistPage from './pages/fnb/VoidItemDetailHistPage'
import TotalFoodGrossSalesPage from './pages/fnb/TotalFoodGrossSalesPage'
import DiscountBreakdownByCommerceChannelPage from './pages/fnb/DiscountBreakdownByCommerceChannelPage'
import OrderEntriesAuditTrailPage from './pages/fnb/OrderEntriesAuditTrailPage'
import OrderSettlementPage from './pages/fnb/OrderSettlementPage'
import SignInPage from './pages/SignInPage'
import StoreStaffSalesPerformanceSummaryPage from './pages/fnb/StoreStaffSalesPerformanceSummaryPage'
import RtDiscountRelationsCheckingPage from './pages/rt/RtDiscountRelationsCheckingPage'
import RtBeveragesSalesPage from './pages/rt/RtBeveragesSalesPage'
import SKUSalesDetailsHistPage from './pages/fnb/SKUSalesDetailsHistPage'
import SKUSalesDetailsPage from './pages/fnb/SKUSalesDetailsPage'
import TimePeriodSummaryHistPage from './pages/fnb/TimePeriodSummaryHistPage'
import PaymentByStoreTypeBreakdownPage from './pages/fnb/PaymentByStoreTypeBreakdownPage'
import OutstandingTablePage from './pages/fnb/OutstandingTablePage'
import VoidItemDetailPage from './pages/fnb/VoidItemDetailPage'
import TimePeriodSummaryPage from './pages/fnb/TimePeriodSummaryPage'
import DiscountBreakdownByStaffPage from './pages/fnb/DiscountBreakdownByStaffPage'
import StaffStatisticsPage from './pages/fnb/StaffStatisticsPage'
import StaffTenderSummaryPage from './pages/fnb/StaffTenderSummaryPage'
import RtUserExportPage from './pages/rt/RtUserExportPage'
import UserExportPage from './pages/fnb/UserExportPage'
import RtUserSearchPage from './pages/rt/RtUserSearchPage'
import UserSearchPage from './pages/fnb/UserSearchPage'
import DailyOperationHistPage from './pages/fnb/DailyOperationHistPage'
import DailyOperationHistPrintPage from './pages/fnb/DailyOperationHistPrintPage'
import UserRankPage from './pages/fnb/UserRankPage'
import StaffAttendancePage from './pages/fnb/StaffAttendancePage'
import CurrentInventoryBalancePage from './pages/supplier/CurrentInventoryBalancePage'
import SupplierSummaryPage from './pages/supplier/SupplierSummaryPage'
import PerDayReportPage from './pages/fnb/PerDayReportPage'
import DailyOperationPrintPage from './pages/fnb/DailyOperationPrintPage'
import RSMCheckingPage from './pages/fnb/RSMCheckingPage'
import RSMRCheckingPage from './pages/fnb/RSMRCheckingPage'
import DiscountBreakdownByStaffHistPage from './pages/fnb/DiscountBreakdownByStaffHistPage'
import SKUFNBCheckingPage from './pages/fnb/SKUFNBCheckingPage'
import FSDailyOperationPage from './pages/fs/FSDailyOperationPage'
import DailySalesPage from './pages/fnb/DailySalesPage'
import RtOrderUserExportPage from './pages/rt/RtOrderUserExportPage'
import OrderUserExportPage from './pages/fnb/OrderUserExportPage'
import PaymentDayEndPage from './pages/fnb/PaymentDayEndPage'
import NewMemberPage from './pages/membership/NewMemberPage'
import PMCampaignRatePage from './pages/membership/PMCampaignRatePage'
import MemberSpendingPage from './pages/membership/MemberSpendingPage'
import ActiveMemberPage from './pages/membership/ActiveMemberPage'
import MemberStorePage from './pages/membership/MemberStorePage'
import CouponUsagePage from './pages/membership/CouponUsagePage'
import DiscountBreakdownByMemberPage from './pages/fnb/DiscountBreakdownByMemberPage'
import CartDiscountCheckingPage from './pages/fnb/CartDiscountCheckingPage'
import CHEDailyOperationPage from './pages/che/CHEDailyOperationPage'
import CHEDailyOperationFnbPage from './pages/che/CHEDailyOperationFnbPage'
import SKUSalesDetailsCHEHistPage from './pages/che/SKUSalesDetailsCHEHistPage'
import MPosSelfOrderPage from './pages/fnb/MPosSelfOrderPage'
import DiscountBreakdownCHEHistPage from './pages/che/DiscountBreakdownCHEHistPage'
import KSTDailyOperationPage from './pages/kst/KSTDailyOperationPage'
import KSTDailyOperationFnbPage from './pages/kst/KSTDailyOperationFnbPage'
import SKUSalesDetailsKSTHistPage from './pages/kst/SKUSalesDetailsKSTHistPage'
import DiscountBreakdownKSTHistPage from './pages/kst/DiscountBreakdownKSTHistPage'
import CouponUsageByDateRangePage from './pages/membership/CouponUsageByDatePage'
import StockTakeReportPage from './pages/stocktake/StockTakeReportPage'
import SkuInventoryAdjustmentReportPage from './pages/stocktake/SkuInventoryAdjustmentReportPage'
import RepeatMemberPage from './pages/membership/RepeatMemberPage'
import CartAuditTrailPage from './pages/fnb/CartAuditTrailPage'
import './i18n';

import App from './App';

ReactDOM.render(
  <Router>
    <App>
      <Switch>
        <Route exact path="/"
          render={() => {
          return ( <Redirect to="/dashboard" />)}
        }/>
        <PublicRoute restricted={false} key="sign_in" exact path="/sign_in" component={SignInPage} />
        <PublicRoute restricted={true} key="dashboard" exact path="/dashboard" component={DashboardPage} />
        <PrivateRoute pageACL={"fnb.daily_operation"} key="daily_operation" exact 
          path="/daily_operation" component={DailyOperationPage} />
        <PrivateRoute pageACL={"fnb.discount_breakdown_hist"} key="discount_breakdown_hist" exact 
          path="/discount_breakdown_hist" component={DiscountBreakdownHistPage} />
        <PrivateRoute pageACL={"fnb.discount_breakdown_by_order"} key="discount_breakdown_by_order" exact 
          path="/discount_breakdown_by_order" component={DiscountBreakdownByOrderPage} />
        <PrivateRoute pageACL={"fnb.discount_breakdown_by_item"} key="discount_breakdown_by_item" exact 
          path="/discount_breakdown_by_item" component={DiscountBreakdownByItemPage} />
        <PrivateRoute pageACL={"rt.rt_user_device_register_report"} key="rt_user_device_register_report" exact 
          path="/rt_user_device_register_report" component={RtUserDeviceRegisterPage} />
        <PrivateRoute pageACL={"rt.rt_takeaway_delivery_order_approval_time_trace"} 
          key="rt_takeaway_delivery_order_approval_time_trace" exact 
          path="/rt_takeaway_delivery_order_approval_time_trace" component={RtTakeawayDeliveryOrderApprovalTimeTracePage} />
        <PrivateRoute pageACL={"membership.membership_purchase_exporter"} key="membership_purchase_exporter" exact 
          path="/membership_purchase_exporter" component={MembershipPurchaseExporterPage} />
        <PrivateRoute pageACL={"fnb.void_item_detail_hist"} key="void_item_detail_hist" exact 
          path="/void_item_detail_hist" component={VoidItemDetailHistPage} />
        <PrivateRoute pageACL={"fnb.total_food_gross_sales"} key="total_food_gross_sales" exact 
          path="/total_food_gross_sales" component={TotalFoodGrossSalesPage} />
        <PrivateRoute pageACL={"fnb.discount_breakdown_by_commerce_channel"} key="discount_breakdown_by_commerce_channel" exact 
          path="/discount_breakdown_by_commerce_channel" component={DiscountBreakdownByCommerceChannelPage} />
        <PrivateRoute pageACL={"fnb.order_entries_audit_trail"} key="order_entries_audit_trail" exact 
          path="/order_entries_audit_trail" component={OrderEntriesAuditTrailPage} />
        <PrivateRoute pageACL={"fnb.order_settlement"} key="order_settlement" exact 
          path="/order_settlement" component={OrderSettlementPage} />
        <PrivateRoute pageACL={"fnb.store_staff_sales_performance_summary"} key="store_staff_sales_performance_summary" exact 
          path="/store_staff_sales_performance_summary" component={StoreStaffSalesPerformanceSummaryPage} />
        <PrivateRoute pageACL={"rt.rt_discount_reloations_checking"} key="rt_discount_reloations_checking" exact 
          path="/rt_discount_reloations_checking" component={RtDiscountRelationsCheckingPage} />
        <PrivateRoute pageACL={"rt.rt_beverages_sales"} key="rt_beverages_sales" exact 
          path="/rt_beverages_sales" component={RtBeveragesSalesPage} />
        <PrivateRoute pageACL={"fnb.sku_sales_details_hist"} key="sku_sales_details_hist" exact 
          path="/sku_sales_details_hist" component={SKUSalesDetailsHistPage} />
        <PrivateRoute pageACL={"fnb.sku_sales_details"} key="sku_sales_details" exact 
          path="/sku_sales_details" component={SKUSalesDetailsPage} />
        <PrivateRoute pageACL={"fnb.time_period_summary_hist"} key="time_period_summary_hist" exact 
          path="/time_period_summary_hist" component={TimePeriodSummaryHistPage} />
        <PrivateRoute pageACL={"fnb.payment_by_store_type_breakdown"} key="payment_by_store_type_breakdown" exact 
          path="/payment_by_store_type_breakdown" component={PaymentByStoreTypeBreakdownPage} />
        <PrivateRoute pageACL={"fnb.outstanding_table"} key="outstanding_table" exact 
          path="/outstanding_table" component={OutstandingTablePage} />
        <PrivateRoute pageACL={"fnb.void_item_detail"} key="void_item_detail" exact 
          path="/void_item_detail" component={VoidItemDetailPage} />
        <PrivateRoute pageACL={"fnb.time_period_summary"} key="time_period_summary" exact 
          path="/time_period_summary" component={TimePeriodSummaryPage} />
        <PrivateRoute pageACL={"fnb.discount_breakdown_by_staff"} key="discount_breakdown_by_staff" exact 
          path="/discount_breakdown_by_staff" component={DiscountBreakdownByStaffPage} />
        <PrivateRoute pageACL={"fnb.staff_statistics"} key="staff_statistics" exact 
          path="/staff_statistics" component={StaffStatisticsPage} />
        <PrivateRoute pageACL={"fnb.staff_tender_summary"} key="staff_tender_summary" exact 
          path="/staff_tender_summary" component={StaffTenderSummaryPage} />
        <PrivateRoute pageACL={"rt.rt_user_export"} key="rt_user_export" exact 
          path="/rt_user_export" component={RtUserExportPage} />
        <PrivateRoute pageACL={"fnb.user_export"} key="user_export" exact 
          path="/user_export" component={UserExportPage} />
        <PrivateRoute pageACL={"rt.rt_user_search"} key="rt_user_search" exact 
          path="/rt_user_search" component={RtUserSearchPage} />
        <PrivateRoute pageACL={"fnb.user_search"} key="user_search" exact 
          path="/user_search" component={UserSearchPage} />
        <PrivateRoute pageACL={"fnb.daily_operation_hist"} key="daily_operation_hist" exact 
          path="/daily_operation_hist" component={DailyOperationHistPage} />
        <PrivateRoute pageACL={"fnb.daily_operation_hist_print"} key="daily_operation_hist_print" exact 
          path="/daily_operation_hist_print" component={DailyOperationHistPrintPage} />
        <PrivateRoute pageACL={"fnb.user_rank"} key="user_rank" exact 
          path="/user_rank" component={UserRankPage} />
        <PrivateRoute pageACL={"fnb.staff_attendance"} key="staff_attendance" exact 
          path="/staff_attendance" component={StaffAttendancePage} />
        <PrivateRoute pageACL={"supplier.current_inventory_balance"} key="current_inventory_balance" exact 
          path="/current_inventory_balance" component={CurrentInventoryBalancePage} />
        <PrivateRoute pageACL={"supplier.supplier_summary"} key="supplier_summary" exact 
          path="/supplier_summary" component={SupplierSummaryPage} />
        <PrivateRoute pageACL={"fnb.per_day_report"} key="per_day_report" exact 
          path="/per_day_report" component={PerDayReportPage} />
        <PrivateRoute pageACL={"fnb.daily_operation_print"} key="daily_operation_print" exact 
          path="/daily_operation_print" component={DailyOperationPrintPage} />
        <PrivateRoute pageACL={"fnb.rsm_checking"} key="rsm_checking" exact 
          path="/rsm_checking" component={RSMCheckingPage} />
        <PrivateRoute pageACL={"fnb.rsmr_checking"} key="rsmr_checking" exact 
          path="/rsmr_checking" component={RSMRCheckingPage} />
        <PrivateRoute pageACL={"fnb.discount_breakdown_by_staff_hist"} key="discount_breakdown_by_staff_hist" exact 
          path="/discount_breakdown_by_staff_hist" component={DiscountBreakdownByStaffHistPage} />
        <PrivateRoute pageACL={"fnb.sku_checking"} key="fnb_sku_checking" exact 
          path="/fnb_sku_checking" component={SKUFNBCheckingPage} />
        <PrivateRoute pageACL={"fs.daily_opeation"} key="fs_daily_opeation" exact 
          path="/fs_daily_opeation" component={FSDailyOperationPage} />
        <PrivateRoute pageACL={"fnb.daily_sales"} key="daily_sales" exact 
          path="/daily_sales" component={DailySalesPage} />
        <PrivateRoute pageACL={"rt.rt_order_user_export"} key="rt_order_user_export" exact 
          path="/rt_order_user_export" component={RtOrderUserExportPage} />
        <PrivateRoute pageACL={"fnb.order_user_export"} key="order_user_export" exact 
          path="/order_user_export" component={OrderUserExportPage} />
        <PrivateRoute pageACL={"fnb.payment_day_end"} key="payment_day_end" exact 
          path="/payment_day_end" component={PaymentDayEndPage} />
        <PrivateRoute pageACL={"membership.new_member"} key="new_member" exact 
          path="/new_member" component={NewMemberPage} />
        <PrivateRoute pageACL={"membership.pm_campaign_rate"} key="pm_campaign_rate" exact 
          path="/pm_campaign_rate" component={PMCampaignRatePage} />
        <PrivateRoute pageACL={"membership.member_spending"} key="member_spending" exact 
          path="/member_spending" component={MemberSpendingPage} />
        <PrivateRoute pageACL={"membership.active_member"} key="active_member" exact 
          path="/active_member" component={ActiveMemberPage} />
        <PrivateRoute pageACL={"membership.member_store"} key="member_store" exact 
          path="/member_store" component={MemberStorePage} />
        <PrivateRoute pageACL={"membership.coupon_usage"} key="coupon_usage" exact 
          path="/coupon_usage" component={CouponUsagePage} />
        <PrivateRoute pageACL={"fnb.discount_breakdown_by_member"} key="discount_breakdown_by_member" exact 
          path="/discount_breakdown_by_member" component={DiscountBreakdownByMemberPage} />
        <PrivateRoute pageACL={"fnb.cart_discount_checking"} key="cart_discount_checking" exact 
          path="/cart_discount_checking" component={CartDiscountCheckingPage} />
        <PrivateRoute pageACL={"che.daily_opeation"} key="che_daily_opeation" exact 
          path="/che_daily_opeation" component={CHEDailyOperationPage} />
        <PrivateRoute pageACL={"che.daily_opeation_fnb"} key="che_daily_opeation_fnb" exact 
          path="/che_daily_opeation_fnb" component={CHEDailyOperationFnbPage} />
        <PrivateRoute pageACL={"che.che_sku_sales_details_hist"} key="che_sku_sales_details_hist" exact 
          path="/che_sku_sales_details_hist" component={SKUSalesDetailsCHEHistPage} />
        <PrivateRoute pageACL={"fnb.mpos_vs_self_order"} key="mpos_vs_self_order" exact 
          path="/mpos_vs_self_order" component={MPosSelfOrderPage} />
        <PrivateRoute pageACL={"che.che_discount_breakdown_hist"} key="che_discount_breakdown_hist" exact 
          path="/che_discount_breakdown_hist" component={DiscountBreakdownCHEHistPage} />
        <PrivateRoute pageACL={"kst.daily_opeation"} key="kst_daily_opeation" exact 
          path="/kst_daily_opeation" component={KSTDailyOperationPage} />
        <PrivateRoute pageACL={"kst.daily_opeation_fnb"} key="kst_daily_opeation_fnb" exact 
          path="/kst_daily_opeation_fnb" component={KSTDailyOperationFnbPage} />
        <PrivateRoute pageACL={"kst.che_sku_sales_details_hist"} key="kst_sku_sales_details_hist" exact 
          path="/kst_sku_sales_details_hist" component={SKUSalesDetailsKSTHistPage} />
        <PrivateRoute pageACL={"kst.che_discount_breakdown_hist"} key="kst_discount_breakdown_hist" exact 
          path="/kst_discount_breakdown_hist" component={DiscountBreakdownKSTHistPage} />
        <PrivateRoute pageACL={"membership.coupon_usage_by_date_range"} key="coupon_usage_by_date_range" exact 
          path="/coupon_usage_by_date_range" component={CouponUsageByDateRangePage} />
        <PrivateRoute pageACL={"stocktake.stocktake_report"} key="stocktake_report" exact 
          path="/stocktake_report" component={StockTakeReportPage} />
        <PrivateRoute pageACL={"stocktake.inventory_adjustment_report"} key="inventory_adjustment_report" exact 
          path="/inventory_adjustment_report" component={SkuInventoryAdjustmentReportPage} />
        <PrivateRoute pageACL={"membership.repeated_members"} key="repeated_members" exact 
          path="/repeated_members" component={RepeatMemberPage} />
        <PrivateRoute pageACL={"fnb.cart_audit_trail"} key="cart_audit_trail" exact 
          path="/cart_audit_trail" component={CartAuditTrailPage} />
      </Switch>
    </App>
  </Router>, // eslint-disable-next-line no-undef
  document.getElementById('root')
);
