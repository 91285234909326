const commonZhHK = {
  dailyOperation: {
    orderSummaryDetail:{
      name: "訂單銷售詳情",
      tableHeader: {
        catType: "類別",
        grossSales: "銷售額",
        sumSkuQuantity: "貨品數量",
        discount: "折扣",
        netSales: "淨銷售額",
        percentage: "銷售額百分比"
      }
    },
    titleMapping: {
      orderCount: "訂單數量",
      orderQuantity: "貨品數量",
      netSales: "淨銷售額",
      grossSales: "銷售額",
      orderRounding: "舍入",
      orderDiscount: "折扣",
      shelfGrossSales: "貨架銷售額",
      shelfTotalQuantity: "貨架貨品數量",
      shelfDiscount: "貨架折扣",
      shelfNetSales: "貨架淨銷售額",
      shelfPercentage: "貨架銷售額百分比",
      saladBarGrossSales: "沙拉吧銷售額",
      saladBarTotalQuantity: "沙拉吧貨品數量",
      saladBarDiscount: "沙拉吧折扣",
      saladBarNetSales: "沙拉吧淨銷售額",
      saladBarPercentage: "沙拉吧銷售額百分比",
      fridgeGrossSales: "雪櫃銷售額",
      fridgeTotalQuantity: "雪櫃貨品數量",
      fridgeDiscount: "雪櫃折扣",
      fridgeNetSales: "雪櫃淨銷售額",
      fridgePercentage: "雪櫃銷售額百分比",
      meatCounterGrossSales: "肉類櫃檯銷售額",
      meatCounterTotalQuantity: "肉類櫃檯貨品數量",
      meatCounterDiscount: "肉類櫃檯折扣",
      meatCounterNetSales: "肉類櫃檯淨銷售額",
      meatCounterPercentage: "肉類櫃檯銷售額百分比",
      eshopGrossSales: "網店銷售額",
      eshopTotalQuantity: "網店貨品數量",
      eshopDiscount: "網店折扣",
      eshopNetSales: "網店淨銷售額",
      eshopPercentage: "網店銷售額百分比",
      hongyuteaGrossSales: "寄售 - 紅玉茶樓銷售額",
      hongyuteaTotalQuantity: "寄售 - 紅玉茶樓貨品數量",
      hongyuteaDiscount: "寄售 - 紅玉茶樓折扣",
      hongyuteaNetSales: "寄售 - 紅玉茶樓淨銷售額",
      hongyuteaPercentage: "寄售 - 紅玉茶樓銷售額百分比",
      jebsenGrossSales: "寄售 - 捷成飲料銷售額",
      jebsenTotalQuantity: "寄售 - 捷成飲料貨品數量",
      jebsenDiscount: "寄售 - 捷成飲料折扣",
      jebsenNetSales: "寄售 - 捷成飲料淨銷售額",
      jebsenPercentage: "寄售 - 捷成飲料銷售額百分比",
      vomfassGrossSales: "寄售 - VomFass銷售額",
      vomfassTotalQuantity: "寄售 - VomFass貨品數量",
      vomfassDiscount: "寄售 - VomFass折扣",
      vomfassNetSales: "寄售 - VomFass淨銷售額",
      vomfassPercentage: "寄售 - VomFass銷售額百分比",
      pastrybarGrossSales: "糕點吧銷售額",
      pastrybarTotalQuantity: "糕點吧貨品數量",
      pastrybarDiscount: "糕點吧折扣",
      pastrybarNetSales: "糕點吧淨銷售額",
      pastrybarPercentage: "糕點吧銷售額百分比",
      pastrybarDrinksGrossSales: "糕點吧飲料銷售額",
      pastrybarDrinksTotalQuantity: "糕點吧飲料貨品數量",
      pastrybarDrinksDiscount: "糕點吧飲料折扣",
      pastrybarDrinksNetSales: "糕點吧飲料淨銷售額",
      pastrybarDrinksPercentage: "糕點吧飲料銷售額百分比",
      dairyCounterGrossSales: "乳製品櫃檯銷售額",
      dairyCounterTotalQuantity: "乳製品櫃檯貨品數量",
      dairyCounterDiscount: "乳製品櫃檯折扣",
      dairyCounterNetSales: "乳製品櫃檯淨銷售額",
      dairyCounterPercentage: "乳製品櫃檯銷售額百分比",
      organicWarehouseGrossSales: "有機倉庫銷售額",
      organicWarehouseTotalQuantity: "有機倉庫貨品數量",
      organicWarehouseDiscount: "有機倉庫折扣",
      organicWarehouseNetSales: "有機倉庫淨銷售額",
      organicWarehousePercentage: "有機倉庫銷售額百分比",
      dryAgedGrossSales: "熟成肉銷售額",
      dryAgedTotalQuantity: "熟成肉貨品數量",
      dryAgedDiscount: "熟成肉折扣",
      dryAgedNetSales: "熟成肉淨銷售額",
      dryAgedPercentage: "熟成肉銷售額百分比",
      kojiGrossSales: "Koji銷售額",
      kojiTotalQuantity: "Koji貨品數量",
      kojiDiscount: "Koji折扣",
      kojiNetSales: "Koji淨銷售額",
      kojiPercentage: "Koji銷售額百分比",
      otherGrossSales: "其他銷售額",
      otherDiscount: "其他折扣",
      otherNetSales: "其他淨銷售額",
      otherTotalQuantity: "其他貨品數量",
      otherPercentage: "其他銷售額百分比",
      totalRefund: "總退款",
      totalRefundAmount: "總退款金額",
      netSalesDeductRefund: "淨銷售額 (已扣除退款)"
    },
    filter: {
      storeCode: {
        name: "分店編號",
        all: "全部"
      },
      orderConfirmedAt: {
        "startDate": "訂單確認開始日期",
        "to": "至",
        "endDate": "訂單確認結束日期"
      }
    },
  },
  "dailyOperationFnb": {
    "filter": {
      "store": {
        "all": "全部",
        "name": "分店編號",
      },
      "period": {
        "name": "時段",
        "all": "全部",
        "lunch": "午市",
        "dinner": "晚市"
      },
      "orderCompletedAt": {
        "date": "訂單完成日期",
      }
    },
    "titleMapping": {
      "totalRevenue": "總收入",
      "totalOrder": "總訂單",
      "totalMembershipSales": "會員銷售總額",
      "totalNetSales": "總淨銷售額",
      "totalGrossSales": "總銷售額",
      "totalDiscounts": "總折扣",
      "totalVoidOrder": "總取消訂單",
      "totalVoidOrderAmount": "總取消訂單銷售額",
      "avgBillAmount": "平均訂單銷售額",
      "perPersonSpending": "人均消費",
      "totalTip": "總小費",
      "totalPax": "總人數",
      "totalShippingFees": "總運費",
      "totalServiceCharges": "總服務費",
      "totalVoidItem": "總取消物品",
      "totalOrderAmountRounding": "總訂單捨入金額",
      "outStandingNetsales": "未完成檯銷售額",
      "totalFoodGrossSales": "總食品銷售額",
      "totalBeverageGrossSales": "飲料總銷售額",
      "foodPercentage": "食品銷售額百分比",
      "beveragePercentage": "飲料銷售額百分比",
      "otherPercentage": "其他銷售額百分比",
      "totalGrossSalesChart": "總銷售額明細",
      "totalOtherGrossSales": "其他總銷售額",
      "totalRefund": "總退款",
      "totalRefundAmount": "總退款金額",
      "netSalesDeductRefund": "淨銷售額 (已扣除退款)"
    },
    "paymentByStoreType":{
      "name": "商店和支付類型明細付款",
      "tableHeader": {
        "sumAmount": "金額",
        "sumTip": "小費",
        "grandTotal": "累計",
        "countOrderId": "訂單總數",
        "shipmentsFromStoreCode": "分店編號",
        "paymentProviderCode": "支付提供商編號",
        "name": "支付提供商名稱",
        "currencyCode": "貨幣編號",
        "state": "狀態"
      }
    },
    "discountBreakdown":{
      "name": "折扣明細",
      "tableHeader": {
        "shipFromStoreCode": "分店編號",
        "discountCode": "折扣編號",
        "discountName": "折扣名稱",
        "lineTotal": "行總計",
        "quantity": "數量"
      },
    },
    "RSMSalesDetails": {
      "name": "RSM銷售詳情",
      "tableHeader": {
        "code": "RSM編號",
        "lineTotal": "行總計",
        "quantity": "數量"
      },
    },
    "text":{
      "paymentCodeMapping":{
        "vsm": "vsm - VISA (Manual) - VISA (手動);",
        "msm": "msm - MASTER (Manual) - 萬事達 (手動);",
        "CV": "CV - Cash Voucher - 現金券;",
        "CU": "CU - UNION PAY (Manual) - 銀聯 (手動);",
        "aem": "aem - AMERICAN EXPRESS (Manual) - 美國運通 (手動);",
        "CASH_HKD": "CASH_HKD - Cash(HKD) - 現金(港元);",
        "octopus": "octopus - Octopus (Manual) - 八達通 (手動);",
        "alipay_hkd_manual": "alipay_hkd_manual - Alipay HKD (Manual) - 支付寶香港 (手動);",
        "wechat_pay_hkd_manual": "wechat_pay_hkd_manual - WeChat Pay HKD (Manual) - 微信支付香港 (手動);",
      },
      "breakdown": "銷售詳细",
      "summary": "銷售總結",
      "others": "其他總結",
      "void": "取消總結"
    }
  },
  "SKUSalesKSTDetailsHistPage": {
    "SKUSalesDetails": {
      "name": "SKU銷售明細報告",
      "tableHeader": {
        "skuCode": "SKU編號",
        "upcCode": "UPC編號",
        "productCode": "物品編號",
        "productStatus": "物品狀態",
        "skuCost": "SKU成本",
        "propertyAc": "供應商狀態",
        "commerceChannelCodes": "商業頻道編號",
        "skuMeasurementUnit": "SKU量度基準",
        "skuInternalUseTitle": "SKU內部名稱",
        "productTitleEnHk": "物品名稱 (英文)",
        "productTitleZhHk": "物品名稱 (中文)",
        "rsmrCode": "RSMR編號",
        "rsmCode": "RSMR編號",
        "lineTotal": "行總計",
        "quantity": "數量",
        "subcatCategoryCode": "分類別編號",
        "skuMeasure": "總重",
        "skuMeasurementCode": "單位"
      }
    },
    "filter": {
      "store": {
        "all": "全部",
        "name": "分店編號",
      },
      "orderCompletedAt": {
        "startDate": "訂單完成開始日期",
        "to": "至",
        "endDate": "訂單完成結束日期"
      },
    }
  },
  "discountBreakDownKSTHistPage": {
    "name": "折扣明細報告",
    "tableHeader": {
      "shipFromStoreCode": "分店編號",
      "discountCode": "折扣編號",
      "discountNameEnHk": "折扣名稱 (英文)",
      "discountNameZhHk": "折扣名稱 (中文)",
      "rsmrCode": "RSMR編號",
      "subcatCategoryCode": "分類別編號",
      "lineTotal": "行總計",
      "quantity": "數量"
    },
    "filter": {
      "store": {
        "all": "全部",
        "name": "分店編號",
      },
      "orderCompletedAt": {
        "startDate": "訂單完成開始日期",
        "to": "至",
        "endDate": "訂單完成結束日期"
      }
    }
  },
}

export default commonZhHK