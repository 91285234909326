import React, { useMemo } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import LinearProgress from '@material-ui/core/LinearProgress';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  LabelList,
  Legend,
  Line,
  LineChart,
  Pie,
  PieChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import Grid from '@material-ui/core/Grid';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';
import { format } from 'date-fns'
import DashboardItem from './DashboardItem';
import ItemLoading from './ItemLoading';

/**
 * data and dataSeries format for chart rendering
 * 
 * data: [
 *  // array of entries object
 *  {
 *    x: x axis value
 *    name (pie chart only): a name used for labeling of a section in a pie chart
 *    // other attribute can be contained in the object for y axis value, for example
 *    count: 123,
 *    amount: 5648.00,
 *  }
 * ]
 * 
 * dataSeries: [
 *  // array of series data object, it is from Cubejs resultSet
 *  {
 *    key: the key to be used retrieving y axis value from an entry object 
 *    title: the name will be used labeling a series of data in a chart 
 *  }
 * ]
 */

// generated with online tools, https://learnui.design/tools/data-color-picker.html
const CHART_COLORS = [
  '#0093d6',
  '#FF8749',
  '#FFA600',
  '#FF6CAA',
  '#FF717B',
  '#E676CF',
  '#AE85E4',
  '#6A8FE6',
];

const theme = createTheme({
  typography: {
    body1: {
      fontSize: 12,
      fontWeight: 700,
    },
    body2: {
      fontSize: 11,
      fontWeight: 600,
    },
  },
});

/**
 * CartesianChart
 * Reuse for cartesian type charts, such as bar, line, area
 */
const CartesianChart = ({ data, enableTooltip, children, ChartTypeComponent, height = 300, width = '100%' }) => {
  return (
    <ResponsiveContainer width={width} height={height}>
      <ChartTypeComponent data={data}>
        <XAxis dataKey="x" axisLine={false} tickLine={false} />
        <YAxis axisLine={false} tickLine={false} />
        <CartesianGrid vertical={false} />
        <Legend verticalAlign="top" height={36} />
        { enableTooltip && <Tooltip /> }
        { children }
      </ChartTypeComponent>
    </ResponsiveContainer>
  )
}

const OmniBarChart = ({ data, dataSeries, enableTooltip, height, width }) => (
  <CartesianChart data={data} enableTooltip={enableTooltip} ChartTypeComponent={BarChart} 
    height={height} width={width}>
    {dataSeries.map((series, i) => (
      <Bar
        key={series.key}
        dataKey={series.key}
        fill={CHART_COLORS[i]}
        isAnimationActive={false}
        name={series.title}
      >
        <LabelList dataKey={series.key} position="top" />
      </Bar>
    ))}
  </CartesianChart>
)

const OmniLineChart = ({ data, dataSeries, enableTooltip, height, width }) => (
  <CartesianChart data={data} enableTooltip={enableTooltip} ChartTypeComponent={LineChart} 
    height={height} width={width}>
    {dataSeries.map((series, i) => (
      <Line
        key={series.key}
        dataKey={series.key}
        isAnimationActive={false}
        name={series.title}
        stroke={CHART_COLORS[i]}
        type="monotone"
      />
    ))}
  </CartesianChart>
)

const OmniPieChart = ({ data, enableTooltip, height = 300, width = '100%', percentage, renderLabel }) => {
  const defaultRenderLabel  = (entry) => {
    return percentage 
      ? `${((entry.percent * 100).toFixed(2).toString() || '0')}%`
      : entry.value;
  }
  
  const customRenderLabel = typeof renderLabel === 'function'
    ? renderLabel
    : defaultRenderLabel

  return (
    <ResponsiveContainer width={width} height={height}>
      <PieChart>
        <Legend verticalAlign="top" height={36} />
        { enableTooltip && <Tooltip /> }
        <Pie
          isAnimationActive={true}
          cx="50%"
          cy="50%"
          data={data}
          nameKey="name"
          dataKey="x"
          label={customRenderLabel}
        >
          {data.map((e, index) => (
            <Cell key={index} fill={CHART_COLORS[index % CHART_COLORS.length]} />
          ))}
        </Pie>
      </PieChart>
    </ResponsiveContainer>
  )
}

/**
 * ChartComponent
 * Render charts based on provided chart type and result set data
 */
const ChartComponent = ({ chartType, item = {}, data, dataSeries, result, titleName, fieldName, progressType = 'linear', ...otherProps }) => {
  const grid = item.grid || {};
  const { xs = 12, sm, md, lg } = grid;
  const { resultSet, isLoading, error, progress } = result;

  const Component = useMemo(() => {
    switch (chartType) {
      case "bar":
        return OmniBarChart;
      case "line":
        return OmniLineChart;
      case "pie":
        return OmniPieChart;
      default :
    }
  }, [chartType])

  const { t } = useTranslation();

  return (
    <Grid item xs={xs} sm={sm} md={md} lg={lg} key={fieldName}>
      <DashboardItem title={titleName}>
        {error && <div>{error.toString()}</div>}
        {isLoading && <div><center><ItemLoading progress={progress} /><Grid style={{padding: 10}}/></center></div>}
        {isLoading && <center>
          { progressType === 'linear' ? <LinearProgress /> : <CircularProgress /> }
        </center>}
        {resultSet && Component && <Component data={data} dataSeries={dataSeries} {...otherProps} /> }
        {
          resultSet && resultSet.loadResponse.slowQuery && (new Date().getTime() / 1000 - new Date(resultSet.loadResponse.results[0].lastRefreshTime).getTime() / 1000 > 300) &&
              <ThemeProvider theme={theme}><Typography style={{"marginTop": 10}} variant="body2" color="red" align="right" gutterBottom>{t('component.lastUpdatedAt')+": "+ format(new Date(resultSet.loadResponse.results[0].lastRefreshTime), 'yyyy/MM/dd HH:mm:ss')}</Typography></ThemeProvider>
        }
        {
          resultSet && (!resultSet.loadResponse.slowQuery || (resultSet.loadResponse.slowQuery && (new Date().getTime() / 1000 - new Date(resultSet.loadResponse.results[0].lastRefreshTime).getTime() / 1000 <= 300))) && 
          <ThemeProvider theme={theme}><Typography style={{"marginTop": 10}} variant="body2" color="textSecondary" align="right" gutterBottom>{t('component.lastUpdatedAt')+": "+ format(new Date(resultSet.loadResponse.results[0].lastRefreshTime), 'yyyy/MM/dd HH:mm:ss')}</Typography></ThemeProvider>
        }
      </DashboardItem>
    </Grid>
  )
}

export default ChartComponent;