import React, { useState, useContext } from "react";
import Header from '../../components/Header';
import TableComponent from '../../components/TableComponent';
import MultiSelectComponent from '../../components/MultiSelectComponent';
import DateRangePickerComponent from '../../components/DateRangePickerComponent';
import TextComponent from '../../components/TextComponent';
import Dashboard from '../../components/Dashboard';
import PageHeaderComponent from '../../components/PageHeaderComponent';
import { CubeJSContext } from '../../context/CubeJSProvider';
import OmnitechQueries from '../../queries/OmnitechQueries'
import { useTranslation } from 'react-i18next';
import { useCubeQuery } from '@cubejs-client/react';
import { setHours, setMinutes, setSeconds, setMilliseconds, format } from 'date-fns'

const applyFilter = (query, storeFilter, storeMember, startHour, endHour) => {
  let storeCodeFilterObj = {
    member: storeMember,
    operator: "contains",
    values: storeFilter
  }

  query = {
    ...query,
    filters: (query.filters || []).concat(storeCodeFilterObj)
  }

  if(startHour && endHour){
    if(startHour > endHour){
      let tmp = endHour
      endHour = startHour
      startHour = tmp
    }
    let offset = (new Date().getTimezoneOffset())/60
    let startHourOver = false
    let endHourOver = false
    if(offset < 0){
      if(startHour + offset < 0){
        startHour = startHour + offset + 24
        startHourOver = true
      }
      else{
        startHour = startHour + offset
      }
      if(endHour + offset < 0){
        endHour = endHour + offset + 24
        endHourOver = true
      }
      else
        endHour = endHour + offset
    }
    else if(offset > 0){
      if(startHour + offset > 23){
        startHour = startHour + offset - 24
        startHourOver = true
      }
      else
        startHour = startHour + offset
      if(endHour + offset > 23){
        endHour = endHour + offset - 24
        endHourOver = true
      }
      else
        endHour = endHour + offset
    }
  
    let HourFilterObj={}
    if((startHourOver && endHourOver) || (!startHourOver && !endHourOver)){
      HourFilterObj = {
        and: [
          {
            member: "MvOrdersSummaryEM.cartsCreatedAtHour",
            operator: "gte",
            values: [startHour.toString()]
          },
          {
            member: "MvOrdersSummaryEM.cartsCreatedAtHour",
            operator: "lte",
            values: [endHour.toString()]
          }
        ]
      }
    }
    else{
      HourFilterObj = {
        or: [
          {
            and: [
              {
                member: "MvOrdersSummaryEM.cartsCreatedAtHour",
                operator: "gte",
                values: [startHour.toString()]
              },
              {
                member: "MvOrdersSummaryEM.cartsCreatedAtHour",
                operator: "lte",
                values: ["24"]
              }
            ]
          },
          {
            and: [
              {
                member: "MvOrdersSummaryEM.cartsCreatedAtHour",
                operator: "gte",
                values: ["0"]
              },
              {
                member: "MvOrdersSummaryEM.cartsCreatedAtHour",
                operator: "lte",
                values: [endHour.toString()]
              }
            ]
          }
        ]
      }
    }
    query = {
      ...query,
      filters: (query.filters || []).concat(HourFilterObj)
    }
  }

  return query
}

const TimePeriodSummaryBreakfastDineInHistQuery = (storeFilter, startDateFilter, endDateFilter, start, end, isRefresh, setRefresh) => {
  const { t } = useTranslation();
  if(startDateFilter == null && endDateFilter == null){
    startDateFilter = new Date()
    startDateFilter.setHours(0,0,0,0);
    endDateFilter = new Date()
    endDateFilter.setHours(23,59,59,999);
  }
  else if(startDateFilter == null){
    startDateFilter = setHours(endDateFilter, 0)
    startDateFilter = setMinutes(startDateFilter, 0)
    startDateFilter = setSeconds(startDateFilter, 0)
    startDateFilter = setMilliseconds(startDateFilter, 0)
  }
  else if (endDateFilter == null){
    endDateFilter = setHours(startDateFilter, 23)
    endDateFilter = setMinutes(endDateFilter, 59)
    endDateFilter = setSeconds(endDateFilter, 59)
    endDateFilter = setMilliseconds(endDateFilter, 999)
  }
  let startDateString = new Date(startDateFilter.getTime() - (startDateFilter.getTimezoneOffset() * 60000 ))
                    .toISOString()
                    .split("T")[0];
  let endDateString = new Date(endDateFilter.getTime() - (endDateFilter.getTimezoneOffset() * 60000 ))
                    .toISOString()
                    .split("T")[0];
  let item = {
    name: t('page.fnb.timePeriodSummaryHistPage.timePeriodSummaryBreakfastDineIn.name'),
    tableHeaders: {
      "MvOrdersSummaryEM.count": { name: t('page.fnb.timePeriodSummaryHistPage.timePeriodSummaryBreakfastDineIn.tableHeader.count'), type: 'number'},
      "MvOrdersSummaryEM.sumDineInNumberOfPeople": { name: t('page.fnb.timePeriodSummaryHistPage.timePeriodSummaryBreakfastDineIn.tableHeader.sumDineInNumberOfPeople'), type: 'number'},
      "MvOrdersSummaryEM.avgBillAmount": { name: t('page.fnb.timePeriodSummaryHistPage.timePeriodSummaryBreakfastDineIn.tableHeader.avgBillAmount'), type: 'number'},
      "MvOrdersSummaryEM.sumNetSales": { name: t('page.fnb.timePeriodSummaryHistPage.timePeriodSummaryBreakfastDineIn.tableHeader.sumNetSales'), type: 'number'},
      "MvOrdersSummaryEM.perPersonSpending": { name: t('page.fnb.timePeriodSummaryHistPage.timePeriodSummaryBreakfastDineIn.tableHeader.perPersonSpending'), type: 'number'},
      "MvOrdersSummaryEM.cartsCreatedAt.day": { name: t('page.fnb.timePeriodSummaryHistPage.timePeriodSummaryBreakfastDineIn.tableHeader.cartsCreatedAt'), type: 'date'}
    },
    grid: {sm: 12, md: 6},
    export: { allowExport: true, filename: 'time_period_summary_breakfast_export_' + format(startDateFilter, 'yyyyMMddHHmmss') +
      '_' + format(endDateFilter, 'yyyyMMddHHmmss') }
  }
  let query = {
    ...OmnitechQueries.mvOrdersSummaryEM.TimePeriodDineInSummary.query,
    "timeDimensions": [
      {
        "dimension": "MvOrdersSummaryEM.cartsCreatedAt",
        "granularity": "day",
        "dateRange": [ startDateString + " 00:00:00",
         endDateString + " 23:59:59" ]
      }
    ],
    "timezone": "Asia/Hong_Kong"
  }

  query = applyFilter(query, storeFilter, "MvOrdersSummaryEM.shipFromStoreCode", start, end)
  if(isRefresh)
    query = {}
  const result = useCubeQuery(query, {resetResultSetOnChange: true})
  if(isRefresh)
    setRefresh(false)
  return { item, result }
}

const TimePeriodSummaryLunchDineInHistQuery = (storeFilter, startDateFilter, endDateFilter, start, end, isRefresh, setRefresh) => {
  const { t } = useTranslation();
  if(startDateFilter == null && endDateFilter == null){
    startDateFilter = new Date()
    startDateFilter.setHours(0,0,0,0);
    endDateFilter = new Date()
    endDateFilter.setHours(23,59,59,999);
  }
  else if(startDateFilter == null){
    startDateFilter = setHours(endDateFilter, 0)
    startDateFilter = setMinutes(startDateFilter, 0)
    startDateFilter = setSeconds(startDateFilter, 0)
    startDateFilter = setMilliseconds(startDateFilter, 0)
  }
  else if (endDateFilter == null){
    endDateFilter = setHours(startDateFilter, 23)
    endDateFilter = setMinutes(endDateFilter, 59)
    endDateFilter = setSeconds(endDateFilter, 59)
    endDateFilter = setMilliseconds(endDateFilter, 999)
  }
  let startDateString = new Date(startDateFilter.getTime() - (startDateFilter.getTimezoneOffset() * 60000 ))
                    .toISOString()
                    .split("T")[0];
  let endDateString = new Date(endDateFilter.getTime() - (endDateFilter.getTimezoneOffset() * 60000 ))
                    .toISOString()
                    .split("T")[0];
  let item = {
    name: t('page.fnb.timePeriodSummaryHistPage.timePeriodSummaryLunchDineIn.name'),
    tableHeaders: {
      "MvOrdersSummaryEM.count": { name: t('page.fnb.timePeriodSummaryHistPage.timePeriodSummaryLunchDineIn.tableHeader.count'), type: 'number'},
      "MvOrdersSummaryEM.sumDineInNumberOfPeople": { name: t('page.fnb.timePeriodSummaryHistPage.timePeriodSummaryLunchDineIn.tableHeader.sumDineInNumberOfPeople'), type: 'number'},
      "MvOrdersSummaryEM.avgBillAmount": { name: t('page.fnb.timePeriodSummaryHistPage.timePeriodSummaryLunchDineIn.tableHeader.avgBillAmount'), type: 'number'},
      "MvOrdersSummaryEM.sumNetSales": { name: t('page.fnb.timePeriodSummaryHistPage.timePeriodSummaryLunchDineIn.tableHeader.sumNetSales'), type: 'number'},
      "MvOrdersSummaryEM.perPersonSpending": { name: t('page.fnb.timePeriodSummaryHistPage.timePeriodSummaryLunchDineIn.tableHeader.perPersonSpending'), type: 'number'},
      "MvOrdersSummaryEM.cartsCreatedAt.day": { name: t('page.fnb.timePeriodSummaryHistPage.timePeriodSummaryLunchDineIn.tableHeader.cartsCreatedAt'), type: 'date'}
    },
    grid: {sm: 12, md: 6},
    export: { allowExport: true, filename: 'time_period_summary_lunch_export_' + format(startDateFilter, 'yyyyMMddHHmmss') +
      '_' + format(endDateFilter, 'yyyyMMddHHmmss') }
  }
  let query = {
    ...OmnitechQueries.mvOrdersSummaryEM.TimePeriodDineInSummary.query,
    "timeDimensions": [
      {
        "dimension": "MvOrdersSummaryEM.cartsCreatedAt",
        "granularity": "day",
        "dateRange": [ startDateString + " 00:00:00",
         endDateString + " 23:59:59" ]
      },
    ],
    "timezone": "Asia/Hong_Kong"
  }
  query = applyFilter(query, storeFilter, "MvOrdersSummaryEM.shipFromStoreCode", start, end)
  if(isRefresh)
    query = {}
  const result = useCubeQuery(query, {resetResultSetOnChange: true})
  if(isRefresh)
    setRefresh(false)
  return { item, result }
}

const TimePeriodSummaryTeaDineInHistQuery = (storeFilter, startDateFilter, endDateFilter, start, end, isRefresh, setRefresh) => {
  const { t } = useTranslation();
  if(startDateFilter == null && endDateFilter == null){
    startDateFilter = new Date()
    startDateFilter.setHours(0,0,0,0);
    endDateFilter = new Date()
    endDateFilter.setHours(23,59,59,999);
  }
  else if(startDateFilter == null){
    startDateFilter = setHours(endDateFilter, 0)
    startDateFilter = setMinutes(startDateFilter, 0)
    startDateFilter = setSeconds(startDateFilter, 0)
    startDateFilter = setMilliseconds(startDateFilter, 0)
  }
  else if (endDateFilter == null){
    endDateFilter = setHours(startDateFilter, 23)
    endDateFilter = setMinutes(endDateFilter, 59)
    endDateFilter = setSeconds(endDateFilter, 59)
    endDateFilter = setMilliseconds(endDateFilter, 999)
  }
  let startDateString = new Date(startDateFilter.getTime() - (startDateFilter.getTimezoneOffset() * 60000 ))
                    .toISOString()
                    .split("T")[0];
  let endDateString = new Date(endDateFilter.getTime() - (endDateFilter.getTimezoneOffset() * 60000 ))
                    .toISOString()
                    .split("T")[0];
  let item = {
    name: t('page.fnb.timePeriodSummaryHistPage.timePeriodSummaryTeaDineIn.name'),
    tableHeaders: {
      "MvOrdersSummaryEM.count": { name: t('page.fnb.timePeriodSummaryHistPage.timePeriodSummaryTeaDineIn.tableHeader.count'), type: 'number'},
      "MvOrdersSummaryEM.sumDineInNumberOfPeople": { name: t('page.fnb.timePeriodSummaryHistPage.timePeriodSummaryTeaDineIn.tableHeader.sumDineInNumberOfPeople'), type: 'number'},
      "MvOrdersSummaryEM.avgBillAmount": { name: t('page.fnb.timePeriodSummaryHistPage.timePeriodSummaryTeaDineIn.tableHeader.avgBillAmount'), type: 'number'},
      "MvOrdersSummaryEM.sumNetSales": { name: t('page.fnb.timePeriodSummaryHistPage.timePeriodSummaryTeaDineIn.tableHeader.sumNetSales'), type: 'number'},
      "MvOrdersSummaryEM.perPersonSpending": { name: t('page.fnb.timePeriodSummaryHistPage.timePeriodSummaryTeaDineIn.tableHeader.perPersonSpending'), type: 'number'},
      "MvOrdersSummaryEM.cartsCreatedAt.day": { name: t('page.fnb.timePeriodSummaryHistPage.timePeriodSummaryTeaDineIn.tableHeader.cartsCreatedAt'), type: 'date'}
    },
    grid: {sm: 12, md: 6},
    export: { allowExport: true, filename: 'time_period_summary_tea_export_' + format(startDateFilter, 'yyyyMMddHHmmss') +
      '_' + format(endDateFilter, 'yyyyMMddHHmmss') }
  }
  let query = {
    ...OmnitechQueries.mvOrdersSummaryEM.TimePeriodDineInSummary.query,
    "timeDimensions": [
      {
        "dimension": "MvOrdersSummaryEM.cartsCreatedAt",
        "granularity": "day",
        "dateRange": [ startDateString + " 00:00:00",
         endDateString + " 23:59:59" ]
      }
    ],
    "timezone": "Asia/Hong_Kong"
  }
  query = applyFilter(query, storeFilter, "MvOrdersSummaryEM.shipFromStoreCode", start, end)
  if(isRefresh)
    query = {}
  const result = useCubeQuery(query, {resetResultSetOnChange: true})
  if(isRefresh)
    setRefresh(false)
  return { item, result }
}

const TimePeriodSummaryDinnerDineInHistQuery = (storeFilter, startDateFilter, endDateFilter, start, end, isRefresh, setRefresh) => {
  const { t } = useTranslation();
  if(startDateFilter == null && endDateFilter == null){
    startDateFilter = new Date()
    startDateFilter.setHours(0,0,0,0);
    endDateFilter = new Date()
    endDateFilter.setHours(23,59,59,999);
  }
  else if(startDateFilter == null){
    startDateFilter = setHours(endDateFilter, 0)
    startDateFilter = setMinutes(startDateFilter, 0)
    startDateFilter = setSeconds(startDateFilter, 0)
    startDateFilter = setMilliseconds(startDateFilter, 0)
  }
  else if (endDateFilter == null){
    endDateFilter = setHours(startDateFilter, 23)
    endDateFilter = setMinutes(endDateFilter, 59)
    endDateFilter = setSeconds(endDateFilter, 59)
    endDateFilter = setMilliseconds(endDateFilter, 999)
  }
  let startDateString = new Date(startDateFilter.getTime() - (startDateFilter.getTimezoneOffset() * 60000 ))
                    .toISOString()
                    .split("T")[0];
  let endDateString = new Date(endDateFilter.getTime() - (endDateFilter.getTimezoneOffset() * 60000 ))
                    .toISOString()
                    .split("T")[0];
  let item = {
    name: t('page.fnb.timePeriodSummaryHistPage.timePeriodSummaryDinnerDineIn.name'),
    tableHeaders: {
      "MvOrdersSummaryEM.count": { name: t('page.fnb.timePeriodSummaryHistPage.timePeriodSummaryDinnerDineIn.tableHeader.count'), type: 'number'},
      "MvOrdersSummaryEM.sumDineInNumberOfPeople": { name: t('page.fnb.timePeriodSummaryHistPage.timePeriodSummaryDinnerDineIn.tableHeader.sumDineInNumberOfPeople'), type: 'number'},
      "MvOrdersSummaryEM.avgBillAmount": { name: t('page.fnb.timePeriodSummaryHistPage.timePeriodSummaryDinnerDineIn.tableHeader.avgBillAmount'), type: 'number'},
      "MvOrdersSummaryEM.sumNetSales": { name: t('page.fnb.timePeriodSummaryHistPage.timePeriodSummaryDinnerDineIn.tableHeader.sumNetSales'), type: 'number'},
      "MvOrdersSummaryEM.perPersonSpending": { name: t('page.fnb.timePeriodSummaryHistPage.timePeriodSummaryDinnerDineIn.tableHeader.perPersonSpending'), type: 'number'},
      "MvOrdersSummaryEM.cartsCreatedAt.day": { name: t('page.fnb.timePeriodSummaryHistPage.timePeriodSummaryDinnerDineIn.tableHeader.cartsCreatedAt'), type: 'date'}
    },
    grid: {sm: 12, md: 6},
    export: { allowExport: true, filename: 'time_period_summary_dinner_export_' + format(startDateFilter, 'yyyyMMddHHmmss') +
      '_' + format(endDateFilter, 'yyyyMMddHHmmss') }
  }
  let query = {
    ...OmnitechQueries.mvOrdersSummaryEM.TimePeriodDineInSummary.query,
    "timeDimensions": [
      {
        "dimension": "MvOrdersSummaryEM.cartsCreatedAt",
        "granularity": "day",
        "dateRange": [ startDateString + " 00:00:00",
         endDateString + " 23:59:59" ]
      },
    ],
    "timezone": "Asia/Hong_Kong"
  }
  query = applyFilter(query, storeFilter, "MvOrdersSummaryEM.shipFromStoreCode", start, end)
  if(isRefresh)
    query = {}
  const result = useCubeQuery(query, {resetResultSetOnChange: true})
  if(isRefresh)
    setRefresh(false)
  return { item, result }
}


const TimePeriodSummaryBreakfastTakeawayHistQuery = (storeFilter, startDateFilter, endDateFilter, start, end, isRefresh, setRefresh) => {
  const { t } = useTranslation();
  if(startDateFilter == null && endDateFilter == null){
    startDateFilter = new Date()
    startDateFilter.setHours(0,0,0,0);
    endDateFilter = new Date()
    endDateFilter.setHours(23,59,59,999);
  }
  else if(startDateFilter == null){
    startDateFilter = setHours(endDateFilter, 0)
    startDateFilter = setMinutes(startDateFilter, 0)
    startDateFilter = setSeconds(startDateFilter, 0)
    startDateFilter = setMilliseconds(startDateFilter, 0)
  }
  else if (endDateFilter == null){
    endDateFilter = setHours(startDateFilter, 23)
    endDateFilter = setMinutes(endDateFilter, 59)
    endDateFilter = setSeconds(endDateFilter, 59)
    endDateFilter = setMilliseconds(endDateFilter, 999)
  }
  let startDateString = new Date(startDateFilter.getTime() - (startDateFilter.getTimezoneOffset() * 60000 ))
                    .toISOString()
                    .split("T")[0];
  let endDateString = new Date(endDateFilter.getTime() - (endDateFilter.getTimezoneOffset() * 60000 ))
                    .toISOString()
                    .split("T")[0];
  let item = {
    name: t('page.fnb.timePeriodSummaryHistPage.timePeriodSummaryBreakfastTakeaway.name'),
    tableHeaders: {
      "MvOrdersSummaryEM.count": { name: t('page.fnb.timePeriodSummaryHistPage.timePeriodSummaryBreakfastTakeaway.tableHeader.count'), type: 'number'},
      "MvOrdersSummaryEM.sumDineInNumberOfPeople": { name: t('page.fnb.timePeriodSummaryHistPage.timePeriodSummaryBreakfastTakeaway.tableHeader.sumDineInNumberOfPeople'), type: 'number'},
      "MvOrdersSummaryEM.avgBillAmount": { name: t('page.fnb.timePeriodSummaryHistPage.timePeriodSummaryBreakfastTakeaway.tableHeader.avgBillAmount'), type: 'number'},
      "MvOrdersSummaryEM.sumNetSales": { name: t('page.fnb.timePeriodSummaryHistPage.timePeriodSummaryBreakfastTakeaway.tableHeader.sumNetSales'), type: 'number'},
      "MvOrdersSummaryEM.perPersonSpending": { name: t('page.fnb.timePeriodSummaryHistPage.timePeriodSummaryBreakfastTakeaway.tableHeader.perPersonSpending'), type: 'number'},
      "MvOrdersSummaryEM.cartsCreatedAt.day": { name: t('page.fnb.timePeriodSummaryHistPage.timePeriodSummaryBreakfastTakeaway.tableHeader.cartsCreatedAt'), type: 'date'}
    },
    grid: {sm: 12, md: 6},
    export: { allowExport: true, filename: 'time_period_summary_breakfast_export_' + format(startDateFilter, 'yyyyMMddHHmmss') +
      '_' + format(endDateFilter, 'yyyyMMddHHmmss') }
  }
  let query = {
    ...OmnitechQueries.mvOrdersSummaryEM.TimePeriodTakeawaySummary.query,
    "timeDimensions": [
      {
        "dimension": "MvOrdersSummaryEM.cartsCreatedAt",
        "granularity": "day",
        "dateRange": [ startDateString + " 00:00:00",
         endDateString + " 23:59:59" ]
      }
    ],
    "timezone": "Asia/Hong_Kong"
  }

  query = applyFilter(query, storeFilter, "MvOrdersSummaryEM.shipFromStoreCode", start, end)
  if(isRefresh)
    query = {}
  const result = useCubeQuery(query, {resetResultSetOnChange: true})
  if(isRefresh)
    setRefresh(false)
  return { item, result }
}

const TimePeriodSummaryLunchTakeawayHistQuery = (storeFilter, startDateFilter, endDateFilter, start, end, isRefresh, setRefresh) => {
  const { t } = useTranslation();
  if(startDateFilter == null && endDateFilter == null){
    startDateFilter = new Date()
    startDateFilter.setHours(0,0,0,0);
    endDateFilter = new Date()
    endDateFilter.setHours(23,59,59,999);
  }
  else if(startDateFilter == null){
    startDateFilter = setHours(endDateFilter, 0)
    startDateFilter = setMinutes(startDateFilter, 0)
    startDateFilter = setSeconds(startDateFilter, 0)
    startDateFilter = setMilliseconds(startDateFilter, 0)
  }
  else if (endDateFilter == null){
    endDateFilter = setHours(startDateFilter, 23)
    endDateFilter = setMinutes(endDateFilter, 59)
    endDateFilter = setSeconds(endDateFilter, 59)
    endDateFilter = setMilliseconds(endDateFilter, 999)
  }
  let startDateString = new Date(startDateFilter.getTime() - (startDateFilter.getTimezoneOffset() * 60000 ))
                    .toISOString()
                    .split("T")[0];
  let endDateString = new Date(endDateFilter.getTime() - (endDateFilter.getTimezoneOffset() * 60000 ))
                    .toISOString()
                    .split("T")[0];
  let item = {
    name: t('page.fnb.timePeriodSummaryHistPage.timePeriodSummaryLunchTakeaway.name'),
    tableHeaders: {
      "MvOrdersSummaryEM.count": { name: t('page.fnb.timePeriodSummaryHistPage.timePeriodSummaryLunchTakeaway.tableHeader.count'), type: 'number'},
      "MvOrdersSummaryEM.sumDineInNumberOfPeople": { name: t('page.fnb.timePeriodSummaryHistPage.timePeriodSummaryLunchTakeaway.tableHeader.sumDineInNumberOfPeople'), type: 'number'},
      "MvOrdersSummaryEM.avgBillAmount": { name: t('page.fnb.timePeriodSummaryHistPage.timePeriodSummaryLunchTakeaway.tableHeader.avgBillAmount'), type: 'number'},
      "MvOrdersSummaryEM.sumNetSales": { name: t('page.fnb.timePeriodSummaryHistPage.timePeriodSummaryLunchTakeaway.tableHeader.sumNetSales'), type: 'number'},
      "MvOrdersSummaryEM.perPersonSpending": { name: t('page.fnb.timePeriodSummaryHistPage.timePeriodSummaryLunchTakeaway.tableHeader.perPersonSpending'), type: 'number'},
      "MvOrdersSummaryEM.cartsCreatedAt.day": { name: t('page.fnb.timePeriodSummaryHistPage.timePeriodSummaryLunchTakeaway.tableHeader.cartsCreatedAt'), type: 'date'}
    },
    grid: {sm: 12, md: 6},
    export: { allowExport: true, filename: 'time_period_summary_lunch_export_' + format(startDateFilter, 'yyyyMMddHHmmss') +
      '_' + format(endDateFilter, 'yyyyMMddHHmmss') }
  }
  let query = {
    ...OmnitechQueries.mvOrdersSummaryEM.TimePeriodTakeawaySummary.query,
    "timeDimensions": [
      {
        "dimension": "MvOrdersSummaryEM.cartsCreatedAt",
        "granularity": "day",
        "dateRange": [ startDateString + " 00:00:00",
         endDateString + " 23:59:59" ]
      },
    ],
    "timezone": "Asia/Hong_Kong"
  }
  query = applyFilter(query, storeFilter, "MvOrdersSummaryEM.shipFromStoreCode", start, end)
  if(isRefresh)
    query = {}
  const result = useCubeQuery(query, {resetResultSetOnChange: true})
  if(isRefresh)
    setRefresh(false)
  return { item, result }
}

const TimePeriodSummaryTeaTakeawayHistQuery = (storeFilter, startDateFilter, endDateFilter, start, end, isRefresh, setRefresh) => {
  const { t } = useTranslation();
  if(startDateFilter == null && endDateFilter == null){
    startDateFilter = new Date()
    startDateFilter.setHours(0,0,0,0);
    endDateFilter = new Date()
    endDateFilter.setHours(23,59,59,999);
  }
  else if(startDateFilter == null){
    startDateFilter = setHours(endDateFilter, 0)
    startDateFilter = setMinutes(startDateFilter, 0)
    startDateFilter = setSeconds(startDateFilter, 0)
    startDateFilter = setMilliseconds(startDateFilter, 0)
  }
  else if (endDateFilter == null){
    endDateFilter = setHours(startDateFilter, 23)
    endDateFilter = setMinutes(endDateFilter, 59)
    endDateFilter = setSeconds(endDateFilter, 59)
    endDateFilter = setMilliseconds(endDateFilter, 999)
  }
  let startDateString = new Date(startDateFilter.getTime() - (startDateFilter.getTimezoneOffset() * 60000 ))
                    .toISOString()
                    .split("T")[0];
  let endDateString = new Date(endDateFilter.getTime() - (endDateFilter.getTimezoneOffset() * 60000 ))
                    .toISOString()
                    .split("T")[0];
  let item = {
    name: t('page.fnb.timePeriodSummaryHistPage.timePeriodSummaryTeaTakeaway.name'),
    tableHeaders: {
      "MvOrdersSummaryEM.count": { name: t('page.fnb.timePeriodSummaryHistPage.timePeriodSummaryTeaTakeaway.tableHeader.count'), type: 'number'},
      "MvOrdersSummaryEM.sumDineInNumberOfPeople": { name: t('page.fnb.timePeriodSummaryHistPage.timePeriodSummaryTeaTakeaway.tableHeader.sumDineInNumberOfPeople'), type: 'number'},
      "MvOrdersSummaryEM.avgBillAmount": { name: t('page.fnb.timePeriodSummaryHistPage.timePeriodSummaryTeaTakeaway.tableHeader.avgBillAmount'), type: 'number'},
      "MvOrdersSummaryEM.sumNetSales": { name: t('page.fnb.timePeriodSummaryHistPage.timePeriodSummaryTeaTakeaway.tableHeader.sumNetSales'), type: 'number'},
      "MvOrdersSummaryEM.perPersonSpending": { name: t('page.fnb.timePeriodSummaryHistPage.timePeriodSummaryTeaTakeaway.tableHeader.perPersonSpending'), type: 'number'},
      "MvOrdersSummaryEM.cartsCreatedAt.day": { name: t('page.fnb.timePeriodSummaryHistPage.timePeriodSummaryTeaTakeaway.tableHeader.cartsCreatedAt'), type: 'date'}
    },
    grid: {sm: 12, md: 6},
    export: { allowExport: true, filename: 'time_period_summary_tea_export_' + format(startDateFilter, 'yyyyMMddHHmmss') +
      '_' + format(endDateFilter, 'yyyyMMddHHmmss') }
  }
  let query = {
    ...OmnitechQueries.mvOrdersSummaryEM.TimePeriodTakeawaySummary.query,
    "timeDimensions": [
      {
        "dimension": "MvOrdersSummaryEM.cartsCreatedAt",
        "granularity": "day",
        "dateRange": [ startDateString + " 00:00:00",
         endDateString + " 23:59:59" ]
      }
    ],
    "timezone": "Asia/Hong_Kong"
  }
  query = applyFilter(query, storeFilter, "MvOrdersSummaryEM.shipFromStoreCode", start, end)
  if(isRefresh)
    query = {}
  const result = useCubeQuery(query, {resetResultSetOnChange: true})
  if(isRefresh)
    setRefresh(false)
  return { item, result }
}

const TimePeriodSummaryDinnerTakeawayHistQuery = (storeFilter, startDateFilter, endDateFilter, start, end, isRefresh, setRefresh) => {
  const { t } = useTranslation();
  if(startDateFilter == null && endDateFilter == null){
    startDateFilter = new Date()
    startDateFilter.setHours(0,0,0,0);
    endDateFilter = new Date()
    endDateFilter.setHours(23,59,59,999);
  }
  else if(startDateFilter == null){
    startDateFilter = setHours(endDateFilter, 0)
    startDateFilter = setMinutes(startDateFilter, 0)
    startDateFilter = setSeconds(startDateFilter, 0)
    startDateFilter = setMilliseconds(startDateFilter, 0)
  }
  else if (endDateFilter == null){
    endDateFilter = setHours(startDateFilter, 23)
    endDateFilter = setMinutes(endDateFilter, 59)
    endDateFilter = setSeconds(endDateFilter, 59)
    endDateFilter = setMilliseconds(endDateFilter, 999)
  }
  let startDateString = new Date(startDateFilter.getTime() - (startDateFilter.getTimezoneOffset() * 60000 ))
                    .toISOString()
                    .split("T")[0];
  let endDateString = new Date(endDateFilter.getTime() - (endDateFilter.getTimezoneOffset() * 60000 ))
                    .toISOString()
                    .split("T")[0];
  let item = {
    name: t('page.fnb.timePeriodSummaryHistPage.timePeriodSummaryDinnerTakeaway.name'),
    tableHeaders: {
      "MvOrdersSummaryEM.count": { name: t('page.fnb.timePeriodSummaryHistPage.timePeriodSummaryDinnerTakeaway.tableHeader.count'), type: 'number'},
      "MvOrdersSummaryEM.sumDineInNumberOfPeople": { name: t('page.fnb.timePeriodSummaryHistPage.timePeriodSummaryDinnerTakeaway.tableHeader.sumDineInNumberOfPeople'), type: 'number'},
      "MvOrdersSummaryEM.avgBillAmount": { name: t('page.fnb.timePeriodSummaryHistPage.timePeriodSummaryDinnerTakeaway.tableHeader.avgBillAmount'), type: 'number'},
      "MvOrdersSummaryEM.sumNetSales": { name: t('page.fnb.timePeriodSummaryHistPage.timePeriodSummaryDinnerTakeaway.tableHeader.sumNetSales'), type: 'number'},
      "MvOrdersSummaryEM.perPersonSpending": { name: t('page.fnb.timePeriodSummaryHistPage.timePeriodSummaryDinnerTakeaway.tableHeader.perPersonSpending'), type: 'number'},
      "MvOrdersSummaryEM.cartsCreatedAt.day": { name: t('page.fnb.timePeriodSummaryHistPage.timePeriodSummaryDinnerTakeaway.tableHeader.cartsCreatedAt'), type: 'date'}
    },
    grid: {sm: 12, md: 6},
    export: { allowExport: true, filename: 'time_period_summary_dinner_export_' + format(startDateFilter, 'yyyyMMddHHmmss') +
      '_' + format(endDateFilter, 'yyyyMMddHHmmss') }
  }
  let query = {
    ...OmnitechQueries.mvOrdersSummaryEM.TimePeriodTakeawaySummary.query,
    "timeDimensions": [
      {
        "dimension": "MvOrdersSummaryEM.cartsCreatedAt",
        "granularity": "day",
        "dateRange": [ startDateString + " 00:00:00",
         endDateString + " 23:59:59" ]
      },
    ],
    "timezone": "Asia/Hong_Kong"
  }
  query = applyFilter(query, storeFilter, "MvOrdersSummaryEM.shipFromStoreCode", start, end)
  if(isRefresh)
    query = {}
  const result = useCubeQuery(query, {resetResultSetOnChange: true})
  if(isRefresh)
    setRefresh(false)
  return { item, result }
}

const TimePeriodSummaryDetailHistQuery = (storeFilter, startDateFilter, endDateFilter, isRefresh, setRefresh) => {
  const { t } = useTranslation();
  if(startDateFilter == null && endDateFilter == null){
    startDateFilter = new Date()
    startDateFilter.setHours(0,0,0,0);
    endDateFilter = new Date()
    endDateFilter.setHours(23,59,59,999);
  }
  else if(startDateFilter == null){
    startDateFilter = setHours(endDateFilter, 0)
    startDateFilter = setMinutes(startDateFilter, 0)
    startDateFilter = setSeconds(startDateFilter, 0)
    startDateFilter = setMilliseconds(startDateFilter, 0)
  }
  else if (endDateFilter == null){
    endDateFilter = setHours(startDateFilter, 23)
    endDateFilter = setMinutes(endDateFilter, 59)
    endDateFilter = setSeconds(endDateFilter, 59)
    endDateFilter = setMilliseconds(endDateFilter, 999)
  }

  let startDateString = new Date(startDateFilter.getTime() - (startDateFilter.getTimezoneOffset() * 60000 ))
                    .toISOString()
                    .split("T")[0];
  let endDateString = new Date(endDateFilter.getTime() - (endDateFilter.getTimezoneOffset() * 60000 ))
                    .toISOString()
                    .split("T")[0];

  let item = {
    name: t('page.fnb.timePeriodSummaryHistPage.timePeriodSummaryDetail.name'),
    tableHeaders: {
      "MvOrdersSummaryEM.shipFromStoreCode": { name: t('page.fnb.timePeriodSummaryHistPage.timePeriodSummaryDetail.tableHeader.shipFromStoreCode'), type: 'string'},
      "MvOrdersSummaryEM.commerceChannelCode": { name: t('page.fnb.timePeriodSummaryHistPage.timePeriodSummaryDetail.tableHeader.commerceChannelCode'), type: 'strinng'},
      "MvOrdersSummaryEM.cartsCreatedAtHour": { name: t('page.fnb.timePeriodSummaryHistPage.timePeriodSummaryDetail.tableHeader.cartsCreatedAtHour'), 
        type: 'offsetNumber', offset: (new Date().getTimezoneOffset())/60},
      "MvOrdersSummaryEM.cartsCreatedAt.hour": { name: t('page.fnb.timePeriodSummaryHistPage.timePeriodSummaryDetail.tableHeader.cartsCreatedAtHour'), 
        type: 'hkHour'},
      "MvOrdersSummaryEM.orderSource": { name: t('page.fnb.timePeriodSummaryHistPage.timePeriodSummaryDetail.tableHeader.orderSource'), type: 'strinng'},
      "MvOrdersSummaryEM.sumGrossSales": { name: t('page.fnb.timePeriodSummaryHistPage.timePeriodSummaryDetail.tableHeader.sumGrossSales'), type: 'number'},
      "MvOrdersSummaryEM.sumDiscounts": { name: t('page.fnb.timePeriodSummaryHistPage.timePeriodSummaryDetail.tableHeader.sumDiscounts'), type: 'number'},
      "MvOrdersSummaryEM.sumShippingFees": { name: t('page.fnb.timePeriodSummaryHistPage.timePeriodSummaryDetail.tableHeader.sumShippingFees'), type: 'number'},
      "MvOrdersSummaryEM.sumServiceCharges": { name: t('page.fnb.timePeriodSummaryHistPage.timePeriodSummaryDetail.tableHeader.sumServiceCharges'), type: 'number'},
      "MvOrdersSummaryEM.sumOrderAmountRounding": { name: t('page.fnb.timePeriodSummaryHistPage.timePeriodSummaryDetail.tableHeader.sumOrderAmountRounding'), type: 'number'},
      "MvOrdersSummaryEM.sumMembershipCharges": { name: t('page.fnb.timePeriodSummaryHistPage.timePeriodSummaryDetail.tableHeader.sumMembershipCharges'), type: 'number'},
      "MvOrdersSummaryEM.sumOrderTotal": { name: t('page.fnb.timePeriodSummaryHistPage.timePeriodSummaryDetail.tableHeader.sumOrderTotal'), type: 'number'},
      "MvOrdersSummaryEM.count": { name: t('page.fnb.timePeriodSummaryHistPage.timePeriodSummaryDetail.tableHeader.count'), type: 'number'},
      "MvOrdersSummaryEM.sumDineInNumberOfPeople": { name: t('page.fnb.timePeriodSummaryHistPage.timePeriodSummaryDetail.tableHeader.sumDineInNumberOfPeople'), type: 'number'},
      "MvOrdersSummaryEM.avgBillAmount": { name: t('page.fnb.timePeriodSummaryHistPage.timePeriodSummaryDetail.tableHeader.avgBillAmount'), type: 'number'},
      "MvOrdersSummaryEM.sumNetSales": { name: t('page.fnb.timePeriodSummaryHistPage.timePeriodSummaryDetail.tableHeader.sumNetSales'), type: 'number'},
      "MvOrdersSummaryEM.perPersonSpending": { name: t('page.fnb.timePeriodSummaryHistPage.timePeriodSummaryDetail.tableHeader.perPersonSpending'), type: 'number'}
    },
    grid: {xs: 12},
    export: { allowExport: true, filename: 'time_period_summary_detail_export_' + format(startDateFilter, 'yyyyMMddHHmmss') +
      '_' + format(endDateFilter, 'yyyyMMddHHmmss') }
  }
  let query = {
    ...OmnitechQueries.mvOrdersSummaryEM.TimePeriodSummaryDetail.query,
    "timeDimensions": [
      {
        "dimension": "MvOrdersSummaryEM.cartsCreatedAt",
        "granularity": 'hour',
        "dateRange": [ startDateString + " 00:00:00",
         endDateString + " 23:59:59" ]
      }
    ],
    "timezone": "Asia/Hong_Kong",
  }
  query = applyFilter(query, storeFilter, "MvOrdersSummaryEM.shipFromStoreCode", null, null)
  if(isRefresh)
    query = {}
  const result = useCubeQuery(query, {resetResultSetOnChange: true})
  if(isRefresh)
    setRefresh(false)
  return { item, result }
}

const FnBTotalAndDiscountBreakDownQuery = (storeFilter, startDateFilter, endDateFilter, isRefresh, setRefresh) => {
  const { t } = useTranslation();
  if(startDateFilter == null && endDateFilter == null){
    startDateFilter = new Date()
    startDateFilter.setHours(0,0,0,0);
    endDateFilter = new Date()
    endDateFilter.setHours(23,59,59,999);
  }
  else if(startDateFilter == null){
    startDateFilter = setHours(endDateFilter, 0)
    startDateFilter = setMinutes(startDateFilter, 0)
    startDateFilter = setSeconds(startDateFilter, 0)
    startDateFilter = setMilliseconds(startDateFilter, 0)
  }
  else if (endDateFilter == null){
    endDateFilter = setHours(startDateFilter, 23)
    endDateFilter = setMinutes(endDateFilter, 59)
    endDateFilter = setSeconds(endDateFilter, 59)
    endDateFilter = setMilliseconds(endDateFilter, 999)
  }

  let startDateString = new Date(startDateFilter.getTime() - (startDateFilter.getTimezoneOffset() * 60000 ))
                    .toISOString()
                    .split("T")[0];
  let endDateString = new Date(endDateFilter.getTime() - (endDateFilter.getTimezoneOffset() * 60000 ))
                    .toISOString()
                    .split("T")[0];

  let item = {
    name: t('page.fnb.timePeriodSummaryHistPage.FnBTotalAndDiscountBreakDown.name'),
    tableHeaders: {
      "FnBTotalAndDiscountBreakDownMV.storeCode": { name: t('page.fnb.timePeriodSummaryHistPage.FnBTotalAndDiscountBreakDown.tableHeader.storeCode'), type: 'string'},
      "FnBTotalAndDiscountBreakDownMV.openTableTime.hour": { name: t('page.fnb.timePeriodSummaryHistPage.FnBTotalAndDiscountBreakDown.tableHeader.openTableTime'), 
        type: 'hkHour'},
      "FnBTotalAndDiscountBreakDownMV.dineInTableNumber": { name: t('page.fnb.timePeriodSummaryHistPage.FnBTotalAndDiscountBreakDown.tableHeader.dineInTableNumber'), type: 'string'},
      "FnBTotalAndDiscountBreakDownMV.serviceMode": { name: t('page.fnb.timePeriodSummaryHistPage.FnBTotalAndDiscountBreakDown.tableHeader.serviceMode'), type: 'string'},
      "FnBTotalAndDiscountBreakDownMV.commerceChannelCode": { name: t('page.fnb.timePeriodSummaryHistPage.FnBTotalAndDiscountBreakDown.tableHeader.commerceChannelCode'), type: 'string'},
      "FnBTotalAndDiscountBreakDownMV.orderGrossTotal": { name: t('page.fnb.timePeriodSummaryHistPage.FnBTotalAndDiscountBreakDown.tableHeader.orderGrossTotal'), type: 'number'},
      "FnBTotalAndDiscountBreakDownMV.foodGrossTotal": { name: t('page.fnb.timePeriodSummaryHistPage.FnBTotalAndDiscountBreakDown.tableHeader.foodGrossTotal'), type: 'number'},
      "FnBTotalAndDiscountBreakDownMV.bvGrossTotal": { name: t('page.fnb.timePeriodSummaryHistPage.FnBTotalAndDiscountBreakDown.tableHeader.bvGrossTotal'), type: 'number'},
      "FnBTotalAndDiscountBreakDownMV.rounding": { name: t('page.fnb.timePeriodSummaryHistPage.FnBTotalAndDiscountBreakDown.tableHeader.rounding'), type: 'number'},
      "FnBTotalAndDiscountBreakDownMV.serviceCharge": { name: t('page.fnb.timePeriodSummaryHistPage.FnBTotalAndDiscountBreakDown.tableHeader.serviceCharge'), type: 'number'},
      "FnBTotalAndDiscountBreakDownMV.discountTotal": { name: t('page.fnb.timePeriodSummaryHistPage.FnBTotalAndDiscountBreakDown.tableHeader.discountTotal'), type: 'number'},
      "FnBTotalAndDiscountBreakDownMV.allotedFoodDiscount": { name: t('page.fnb.timePeriodSummaryHistPage.FnBTotalAndDiscountBreakDown.tableHeader.allotedFoodDiscount'), type: 'number'},
      "FnBTotalAndDiscountBreakDownMV.allotedBvDiscount": { name: t('page.fnb.timePeriodSummaryHistPage.FnBTotalAndDiscountBreakDown.tableHeader.allotedBvDiscount'), type: 'number'},
      "FnBTotalAndDiscountBreakDownMV.unallottableDiscount": { name: t('page.fnb.timePeriodSummaryHistPage.FnBTotalAndDiscountBreakDown.tableHeader.unallottableDiscount'), type: 'number'},
      "FnBTotalAndDiscountBreakDownMV.orderNetTotal": { name: t('page.fnb.timePeriodSummaryHistPage.FnBTotalAndDiscountBreakDown.tableHeader.orderNetTotal'), type: 'number'},
    },
    grid: {xs: 12},
    export: { allowExport: true, filename: 'fnb_total_and_discount_breakdown_export_' + format(startDateFilter, 'yyyyMMddHHmmss') +
      '_' + format(endDateFilter, 'yyyyMMddHHmmss') }
  }
  let query = {
    ...OmnitechQueries.mvOrdersSummaryEM.FnBTotalAndDiscountBreakDown.query,
    "timeDimensions": [
      {
        "dimension": "FnBTotalAndDiscountBreakDownMV.openTableTime",
        "granularity": 'hour',
        "dateRange": [ startDateString + " 00:00:00",
         endDateString + " 23:59:59" ]
      }
    ],
    "timezone": "Asia/Hong_Kong",
  }
  query = applyFilter(query, storeFilter, "FnBTotalAndDiscountBreakDownMV.storeCode", null, null, null)
  if(isRefresh)
    query = {}
  const result = useCubeQuery(query, {resetResultSetOnChange: true})
  if(isRefresh)
    setRefresh(false)
  return { item, result }
}

const DiscountBreakdownQuery = (storeFilter, startDateFilter, endDateFilter, isRefresh, setRefresh) => {
  const { t } = useTranslation();
  if(startDateFilter == null && endDateFilter == null){
    startDateFilter = new Date()
    startDateFilter.setHours(0,0,0,0);
    endDateFilter = new Date()
    endDateFilter.setHours(23,59,59,999);
  }
  else if(startDateFilter == null){
    startDateFilter = setHours(endDateFilter, 0)
    startDateFilter = setMinutes(startDateFilter, 0)
    startDateFilter = setSeconds(startDateFilter, 0)
    startDateFilter = setMilliseconds(startDateFilter, 0)
  }
  else if (endDateFilter == null){
    endDateFilter = setHours(startDateFilter, 23)
    endDateFilter = setMinutes(endDateFilter, 59)
    endDateFilter = setSeconds(endDateFilter, 59)
    endDateFilter = setMilliseconds(endDateFilter, 999)
  }
  let item = {
    name: t('page.fnb.timePeriodSummaryHistPage.discountBreakdown.name'),
    tableHeaders: {
      "MvOrderTransactions.shipFromStoreCode": { name: t('page.fnb.timePeriodSummaryHistPage.discountBreakdown.tableHeader.shipFromStoreCode'), type: 'string'},
      "MvOrderTransactions.discountCode": { name: t('page.fnb.timePeriodSummaryHistPage.discountBreakdown.tableHeader.discountCode'), type: 'string'},
      "MvOrderTransactions.discountNameEnHk": { name: t('page.fnb.timePeriodSummaryHistPage.discountBreakdown.tableHeader.discountNameEnHk'), type: 'string'},
      "MvOrderTransactions.discountNameZhHk":  { name: t('page.fnb.timePeriodSummaryHistPage.discountBreakdown.tableHeader.discountNameZhHk'), type: "string" },
      "MvOrderTransactions.sumLineTotal": { name: t('page.fnb.timePeriodSummaryHistPage.discountBreakdown.tableHeader.lineTotal'), type: 'number'},
      "MvOrderTransactions.sumQuantity": { name: t('page.fnb.timePeriodSummaryHistPage.discountBreakdown.tableHeader.quantity'), type: 'number'},
      "Orders.totalPrice": { name: t('page.fnb.timePeriodSummaryHistPage.discountBreakdown.tableHeader.totalPrice'), type: 'number'},
    },
    grid: {xs: 12},
    export: { allowExport: true, filename: 'discount_breakdown_export_' + format(startDateFilter, 'yyyyMMddHHmmss') +
      '_' + format(endDateFilter, 'yyyyMMddHHmmss') }
  }
  let query = {
    ...OmnitechQueries.mvOrderTransactions.DiscountBreakdown.query,
    "timeDimensions": [
      {
        "dimension": "MvOrderTransactions.ordersCompletedAt",
        "dateRange": [ startDateFilter, endDateFilter ]
      }
    ],
  }
  query = applyFilter(query, storeFilter, "MvOrderTransactions.shipFromStoreCode", null, null)
  if(isRefresh)
    query = {}
  const result = useCubeQuery(query, {resetResultSetOnChange: true})
  if(isRefresh)
    setRefresh(false)
  return { item, result }
}

const TimePeriodSummaryHistPage = () => {
  const { t } = useTranslation();
  const context = useContext(CubeJSContext);
  let breakfastStart = context.breakfastStart ? context.breakfastStart : 6
  let breakfastEnd = context.breakfastEnd ? context.breakfastEnd : 10
  let lunchStart = context.lunchStart ? context.lunchStart : 11
  let lunchEnd = context.lunchEnd ? context.lunchEnd : 14
  let teaStart = context.teaStart ? context.teaStart : 15
  let teaEnd = context.teaEnd ? context.teaEnd : 17
  let dinnerStart = context.dinnerStart ? context.dinnerStart : 18
  let dinnerEnd = context.dinnerEnd ? context.dinnerEnd : 23
  let storeCodeSelect = []
  if(context.storeCodes)
    storeCodeSelect = context.storeCodes
  const [ storeFilter, setStoreFilter ] = useState([]);
  let filterStartDate = new Date()
  filterStartDate.setHours(0,0,0,0);
  let filterEndDate = new Date()
  filterEndDate.setHours(23,59,59,999);
  const [dateRangeFilter, setDateRangeFilter] = useState([filterStartDate, filterEndDate])
  const [startDateFilter, endDateFilter] = dateRangeFilter
  const [rowsPerPage1, setRowsPerPage1] = useState(10);
  const [page1, setPage1] = useState(0);
  const [rowsPerPage2, setRowsPerPage2] = useState(10);
  const [page2, setPage2] = useState(0);
  const [rowsPerPage3, setRowsPerPage3] = useState(10);
  const [page3, setPage3] = useState(0);
  const [rowsPerPage4, setRowsPerPage4] = useState(10);
  const [page4, setPage4] = useState(0);
  const [rowsPerPage5, setRowsPerPage5] = useState(10);
  const [page5, setPage5] = useState(0);
  const [rowsPerPage6, setRowsPerPage6] = useState(10);
  const [page6, setPage6] = useState(0);

  const [rowsPerPage7, setRowsPerPage7] = useState(10);
  const [page7, setPage7] = useState(0);
  const [rowsPerPage8, setRowsPerPage8] = useState(10);
  const [page8, setPage8] = useState(0);
  const [rowsPerPage9, setRowsPerPage9] = useState(10);
  const [page9, setPage9] = useState(0);
  const [rowsPerPage10, setRowsPerPage10] = useState(10);
  const [page10, setPage10] = useState(0);
  const [rowsPerPage11, setRowsPerPage11] = useState(10);
  const [page11, setPage11] = useState(0);

  const [ refresh1, setRefresh1 ] = useState(false);
  const [ refresh2, setRefresh2 ] = useState(false);
  const [ refresh3, setRefresh3 ] = useState(false);
  const [ refresh4, setRefresh4 ] = useState(false);
  const [ refresh5, setRefresh5 ] = useState(false);
  const [ refresh6, setRefresh6 ] = useState(false);
  
  const [ refresh7, setRefresh7 ] = useState(false);
  const [ refresh8, setRefresh8 ] = useState(false);
  const [ refresh9, setRefresh9 ] = useState(false);
  const [ refresh10, setRefresh10 ] = useState(false);
  const [ refresh11, setRefresh11 ] = useState(false);

  const query1 = TimePeriodSummaryBreakfastDineInHistQuery(storeFilter, startDateFilter, endDateFilter, breakfastStart, breakfastEnd, refresh1, setRefresh1)
  const query2 = TimePeriodSummaryLunchDineInHistQuery(storeFilter, startDateFilter, endDateFilter, lunchStart, lunchEnd, refresh2, setRefresh2)
  const query3 = TimePeriodSummaryTeaDineInHistQuery(storeFilter, startDateFilter, endDateFilter, teaStart, teaEnd, refresh3, setRefresh3)
  const query4 = TimePeriodSummaryDinnerDineInHistQuery(storeFilter, startDateFilter, endDateFilter, dinnerStart, dinnerEnd, refresh4, setRefresh4)
  const query5 = TimePeriodSummaryDetailHistQuery(storeFilter, startDateFilter, endDateFilter, refresh5, setRefresh5)
  const query6 = DiscountBreakdownQuery(storeFilter, startDateFilter, endDateFilter, refresh6, setRefresh6)
  const query7 = TimePeriodSummaryBreakfastTakeawayHistQuery(storeFilter, startDateFilter, endDateFilter, breakfastStart, breakfastEnd, refresh7, setRefresh7)
  const query8 = TimePeriodSummaryLunchTakeawayHistQuery(storeFilter, startDateFilter, endDateFilter, lunchStart, lunchEnd, refresh8, setRefresh8)
  const query9 = TimePeriodSummaryTeaTakeawayHistQuery(storeFilter, startDateFilter, endDateFilter, teaStart, teaEnd, refresh9, setRefresh9)
  const query10 = TimePeriodSummaryDinnerTakeawayHistQuery(storeFilter, startDateFilter, endDateFilter, dinnerStart, dinnerEnd, refresh10, setRefresh10)
  const query11 = FnBTotalAndDiscountBreakDownQuery(storeFilter, startDateFilter, endDateFilter, refresh11, setRefresh11)

  const refresh = () => {
    setRefresh1(true)
    setRefresh2(true)
    setRefresh3(true)
    setRefresh4(true)
    setRefresh5(true)
    setRefresh6(true)
    setRefresh7(true)
    setRefresh8(true)
    setRefresh9(true)
    setRefresh10(true)
    setRefresh11(true)
    setPage1(0)
    setPage2(0)
    setPage3(0)
    setPage4(0)
    setPage5(0)
    setPage6(0)
    setPage7(0)
    setPage8(0)
    setPage9(0)
    setPage10(0)
    setPage11(0)
  }


  return ([
    <Header />,
    <PageHeaderComponent onClickFunc={refresh} refreshlabel={t('component.refresh')} headerTitle={t('component.header.menu.timePeriodSummaryHist')} />,
    <Dashboard>
      <MultiSelectComponent id={"store_code_filter"} state={storeFilter} pageChangerArr={[setPage1,setPage2,setPage3,setPage4,setPage5,setPage6,
        setPage7,setPage8,setPage9,setPage10,setPage11]}
          stateChanger={setStoreFilter} textLabel={t('page.fnb.timePeriodSummaryHistPage.filter.store.name')} selectArray={storeCodeSelect}/>
      <DateRangePickerComponent state={dateRangeFilter} stateChanger={setDateRangeFilter} 
        pageChangerArr={[setPage1,setPage2,setPage3,setPage4,setPage5,setPage6,setPage7,setPage8,setPage9,setPage10,setPage11]}
        startDateLabel={t('page.fnb.timePeriodSummaryHistPage.filter.orderCompletedAt.startDate')} 
        toLabel={t('page.fnb.timePeriodSummaryHistPage.filter.orderCompletedAt.to')} 
        endDateLabel={t('page.fnb.timePeriodSummaryHistPage.filter.orderCompletedAt.endDate')}/>
    </Dashboard>
    ,
    <Dashboard>
      <TextComponent title={t('component.text.historical')} xs={12} />
      <TableComponent item={query1.item} result={query1.result} rowsPerPage={rowsPerPage1} 
        setRowsPerPage={setRowsPerPage1} page={page1} setPage={setPage1}/>
      <TableComponent item={query2.item} result={query2.result} rowsPerPage={rowsPerPage2} 
        setRowsPerPage={setRowsPerPage2} page={page2} setPage={setPage2}/>
      <TableComponent item={query3.item} result={query3.result} rowsPerPage={rowsPerPage3} 
        setRowsPerPage={setRowsPerPage3} page={page3} setPage={setPage3}/>
      <TableComponent item={query4.item} result={query4.result} rowsPerPage={rowsPerPage4} 
        setRowsPerPage={setRowsPerPage4} page={page4} setPage={setPage4}/>

      <TableComponent item={query7.item} result={query7.result} rowsPerPage={rowsPerPage7} 
        setRowsPerPage={setRowsPerPage7} page={page7} setPage={setPage7}/>
      <TableComponent item={query8.item} result={query8.result} rowsPerPage={rowsPerPage8} 
        setRowsPerPage={setRowsPerPage8} page={page8} setPage={setPage8}/>
      <TableComponent item={query9.item} result={query9.result} rowsPerPage={rowsPerPage9} 
        setRowsPerPage={setRowsPerPage9} page={page9} setPage={setPage9}/>
      <TableComponent item={query10.item} result={query10.result} rowsPerPage={rowsPerPage10} 
        setRowsPerPage={setRowsPerPage10} page={page10} setPage={setPage10}/>

      <TableComponent item={query5.item} result={query5.result} rowsPerPage={rowsPerPage5} 
        setRowsPerPage={setRowsPerPage5} page={page5} setPage={setPage5}/>
      <TableComponent item={query11.item} result={query11.result} rowsPerPage={rowsPerPage11} 
        setRowsPerPage={setRowsPerPage11} page={page11} setPage={setPage11}/>
      <TableComponent item={query6.item} result={query6.result} rowsPerPage={rowsPerPage6} 
        setRowsPerPage={setRowsPerPage6} page={page6} setPage={setPage6}/>
    </Dashboard>
  ]) 
};

export default TimePeriodSummaryHistPage;