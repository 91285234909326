const commonZhHK = {
  "userDeviceRegister": {
    "name": "User Device Register",
    "tableHeader": {
      "lastDeviceCreatedAt": "上次設備創建時間",
      "deviceCount": "設備數量",
      "membershipCode": "會員編號",
      "firstName": "顧客姓名",
      "rankCode": "會員等級編號",
      "sex": "性別",
      "email": "電郵地址",
      "phone": "電話",
      "dateOfBirth": "出生日期",
      "isActive": "已激活",
      "isDeleted": "已刪除",
      "remark": "備註",
      "createdAt": "創建時間",
      "updatedAt": "更新時間",
      "modifiedAt": "修改時間"
    },
    "filter": {
      "membershipCode": "會員編號",
      "email": "電郵地址",
      "phone": "電話",
      "userName": "顧客姓名",
    }
  },
  "takeawayDeliveryOrderApprovalTimeTrace": {
    "name": "外賣配送訂單審批時間追溯報告",
    "tableHeader": {
      "storeCode": "分店編號",
      "referenceNumber": "訂單參考編號",
      "orderState": "訂單狀態",
      "createdAt": "創建時間",
      "waitingForApprovalAt": "客戶下訂單時間",
      "confirmedAt": "訂單批准時間",
      "orderType": "訂單類型",
      "commerceChannelCode": "商業頻道編號",
      "ticketNumber": "票號",
    },
    "filter": {
      "orderCreatedAt": {
        "date": "創建日期"
      },
      "referenceNumber": "訂單參考編號",
    }
  },
  "rtDiscountRelationsChecking": {
    "discountRelationsReport":{
      "name": "折扣關係報告",
      "tableHeader": {
        "rsmCode": "RSM編號",
        "skuCode": "SKU編號",
        "discountCode": "折扣編號",
        "discountableType": "優惠類型",
        "requiredManualTrigger": "需要人手觸發",
        "skuProductTitle": "SKU產品名稱",
        "requiredCoupon": "所需優惠券",
        "allSkusByApply": "所有SKU應用",
        "propertyApplyNature": "屬性應用性質",
        "cartDiscountsCode": "購物車折扣編號",
        "cartDiscountsCouponCode": "購物車折扣優惠券編號",
        "startAt": "開始於",
        "endAt": "結束於",
        "mode": "模式",
        "couponCode": "優惠券編號",
        "timingState": "時間狀態"
      },
    },
    "discountRelationPivot":{
      "name": "折扣關係透視表",
      "tableHeader": {
        "skuCode": "SKU編號",
      }
    },
    "filter": {
      "StartsAt": {
        "startDate": "開始於開始日期",
        "to": "to",
        "endDate": "開始於結束日期"
      },
      "rsmCode": "RSM編號",
      "skuCode": "SKU編號",
      "discountCode": "購物車折扣編號",
      "discountableType": "優惠類型",
      "timingState": "時間狀態",
      "requiredManualTrigger": "需要人手觸發"
    }
  },
  "rtBeveragesSalesPage": {
    "name": "會員購買記錄報告",
    "tableHeader": {
      "lineTotal": "行總計",
      "quantity": "數量",
      "code": "飲品RSM編號",
    },
    "filter": {
      "store": {
        "all": "全部",
        "name": "分店編號",
      },
      "orderCompletedAt": {
        "startDate": "訂單完成開始日期",
        "to": "至",
        "endDate": "訂單完成結束日期"
      }
    }
  },
  "rtUserExport":{
    "name": "會員匯出",
    "tableHeader": {
      "totalSpend": "總消費",
      "orderCount": "訂單總數",
      "membershipCode": "會員編號",
      "firstName": "會員姓名",
      "rankCode": "會員等級編號",
      "sex": "性別",
      "email": "電郵地址",
      "phone": "電話",
      "dateOfBirth": "出生日期",
      "isActive": "已激活",
      "isDeleted": "已刪除",
      "remark": "備註",
      "createdAt": "創建時間",
      "updatedAt": "更新時間",
      "modifiedAt": "修改時間",
      "rankExpireAt": "會員有效日期",
      "userPreferDistrict": "會員首選地區",
      "district": "地區(PS)",
      "salesamountPS": "總消費(PS)",
      "salescountPS": "訂單總數(PS)",
      "dateJoinPS": "加入會員日期(PS)",
      "dateContPS": "會員續會日期(PS)",
      "oldCodePS": "舊代碼(PS)",
      "zonePS": "不接收推廣資訊指示(PS)",
      "userAgreementPics": "接收推廣資訊指示",
      "userAgreementTerms": "同意用戶條款",
      "point": "積分"
    },
    "filter": {
      "updatedAt": {
        "startDate": "更新日期開始",
        "to": "至",
        "endDate": "更新日期結束"
      },
      "createdAt": {
        "startDate": "創建日期開始",
        "to": "至",
        "endDate": "創建日期結束"
      },
      "membershipCode": "會員編號",
      "email": "電郵地址",
      "phone": "電話",
      "userName": "顧客姓名",
      "oldCode": "舊代碼(PS)",
      "zoneFromPS": "用戶協議宣傳(PS)",
      "userAgreementPics": "接收推廣資訊指示",
      "userAgreementTerms": "同意用戶條款"
    },
  },
  "rtUserQuery":{
    "userExport":{
      "name": "用戶資料",
      "tableHeader": {
        "totalSpend": "總消費",
        "orderCount": "訂單總數",
        "membershipCode": "會員編號",
        "firstName": "會員姓名",
        "rankCode": "會員等級編號",
        "sex": "性別",
        "email": "電郵地址",
        "phone": "電話",
        "dateOfBirth": "出生日期",
        "isActive": "已激活",
        "isDeleted": "已刪除",
        "remark": "備註",
        "createdAt": "創建時間",
        "updatedAt": "更新時間",
        "modifiedAt": "修改時間",
        "rankExpireAt": "會員有效日期",
        "userPreferDistrict": "會員首選地區",
        "district": "地區(PS)",
        "salesamountPS": "總消費(PS)",
        "salescountPS": "訂單總數(PS)",
        "dateJoinPS": "加入會員日期(PS)",
        "dateContPS": "會員續會日期(PS)",
        "oldCodePS": "舊代碼(PS)",
        "zonePS": "不接收推廣資訊指示(PS)",
        "userAgreementPics": "接收推廣資訊指示",
        "userAgreementTerms": "同意用戶條款",
        "point": "積分"
      },
    },
    "membershipTransaction":{
      "name": "會籍資料",
      "tableHeader": {
        "rankCode": "會員等級編號",
        "effectiveAt": "生效時間",
        "expireAt": "過期時間",
        "isDeleted": "已刪除",
      },
    },
    "orderHistory":{
      "name": "訂單歷史",
      "tableHeader": {
        "ordersCompletedAt": "訂單完成時間",
        "shipFromStoreCode": "分店編號",
        "referenceNumber": "訂單參考編號",
        "grossSales": "銷售總額",
        "discounts": "折扣",
        "serviceCharges": "服務費",
        "orderAmountRounding": "訂單捨入金額",
        "membershipCharges": "會籍費",
        "orderTotal": "合計",
      },
    },
    "loyaltyPointTransaction":{
      "name": "會員積分交易",
      "tableHeader": {
      "id": "ID", 
        "createdAt": "創建時間",
        "code": "編號",
        "opening": "上一個結餘", 
        "points": "積分",
        "balance": "現在結餘",
        "isDeleted": "已刪除", 
        "isActive": "已激活", 
        "activeAt": "激活時間",
        "expireAt": "過期時間", 
        "updatedAt": "更新時間",
      },
    },
    "usersMostConsumeStore": {
      "name": "用戶最常消費分店",
      "tableHeader": {
        "storeCode": "分店編號",
        "count": "數量"
      }
    },
    "filter": {
      "membershipCode": "會員編號",
    },
    "enterMembershipCode": "請輸入會員編號，按ENTER鍵查詢會員。",
  },
  "rtOrderUserExport":{
    "name": "RT按用戶關聯的商店訂購匯出",
    "tableHeader": {
      "referenceNumber": "訂單參考編號",
      "shipmentStoreCode": "分店編號",
      "completedAt": "訂單完成時間",
      "totalPrice": "總額",
      "membershipCode": "會員編號",
      "firstName": "會員姓名",
      "rankExpireAt": "會員等級過期時間",
      "customerRankCode": "會員等級編號",
      "sex": "性別",
      "phone": "電話",
      "userEmail": "電郵地址",
      "zonePS": "不接收推廣資訊指示(PS)",
      "userAgreementPics": "接收推廣資訊指示",
      "userAgreementTerms": "同意用戶條款",
    },
    "filter": {
      "orderCompletedAt": {
        "startDate": "訂單完成開始時間",
        "to": "至",
        "endDate": "訂單完成結束時間"
      },
      "membershipCode": "會員編號",
      "email": "電郵地址",
      "phone": "電話",
      "userName": "會員姓名",
      "storeCode": "分店編號",
      "zoneFromPS": "不接收推廣資訊指示(PS)",
      "userAgreementPics": "接收推廣資訊指示",
      "userAgreementTerms": "同意用戶條款",
    },
  },
}

export default commonZhHK