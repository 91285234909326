const DiscountRelationQueries = {
  DiscountRelation: {
    query: {
      "timeDimensions": [
      ],
      "order": {
        "ViewDiscountRelationsCachesReport.startAt": "desc",
        "ViewDiscountRelationsCachesReport.rsmCode": "asc"
      },
      "filters": [
      ],
      "dimensions": [
        "ViewDiscountRelationsCachesReport.rsmCode",
        "ViewDiscountRelationsCachesReport.skuCode",
        "ViewDiscountRelationsCachesReport.skuProductTitle",
        "ViewDiscountRelationsCachesReport.discountableType",
        "ViewDiscountRelationsCachesReport.requiredManualTrigger",
        "ViewDiscountRelationsCachesReport.requiredCoupon",
        "ViewDiscountRelationsCachesReport.allSkusByApply",
        "ViewDiscountRelationsCachesReport.propertyApplyNature",
        "ViewDiscountRelationsCachesReport.cartDiscountsCode",
        "ViewDiscountRelationsCachesReport.cdcc",
        "ViewDiscountRelationsCachesReport.couponCode",
        "ViewDiscountRelationsCachesReport.startAt",
        "ViewDiscountRelationsCachesReport.endAt",
        "ViewDiscountRelationsCachesReport.timingState",
        "ViewDiscountRelationsCachesReport.mode",
      ],
      "limit": 50000,
      "renewQuery": true,
    }
  },
  DiscountRelationPivot: {
    query: {
      "timeDimensions": [
      ],
      "order": {
        "ViewDiscountRelationsCachesReport.skuCode": "asc",
      },
      "filters": [
      ],
      "dimensions": [
        "ViewDiscountRelationsCachesReport.skuCode",
        "ViewDiscountRelationsCachesReport.cartDiscountsCode",
        "ViewDiscountRelationsCachesReport.mode",
      ],
      "measures": [
        "ViewDiscountRelationsCachesReport.count"
      ],
      "limit": 50000,
      "renewQuery": true,
    }
  }
}

export default DiscountRelationQueries