const DiscountBreakdownQueries = {
  DiscountBreakdown: {
   query: {
      "measures": [
        "MvOrderTransactions.sumLineTotal",
        "MvOrderTransactions.sumQuantity",
        "Orders.totalPrice"
      ],
      "order": [
        [
          "OrderTransactionsDiscount.storeCode",
          "asc"
        ],
        [
          "OrderTransactionsDiscount.discountCode",
          "asc"
        ]
      ],
      "filters": [
        {
          "member": "MvOrderTransactions.code",
          "operator": "equals",
          "values": [
            "sku_catalog_promotion_calculated_unit_discount_amount",
            "sku_cart_discount_calculated_unit_discount_amount",
            "sku_coupon_calculated_unit_discount_amount",
            "cart_discount_calculated_total_discount_amount",
            "coupon_calculated_total_discount_amount",
            "shipping_cart_discount_calculated_unit_discount_amount",
            "shipping_cart_discount_calculated_total_discount_amount",
            "voucher_total_amount"
          ]
        },
        {
          "member": "MvOrderTransactions.commerceChannelCode",
          "operator": "notEquals",
          "values": [
            "membership_upgrade"
          ]
        },
        {
          "member": "MvOrderTransactions.orderState",
          "operator": "equals",
          "values": [
            "completed"
          ]
        },
      ],
      "dimensions": [
        "MvOrderTransactions.shipFromStoreCode",
        "MvOrderTransactions.discountCode",
        "MvOrderTransactions.discountNameEnHk",
        "MvOrderTransactions.discountNameZhHk",
      ],
      "limit": 50000,
      "renewQuery": true,
    }
  },
  DiscountBreakdownByStaff: {
   query: {
      "measures": [
        "MvOrderTransactions.sumLineTotal",
        "MvOrderTransactions.sumQuantity"
      ],
      "order": [
        [
          "OrderTransactionsDiscount.storeCode",
          "asc"
        ],
        [
          "InternalAppOperators.staffNumber",
          "asc"
        ],
        [
          "OrderTransactionsDiscount.discountCode",
          "asc"
        ]
      ],
      "filters": [
        {
          "member": "MvOrderTransactions.code",
          "operator": "equals",
          "values": [
            "sku_catalog_promotion_calculated_unit_discount_amount",
            "sku_cart_discount_calculated_unit_discount_amount",
            "sku_coupon_calculated_unit_discount_amount",
            "cart_discount_calculated_total_discount_amount",
            "coupon_calculated_total_discount_amount",
            "shipping_cart_discount_calculated_unit_discount_amount",
            "shipping_cart_discount_calculated_total_discount_amount",
            "voucher_total_amount"
          ]
        },
        {
          "member": "MvOrderTransactions.commerceChannelCode",
          "operator": "notEquals",
          "values": [
            "membership_upgrade"
          ]
        },
        {
          "member": "MvOrderTransactions.orderState",
          "operator": "equals",
          "values": [
            "completed"
          ]
        },
      ],
      "dimensions": [
        "MvOrderTransactions.shipFromStoreCode",
        "MvOrderTransactions.discountCode",
        "MvOrderTransactions.discountNameEnHk",
        "MvOrderTransactions.discountNameZhHk",
        "MvOrderTransactions.staffNumber",
        "MvOrderTransactions.firstName",
        "MvOrderTransactions.lastName",
      ],
      "limit": 50000,
      "renewQuery": true,
    }
  },
  DiscountBreakdownByCommerceChannel: {
   query: {
      "measures": [
        "MvOrderTransactions.sumLineTotal",
        "MvOrderTransactions.sumQuantity",
        "MvOrderTransactions.orderCount",
        "MvOrderTransactions.sumOrderTotalPrice"
      ],
      "order": [
        [
          "MvOrderTransactions.shipFromStoreCode",
          "asc"
        ],
        [
          "MvOrderTransactions.discountCode",
          "asc"
        ]
      ],
      "filters": [
        {
          "member": "MvOrderTransactions.code",
          "operator": "equals",
          "values": [
            "sku_catalog_promotion_calculated_unit_discount_amount",
            "sku_cart_discount_calculated_unit_discount_amount",
            "sku_coupon_calculated_unit_discount_amount",
            "cart_discount_calculated_total_discount_amount",
            "coupon_calculated_total_discount_amount",
            "shipping_cart_discount_calculated_unit_discount_amount",
            "shipping_cart_discount_calculated_total_discount_amount",
            "voucher_total_amount"
          ]
        },
        {
          "member": "MvOrderTransactions.commerceChannelCode",
          "operator": "notEquals",
          "values": [
            "membership_upgrade"
          ]
        },
        {
          "member": "MvOrderTransactions.orderState",
          "operator": "equals",
          "values": [
            "completed"
          ]
        },
      ],
      "dimensions": [
        "MvOrderTransactions.shipFromStoreCode",
        "MvOrderTransactions.commerceChannelCode",
        "MvOrderTransactions.discountCode",
        "MvOrderTransactions.discountNameEnHk",
        "MvOrderTransactions.discountNameZhHk",
        "MvOrderTransactions.couponRedeemablePoint",
        "MvOrderTransactions.couponRedeemablePrice"
      ],
      "limit": 50000,
      "renewQuery": true,
    }
  },
  DiscountBreakdownByItem: {
    query: {
      "measures": [
        "MvOrderTransactions.sumLineTotal",
        "MvOrderTransactions.sumQuantity"
      ],
      "order": [
        [
          "OrderTransactionsDiscount.storeCode",
          "asc"
        ],
        [
          "OrderTransactionsDiscount.discountCode",
          "asc"
        ]
      ],
      "filters": [
        {
          "member": "MvOrderTransactions.code",
          "operator": "equals",
          "values": [
            "sku_catalog_promotion_calculated_unit_discount_amount",
            "sku_cart_discount_calculated_unit_discount_amount",
            "sku_coupon_calculated_unit_discount_amount"
          ]
        },
        {
          "member": "MvOrderTransactions.commerceChannelCode",
          "operator": "notEquals",
          "values": [
            "membership_upgrade"
          ]
        },
        {
          "member": "MvOrderTransactions.orderState",
          "operator": "equals",
          "values": [
            "completed"
          ]
        },
      ],
      "dimensions": [
        "MvOrderTransactions.discountCode",
        "MvOrderTransactions.discountNameEnHk",
        "MvOrderTransactions.discountNameZhHk",
        "OrderLinePropertySkus.code",
        "OrderLinePropertySkus.internalUseTitle"
      ],
      "limit": 50000,
      "renewQuery": true,
    }
  },
  DeliverServiceCharge:{
    query: {
      "measures": [
        "MvOrderTransactions.absSumLineTotal"
      ],
      "order": {
        "MvOrderTransactions.shipFromStoreCode": "asc"
      },
      "filters": [
        {
          "member": "MvOrderTransactions.commerceChannelCode",
          "operator": "notEquals",
          "values": [
            "membership_upgrade"
          ]
        },
        {
          "member": "MvOrderTransactions.discountCode",
          "operator": "set"
        },
        {
          "member": "MvOrderTransactions.orderState",
          "operator": "equals",
          "values": [
            "completed"
          ]
        },
      ],
      "dimensions": [
        "MvOrderTransactions.shipFromStoreCode"
      ],
      "limit": 50000,
      "renewQuery": true,
    }
  },
  DiscountBreakdownCHE: {
   query: {
      "measures": [
        "DiscountBreakdownCHE.sumLineTotal",
        "DiscountBreakdownCHE.sumQuantity"
      ],
      "order": [
        [
          "DiscountBreakdownCHE.shipFromStoreCode",
          "asc"
        ],
        [
          "DiscountBreakdownCHE.discountCode",
          "asc"
        ]
      ],
      "filters": [
        {
          "member": "DiscountBreakdownCHE.commerceChannelCode",
          "operator": "notEquals",
          "values": [
            "membership_upgrade"
          ]
        },
        {
          "member": "DiscountBreakdownCHE.orderState",
          "operator": "equals",
          "values": [
            "completed"
          ]
        },
      ],
      "dimensions": [
        "DiscountBreakdownCHE.shipFromStoreCode",
        "DiscountBreakdownCHE.discountCode",
        "DiscountBreakdownCHE.discountNameEnHk",
        "DiscountBreakdownCHE.discountNameZhHk",
        "DiscountBreakdownCHE.rsmrCode",
        "DiscountBreakdownCHE.subcatCategoryCode",
      ],
      "limit": 50000,
      "renewQuery": true,
    }
  },
}

export default DiscountBreakdownQueries