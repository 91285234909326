const OrderRefundSummaryQueries = {
  OrderRefundSummary: {
    query: {
      "measures": [
        "OrderRefundSummary.count",
        "OrderRefundSummary.totalRefundAmount"
      ],
      "timeDimensions": [
      ],
      "order": [
      ],
      "filters": [
        {
          "member": "OrderRefundSummary.refundState",
          "operator": "equals",
          "values": [
            "refunded"
          ]
        },
      ],
      "dimensions": [
      ],
      "limit": 50000,
      "renewQuery": true,
    }
  },
  OrderRefundSummaryDetail: {
    query: {
      "measures": [
      ],
      "timeDimensions": [
      ],
      "order": [
      ],
      "filters": [
        {
          "member": "OrderRefundSummary.refundState",
          "operator": "equals",
          "values": [
            "refunded"
          ]
        },
      ],
      "dimensions": [
        "OrderRefundSummary.id",
        "OrderRefundSummary.shipmentStoreCode",
        "OrderRefundSummary.commerceChannelCode",
        "OrderRefundSummary.orderId",
        "OrderRefundSummary.referenceNumber",
        "OrderRefundSummary.refundAmount",
        "OrderRefundSummary.refundedAt",
        "OrderRefundSummary.refundState",
      ],
      "limit": 50000,
      "renewQuery": true,
    }
  },
}

export default OrderRefundSummaryQueries