const StockMovementQueries = {
  StockMovement: {
    query: {
      "timeDimensions": [
      ],
      "order": {
        "StockMovement.transactionTimestamp": "desc",
        "StockMovement.propertyAc": "asc"
      },
      "filters": [
      ],
      "dimensions": [
        "StockMovement.transactionTimestamp",
        "StockMovement.propertyAc",
        "StockMovement.skuCode",
        "StockMovement.upcCode",
        "StockMovement.productCode",
        "StockMovement.storeCode",
        "StockMovement.txNumber",
        "StockMovement.txType",
        "StockMovement.quantity",
      ],
      "limit": 50000,
      "renewQuery": true,
    }
  },
}

export default StockMovementQueries