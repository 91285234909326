const commonZhHK = {
  currentInventoryBalancePage: {
    name: "當前庫存記錄",
    measureName: "當前庫存記錄",
    filter: {
      propertyAc: {
        name: "供應商賬戶",
        all: "全部"
      },
      categoryCodes: "產品類型編號",
      storeCode: {
        name: "分店編號",
        all: "全部"
      }
    },
    tableHeader: {
      propertyAc: "供應商賬戶",
      skuCode: "SKU編號",
      upcCode: "UPC編號",
      skuInternalUseTitle: "SKU內部名稱",
      storeCode: "分店編號",
      availableForSale: "可供出售",
      instock: "現貨",
      holdCount: "保留",
      available: "可供出售(已扣減保留)",
      cost: "成本",
      inventoryCost: "庫存成本",
      balance: "；數量",
      unit: "單位",
      productCode: "產品編號",
      productTitleEn: "產品名稱 (英文)",
      productTitleZh: "產品名稱 (中文)",
      categoryCodes: "產品類型編號",
      stockCounterCacheId: "庫存盤點緩存編號"
    }
  },
  supplierSummaryPage: {
    text: {
      orderCompleted: "已完成訂單",
      orderConfirmed: "已確定訂單",
      orderCompletedDetail: "已完成訂單詳情",
    },
    filter: {
      propertyAc: {
        name: "供應商賬戶",
        all: "全部"
      },
      storeCode: {
        name: "分店編號",
        all: "全部"
      },
      orderCompletedAt: {
        "startDate": "訂單完成開始日期",
        "to": "至",
        "endDate": "訂單完成結束日期"
      }
    },
    titleMapping: {
      totalSkuQuantity: "總數量",
      totalOrderAmount: "總淨銷售額",
      totalGrossSales: "總銷售額",
      totalDiscount: "總折扣",
    },
    orderQuantityBreakdownByPropertyAC: {
      name: "訂單數量明細",
      tableHeader: {
        propertyAc: "供應商賬戶",
        referenceNumber: "訂單參考編號",
        shipFromStoreCode: "分店編號",
        skuCode: "SKU編號",
        upcCode: "UPC編號",
        skuInternalUseTitle: "SKU內部名稱",
        completedAt: "訂單完成日期",
        txHour: "交易時間 (小時)",
        weekday: "交易時間 (工作日)",
        txDow: "交易時間 (星期)",
        unitAmount: "單位金額",
        quantity: "數量",
        orderAmount: "訂單銷售額",
      }
    },
    orderQuantityBreakdownByPropertyACSummary: {
      name: "訂單數量匯總",
      tableHeader: {
        skuCode: "SKU編號",
        upcCode: "UPC編號",
        shipFromStoreCode: "分店編號",
        totalQuantity: "總數量",
        totalOrderAmount: "總訂單銷售額",
      }
    },
    discountQuantityBreakdownByPropertyAC: {
      name: "折扣數量明細",
      tableHeader: {
        propertyAc: "供應商賬戶",
        referenceNumber: "訂單參考編號",
        shipFromStoreCode: "分店編號",
        discountCode: "折扣編號",
        discountNameEn: "折扣名稱(英文)",
        discountNameZh: "折扣名稱(中文)",
        orderTransactionType: "折扣類型",
        skuInternalUseTitle: "SKU內部名稱",
        completedAt: "訂單完成日期",
        txHour: "交易時間 (小時)",
        weekday: "交易時間 (工作日)",
        txDow: "交易時間 (星期)",
        unitAmount: "單位金額",
        quantity: "數量",
        orderAmount: "訂單銷售額",
      }
    },
    discountQuantityBreakdownByPropertyACSummary: {
      name: "折扣數量匯總",
      tableHeader: {
        discountCode: "折扣編號",
        discountNameEn: "折扣名稱(英文)",
        discountNameZh: "折扣名稱(中文)",
        orderTransactionType: "折扣類型",
        shipFromStoreCode: "分店編號",
        totalQuantity: "總數量",
        totalOrderAmount: "總訂單銷售額",
      }
    },
    skuUploadTemplate: {
      name: "SKU上傳模板",
      tableHeader: {
        propertyAc: "skus[meta][property_ac](json)",
        productCode: "product[code]",
        skuCode: "sku[code]",
        upcCode: "sku[upc_code]",
        skuCommerceChannelCodes: "sku[commerce_channel_codes]",
        sizeOptionCode: "size_option[code]",
        sizeMasterCode: "size_option[size_master_code]",
        sizeOptionDisplaySequence: "size_option[display_sequence]",
        sizeOptionNameEn: "size_option[name]:en-HK",
        sizeOptionNameZn: "size_option[name]:zh-HK",
        sizeOptionState: "size_option[state]",
        colorOptionHexValue: "color_option[hex_value]",
        colorOptionColorCode: "color_option[color_code]",
        colorMasterCode: "color_option[color_master_code]",
        colorOptionNameEn: "color_option[name]:en-HK",
        colorOptionNameZh: "color_option[name]:zh-HK",
        colorOptionState: "color_option[state]",
        productType: "product[product_type]",
        productStatus: "product[status]",
        skuInternalUseTitle: "sku[internal_use_title]",
        productTitleEn: "product[title]:en-HK",
        productTitleZh: "product[title]:zh-HK",
        categoriesCode: "categories[1][code]",
      }
    },
    stockMovement: {
      name: "存貨來往",
      tableHeader: {
        transactionTimestamp: "交易時間",
        propertyAc: "供應商賬戶",
        skuCode: "SKU編號",
        upcCode: "UPC編號",
        productCode: "商品編號",
        storeCode: "分店編號",
        txNumber: "交易號碼",
        txType: "交易類型",
        quantity: "數量"
      }
    }
  }
}

export default commonZhHK