const RepeatedMembersQueries = {
  RepeatedMembers: {
    query: {
      "measures": [
        "RepeatedMembers.count",
        "RepeatedMembers.sumTotalPrice"
      ],
      "timeDimensions":[],
      "dimensions": [
        "RepeatedMembers.shipFromStoreCode",
        "RepeatedMembers.membershipCode",
        "RepeatedMembers.firstName",
        "RepeatedMembers.lastName",
        "RepeatedMembers.phone",
        "RepeatedMembers.email",
        "RepeatedMembers.sex",
        "RepeatedMembers.rankCode",
      ],
      "order": {
      },
      "filters": [
      ],
      "limit": 50000,
      "renewQuery": true,
    }
  },
}

export default RepeatedMembersQueries