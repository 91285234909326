const StocktakeQueries = {
  Stocktake: {
    query: {
      "timeDimensions": [
      ],
      "order": {
        "StockTake.createdAt": "desc"
      },
      "filters": [
      ],
      "dimensions": [
        "StockTake.stocktakeReferenceNumber",
        "StockTake.storeCode",
        "StockTake.skuInventoryType",
        "StockTake.state",
        "StockTake.staffNumber",
        "StockTake.firstName",
        "StockTake.lastName",
        "StockTake.createdAt",
        "StockTake.waitingForComparisonAt",
        "StockTake.comparingBalanceAt",
        "StockTake.completedAt",
        "StockTake.adjustmentReferenceNumber",
      ],
      "limit": 50000,
      "renewQuery": true,
    }
  },
  StocktakeLine: {
    query: {
      "measures": [
      ],
      "timeDimensions": [
      ],
      "order": {
        "StockTakeLine.skuCode": "asc"
      },
      "filters": [
      ],
      "dimensions": [
        "StockTakeLine.stocktakeReferenceNumber",
        "StockTakeLine.skuCode",
        "StockTakeLine.productTitleEn",
        "StockTakeLine.productTitleZh",
        "StockTakeLine.quantity",
        "StockTakeLine.snapshotQuantity",
        "StockTakeLine.quantityMovement",
        "StockTakeLine.skuMeasure",
        "StockTakeLine.snapshotSkuMeasure",
        "StockTakeLine.skuMeasureMovement",
        "StockTakeLine.skuMeasurementCode",
        "StockTakeLine.state",
        "StockTakeLine.adjustmentReferenceNumber",
        "StockTakeLine.processedAt"
      ],
      "limit": 50000,
      "renewQuery": true,
    }
  },
  SkuInventoryAdjustment: {
    query: {
      "measures": [
      ],
      "timeDimensions": [
      ],
      "order": {
         "SkuInventoryAdjustment.skuCode": "asc"
      },
      "filters": [
      ],
      "dimensions": [
        "SkuInventoryAdjustment.adjustmentReferenceNumber",
        "SkuInventoryAdjustment.storeCode",
        "SkuInventoryAdjustment.state",
        "SkuInventoryAdjustment.skuCode",
        "SkuInventoryAdjustment.productTitleEn",
        "SkuInventoryAdjustment.productTitleZh",
        "SkuInventoryAdjustment.quantityMovement",
        "SkuInventoryAdjustment.skuMeasureMovement",
        "SkuInventoryAdjustment.skuMeasurementCode",
        "SkuInventoryAdjustment.staffNumber",
        "SkuInventoryAdjustment.firstName",
        "SkuInventoryAdjustment.lastName",
        "SkuInventoryAdjustment.userType",
        "SkuInventoryAdjustment.createdAt",
        "SkuInventoryAdjustment.queueingProcessAt",
        "SkuInventoryAdjustment.processingAt",
        "SkuInventoryAdjustment.processedAt",
        "SkuInventoryAdjustment.remark",
      ],
      "limit": 50000,
      "renewQuery": true,
    }
  },
}

export default StocktakeQueries