import React, { useEffect, useState } from 'react'
import TextField from '@material-ui/core/TextField';

// if now is in composition session
let isOnComposition = false

/**
 * CompositionTextField
 * Wrapping the TextField component, handling the input with composition event 
 * for chinese input method
 * 
 * Reference:
 * https://titanwolf.org/Network/Articles/Article?AID=e1f024d6-80d3-41fd-b83c-52e1c2deb354
 * https://developpaper.com/solve-the-problem-of-using-input-method-to-input-in-the-react-input-box/
 */
const CompositionTextField = ({
  InputProps = {},
  onChange,
  ...others
}) => {
  const [innerValue, setInnerValue] = useState('');

  const handleChange = (e) => {
    // console.log('change type ', e.type, ', target ', e.target, ', target.value ', e.target.value)
    const newValue = e.target.value;
    // Flow check
    if (!(e.target instanceof HTMLInputElement)) return
    if (isOnComposition) return
    
    // when not in composition change inputValue and innerValue both
    setInnerValue(newValue)
 }

  const handleComposition = (e) => {
    // console.log('type ', e.type, ', target ', e.target, ',target.value ', e.target.value, ', data', e.data)
    const newValue = e.target.value;

    // Flow check
    if (!(e.target instanceof HTMLInputElement)) return

    if (e.type === 'compositionend') {
      setInnerValue(newValue)
      isOnComposition = false
    } else {
      isOnComposition = true
    }
  }

  useEffect(() => {
    onChange(innerValue);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [innerValue]);

  return (
    <TextField
      InputProps={{
        ...InputProps,
        onCompositionUpdate: handleComposition,
        onCompositionEnd: handleComposition,
        onCompositionStart: handleComposition,
      }}
      {...others}
      onChange={handleChange}
    />
  )
}

export default CompositionTextField