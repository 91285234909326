import React from 'react';
import { useTranslation } from 'react-i18next';
import XLSX from 'xlsx';
import { cloneDeep, isArray, isObject } from 'lodash';
import Button from '@material-ui/core/Button';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { DownloadOutlined } from '@ant-design/icons';
import Menu from '@material-ui/core/Menu';
import MenuList from '@material-ui/core/MenuList';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(() => ({
  button: {
    background: '#19D9FD !important',
    borderColor: '#C1C1C1',
    marginLeft: 'auto !important',
  }
}));

/**
 * ExportButtonComponent
 * 
 * Button provided export function via the `XLSX` lib
 * 
 * Props:
 *  data, should be array of objects, required by XLSX
 *  headers, used for table header, sharing same header data and reformat inside this component
 *  filename, filename for exported file, should be without file extension.
 */
const ExportButtonComponent = ({
  data,
  headers,
  filename = 'export',
  skipHeader = false,
}) => {
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const anchorRef = React.useRef(null);
  const styles = useStyles();
  const supportedExportFormat = ['csv', 'xlsx', 'txt'];

  // stringify array or object data to string, because cell data doesn't support them
  const formatArrayAndObjectData = (data) => {
    return data.map((item) => {
      let tmpItem = cloneDeep(item);
      Object.keys(tmpItem).map((k) => {
        if (isArray(tmpItem[k]) || isObject(tmpItem[k])) {
          tmpItem[k] = JSON.stringify(tmpItem[k]);
        }
        return null;
      })
      return tmpItem;
    })
  }

  // generate file for export with `XLSX` lib
  const exportFile = (exportType, e) => {
    handleClose(e);

    let extraWriteOption = {}
    let fileExtension = `.${exportType}`;

    switch (exportType) {
      case 'txt':
        extraWriteOption.bookType = 'csv';
        break;
      default:
        break;
    }

    // map the header data to correct format using in XLSX
    const formattedHeader = headers.reduce((result = {}, header, index) => {
      return index === 1 
        ? { 
          [result.key]: result.label,
          [header.key]: header.label,
        }
        : {
          ...result,
          [header.key]: header.label,
        }
    });

    // create new workbook 
    const wb = XLSX.utils.book_new();
    // converts an array of arrays into a worksheet.
    const ws = XLSX.utils.json_to_sheet(
      formatArrayAndObjectData(data),
      {
        header: Object.keys(formattedHeader),
        skipHeader,
      }
    );
    // if need to show header, rename the header key with header name
    if (!skipHeader) {
      const range = XLSX.utils.decode_range(ws['!ref']);
      for(let C = range.s.c; C <= range.e.c; ++C) {
        const address = XLSX.utils.encode_col(C) + "1"; // <-- first row, column number C
        if(!ws[address]) continue;
        ws[address].v = formattedHeader[ws[address].v];
      }
    }
    // add worksheet to workbook under name Sheet1
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    // save workbook to file export.xlsx
    XLSX.writeFile(wb, `${filename}${fileExtension}`, extraWriteOption);
  }

  const handleToggle = (e) => {
    setOpen((prevOpen) => !prevOpen);
    setAnchorEl(e.currentTarget);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  return (
    <React.Fragment>
      <Button 
        ref={anchorRef}
        variant="contained"
        disableElevation
        startIcon={<DownloadOutlined />}
        endIcon={<ArrowDropDownIcon />}
        size="small"
        className={styles.button}
        onClick={handleToggle}
      >
        {t('component.exportButton.export')}
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuList dense>
          {
            supportedExportFormat.map((format) => (
              <MenuItem key={`export-option-${format}`} onClick={exportFile.bind(null, format)}>
                {t(`component.exportButton.${format}`)}
              </MenuItem>
            ))
          }
        </MenuList>
      </Menu>       
    </React.Fragment>
  )
}

export default ExportButtonComponent;