import DeviceRegisterQueries from './DeviceRegisterQueries'
import UserQueries from './UserQueries'

const users = {
  users: {
    ...DeviceRegisterQueries,
    ...UserQueries,
  }
}

export default users