const RSMCheckingQueries = {
  RSMChecking: {
    query: {
      "order": {
        "RSMChecking.rsm_category_count": "desc"
      },
      "filters": [
      ],
      "dimensions": [
        "RSMChecking.id",
        "RSMChecking.code",
        "RSMChecking.status",
        "RSMChecking.rsm_category_count",
        "RSMChecking.rsm_category"
      ],
      "limit": 50000,
      "renewQuery": true,
    }
  },
  RSMRChecking: {
    query: {
      "order": {
        "RSMRChecking.rsmr_category_count": "desc"
      },
      "filters": [
      ],
      "dimensions": [
        "RSMRChecking.id",
        "RSMRChecking.code",
        "RSMRChecking.status",
        "RSMRChecking.rsmr_category_count",
        "RSMRChecking.rsmr_category"
      ],
      "limit": 50000,
      "renewQuery": true,
    }
  },
}

export default RSMCheckingQueries