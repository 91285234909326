const DayEndQueries = {
  PaymentDayEnd:{
    query: {
      "measures": [
        "MvPaymentDayEnd.sumTotalPaymentAmount",
      ],
      "timeDimensions": [
      ],
      "order": {
      },
      "dimensions": [
        "MvPaymentDayEnd.compositePaymentType",
      ],
      "filters": [
      ],
      "limit": 50000,
      "renewQuery": true,
    }
  },
}

export default DayEndQueries