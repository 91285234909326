import React, { useState } from "react";
import Header from '../../components/Header';
import TableComponent from '../../components/TableComponent';
import TextFieldComponent from '../../components/TextFieldComponent';
import SelectComponent from '../../components/SelectComponent';
import DateRangePickerComponent from '../../components/DateRangePickerComponent';
import Dashboard from '../../components/Dashboard';
import PageHeaderComponent from '../../components/PageHeaderComponent';
import OmnitechQueries from '../../queries/OmnitechQueries'
import { useTranslation } from 'react-i18next';
import { useCubeQuery } from '@cubejs-client/react';
import { format } from 'date-fns'

const defaultDate = new Date(1970, 0, 1, 0, 0, 0)

const applyFilter = (query, membershipCodeFilter, emailFilter, phoneFilter, 
  userNameFilter, oldCodeFilter, zoneFromPSFilter, userAgreementPicsFilter, userAgreementTermsFilter,
  updateStartDateFilter, updateEndDateFilter, createStartDateFilter, createEndDateFilter) => {

  let membershipCodeFilterObj = {
    member: "Users.membershipCode",
    operator: "contains",
    values: [membershipCodeFilter]
  }


  if (membershipCodeFilter !== '') {
    query = {
      ...query,
      filters: (query.filters || []).concat(membershipCodeFilterObj)
    }
  }

  let emailFilterObj = {
    member: "Users.email",
    operator: "contains",
    values: [emailFilter]
  }


  if (emailFilter !== '') {
    query = {
      ...query,
      filters: (query.filters || []).concat(emailFilterObj)
    }
  }

  let phoneFilterObj = {
    member: "Users.phone",
    operator: "contains",
    values: [phoneFilter]
  }


  if (phoneFilter !== '') {
    query = {
      ...query,
      filters: (query.filters || []).concat(phoneFilterObj)
    }
  }

  let userNameFilterObj = {
    member: "Users.firstName",
    operator: "contains",
    values: [userNameFilter]
  }


  if (userNameFilter !== '') {
    query = {
      ...query,
      filters: (query.filters || []).concat(userNameFilterObj)
    }
  }

  let oldCodeFilterObj = {
    member: "Users.oldCodePS",
    operator: "contains",
    values: [oldCodeFilter]
  }


  if (oldCodeFilter !== '') {
    query = {
      ...query,
      filters: (query.filters || []).concat(oldCodeFilterObj)
    }
  }

  let zoneFromPSFilterObj = {
    member: "Users.zonePS",
    operator: "contains",
    values: [zoneFromPSFilter]
  }


  if (zoneFromPSFilter !== '') {
    query = {
      ...query,
      filters: (query.filters || []).concat(zoneFromPSFilterObj)
    }
  }


  if(userAgreementPicsFilter !== 'ALL'){
    let userAgreementPicsFilterObj = {
      member: "Users.userAgreementPics",
      operator: "equals",
      values: [userAgreementPicsFilter]
    }
    query = {
      ...query,
      filters: (query.filters || []).concat(userAgreementPicsFilterObj)
    }
  }


  if(userAgreementTermsFilter !== 'ALL'){
    let userAgreementTermsFilterObj = {
      member: "Users.userAgreementTerms",
      operator: "equals",
      values: [userAgreementTermsFilter]
    }
    query = {
      ...query,
      filters: (query.filters || []).concat(userAgreementTermsFilterObj)
    }
  }

  if( updateStartDateFilter.getTime() !== defaultDate.getTime() || updateEndDateFilter.getTime() !== defaultDate.getTime()){
    let updateDateFilterObj = {
      "dimension": "Users.updatedAt",
      "dateRange": [ updateStartDateFilter, updateEndDateFilter ]
    }
    query = {
      ...query,
      timeDimensions: (query.timeDimensions || []).concat(updateDateFilterObj)
    }
  }

  if( createStartDateFilter.getTime() !== defaultDate.getTime() || createEndDateFilter.getTime() !== defaultDate.getTime()){
     let createDateFilterObj = {
      "dimension": "Users.createdAt",
      "dateRange": [ createStartDateFilter, createEndDateFilter ]
    }
    query = {
      ...query,
      timeDimensions: (query.timeDimensions || []).concat(createDateFilterObj)
    }
  }

  return query
}

const RtUserExportQuery = (membershipCodeFilter, emailFilter, phoneFilter, userNameFilter, 
  oldCodeFilter, zoneFromPSFilter, userAgreementPicsFilter, userAgreementTermsFilter,
  updateStartDateFilter, updateEndDateFilter, createStartDateFilter, createEndDateFilter, isRefresh, setRefresh) => {
  const { t } = useTranslation();
  let item = {
    name: t('page.rt.rtUserExport.name'),
    tableHeaders: {
      "UserOrders.totalPrice":  { name: t('page.rt.rtUserExport.tableHeader.totalSpend'), type: "number" },
      "UserOrders.count":  { name: t('page.rt.rtUserExport.tableHeader.orderCount'), type: "number" },
      "Users.membershipCode":  { name: t('page.rt.rtUserExport.tableHeader.membershipCode'), type: "string" },
      "Users.firstName":  { name: t('page.rt.rtUserExport.tableHeader.firstName'), type: "string" },
      "CustomerRanks.code":  { name: t('page.rt.rtUserExport.tableHeader.rankCode'), type: "string" },
      "Users.sex":  { name: t('page.rt.rtUserExport.tableHeader.sex'), type: "string" },
      "Users.email":  { name: t('page.rt.rtUserExport.tableHeader.email'), type: "string" },
      "Users.phone":  { name: t('page.rt.rtUserExport.tableHeader.phone'), type: "string" },
      "Users.dateOfBirth":  { name: t('page.rt.rtUserExport.tableHeader.dateOfBirth'), type: "date" },
      "Users.isActive":  { name: t('page.rt.rtUserExport.tableHeader.isActive'), type: "string" },
      "Users.isDeleted":  { name: t('page.rt.rtUserExport.tableHeader.isDeleted'), type: "string" },
      "Users.remark":  { name: t('page.rt.rtUserExport.tableHeader.remark'), type: "string" },
      "Users.createdAt":  { name: t('page.rt.rtUserExport.tableHeader.createdAt'), type: "dateTime" },
      "Users.updatedAt":  { name: t('page.rt.rtUserExport.tableHeader.updatedAt'), type: "dateTime" },
      "Users.modifiedAt":  { name: t('page.rt.rtUserExport.tableHeader.modifiedAt'), type: "dateTime" },
      "UserLoyaltyPointBalances.rankExpireAt":  { name: t('page.rt.rtUserExport.tableHeader.rankExpireAt'), type: "dateTime" },
      "UserLoyaltyPointBalances.balance":  { name: t('page.rt.rtUserExport.tableHeader.point'), type: "number" },
      "Users.userPreferDistrict":  { name: t('page.rt.rtUserExport.tableHeader.userPreferDistrict'), type: "string" },
      "Users.district":  { name: t('page.rt.rtUserExport.tableHeader.district'), type: "string" },
      "Users.salesamountPS":  { name: t('page.rt.rtUserExport.tableHeader.salesamountPS'), type: "number" },
      "Users.salescountPS":  { name: t('page.rt.rtUserExport.tableHeader.salescountPS'), type: "number" },
      "Users.dateJoinPS":  { name: t('page.rt.rtUserExport.tableHeader.dateJoinPS'), type: "string" },
      "Users.dateContPS":  { name: t('page.rt.rtUserExport.tableHeader.dateContPS'), type: "string" },
      "Users.oldCodePS":  { name: t('page.rt.rtUserExport.tableHeader.oldCodePS'), type: "string" },
      "Users.zonePS":  { name: t('page.rt.rtUserExport.tableHeader.zonePS'), type: "string" },
      "Users.userAgreementPics":  { name: t('page.rt.rtUserExport.tableHeader.userAgreementPics'), type: "string" },
      "Users.userAgreementTerms":  { name: t('page.rt.rtUserExport.tableHeader.userAgreementTerms'), type: "string" },
    },
    grid: {xs: 12, lg: 12},
    export: { allowExport: true, filename: 'rt_user_export_' + format(new Date(), 'yyyyMMddHHmmss') }
  }
  let query = {
    ...OmnitechQueries.users.RtUserExport.query
  }
  query = applyFilter(query, membershipCodeFilter, emailFilter, phoneFilter, 
    userNameFilter, oldCodeFilter, zoneFromPSFilter, userAgreementPicsFilter, userAgreementTermsFilter,
    updateStartDateFilter, updateEndDateFilter, createStartDateFilter, createEndDateFilter)
  if(isRefresh)
    query = {}
  const result = useCubeQuery(query, {resetResultSetOnChange: true})
  if(isRefresh)
    setRefresh(false)
  return { item, result }
}

const RtUserExportPage = () => {
  const { t } = useTranslation();
  const [ membershipCodeFilter, setMembershipCodeFilter ] = useState('');
  const [ emailFilter, setEmailFilter ] = useState('');
  const [ phoneFilter, setPhoneFilter ] = useState('');
  const [ userNameFilter, setUserNameFilter ] = useState('');
  const [ oldCodeFilter, setOldCodeFilter ] = useState('');
  const [ zoneFromPSFilter, setZoneFromPSFilter ] = useState('');
  const [userAgreementPicsFilter, setUserAgreementPicsFilter] = useState('ALL');
  const userAgreementPicsSelect = ['ALL','yes','no']
  const [userAgreementTermsFilter, setUserAgreementTermsFilter] = useState('ALL');
  const userAgreementTermsSelect = ['ALL','true','false']
  let defaultDateFilter = new Date(1970, 0, 1, 0, 0, 0)
  const [updateDateRangeFilter, setUpdateDateRangeFilter] = useState([defaultDateFilter, defaultDateFilter])
  const [updateStartDateFilter, updateEndDateFilter] = updateDateRangeFilter
  const [createDateRangeFilter, setCreateDateRangeFilter] = useState([defaultDateFilter, defaultDateFilter])
  const [createStartDateFilter, createEndDateFilter] = createDateRangeFilter
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  const [ refresh1, setRefresh1 ] = useState(false);

  const { item, result }  = RtUserExportQuery(membershipCodeFilter, emailFilter, phoneFilter, 
    userNameFilter, oldCodeFilter, zoneFromPSFilter, userAgreementPicsFilter, userAgreementTermsFilter,
    updateStartDateFilter, updateEndDateFilter, createStartDateFilter, createEndDateFilter, refresh1, setRefresh1)

  const refresh = () => {
    setRefresh1(true)
    setPage(0)
  }

  return ([
    <Header />,
    <PageHeaderComponent onClickFunc={refresh} refreshlabel={t('component.refresh')} headerTitle={t('component.header.menu.rtUserExport')} />,
    <Dashboard>
      <DateRangePickerComponent state={updateDateRangeFilter} stateChanger={setUpdateDateRangeFilter} pageChangerArr={[setPage]}
      startDateLabel={t('page.rt.rtUserExport.filter.updatedAt.startDate')} 
      toLabel={t('page.rt.rtUserExport.filter.updatedAt.to')} 
      endDateLabel={t('page.rt.rtUserExport.filter.updatedAt.endDate')}/>
      <DateRangePickerComponent state={createDateRangeFilter} stateChanger={setCreateDateRangeFilter} pageChangerArr={[setPage]}
      startDateLabel={t('page.rt.rtUserExport.filter.createdAt.startDate')} 
      toLabel={t('page.rt.rtUserExport.filter.createdAt.to')} 
      endDateLabel={t('page.rt.rtUserExport.filter.createdAt.endDate')}/>
      <TextFieldComponent id={"membership_code_filter"} state={membershipCodeFilter} pageChangerArr={[setPage]}
        stateChanger={setMembershipCodeFilter} textLabel={t('page.rt.rtUserExport.filter.membershipCode')}/>
      <TextFieldComponent id={"email_filter"} state={emailFilter} pageChangerArr={[setPage]}
        stateChanger={setEmailFilter} textLabel={t('page.rt.rtUserExport.filter.email')}/>
      <TextFieldComponent id={"phone_filter"} state={phoneFilter} pageChangerArr={[setPage]}
        stateChanger={setPhoneFilter} textLabel={t('page.rt.rtUserExport.filter.phone')}/>
      <TextFieldComponent id={"name_filter"} state={userNameFilter} pageChangerArr={[setPage]}
        stateChanger={setUserNameFilter} textLabel={t('page.rt.rtUserExport.filter.userName')}/>
      <TextFieldComponent id={"old_code_filter"} state={oldCodeFilter} pageChangerArr={[setPage]}
        stateChanger={setOldCodeFilter} textLabel={t('page.rt.rtUserExport.filter.oldCode')}/>
      <TextFieldComponent id={"zone_from_ps_filter"} state={zoneFromPSFilter} pageChangerArr={[setPage]}
        stateChanger={setZoneFromPSFilter} textLabel={t('page.rt.rtUserExport.filter.zoneFromPS')}/>
      <SelectComponent id={"user_agreement_pics_filter"} state={userAgreementPicsFilter} pageChangerArr={[setPage]}
        stateChanger={setUserAgreementPicsFilter} textLabel={t('page.rt.rtUserExport.filter.userAgreementPics')} selectArray={userAgreementPicsSelect}/>
      <SelectComponent id={"user_agreement_terms_filter"} state={userAgreementTermsFilter} pageChangerArr={[setPage]}
        stateChanger={setUserAgreementTermsFilter} textLabel={t('page.rt.rtUserExport.filter.userAgreementTerms')} selectArray={userAgreementTermsSelect}/>
    </Dashboard>
    ,
    <Dashboard>
      <TableComponent item={item} result={result} rowsPerPage={rowsPerPage} 
        setRowsPerPage={setRowsPerPage} page={page} setPage={setPage}/>
    </Dashboard>
  ]) 
};

export default RtUserExportPage;