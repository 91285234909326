import React from "react";
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

let MultiSelectComponent = ({state, stateChanger, textLabel, selectArray, pageChangerArr, ...rest}) => {

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    stateChanger(
      // On autofill we get a the stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
    if(pageChangerArr){
      for(let i = 0; i < pageChangerArr.length; i++){
        pageChangerArr[i](0)
      }
    }
  };

  return(
    <div style={{marginLeft: "24px", marginBottom: "20px"}}>
      <FormControl sx={{ width: 200, marginTop: '20', marginBottom: '20', marginLeft: '30', marginRight: '30'}}>
        <InputLabel id={textLabel+"-select-label"}>{textLabel}</InputLabel>
        <Select
          labelId={textLabel+"-select-label"}
          id={textLabel+"-select"}
          multiple
          value={state}
          label={textLabel}
          onChange={handleChange}
          renderValue={(selected) => selected.join(', ')}
          MenuProps={MenuProps}
        >
          {selectArray.map((select) => (
            <MenuItem key={select} value={select}>
              <Checkbox checked={state.indexOf(select) > -1} />
              <ListItemText primary={select} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  )
}

export default MultiSelectComponent;