import React, { useState } from "react";
import Header from '../../components/Header';
import TableComponent from '../../components/TableComponent';
import DateRangePickerComponent from '../../components/DateRangePickerComponent';
import TextFieldComponent from '../../components/TextFieldComponent';
import MultiSelectComponent from '../../components/MultiSelectComponent';
import SelectComponent from '../../components/SelectComponent';
import Dashboard from '../../components/Dashboard';
import PageHeaderComponent from '../../components/PageHeaderComponent';
import OmnitechQueries from '../../queries/OmnitechQueries'
import { useTranslation } from 'react-i18next';
import { useCubeQuery } from '@cubejs-client/react';
import { format } from 'date-fns'

const applyFilter = (query, rsmCodeFilter, skuCodeFilter, discountCodeFilter,
    discountableTypeFilter, timingStateFilter, requiredManualTrigger) => {
 
  let rsmCodeFilterObj = {
    member: "ViewDiscountRelationsCachesReport.rsmCode",
    operator: "contains",
    values: [rsmCodeFilter]
  }
  if(rsmCodeFilter !== ''){
    query = {
      ...query,
      filters: (query.filters || []).concat(rsmCodeFilterObj)
    }
  }

  let skuCodeFilterObj = {
    member: "ViewDiscountRelationsCachesReport.skuCode",
    operator: "contains",
    values: [skuCodeFilter]
  }
  if(skuCodeFilter !== ''){
    query = {
      ...query,
      filters: (query.filters || []).concat(skuCodeFilterObj)
    }
  }

  let discountCodeFilterObj = {
    member: "ViewDiscountRelationsCachesReport.cartDiscountsCode",
    operator: "contains",
    values: [discountCodeFilter]
  }
  if(discountCodeFilter !== ''){
    query = {
      ...query,
      filters: (query.filters || []).concat(discountCodeFilterObj)
    }
  }

  let discountableTypeFilterObj = {
    member: "ViewDiscountRelationsCachesReport.discountableType",
    operator: "contains",
    values: discountableTypeFilter
  }
  if(discountableTypeFilter){
    query = {
      ...query,
      filters: (query.filters || []).concat(discountableTypeFilterObj)
    }
  }

  let timingStateFilterObj = {
    member: "ViewDiscountRelationsCachesReport.timingState",
    operator: "contains",
    values: timingStateFilter
  }
  if(timingStateFilter){
    query = {
      ...query,
      filters: (query.filters || []).concat(timingStateFilterObj)
    }
  }

  if(requiredManualTrigger !== 'ALL'){
    let requiredManualTriggerObj = {
      member: "ViewDiscountRelationsCachesReport.requiredManualTrigger",
      operator: "equals",
      values: [requiredManualTrigger]
    }
    query = {
      ...query,
      filters: (query.filters || []).concat(requiredManualTriggerObj)
    }
  }

  return query
}

const RtDiscountRelationsReportQueries = (rsmCodeFilter, skuCodeFilter, discountCodeFilter,
    discountableTypeFilter, timingStateFilter, requiredManualTrigger, startDateFilter, endDateFilter,
    isRefresh, setRefresh) => {
  const { t } = useTranslation();
  if(startDateFilter == null){
    startDateFilter = new Date()
    startDateFilter.setHours(0,0,0,0);
    endDateFilter = new Date()
    endDateFilter.setHours(23,59,59,999);
  }
  
  let item = {
    name: t('page.rt.rtDiscountRelationsChecking.discountRelationsReport.name'),
    tableHeaders: {
      "ViewDiscountRelationsCachesReport.rsmCode": { name: t('page.rt.rtDiscountRelationsChecking.discountRelationsReport.tableHeader.rsmCode'), type: "string" },
      "ViewDiscountRelationsCachesReport.skuCode": { name: t('page.rt.rtDiscountRelationsChecking.discountRelationsReport.tableHeader.skuCode'), type: "string" },
      "ViewDiscountRelationsCachesReport.skuProductTitle": { name: t('page.rt.rtDiscountRelationsChecking.discountRelationsReport.tableHeader.skuProductTitle'), type: "string" },
      "ViewDiscountRelationsCachesReport.discountableType": { name: t('page.rt.rtDiscountRelationsChecking.discountRelationsReport.tableHeader.discountableType'), type: "string" },
      "ViewDiscountRelationsCachesReport.requiredManualTrigger": { name: t('page.rt.rtDiscountRelationsChecking.discountRelationsReport.tableHeader.requiredManualTrigger'), type: "boolean" },
      "ViewDiscountRelationsCachesReport.requiredCoupon": { name: t('page.rt.rtDiscountRelationsChecking.discountRelationsReport.tableHeader.requiredCoupon'), type: "boolean" },
      "ViewDiscountRelationsCachesReport.allSkusByApply": { name: t('page.rt.rtDiscountRelationsChecking.discountRelationsReport.tableHeader.allSkusByApply'), type: "boolean" },
      "ViewDiscountRelationsCachesReport.propertyApplyNature": { name: t('page.rt.rtDiscountRelationsChecking.discountRelationsReport.tableHeader.propertyApplyNature'), type: "string" },
      "ViewDiscountRelationsCachesReport.cartDiscountsCode": { name: t('page.rt.rtDiscountRelationsChecking.discountRelationsReport.tableHeader.cartDiscountsCode'), type: "string" },
      "ViewDiscountRelationsCachesReport.cdcc": { name: t('page.rt.rtDiscountRelationsChecking.discountRelationsReport.tableHeader.cartDiscountsCouponCode'), type: "string" },
      "ViewDiscountRelationsCachesReport.couponCode": { name: t('page.rt.rtDiscountRelationsChecking.discountRelationsReport.tableHeader.couponCode'), type: "string" },
      "ViewDiscountRelationsCachesReport.startAt": { name: t('page.rt.rtDiscountRelationsChecking.discountRelationsReport.tableHeader.startAt'), type: "dateTime" },
      "ViewDiscountRelationsCachesReport.endAt": { name: t('page.rt.rtDiscountRelationsChecking.discountRelationsReport.tableHeader.endAt'), type: "dateTime" },
      "ViewDiscountRelationsCachesReport.timingState": { name: t('page.rt.rtDiscountRelationsChecking.discountRelationsReport.tableHeader.timingState'), type: "string" },
      "ViewDiscountRelationsCachesReport.mode": { name: t('page.rt.rtDiscountRelationsChecking.discountRelationsReport.tableHeader.mode'), type: "string" },
    },
    grid: {xs: 12},
    export: { allowExport: true, filename: 'rt_discount_relations_report_export_' + format(startDateFilter, 'yyyyMMddHHmmss') +
      '_' + format(endDateFilter, 'yyyyMMddHHmmss') }
  }
  let query = {
    ...OmnitechQueries.viewDiscountRelationsCachesReport.DiscountRelation.query,
    "timeDimensions": [
      {
        "dimension": "ViewDiscountRelationsCachesReport.startAt",
        "dateRange": [ startDateFilter, endDateFilter ]
      }
    ],
  }
  query = applyFilter(query, rsmCodeFilter, skuCodeFilter, discountCodeFilter,
    discountableTypeFilter, timingStateFilter, requiredManualTrigger)
  if(isRefresh)
    query = {}
  const result = useCubeQuery(query, {resetResultSetOnChange: true})
  if(isRefresh)
    setRefresh(false)
  return { item, result }
}

const RtDiscountRelationsPivotQueries = (rsmCodeFilter, skuCodeFilter, discountCodeFilter,
    discountableTypeFilter, timingStateFilter, requiredManualTrigger, startDateFilter, endDateFilter,
    isRefresh, setRefresh) => {
  const { t } = useTranslation();
  if(startDateFilter == null){
    startDateFilter = new Date()
    startDateFilter.setHours(0,0,0,0);
    endDateFilter = new Date()
    endDateFilter.setHours(23,59,59,999);
  }
  
  let item = {
    name: t('page.rt.rtDiscountRelationsChecking.discountRelationPivot.name'),
    tableHeaders: {
      "ViewDiscountRelationsCachesReport.skuCode": { name: t('page.rt.rtDiscountRelationsChecking.discountRelationPivot.tableHeader.skuCode'), type: "string" },
    },
    grid: {xs: 12},
    export: { allowExport: true, filename: 'rt_discount_relations_report_export_' + format(startDateFilter, 'yyyyMMddHHmmss') +
      '_' + format(endDateFilter, 'yyyyMMddHHmmss') },
    pivotConfig: {
      x: [
        "ViewDiscountRelationsCachesReport.skuCode",
      ],
      y: [
        "ViewDiscountRelationsCachesReport.cartDiscountsCode",  "ViewDiscountRelationsCachesReport.mode", 'measures'
      ],
    },
    pivotRename: ["ViewDiscountRelationsCachesReport.count", "count"]
  }
  let query = {
    ...OmnitechQueries.viewDiscountRelationsCachesReport.DiscountRelationPivot.query,
    "timeDimensions": [
      {
        "dimension": "ViewDiscountRelationsCachesReport.startAt",
        "dateRange": [ startDateFilter, endDateFilter ]
      }
    ],
  }
  query = applyFilter(query, rsmCodeFilter, skuCodeFilter, discountCodeFilter,
    discountableTypeFilter, timingStateFilter, requiredManualTrigger)
  if(isRefresh)
    query = {}
  const result = useCubeQuery(query, {resetResultSetOnChange: true})
  if(isRefresh)
    setRefresh(false)
  return { item, result }
}



const RtDiscountRelationsCheckingPage = () => {
  const { t } = useTranslation();
  let filterStartDate = new Date()
  filterStartDate.setMonth(0)
  filterStartDate.setDate(1)
  filterStartDate.setHours(0,0,0,0)
  let filterEndDate = new Date()
  filterEndDate.setMonth(11)
  filterEndDate.setDate(31)
  filterEndDate.setHours(23,59,59,999)
  const [dateRangeFilter, setDateRangeFilter] = useState([filterStartDate, filterEndDate])
  const [startDateFilter, endDateFilter] = dateRangeFilter
  const [rsmCodeFilter, setRsmCodeFilter] = useState('');
  const [skuCodeFilter, setSkuCodeFilter] = useState('');
  const [discountCodeFilter, setDiscountCodeFilter] = useState('');
  const [discountableTypeFilter, setDiscountableTypeFilter] = useState([]);
  const discountableTypes = ['CartDiscount','Coupon']
  const [timingStateFilter, setTimingStateFilter] = useState([]);
  const timingStates = ['running','past']
  const [requiredManualTrigger, setRequiredManualTrigger] = useState('ALL');
  const requiredManualTriggers = ['ALL','true','false']
  const [rowsPerPage1, setRowsPerPage1] = useState(10);
  const [page1, setPage1] = useState(0);
  const [rowsPerPage2, setRowsPerPage2] = useState(10);
  const [page2, setPage2] = useState(0);

  const [ refresh1, setRefresh1 ] = useState(false);
  const [ refresh2, setRefresh2 ] = useState(false);

  const rtDiscountRelationsReportQueries = RtDiscountRelationsReportQueries(rsmCodeFilter, skuCodeFilter, discountCodeFilter,
    discountableTypeFilter, timingStateFilter, requiredManualTrigger, startDateFilter, endDateFilter, refresh1, setRefresh1)
  const rtDiscountRelationsPivotQueries = RtDiscountRelationsPivotQueries(rsmCodeFilter, skuCodeFilter, discountCodeFilter,
    discountableTypeFilter, timingStateFilter, requiredManualTrigger, startDateFilter, endDateFilter, refresh2, setRefresh2)
  
  const refresh = () => {
    setRefresh1(true)
    setRefresh2(true)
    setPage1(0)
    setPage2(0)
  }

  return ([
    <Header />,
    <PageHeaderComponent onClickFunc={refresh} refreshlabel={t('component.refresh')} headerTitle={t('component.header.menu.rtDiscountRelationsChecking')} />,
    <Dashboard>
      <DateRangePickerComponent state={dateRangeFilter} stateChanger={setDateRangeFilter} pageChangerArr={[setPage1,setPage2]}
      startDateLabel={t('page.rt.rtDiscountRelationsChecking.filter.StartsAt.startDate')} 
      toLabel={t('page.rt.rtDiscountRelationsChecking.filter.StartsAt.to')} 
      endDateLabel={t('page.rt.rtDiscountRelationsChecking.filter.StartsAt.endDate')}/>
      <TextFieldComponent id={"rsm_code_filter"} state={rsmCodeFilter} pageChangerArr={[setPage1,setPage2]}
        stateChanger={setRsmCodeFilter} textLabel={t('page.rt.rtDiscountRelationsChecking.filter.rsmCode')}/>
      <TextFieldComponent id={"sku_code_filter"} state={skuCodeFilter} pageChangerArr={[setPage1,setPage2]}
        stateChanger={setSkuCodeFilter} textLabel={t('page.rt.rtDiscountRelationsChecking.filter.skuCode')}/>
      <TextFieldComponent id={"discount_code_filter"} state={discountCodeFilter} pageChangerArr={[setPage1,setPage2]}
        stateChanger={setDiscountCodeFilter} textLabel={t('page.rt.rtDiscountRelationsChecking.filter.discountCode')}/>
      <MultiSelectComponent id={"discountable_type_filter"} state={discountableTypeFilter} pageChangerArr={[setPage1,setPage2]}
        stateChanger={setDiscountableTypeFilter} textLabel={t('page.rt.rtDiscountRelationsChecking.filter.discountableType')} selectArray={discountableTypes}/>
      <MultiSelectComponent id={"time_state_filter"} state={timingStateFilter} pageChangerArr={[setPage1,setPage2]}
        stateChanger={setTimingStateFilter} textLabel={t('page.rt.rtDiscountRelationsChecking.filter.timingState')} selectArray={timingStates}/>
      <SelectComponent id={"required_manual_trigger_filter"} state={requiredManualTrigger} pageChangerArr={[setPage1,setPage2]}
        stateChanger={setRequiredManualTrigger} textLabel={t('page.rt.rtDiscountRelationsChecking.filter.requiredManualTrigger')} selectArray={requiredManualTriggers}/>
    </Dashboard>,
    <Dashboard>
      <TableComponent item={rtDiscountRelationsReportQueries.item} result={rtDiscountRelationsReportQueries.result}
        rowsPerPage={rowsPerPage1} setRowsPerPage={setRowsPerPage1} page={page1} setPage={setPage1}/>
      <TableComponent item={rtDiscountRelationsPivotQueries.item} result={rtDiscountRelationsPivotQueries.result}
        rowsPerPage={rowsPerPage2} setRowsPerPage={setRowsPerPage2} page={page2} setPage={setPage2}/>
    </Dashboard>
  ]) 
};

export default RtDiscountRelationsCheckingPage;