import React, { useState, useContext } from "react";
import Header from '../../components/Header';
import TableComponent from '../../components/TableComponent';
import SelectComponent from '../../components/SelectComponent';
import DateRangePickerComponent from '../../components/DateRangePickerComponent';
import TextComponent from '../../components/TextComponent';
import Dashboard from '../../components/Dashboard';
import { CubeJSContext } from '../../context/CubeJSProvider';
import OmnitechQueries from '../../queries/OmnitechQueries'
import PageHeaderComponent from '../../components/PageHeaderComponent';
import { useTranslation } from 'react-i18next';
import { useCubeQuery } from '@cubejs-client/react';
import { format } from 'date-fns'

const applyFilter = (query, storeFilter) => {
  let storeCodeFilterObj = {
    member: "MvPaymentDayEnd.shipmentStoreCode",
    operator: "equals",
    values: [storeFilter]
  }

  query = {
    ...query,
    filters: (query.filters || []).concat(storeCodeFilterObj)
  }

  return query
}

const PaymentDayEndQuery = (storeFilter, startDateFilter, endDateFilter, isRefresh, setRefresh) => {
  const { t } = useTranslation();
  if(startDateFilter == null){
    startDateFilter = new Date()
    startDateFilter.setHours(0,0,0,0);
    endDateFilter = new Date()
    endDateFilter.setHours(23,59,59,999);
  }
  let startDateString = new Date(startDateFilter.getTime() - (startDateFilter.getTimezoneOffset() * 60000 ))
                    .toISOString()
                    .split("T")[0];
  let endDateString = new Date(endDateFilter.getTime() - (endDateFilter.getTimezoneOffset() * 60000 ))
                    .toISOString()
                    .split("T")[0];
  
  let item = {
    name: t('page.fnb.paymentDayEnd.name'),
    tableHeaders: {
      "MvPaymentDayEnd.ordersCompleteDateHkt.day": { name: t('page.fnb.paymentDayEnd.tableHeader.date'), type: 'date'},
    },
    grid: {xs: 12},
    pivotConfig: {
      x: [
        "MvPaymentDayEnd.ordersCompleteDateHkt.day",
      ],
      y: [
        "MvPaymentDayEnd.compositePaymentType", 'measures'
      ],
    },
    pivotRename: [",MvPaymentDayEnd.sumTotalPaymentAmount", ""],
    withDoW: false,
    withPivotSum: true,
    pivotSumHeaderName: t('page.fnb.paymentDayEnd.tableHeader.total'),
    doWHeader: "",
    doWHeaderName: "",
    export: { allowExport: true, filename: 'payment_day_end_' + format(startDateFilter, 'yyyyMMddHHmmss') +
      '_' + format(endDateFilter, 'yyyyMMddHHmmss') }
  }
  let query = {
    ...OmnitechQueries.mvPaymentDayEnd.PaymentDayEnd.query,
    "timeDimensions": [
      {
        "dimension": "MvPaymentDayEnd.ordersCompleteDateHkt",
        "dateRange": [ startDateString + " 00:00:00",
         endDateString + " 23:59:59" ],
        "granularity": "day",
      }
    ],
    "timezone": "Asia/Hong_Kong"
  }
  query = applyFilter(query, storeFilter)
  if(isRefresh)
    query = {}
  const result = useCubeQuery(query, {resetResultSetOnChange: true})
  if(isRefresh)
    setRefresh(false)
  return { item, result }
}

const PaymentDayEndPage = () => {
  const { t } = useTranslation();
  const context = useContext(CubeJSContext);
  let storeCodeSelect = []
  if(context.storeCodes)
    storeCodeSelect = context.storeCodes
  const [ storeFilter, setStoreFilter ] = useState(storeCodeSelect[0]);
  let filterStartDate = new Date()
  filterStartDate.setHours(0,0,0,0);
  let filterEndDate = new Date()
  filterEndDate.setHours(23,59,59,999);
  const [dateRangeFilter, setDateRangeFilter] = useState([filterStartDate, filterEndDate])
  const [startDateFilter, endDateFilter] = dateRangeFilter
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  const [ refresh1, setRefresh1 ] = useState(false);

  const { item, result }  = PaymentDayEndQuery(storeFilter, startDateFilter, endDateFilter, refresh1, setRefresh1)

  const refresh = () => {
    setRefresh1(true)
    setPage(0)
  }

  return ([
    <Header />,
    <PageHeaderComponent onClickFunc={refresh} refreshlabel={t('component.refresh')} headerTitle={t('component.header.menu.paymentDayEnd')} />,
    <Dashboard>
      <SelectComponent id={"store_code_filter"} state={storeFilter} pageChangerArr={[setPage]}
          stateChanger={setStoreFilter} textLabel={t('page.fnb.paymentDayEnd.filter.store.name')} selectArray={storeCodeSelect}/>
      <DateRangePickerComponent state={dateRangeFilter} stateChanger={setDateRangeFilter} pageChangerArr={[setPage]}
        startDateLabel={t('page.fnb.paymentDayEnd.filter.ordersCompleteDateHkt.startDate')} 
        toLabel={t('page.fnb.paymentDayEnd.filter.ordersCompleteDateHkt.to')} 
        endDateLabel={t('page.fnb.paymentDayEnd.filter.ordersCompleteDateHkt.endDate')}/>
    </Dashboard>,
    <Dashboard>
      <TextComponent title={t('component.text.historical')} xs={12} />
      <TableComponent item={item} result={result} rowsPerPage={rowsPerPage} 
        setRowsPerPage={setRowsPerPage} page={page} setPage={setPage}/>
    </Dashboard>
  ]) 
};

export default PaymentDayEndPage;