import React from 'react';

export const CubeJSContext = React.createContext()

export const CubeJSProvider = ({ companyName, cubeJSToken, cubeJSUrl, storeCodes, propertyAc, login, acl, expiredAt, expired, 
  breakfastStart, breakfastEnd, lunchStart, lunchEnd, teaStart, teaEnd, dinnerStart, dinnerEnd, children }) => {
  return <CubeJSContext.Provider value={{ companyName: companyName, cubeJSToken: cubeJSToken, cubeJSUrl: cubeJSUrl,
     storeCodes: storeCodes, propertyAc: propertyAc, login: login, acl: acl, expiredAt: expiredAt, expired: expired,
     breakfastStart: breakfastStart, breakfastEnd: breakfastEnd, lunchStart: lunchStart, lunchEnd: lunchEnd, 
     teaStart: teaStart, teaEnd: teaEnd, dinnerStart: dinnerStart, dinnerEnd: dinnerEnd }}>{children}</CubeJSContext.Provider>;
}