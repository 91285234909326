import UserRankQueries from './UserRankQueries'
import ActiveQueries from './ActiveQueries'

const userLoyaltyPointBalances = {
  userLoyaltyPointBalances: {
    ...UserRankQueries,
    ...ActiveQueries,
  }
}

export default userLoyaltyPointBalances