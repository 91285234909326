import mvOrderTransactions from './mv_order_transactions/index'
import mvOrdersSummary from './mv_orders_summary/index'
import orders from './orders/index'
import users from './users/index'
import mvCartLineProperties from './mv_cart_line_properties/index'
import orderTransactions from './order_transctions/index'
import viewDiscountRelationsCachesReport from './view_discount_relations_caches_report/index'
import carts from './carts/index'
import voidedCartLineProperties from './voided_cart_line_properties/index'
import timePeriodSummary from './time_period_summary/index'
import orderTransactionsDiscount from './order_transactions_discount/index'
import mvOrdersSummaryEM from './mv_orders_summary_em/index'
import mvPaymentRequests from './mv_payment_requests/index'
import mvCartTransactions from './mv_cart_transactions/index'
import userLoyaltyPointBalances from './user_loyalty_point_balances/index'
import staffAttendance from './staff_attendance/index'
import stockCounterCaches from './stock_counter_caches/index'
import mvOqbbpa from './mv_oqbbpa/index'
import skus from './skus/index'
import mvStockMovement from './mv_stock_movement/index'
import products from './products/index'
import mvPaymentDayEnd from './mv_payment_day_end/index'
import mvNewMember from './mv_new_member/index'
import pushMessages from './push_messages/index'
import mvMemberSpending from './mv_member_spending/index'
import mvMemberStore from './mv_member_store/index'
import coupons from './coupons/index'
import cartDiscounts from './cart_discounts/index'
import stocktake from './stocktake/index'
import orderRefundRequests from './order_refund_requests/index'

const OmnitechQueries = {
  ...mvOrderTransactions,
  ...mvOrdersSummary,
  ...orders,
  ...users,
  ...mvCartLineProperties,
  ...orderTransactions,
  ...viewDiscountRelationsCachesReport,
  ...carts,
  ...voidedCartLineProperties,
  ...timePeriodSummary,
  ...orderTransactionsDiscount,
  ...mvOrdersSummaryEM,
  ...mvPaymentRequests,
  ...mvCartTransactions,
  ...userLoyaltyPointBalances,
  ...staffAttendance,
  ...stockCounterCaches,
  ...mvOqbbpa,
  ...skus,
  ...mvStockMovement,
  ...products,
  ...mvPaymentDayEnd,
  ...mvNewMember,
  ...pushMessages,
  ...mvMemberSpending,
  ...mvMemberStore,
  ...coupons,
  ...cartDiscounts,
  ...stocktake,
  ...orderRefundRequests,
}

export default OmnitechQueries