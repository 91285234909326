import React, { useState, useMemo, useContext } from "react";
import Header from '../../components/Header';
import TableComponent from '../../components/TableComponent';
import DateRangePickerComponent from '../../components/DateRangePickerComponent';
import TextComponent from '../../components/TextComponent';
import MultiSelectComponent from '../../components/MultiSelectComponent';
import ButtonGroupComponent from '../../components/ButtonGroupComponent';
import ChartComponent from '../../components/ChartComponent';
import Dashboard from '../../components/Dashboard';
import PageHeaderComponent from '../../components/PageHeaderComponent';
import OmnitechQueries from '../../queries/OmnitechQueries'
import { useTranslation } from 'react-i18next';
import { CubeJSContext } from '../../context/CubeJSProvider';
import { useCubeQuery } from '@cubejs-client/react';
import { setHours, setMinutes, setSeconds, setMilliseconds, format } from 'date-fns'

const applyFilter = (query, storeFilter) => {
  let storeCodeFilterObj = {
    member: "MvMemberStore.shipFromStoreCode",
    operator: "contains",
    values: storeFilter
  }

  query = {
    ...query,
    filters: (query.filters || []).concat(storeCodeFilterObj)
  }

  return query
}

const MemberStoreQuery = (storeFilter, startDateFilter, endDateFilter, granularity, isRefresh, setRefresh, type, name) => {
  const { t } = useTranslation();
  if(startDateFilter == null && endDateFilter == null){
    startDateFilter = new Date()
    startDateFilter.setHours(0,0,0,0);
    endDateFilter = new Date()
    endDateFilter.setHours(23,59,59,999);
  }
  else if(startDateFilter == null){
    startDateFilter = setHours(endDateFilter, 0)
    startDateFilter = setMinutes(startDateFilter, 0)
    startDateFilter = setSeconds(startDateFilter, 0)
    startDateFilter = setMilliseconds(startDateFilter, 0)
  }
  else if (endDateFilter == null){
    endDateFilter = setHours(startDateFilter, 23)
    endDateFilter = setMinutes(endDateFilter, 59)
    endDateFilter = setSeconds(endDateFilter, 59)
    endDateFilter = setMilliseconds(endDateFilter, 999)
  }
  let startDateString = new Date(startDateFilter.getTime() - (startDateFilter.getTimezoneOffset() * 60000 ))
                    .toISOString()
                    .split("T")[0];
  let endDateString = new Date(endDateFilter.getTime() - (endDateFilter.getTimezoneOffset() * 60000 ))
                    .toISOString()
                    .split("T")[0];
  let item = {
    name: name,
    tableHeaders: {
      "MvMemberStore.averageMembersCount": { name: t('page.membership.MemberStorePage.tableHeader.member'), type: 'numberCSV'},
      "MvMemberStore.averageNonMembersCount": { name: t('page.membership.MemberStorePage.tableHeader.nonMember'), type: 'numberCSV'},
      "MvMemberStore.averageMembersRatio": { name: t('page.membership.MemberStorePage.tableHeader.memberRatio'), type: 'numberCSV'},
      "MvMemberStore.averageNonMembersRatio": { name: t('page.membership.MemberStorePage.tableHeader.nonMemberRatio'), type: 'numberCSV'},
      "MvMemberStore.ordersCompleteDate.day": { name: t('page.membership.MemberStorePage.tableHeader.ordersCompleteDate'), type: 'date'},
      "MvMemberStore.ordersCompleteDate.month": { name: t('page.membership.MemberStorePage.tableHeader.ordersCompleteDate'), type: 'dateMonth'},
      "MvMemberStore.ordersCompleteDate.year": { name: t('page.membership.MemberStorePage.tableHeader.ordersCompleteDate'), type: 'dateYear'}
    },
    pivotConfig: {
      x: [
        "MvMemberStore.ordersCompletedAt."+granularity,
      ],
      y: [
        "MvMemberStore.shipFromStoreCode", 'measures'
      ],
    },
    pivotRename: [
      ",MvMemberStore.averageMembersCount", " " + t('page.membership.MemberStorePage.tableHeader.member'),
      ",MvMemberStore.averageNonMembersCount", " " + t('page.membership.MemberStorePage.tableHeader.nonMember'),
      ",MvMemberStore.averageMembersRatio", " " + t('page.membership.MemberStorePage.tableHeader.memberRatio'),
      ",MvMemberStore.averageNonMembersRatio", " " + t('page.membership.MemberStorePage.tableHeader.nonMemberRatio'),
    ],
    forcePivotNumber: true,
    grid: {sm: 12, md: 12},
    export: { allowExport: true, filename: 'member_store_export_by_' + granularity + '_' + format(startDateFilter, 'yyyyMMddHHmmss') +
      '_' + format(endDateFilter, 'yyyyMMddHHmmss') }
  }

  let query

  if(type === 'count')
    query = {
      ...OmnitechQueries.mvMemberStore.memberStoreCount.query,
    }
  else if(type === 'ratio')
    query = {
      ...OmnitechQueries.mvMemberStore.memberStoreRatio.query,
    }
  else
    query = {
        ...OmnitechQueries.mvMemberStore.memberStore.query,
      }

  query = {
    ...query,
    "timeDimensions": [
      {
        "dimension": "MvMemberStore.ordersCompleteDate",
        "granularity": granularity,
        "dateRange": [ startDateString + " 00:00:00",
        endDateString + " 23:59:59" ]
      }
    ],
    "timezone": "Asia/Hong_Kong"
  }
  query = applyFilter(query, storeFilter)
  if(isRefresh)
    query = {}
  const result = useCubeQuery(query, {resetResultSetOnChange: true})
  if(isRefresh)
    setRefresh(false)
  return { item, result }
}


const getMemberStoreChart = (resultSet, granularity, member, nonMember, memberRatio, nonMemberRatio) => {
  
  const renameLabels = {
    "MvMemberStore.averageMembersCount": member,
    "MvMemberStore.averageNonMembersCount": nonMember,
    "MvMemberStore.averageMembersRatio": memberRatio,
    "MvMemberStore.averageNonMembersRatio": nonMemberRatio
  };
  return {
    data: resultSet?.chartPivot().map((data) => {
      return {
        ...data,
        x: granularity === 'day' ? format(new Date(`${data.x}+00:00`), 'yyyy/MM/dd') : 
          granularity === 'month' ? format(new Date(`${data.x}+00:00`), 'yyyy/MM') : 
          format(new Date(`${data.x}+00:00`), 'yyyy'),
      }
    }),
    dataSeries: resultSet?.seriesNames().map((seriesName) => {
      let renameTitle
      if(renameLabels[seriesName.key]){
        renameTitle = renameLabels[seriesName.key]
      } else {
        if(seriesName.yValues.length < 2){
          renameTitle = seriesName.yValues[0]
        }
        else{
          renameTitle = seriesName.yValues[0]
          for(let i = 1; i < seriesName.yValues.length; i++){
            renameTitle += " " + renameLabels[seriesName.yValues[i]]
          }
        }
      }
      return {
        ...seriesName,
        title: renameTitle,
      }
    }),
  }
}

const MemberStorePage = () => {
  const { t } = useTranslation();
  let filterStartDate = new Date()
  filterStartDate.setHours(0,0,0,0);
  let filterEndDate = new Date()
  filterEndDate.setHours(23,59,59,999);
  let groupArr = [{id: 'day', code: t('component.day')},{id: 'month', code: t('component.month')},{id: 'year', code: t('component.year')}]
  const [ granularity, setGranularity ] = useState('day');
  const context = useContext(CubeJSContext);
  let storeCodeSelect = []
  if(context.storeCodes)
    storeCodeSelect = context.storeCodes
  const [ storeFilter, setStoreFilter ] = useState([]);

  const [dateRangeFilter, setDateRangeFilter] = useState([filterStartDate, filterEndDate])
  const [startDateFilter, endDateFilter] = dateRangeFilter
  const [rowsPerPage1, setRowsPerPage1] = useState(10);
  const [page1, setPage1] = useState(0);

  const [ refresh1, setRefresh1 ] = useState(false);
  const [ refresh2, setRefresh2 ] = useState(false);
  const [ refresh3, setRefresh3 ] = useState(false);

  const query1 = MemberStoreQuery(storeFilter, startDateFilter, endDateFilter, granularity, refresh1, setRefresh1, 'all', t('page.membership.MemberStorePage.name'))
  const query2 = MemberStoreQuery(storeFilter, startDateFilter, endDateFilter, granularity, refresh2, setRefresh2, 'count', t('page.membership.MemberStorePage.countName'))
  const query3 = MemberStoreQuery(storeFilter, startDateFilter, endDateFilter, granularity, refresh3, setRefresh3, 'ratio', t('page.membership.MemberStorePage.ratioName'))

  const refresh = () => {
    setRefresh1(true)
    setPage1(0)
    setRefresh2(true)
    setRefresh3(true)
  }

  const member = " " + t('page.membership.MemberStorePage.tableHeader.member')
  const nonMember = " " + t('page.membership.MemberStorePage.tableHeader.nonMember')
  const memberRatio = " " + t('page.membership.MemberStorePage.tableHeader.memberRatio')
  const nonMemberRatio = " " + t('page.membership.MemberStorePage.tableHeader.nonMemberRatio')

  const memberStoreCountChartResultSet = query2.result?.resultSet
  const memberStoreCountChart = useMemo(() => {
    if (memberStoreCountChartResultSet === null) return {data: [], dataSeries: []}
    
    return getMemberStoreChart(memberStoreCountChartResultSet, granularity, member, nonMember, memberRatio, nonMemberRatio)
  }, [memberStoreCountChartResultSet, granularity, member, nonMember, memberRatio, nonMemberRatio])

  const memberStoreRatioChartResultSet = query3.result?.resultSet
  const memberStoreRatioChart = useMemo(() => {
    if (memberStoreRatioChartResultSet === null) return {data: [], dataSeries: []}
    
    return getMemberStoreChart(memberStoreRatioChartResultSet, granularity, member, nonMember, memberRatio, nonMemberRatio)
  }, [memberStoreRatioChartResultSet, granularity, member, nonMember, memberRatio, nonMemberRatio])

  return ([
    <Header />,
    <PageHeaderComponent onClickFunc={refresh} refreshlabel={t('component.refresh')} headerTitle={t('component.header.menu.memberStore')} />,
    <Dashboard>
      <MultiSelectComponent id={"store_code_filter"} state={storeFilter} pageChangerArr={[setPage1]}
          stateChanger={setStoreFilter} textLabel={t('page.membership.MemberStorePage.filter.store.name')} selectArray={storeCodeSelect}/>
      <DateRangePickerComponent state={dateRangeFilter} stateChanger={setDateRangeFilter} 
        pageChangerArr={[setPage1]}
        startDateLabel={t('page.membership.MemberStorePage.filter.ordersCompleteDate.startDate')} 
        toLabel={t('page.membership.MemberStorePage.filter.ordersCompleteDate.to')} 
        endDateLabel={t('page.membership.MemberStorePage.filter.ordersCompleteDate.endDate')}/>
      <ButtonGroupComponent state={granularity} stateChanger={setGranularity} pageChangerArr={[setPage1]} buttonGroupArray={groupArr} title={t('component.dateGranularity')}/>
    </Dashboard>,
    <Dashboard>
      <TextComponent title={t('component.text.historical')} xs={12} />
      <TableComponent item={query1.item} result={query1.result} rowsPerPage={rowsPerPage1} 
        setRowsPerPage={setRowsPerPage1} page={page1} setPage={setPage1}/>
      <ChartComponent chartType="line" data={memberStoreCountChart.data} dataSeries={memberStoreCountChart.dataSeries} 
        item={query2.item} result={query2.result} titleName={query2.item.name} enableTooltip />
      <ChartComponent chartType="line" data={memberStoreRatioChart.data} dataSeries={memberStoreRatioChart.dataSeries} 
        item={query3.item} result={query3.result} titleName={query3.item.name} enableTooltip />
    </Dashboard>
  ]) 
};

export default MemberStorePage;