import React, { useState, useContext, useMemo } from "react";
import Header from '../../components/Header';
import TableComponent from '../../components/TableComponent';
import DateRangePickerComponent from '../../components/DateRangePickerComponent';
import TextComponent from '../../components/TextComponent';
import ChartComponent from '../../components/ChartComponent';
import Dashboard from '../../components/Dashboard';
import PageHeaderComponent from '../../components/PageHeaderComponent';
import OmnitechQueries from '../../queries/OmnitechQueries';
import { CubeJSContext } from '../../context/CubeJSProvider';
import { useTranslation } from 'react-i18next';
import { useCubeQuery } from '@cubejs-client/react';
import { setHours, setMinutes, setSeconds, setMilliseconds, format } from 'date-fns'

const applyFilter = (query, startHour, endHour) => {
  if(startHour && endHour){
    if(startHour > endHour){
      let tmp = endHour
      endHour = startHour
      startHour = tmp
    }
    let offset = (new Date().getTimezoneOffset())/60
    let startHourOver = false
    let endHourOver = false
    if(offset < 0){
      if(startHour + offset < 0){
        startHour = startHour + offset + 24
        startHourOver = true
      }
      else{
        startHour = startHour + offset
      }
      if(endHour + offset < 0){
        endHour = endHour + offset + 24
        endHourOver = true
      }
      else
        endHour = endHour + offset
    }
    else if(offset > 0){
      if(startHour + offset > 23){
        startHour = startHour + offset - 24
        startHourOver = true
      }
      else
        startHour = startHour + offset
      if(endHour + offset > 23){
        endHour = endHour + offset - 24
        endHourOver = true
      }
      else
        endHour = endHour + offset
    }
  
    let HourFilterObj={}
    if((startHourOver && endHourOver) || (!startHourOver && !endHourOver)){
      HourFilterObj = {
        and: [
          {
            member: "MvOrdersSummaryEM.cartsCreatedAtHour",
            operator: "gte",
            values: [startHour.toString()]
          },
          {
            member: "MvOrdersSummaryEM.cartsCreatedAtHour",
            operator: "lte",
            values: [endHour.toString()]
          }
        ]
      }
    }
    else{
      HourFilterObj = {
        or: [
          {
            and: [
              {
                member: "MvOrdersSummaryEM.cartsCreatedAtHour",
                operator: "gte",
                values: [startHour.toString()]
              },
              {
                member: "MvOrdersSummaryEM.cartsCreatedAtHour",
                operator: "lte",
                values: ["24"]
              }
            ]
          },
          {
            and: [
              {
                member: "MvOrdersSummaryEM.cartsCreatedAtHour",
                operator: "gte",
                values: ["0"]
              },
              {
                member: "MvOrdersSummaryEM.cartsCreatedAtHour",
                operator: "lte",
                values: [endHour.toString()]
              }
            ]
          }
        ]
      }
    }
    query = {
      ...query,
      filters: (query.filters || []).concat(HourFilterObj)
    }
  }

  return query
}

const NetSalesPerDayQuery = (startDateFilter, endDateFilter, isRefresh, setRefresh) => {
  const { t } = useTranslation();
  if(startDateFilter == null && endDateFilter == null){
    startDateFilter = new Date()
    startDateFilter.setHours(0,0,0,0);
    endDateFilter = new Date()
    endDateFilter.setHours(23,59,59,999);
  }
  else if(startDateFilter == null){
    startDateFilter = setHours(endDateFilter, 0)
    startDateFilter = setMinutes(startDateFilter, 0)
    startDateFilter = setSeconds(startDateFilter, 0)
    startDateFilter = setMilliseconds(startDateFilter, 0)
  }
  else if (endDateFilter == null){
    endDateFilter = setHours(startDateFilter, 23)
    endDateFilter = setMinutes(endDateFilter, 59)
    endDateFilter = setSeconds(endDateFilter, 59)
    endDateFilter = setMilliseconds(endDateFilter, 999)
  }
  let startDateString = new Date(startDateFilter.getTime() - (startDateFilter.getTimezoneOffset() * 60000 ))
                    .toISOString()
                    .split("T")[0];
  let endDateString = new Date(endDateFilter.getTime() - (endDateFilter.getTimezoneOffset() * 60000 ))
                    .toISOString()
                    .split("T")[0];
  let item = {
    name: t('page.fnb.perDayPage.netSalesPerDayName'),
    tableHeaders: {
      "MvOrdersSummaryEM.ordersCompletedAt.day": { name: t('page.fnb.perDayPage.tableHeader.date'), type: 'date'},
    },
    grid: { xs: 12 },
    export: { allowExport: true, filename: 'net_sales_per_day_export_' + format(startDateFilter, 'yyyyMMddHHmmss') +
      '_' + format(endDateFilter, 'yyyyMMddHHmmss') },
    pivotConfig: {
      x: [
        "MvOrdersSummaryEM.ordersCompletedAt.day",
      ],
      y: [
        "MvOrdersSummaryEM.shipFromStoreCode", 'measures'
      ],
    },
    pivotRename: ["RT08,MvOrdersSummaryEM.sumNetSales", "WTC", 
      "RT12,MvOrdersSummaryEM.sumNetSales", "MB", 
      "RT13,MvOrdersSummaryEM.sumNetSales", "QB", 
      "RT15,MvOrdersSummaryEM.sumNetSales", "OPM", 
      "RT16,MvOrdersSummaryEM.sumNetSales", "TST", 
      "RT17,MvOrdersSummaryEM.sumNetSales", "MCP", 
      "RT18,MvOrdersSummaryEM.sumNetSales", "TPTM",
      ",MvOrdersSummaryEM.sumNetSales", ""],
    withDoW: true,
    withPivotSum: true,
    pivotSumHeaderName: t('page.fnb.perDayPage.tableHeader.netSales'),
    doWHeader: "MvOrdersSummaryEM.ordersCompletedAt.day",
    doWHeaderName: t('page.fnb.perDayPage.tableHeader.dow')
  }
  let query = {
    ...OmnitechQueries.mvOrdersSummaryEM.NetSalesByStorePerDayEMHist.query,
    "timeDimensions": [
      {
        "dimension": "MvOrdersSummaryEM.ordersCompletedAt",
        "dateRange": [ startDateString + " 00:00:00",
         endDateString + " 23:59:59" ],
        "granularity": "day",
      }
    ],
    "timezone": "Asia/Hong_Kong"
  }
  if(isRefresh)
    query = {}
  const result = useCubeQuery(query, {resetResultSetOnChange: true})
  if(isRefresh)
    setRefresh(false)
  return { item, result }
}

const MembershipSalesPerDayQuery = (startDateFilter, endDateFilter, isRefresh, setRefresh) => {
  const { t } = useTranslation();
  if(startDateFilter == null && endDateFilter == null){
    startDateFilter = new Date()
    startDateFilter.setHours(0,0,0,0);
    endDateFilter = new Date()
    endDateFilter.setHours(23,59,59,999);
  }
  else if(startDateFilter == null){
    startDateFilter = setHours(endDateFilter, 0)
    startDateFilter = setMinutes(startDateFilter, 0)
    startDateFilter = setSeconds(startDateFilter, 0)
    startDateFilter = setMilliseconds(startDateFilter, 0)
  }
  else if (endDateFilter == null){
    endDateFilter = setHours(startDateFilter, 23)
    endDateFilter = setMinutes(endDateFilter, 59)
    endDateFilter = setSeconds(endDateFilter, 59)
    endDateFilter = setMilliseconds(endDateFilter, 999)
  }
  let startDateString = new Date(startDateFilter.getTime() - (startDateFilter.getTimezoneOffset() * 60000 ))
                    .toISOString()
                    .split("T")[0];
  let endDateString = new Date(endDateFilter.getTime() - (endDateFilter.getTimezoneOffset() * 60000 ))
                    .toISOString()
                    .split("T")[0];
  let item = {
    name: t('page.fnb.perDayPage.membershipSalesPerDayName'),
    tableHeaders: {
      "MvOrdersSummary.ordersCompletedAt.day": { name: t('page.fnb.perDayPage.tableHeader.date'), type: 'date'},
    },
    grid: { xs: 12 },
    export: { allowExport: true, filename: 'membership_sales_per_day_export_' + format(startDateFilter, 'yyyyMMddHHmmss') +
      '_' + format(endDateFilter, 'yyyyMMddHHmmss') },
    pivotConfig: {
      x: [
        "MvOrdersSummary.ordersCompletedAt.day",
      ],
      y: [
        "MvOrdersSummary.shipFromStoreCode", 'measures'
      ],
    },
    pivotRename: ["RT08,MvOrdersSummary.sumMembershipCharges", "WTC", 
      "RT12,MvOrdersSummary.sumMembershipCharges", "MB", 
      "RT13,MvOrdersSummary.sumMembershipCharges", "QB", 
      "RT15,MvOrdersSummary.sumMembershipCharges", "OPM", 
      "RT16,MvOrdersSummary.sumMembershipCharges", "TST", 
      "RT17,MvOrdersSummary.sumMembershipCharges", "MCP", 
      "RT18,MvOrdersSummary.sumMembershipCharges", "TPTM",
      ",MvOrdersSummary.sumMembershipCharges", ""],
    withDoW: true,
    doWHeader: "MvOrdersSummary.ordersCompletedAt.day",
    doWHeaderName: t('page.fnb.perDayPage.tableHeader.dow')
  }
  let query = {
    ...OmnitechQueries.mvOrdersSummary.MembershipSalesByStorePerDayHist.query,
    "timeDimensions": [
      {
        "dimension": "MvOrdersSummary.ordersCompletedAt",
        "dateRange": [ startDateString + " 00:00:00",
         endDateString + " 23:59:59" ],
        "granularity": "day",
      }
    ],
    "timezone": "Asia/Hong_Kong"
  }
  if(isRefresh)
    query = {}
  const result = useCubeQuery(query, {resetResultSetOnChange: true})
  if(isRefresh)
    setRefresh(false)
  return { item, result }
}

const PAXPerDayQuery = (startDateFilter, endDateFilter, isRefresh, setRefresh) => {
  const { t } = useTranslation();
  if(startDateFilter == null && endDateFilter == null){
    startDateFilter = new Date()
    startDateFilter.setHours(0,0,0,0);
    endDateFilter = new Date()
    endDateFilter.setHours(23,59,59,999);
  }
  else if(startDateFilter == null){
    startDateFilter = setHours(endDateFilter, 0)
    startDateFilter = setMinutes(startDateFilter, 0)
    startDateFilter = setSeconds(startDateFilter, 0)
    startDateFilter = setMilliseconds(startDateFilter, 0)
  }
  else if (endDateFilter == null){
    endDateFilter = setHours(startDateFilter, 23)
    endDateFilter = setMinutes(endDateFilter, 59)
    endDateFilter = setSeconds(endDateFilter, 59)
    endDateFilter = setMilliseconds(endDateFilter, 999)
  }
  let startDateString = new Date(startDateFilter.getTime() - (startDateFilter.getTimezoneOffset() * 60000 ))
                    .toISOString()
                    .split("T")[0];
  let endDateString = new Date(endDateFilter.getTime() - (endDateFilter.getTimezoneOffset() * 60000 ))
                    .toISOString()
                    .split("T")[0];
  let item = {
    name: t('page.fnb.perDayPage.paxPerDayName'),
    tableHeaders: {
      "MvOrdersSummaryEM.ordersCompletedAt.day": { name: t('page.fnb.perDayPage.tableHeader.date'), type: 'date'},
    },
    grid: { xs: 12 },
    export: { allowExport: true, filename: 'pax_per_day_export_' + format(startDateFilter, 'yyyyMMddHHmmss') +
      '_' + format(endDateFilter, 'yyyyMMddHHmmss') },
    pivotConfig: {
      x: [
        "MvOrdersSummaryEM.ordersCompletedAt.day",
      ],
      y: [
        "MvOrdersSummaryEM.shipFromStoreCode", 'measures'
      ],
    },
    pivotRename: ["RT08,MvOrdersSummaryEM.sumDineInNumberOfPeople", "WTC", 
      "RT12,MvOrdersSummaryEM.sumDineInNumberOfPeople", "MB", 
      "RT13,MvOrdersSummaryEM.sumDineInNumberOfPeople", "QB", 
      "RT15,MvOrdersSummaryEM.sumDineInNumberOfPeople", "OPM", 
      "RT16,MvOrdersSummaryEM.sumDineInNumberOfPeople", "TST", 
      "RT17,MvOrdersSummaryEM.sumDineInNumberOfPeople", "MCP", 
      "RT18,MvOrdersSummaryEM.sumDineInNumberOfPeople", "TPTM",
      ",MvOrdersSummaryEM.sumDineInNumberOfPeople", ""],
    withDoW: true,
    doWHeader: "MvOrdersSummaryEM.ordersCompletedAt.day",
    doWHeaderName: t('page.fnb.perDayPage.tableHeader.dow')
  }
  let query = {
    ...OmnitechQueries.mvOrdersSummaryEM.PAXByStorePerDayEMHist.query,
    "timeDimensions": [
      {
        "dimension": "MvOrdersSummaryEM.ordersCompletedAt",
        "dateRange": [ startDateString + " 00:00:00",
         endDateString + " 23:59:59" ],
        "granularity": "day",
      }
    ],
    "timezone": "Asia/Hong_Kong"
  }
  if(isRefresh)
    query = {}
  const result = useCubeQuery(query, {resetResultSetOnChange: true})
  if(isRefresh)
    setRefresh(false)
  return { item, result }
}

const PerPersonSpendingPerDayQuery = (startDateFilter, endDateFilter, isRefresh, setRefresh) => {
  const { t } = useTranslation();
  if(startDateFilter == null && endDateFilter == null){
    startDateFilter = new Date()
    startDateFilter.setHours(0,0,0,0);
    endDateFilter = new Date()
    endDateFilter.setHours(23,59,59,999);
  }
  else if(startDateFilter == null){
    startDateFilter = setHours(endDateFilter, 0)
    startDateFilter = setMinutes(startDateFilter, 0)
    startDateFilter = setSeconds(startDateFilter, 0)
    startDateFilter = setMilliseconds(startDateFilter, 0)
  }
  else if (endDateFilter == null){
    endDateFilter = setHours(startDateFilter, 23)
    endDateFilter = setMinutes(endDateFilter, 59)
    endDateFilter = setSeconds(endDateFilter, 59)
    endDateFilter = setMilliseconds(endDateFilter, 999)
  }
  let startDateString = new Date(startDateFilter.getTime() - (startDateFilter.getTimezoneOffset() * 60000 ))
                    .toISOString()
                    .split("T")[0];
  let endDateString = new Date(endDateFilter.getTime() - (endDateFilter.getTimezoneOffset() * 60000 ))
                    .toISOString()
                    .split("T")[0];
  let item = {
    name: t('page.fnb.perDayPage.perPersonSpendingPerDayName'),
    tableHeaders: {
      "MvOrdersSummaryEM.ordersCompletedAt.day": { name: t('page.fnb.perDayPage.tableHeader.date'), type: 'date'},
    },
    grid: { xs: 12 },
    export: { allowExport: true, filename: 'per_person_spending_per_day_export_' + format(startDateFilter, 'yyyyMMddHHmmss') +
      '_' + format(endDateFilter, 'yyyyMMddHHmmss') },
    pivotConfig: {
      x: [
        "MvOrdersSummaryEM.ordersCompletedAt.day",
      ],
      y: [
        "MvOrdersSummaryEM.shipFromStoreCode", 'measures'
      ],
    },
    pivotRename: ["RT08,MvOrdersSummaryEM.perPersonSpending", "WTC", 
      "RT12,MvOrdersSummaryEM.perPersonSpending", "MB", 
      "RT13,MvOrdersSummaryEM.perPersonSpending", "QB", 
      "RT15,MvOrdersSummaryEM.perPersonSpending", "OPM", 
      "RT16,MvOrdersSummaryEM.perPersonSpending", "TST", 
      "RT17,MvOrdersSummaryEM.perPersonSpending", "MCP", 
      "RT18,MvOrdersSummaryEM.perPersonSpending", "TPTM",
      ",MvOrdersSummaryEM.perPersonSpending", ""],
    withDoW: true,
    doWHeader: "MvOrdersSummaryEM.ordersCompletedAt.day",
    doWHeaderName: t('page.fnb.perDayPage.tableHeader.dow')
  }
  let query = {
    ...OmnitechQueries.mvOrdersSummaryEM.PerPersonSpendingByStorePerDayEMHist.query,
    "timeDimensions": [
      {
        "dimension": "MvOrdersSummaryEM.ordersCompletedAt",
        "dateRange": [ startDateString + " 00:00:00",
         endDateString + " 23:59:59" ],
        "granularity": "day",
      }
    ],
    "timezone": "Asia/Hong_Kong"
  }
  if(isRefresh)
    query = {}
  const result = useCubeQuery(query, {resetResultSetOnChange: true})
  if(isRefresh)
    setRefresh(false)
  return { item, result }
}

const NoOfOrderPerDayQuery = (startDateFilter, endDateFilter, isRefresh, setRefresh) => {
  const { t } = useTranslation();
  if(startDateFilter == null && endDateFilter == null){
    startDateFilter = new Date()
    startDateFilter.setHours(0,0,0,0);
    endDateFilter = new Date()
    endDateFilter.setHours(23,59,59,999);
  }
  else if(startDateFilter == null){
    startDateFilter = setHours(endDateFilter, 0)
    startDateFilter = setMinutes(startDateFilter, 0)
    startDateFilter = setSeconds(startDateFilter, 0)
    startDateFilter = setMilliseconds(startDateFilter, 0)
  }
  else if (endDateFilter == null){
    endDateFilter = setHours(startDateFilter, 23)
    endDateFilter = setMinutes(endDateFilter, 59)
    endDateFilter = setSeconds(endDateFilter, 59)
    endDateFilter = setMilliseconds(endDateFilter, 999)
  }
  let startDateString = new Date(startDateFilter.getTime() - (startDateFilter.getTimezoneOffset() * 60000 ))
                    .toISOString()
                    .split("T")[0];
  let endDateString = new Date(endDateFilter.getTime() - (endDateFilter.getTimezoneOffset() * 60000 ))
                    .toISOString()
                    .split("T")[0];
  let item = {
    name: t('page.fnb.perDayPage.noOfOrderPerDayName'),
    tableHeaders: {
      "MvOrdersSummaryEM.ordersCompletedAt.day": { name: t('page.fnb.perDayPage.tableHeader.date'), type: 'date'},
    },
    grid: { xs: 12 },
    export: { allowExport: true, filename: 'no_of_order_per_day_export_' + format(startDateFilter, 'yyyyMMddHHmmss') +
      '_' + format(endDateFilter, 'yyyyMMddHHmmss') },
    pivotConfig: {
      x: [
        "MvOrdersSummaryEM.ordersCompletedAt.day",
      ],
      y: [
        "MvOrdersSummaryEM.shipFromStoreCode", 'measures'
      ],
    },
    pivotRename: ["RT08,MvOrdersSummaryEM.count", "WTC", 
      "RT12,MvOrdersSummaryEM.count", "MB", 
      "RT13,MvOrdersSummaryEM.count", "QB", 
      "RT15,MvOrdersSummaryEM.count", "OPM", 
      "RT16,MvOrdersSummaryEM.count", "TST", 
      "RT17,MvOrdersSummaryEM.count", "MCP", 
      "RT18,MvOrdersSummaryEM.count", "TPTM",
      ",MvOrdersSummaryEM.count", ""],
    withDoW: true,
    doWHeader: "MvOrdersSummaryEM.ordersCompletedAt.day",
    doWHeaderName: t('page.fnb.perDayPage.tableHeader.dow')
  }
  
  let query = {
    ...OmnitechQueries.mvOrdersSummaryEM.NoOfOrderByStorePerDayEMHist.query,
    "timeDimensions": [
      {
        "dimension": "MvOrdersSummaryEM.ordersCompletedAt",
        "dateRange": [ startDateString + " 00:00:00",
         endDateString + " 23:59:59" ],
        "granularity": "day",
      }
    ],
    "timezone": "Asia/Hong_Kong"
  }
  if(isRefresh)
    query = {}
  const result = useCubeQuery(query, {resetResultSetOnChange: true})
  if(isRefresh)
    setRefresh(false)
  return { item, result }
}

const PerOrderSpendingPerDayQuery = (startDateFilter, endDateFilter, isRefresh, setRefresh) => {
  const { t } = useTranslation();
  if(startDateFilter == null && endDateFilter == null){
    startDateFilter = new Date()
    startDateFilter.setHours(0,0,0,0);
    endDateFilter = new Date()
    endDateFilter.setHours(23,59,59,999);
  }
  else if(startDateFilter == null){
    startDateFilter = setHours(endDateFilter, 0)
    startDateFilter = setMinutes(startDateFilter, 0)
    startDateFilter = setSeconds(startDateFilter, 0)
    startDateFilter = setMilliseconds(startDateFilter, 0)
  }
  else if (endDateFilter == null){
    endDateFilter = setHours(startDateFilter, 23)
    endDateFilter = setMinutes(endDateFilter, 59)
    endDateFilter = setSeconds(endDateFilter, 59)
    endDateFilter = setMilliseconds(endDateFilter, 999)
  }
  let startDateString = new Date(startDateFilter.getTime() - (startDateFilter.getTimezoneOffset() * 60000 ))
                    .toISOString()
                    .split("T")[0];
  let endDateString = new Date(endDateFilter.getTime() - (endDateFilter.getTimezoneOffset() * 60000 ))
                    .toISOString()
                    .split("T")[0];
  let item = {
    name: t('page.fnb.perDayPage.perOrderSpendingPerDayName'),
    tableHeaders: {
      "MvOrdersSummaryEM.ordersCompletedAt.day": { name: t('page.fnb.perDayPage.tableHeader.date'), type: 'date'},
    },
    grid: { xs: 12 },
    export: { allowExport: true, filename: 'per_order_spending_per_day_export_' + format(startDateFilter, 'yyyyMMddHHmmss') +
      '_' + format(endDateFilter, 'yyyyMMddHHmmss') },
    pivotConfig: {
      x: [
        "MvOrdersSummaryEM.ordersCompletedAt.day",
      ],
      y: [
        "MvOrdersSummaryEM.shipFromStoreCode", 'measures'
      ],
    },
    pivotRename: ["RT08,MvOrdersSummaryEM.avgBillAmount", "WTC", 
      "RT12,MvOrdersSummaryEM.avgBillAmount", "MB", 
      "RT13,MvOrdersSummaryEM.avgBillAmount", "QB", 
      "RT15,MvOrdersSummaryEM.avgBillAmount", "OPM", 
      "RT16,MvOrdersSummaryEM.avgBillAmount", "TST", 
      "RT17,MvOrdersSummaryEM.avgBillAmount", "MCP", 
      "RT18,MvOrdersSummaryEM.avgBillAmount", "TPTM",
      ",MvOrdersSummaryEM.avgBillAmount", ""],
    withDoW: true,
    doWHeader: "MvOrdersSummaryEM.ordersCompletedAt.day",
    doWHeaderName: t('page.fnb.perDayPage.tableHeader.dow')
  }
  let query = {
    ...OmnitechQueries.mvOrdersSummaryEM.PerOrderSpendingByStorePerDayEMHist.query,
    "timeDimensions": [
      {
        "dimension": "MvOrdersSummaryEM.ordersCompletedAt",
        "dateRange": [ startDateString + " 00:00:00",
          endDateString + " 23:59:59" ],
        "granularity": "day",
      }
    ],
    "timezone": "Asia/Hong_Kong"
  }
  if(isRefresh)
    query = {}
  const result = useCubeQuery(query, {resetResultSetOnChange: true})
  if(isRefresh)
    setRefresh(false)
  return { item, result }
}

const DiscountPerDayQuery = (startDateFilter, endDateFilter, isRefresh, setRefresh) => {
  const { t } = useTranslation();
  if(startDateFilter == null && endDateFilter == null){
    startDateFilter = new Date()
    startDateFilter.setHours(0,0,0,0);
    endDateFilter = new Date()
    endDateFilter.setHours(23,59,59,999);
  }
  else if(startDateFilter == null){
    startDateFilter = setHours(endDateFilter, 0)
    startDateFilter = setMinutes(startDateFilter, 0)
    startDateFilter = setSeconds(startDateFilter, 0)
    startDateFilter = setMilliseconds(startDateFilter, 0)
  }
  else if (endDateFilter == null){
    endDateFilter = setHours(startDateFilter, 23)
    endDateFilter = setMinutes(endDateFilter, 59)
    endDateFilter = setSeconds(endDateFilter, 59)
    endDateFilter = setMilliseconds(endDateFilter, 999)
  }
  let startDateString = new Date(startDateFilter.getTime() - (startDateFilter.getTimezoneOffset() * 60000 ))
                    .toISOString()
                    .split("T")[0];
  let endDateString = new Date(endDateFilter.getTime() - (endDateFilter.getTimezoneOffset() * 60000 ))
                    .toISOString()
                    .split("T")[0];
  let item = {
    name: t('page.fnb.perDayPage.discountPerDayName'),
    tableHeaders: {
      "MvOrdersSummary.ordersCompletedAt.day": { name: t('page.fnb.perDayPage.tableHeader.date'), type: 'date'},
    },
    grid: { xs: 12 },
    export: { allowExport: true, filename: 'discount_per_day_export_' + format(startDateFilter, 'yyyyMMddHHmmss') +
      '_' + format(endDateFilter, 'yyyyMMddHHmmss') },
    pivotConfig: {
      x: [
        "MvOrdersSummary.ordersCompletedAt.day",
      ],
      y: [
        "MvOrdersSummary.shipFromStoreCode", 'measures'
      ],
    },
    pivotRename: [
      "RT08,MvOrdersSummary.sumDiscounts", "WTC", 
      "RT12,MvOrdersSummary.sumDiscounts", "MB", 
      "RT13,MvOrdersSummary.sumDiscounts", "QB", 
      "RT15,MvOrdersSummary.sumDiscounts", "OPM", 
      "RT16,MvOrdersSummary.sumDiscounts", "TST", 
      "RT17,MvOrdersSummary.sumDiscounts", "MCP", 
      "RT18,MvOrdersSummary.sumDiscounts", "TPTM",
      ",MvOrdersSummary.sumDiscounts", ""],
    withDoW: true,
    withPivotSum: true,
    pivotSumHeaderName: t('page.fnb.perDayPage.tableHeader.total'),
    doWHeader: "MvOrdersSummary.ordersCompletedAt.day",
    doWHeaderName: t('page.fnb.perDayPage.tableHeader.dow')
  }
  let query = {
    ...OmnitechQueries.mvOrdersSummary.DiscountByStorePerDayHist.query,
    "timeDimensions": [
      {
        "dimension": "MvOrdersSummary.ordersCompletedAt",
        "dateRange": [ startDateString + " 00:00:00",
         endDateString + " 23:59:59" ],
        "granularity": "day",
      }
    ],
    "timezone": "Asia/Hong_Kong"
  }
  if(isRefresh)
    query = {}
  const result = useCubeQuery(query, {resetResultSetOnChange: true})
  if(isRefresh)
    setRefresh(false)
  return { item, result }
}

const BeverageGrossSalesPerDayQuery = (startDateFilter, endDateFilter, isRefresh, setRefresh) => {
  const { t } = useTranslation();
  if(startDateFilter == null && endDateFilter == null){
    startDateFilter = new Date()
    startDateFilter.setHours(0,0,0,0);
    endDateFilter = new Date()
    endDateFilter.setHours(23,59,59,999);
  }
  else if(startDateFilter == null){
    startDateFilter = setHours(endDateFilter, 0)
    startDateFilter = setMinutes(startDateFilter, 0)
    startDateFilter = setSeconds(startDateFilter, 0)
    startDateFilter = setMilliseconds(startDateFilter, 0)
  }
  else if (endDateFilter == null){
    endDateFilter = setHours(startDateFilter, 23)
    endDateFilter = setMinutes(endDateFilter, 59)
    endDateFilter = setSeconds(endDateFilter, 59)
    endDateFilter = setMilliseconds(endDateFilter, 999)
  }
  let startDateString = new Date(startDateFilter.getTime() - (startDateFilter.getTimezoneOffset() * 60000 ))
                    .toISOString()
                    .split("T")[0];
  let endDateString = new Date(endDateFilter.getTime() - (endDateFilter.getTimezoneOffset() * 60000 ))
                    .toISOString()
                    .split("T")[0];
  let item = {
    name: t('page.fnb.perDayPage.beverageGrossSalesPerDayName'),
    tableHeaders: {
      "MvOrderTransactions.ordersCompletedAt.day": { name: t('page.fnb.perDayPage.tableHeader.date'), type: 'date'},
    },
    grid: { xs: 12 },
    export: { allowExport: true, filename: 'beverage_gross_sales_per_day_export_' + format(startDateFilter, 'yyyyMMddHHmmss') +
      '_' + format(endDateFilter, 'yyyyMMddHHmmss') },
    pivotConfig: {
      x: [
        "MvOrderTransactions.ordersCompletedAt.day",
      ],
      y: [
        "MvOrderTransactions.shipFromStoreCode", 'measures'
      ],
    },
    pivotRename: [
      "RT08,MvOrderTransactions.sumLineTotal", "WTC", 
      "RT12,MvOrderTransactions.sumLineTotal", "MB", 
      "RT13,MvOrderTransactions.sumLineTotal", "QB", 
      "RT15,MvOrderTransactions.sumLineTotal", "OPM", 
      "RT16,MvOrderTransactions.sumLineTotal", "TST", 
      "RT17,MvOrderTransactions.sumLineTotal", "MCP", 
      "RT18,MvOrderTransactions.sumLineTotal", "TPTM",
      ",MvOrderTransactions.sumLineTotal", ""],
    withDoW: true,
    doWHeader: "MvOrderTransactions.ordersCompletedAt.day",
    doWHeaderName: t('page.fnb.perDayPage.tableHeader.dow')
  }
  let query = {
    ...OmnitechQueries.mvOrderTransactions.TotalBeverageGrossSalesByStorePerDayHist.query,
    "timeDimensions": [
      {
        "dimension": "MvOrderTransactions.ordersCompletedAt",
        "dateRange": [ startDateString + " 00:00:00",
         endDateString + " 23:59:59" ],
        "granularity": "day",
      }
    ],
    "timezone": "Asia/Hong_Kong"
  }
  if(isRefresh)
    query = {}
  const result = useCubeQuery(query, {resetResultSetOnChange: true})
  if(isRefresh)
    setRefresh(false)
  return { item, result }
}

const DeliverooNetSalesPerDayQuery = (startDateFilter, endDateFilter, isRefresh, setRefresh) => {
  const { t } = useTranslation();
  if(startDateFilter == null && endDateFilter == null){
    startDateFilter = new Date()
    startDateFilter.setHours(0,0,0,0);
    endDateFilter = new Date()
    endDateFilter.setHours(23,59,59,999);
  }
  else if(startDateFilter == null){
    startDateFilter = setHours(endDateFilter, 0)
    startDateFilter = setMinutes(startDateFilter, 0)
    startDateFilter = setSeconds(startDateFilter, 0)
    startDateFilter = setMilliseconds(startDateFilter, 0)
  }
  else if (endDateFilter == null){
    endDateFilter = setHours(startDateFilter, 23)
    endDateFilter = setMinutes(endDateFilter, 59)
    endDateFilter = setSeconds(endDateFilter, 59)
    endDateFilter = setMilliseconds(endDateFilter, 999)
  }
  let startDateString = new Date(startDateFilter.getTime() - (startDateFilter.getTimezoneOffset() * 60000 ))
                    .toISOString()
                    .split("T")[0];
  let endDateString = new Date(endDateFilter.getTime() - (endDateFilter.getTimezoneOffset() * 60000 ))
                    .toISOString()
                    .split("T")[0];
  let item = {
    name: t('page.fnb.perDayPage.deliverooNetSalesPerDayName'),
    tableHeaders: {
      "MvPaymentRequests.ordersCompletedAt.day": { name: t('page.fnb.perDayPage.tableHeader.date'), type: 'date'},
    },
    grid: { xs: 12 },
    export: { allowExport: true, filename: 'deliveroo_net_sales_per_day_export_' + format(startDateFilter, 'yyyyMMddHHmmss') +
      '_' + format(endDateFilter, 'yyyyMMddHHmmss') },
    pivotConfig: {
      x: [
        "MvPaymentRequests.ordersCompletedAt.day",
      ],
      y: [
        "MvPaymentRequests.shipmentsFromStoreCode", 'measures'
      ],
    },
    pivotRename: [
      "RT08,MvPaymentRequests.sumAmount", "WTC", 
      "RT12,MvPaymentRequests.sumAmount", "MB", 
      "RT13,MvPaymentRequests.sumAmount", "QB", 
      "RT15,MvPaymentRequests.sumAmount", "OPM", 
      "RT16,MvPaymentRequests.sumAmount", "TST", 
      "RT17,MvPaymentRequests.sumAmount", "MCP", 
      "RT18,MvPaymentRequests.sumAmount", "TPTM",
      ",MvPaymentRequests.sumAmount", ""],
    withDoW: true,
    withPivotSum: true,
    pivotSumHeaderName: t('page.fnb.perDayPage.tableHeader.total'),
    doWHeader: "MvPaymentRequests.ordersCompletedAt.day",
    doWHeaderName: t('page.fnb.perDayPage.tableHeader.dow')
  }
  let query = {
    ...OmnitechQueries.mvPaymentRequests.DeliveryNetSalesByStorePerDayEMHist.query,
    "filters": (OmnitechQueries.mvPaymentRequests.DeliveryNetSalesByStorePerDayEMHist.query.filters || []).concat(
      {
        "member": "MvPaymentRequests.paymentProviderCode",
        "operator": "equals",
        "values": [
          "deliveroo"
        ]
      }
    ),
    "timeDimensions": [
      {
        "dimension": "MvPaymentRequests.ordersCompletedAt",
        "dateRange": [ startDateString + " 00:00:00",
         endDateString + " 23:59:59" ],
        "granularity": "day",
      }
    ],
    "timezone": "Asia/Hong_Kong"
  }
  if(isRefresh)
    query = {}
  const result = useCubeQuery(query, {resetResultSetOnChange: true})
  if(isRefresh)
    setRefresh(false)
  return { item, result }
}

const OpenriceNetSalesPerDayQuery = (startDateFilter, endDateFilter, isRefresh, setRefresh) => {
  const { t } = useTranslation();
  if(startDateFilter == null && endDateFilter == null){
    startDateFilter = new Date()
    startDateFilter.setHours(0,0,0,0);
    endDateFilter = new Date()
    endDateFilter.setHours(23,59,59,999);
  }
  else if(startDateFilter == null){
    startDateFilter = setHours(endDateFilter, 0)
    startDateFilter = setMinutes(startDateFilter, 0)
    startDateFilter = setSeconds(startDateFilter, 0)
    startDateFilter = setMilliseconds(startDateFilter, 0)
  }
  else if (endDateFilter == null){
    endDateFilter = setHours(startDateFilter, 23)
    endDateFilter = setMinutes(endDateFilter, 59)
    endDateFilter = setSeconds(endDateFilter, 59)
    endDateFilter = setMilliseconds(endDateFilter, 999)
  }
  let startDateString = new Date(startDateFilter.getTime() - (startDateFilter.getTimezoneOffset() * 60000 ))
                    .toISOString()
                    .split("T")[0];
  let endDateString = new Date(endDateFilter.getTime() - (endDateFilter.getTimezoneOffset() * 60000 ))
                    .toISOString()
                    .split("T")[0];
  let item = {
    name: t('page.fnb.perDayPage.openriceNetSalesPerDayName'),
    tableHeaders: {
      "MvPaymentRequests.ordersCompletedAt.day": { name: t('page.fnb.perDayPage.tableHeader.date'), type: 'date'},
    },
    grid: { xs: 12 },
    export: { allowExport: true, filename: 'openrice_net_sales_per_day_export_' + format(startDateFilter, 'yyyyMMddHHmmss') +
      '_' + format(endDateFilter, 'yyyyMMddHHmmss') },
    pivotConfig: {
      x: [
        "MvPaymentRequests.ordersCompletedAt.day",
      ],
      y: [
        "MvPaymentRequests.shipmentsFromStoreCode", 'measures'
      ],
    },
    pivotRename: [
      "RT08,MvPaymentRequests.sumAmount", "WTC", 
      "RT12,MvPaymentRequests.sumAmount", "MB", 
      "RT13,MvPaymentRequests.sumAmount", "QB", 
      "RT15,MvPaymentRequests.sumAmount", "OPM", 
      "RT16,MvPaymentRequests.sumAmount", "TST", 
      "RT17,MvPaymentRequests.sumAmount", "MCP", 
      "RT18,MvPaymentRequests.sumAmount", "TPTM",
      ",MvPaymentRequests.sumAmount", ""],
    withDoW: true,
    withPivotSum: true,
    pivotSumHeaderName: t('page.fnb.perDayPage.tableHeader.total'),
    doWHeader: "MvPaymentRequests.ordersCompletedAt.day",
    doWHeaderName: t('page.fnb.perDayPage.tableHeader.dow')
  }
  let query = {
    ...OmnitechQueries.mvPaymentRequests.DeliveryNetSalesByStorePerDayEMHist.query,
    "filters": (OmnitechQueries.mvPaymentRequests.DeliveryNetSalesByStorePerDayEMHist.query.filters || []).concat(
      {
        "member": "MvPaymentRequests.paymentProviderCode",
        "operator": "equals",
        "values": [
          "openrice"
        ]
      }
    ),
    "timeDimensions": [
      {
        "dimension": "MvPaymentRequests.ordersCompletedAt",
        "dateRange": [ startDateString + " 00:00:00",
         endDateString + " 23:59:59" ],
        "granularity": "day",
      }
    ],
    "timezone": "Asia/Hong_Kong"
  }
  if(isRefresh)
    query = {}
  const result = useCubeQuery(query, {resetResultSetOnChange: true})
  if(isRefresh)
    setRefresh(false)
  return { item, result }
}

const FoodPandaNetSalesPerDayQuery = (startDateFilter, endDateFilter, isRefresh, setRefresh) => {
  const { t } = useTranslation();
  if(startDateFilter == null && endDateFilter == null){
    startDateFilter = new Date()
    startDateFilter.setHours(0,0,0,0);
    endDateFilter = new Date()
    endDateFilter.setHours(23,59,59,999);
  }
  else if(startDateFilter == null){
    startDateFilter = setHours(endDateFilter, 0)
    startDateFilter = setMinutes(startDateFilter, 0)
    startDateFilter = setSeconds(startDateFilter, 0)
    startDateFilter = setMilliseconds(startDateFilter, 0)
  }
  else if (endDateFilter == null){
    endDateFilter = setHours(startDateFilter, 23)
    endDateFilter = setMinutes(endDateFilter, 59)
    endDateFilter = setSeconds(endDateFilter, 59)
    endDateFilter = setMilliseconds(endDateFilter, 999)
  }
  let startDateString = new Date(startDateFilter.getTime() - (startDateFilter.getTimezoneOffset() * 60000 ))
                    .toISOString()
                    .split("T")[0];
  let endDateString = new Date(endDateFilter.getTime() - (endDateFilter.getTimezoneOffset() * 60000 ))
                    .toISOString()
                    .split("T")[0];
  let item = {
    name: t('page.fnb.perDayPage.foodpandaNetSalesPerDayName'),
    tableHeaders: {
      "MvPaymentRequests.ordersCompletedAt.day": { name: t('page.fnb.perDayPage.tableHeader.date'), type: 'date'},
    },
    grid: { xs: 12 },
    export: { allowExport: true, filename: 'foodpanda_net_sales_per_day_export_' + format(startDateFilter, 'yyyyMMddHHmmss') +
      '_' + format(endDateFilter, 'yyyyMMddHHmmss') },
    pivotConfig: {
      x: [
        "MvPaymentRequests.ordersCompletedAt.day",
      ],
      y: [
        "MvPaymentRequests.shipmentsFromStoreCode", 'measures'
      ],
    },
    pivotRename: [
      "RT08,MvPaymentRequests.sumAmount", "WTC", 
      "RT12,MvPaymentRequests.sumAmount", "MB", 
      "RT13,MvPaymentRequests.sumAmount", "QB", 
      "RT15,MvPaymentRequests.sumAmount", "OPM", 
      "RT16,MvPaymentRequests.sumAmount", "TST", 
      "RT17,MvPaymentRequests.sumAmount", "MCP", 
      "RT18,MvPaymentRequests.sumAmount", "TPTM",
      ",MvPaymentRequests.sumAmount", ""],
    withDoW: true,
    withPivotSum: true,
    pivotSumHeaderName: t('page.fnb.perDayPage.tableHeader.total'),
    doWHeader: "MvPaymentRequests.ordersCompletedAt.day",
    doWHeaderName: t('page.fnb.perDayPage.tableHeader.dow')
  }
  let query = {
    ...OmnitechQueries.mvPaymentRequests.DeliveryNetSalesByStorePerDayEMHist.query,
    "filters": (OmnitechQueries.mvPaymentRequests.DeliveryNetSalesByStorePerDayEMHist.query.filters || []).concat(
      {
        "member": "MvPaymentRequests.paymentProviderCode",
        "operator": "equals",
        "values": [
          "foodpanda"
        ]
      }
    ),
    "timeDimensions": [
      {
        "dimension": "MvPaymentRequests.ordersCompletedAt",
        "dateRange": [ startDateString + " 00:00:00",
         endDateString + " 23:59:59" ],
        "granularity": "day",
      }
    ],
    "timezone": "Asia/Hong_Kong"
  }
  if(isRefresh)
    query = {}
  const result = useCubeQuery(query, {resetResultSetOnChange: true})
  if(isRefresh)
    setRefresh(false)
  return { item, result }
}

const BrainFreezeNetSalesPerDayQuery = (startDateFilter, endDateFilter, isRefresh, setRefresh) => {
  const { t } = useTranslation();
  if(startDateFilter == null && endDateFilter == null){
    startDateFilter = new Date()
    startDateFilter.setHours(0,0,0,0);
    endDateFilter = new Date()
    endDateFilter.setHours(23,59,59,999);
  }
  else if(startDateFilter == null){
    startDateFilter = setHours(endDateFilter, 0)
    startDateFilter = setMinutes(startDateFilter, 0)
    startDateFilter = setSeconds(startDateFilter, 0)
    startDateFilter = setMilliseconds(startDateFilter, 0)
  }
  else if (endDateFilter == null){
    endDateFilter = setHours(startDateFilter, 23)
    endDateFilter = setMinutes(endDateFilter, 59)
    endDateFilter = setSeconds(endDateFilter, 59)
    endDateFilter = setMilliseconds(endDateFilter, 999)
  }
  let startDateString = new Date(startDateFilter.getTime() - (startDateFilter.getTimezoneOffset() * 60000 ))
                    .toISOString()
                    .split("T")[0];
  let endDateString = new Date(endDateFilter.getTime() - (endDateFilter.getTimezoneOffset() * 60000 ))
                    .toISOString()
                    .split("T")[0];
  let item = {
    name: t('page.fnb.perDayPage.brainFreezeNetSalesPerDayName'),
    tableHeaders: {
      "MvPaymentRequests.ordersCompletedAt.day": { name: t('page.fnb.perDayPage.tableHeader.date'), type: 'date'},
    },
    grid: { xs: 12 },
    export: { allowExport: true, filename: 'brainfreeze_net_sales_per_day_export_' + format(startDateFilter, 'yyyyMMddHHmmss') +
      '_' + format(endDateFilter, 'yyyyMMddHHmmss') },
    pivotConfig: {
      x: [
        "MvPaymentRequests.ordersCompletedAt.day",
      ],
      y: [
        "MvPaymentRequests.shipmentsFromStoreCode", 'measures'
      ],
    },
    pivotRename: [
      "RT08,MvPaymentRequests.sumAmount", "WTC", 
      "RT12,MvPaymentRequests.sumAmount", "MB", 
      "RT13,MvPaymentRequests.sumAmount", "QB", 
      "RT15,MvPaymentRequests.sumAmount", "OPM", 
      "RT16,MvPaymentRequests.sumAmount", "TST", 
      "RT17,MvPaymentRequests.sumAmount", "MCP", 
      "RT18,MvPaymentRequests.sumAmount", "TPTM",
      ",MvPaymentRequests.sumAmount", ""],
    withDoW: true,
    withPivotSum: true,
    pivotSumHeaderName: t('page.fnb.perDayPage.tableHeader.total'),
    doWHeader: "MvPaymentRequests.ordersCompletedAt.day",
    doWHeaderName: t('page.fnb.perDayPage.tableHeader.dow')
  }
  let query = {
    ...OmnitechQueries.mvPaymentRequests.DeliveryNetSalesByStorePerDayEMHist.query,
    "filters": (OmnitechQueries.mvPaymentRequests.DeliveryNetSalesByStorePerDayEMHist.query.filters || []).concat(
      {
        "member": "MvPaymentRequests.paymentProviderCode",
        "operator": "equals",
        "values": [
          "brainfreeze"
        ]
      }
    ),
    "timeDimensions": [
      {
        "dimension": "MvPaymentRequests.ordersCompletedAt",
        "dateRange": [ startDateString + " 00:00:00",
         endDateString + " 23:59:59" ],
        "granularity": "day",
      }
    ],
    "timezone": "Asia/Hong_Kong"
  }
  if(isRefresh)
    query = {}
  const result = useCubeQuery(query, {resetResultSetOnChange: true})
  if(isRefresh)
    setRefresh(false)
  return { item, result }
}

const HKTVMallNetSalesPerDayQuery = (startDateFilter, endDateFilter, isRefresh, setRefresh) => {
  const { t } = useTranslation();
  if(startDateFilter == null && endDateFilter == null){
    startDateFilter = new Date()
    startDateFilter.setHours(0,0,0,0);
    endDateFilter = new Date()
    endDateFilter.setHours(23,59,59,999);
  }
  else if(startDateFilter == null){
    startDateFilter = setHours(endDateFilter, 0)
    startDateFilter = setMinutes(startDateFilter, 0)
    startDateFilter = setSeconds(startDateFilter, 0)
    startDateFilter = setMilliseconds(startDateFilter, 0)
  }
  else if (endDateFilter == null){
    endDateFilter = setHours(startDateFilter, 23)
    endDateFilter = setMinutes(endDateFilter, 59)
    endDateFilter = setSeconds(endDateFilter, 59)
    endDateFilter = setMilliseconds(endDateFilter, 999)
  }
  let startDateString = new Date(startDateFilter.getTime() - (startDateFilter.getTimezoneOffset() * 60000 ))
                    .toISOString()
                    .split("T")[0];
  let endDateString = new Date(endDateFilter.getTime() - (endDateFilter.getTimezoneOffset() * 60000 ))
                    .toISOString()
                    .split("T")[0];
  let item = {
    name: t('page.fnb.perDayPage.hktvmallNetSalesPerDayName'),
    tableHeaders: {
      "MvPaymentRequests.ordersCompletedAt.day": { name: t('page.fnb.perDayPage.tableHeader.date'), type: 'date'},
    },
    grid: { xs: 12 },
    export: { allowExport: true, filename: 'hktvmall_net_sales_per_day_export_' + format(startDateFilter, 'yyyyMMddHHmmss') +
      '_' + format(endDateFilter, 'yyyyMMddHHmmss') },
    pivotConfig: {
      x: [
        "MvPaymentRequests.ordersCompletedAt.day",
      ],
      y: [
        "MvPaymentRequests.shipmentsFromStoreCode", 'measures'
      ],
    },
    pivotRename: [
      "RT08,MvPaymentRequests.sumAmount", "WTC", 
      "RT12,MvPaymentRequests.sumAmount", "MB", 
      "RT13,MvPaymentRequests.sumAmount", "QB", 
      "RT15,MvPaymentRequests.sumAmount", "OPM", 
      "RT16,MvPaymentRequests.sumAmount", "TST", 
      "RT17,MvPaymentRequests.sumAmount", "MCP", 
      "RT18,MvPaymentRequests.sumAmount", "TPTM",
      ",MvPaymentRequests.sumAmount", ""],
    withDoW: true,
    withPivotSum: true,
    pivotSumHeaderName: t('page.fnb.perDayPage.tableHeader.total'),
    doWHeader: "MvPaymentRequests.ordersCompletedAt.day",
    doWHeaderName: t('page.fnb.perDayPage.tableHeader.dow')
  }
  let query = {
    ...OmnitechQueries.mvPaymentRequests.DeliveryNetSalesByStorePerDayEMHist.query,
    "filters": (OmnitechQueries.mvPaymentRequests.DeliveryNetSalesByStorePerDayEMHist.query.filters || []).concat(
      {
        "member": "MvPaymentRequests.paymentProviderCode",
        "operator": "equals",
        "values": [
          "hktvmall"
        ]
      }
    ),
    "timeDimensions": [
      {
        "dimension": "MvPaymentRequests.ordersCompletedAt",
        "dateRange": [ startDateString + " 00:00:00",
         endDateString + " 23:59:59" ],
        "granularity": "day",
      }
    ],
    "timezone": "Asia/Hong_Kong"
  }
  if(isRefresh)
    query = {}
  const result = useCubeQuery(query, {resetResultSetOnChange: true})
  if(isRefresh)
    setRefresh(false)
  return { item, result }
}

const InlineNetSalesPerDayQuery = (startDateFilter, endDateFilter, isRefresh, setRefresh) => {
  const { t } = useTranslation();
  if(startDateFilter == null && endDateFilter == null){
    startDateFilter = new Date()
    startDateFilter.setHours(0,0,0,0);
    endDateFilter = new Date()
    endDateFilter.setHours(23,59,59,999);
  }
  else if(startDateFilter == null){
    startDateFilter = setHours(endDateFilter, 0)
    startDateFilter = setMinutes(startDateFilter, 0)
    startDateFilter = setSeconds(startDateFilter, 0)
    startDateFilter = setMilliseconds(startDateFilter, 0)
  }
  else if (endDateFilter == null){
    endDateFilter = setHours(startDateFilter, 23)
    endDateFilter = setMinutes(endDateFilter, 59)
    endDateFilter = setSeconds(endDateFilter, 59)
    endDateFilter = setMilliseconds(endDateFilter, 999)
  }
  let startDateString = new Date(startDateFilter.getTime() - (startDateFilter.getTimezoneOffset() * 60000 ))
                    .toISOString()
                    .split("T")[0];
  let endDateString = new Date(endDateFilter.getTime() - (endDateFilter.getTimezoneOffset() * 60000 ))
                    .toISOString()
                    .split("T")[0];
  let item = {
    name: t('page.fnb.perDayPage.inlineNetSalesPerDayName'),
    tableHeaders: {
      "MvPaymentRequests.ordersCompletedAt.day": { name: t('page.fnb.perDayPage.tableHeader.date'), type: 'date'},
    },
    grid: { xs: 12 },
    export: { allowExport: true, filename: 'inline_net_sales_per_day_export_' + format(startDateFilter, 'yyyyMMddHHmmss') +
      '_' + format(endDateFilter, 'yyyyMMddHHmmss') },
    pivotConfig: {
      x: [
        "MvPaymentRequests.ordersCompletedAt.day",
      ],
      y: [
        "MvPaymentRequests.shipmentsFromStoreCode", 'measures'
      ],
    },
    pivotRename: [
      "RT08,MvPaymentRequests.sumAmount", "WTC", 
      "RT12,MvPaymentRequests.sumAmount", "MB", 
      "RT13,MvPaymentRequests.sumAmount", "QB", 
      "RT15,MvPaymentRequests.sumAmount", "OPM", 
      "RT16,MvPaymentRequests.sumAmount", "TST", 
      "RT17,MvPaymentRequests.sumAmount", "MCP", 
      "RT18,MvPaymentRequests.sumAmount", "TPTM",
      ",MvPaymentRequests.sumAmount", ""],
    withDoW: true,
    withPivotSum: true,
    pivotSumHeaderName: t('page.fnb.perDayPage.tableHeader.total'),
    doWHeader: "MvPaymentRequests.ordersCompletedAt.day",
    doWHeaderName: t('page.fnb.perDayPage.tableHeader.dow')
  }
  let query = {
    ...OmnitechQueries.mvPaymentRequests.DeliveryNetSalesByStorePerDayEMHist.query,
    "filters": (OmnitechQueries.mvPaymentRequests.DeliveryNetSalesByStorePerDayEMHist.query.filters || []).concat(
      {
        "member": "MvPaymentRequests.paymentProviderCode",
        "operator": "equals",
        "values": [
          "inline"
        ]
      }
    ),
    "timeDimensions": [
      {
        "dimension": "MvPaymentRequests.ordersCompletedAt",
        "dateRange": [ startDateString + " 00:00:00",
         endDateString + " 23:59:59" ],
        "granularity": "day",
      }
    ],
    "timezone": "Asia/Hong_Kong"
  }
  if(isRefresh)
    query = {}
  const result = useCubeQuery(query, {resetResultSetOnChange: true})
  if(isRefresh)
    setRefresh(false)
  return { item, result }
}

const GroupBuyNetSalesPerDayQuery = (startDateFilter, endDateFilter, isRefresh, setRefresh) => {
  const { t } = useTranslation();
  if(startDateFilter == null && endDateFilter == null){
    startDateFilter = new Date()
    startDateFilter.setHours(0,0,0,0);
    endDateFilter = new Date()
    endDateFilter.setHours(23,59,59,999);
  }
  else if(startDateFilter == null){
    startDateFilter = setHours(endDateFilter, 0)
    startDateFilter = setMinutes(startDateFilter, 0)
    startDateFilter = setSeconds(startDateFilter, 0)
    startDateFilter = setMilliseconds(startDateFilter, 0)
  }
  else if (endDateFilter == null){
    endDateFilter = setHours(startDateFilter, 23)
    endDateFilter = setMinutes(endDateFilter, 59)
    endDateFilter = setSeconds(endDateFilter, 59)
    endDateFilter = setMilliseconds(endDateFilter, 999)
  }
  let startDateString = new Date(startDateFilter.getTime() - (startDateFilter.getTimezoneOffset() * 60000 ))
                    .toISOString()
                    .split("T")[0];
  let endDateString = new Date(endDateFilter.getTime() - (endDateFilter.getTimezoneOffset() * 60000 ))
                    .toISOString()
                    .split("T")[0];
  let item = {
    name: t('page.fnb.perDayPage.groupBuyNetSalesPerDayName'),
    tableHeaders: {
      "MvPaymentRequests.ordersCompletedAt.day": { name: t('page.fnb.perDayPage.tableHeader.date'), type: 'date'},
    },
    grid: { xs: 12 },
    export: { allowExport: true, filename: 'group_buy_net_sales_per_day_export_' + format(startDateFilter, 'yyyyMMddHHmmss') +
      '_' + format(endDateFilter, 'yyyyMMddHHmmss') },
    pivotConfig: {
      x: [
        "MvPaymentRequests.ordersCompletedAt.day",
      ],
      y: [
        "MvPaymentRequests.shipmentsFromStoreCode", 'measures'
      ],
    },
    pivotRename: [
      "RT08,MvPaymentRequests.sumAmount", "WTC", 
      "RT12,MvPaymentRequests.sumAmount", "MB", 
      "RT13,MvPaymentRequests.sumAmount", "QB", 
      "RT15,MvPaymentRequests.sumAmount", "OPM", 
      "RT16,MvPaymentRequests.sumAmount", "TST", 
      "RT17,MvPaymentRequests.sumAmount", "MCP", 
      "RT18,MvPaymentRequests.sumAmount", "TPTM",
      ",MvPaymentRequests.sumAmount", ""],
    withDoW: true,
    withPivotSum: true,
    pivotSumHeaderName: t('page.fnb.perDayPage.tableHeader.total'),
    doWHeader: "MvPaymentRequests.ordersCompletedAt.day",
    doWHeaderName: t('page.fnb.perDayPage.tableHeader.dow')
  }
  let query = {
    ...OmnitechQueries.mvPaymentRequests.DeliveryNetSalesByStorePerDayEMHist.query,
    "filters": (OmnitechQueries.mvPaymentRequests.DeliveryNetSalesByStorePerDayEMHist.query.filters || []).concat(
      {
        "member": "MvPaymentRequests.paymentProviderCode",
        "operator": "equals",
        "values": [
          "groupbuy"
        ]
      }
    ),
    "timeDimensions": [
      {
        "dimension": "MvPaymentRequests.ordersCompletedAt",
        "dateRange": [ startDateString + " 00:00:00",
         endDateString + " 23:59:59" ],
        "granularity": "day",
      }
    ],
    "timezone": "Asia/Hong_Kong"
  }
  if(isRefresh)
    query = {}
  const result = useCubeQuery(query, {resetResultSetOnChange: true})
  if(isRefresh)
    setRefresh(false)
  return { item, result }
}

const DeliverooServiceChargePerDayQuery = (startDateFilter, endDateFilter, isRefresh, setRefresh) => {
  const { t } = useTranslation();
  if(startDateFilter == null && endDateFilter == null){
    startDateFilter = new Date()
    startDateFilter.setHours(0,0,0,0);
    endDateFilter = new Date()
    endDateFilter.setHours(23,59,59,999);
  }
  else if(startDateFilter == null){
    startDateFilter = setHours(endDateFilter, 0)
    startDateFilter = setMinutes(startDateFilter, 0)
    startDateFilter = setSeconds(startDateFilter, 0)
    startDateFilter = setMilliseconds(startDateFilter, 0)
  }
  else if (endDateFilter == null){
    endDateFilter = setHours(startDateFilter, 23)
    endDateFilter = setMinutes(endDateFilter, 59)
    endDateFilter = setSeconds(endDateFilter, 59)
    endDateFilter = setMilliseconds(endDateFilter, 999)
  }
  let startDateString = new Date(startDateFilter.getTime() - (startDateFilter.getTimezoneOffset() * 60000 ))
                    .toISOString()
                    .split("T")[0];
  let endDateString = new Date(endDateFilter.getTime() - (endDateFilter.getTimezoneOffset() * 60000 ))
                    .toISOString()
                    .split("T")[0];
  let item = {
    name: t('page.fnb.perDayPage.deliverooServiceChargePerDayName'),
    tableHeaders: {
      "MvOrderTransactions.ordersCompletedAt.day": { name: t('page.fnb.perDayPage.tableHeader.date'), type: 'date'},
    },
    grid: { xs: 12 },
    export: { allowExport: true, filename: 'deliveroo_service_charge_per_day_export_' + format(startDateFilter, 'yyyyMMddHHmmss') +
      '_' + format(endDateFilter, 'yyyyMMddHHmmss') },
    pivotConfig: {
      x: [
        "MvOrderTransactions.ordersCompletedAt.day",
      ],
      y: [
        "MvOrderTransactions.shipFromStoreCode", 'measures'
      ],
    },
    pivotRename: [
      "RT08,MvOrderTransactions.absSumLineTotal", "WTC", 
      "RT12,MvOrderTransactions.absSumLineTotal", "MB", 
      "RT13,MvOrderTransactions.absSumLineTotal", "QB", 
      "RT15,MvOrderTransactions.absSumLineTotal", "OPM", 
      "RT16,MvOrderTransactions.absSumLineTotal", "TST", 
      "RT17,MvOrderTransactions.absSumLineTotal", "MCP", 
      "RT18,MvOrderTransactions.absSumLineTotal", "TPTM",
      ",MvOrderTransactions.absSumLineTotal", ""],
    withDoW: true,
    withPivotSum: true,
    pivotSumHeaderName: t('page.fnb.perDayPage.tableHeader.total'),
    doWHeader: "MvOrderTransactions.ordersCompletedAt.day",
    doWHeaderName: t('page.fnb.perDayPage.tableHeader.dow')
  }
  let query = {
    ...OmnitechQueries.mvOrderTransactions.DeliverServiceCharge.query,
    "filters": (OmnitechQueries.mvOrderTransactions.DeliverServiceCharge.query.filters || []).concat(
      {
        "member": "MvOrderTransactions.discountCode",
        "operator": "equals",
        "values": [
          "deliveroo_30"
        ]
      }
    ),
    "timeDimensions": [
      {
        "dimension": "MvOrderTransactions.ordersCompletedAt",
        "dateRange": [ startDateString + " 00:00:00",
         endDateString + " 23:59:59" ],
        "granularity": "day",
      }
    ],
    "timezone": "Asia/Hong_Kong"
  }
  if(isRefresh)
    query = {}
  const result = useCubeQuery(query, {resetResultSetOnChange: true})
  if(isRefresh)
    setRefresh(false)
  return { item, result }
}

const OpenriceServiceChargePerDayQuery = (startDateFilter, endDateFilter, isRefresh, setRefresh) => {
  const { t } = useTranslation();
  if(startDateFilter == null && endDateFilter == null){
    startDateFilter = new Date()
    startDateFilter.setHours(0,0,0,0);
    endDateFilter = new Date()
    endDateFilter.setHours(23,59,59,999);
  }
  else if(startDateFilter == null){
    startDateFilter = setHours(endDateFilter, 0)
    startDateFilter = setMinutes(startDateFilter, 0)
    startDateFilter = setSeconds(startDateFilter, 0)
    startDateFilter = setMilliseconds(startDateFilter, 0)
  }
  else if (endDateFilter == null){
    endDateFilter = setHours(startDateFilter, 23)
    endDateFilter = setMinutes(endDateFilter, 59)
    endDateFilter = setSeconds(endDateFilter, 59)
    endDateFilter = setMilliseconds(endDateFilter, 999)
  }
  let startDateString = new Date(startDateFilter.getTime() - (startDateFilter.getTimezoneOffset() * 60000 ))
                    .toISOString()
                    .split("T")[0];
  let endDateString = new Date(endDateFilter.getTime() - (endDateFilter.getTimezoneOffset() * 60000 ))
                    .toISOString()
                    .split("T")[0];
  let item = {
    name: t('page.fnb.perDayPage.openriceServiceChargePerDayName'),
    tableHeaders: {
      "MvOrderTransactions.ordersCompletedAt.day": { name: t('page.fnb.perDayPage.tableHeader.date'), type: 'date'},
    },
    grid: { xs: 12 },
    export: { allowExport: true, filename: 'openrice_service_charge_per_day_export_' + format(startDateFilter, 'yyyyMMddHHmmss') +
      '_' + format(endDateFilter, 'yyyyMMddHHmmss') },
    pivotConfig: {
      x: [
        "MvOrderTransactions.ordersCompletedAt.day",
      ],
      y: [
        "MvOrderTransactions.shipFromStoreCode", 'measures'
      ],
    },
    pivotRename: [
      "RT08,MvOrderTransactions.absSumLineTotal", "WTC", 
      "RT12,MvOrderTransactions.absSumLineTotal", "MB", 
      "RT13,MvOrderTransactions.absSumLineTotal", "QB", 
      "RT15,MvOrderTransactions.absSumLineTotal", "OPM", 
      "RT16,MvOrderTransactions.absSumLineTotal", "TST", 
      "RT17,MvOrderTransactions.absSumLineTotal", "MCP", 
      "RT18,MvOrderTransactions.absSumLineTotal", "TPTM",
      ",MvOrderTransactions.absSumLineTotal", ""],
    withDoW: true,
    withPivotSum: true,
    pivotSumHeaderName: t('page.fnb.perDayPage.tableHeader.total'),
    doWHeader: "MvOrderTransactions.ordersCompletedAt.day",
    doWHeaderName: t('page.fnb.perDayPage.tableHeader.dow')
  }
  let query = {
    ...OmnitechQueries.mvOrderTransactions.DeliverServiceCharge.query,
    "filters": (OmnitechQueries.mvOrderTransactions.DeliverServiceCharge.query.filters || []).concat(
      {
        "member": "MvOrderTransactions.discountCode",
        "operator": "equals",
        "values": [
          "openrice_spu_6_payment"
        ]
      }
    ),
    "timeDimensions": [
      {
        "dimension": "MvOrderTransactions.ordersCompletedAt",
        "dateRange": [ startDateString + " 00:00:00",
         endDateString + " 23:59:59" ],
        "granularity": "day",
      }
    ],
    "timezone": "Asia/Hong_Kong"
  }
  if(isRefresh)
    query = {}
  const result = useCubeQuery(query, {resetResultSetOnChange: true})
  if(isRefresh)
    setRefresh(false)
  return { item, result }
}

const FoodPandaServiceChargePerDayQuery = (startDateFilter, endDateFilter, isRefresh, setRefresh) => {
  const { t } = useTranslation();
  if(startDateFilter == null && endDateFilter == null){
    startDateFilter = new Date()
    startDateFilter.setHours(0,0,0,0);
    endDateFilter = new Date()
    endDateFilter.setHours(23,59,59,999);
  }
  else if(startDateFilter == null){
    startDateFilter = setHours(endDateFilter, 0)
    startDateFilter = setMinutes(startDateFilter, 0)
    startDateFilter = setSeconds(startDateFilter, 0)
    startDateFilter = setMilliseconds(startDateFilter, 0)
  }
  else if (endDateFilter == null){
    endDateFilter = setHours(startDateFilter, 23)
    endDateFilter = setMinutes(endDateFilter, 59)
    endDateFilter = setSeconds(endDateFilter, 59)
    endDateFilter = setMilliseconds(endDateFilter, 999)
  }
  let startDateString = new Date(startDateFilter.getTime() - (startDateFilter.getTimezoneOffset() * 60000 ))
                    .toISOString()
                    .split("T")[0];
  let endDateString = new Date(endDateFilter.getTime() - (endDateFilter.getTimezoneOffset() * 60000 ))
                    .toISOString()
                    .split("T")[0];
  let item = {
    name: t('page.fnb.perDayPage.foodpandaServiceChargePerDayName'),
    tableHeaders: {
      "MvOrderTransactions.ordersCompletedAt.day": { name: t('page.fnb.perDayPage.tableHeader.date'), type: 'date'},
    },
    grid: { xs: 12 },
    export: { allowExport: true, filename: 'foodpanda_service_charge_per_day_export_' + format(startDateFilter, 'yyyyMMddHHmmss') +
      '_' + format(endDateFilter, 'yyyyMMddHHmmss') },
    pivotConfig: {
      x: [
        "MvOrderTransactions.ordersCompletedAt.day",
      ],
      y: [
        "MvOrderTransactions.shipFromStoreCode", 'measures'
      ],
    },
    pivotRename: [
      "RT08,MvOrderTransactions.absSumLineTotal", "WTC", 
      "RT12,MvOrderTransactions.absSumLineTotal", "MB", 
      "RT13,MvOrderTransactions.absSumLineTotal", "QB", 
      "RT15,MvOrderTransactions.absSumLineTotal", "OPM", 
      "RT16,MvOrderTransactions.absSumLineTotal", "TST", 
      "RT17,MvOrderTransactions.absSumLineTotal", "MCP", 
      "RT18,MvOrderTransactions.absSumLineTotal", "TPTM",
      ",MvOrderTransactions.absSumLineTotal", ""],
    withDoW: true,
    withPivotSum: true,
    pivotSumHeaderName: t('page.fnb.perDayPage.tableHeader.total'),
    doWHeader: "MvOrderTransactions.ordersCompletedAt.day",
    doWHeaderName: t('page.fnb.perDayPage.tableHeader.dow')
  }
  let query = {
    ...OmnitechQueries.mvOrderTransactions.DeliverServiceCharge.query,
    "filters": (OmnitechQueries.mvOrderTransactions.DeliverServiceCharge.query.filters || []).concat(
      {
        "member": "MvOrderTransactions.discountCode",
        "operator": "equals",
        "values": [
          "wtc_mb_opm_30", "k11tst_k11qb_20"
        ]
      }
    ),
    "timeDimensions": [
      {
        "dimension": "MvOrderTransactions.ordersCompletedAt",
        "dateRange": [ startDateString + " 00:00:00",
         endDateString + " 23:59:59" ],
        "granularity": "day",
      }
    ],
    "timezone": "Asia/Hong_Kong"
  }
  if(isRefresh)
    query = {}
  const result = useCubeQuery(query, {resetResultSetOnChange: true})
  if(isRefresh)
    setRefresh(false)
  return { item, result }
}

const BrainFreezeServiceChargePerDayQuery = (startDateFilter, endDateFilter, isRefresh, setRefresh) => {
  const { t } = useTranslation();
  if(startDateFilter == null && endDateFilter == null){
    startDateFilter = new Date()
    startDateFilter.setHours(0,0,0,0);
    endDateFilter = new Date()
    endDateFilter.setHours(23,59,59,999);
  }
  else if(startDateFilter == null){
    startDateFilter = setHours(endDateFilter, 0)
    startDateFilter = setMinutes(startDateFilter, 0)
    startDateFilter = setSeconds(startDateFilter, 0)
    startDateFilter = setMilliseconds(startDateFilter, 0)
  }
  else if (endDateFilter == null){
    endDateFilter = setHours(startDateFilter, 23)
    endDateFilter = setMinutes(endDateFilter, 59)
    endDateFilter = setSeconds(endDateFilter, 59)
    endDateFilter = setMilliseconds(endDateFilter, 999)
  }
  let startDateString = new Date(startDateFilter.getTime() - (startDateFilter.getTimezoneOffset() * 60000 ))
                    .toISOString()
                    .split("T")[0];
  let endDateString = new Date(endDateFilter.getTime() - (endDateFilter.getTimezoneOffset() * 60000 ))
                    .toISOString()
                    .split("T")[0];
  let item = {
    name: t('page.fnb.perDayPage.brainFreezeServiceChargePerDayName'),
    tableHeaders: {
      "MvOrderTransactions.ordersCompletedAt.day": { name: t('page.fnb.perDayPage.tableHeader.date'), type: 'date'},
    },
    grid: { xs: 12 },
    export: { allowExport: true, filename: 'brain_freeze_service_charge_per_day_export_' + format(startDateFilter, 'yyyyMMddHHmmss') +
      '_' + format(endDateFilter, 'yyyyMMddHHmmss') },
    pivotConfig: {
      x: [
        "MvOrderTransactions.ordersCompletedAt.day",
      ],
      y: [
        "MvOrderTransactions.shipFromStoreCode", 'measures'
      ],
    },
    pivotRename: [
      "RT08,MvOrderTransactions.absSumLineTotal", "WTC", 
      "RT12,MvOrderTransactions.absSumLineTotal", "MB", 
      "RT13,MvOrderTransactions.absSumLineTotal", "QB", 
      "RT15,MvOrderTransactions.absSumLineTotal", "OPM", 
      "RT16,MvOrderTransactions.absSumLineTotal", "TST", 
      "RT17,MvOrderTransactions.absSumLineTotal", "MCP", 
      "RT18,MvOrderTransactions.absSumLineTotal", "TPTM",
      ",MvOrderTransactions.absSumLineTotal", ""],
    withDoW: true,
    withPivotSum: true,
    pivotSumHeaderName: t('page.fnb.perDayPage.tableHeader.total'),
    doWHeader: "MvOrderTransactions.ordersCompletedAt.day",
    doWHeaderName: t('page.fnb.perDayPage.tableHeader.dow')
  }
  let query = {
    ...OmnitechQueries.mvOrderTransactions.DeliverServiceCharge.query,
    "filters": (OmnitechQueries.mvOrderTransactions.DeliverServiceCharge.query.filters || []).concat(
      {
        "member": "MvOrderTransactions.discountCode",
        "operator": "equals",
        "values": [
          "brainfreeze_25_payment"
        ]
      }
    ),
    "timeDimensions": [
      {
        "dimension": "MvOrderTransactions.ordersCompletedAt",
        "dateRange": [ startDateString + " 00:00:00",
         endDateString + " 23:59:59" ],
        "granularity": "day",
      }
    ],
    "timezone": "Asia/Hong_Kong"
  }
  if(isRefresh)
    query = {}
  const result = useCubeQuery(query, {resetResultSetOnChange: true})
  if(isRefresh)
    setRefresh(false)
  return { item, result }
}

const HKTVMallServiceChargePerDayQuery = (startDateFilter, endDateFilter, isRefresh, setRefresh) => {
  const { t } = useTranslation();
  if(startDateFilter == null && endDateFilter == null){
    startDateFilter = new Date()
    startDateFilter.setHours(0,0,0,0);
    endDateFilter = new Date()
    endDateFilter.setHours(23,59,59,999);
  }
  else if(startDateFilter == null){
    startDateFilter = setHours(endDateFilter, 0)
    startDateFilter = setMinutes(startDateFilter, 0)
    startDateFilter = setSeconds(startDateFilter, 0)
    startDateFilter = setMilliseconds(startDateFilter, 0)
  }
  else if (endDateFilter == null){
    endDateFilter = setHours(startDateFilter, 23)
    endDateFilter = setMinutes(endDateFilter, 59)
    endDateFilter = setSeconds(endDateFilter, 59)
    endDateFilter = setMilliseconds(endDateFilter, 999)
  }
  let startDateString = new Date(startDateFilter.getTime() - (startDateFilter.getTimezoneOffset() * 60000 ))
                    .toISOString()
                    .split("T")[0];
  let endDateString = new Date(endDateFilter.getTime() - (endDateFilter.getTimezoneOffset() * 60000 ))
                    .toISOString()
                    .split("T")[0];
  let item = {
    name: t('page.fnb.perDayPage.hktvmallServiceChargePerDayName'),
    tableHeaders: {
      "MvOrderTransactions.ordersCompletedAt.day": { name: t('page.fnb.perDayPage.tableHeader.date'), type: 'date'},
    },
    grid: { xs: 12 },
    export: { allowExport: true, filename: 'hktvmall_service_charge_per_day_export_' + format(startDateFilter, 'yyyyMMddHHmmss') +
      '_' + format(endDateFilter, 'yyyyMMddHHmmss') },
    pivotConfig: {
      x: [
        "MvOrderTransactions.ordersCompletedAt.day",
      ],
      y: [
        "MvOrderTransactions.shipFromStoreCode", 'measures'
      ],
    },
    pivotRename: [
      "RT08,MvOrderTransactions.absSumLineTotal", "WTC", 
      "RT12,MvOrderTransactions.absSumLineTotal", "MB", 
      "RT13,MvOrderTransactions.absSumLineTotal", "QB", 
      "RT15,MvOrderTransactions.absSumLineTotal", "OPM", 
      "RT16,MvOrderTransactions.absSumLineTotal", "TST", 
      "RT17,MvOrderTransactions.absSumLineTotal", "MCP", 
      "RT18,MvOrderTransactions.absSumLineTotal", "TPTM",
      ",MvOrderTransactions.absSumLineTotal", ""],
    withDoW: true,
    withPivotSum: true,
    pivotSumHeaderName: t('page.fnb.perDayPage.tableHeader.total'),
    doWHeader: "MvOrderTransactions.ordersCompletedAt.day",
    doWHeaderName: t('page.fnb.perDayPage.tableHeader.dow')
  }
  let query = {
    ...OmnitechQueries.mvOrderTransactions.DeliverServiceCharge.query,
    "filters": (OmnitechQueries.mvOrderTransactions.DeliverServiceCharge.query.filters || []).concat(
      {
        "member": "MvOrderTransactions.discountCode",
        "operator": "equals",
        "values": [
          "hktvmall_10", "hktvmall_20"
        ]
      }
    ),
    "timeDimensions": [
      {
        "dimension": "MvOrderTransactions.ordersCompletedAt",
        "dateRange": [ startDateString + " 00:00:00",
         endDateString + " 23:59:59" ],
        "granularity": "day",
      }
    ],
    "timezone": "Asia/Hong_Kong"
  }
  if(isRefresh)
    query = {}
  const result = useCubeQuery(query, {resetResultSetOnChange: true})
  if(isRefresh)
    setRefresh(false)
  return { item, result }
}

const InlineServiceChargePerDayQuery = (startDateFilter, endDateFilter, isRefresh, setRefresh) => {
  const { t } = useTranslation();
  if(startDateFilter == null && endDateFilter == null){
    startDateFilter = new Date()
    startDateFilter.setHours(0,0,0,0);
    endDateFilter = new Date()
    endDateFilter.setHours(23,59,59,999);
  }
  else if(startDateFilter == null){
    startDateFilter = setHours(endDateFilter, 0)
    startDateFilter = setMinutes(startDateFilter, 0)
    startDateFilter = setSeconds(startDateFilter, 0)
    startDateFilter = setMilliseconds(startDateFilter, 0)
  }
  else if (endDateFilter == null){
    endDateFilter = setHours(startDateFilter, 23)
    endDateFilter = setMinutes(endDateFilter, 59)
    endDateFilter = setSeconds(endDateFilter, 59)
    endDateFilter = setMilliseconds(endDateFilter, 999)
  }
  let startDateString = new Date(startDateFilter.getTime() - (startDateFilter.getTimezoneOffset() * 60000 ))
                    .toISOString()
                    .split("T")[0];
  let endDateString = new Date(endDateFilter.getTime() - (endDateFilter.getTimezoneOffset() * 60000 ))
                    .toISOString()
                    .split("T")[0];
  let item = {
    name: t('page.fnb.perDayPage.inlineServiceChargePerDayName'),
    tableHeaders: {
      "MvOrderTransactions.ordersCompletedAt.day": { name: t('page.fnb.perDayPage.tableHeader.date'), type: 'date'},
    },
    grid: { xs: 12 },
    export: { allowExport: true, filename: 'inline_service_charge_per_day_export_' + format(startDateFilter, 'yyyyMMddHHmmss') +
      '_' + format(endDateFilter, 'yyyyMMddHHmmss') },
    pivotConfig: {
      x: [
        "MvOrderTransactions.ordersCompletedAt.day",
      ],
      y: [
        "MvOrderTransactions.shipFromStoreCode", 'measures'
      ],
    },
    pivotRename: [
      "RT08,MvOrderTransactions.absSumLineTotal", "WTC", 
      "RT12,MvOrderTransactions.absSumLineTotal", "MB", 
      "RT13,MvOrderTransactions.absSumLineTotal", "QB", 
      "RT15,MvOrderTransactions.absSumLineTotal", "OPM", 
      "RT16,MvOrderTransactions.absSumLineTotal", "TST", 
      "RT17,MvOrderTransactions.absSumLineTotal", "MCP", 
      "RT18,MvOrderTransactions.absSumLineTotal", "TPTM",
      ",MvOrderTransactions.absSumLineTotal", ""],
    withDoW: true,
    withPivotSum: true,
    pivotSumHeaderName: t('page.fnb.perDayPage.tableHeader.total'),
    doWHeader: "MvOrderTransactions.ordersCompletedAt.day",
    doWHeaderName: t('page.fnb.perDayPage.tableHeader.dow')
  }
  let query = {
    ...OmnitechQueries.mvOrderTransactions.DeliverServiceCharge.query,
    "filters": (OmnitechQueries.mvOrderTransactions.DeliverServiceCharge.query.filters || []).concat(
      {
        "member": "MvOrderTransactions.discountCode",
        "operator": "equals",
        "values": [
          "inline_spu_2_payment"
        ]
      }
    ),
    "timeDimensions": [
      {
        "dimension": "MvOrderTransactions.ordersCompletedAt",
        "dateRange": [ startDateString + " 00:00:00",
         endDateString + " 23:59:59" ],
        "granularity": "day",
      }
    ],
    "timezone": "Asia/Hong_Kong"
  }
  if(isRefresh)
    query = {}
  const result = useCubeQuery(query, {resetResultSetOnChange: true})
  if(isRefresh)
    setRefresh(false)
  return { item, result }
}

const GroupBuyServiceChargePerDayQuery = (startDateFilter, endDateFilter, isRefresh, setRefresh) => {
  const { t } = useTranslation();
  if(startDateFilter == null && endDateFilter == null){
    startDateFilter = new Date()
    startDateFilter.setHours(0,0,0,0);
    endDateFilter = new Date()
    endDateFilter.setHours(23,59,59,999);
  }
  else if(startDateFilter == null){
    startDateFilter = setHours(endDateFilter, 0)
    startDateFilter = setMinutes(startDateFilter, 0)
    startDateFilter = setSeconds(startDateFilter, 0)
    startDateFilter = setMilliseconds(startDateFilter, 0)
  }
  else if (endDateFilter == null){
    endDateFilter = setHours(startDateFilter, 23)
    endDateFilter = setMinutes(endDateFilter, 59)
    endDateFilter = setSeconds(endDateFilter, 59)
    endDateFilter = setMilliseconds(endDateFilter, 999)
  }
  let startDateString = new Date(startDateFilter.getTime() - (startDateFilter.getTimezoneOffset() * 60000 ))
                    .toISOString()
                    .split("T")[0];
  let endDateString = new Date(endDateFilter.getTime() - (endDateFilter.getTimezoneOffset() * 60000 ))
                    .toISOString()
                    .split("T")[0];
  let item = {
    name: t('page.fnb.perDayPage.groupBuyServiceChargePerDayName'),
    tableHeaders: {
      "MvOrderTransactions.ordersCompletedAt.day": { name: t('page.fnb.perDayPage.tableHeader.date'), type: 'date'},
    },
    grid: { xs: 12 },
    export: { allowExport: true, filename: 'group_buy_service_charge_per_day_export_' + format(startDateFilter, 'yyyyMMddHHmmss') +
      '_' + format(endDateFilter, 'yyyyMMddHHmmss') },
    pivotConfig: {
      x: [
        "MvOrderTransactions.ordersCompletedAt.day",
      ],
      y: [
        "MvOrderTransactions.shipFromStoreCode", 'measures'
      ],
    },
    pivotRename: [
      "RT08,MvOrderTransactions.absSumLineTotal", "WTC", 
      "RT12,MvOrderTransactions.absSumLineTotal", "MB", 
      "RT13,MvOrderTransactions.absSumLineTotal", "QB", 
      "RT15,MvOrderTransactions.absSumLineTotal", "OPM", 
      "RT16,MvOrderTransactions.absSumLineTotal", "TST", 
      "RT17,MvOrderTransactions.absSumLineTotal", "MCP", 
      "RT18,MvOrderTransactions.absSumLineTotal", "TPTM",
      ",MvOrderTransactions.absSumLineTotal", ""],
    withDoW: true,
    withPivotSum: true,
    pivotSumHeaderName: t('page.fnb.perDayPage.tableHeader.total'),
    doWHeader: "MvOrderTransactions.ordersCompletedAt.day",
    doWHeaderName: t('page.fnb.perDayPage.tableHeader.dow')
  }
  let query = {
    ...OmnitechQueries.mvOrderTransactions.DeliverServiceCharge.query,
    "filters": (OmnitechQueries.mvOrderTransactions.DeliverServiceCharge.query.filters || []).concat(
      {
        "member": "MvOrderTransactions.discountCode",
        "operator": "equals",
        "values": [
          "groupbuyer_15"
        ]
      }
    ),
    "timeDimensions": [
      {
        "dimension": "MvOrderTransactions.ordersCompletedAt",
        "dateRange": [ startDateString + " 00:00:00",
         endDateString + " 23:59:59" ],
        "granularity": "day",
      }
    ],
    "timezone": "Asia/Hong_Kong"
  }
  if(isRefresh)
    query = {}
  const result = useCubeQuery(query, {resetResultSetOnChange: true})
  if(isRefresh)
    setRefresh(false)
  return { item, result }
}

const TimePeriodSummaryBreakfastPerDayQuery = (startDateFilter, endDateFilter, start, end, isRefresh, setRefresh) => {
  const { t } = useTranslation();
  if(startDateFilter == null && endDateFilter == null){
    startDateFilter = new Date()
    startDateFilter.setHours(0,0,0,0);
    endDateFilter = new Date()
    endDateFilter.setHours(23,59,59,999);
  }
  else if(startDateFilter == null){
    startDateFilter = setHours(endDateFilter, 0)
    startDateFilter = setMinutes(startDateFilter, 0)
    startDateFilter = setSeconds(startDateFilter, 0)
    startDateFilter = setMilliseconds(startDateFilter, 0)
  }
  else if (endDateFilter == null){
    endDateFilter = setHours(startDateFilter, 23)
    endDateFilter = setMinutes(endDateFilter, 59)
    endDateFilter = setSeconds(endDateFilter, 59)
    endDateFilter = setMilliseconds(endDateFilter, 999)
  }
  let startDateString = new Date(startDateFilter.getTime() - (startDateFilter.getTimezoneOffset() * 60000 ))
                    .toISOString()
                    .split("T")[0];
  let endDateString = new Date(endDateFilter.getTime() - (endDateFilter.getTimezoneOffset() * 60000 ))
                    .toISOString()
                    .split("T")[0];
  let item = {
    name: t('page.fnb.perDayPage.timePeriodSummaryBreakfastPerDayName'),
    tableHeaders: {
      "MvOrdersSummaryEM.ordersCompletedAt.day": { name: t('page.fnb.perDayPage.tableHeader.date'), type: 'date'},
    },
    grid: { xs: 12 },
    export: { allowExport: true, filename: 'time_period_summary_breakfast_per_day_export_' + format(startDateFilter, 'yyyyMMddHHmmss') +
      '_' + format(endDateFilter, 'yyyyMMddHHmmss') },
    pivotConfig: {
      x: [
        "MvOrdersSummaryEM.ordersCompletedAt.day",
      ],
      y: [
        "MvOrdersSummaryEM.shipFromStoreCode", 'measures'
      ],
    },
    pivotRename: [
      "RT08,MvOrdersSummaryEM.sumNetSales", "WTC", 
      "RT12,MvOrdersSummaryEM.sumNetSales", "MB", 
      "RT13,MvOrdersSummaryEM.sumNetSales", "QB", 
      "RT15,MvOrdersSummaryEM.sumNetSales", "OPM", 
      "RT16,MvOrdersSummaryEM.sumNetSales", "TST", 
      "RT17,MvOrdersSummaryEM.sumNetSales", "MCP", 
      "RT18,MvOrdersSummaryEM.sumNetSales", "TPTM",
      ",MvOrdersSummaryEM.sumNetSales", ""],
    withDoW: true,
    withPivotSum: true,
    pivotSumHeaderName: t('page.fnb.perDayPage.tableHeader.total'),
    doWHeader: "MvOrdersSummaryEM.ordersCompletedAt.day",
    doWHeaderName: t('page.fnb.perDayPage.tableHeader.dow')
  }
  let query = {
    ...OmnitechQueries.mvOrdersSummaryEM.TimePeriodSummaryByStorePerDayEMHist.query,
    "timeDimensions": [
      {
        "dimension": "MvOrdersSummaryEM.ordersCompletedAt",
        "dateRange": [ startDateString + " 00:00:00",
          endDateString + " 23:59:59" ],
        "granularity": "day",
      }
    ],
    "timezone": "Asia/Hong_Kong"
  }
  query = applyFilter(query, start, end)
  if(isRefresh)
    query = {}
  const result = useCubeQuery(query, {resetResultSetOnChange: true})
  if(isRefresh)
    setRefresh(false)
  return { item, result }
}

const TimePeriodSummaryLunchPerDayQuery = (startDateFilter, endDateFilter, start, end, isRefresh, setRefresh) => {
  const { t } = useTranslation();
  if(startDateFilter == null && endDateFilter == null){
    startDateFilter = new Date()
    startDateFilter.setHours(0,0,0,0);
    endDateFilter = new Date()
    endDateFilter.setHours(23,59,59,999);
  }
  else if(startDateFilter == null){
    startDateFilter = setHours(endDateFilter, 0)
    startDateFilter = setMinutes(startDateFilter, 0)
    startDateFilter = setSeconds(startDateFilter, 0)
    startDateFilter = setMilliseconds(startDateFilter, 0)
  }
  else if (endDateFilter == null){
    endDateFilter = setHours(startDateFilter, 23)
    endDateFilter = setMinutes(endDateFilter, 59)
    endDateFilter = setSeconds(endDateFilter, 59)
    endDateFilter = setMilliseconds(endDateFilter, 999)
  }
  let startDateString = new Date(startDateFilter.getTime() - (startDateFilter.getTimezoneOffset() * 60000 ))
                    .toISOString()
                    .split("T")[0];
  let endDateString = new Date(endDateFilter.getTime() - (endDateFilter.getTimezoneOffset() * 60000 ))
                    .toISOString()
                    .split("T")[0];
  let item = {
    name: t('page.fnb.perDayPage.timePeriodSummaryLunchPerDayName'),
    tableHeaders: {
      "MvOrdersSummaryEM.ordersCompletedAt.day": { name: t('page.fnb.perDayPage.tableHeader.date'), type: 'date'},
    },
    grid: { xs: 12 },
    export: { allowExport: true, filename: 'time_period_summary_lunch_per_day_export_' + format(startDateFilter, 'yyyyMMddHHmmss') +
      '_' + format(endDateFilter, 'yyyyMMddHHmmss') },
    pivotConfig: {
      x: [
        "MvOrdersSummaryEM.ordersCompletedAt.day",
      ],
      y: [
        "MvOrdersSummaryEM.shipFromStoreCode", 'measures'
      ],
    },
    pivotRename: [
      "RT08,MvOrdersSummaryEM.sumNetSales", "WTC", 
      "RT12,MvOrdersSummaryEM.sumNetSales", "MB", 
      "RT13,MvOrdersSummaryEM.sumNetSales", "QB", 
      "RT15,MvOrdersSummaryEM.sumNetSales", "OPM", 
      "RT16,MvOrdersSummaryEM.sumNetSales", "TST", 
      "RT17,MvOrdersSummaryEM.sumNetSales", "MCP", 
      "RT18,MvOrdersSummaryEM.sumNetSales", "TPTM",
      ",MvOrdersSummaryEM.sumNetSales", ""],
    withDoW: true,
    withPivotSum: true,
    pivotSumHeaderName: t('page.fnb.perDayPage.tableHeader.total'),
    doWHeader: "MvOrdersSummaryEM.ordersCompletedAt.day",
    doWHeaderName: t('page.fnb.perDayPage.tableHeader.dow')
  }
  let query = {
    ...OmnitechQueries.mvOrdersSummaryEM.TimePeriodSummaryByStorePerDayEMHist.query,
    "timeDimensions": [
      {
        "dimension": "MvOrdersSummaryEM.ordersCompletedAt",
        "dateRange": [ startDateString + " 00:00:00",
          endDateString + " 23:59:59" ],
        "granularity": "day",
      }
    ],
    "timezone": "Asia/Hong_Kong"
  }
  query = applyFilter(query, start, end)
  if(isRefresh)
    query = {}
  const result = useCubeQuery(query, {resetResultSetOnChange: true})
  if(isRefresh)
    setRefresh(false)
  return { item, result }
}

const TimePeriodSummaryTeaPerDayQuery = (startDateFilter, endDateFilter, start, end, isRefresh, setRefresh) => {
  const { t } = useTranslation();
  if(startDateFilter == null && endDateFilter == null){
    startDateFilter = new Date()
    startDateFilter.setHours(0,0,0,0);
    endDateFilter = new Date()
    endDateFilter.setHours(23,59,59,999);
  }
  else if(startDateFilter == null){
    startDateFilter = setHours(endDateFilter, 0)
    startDateFilter = setMinutes(startDateFilter, 0)
    startDateFilter = setSeconds(startDateFilter, 0)
    startDateFilter = setMilliseconds(startDateFilter, 0)
  }
  else if (endDateFilter == null){
    endDateFilter = setHours(startDateFilter, 23)
    endDateFilter = setMinutes(endDateFilter, 59)
    endDateFilter = setSeconds(endDateFilter, 59)
    endDateFilter = setMilliseconds(endDateFilter, 999)
  }
  let startDateString = new Date(startDateFilter.getTime() - (startDateFilter.getTimezoneOffset() * 60000 ))
                    .toISOString()
                    .split("T")[0];
  let endDateString = new Date(endDateFilter.getTime() - (endDateFilter.getTimezoneOffset() * 60000 ))
                    .toISOString()
                    .split("T")[0];
  let item = {
    name: t('page.fnb.perDayPage.timePeriodSummaryTeaPerDayName'),
    tableHeaders: {
      "MvOrdersSummaryEM.ordersCompletedAt.day": { name: t('page.fnb.perDayPage.tableHeader.date'), type: 'date'},
    },
    grid: { xs: 12 },
    export: { allowExport: true, filename: 'time_period_summary_tea_per_day_export_' + format(startDateFilter, 'yyyyMMddHHmmss') +
      '_' + format(endDateFilter, 'yyyyMMddHHmmss') },
    pivotConfig: {
      x: [
        "MvOrdersSummaryEM.ordersCompletedAt.day",
      ],
      y: [
        "MvOrdersSummaryEM.shipFromStoreCode", 'measures'
      ],
    },
    pivotRename: [
      "RT08,MvOrdersSummaryEM.sumNetSales", "WTC", 
      "RT12,MvOrdersSummaryEM.sumNetSales", "MB", 
      "RT13,MvOrdersSummaryEM.sumNetSales", "QB", 
      "RT15,MvOrdersSummaryEM.sumNetSales", "OPM", 
      "RT16,MvOrdersSummaryEM.sumNetSales", "TST", 
      "RT17,MvOrdersSummaryEM.sumNetSales", "MCP", 
      "RT18,MvOrdersSummaryEM.sumNetSales", "TPTM",
      ",MvOrdersSummaryEM.sumNetSales", ""],
    withDoW: true,
    withPivotSum: true,
    pivotSumHeaderName: t('page.fnb.perDayPage.tableHeader.total'),
    doWHeader: "MvOrdersSummaryEM.ordersCompletedAt.day",
    doWHeaderName: t('page.fnb.perDayPage.tableHeader.dow')
  }
  let query = {
    ...OmnitechQueries.mvOrdersSummaryEM.TimePeriodSummaryByStorePerDayEMHist.query,
    "timeDimensions": [
      {
        "dimension": "MvOrdersSummaryEM.ordersCompletedAt",
        "dateRange": [ startDateString + " 00:00:00",
          endDateString + " 23:59:59" ],
        "granularity": "day",
      }
    ],
    "timezone": "Asia/Hong_Kong"
  }
  query = applyFilter(query, start, end)
  if(isRefresh)
    query = {}
  const result = useCubeQuery(query, {resetResultSetOnChange: true})
  if(isRefresh)
    setRefresh(false)
  return { item, result }
}

const TimePeriodSummaryDinnerPerDayQuery = (startDateFilter, endDateFilter, start, end, isRefresh, setRefresh) => {
  const { t } = useTranslation();
  if(startDateFilter == null && endDateFilter == null){
    startDateFilter = new Date()
    startDateFilter.setHours(0,0,0,0);
    endDateFilter = new Date()
    endDateFilter.setHours(23,59,59,999);
  }
  else if(startDateFilter == null){
    startDateFilter = setHours(endDateFilter, 0)
    startDateFilter = setMinutes(startDateFilter, 0)
    startDateFilter = setSeconds(startDateFilter, 0)
    startDateFilter = setMilliseconds(startDateFilter, 0)
  }
  else if (endDateFilter == null){
    endDateFilter = setHours(startDateFilter, 23)
    endDateFilter = setMinutes(endDateFilter, 59)
    endDateFilter = setSeconds(endDateFilter, 59)
    endDateFilter = setMilliseconds(endDateFilter, 999)
  }
  let startDateString = new Date(startDateFilter.getTime() - (startDateFilter.getTimezoneOffset() * 60000 ))
                    .toISOString()
                    .split("T")[0];
  let endDateString = new Date(endDateFilter.getTime() - (endDateFilter.getTimezoneOffset() * 60000 ))
                    .toISOString()
                    .split("T")[0];
  let item = {
    name: t('page.fnb.perDayPage.timePeriodSummaryDinnerPerDayName'),
    tableHeaders: {
      "MvOrdersSummaryEM.ordersCompletedAt.day": { name: t('page.fnb.perDayPage.tableHeader.date'), type: 'date'},
    },
    grid: { xs: 12 },
    export: { allowExport: true, filename: 'time_period_summary_dinner_per_day_export_' + format(startDateFilter, 'yyyyMMddHHmmss') +
      '_' + format(endDateFilter, 'yyyyMMddHHmmss') },
    pivotConfig: {
      x: [
        "MvOrdersSummaryEM.ordersCompletedAt.day",
      ],
      y: [
        "MvOrdersSummaryEM.shipFromStoreCode", 'measures'
      ],
    },
    pivotRename: [
      "RT08,MvOrdersSummaryEM.sumNetSales", "WTC", 
      "RT12,MvOrdersSummaryEM.sumNetSales", "MB", 
      "RT13,MvOrdersSummaryEM.sumNetSales", "QB", 
      "RT15,MvOrdersSummaryEM.sumNetSales", "OPM", 
      "RT16,MvOrdersSummaryEM.sumNetSales", "TST", 
      "RT17,MvOrdersSummaryEM.sumNetSales", "MCP", 
      "RT18,MvOrdersSummaryEM.sumNetSales", "TPTM",
      ",MvOrdersSummaryEM.sumNetSales", ""],
    withDoW: true,
    withPivotSum: true,
    pivotSumHeaderName: t('page.fnb.perDayPage.tableHeader.total'),
    doWHeader: "MvOrdersSummaryEM.ordersCompletedAt.day",
    doWHeaderName: t('page.fnb.perDayPage.tableHeader.dow')
  }
  let query = {
    ...OmnitechQueries.mvOrdersSummaryEM.TimePeriodSummaryByStorePerDayEMHist.query,
    "timeDimensions": [
      {
        "dimension": "MvOrdersSummaryEM.ordersCompletedAt",
        "dateRange": [ startDateString + " 00:00:00",
          endDateString + " 23:59:59" ],
        "granularity": "day",
      }
    ],
    "timezone": "Asia/Hong_Kong"
  }
  query = applyFilter(query, start, end)
  if(isRefresh)
    query = {}
  const result = useCubeQuery(query, {resetResultSetOnChange: true})
  if(isRefresh)
    setRefresh(false)
  return { item, result }
}

const DayPartAtHomePerDayQuery = (startDateFilter, endDateFilter, isRefresh, setRefresh) => {
  const { t } = useTranslation();
  if(startDateFilter == null && endDateFilter == null){
    startDateFilter = new Date()
    startDateFilter.setHours(0,0,0,0);
    endDateFilter = new Date()
    endDateFilter.setHours(23,59,59,999);
  }
  else if(startDateFilter == null){
    startDateFilter = setHours(endDateFilter, 0)
    startDateFilter = setMinutes(startDateFilter, 0)
    startDateFilter = setSeconds(startDateFilter, 0)
    startDateFilter = setMilliseconds(startDateFilter, 0)
  }
  else if (endDateFilter == null){
    endDateFilter = setHours(startDateFilter, 23)
    endDateFilter = setMinutes(endDateFilter, 59)
    endDateFilter = setSeconds(endDateFilter, 59)
    endDateFilter = setMilliseconds(endDateFilter, 999)
  }
  let startDateString = new Date(startDateFilter.getTime() - (startDateFilter.getTimezoneOffset() * 60000 ))
                    .toISOString()
                    .split("T")[0];
  let endDateString = new Date(endDateFilter.getTime() - (endDateFilter.getTimezoneOffset() * 60000 ))
                    .toISOString()
                    .split("T")[0];
  let item = {
    name: t('page.fnb.perDayPage.daypartAtHomePerDayName'),
    tableHeaders: {
      "ByDaySpecialReport.ordersCompletedAt.day": { name: t('page.fnb.perDayPage.tableHeader.date'), type: 'date'},
      "ByDaySpecialReport.breakfastNetSales": { name: t('page.fnb.perDayPage.tableHeader.breakfastNetSales'), type: 'number'},
      "ByDaySpecialReport.lunchNetSales": { name: t('page.fnb.perDayPage.tableHeader.lunchNetSales'), type: 'number'},
      "ByDaySpecialReport.teaNetSales": { name: t('page.fnb.perDayPage.tableHeader.teaNetSales'), type: 'number'},
      "ByDaySpecialReport.dinnerNetSales": { name: t('page.fnb.perDayPage.tableHeader.dinnerNetSales'), type: 'number'},
      "ByDaySpecialReport.deliverooNetSales": { name: t('page.fnb.perDayPage.tableHeader.deliverooNetSales'), type: 'number'},
      "ByDaySpecialReport.openriceNetSales": { name: t('page.fnb.perDayPage.tableHeader.openriceNetSales'), type: 'number'},
      "ByDaySpecialReport.foodpandaNetSales": { name: t('page.fnb.perDayPage.tableHeader.foodpandaNetSales'), type: 'number'},
      "ByDaySpecialReport.brainfreezeNetSales": { name: t('page.fnb.perDayPage.tableHeader.brainfreezeNetSales'), type: 'number'},
      "ByDaySpecialReport.hktvmallNetSales": { name: t('page.fnb.perDayPage.tableHeader.hktvmallNetSales'), type: 'number'},
      "ByDaySpecialReport.inlineNetSales": { name: t('page.fnb.perDayPage.tableHeader.inlineNetSales'), type: 'number'},
      "ByDaySpecialReport.groupBuyNetSales": { name: t('page.fnb.perDayPage.tableHeader.groupBuyNetSales'), type: 'number'},
      "ByDaySpecialReport.sumNetSales": { name: t('page.fnb.perDayPage.tableHeader.sumNetSales'), type: 'number'},
    },
    grid: { xs: 12 },
    export: { allowExport: true, filename: 'day_part_and_at_home_per_day_export_' + format(startDateFilter, 'yyyyMMddHHmmss') +
      '_' + format(endDateFilter, 'yyyyMMddHHmmss') },
    withDoW: true,
    doWHeader: "ByDaySpecialReport.ordersCompletedAt.day",
    doWHeaderName: t('page.fnb.perDayPage.tableHeader.dow')
  }
  let query = {
    ...OmnitechQueries.mvOrdersSummaryEM.DayPartAtHomePerDayEMHist.query,
    "timeDimensions": [
      {
        "dimension": "ByDaySpecialReport.ordersCompletedAt",
        "dateRange": [ startDateString + " 00:00:00",
         endDateString + " 23:59:59" ],
        "granularity": "day",
      }
    ],
    "timezone": "Asia/Hong_Kong"
  }
  if(isRefresh)
    query = {}
  const result = useCubeQuery(query, {resetResultSetOnChange: true})
  if(isRefresh)
    setRefresh(false)
  return { item, result }
}

const getNetSalesPerDayChart = (resultSet) => {
  const renameLabels = {
    "RT08,MvOrdersSummaryEM.sumNetSales": "WTC", 
    "RT12,MvOrdersSummaryEM.sumNetSales": "MB", 
    "RT13,MvOrdersSummaryEM.sumNetSales": "QB", 
    "RT15,MvOrdersSummaryEM.sumNetSales": "OPM", 
    "RT16,MvOrdersSummaryEM.sumNetSales": "TST", 
    "RT17,MvOrdersSummaryEM.sumNetSales": "MCP", 
    "RT18,MvOrdersSummaryEM.sumNetSales": "TPTM",
    ",MvOrdersSummaryEM.sumNetSales": "",
  };
  return {
    data: resultSet?.chartPivot().map((data) => {
      return {
        ...data,
        x: format(new Date(`${data.x}+00:00`), 'yyyy/MM/dd'),
      }
    }),
    dataSeries: resultSet?.seriesNames().map((seriesName) => {
      return {
        ...seriesName,
        title: renameLabels[seriesName.key] ? renameLabels[seriesName.key]: seriesName.yValues[0],
      }
    }),
  }
}

const PerDayReportPage = () => {
  const { t } = useTranslation();
  const context = useContext(CubeJSContext);
  let breakfastStart = context.breakfastStart ? context.breakfastStart : 5
  let breakfastEnd = context.breakfastEnd ? context.breakfastEnd : 10
  let lunchStart = context.lunchStart ? context.lunchStart : 11
  let lunchEnd = context.lunchEnd ? context.lunchEnd : 14
  let teaStart = context.teaStart ? context.teaStart : 15
  let teaEnd = context.teaEnd ? context.teaEnd : 17
  let dinnerStart = context.dinnerStart ? context.dinnerStart : 18
  let dinnerEnd = context.dinnerEnd ? context.dinnerEnd : 23
  let filterStartDate = new Date()
  filterStartDate.setHours(0,0,0,0);
  let filterEndDate = new Date()
  filterEndDate.setHours(23,59,59,999);
  const [dateRangeFilter, setDateRangeFilter] = useState([filterStartDate, filterEndDate])
  const [startDateFilter, endDateFilter] = dateRangeFilter
  const [rowsPerPage1, setRowsPerPage1] = useState(10);
  const [page1, setPage1] = useState(0);
  const [rowsPerPage2, setRowsPerPage2] = useState(10);
  const [page2, setPage2] = useState(0);
  const [rowsPerPage3, setRowsPerPage3] = useState(10);
  const [page3, setPage3] = useState(0);
  const [rowsPerPage4, setRowsPerPage4] = useState(10);
  const [page4, setPage4] = useState(0);
  const [rowsPerPage5, setRowsPerPage5] = useState(10);
  const [page5, setPage5] = useState(0);
  const [rowsPerPage6, setRowsPerPage6] = useState(10);
  const [page6, setPage6] = useState(0);
  const [rowsPerPage7, setRowsPerPage7] = useState(10);
  const [page7, setPage7] = useState(0);
  const [rowsPerPage8, setRowsPerPage8] = useState(10);
  const [page8, setPage8] = useState(0);
  const [rowsPerPage9, setRowsPerPage9] = useState(10);
  const [page9, setPage9] = useState(0);
  const [rowsPerPage10, setRowsPerPage10] = useState(10);
  const [page10, setPage10] = useState(0);
  const [rowsPerPage11, setRowsPerPage11] = useState(10);
  const [page11, setPage11] = useState(0);
  const [rowsPerPage12, setRowsPerPage12] = useState(10);
  const [page12, setPage12] = useState(0);
  const [rowsPerPage13, setRowsPerPage13] = useState(10);
  const [page13, setPage13] = useState(0);
  const [rowsPerPage14, setRowsPerPage14] = useState(10);
  const [page14, setPage14] = useState(0);
  const [rowsPerPage15, setRowsPerPage15] = useState(10);
  const [page15, setPage15] = useState(0);
  const [rowsPerPage16, setRowsPerPage16] = useState(10);
  const [page16, setPage16] = useState(0);
  const [rowsPerPage17, setRowsPerPage17] = useState(10);
  const [page17, setPage17] = useState(0);
  const [rowsPerPage18, setRowsPerPage18] = useState(10);
  const [page18, setPage18] = useState(0);
  const [rowsPerPage19, setRowsPerPage19] = useState(10);
  const [page19, setPage19] = useState(0);
  const [rowsPerPage20, setRowsPerPage20] = useState(10);
  const [page20, setPage20] = useState(0);
  const [rowsPerPage21, setRowsPerPage21] = useState(10);
  const [page21, setPage21] = useState(0);
  const [rowsPerPage22, setRowsPerPage22] = useState(10);
  const [page22, setPage22] = useState(0);
  const [rowsPerPage23, setRowsPerPage23] = useState(10);
  const [page23, setPage23] = useState(0);
  const [rowsPerPage24, setRowsPerPage24] = useState(10);
  const [page24, setPage24] = useState(0);
  const [rowsPerPage25, setRowsPerPage25] = useState(10);
  const [page25, setPage25] = useState(0);
  const [rowsPerPage26, setRowsPerPage26] = useState(10);
  const [page26, setPage26] = useState(0);
  const [rowsPerPage27, setRowsPerPage27] = useState(10);
  const [page27, setPage27] = useState(0);

  const [ refresh1, setRefresh1 ] = useState(false);
  const [ refresh2, setRefresh2 ] = useState(false);
  const [ refresh3, setRefresh3 ] = useState(false);
  const [ refresh4, setRefresh4 ] = useState(false);
  const [ refresh5, setRefresh5 ] = useState(false);
  const [ refresh6, setRefresh6 ] = useState(false);
  const [ refresh7, setRefresh7 ] = useState(false);
  const [ refresh8, setRefresh8 ] = useState(false);
  const [ refresh9, setRefresh9 ] = useState(false);
  const [ refresh10, setRefresh10 ] = useState(false);
  const [ refresh11, setRefresh11 ] = useState(false);
  const [ refresh12, setRefresh12 ] = useState(false);
  const [ refresh13, setRefresh13 ] = useState(false);
  const [ refresh14, setRefresh14 ] = useState(false);
  const [ refresh15, setRefresh15 ] = useState(false);
  const [ refresh16, setRefresh16 ] = useState(false);
  const [ refresh17, setRefresh17 ] = useState(false);
  const [ refresh18, setRefresh18 ] = useState(false);
  const [ refresh19, setRefresh19 ] = useState(false);
  const [ refresh20, setRefresh20 ] = useState(false);
  const [ refresh21, setRefresh21 ] = useState(false);
  const [ refresh22, setRefresh22 ] = useState(false);
  const [ refresh23, setRefresh23 ] = useState(false);
  const [ refresh24, setRefresh24 ] = useState(false);
  const [ refresh25, setRefresh25 ] = useState(false);
  const [ refresh26, setRefresh26 ] = useState(false);
  const [ refresh27, setRefresh27 ] = useState(false);

  const query1  = NetSalesPerDayQuery(startDateFilter, endDateFilter, refresh1, setRefresh1)
  const query2  = MembershipSalesPerDayQuery(startDateFilter, endDateFilter, refresh2, setRefresh2)
  const query3  = PAXPerDayQuery(startDateFilter, endDateFilter, refresh3, setRefresh3)
  const query4  = PerPersonSpendingPerDayQuery(startDateFilter, endDateFilter, refresh4, setRefresh4)
  const query5  = NoOfOrderPerDayQuery(startDateFilter, endDateFilter, refresh5, setRefresh5)
  const query6  = PerOrderSpendingPerDayQuery(startDateFilter, endDateFilter, refresh6, setRefresh6)
  const query7  = DiscountPerDayQuery(startDateFilter, endDateFilter, refresh7, setRefresh7)
  const query8  = BeverageGrossSalesPerDayQuery(startDateFilter, endDateFilter, refresh8, setRefresh8)
  const query9  = DeliverooNetSalesPerDayQuery(startDateFilter, endDateFilter, refresh9, setRefresh9)
  const query10 = OpenriceNetSalesPerDayQuery(startDateFilter, endDateFilter, refresh10, setRefresh10)
  const query11 = FoodPandaNetSalesPerDayQuery(startDateFilter, endDateFilter, refresh11, setRefresh11)
  const query12 = BrainFreezeNetSalesPerDayQuery(startDateFilter, endDateFilter, refresh12, setRefresh12)
  const query13 = TimePeriodSummaryLunchPerDayQuery(startDateFilter, endDateFilter, lunchStart, lunchEnd, refresh13, setRefresh13)
  const query14 = TimePeriodSummaryTeaPerDayQuery(startDateFilter, endDateFilter, teaStart, teaEnd, refresh14, setRefresh14)
  const query15 = TimePeriodSummaryDinnerPerDayQuery(startDateFilter, endDateFilter, dinnerStart, dinnerEnd, refresh15, setRefresh15)
  const query16 = DayPartAtHomePerDayQuery(startDateFilter, endDateFilter, refresh16, setRefresh16)
  const query17 = HKTVMallNetSalesPerDayQuery(startDateFilter, endDateFilter, refresh17, setRefresh17)
  const query18 = InlineNetSalesPerDayQuery(startDateFilter, endDateFilter, refresh18, setRefresh18)
  const query19 = DeliverooServiceChargePerDayQuery(startDateFilter, endDateFilter, refresh19, setRefresh19)
  const query20 = OpenriceServiceChargePerDayQuery(startDateFilter, endDateFilter, refresh20, setRefresh20)
  const query21 = FoodPandaServiceChargePerDayQuery(startDateFilter, endDateFilter, refresh21, setRefresh21)
  const query22 = BrainFreezeServiceChargePerDayQuery(startDateFilter, endDateFilter, refresh22, setRefresh22)
  const query23 = HKTVMallServiceChargePerDayQuery(startDateFilter, endDateFilter, refresh23, setRefresh23)
  const query24 = InlineServiceChargePerDayQuery(startDateFilter, endDateFilter, refresh24, setRefresh24)
  const query25 = GroupBuyNetSalesPerDayQuery(startDateFilter, endDateFilter, refresh25, setRefresh25)
  const query26 = GroupBuyServiceChargePerDayQuery(startDateFilter, endDateFilter, refresh26, setRefresh26)
  const query27 = TimePeriodSummaryBreakfastPerDayQuery(startDateFilter, endDateFilter, breakfastStart, breakfastEnd, refresh27, setRefresh27)

  const refresh = () => {
    setRefresh1(true)
    setRefresh2(true)
    setRefresh3(true)
    setRefresh4(true)
    setRefresh5(true)
    setRefresh6(true)
    setRefresh7(true)
    setRefresh8(true)
    setRefresh9(true)
    setRefresh10(true)
    setRefresh11(true)
    setRefresh12(true)
    setRefresh13(true)
    setRefresh14(true)
    setRefresh15(true)
    setRefresh16(true)
    setRefresh17(true)
    setRefresh18(true)
    setRefresh19(true)
    setRefresh20(true)
    setRefresh21(true)
    setRefresh22(true)
    setRefresh23(true)
    setRefresh24(true)
    setRefresh25(true)
    setRefresh26(true)
    setRefresh27(true)
    setPage1(0)
    setPage2(0)
    setPage3(0)
    setPage4(0)
    setPage5(0)
    setPage6(0)
    setPage7(0)
    setPage8(0)
    setPage9(0)
    setPage10(0)
    setPage11(0)
    setPage12(0)
    setPage13(0)
    setPage14(0)
    setPage15(0)
    setPage16(0)
    setPage17(0)
    setPage18(0)
    setPage19(0)
    setPage20(0)
    setPage21(0)
    setPage22(0)
    setPage23(0)
    setPage24(0)
    setPage25(0)
    setPage26(0)
    setPage27(0)
  }

  // cache the chart massaged data
  const netSalesPerDayChartResultSet = query1.result?.resultSet
  const netSalesPerDayChart = useMemo(() => {
    if (netSalesPerDayChartResultSet === null) return {data: [], dataSeries: []}
    
    return getNetSalesPerDayChart(netSalesPerDayChartResultSet)
  }, [netSalesPerDayChartResultSet])
  return ([
    <Header />,
    <PageHeaderComponent onClickFunc={refresh} refreshlabel={t('component.refresh')} headerTitle={t('component.header.menu.perDayReport')} />,
    <DateRangePickerComponent state={dateRangeFilter} stateChanger={setDateRangeFilter} pageChangerArr={[setPage1,
      setPage2,setPage3,setPage4,setPage5,setPage6,setPage7,setPage8,setPage9,setPage10,setPage11,setPage12,setPage13,
      setPage14,setPage15,setPage16,setPage17,setPage18,setPage19,setPage20,setPage21,setPage22,setPage23,setPage24,
      setPage25,setPage26,setPage27]}
      startDateLabel={t('page.fnb.perDayPage.filter.orderCompletedAt.startDate')} 
      toLabel={t('page.fnb.perDayPage.filter.orderCompletedAt.to')} 
      endDateLabel={t('page.fnb.perDayPage.filter.orderCompletedAt.endDate')}/>
    ,
    <Dashboard>
      <TextComponent title={t('component.text.historical')} xs={12} />
      <TableComponent item={query1.item} result={query1.result} rowsPerPage={rowsPerPage1} 
        setRowsPerPage={setRowsPerPage1} page={page1} setPage={setPage1}/>
      <ChartComponent chartType="line" data={netSalesPerDayChart.data} dataSeries={netSalesPerDayChart.dataSeries} 
        item={query1.item} result={query1.result} titleName={query1.item.name} enableTooltip />
      <TableComponent item={query2.item} result={query2.result} rowsPerPage={rowsPerPage2} 
        setRowsPerPage={setRowsPerPage2} page={page2} setPage={setPage2}/>
      <TableComponent item={query3.item} result={query3.result} rowsPerPage={rowsPerPage3} 
        setRowsPerPage={setRowsPerPage3} page={page3} setPage={setPage3}/>
      <TableComponent item={query4.item} result={query4.result} rowsPerPage={rowsPerPage4} 
        setRowsPerPage={setRowsPerPage4} page={page4} setPage={setPage4}/>
      <TableComponent item={query5.item} result={query5.result} rowsPerPage={rowsPerPage5} 
        setRowsPerPage={setRowsPerPage5} page={page5} setPage={setPage5}/>
      <TableComponent item={query6.item} result={query6.result} rowsPerPage={rowsPerPage6} 
        setRowsPerPage={setRowsPerPage6} page={page6} setPage={setPage6}/>
      <TableComponent item={query7.item} result={query7.result} rowsPerPage={rowsPerPage7} 
        setRowsPerPage={setRowsPerPage7} page={page7} setPage={setPage7}/>
      <TableComponent item={query8.item} result={query8.result} rowsPerPage={rowsPerPage8} 
        setRowsPerPage={setRowsPerPage8} page={page8} setPage={setPage8}/>
      <TableComponent item={query9.item} result={query9.result} rowsPerPage={rowsPerPage9} 
        setRowsPerPage={setRowsPerPage9} page={page9} setPage={setPage9}/>
      <TableComponent item={query19.item} result={query19.result} rowsPerPage={rowsPerPage19} 
        setRowsPerPage={setRowsPerPage19} page={page19} setPage={setPage19}/>
      <TableComponent item={query10.item} result={query10.result} rowsPerPage={rowsPerPage10} 
        setRowsPerPage={setRowsPerPage10} page={page10} setPage={setPage10}/>
      <TableComponent item={query20.item} result={query20.result} rowsPerPage={rowsPerPage20} 
        setRowsPerPage={setRowsPerPage20} page={page20} setPage={setPage20}/>
      <TableComponent item={query11.item} result={query11.result} rowsPerPage={rowsPerPage11} 
        setRowsPerPage={setRowsPerPage11} page={page11} setPage={setPage11}/>
      <TableComponent item={query21.item} result={query21.result} rowsPerPage={rowsPerPage21} 
        setRowsPerPage={setRowsPerPage21} page={page21} setPage={setPage21}/>
      <TableComponent item={query12.item} result={query12.result} rowsPerPage={rowsPerPage12} 
        setRowsPerPage={setRowsPerPage12} page={page12} setPage={setPage12}/>
      <TableComponent item={query22.item} result={query22.result} rowsPerPage={rowsPerPage22} 
        setRowsPerPage={setRowsPerPage22} page={page22} setPage={setPage22}/>
      <TableComponent item={query17.item} result={query17.result} rowsPerPage={rowsPerPage17} 
        setRowsPerPage={setRowsPerPage17} page={page17} setPage={setPage17}/>
      <TableComponent item={query23.item} result={query23.result} rowsPerPage={rowsPerPage23} 
        setRowsPerPage={setRowsPerPage23} page={page23} setPage={setPage23}/>
      <TableComponent item={query18.item} result={query18.result} rowsPerPage={rowsPerPage18} 
        setRowsPerPage={setRowsPerPage18} page={page18} setPage={setPage18}/>
      <TableComponent item={query24.item} result={query24.result} rowsPerPage={rowsPerPage24} 
        setRowsPerPage={setRowsPerPage24} page={page24} setPage={setPage24}/>
      <TableComponent item={query25.item} result={query25.result} rowsPerPage={rowsPerPage25} 
        setRowsPerPage={setRowsPerPage25} page={page25} setPage={setPage25}/>
      <TableComponent item={query26.item} result={query26.result} rowsPerPage={rowsPerPage26} 
        setRowsPerPage={setRowsPerPage26} page={page26} setPage={setPage26}/>
      <TableComponent item={query27.item} result={query27.result} rowsPerPage={rowsPerPage27} 
        setRowsPerPage={setRowsPerPage27} page={page27} setPage={setPage27}/>
      <TableComponent item={query13.item} result={query13.result} rowsPerPage={rowsPerPage13} 
        setRowsPerPage={setRowsPerPage13} page={page13} setPage={setPage13}/>
      <TableComponent item={query14.item} result={query14.result} rowsPerPage={rowsPerPage14} 
        setRowsPerPage={setRowsPerPage14} page={page14} setPage={setPage14}/>
      <TableComponent item={query15.item} result={query15.result} rowsPerPage={rowsPerPage15} 
        setRowsPerPage={setRowsPerPage15} page={page15} setPage={setPage15}/>
      <TableComponent item={query16.item} result={query16.result} rowsPerPage={rowsPerPage16} 
        setRowsPerPage={setRowsPerPage16} page={page16} setPage={setPage16}/>
    </Dashboard>
  ]) 
};

export default PerDayReportPage;