const commonZhHK = {
  currentInventoryBalancePage: {
    name: "當前庫存記錄",
    filter: {
      propertyAc: {
        name: "供應商賬戶",
        all: "全部"
      },
      storeCode: {
        name: "分店編號",
        all: "全部"
      }
    },
    tableHeader: {
      propertyAc: "供應商賬戶",
      skuCode: "SKU編號",
      upcCode: "UPC編號",
      skuInternalUseTitle: "SKU內部名稱",
      storeCode: "分店編號",
      availableForSale: "可供出售",
      instock: "現貨",
      holdCount: "保留",
      available: "可供出售(已扣減保留)",
      cost: "成本",
      inventoryCost: "庫存成本"
    }
  },
  supplierSummaryPage: {
    filter: {
      propertyAc: {
        name: "供應商賬戶",
        all: "全部"
      },
      storeCode: {
        name: "分店編號",
        all: "全部"
      },
      orderCompletedAt: {
        "startDate": "訂單完成開始日期",
        "to": "至",
        "endDate": "訂單完成結束日期"
      }
    },
    titleMapping: {
      totalSkuQuantity: "總數量",
      totalOrderAmount: "總淨銷售額",
      totalGrossSales: "總銷售額",
      totalDiscount: "總折扣",
    },
    orderQuantityBreakdownByPropertyAC: {
      name: "訂單數量明細",
      tableHeader: {
        propertyAc: "供應商賬戶",
        referenceNumber: "訂單參考編號",
        shipFromStoreCode: "分店編號",
        skuCode: "SKU編號",
        upcCode: "UPC編號",
        skuInternalUseTitle: "SKU內部名稱",
        completedAt: "訂單完成日期",
        txHour: "交易時間 (小時)",
        weekday: "交易時間 (工作日)",
        txDow: "交易時間 (星期)",
        unitAmount: "單位金額",
        quantity: "數量",
        orderAmount: "訂單銷售額",
      }
    },
    orderQuantityBreakdownByPropertyACSummary: {
      name: "訂單數量匯總",
      tableHeader: {
        skuCode: "SKU編號",
        upcCode: "UPC編號",
        shipFromStoreCode: "分店編號",
        totalQuantity: "總數量",
        totalOrderAmount: "總訂單銷售額",
      }
    },
    discountQuantityBreakdownByPropertyAC: {
      name: "折扣數量明細",
      tableHeader: {
        propertyAc: "供應商賬戶",
        referenceNumber: "訂單參考編號",
        shipFromStoreCode: "分店編號",
        discountCode: "折扣編號",
        discountNameEn: "折扣名稱(英文)",
        discountNameZh: "折扣名稱(中文)",
        orderTransactionType: "折扣類型",
        skuInternalUseTitle: "SKU內部名稱",
        completedAt: "訂單完成日期",
        txHour: "交易時間 (小時)",
        weekday: "交易時間 (工作日)",
        txDow: "交易時間 (星期)",
        unitAmount: "單位金額",
        quantity: "數量",
        orderAmount: "訂單銷售額",
      }
    },
    discountQuantityBreakdownByPropertyACSummary: {
      name: "折扣數量匯總",
      tableHeader: {
        discountCode: "折扣編號",
        discountNameEn: "折扣名稱(英文)",
        discountNameZh: "折扣名稱(中文)",
        orderTransactionType: "折扣類型",
        shipFromStoreCode: "分店編號",
        totalQuantity: "總數量",
        totalOrderAmount: "總訂單銷售額",
      }
    },
    skuUploadTemplate: {
      name: "SKU上傳模板",
      tableHeader: {
        propertyAc: "skus[meta][property_ac](json)",
        productCode: "product[code]",
        skuCode: "sku[code]",
        upcCode: "sku[upc_code]",
        skuCommerceChannelCodes: "sku[commerce_channel_codes]",
        sizeOptionCode: "size_option[code]",
        sizeMasterCode: "size_option[size_master_code]",
        sizeOptionDisplaySequence: "size_option[display_sequence]",
        sizeOptionNameEn: "size_option[name]:en-HK",
        sizeOptionNameZn: "size_option[name]:zh-HK",
        sizeOptionState: "size_option[state]",
        colorOptionHexValue: "color_option[hex_value]",
        colorOptionColorCode: "color_option[color_code]",
        colorMasterCode: "color_option[color_master_code]",
        colorOptionNameEn: "color_option[name]:en-HK",
        colorOptionNameZh: "color_option[name]:zh-HK",
        colorOptionState: "color_option[state]",
        productType: "product[product_type]",
        productStatus: "product[status]",
        skuInternalUseTitle: "sku[internal_use_title]",
        productTitleEn: "product[title]:en-HK",
        productTitleZh: "product[title]:zh-HK",
        categoriesCode: "categories[1][code]",
      }
    },
    stockMovement: {
      name: "存貨來往",
      tableHeader: {
        transactionTimestamp: "交易時間",
        propertyAc: "供應商賬戶",
        skuCode: "SKU編號",
        upcCode: "UPC編號",
        productCode: "商品編號",
        storeCode: "分店編號",
        txNumber: "交易號碼",
        txType: "交易類型",
        quantity: "數量"
      }
    }
  },
  dailyOperation: {
    titleMapping: {
      orderCount: "訂單數量",
      orderQuantity: "貨品數量",
      netSales: "淨銷售額",
      grossSales: "銷售額",
      dpGrossSales: "DP銷售額",
      dpTotalQuantity: "DP貨品數量",
      dpDiscount: "DP折扣",
      dpNetSales: "DP淨銷售額",
      dpPercentage: "DP銷售額百分比",
      giftGrossSales: "Gift銷售額",
      giftTotalQuantity: "Gift貨品數量",
      giftDiscount: "Gift折扣",
      giftNetSales: "Gift淨銷售額",
      giftPercentage: "Gift銷售額百分比",
      con1GrossSales: "Con1銷售額",
      con1TotalQuantity: "Con1貨品數量",
      con1Discount: "Con1折扣",
      con1NetSales: "Con1淨銷售額",
      con1Percentage: "Con1銷售額百分比",
      con2GrossSales: "Con2銷售額",
      con2TotalQuantity: "Con2貨品數量",
      con2Discount: "Con2折扣",
      con2NetSales: "Con2淨銷售額",
      con2Percentage: "Con2銷售額百分比",
      rec1GrossSales: "Rec1銷售額",
      rec1TotalQuantity: "Rec1貨品數量",
      rec1Discount: "Rec1折扣",
      rec1NetSales: "Rec1淨銷售額",
      rec1Percentage: "Rec1銷售額百分比",
      t2GrossSales: "T2銷售額",
      t2TotalQuantity: "T2貨品數量",
      t2Discount: "T2折扣",
      t2NetSales: "T2淨銷售額",
      t2Percentage: "T2銷售額百分比",
      otherGrossSales: "其他銷售額",
      otherDiscount: "其他折扣",
      otherNetSales: "其他淨銷售額",
      otherTotalQuantity: "其他貨品數量",
      otherPercentage: "其他銷售額百分比",
      totalRefund: "總退款",
      totalRefundAmount: "總退款金額",
      netSalesDeductRefund: "淨銷售額 (已扣除退款)"
    },
    orderRefundSummaryDetail: {
      name: "訂單退款細明",
      tableHeader: {
        id: "退款ID",
        shipmentStoreCode: "分店編號",
        commerceChannelCode: "商業頻道編號",
        orderId: "訂單ID",
        referenceNumber: "訂單參考編號",
        refundAmount: "退款金額",
        refundedAt: "退款日期",
        refundState: "退款狀態"
      }
    },
    filter: {
      storeCode: {
        name: "分店編號",
        all: "全部"
      },
      orderConfirmedAt: {
        "startDate": "訂單確認開始日期",
        "to": "至",
        "endDate": "訂單確認結束日期"
      }
    },
  }
}

export default commonZhHK