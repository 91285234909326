import React, { useState, useContext } from "react";
import Header from '../../components/Header';
import TableComponent from '../../components/TableComponent';
import MultiSelectComponent from '../../components/MultiSelectComponent';
import DateRangePickerComponent from '../../components/DateRangePickerComponent';
import TextComponent from '../../components/TextComponent';
import TextFieldComponent from '../../components/TextFieldComponent';
import Dashboard from '../../components/Dashboard';
import PageHeaderComponent from '../../components/PageHeaderComponent';
import { CubeJSContext } from '../../context/CubeJSProvider';
import OmnitechQueries from '../../queries/OmnitechQueries'
import { useTranslation } from 'react-i18next';
import { useCubeQuery } from '@cubejs-client/react';
import { setHours, setMinutes, setSeconds, setMilliseconds, format } from 'date-fns'

const applyFilter = (query, storeFilter, rsmCodeFilter, skuCodeFilter) => {
  let storeCodeFilterObj = {
    member: "MvOrderTransactions.shipFromStoreCode",
    operator: "contains",
    values: storeFilter
  }

  query = {
    ...query,
    filters: (query.filters || []).concat(storeCodeFilterObj)
  }

  if (rsmCodeFilter.length > 0){
    let rsmCodeFilterObj = {
      member: "ViewReportCategoryProductsFlatten.code",
      operator: "equals",
      values: rsmCodeFilter
    }
    query = {
      ...query,
      filters: (query.filters || []).concat(rsmCodeFilterObj)
    }
  }

  if (skuCodeFilter){
    let skuCodeFilterObj = {
      member: "MvOrderTransactions.skuCode",
      operator: "contains",
      values: [skuCodeFilter]
    }
    query = {
      ...query,
      filters: (query.filters || []).concat(skuCodeFilterObj)
    }
  }

  return query
}

const SKUSalesDetailsHistQuery = (storeFilter, startDateFilter, endDateFilter, rsmCodeFilter, skuCodeFilter, isRefresh, setRefresh) => {
  const { t } = useTranslation();
  if(startDateFilter == null && endDateFilter == null){
    startDateFilter = new Date()
    startDateFilter.setHours(0,0,0,0);
    endDateFilter = new Date()
    endDateFilter.setHours(23,59,59,999);
  }
  else if(startDateFilter == null){
    startDateFilter = setHours(endDateFilter, 0)
    startDateFilter = setMinutes(startDateFilter, 0)
    startDateFilter = setSeconds(startDateFilter, 0)
    startDateFilter = setMilliseconds(startDateFilter, 0)
  }
  else if (endDateFilter == null){
    endDateFilter = setHours(startDateFilter, 23)
    endDateFilter = setMinutes(endDateFilter, 59)
    endDateFilter = setSeconds(endDateFilter, 59)
    endDateFilter = setMilliseconds(endDateFilter, 999)
  }
  let item = {
    name: t('page.fnb.SKUSalesDetailsHistPage.SKUSalesDetails.name'),
    tableHeaders: {
      "MvOrderTransactions.shipFromStoreCode": { name: t('page.fnb.SKUSalesDetailsHistPage.SKUSalesDetails.tableHeader.shipFromStoreCode'), type: 'string'},
      "MvOrderTransactions.skuCode": { name: t('page.fnb.SKUSalesDetailsHistPage.SKUSalesDetails.tableHeader.skuCode'), type: 'string'},
      "MvOrderTransactions.skuInternalUseTitle": { name: t('page.fnb.SKUSalesDetailsHistPage.SKUSalesDetails.tableHeader.skuInternalUseTitle'), type: 'string'},
      "MvOrderTransactions.skuCost": { name: t('page.fnb.SKUSalesDetailsHistPage.SKUSalesDetails.tableHeader.skuCost'), type: 'number'},
      "MvOrderTransactions.productTitleEnHk": { name: t('page.fnb.SKUSalesDetailsHistPage.SKUSalesDetails.tableHeader.productTitleEnHk'), type: 'string'},
      "MvOrderTransactions.productTitleZhHk":  { name: t('page.fnb.SKUSalesDetailsHistPage.SKUSalesDetails.tableHeader.productTitleZhHk'), type: "string" },
      "ViewReportCategoryProductsFlatten.code":  { name: t('page.fnb.SKUSalesDetailsHistPage.SKUSalesDetails.tableHeader.code'), type: "string" },
      "MvOrderTransactions.sumLineTotal": { name: t('page.fnb.SKUSalesDetailsHistPage.SKUSalesDetails.tableHeader.lineTotal'), type: 'number'},
      "MvOrderTransactions.sumQuantity": { name: t('page.fnb.SKUSalesDetailsHistPage.SKUSalesDetails.tableHeader.quantity'), type: 'number'},
    },
    grid: {xs: 12},
    export: { allowExport: true, filename: 'sku_sales_details_export_' + format(startDateFilter, 'yyyyMMddHHmmss') +
      '_' + format(endDateFilter, 'yyyyMMddHHmmss') }
  }
  let query = {
    ...OmnitechQueries.mvOrderTransactions.SKUSalesDetails.query,
    "timeDimensions": [
      {
        "dimension": "MvOrderTransactions.ordersCompletedAt",
        "dateRange": [ startDateFilter, endDateFilter ]
      }
    ],
  }
  query = applyFilter(query, storeFilter, rsmCodeFilter, skuCodeFilter)
  if(isRefresh)
    query = {}
  const result = useCubeQuery(query, {resetResultSetOnChange: true})
  if(isRefresh)
    setRefresh(false)
  return { item, result }
}

const SKUSalesDetailsByDateHistQuery = (storeFilter, startDateFilter, endDateFilter, rsmCodeFilter, isRefresh, setRefresh) => {
  const { t } = useTranslation();
  if(startDateFilter == null && endDateFilter == null){
    startDateFilter = new Date()
    startDateFilter.setHours(0,0,0,0);
    endDateFilter = new Date()
    endDateFilter.setHours(23,59,59,999);
  }
  else if(startDateFilter == null){
    startDateFilter = setHours(endDateFilter, 0)
    startDateFilter = setMinutes(startDateFilter, 0)
    startDateFilter = setSeconds(startDateFilter, 0)
    startDateFilter = setMilliseconds(startDateFilter, 0)
  }
  else if (endDateFilter == null){
    endDateFilter = setHours(startDateFilter, 23)
    endDateFilter = setMinutes(endDateFilter, 59)
    endDateFilter = setSeconds(endDateFilter, 59)
    endDateFilter = setMilliseconds(endDateFilter, 999)
  }
  let startDateString = new Date(startDateFilter.getTime() - (startDateFilter.getTimezoneOffset() * 60000 ))
                    .toISOString()
                    .split("T")[0];
  let endDateString = new Date(endDateFilter.getTime() - (endDateFilter.getTimezoneOffset() * 60000 ))
                    .toISOString()
                    .split("T")[0];
  let item = {
    name: t('page.fnb.SKUSalesDetailsHistPage.SKUSalesByDateDetails.name'),
    tableHeaders: {
      "MvOrderTransactions.sumLineTotal": { name: t('page.fnb.SKUSalesDetailsHistPage.SKUSalesByDateDetails.tableHeader.lineTotal'), type: 'number'},
      "MvOrderTransactions.sumQuantity": { name: t('page.fnb.SKUSalesDetailsHistPage.SKUSalesByDateDetails.tableHeader.quantity'), type: 'number'},
      "MvOrderTransactions.ordersCompletedAt.day": { name: t('page.fnb.SKUSalesDetailsHistPage.SKUSalesByDateDetails.tableHeader.ordersCompletedAt'), type: 'date'}
    },
    grid: {xs: 12},
    export: { allowExport: true, filename: 'sku_sales_by_day_details_export_' + format(startDateFilter, 'yyyyMMddHHmmss') +
      '_' + format(endDateFilter, 'yyyyMMddHHmmss') }
  }
  let query = {
    ...OmnitechQueries.mvOrderTransactions.SKUSalesByDateDetails.query,
    "timeDimensions": [
        {
          "dimension": "MvOrderTransactions.ordersCompletedAt",
          "granularity": "day",
          "dateRange": [ startDateString + " 00:00:00",
          endDateString + " 23:59:59" ]
        }
      ],
      "timezone": "Asia/Hong_Kong"
  }
  query = applyFilter(query, storeFilter, rsmCodeFilter, null)
  if(isRefresh)
    query = {}
  const result = useCubeQuery(query, {resetResultSetOnChange: true})
  if(isRefresh)
    setRefresh(false)
  return { item, result }
}

const SKUSalesDetailsHistPage = () => {
  const { t } = useTranslation();
   const context = useContext(CubeJSContext);
  let storeCodeSelect = []
  if(context.storeCodes)
    storeCodeSelect = context.storeCodes
  const [ storeFilter, setStoreFilter ] = useState([]);
  let filterStartDate = new Date()
  filterStartDate.setHours(0,0,0,0);
  let filterEndDate = new Date()
  filterEndDate.setHours(23,59,59,999);
  const [dateRangeFilter, setDateRangeFilter] = useState([filterStartDate, filterEndDate])
  const [startDateFilter, endDateFilter] = dateRangeFilter
  let rsmCodeSelect = ["a_la_carte", "afternoon_set", "alcohol_cocktail", "alcohol_mega", "appetizers", "baijiu", "bar_beverage", "beer", "breakfast", "brunch_set", "bubbles", "cash_voucher", "chicken", "coffee_tea", "create_your_own", "daily_special", "deliveroo", "deliveroo_brainfreeze", "deposit", "dessert", "dinner_set", "dinner_tasting_set", "eatigo", "exp_menu", "fathers_day_set", "festival_sets", "festive_menu", "festive_products", "festive_set", "food_modifiers", "foodpanda", "fruit_juice", "gin", "grand_open_party", "handcraft_burgers", "happy_hour", "hh_alcoholic_drink", "hh_snack_food", "hktvmall", "inline_spu", "k11", "k11tst_app_spu", "kids_menu", "kids_menu_drinks_upgrade", "kids_set", "liqueurs", "lunch_addon_drinks", "lunch_free_drinks", "lunch_refill_drinks", "lunch_set", "lunchset", "lunchset_addon", "membership", "merchant", "monthly_special", "mothers_day_set", "non_alcohol_cocktail", "non_alcohol_mega", "open_items", "openrice_spu", "other", "others_kk", "others_rz", "party", "partyset", "pasta", "premium_burgers", "premium_special", "privilege_pass", "quesadillas", "red_wine", "refill_drinks", "report_sales_mix", "retail", "ribs", "rose", "rsm_prom_leaflet", "rt_spu", "rte_tea_bogo", "ruby_mins", "rum", "salads_and_soups", "seafood", "seasonal_menu", "set_menu_2", "sides", "soft_beverage", "special_menu", "staff_meal", "staff_set", "steaks", "takeaway", "tea_free_drink", "tea_sets", "tequila", "tg_cafe", "valentines_set", "vodka", "voucher", "weekend_set", "whiskey", "white_wine", "wtc_app_spu", "xmas_set", "zeek_delivery"]
  const [rsmCodeFilter, setRsmCodeFilter] = useState([]);
  const [skuCodeFilter, setSkuCodeFilter] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page1, setPage1] = useState(0);
  const [page2, setPage2] = useState(0);

  const [ refresh1, setRefresh1 ] = useState(false);
  const [ refresh2, setRefresh2 ] = useState(false);

  const query1  = SKUSalesDetailsHistQuery(storeFilter, startDateFilter, endDateFilter, rsmCodeFilter, skuCodeFilter, refresh1, setRefresh1)
  const query2  = SKUSalesDetailsByDateHistQuery(storeFilter, startDateFilter, endDateFilter, rsmCodeFilter, refresh2, setRefresh2)

  const refresh = () => {
    setRefresh1(true)
    setRefresh2(true)
    setPage1(0)
    setPage2(0)
  }

  return ([
    <Header />,
    <PageHeaderComponent onClickFunc={refresh} refreshlabel={t('component.refresh')} headerTitle={t('component.header.menu.skuSalesDetailsHist')} />,
    <Dashboard>
      <MultiSelectComponent id={"store_code_filter"} state={storeFilter} pageChangerArr={[setPage1,setPage2]}
          stateChanger={setStoreFilter} textLabel={t('page.fnb.SKUSalesDetailsHistPage.filter.store.name')} selectArray={storeCodeSelect}/>
      <DateRangePickerComponent state={dateRangeFilter} stateChanger={setDateRangeFilter} pageChangerArr={[setPage1,setPage2]}
        startDateLabel={t('page.fnb.SKUSalesDetailsHistPage.filter.orderCompletedAt.startDate')} 
        toLabel={t('page.fnb.SKUSalesDetailsHistPage.filter.orderCompletedAt.to')} 
        endDateLabel={t('page.fnb.SKUSalesDetailsHistPage.filter.orderCompletedAt.endDate')}/>
      <MultiSelectComponent id={"rsm_code_filter"} state={rsmCodeFilter} pageChangerArr={[setPage1,setPage2]}
          stateChanger={setRsmCodeFilter} textLabel={t('page.fnb.SKUSalesDetailsHistPage.filter.rsmCode')} selectArray={rsmCodeSelect}/>
      <TextFieldComponent id={"sku_code_filter"} state={skuCodeFilter} pageChangerArr={[setPage1,setPage2]} 
        stateChanger={setSkuCodeFilter} textLabel={t('page.fnb.SKUSalesDetailsHistPage.filter.skuCode')}/>
    </Dashboard>
    ,
    <Dashboard>
      <TextComponent title={t('component.text.historical')} xs={12} />
      <TableComponent item={query1.item} result={query1.result} rowsPerPage={rowsPerPage} 
        setRowsPerPage={setRowsPerPage} page={page1} setPage={setPage1}/>
      <TableComponent item={query2.item} result={query2.result} rowsPerPage={rowsPerPage} 
        setRowsPerPage={setRowsPerPage} page={page2} setPage={setPage2}/>
    </Dashboard>
  ]) 
};

export default SKUSalesDetailsHistPage;