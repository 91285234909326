const StoreStaffSalesPerformanceQueries = {
  StoreStaffSalesPerformanceSummary: {
   query: {
      "measures": [
        "MvOrderTransactions.sumLineTotal",
        "MvOrderTransactions.sumQuantity",
        "MvOrderTransactions.sumDineInNumberOfPeople",
        "MvOrderTransactions.PPA",
      ],
      "order": {
        "MvOrderTransactions.shipFromStoreCode": "asc",
        "MvOrderTransactions.commerceChannelCode": "asc",
      },
      "filters": [
        {
          "member": "MvOrderTransactions.code",
          "operator": "equals",
          "values": [
            "sku_unit_sell_price",
            "property_unit_sell_price",
            "shipping_fee"
          ]
        },
        {
          "member": "MvOrderTransactions.commerceChannelCode",
          "operator": "notEquals",
          "values": [
            "membership_upgrade"
          ]
        },
      ],
      "dimensions": [
        "MvOrderTransactions.shipFromStoreCode",
        "MvOrderTransactions.staffNumber",
        "MvOrderTransactions.firstName",
        "MvOrderTransactions.lastName"
      ],
      "limit": 50000,
      "renewQuery": true,
    }
  }
}

export default StoreStaffSalesPerformanceQueries