import React, { useState, useContext } from "react";
import Header from '../../components/Header';
import TableComponent from '../../components/TableComponent';
import TextComponent from '../../components/TextComponent';
import MultiSelectComponent from '../../components/MultiSelectComponent';
import DateRangePickerComponent from '../../components/DateRangePickerComponent';
import Dashboard from '../../components/Dashboard';
import PageHeaderComponent from '../../components/PageHeaderComponent';
import OmnitechQueries from '../../queries/OmnitechQueries'
import { CubeJSContext } from '../../context/CubeJSProvider';
import { useTranslation } from 'react-i18next';
import { useCubeQuery } from '@cubejs-client/react';
import { format } from 'date-fns'

const applyFilter = (query, storeFilter) => {
  let storeCodeFilterObj = {
    member: "CartAuditTrail.storeCode",
    operator: "contains",
    values: storeFilter
  }

  query = {
    ...query,
    filters: (query.filters || []).concat(storeCodeFilterObj)
  }

  return query
}

const CartAuditTrailQueries = (storeFilter, startDateFilter, endDateFilter, isRefresh, setRefresh) => {
  const { t } = useTranslation();
  if(startDateFilter == null){
    startDateFilter = new Date()
    startDateFilter.setHours(0,0,0,0);
    endDateFilter = new Date()
    endDateFilter.setHours(23,59,59,999);
  }
  
  let item = {
    name: t('page.fnb.cartAuditTrail.name'),
    tableHeaders: {
      "CartAuditTrail.cartId": { name: t('page.fnb.cartAuditTrail.tableHeader.cartId'), type: "string" },
      "CartAuditTrail.cartCommerceChannelCode": { name: t('page.fnb.cartAuditTrail.tableHeader.cartCommerceChannelCode'), type: "string" },
      "CartAuditTrail.storeCode": { name: t('page.fnb.cartAuditTrail.tableHeader.storeCode'), type: "string" },
      "CartAuditTrail.orderId": { name: t('page.fnb.cartAuditTrail.tableHeader.orderId'), type: "number" },
      "CartAuditTrail.orderReferenceNumber": { name: t('page.fnb.cartAuditTrail.tableHeader.orderReferenceNumber'), type: "string" },
      "CartAuditTrail.orderState": { name: t('page.fnb.cartAuditTrail.tableHeader.orderState'), type: "string" },
      "CartAuditTrail.orderUpdatedAt": { name: t('page.fnb.cartAuditTrail.tableHeader.orderUpdatedAt'), type: "dateTime" },
      "CartAuditTrail.dineInTableNumber": { name: t('page.fnb.cartAuditTrail.tableHeader.dineInTableNumber'), type: "string" },
      "CartAuditTrail.dineInNumberOfPeople": { name: t('page.fnb.cartAuditTrail.tableHeader.dineInNumberOfPeople'), type: "number" },
      "CartAuditTrail.dineInTableSubNumber": { name: t('page.fnb.cartAuditTrail.tableHeader.dineInTableSubNumber'), type: "string" },
      "CartAuditTrail.staffFirstName": { name: t('page.fnb.cartAuditTrail.tableHeader.staffFirstName'), type: "string" },
      "CartAuditTrail.staffLastName": { name: t('page.fnb.cartAuditTrail.tableHeader.staffLastName'), type: "string" },
      "CartAuditTrail.staffNumber": { name: t('page.fnb.cartAuditTrail.tableHeader.staffNumber'), type: "string" },
      "CartAuditTrail.totalPrice": { name: t('page.fnb.cartAuditTrail.tableHeader.totalPrice'), type: "number" },
      "CartAuditTrail.cartUpdatedAt": { name: t('page.fnb.cartAuditTrail.tableHeader.cartUpdatedAt'), type: "dateTime" },
      "CartAuditTrail.cartCreatedAt": { name: t('page.fnb.cartAuditTrail.tableHeader.cartCreatedAt'), type: "dateTime" },
      "CartAuditTrail.cartClosedAt": { name: t('page.fnb.cartAuditTrail.tableHeader.cartClosedAt'), type: "dateTime" },
    },
    grid: {xs: 12},
    export: { allowExport: true, filename: 'cart_audit_trail_export_' + format(startDateFilter, 'yyyyMMddHHmmss') + 
      '_' + format(endDateFilter, 'yyyyMMddHHmmss') }
  }
  let query = {
    ...OmnitechQueries.carts.CartAuditTrail.query,
    "timeDimensions": [
      {
        "dimension": "CartAuditTrail.cartCreatedAt",
        "dateRange": [ startDateFilter, endDateFilter ]
      }
    ],
  }
  if(isRefresh)
    query = {}
  query = applyFilter(query, storeFilter)
  const result = useCubeQuery(query, {resetResultSetOnChange: true})
  if(isRefresh)
    setRefresh(false)
  return { item, result }
}


const CartAuditTrailPage = () => {
  const { t } = useTranslation();
  const context = useContext(CubeJSContext);
  let storeCodeSelect = []
  if(context.storeCodes)
    storeCodeSelect = context.storeCodes
  const [ storeFilter, setStoreFilter ] = useState([]);
  let filterStartDate = new Date()
  filterStartDate.setHours(0,0,0,0);
  let filterEndDate = new Date()
  filterEndDate.setHours(23,59,59,999);
  const [dateRangeFilter, setDateRangeFilter] = useState([filterStartDate, filterEndDate])
  const [startDateFilter, endDateFilter] = dateRangeFilter
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  const [ refresh1, setRefresh1 ] = useState(false);

  const { item, result } = CartAuditTrailQueries(storeFilter, startDateFilter, endDateFilter, refresh1, setRefresh1)

  const refresh = () => {
    setRefresh1(true)
    setPage(0)
  }

  return ([
    <Header />,
    <PageHeaderComponent onClickFunc={refresh} refreshlabel={t('component.refresh')} headerTitle={t('component.header.menu.cartAuditTrailReport')} />,
    <Dashboard>
      <MultiSelectComponent id={"store_code_filter"} state={storeFilter} pageChangerArr={[setPage]}
          stateChanger={setStoreFilter} textLabel={t('page.fnb.cartAuditTrail.filter.store.name')} selectArray={storeCodeSelect}/>
      <DateRangePickerComponent state={dateRangeFilter} stateChanger={setDateRangeFilter} pageChangerArr={[setPage]}
        startDateLabel={t('page.fnb.cartAuditTrail.filter.cartCreatedAt.startDate')} 
        toLabel={t('page.fnb.cartAuditTrail.filter.cartCreatedAt.to')} 
        endDateLabel={t('page.fnb.cartAuditTrail.filter.cartCreatedAt.endDate')}/>
    </Dashboard>
    ,
    <Dashboard>
      <TextComponent title={t('component.text.longQuery')} xs={12} />
      <TableComponent item={item} result={result} rowsPerPage={rowsPerPage} 
        setRowsPerPage={setRowsPerPage} page={page} setPage={setPage}/>
    </Dashboard>
  ]) 
};

export default CartAuditTrailPage;