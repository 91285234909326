import OutstandingTableQueries from './OutstandingTableQueries.js'
import MPosVsSelfOrderQueries from './MPosVsSelfOrderQueries.js'

const carts = {
  carts: {
    ...OutstandingTableQueries,
    ...MPosVsSelfOrderQueries,
  }
}

export default carts