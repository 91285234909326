const TakeawayDeliveryOrderApprovalTimeTraceQueries = {
  TakeawayDeliveryOrderApprovalTimeTrace: {
    query: {
      "order": {
        "Orders.createdAt": "desc",
      },
      "dimensions": [
        "Stores.code",
        "Orders.referenceNumber",
        "Orders.state",
        "Orders.createdAt",
        "Orders.waitingForApprovalAt",
        "Orders.confirmedAt",
        "Orders.orderType",
        "CommerceChannels.code",
        "Orders.ticketNumber",
      ],
      "timeDimensions": [
      ],
      "filters": [
        {
          "member": "CommerceChannels.code",
          "operator": "equals",
          "values": [
            "take_away_hkd"
          ]
        }
      ],
      "limit": 50000,
      "renewQuery": true,
    }
  }
}

export default TakeawayDeliveryOrderApprovalTimeTraceQueries