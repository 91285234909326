const DayEndQueries = {
  OutstandingTableNetSales:{
    query: {
      "measures": [
        "MvCartTransactions.sumLineTotal",
      ],
      "timeDimensions": [
      ],
      "order": {
      },
      "dimensions": [
      ],
      "filters": [
        {
          "or": [
            {
              "member": "MvCartTransactions.dineInTableNumber",
              "operator": "notContains",
              "values": [
                "test"
              ]
            },
            {
              "member": "MvCartTransactions.dineInTableNumber",
              "operator": "notSet"
            },
          ]
        },
        {
          "member": "MvCartTransactions.cartState",
          "operator": "equals",
          "values": [
            "isolated"
          ]
        },
        {
          "member": "MvCartTransactions.commerceChannelCode",
          "operator": "equals",
          "values": [
            "dine_in_pos_hkd",
            "dine_in_hkd"
          ]
        },
      ],
      "limit": 50000,
      "renewQuery": true,
    }
  },
}

export default DayEndQueries