import React, { useState, useMemo } from "react";
import Header from '../../components/Header';
import TableComponent from '../../components/TableComponent';
import DateRangePickerComponent from '../../components/DateRangePickerComponent';
import TextComponent from '../../components/TextComponent';
import ButtonGroupComponent from '../../components/ButtonGroupComponent';
import ChartComponent from '../../components/ChartComponent';
import Dashboard from '../../components/Dashboard';
import PageHeaderComponent from '../../components/PageHeaderComponent';
import OmnitechQueries from '../../queries/OmnitechQueries'
import { useTranslation } from 'react-i18next';
import { useCubeQuery } from '@cubejs-client/react';
import { setHours, setMinutes, setSeconds, setMilliseconds, format } from 'date-fns'

const NewMemberQuery = (startDateFilter, endDateFilter, granularity, isRefresh, setRefresh) => {
  const { t } = useTranslation();
  if(startDateFilter == null && endDateFilter == null){
    startDateFilter = new Date()
    startDateFilter.setHours(0,0,0,0);
    endDateFilter = new Date()
    endDateFilter.setHours(23,59,59,999);
  }
  else if(startDateFilter == null){
    startDateFilter = setHours(endDateFilter, 0)
    startDateFilter = setMinutes(startDateFilter, 0)
    startDateFilter = setSeconds(startDateFilter, 0)
    startDateFilter = setMilliseconds(startDateFilter, 0)
  }
  else if (endDateFilter == null){
    endDateFilter = setHours(startDateFilter, 23)
    endDateFilter = setMinutes(endDateFilter, 59)
    endDateFilter = setSeconds(endDateFilter, 59)
    endDateFilter = setMilliseconds(endDateFilter, 999)
  }
  let startDateString = new Date(startDateFilter.getTime() - (startDateFilter.getTimezoneOffset() * 60000 ))
                    .toISOString()
                    .split("T")[0];
  let endDateString = new Date(endDateFilter.getTime() - (endDateFilter.getTimezoneOffset() * 60000 ))
                    .toISOString()
                    .split("T")[0];
  let item = {
    name: t('page.membership.NewMemberPage.name'),
    tableHeaders: {
      "MvNewMember.sumCount": { name: t('page.membership.NewMemberPage.tableHeader.count'), type: 'number'},
      "MvNewMember.createdDate.day": { name: t('page.membership.NewMemberPage.tableHeader.cartsCreatedAt'), type: 'date'},
      "MvNewMember.createdDate.month": { name: t('page.membership.NewMemberPage.tableHeader.cartsCreatedAt'), type: 'dateMonth'},
      "MvNewMember.createdDate.year": { name: t('page.membership.NewMemberPage.tableHeader.cartsCreatedAt'), type: 'dateYear'}
    },
    grid: {sm: 12, md: 12},
    export: { allowExport: true, filename: 'new_member_export_by_' + granularity + '_' + format(startDateFilter, 'yyyyMMddHHmmss') +
      '_' + format(endDateFilter, 'yyyyMMddHHmmss') }
  }
  let query = {
    ...OmnitechQueries.mvNewMember.NewMember.query,
    "timeDimensions": [
      {
        "dimension": "MvNewMember.createdDate",
        "granularity": granularity,
        "dateRange": [ startDateString + " 00:00:00",
         endDateString + " 23:59:59" ]
      }
    ],
    "timezone": "Asia/Hong_Kong"
  }

  if(isRefresh)
    query = {}
  const result = useCubeQuery(query, {resetResultSetOnChange: true})
  if(isRefresh)
    setRefresh(false)
  return { item, result }
}

const getNewMemberChart = (resultSet, granularity, sumCountName) => {
  const renameLabels = {
    "MvNewMember.sumCount": sumCountName
  };
  return {
    data: resultSet?.chartPivot().map((data) => {
      return {
        ...data,
        x: granularity === 'day' ? format(new Date(`${data.x}+00:00`), 'yyyy/MM/dd') : 
          granularity === 'month' ? format(new Date(`${data.x}+00:00`), 'yyyy/MM') : 
          format(new Date(`${data.x}+00:00`), 'yyyy'),
      }
    }),
    dataSeries: resultSet?.seriesNames().map((seriesName) => {
      return {
        ...seriesName,
        title: renameLabels[seriesName.key] ? renameLabels[seriesName.key]: seriesName.yValues[0],
      }
    }),
  }
}

const NewMemberPage = () => {
  const { t } = useTranslation();
  let filterStartDate = new Date()
  filterStartDate.setHours(0,0,0,0);
  let filterEndDate = new Date()
  filterEndDate.setHours(23,59,59,999);
  let groupArr = [{id: 'day', code: t('component.day')},{id: 'month', code: t('component.month')},{id: 'year', code: t('component.year')}]
  const [ granularity, setGranularity ] = useState('day');

  const [dateRangeFilter, setDateRangeFilter] = useState([filterStartDate, filterEndDate])
  const [startDateFilter, endDateFilter] = dateRangeFilter
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  const [ refresh1, setRefresh1 ] = useState(false);

  const query = NewMemberQuery(startDateFilter, endDateFilter, granularity, refresh1, setRefresh1)

  const refresh = () => {
    setRefresh1(true)
    setPage(0)
  }

  const newMemberChartResultSet = query.result?.resultSet
  const sumCountName = t('page.membership.NewMemberPage.tableHeader.count')
  const newMemberChart = useMemo(() => {
    if (newMemberChartResultSet === null) return {data: [], dataSeries: []}
    
    return getNewMemberChart(newMemberChartResultSet, granularity, sumCountName)
  }, [newMemberChartResultSet, granularity, sumCountName])

  return ([
    <Header />,
    <PageHeaderComponent onClickFunc={refresh} refreshlabel={t('component.refresh')} headerTitle={t('component.header.menu.newMember')} />,
    <Dashboard>
      <DateRangePickerComponent state={dateRangeFilter} stateChanger={setDateRangeFilter} 
        pageChangerArr={[setPage]}
        startDateLabel={t('page.membership.NewMemberPage.filter.createdDate.startDate')} 
        toLabel={t('page.membership.NewMemberPage.filter.createdDate.to')} 
        endDateLabel={t('page.membership.NewMemberPage.filter.createdDate.endDate')}/>
      <ButtonGroupComponent state={granularity} stateChanger={setGranularity} pageChangerArr={[setPage]} buttonGroupArray={groupArr} title={t('component.dateGranularity')}/>
    </Dashboard>,
    <Dashboard>
      <TextComponent title={t('component.text.historical')} xs={12} />
      <ChartComponent chartType="line" data={newMemberChart.data} dataSeries={newMemberChart.dataSeries} 
        item={query.item} result={query.result} titleName={query.item.name} enableTooltip />
      <TableComponent item={query.item} result={query.result} rowsPerPage={rowsPerPage} 
        setRowsPerPage={setRowsPerPage} page={page} setPage={setPage}/>
    </Dashboard>
  ]) 
};

export default NewMemberPage;