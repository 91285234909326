import fnbEnHK from './fnb/en-HK'
import rtEnHK from './rt/en-HK'
import fsEnHK from './fs/en-HK'
import cheEnHK from './che/en-HK'
import kstEnHK from './kst/en-HK'
import supplierEnHK from './supplier/en-HK'
import membershipEnHK from './membership/en-HK'
import stocktakeEnHK from './stocktake/en-HK'

const pageEnHK = {
  page: {
    ...fnbEnHK,
    ...rtEnHK,
    ...cheEnHK,
    ...kstEnHK,
    ...fsEnHK,
    ...supplierEnHK,
    ...membershipEnHK,
    ...stocktakeEnHK,
    "dashboard": {
      "main": "Please select report on navigation bar"
    },
    "signIn": {
      "copyright": "Copyright © ",
      "tofugear": "Tofugear Limited",
      "wrongCompanyCode": "Invalid Company Code",
      "wrongUsernamePassword": "Invalid Email Address/Password",
      "signIn": "Sign In",
      "companyCode": "Company Code",
      "email": "Email Address",
      "password": "Password",
      "remember": "Remember me",
      "forgotPassword": "Forgot password",
      "forgotPasswordDetail": "Please contact your administrator to reset your password.",
      "ok": "OK",
      "internalError": "Internal Error, please contact Tofugear",
      "expired": "Your session was expired, please re-login",
      "authenticating": "Authenticating ...",
    }
  }
}

export default pageEnHK