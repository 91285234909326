import React, { useState } from "react";
import Header from '../../components/Header';
import TableComponent from '../../components/TableComponent';
import Dashboard from '../../components/Dashboard';
import OmnitechQueries from '../../queries/OmnitechQueries'
import PageHeaderComponent from '../../components/PageHeaderComponent';
import { useTranslation } from 'react-i18next';
import { useCubeQuery } from '@cubejs-client/react';
import { format } from 'date-fns'

const PMCampaignRateQuery = (isRefresh, setRefresh) => {
  const { t } = useTranslation();
  let item = {
    name: t('page.membership.PMCampaignRatePage.name'),
    tableHeaders: {
      "PMCampaignRate.pushMessageCampaignCode":  { name: t('page.membership.PMCampaignRatePage.tableHeader.pushMessageCampaignCode'), type: "string" },
      "PMCampaignRate.campaignStartDate":  { name: t('page.membership.PMCampaignRatePage.tableHeader.campaignStartDate'), type: "dateTime" },
      "PMCampaignRate.scheduleHour":  { name: t('page.membership.PMCampaignRatePage.tableHeader.scheduleHour'), type: "number" },
      "PMCampaignRate.totalMessages":  { name: t('page.membership.PMCampaignRatePage.tableHeader.totalMessages'), type: "number" },
      "PMCampaignRate.readCount":  { name: t('page.membership.PMCampaignRatePage.tableHeader.readCount'), type: "number" },
      "PMCampaignRate.readPercentage":  { name: t('page.membership.PMCampaignRatePage.tableHeader.readPercentage'), type: "number" }
    },
    grid: {xs: 12, lg: 12},
    export: { allowExport: true, filename: 'push_message_campaign_rate_' + format(new Date(), 'yyyyMMddHHmmss') }
  }
  let query = {
    ...OmnitechQueries.push_messages.PMCampaignRate.query
  }
  if(isRefresh)
    query = {}
  const result = useCubeQuery(query, {resetResultSetOnChange: true})
  if(isRefresh)
    setRefresh(false)
  return { item, result }
}

const PMCampaignRatePage = () => {
  const { t } = useTranslation();
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  const [ refresh1, setRefresh1 ] = useState(false);

  const { item, result }  = PMCampaignRateQuery(refresh1, setRefresh1)

  const refresh = () => {
    setRefresh1(true)
    setPage(0)
  }

  return ([
    <Header />,
    <PageHeaderComponent onClickFunc={refresh} refreshlabel={t('component.refresh')} headerTitle={t('component.header.menu.PMCampaignRate')} />,
    <Dashboard>
      <TableComponent item={item} result={result} rowsPerPage={rowsPerPage} 
        setRowsPerPage={setRowsPerPage} page={page} setPage={setPage}/>
    </Dashboard>
  ]) 
};

export default PMCampaignRatePage;