const DayEndQueries = {
  OrderSummaryHist:{
    query: {
      "measures": [
        "MvOrdersSummary.sumGrossSales",
        "MvOrdersSummary.sumDiscounts",
        "MvOrdersSummary.sumShippingFees",
        "MvOrdersSummary.sumServiceCharges",
        "MvOrdersSummary.sumOrderAmountRounding",
        "MvOrdersSummary.sumMembershipCharges",
        "MvOrdersSummary.sumOrderTotal",
        "MvOrdersSummary.sumNetSales"
      ],
      "timeDimensions": [
      ],
      "order": {
      },
      "dimensions": [
      ],
      "filters": [
        {
          "member": "MvOrdersSummary.state",
          "operator": "equals",
          "values": [
            "completed"
          ]
        },
        {
          "member": "MvOrdersSummary.commerceChannelCode",
          "operator": "notEquals",
          "values": [
            "membership_upgrade"
          ]
        },
        {
          "member": "MvOrdersSummary.orderSource",
          "operator": "notEquals",
          "values": [
            "membership_upgrade"
          ]
        }
      ],
      "limit": 50000,
      "renewQuery": true,
    }
  },
  VoidOrderSummaryHist:{
    query: {
      "measures": [
        "MvOrdersSummary.sumOrderTotal",
        "MvOrdersSummary.count",
      ],
      "timeDimensions": [
      ],
      "order": {
      },
      "dimensions": [
      ],
      "filters": [
        {
          "member": "MvOrdersSummary.state",
          "operator": "equals",
          "values": [
            "void_completed"
          ]
        },
        {
          "member": "MvOrdersSummary.commerceChannelCode",
          "operator": "notEquals",
          "values": [
            "membership_upgrade"
          ]
        },
        {
          "member": "MvOrdersSummary.orderSource",
          "operator": "notEquals",
          "values": [
            "membership_upgrade"
          ]
        }
      ],
      "limit": 50000,
      "renewQuery": true,
    }
  },
  NetSalesByStoreHist:{
    query: {
      "measures": [
        "MvOrdersSummary.count",
        "MvOrdersSummary.sumOrderTotal"
      ],
      "timeDimensions": [
      ],
      "order": {
        "MvOrdersSummary.shipFromStoreCode": "asc",
        "MvOrdersSummary.orderSource": "asc"
      },
      "dimensions": [
        "MvOrdersSummary.shipFromStoreCode",
        "MvOrdersSummary.orderSource"
      ],
      "filters": [
        {
          "member": "MvOrdersSummary.state",
          "operator": "equals",
          "values": [
            "completed"
          ]
        },
        {
          "member": "MvOrdersSummary.commerceChannelCode",
          "operator": "notEquals",
          "values": [
            "membership_upgrade"
          ]
        },
        {
          "member": "MvOrdersSummary.orderSource",
          "operator": "notEquals",
          "values": [
            "membership_upgrade"
          ]
        }
      ],
      "limit": 50000,
      "renewQuery": true,
    }
  },
  DiscountByStorePerDayHist:{
    query: {
      "measures": [
        "MvOrdersSummary.sumDiscounts",
      ],
      "timeDimensions": [
      ],
      "order": {
        "MvOrdersSummary.shipFromStoreCode": "asc",
      },
      "dimensions": [
        "MvOrdersSummary.shipFromStoreCode",
      ],
      "filters": [
        {
          "member": "MvOrdersSummary.state",
          "operator": "equals",
          "values": [
            "completed"
          ]
        },
        {
          "member": "MvOrdersSummary.commerceChannelCode",
          "operator": "notEquals",
          "values": [
            "membership_upgrade"
          ]
        },
        {
          "member": "MvOrdersSummary.orderSource",
          "operator": "notEquals",
          "values": [
            "membership_upgrade"
          ]
        }
      ],
      "limit": 50000,
      "renewQuery": true,
    }
  },
  MembershipSalesByStorePerDayHist:{
    query: {
      "measures": [
        "MvOrdersSummary.sumMembershipCharges"
      ],
      "timeDimensions": [
      ],
      "order": {
        "MvOrdersSummary.shipFromStoreCode": "asc",
      },
      "dimensions": [
        "MvOrdersSummary.shipFromStoreCode",
      ],
      "filters": [
        {
          "member": "MvOrdersSummary.state",
          "operator": "equals",
          "values": [
            "completed"
          ]
        },
        {
          "member": "MvOrdersSummary.commerceChannelCode",
          "operator": "notEquals",
          "values": [
            "membership_upgrade"
          ]
        },
        {
          "member": "MvOrdersSummary.orderSource",
          "operator": "notEquals",
          "values": [
            "membership_upgrade"
          ]
        }
      ],
      "limit": 50000,
      "renewQuery": true,
    }
  },
}

export default DayEndQueries