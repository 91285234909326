const MembershipPurchaseQueries = {
  MembershipPurchase: {
   query: {
      "measures": [
      ],
      "order": {
        "MvOrderTransactions.ordersCompletedAt": "desc"
      },
      "filters": [
        {
          "member": "MvOrderTransactions.productCode",
          "operator": "contains",
          "values": [
            "membership"
          ]
        },
        {
          "member": "MvOrderTransactions.orderState",
          "operator": "equals",
          "values": [
            "completed"
          ]
        },
      ],
      "dimensions": [
        "Users.membershipCode",
        "Users.firstName",
        "Users.email",
        "Users.phone",
        "MvOrdersSummary.referenceNumber",
        "MvOrderTransactions.shipFromStoreCode",
        "MvOrderTransactions.staffNumber",
        "MvOrderTransactions.firstName",
        "MvOrderTransactions.commerceChannelCode",
        "MvOrderTransactions.skuCode",
        "MvOrderTransactions.skuInternalUseTitle",
        "MvOrderTransactions.productCode",
        "MvOrderTransactions.productTitleEnHk",
        "MvOrderTransactions.productTitleZhHk",
        "MvOrderTransactions.quantity",
        "MvOrderTransactions.unitAmount",
        "MvOrderTransactions.lineTotal",
        "MvOrderTransactions.ordersCompletedAt"
      ],
      "limit": 50000,
      "renewQuery": true,
    }
  },
  MembershipPurchaseSummary: {
   query: {
      "measures": [
        "MvOrderTransactions.sumQuantity",
        "MvOrderTransactions.sumLineTotal",
      ],
      "order": {
      },
      "filters": [
        {
          "member": "MvOrderTransactions.productCode",
          "operator": "contains",
          "values": [
            "membership"
          ]
        },
        {
          "member": "MvOrderTransactions.orderState",
          "operator": "equals",
          "values": [
            "completed"
          ]
        },
      ],
      "dimensions": [
      ],
      "limit": 50000,
      "renewQuery": true,
    }
  }
}

export default MembershipPurchaseQueries