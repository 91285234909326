import React from 'react';
import Grid from '@material-ui/core/Grid';
import DashboardItem from './DashboardItem';
import TransparentDashboardItem from './TransparentDashboardItem';
import Typography from '@material-ui/core/Typography';

export default function TextComponent({title, contentArray, xs = 12, sm, md, lg, transparent = false, styles={}}) {
  return (
    <Grid item xs={xs} sm={sm} md={md} lg={lg}>
      <div>
        {!transparent && (<DashboardItem title={title} height={null}>
          {contentArray && contentArray.map((c) => (
            <Typography variant="body2" color="textSecondary" gutterBottom>
              {c}
            </Typography>
          ))}
        </DashboardItem>)}
        {transparent && (<TransparentDashboardItem title={title} height={null} styles={styles}>
          {contentArray && contentArray.map((c) => (
            <Typography variant="body2" color="textSecondary" gutterBottom>
              {c}
            </Typography>
          ))}
        </TransparentDashboardItem>)}
      </div>
    </Grid>
  );
}
