import React from "react";
import DateRangePicker from '@material-ui/lab/DateRangePicker';
import AdapterDateFns from '@material-ui/lab/AdapterDateFns';
import LocalizationProvider from '@material-ui/lab/LocalizationProvider';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import {addHours, addMinutes, addSeconds, addMilliseconds} from 'date-fns'

const ModifyEndDate = (stateChanger, date) => {
  if(date[1] != null){
    date[1] = addHours(date[1], 23)
    date[1] = addMinutes(date[1], 59)
    date[1] = addSeconds(date[1], 59)
    date[1] = addMilliseconds(date[1], 999)
  }
  stateChanger(date)
}

let DateRangePickerComponent = ({state, stateChanger, startDateLabel, toLabel, endDateLabel, pageChangerArr, ...rest}) => {

    return(
      <div style={{marginLeft: "24px", marginBottom: "20px"}}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DateRangePicker
          startText={startDateLabel}
          endText={endDateLabel}
          value={state}
          allowSameDateSelection={true}
          showTodayButton={true}
          showToolbar={true}
          disableCloseOnSelect={false}
          onChange={(date) => {
            if(pageChangerArr){
              for(let i = 0; i < pageChangerArr.length; i++){
                pageChangerArr[i](0)
              }
            }
            ModifyEndDate(stateChanger, date)
          }}
          renderInput={(startProps, endProps) => (
            <React.Fragment>
              <TextField {...startProps}/>
              <Box sx={{ mx: 2 }}> {toLabel} </Box>
              <TextField {...endProps}/>
            </React.Fragment>
          )}
        />
      </LocalizationProvider>
      </div>
    )
}

export default DateRangePickerComponent;