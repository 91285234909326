import React from "react";

import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginTop: '20',
    marginBottom: '20',
    marginLeft: '30',
    marginRight: '30',
    width: 200,
  },

}));

let TextFieldComponent = ({id, state, stateChanger, textLabel, pageChangerArr, ...rest}) => {
  const classes = useStyles();

  return(
    <div style={{marginLeft: "24px", marginBottom: "20px"}}>
      <TextField
        id={id}
        label={textLabel}
        defaultValue={state}
        className={classes.textField}
        onChange={(event) => {
          if(pageChangerArr !== null){
            for(let i = 0; i < pageChangerArr.length; i++){
              pageChangerArr[i](0)
            }
          }
          stateChanger(event.target.value)
        }}
      />
    </div>
  )
}

export default TextFieldComponent;