import React, { useState } from "react";
import Header from '../../components/Header';
import TableComponent from '../../components/TableComponent';
import TextFieldComponent from '../../components/TextFieldComponent';
import TextComponent from '../../components/TextComponent';
import Box from '@material-ui/core/Box';
import Dashboard from '../../components/Dashboard';
import PageHeaderComponent from '../../components/PageHeaderComponent';
import OmnitechQueries from '../../queries/OmnitechQueries';
import { useTranslation } from 'react-i18next';
import { useCubeQuery } from '@cubejs-client/react';
import { format } from 'date-fns'

const applyFilter = (query, membershipCodeRealFilter, memberString) => {

  let membershipCodeFilterObj = {
    member: memberString,
    operator: "contains",
    values: [membershipCodeRealFilter]
  }

  if (membershipCodeRealFilter !== '') {
    query = {
      ...query,
      filters: (query.filters || []).concat(membershipCodeFilterObj)
    }
  }

  return query
}

const UserExportQuery = (membershipCodeRealFilter, isRefresh, setRefresh) => {
  const { t } = useTranslation();
  let item = {
    name: t('page.fnb.userQuery.userExport.name'),
    tableHeaders: {
      "UserOrders.totalPrice":  { name: t('page.fnb.userQuery.userExport.tableHeader.totalSpend'), type: "number" },
      "UserOrders.count":  { name: t('page.fnb.userQuery.userExport.tableHeader.orderCount'), type: "number" },
      "Users.membershipCode":  { name: t('page.fnb.userQuery.userExport.tableHeader.membershipCode'), type: "string" },
      "Users.firstName":  { name: t('page.fnb.userQuery.userExport.tableHeader.firstName'), type: "string" },
      "CustomerRanks.code":  { name: t('page.fnb.userQuery.userExport.tableHeader.rankCode'), type: "string" },
      "Users.sex":  { name: t('page.fnb.userQuery.userExport.tableHeader.sex'), type: "string" },
      "Users.email":  { name: t('page.fnb.userQuery.userExport.tableHeader.email'), type: "string" },
      "Users.phone":  { name: t('page.fnb.userQuery.userExport.tableHeader.phone'), type: "string" },
      "Users.dateOfBirth":  { name: t('page.fnb.userQuery.userExport.tableHeader.dateOfBirth'), type: "date" },
      "Users.isActive":  { name: t('page.fnb.userQuery.userExport.tableHeader.isActive'), type: "string" },
      "Users.isDeleted":  { name: t('page.fnb.userQuery.userExport.tableHeader.isDeleted'), type: "string" },
      "Users.remark":  { name: t('page.fnb.userQuery.userExport.tableHeader.remark'), type: "string" },
      "Users.createdAt":  { name: t('page.fnb.userQuery.userExport.tableHeader.createdAt'), type: "dateTime" },
      "Users.updatedAt":  { name: t('page.fnb.userQuery.userExport.tableHeader.updatedAt'), type: "dateTime" },
      "Users.modifiedAt":  { name: t('page.fnb.userQuery.userExport.tableHeader.modifiedAt'), type: "dateTime" },
      "UserLoyaltyPointBalances.rankExpireAt":  { name: t('page.fnb.userQuery.userExport.tableHeader.rankExpireAt'), type: "dateTime" },
      "Users.userPreferDistrict":  { name: t('page.fnb.userQuery.userExport.tableHeader.userPreferDistrict'), type: "string" },
      "Users.district":  { name: t('page.fnb.userQuery.userExport.tableHeader.district'), type: "string" },
      "UserLoyaltyPointBalances.balance":  { name: t('page.fnb.userQuery.userExport.tableHeader.point'), type: "number" },
      "Users.userAgreementPics":  { name: t('page.fnb.userQuery.userExport.tableHeader.userAgreementPics'), type: "string" },
      "Users.userAgreementTerms":  { name: t('page.fnb.userQuery.userExport.tableHeader.userAgreementTerms'), type: "string" },
    },
    grid: {xs: 12, lg: 12},
    export: { allowExport: true, filename: membershipCodeRealFilter+'_user_export_' + format(new Date(), 'yyyyMMddHHmmss') }
  }
  let query = {
    ...OmnitechQueries.users.UserExport.query
  }
  query = applyFilter(query, membershipCodeRealFilter, "Users.membershipCode")
  if(isRefresh)
    query = {}
  const result = useCubeQuery(query, {resetResultSetOnChange: true})
  if(isRefresh)
    setRefresh(false)
  return { item, result }
}

const MembershipTransactionQuery = (membershipCodeRealFilter, isRefresh, setRefresh) => {
  const { t } = useTranslation();
  let item = {
    name: t('page.fnb.userQuery.membershipTransaction.name'),
    tableHeaders: {
      "CustomerRanks.code":  { name: t('page.fnb.userQuery.membershipTransaction.tableHeader.rankCode'), type: "string" },
      "UserCustomerRankTransactions.effectiveAt":  { name: t('page.fnb.userQuery.membershipTransaction.tableHeader.effectiveAt'), type: "dateTime" },
      "UserCustomerRankTransactions.expireAt":  { name: t('page.fnb.userQuery.membershipTransaction.tableHeader.expireAt'), type: "dateTime" },
      "UserCustomerRankTransactions.isDeletedString":  { name: t('page.fnb.userQuery.membershipTransaction.tableHeader.isDeleted'), type: "boolean" },
    },
    grid: {xs: 12, lg: 12},
    export: { allowExport: true, filename: membershipCodeRealFilter+'_membership_transaction_export_' + format(new Date(), 'yyyyMMddHHmmss') }
  }
  let query = {
    ...OmnitechQueries.users.MembershipTransaction.query
  }
  query = applyFilter(query, membershipCodeRealFilter, "Users.membershipCode")
  if(isRefresh)
    query = {}
  const result = useCubeQuery(query, {resetResultSetOnChange: true})
  if(isRefresh)
    setRefresh(false)
  return { item, result }
}

const OrderHistoryQuery = (membershipCodeRealFilter, isRefresh, setRefresh) => {
  const { t } = useTranslation();
  let item = {
    name: t('page.fnb.userQuery.orderHistory.name'),
    tableHeaders: {
      "MvOrdersSummaryUnlimited.ordersCompletedAt":  { name: t('page.fnb.userQuery.orderHistory.tableHeader.ordersCompletedAt'), type: "dateTime" },
      "MvOrdersSummaryUnlimited.shipFromStoreCode":  { name: t('page.fnb.userQuery.orderHistory.tableHeader.shipFromStoreCode'), type: "string" },
      "MvOrdersSummaryUnlimited.referenceNumber":  { name: t('page.fnb.userQuery.orderHistory.tableHeader.referenceNumber'), type: "string" },
      "MvOrdersSummaryUnlimited.grossSales":  { name: t('page.fnb.userQuery.orderHistory.tableHeader.grossSales'), type: "number" },
      "MvOrdersSummaryUnlimited.discounts":  { name: t('page.fnb.userQuery.orderHistory.tableHeader.discounts'), type: "number" },
      "MvOrdersSummaryUnlimited.serviceCharges":  { name: t('page.fnb.userQuery.orderHistory.tableHeader.serviceCharges'), type: "number" },
      "MvOrdersSummaryUnlimited.orderAmountRounding":  { name: t('page.fnb.userQuery.orderHistory.tableHeader.orderAmountRounding'), type: "number" },
      "MvOrdersSummaryUnlimited.membershipCharges":  { name: t('page.fnb.userQuery.orderHistory.tableHeader.membershipCharges'), type: "number" },
      "MvOrdersSummaryUnlimited.orderTotal":  { name: t('page.fnb.userQuery.orderHistory.tableHeader.orderTotal'), type: "number" },
    },
    grid: {xs: 12, lg: 12},
    export: { allowExport: true, filename: membershipCodeRealFilter+'_order_history_export_' + format(new Date(), 'yyyyMMddHHmmss') }
  }
  let query = {
    ...OmnitechQueries.users.OrderHistory.query
  }
  query = applyFilter(query, membershipCodeRealFilter, "Users.membershipCode")
  if(isRefresh)
    query = {}
  const result = useCubeQuery(query, {resetResultSetOnChange: true})
  if(isRefresh)
    setRefresh(false)
  return { item, result }
}

const LoyaltyPointTransactionQuery = (membershipCodeRealFilter, isRefresh, setRefresh) => {
  const { t } = useTranslation();
  let item = {
    name: t('page.fnb.userQuery.loyaltyPointTransaction.name'),
    tableHeaders: {
      "LoyaltyPointTransactions.id":  { name: t('page.fnb.userQuery.loyaltyPointTransaction.tableHeader.id'), type: "number" },
      "LoyaltyPointTransactions.createdAt":  { name: t('page.fnb.userQuery.loyaltyPointTransaction.tableHeader.createdAt'), type: "dateTime" },
      "LoyaltyPointTransactions.code":  { name: t('page.fnb.userQuery.loyaltyPointTransaction.tableHeader.code'), type: "string" },
      "LoyaltyPointTransactions.opening":  { name: t('page.fnb.userQuery.loyaltyPointTransaction.tableHeader.opening'), type: "number" },
      "LoyaltyPointTransactions.points":  { name: t('page.fnb.userQuery.loyaltyPointTransaction.tableHeader.points'), type: "number" },
      "LoyaltyPointTransactions.balance":  { name: t('page.fnb.userQuery.loyaltyPointTransaction.tableHeader.balance'), type: "number" },
      "LoyaltyPointTransactions.isDeleted":  { name: t('page.fnb.userQuery.loyaltyPointTransaction.tableHeader.isDeleted'), type: "boolean" },
      "LoyaltyPointTransactions.isActive":  { name: t('page.fnb.userQuery.loyaltyPointTransaction.tableHeader.isActive'), type: "boolean" },
      "LoyaltyPointTransactions.activeAt":  { name: t('page.fnb.userQuery.loyaltyPointTransaction.tableHeader.activeAt'), type: "dateTime" },
      "LoyaltyPointTransactions.expireAt":  { name: t('page.fnb.userQuery.loyaltyPointTransaction.tableHeader.expireAt'), type: "dateTime" },
      "LoyaltyPointTransactions.updatedAt":  { name: t('page.fnb.userQuery.loyaltyPointTransaction.tableHeader.updatedAt'), type: "dateTime" },
    },
    ordering: ["LoyaltyPointTransactions.id", "LoyaltyPointTransactions.createdAt", "LoyaltyPointTransactions.code", 
      "LoyaltyPointTransactions.opening", "LoyaltyPointTransactions.points", "LoyaltyPointTransactions.balance",
      "LoyaltyPointTransactions.isDeleted", "LoyaltyPointTransactions.isActive", "LoyaltyPointTransactions.activeAt",
      "LoyaltyPointTransactions.expireAt", "LoyaltyPointTransactions.updatedAt"],
    grid: {xs: 12, lg: 12},
    export: { allowExport: true, filename: membershipCodeRealFilter+'_loyalty_point_transaction_export_' + format(new Date(), 'yyyyMMddHHmmss') }
  }
  let query = {
    ...OmnitechQueries.users.LoyaltyPointTransaction.query
  }
  query = applyFilter(query, membershipCodeRealFilter, "Users.membershipCode")
  if(isRefresh)
    query = {}
  const result = useCubeQuery(query, {resetResultSetOnChange: true})
  if(isRefresh)
    setRefresh(false)
  return { item, result }
}

const UsersMostConsumeStoreQuery = (membershipCodeRealFilter, isRefresh, setRefresh) => {
  const { t } = useTranslation();
  let item = {
    name: t('page.fnb.userQuery.usersMostConsumeStore.name'),
    tableHeaders: {
      "UsersMostConsumeStore.shipFromStoreCode":  { name: t('page.fnb.userQuery.usersMostConsumeStore.tableHeader.storeCode'), type: "string" },
      "UsersMostConsumeStore.orderCount":  { name: t('page.fnb.userQuery.usersMostConsumeStore.tableHeader.count'), type: "number" },
    },
    grid: {xs: 12, lg: 12},
    export: { allowExport: true, filename: membershipCodeRealFilter+'_most_consume_store_export_' + format(new Date(), 'yyyyMMddHHmmss') }
  }
  let query = {
    ...OmnitechQueries.users.UsersMostConsumeStore.query
  }
  if(!isRefresh)
    query = applyFilter(query, membershipCodeRealFilter, "UsersMostConsumeStore.membershipCode")
  const result = useCubeQuery(query, {resetResultSetOnChange: true})
  if(isRefresh)
    setRefresh(false)
  return { item, result }
}

const UserExportPage = () => {
  const { t } = useTranslation();
  const [ membershipCodeFilter, setMembershipCodeFilter ] = useState('XXXXXX');
  const [ membershipCodeRealFilter, setMembershipCodeRealFilter ] = useState('XXXXXX');
  const [rowsPerPage1, setRowsPerPage1] = useState(10);
  const [page1, setPage1] = useState(0);
  const [rowsPerPage2, setRowsPerPage2] = useState(10);
  const [page2, setPage2] = useState(0);
  const [rowsPerPage3, setRowsPerPage3] = useState(10);
  const [page3, setPage3] = useState(0);
  const [rowsPerPage4, setRowsPerPage4] = useState(10);
  const [page4, setPage4] = useState(0);
  const [rowsPerPage5, setRowsPerPage5] = useState(10);
  const [page5, setPage5] = useState(0);

  const handleSubmit = (event) => {
    event.preventDefault();
    setMembershipCodeRealFilter(membershipCodeFilter)
  }

  const [ refresh1, setRefresh1 ] = useState(false);
  const [ refresh2, setRefresh2 ] = useState(false);
  const [ refresh3, setRefresh3 ] = useState(false);
  const [ refresh4, setRefresh4 ] = useState(false);
  const [ refresh5, setRefresh5 ] = useState(false);

  const query1 = UserExportQuery(membershipCodeRealFilter, refresh1, setRefresh1)
  const query2 = MembershipTransactionQuery(membershipCodeRealFilter, refresh2, setRefresh2)
  const query3 = OrderHistoryQuery(membershipCodeRealFilter, refresh3, setRefresh3)
  const query4 = LoyaltyPointTransactionQuery(membershipCodeRealFilter, refresh4, setRefresh4)
  const query5 = UsersMostConsumeStoreQuery(membershipCodeRealFilter, refresh5, setRefresh5)

  const refresh = () => {
    setRefresh1(true)
    setRefresh2(true)
    setRefresh3(true)
    setRefresh4(true)
    setRefresh5(true)
    setPage1(0)
    setPage2(0)
    setPage3(0)
    setPage4(0)
    setPage5(0)
  }

  return ([
    <Header />,
    <PageHeaderComponent onClickFunc={refresh} refreshlabel={t('component.refresh')} headerTitle={t('component.header.menu.userQuery')} />,
    <Dashboard>
      <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
        <TextFieldComponent id={"membership_code_filter"} state={membershipCodeFilter} pageChangerArr={[setPage1,setPage2,setPage3,setPage4,setPage5]}
          onChange={false} stateChanger={setMembershipCodeFilter} textLabel={t('page.fnb.userQuery.filter.membershipCode')}/>
      </Box>
    </Dashboard>
    ,
    <Dashboard>
      <TextComponent title={t('component.text.historical')} xs={12} lg={12}/>
      { membershipCodeRealFilter === 'XXXXXX' && <TextComponent title={t('page.fnb.userQuery.enterMembershipCode')} xs={12} lg={12}/>}
      { membershipCodeRealFilter !== 'XXXXXX' && <TableComponent item={query1.item} result={query1.result} rowsPerPage={rowsPerPage1} 
        setRowsPerPage={setRowsPerPage1} page={page1} setPage={setPage1}/> }
      { membershipCodeRealFilter !== 'XXXXXX' && <TableComponent item={query5.item} result={query5.result} rowsPerPage={rowsPerPage5} 
        setRowsPerPage={setRowsPerPage5} page={page5} setPage={setPage5}/> }
      { membershipCodeRealFilter !== 'XXXXXX' && <TableComponent item={query2.item} result={query2.result} rowsPerPage={rowsPerPage2} 
        setRowsPerPage={setRowsPerPage2} page={page2} setPage={setPage2}/> }
      { membershipCodeRealFilter !== 'XXXXXX' && <TableComponent item={query3.item} result={query3.result} rowsPerPage={rowsPerPage3} 
        setRowsPerPage={setRowsPerPage3} page={page3} setPage={setPage3}/> }
      { membershipCodeRealFilter !== 'XXXXXX' && <TableComponent item={query4.item} result={query4.result} rowsPerPage={rowsPerPage4} 
        setRowsPerPage={setRowsPerPage4} page={page4} setPage={setPage4}/> }
    </Dashboard>
  ]) 
};

export default UserExportPage;