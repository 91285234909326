const DeviceRegisterQueries = {
  RtDeviceRegister: {
    query: {
      "measures": [
        "Devices.lastDeviceCreatedAt",
        "Devices.count"
      ],
      "order": {
        "Devices.lastDeviceCreatedAt": "desc"
      },
      "filters": [
        {
          "member": "Users.roles",
          "operator": "equals",
          "values": [
            "consumer"
          ]
        },
        {
          "member": "Users.email",
          "operator": "notContains",
          "values": [
            "@example.com"
          ]
        },
        {
          "member": "CustomerRanks.code",
          "operator": "notEquals",
          "values": [
            "SC"
          ]
        }
      ],
      "dimensions": [
        "Users.membershipCode",
        "Users.firstName",
        "CustomerRanks.code",
        "Users.sex",
        "Users.email",
        "Users.phone",
        "Users.dateOfBirth",
        "Users.isActive",
        "Users.isDeleted",
        "Users.remark",
        "Users.createdAt",
        "Users.updatedAt",
        "Users.modifiedAt"
      ],
      "limit": 50000,
      "renewQuery": true,
    }
  }
}

export default DeviceRegisterQueries