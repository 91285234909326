const commonEnHK = {
  NewMemberPage: {
    "name": "New Member",
    "tableHeader": {
      "count": "Member Count",
      "cartsCreatedAt": "Join Date",
    },
    "filter": {
      "createdDate": {
        "startDate": "Start Date",
        "to": "to",
        "endDate": "End Date"
      }
    }
  },
  PMCampaignRatePage: {
    "name": "Push Message Campaign Read Rate",
    "tableHeader": {
      "pushMessageCampaignCode": "Push Message Campaign Code",
      "campaignStartDate": "Campaign Start Date",
      "scheduleHour": "Schedule Hour",
      "totalMessages": "Total Messages",
      "readCount": "Read Count",
      "readPercentage": "Read Percentage"
    },
  },
  MemberSpendingPage: {
    "memberNetSpending": {
      "name": "Member Average Net Spending",
      "tableHeader": {
        "member": "Member Average Net Spending",
        "nonMember": "Non-Member Average Net Spending",
        "ordersCompleteDate": "Order Complete Date"
      },
    },
    "memberSumNetSpending": {
      "name": "Member Sum Net Spending",
      "tableHeader": {
        "member": "Member Sum Net Spending",
        "nonMember": "Non-Member Sum Net Spending",
        "ordersCompleteDate": "Order Complete Date"
      },
    },
    "memberGrossSpending": {
      "name": "Member Average Gross Spending",
      "tableHeader": {
        "member": "Member Average Gross Spending",
        "nonMember": "Non-Member Average Gross Spending",
        "ordersCompleteDate": "Order Complete Date"
      },
    },
    "memberSumGrossSpending": {
      "name": "Member Sum Gross Spending",
      "tableHeader": {
        "member": "Member Sum Gross Spending",
        "nonMember": "Non-Member Sum Gross Spending",
        "ordersCompleteDate": "Order Complete Date"
      },
    },
    "memberDiscount": {
      "name": "Member Average Discount",
      "tableHeader": {
        "member": "Member Average Discount",
        "nonMember": "Non-Member Average Discount",
        "memberRatio": "Member Average Discount Ratio(Percentage)",
        "nonMemberRatio": "Non-Member Average Discount Ratio(Percentage)",
        "ordersCompleteDate": "Order Complete Date"
      },
    },
    "memberSumDiscount": {
      "name": "Member Sum Discount",
      "tableHeader": {
        "member": "Member Sum Discount",
        "nonMember": "Non-Member Sum Discount",
        "ordersCompleteDate": "Order Complete Date"
      },
    },
    "memberDiscountRatio": {
      "name": "Member Discount (Ratio)",
    },
    "memberOrder": {
      "name": "Member Average Order",
      "tableHeader": {
        "member": "Member Average Order",
        "nonMember": "Non-Member Average Order",
        "memberRatio": "Member Average Order Ratio(Percentage)",
        "nonMemberRatio": "Non-Member Average Order Ratio(Percentage)",
        "ordersCompleteDate": "Order Complete Date"
      },
    },
    "memberOrderRatio": {
      "name": "Member Order (Ratio)",
    },
    "filter": {
      "ordersCompleteDate": {
        "startDate": "Start Date",
        "to": "to",
        "endDate": "End Date"
      }
    }
  },
  ActiveMemberPage: {
    "name": "Active Member",
    "titleMapping": {
      "activePoint": "Active Member By Point",
      "activeLogin": "Active Member By Login",
      "activeOrder": "Active Member By Order",
    },
    "filter": {
      "start": {
        "date": "Date"
      },
      "dayAgo": "Day Ago"
    }
  },
  MemberStorePage: {
    "name": "Members Spend Store",
    "countName": "Members/Non-Member Spend Store Count",
    "ratioName": "Members/Non-Member Spend Store Ratio",
    "tableHeader": {
      "ordersCompleteDate": "Order Complete Date",
      "member": "Member Average Count",
      "nonMember": "Non-Member Average Count",
      "memberRatio": "Member Average Ratio(Percentage)",
      "nonMemberRatio": "Non-Member Average Ratio(Percentage)",
    },
    "filter": {
      "ordersCompleteDate": {
        "startDate": "Start Date",
        "to": "to",
        "endDate": "End Date"
      },
      "store": {
        "all": "ALL",
        "name": "Store Code"
      },
    }
  },
  couponUsagePage: {
    "publicCouponUsage": {
      "name": "Public Coupon Usage",
      "tableHeader": {
        "code": "Coupon Code",
        "used": "Used",
      },
    },
    "memberCouponUsage": {
      "name": "Member Coupon Usage",
      "tableHeader": {
        "code": "Coupon Code",
        "used": "Used",
        "redeemed": "Redeemed",
        "usedRatio": "Used Ratio(Percentage)",
      },
    },
  },
  "membershipPurchaseExporterPage": {
    "name": "Membership Purchase Exporter",
    "tableHeader": {
      "shipFromStoreCode": "Store Code",
      "lineTotal": "Line Total",
      "quantity": "Quantity",
      "membershipCode": "Membersip Code",
      "userName": "Customer Name",
      "firstName": "Staff Name",
      "phone": "Phone",
      "ordersCompletedAt": "Order Completed At",
      "email": "Email",
      "referenceNumber": "Order Reference Number",
      "staffNumber": "Staff Number",
      "commerceChannelCode": "Commerce Channel Code",
      "skuCode": "SKU Code",
      "skuInternalUseTitle": "SKU Internal Use Title",
      "productCode": "Product Code",
      "productTitleEnHk": "Product Title (English)",
      "productTitleZhHk": "Product Title (Chinese)",
      "unitAmount": "Unit Amount",
    },
    "titleMapping":{
      "quantity": "Quantity",
      "membershipSales": "Membership Sales"
    },
    "filter": {
      "store": {
        "all": "ALL",
        "name": "Store Code"
      },
      "channel":{
        "name": "Channel"
      },
      "orderCompletedAt": {
        "startDate": "Order Completed At From Date",
        "to": "to",
        "endDate": "Order Completed At End Date"
      },
      "membershipCode": "Membership Code",
      "email": "Email",
      "phone": "Phone",
      "userName": "Customer Name",
      "skuCode": "SKU Code",
      "skuTitle": "SKU Title"
    }
  },
  couponUsageByDateRangePage: {
    "name": "Coupon Usage By Date Range",
    "tableHeader": {
      "code": "Coupon Code",
      "used": "Used",
    },
    "filter": {
      "createdAt": {
        "startDate": "Used At From Date",
        "to": "to",
        "endDate": "Used At End Date"
      },
    }
  },
  repeatedMembers:{
    "name": "Repeated Members",
    "tableHeader": {
      "shipFromStoreCode": "Store Code",
      "membershipCode": "Membership Code",
      "firstName": "First Name",
      "lastName": "Last Name",
      "phone": "Phone",
      "email": "Email",
      "sex": "Sex",
      "rankCode": "Rank Code",
      "count": "Order Total Count",
      "sumTotalPrice": "Total Spending Amount",
    },
    "filter": {
      "orderCompletedAt": {
        "startDate": "Completed At From Date",
        "to": "to",
        "endDate": "Completed At End Date"
      },
      "membershipCode": "Membership Code",
      "storeCode": "Store Code",
    },
  },
}

export default commonEnHK