import React from "react";
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import ButtonComponent from './ButtonComponent';
import RefreshIcon from '@material-ui/icons/Refresh';

let PageHeaderComponent = ({onClickFunc=null, headerTitle= "", refreshlabel=""}) => {
  return(
    <Grid
      container
      spacing={{ xs: 2, md: 3 }} 
      style={{
        padding: 30,
      }}
      justify="space-around"
      alignItems="flex-start"
    >
      <Grid item xs={8} sm={9} md={10} lg={10}>
        <Typography variant="h6">{headerTitle}</Typography>
      </Grid>
      <Grid item xs={4} sm={3} md={2} lg={2}>
        <ButtonComponent onClickFunc={onClickFunc} label={refreshlabel} startIcon={<RefreshIcon />} toRight={true}/>
      </Grid>
    </Grid>
  )
}

export default PageHeaderComponent;