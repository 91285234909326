const SalesQueries = {
  FoodBeverageSalesPercentage: {
    query: {
      "measures": [
        "MvOrderTransactions.foodPercentage",
        "MvOrderTransactions.beveragePercentage",
        "MvOrderTransactions.otherPercentage"
      ],
      "order": {
      },
      "timeDimensions": [],
      "filters": [
        {
          "member": "MvOrderTransactions.code",
          "operator": "equals",
          "values": [
            "sku_unit_sell_price",
            "property_unit_sell_price",
          ]
        },
        {
          "member": "MvOrderTransactions.commerceChannelCode",
          "operator": "notEquals",
          "values": [
            "membership_upgrade"
          ]
        },
        {
          "member": "MvOrderTransactions.orderState",
          "operator": "equals",
          "values": [
            "completed"
          ]
        },
      ],
      "dimensions": [
      ],
      "limit": 50000,
      "renewQuery": true,
    }
  },
  TotalBeverageGrossSales: {
    query: {
      "measures": [
        "MvOrderTransactions.sumLineTotal"
      ],
      "order": {
      },
      "timeDimensions": [],
      "filters": [
        {
          "member": "MvOrderTransactions.code",
          "operator": "equals",
          "values": [
            "sku_unit_sell_price",
            "property_unit_sell_price",
          ]
        },
        {
          "member": "MvOrderTransactions.commerceChannelCode",
          "operator": "notEquals",
          "values": [
            "membership_upgrade"
          ]
        },
        {
          "member": "ViewReportCategoryProductsFlatten.catType",
          "operator": "equals",
          "values": [
            "drinks"
          ]
        },
        {
          "member": "ViewReportCategoryProductsFlatten.catType",
          "operator": "set",
        },
        {
          "member": "MvOrderTransactions.orderState",
          "operator": "equals",
          "values": [
            "completed"
          ]
        },
      ],
      "dimensions": [
      ],
      "limit": 50000,
      "renewQuery": true,
    }
  },
  TotalFoodGrossSales: {
   query: {
      "measures": [
        "MvOrderTransactions.sumLineTotal"
      ],
      "order": {
      },
      "timeDimensions": [],
      "filters": [
        {
          "member": "MvOrderTransactions.code",
          "operator": "equals",
          "values": [
            "sku_unit_sell_price",
            "property_unit_sell_price",
          ]
        },
        {
          "member": "MvOrderTransactions.commerceChannelCode",
          "operator": "notEquals",
          "values": [
            "membership_upgrade"
          ]
        },
        {
          "member": "ViewReportCategoryProductsFlatten.catType",
          "operator": "notEquals",
          "values": [
            "drinks",
            "membership"
          ]
        },
        {
          "member": "ViewReportCategoryProductsFlatten.catType",
          "operator": "set",
        },
        {
          "member": "MvOrderTransactions.orderState",
          "operator": "equals",
          "values": [
            "completed"
          ]
        },
      ],
      "dimensions": [
      ],
      "limit": 50000,
      "renewQuery": true,
    }
  },
  TotalOtherGrossSales: {
   query: {
      "measures": [
        "MvOrderTransactions.sumLineTotal"
      ],
      "order": {
      },
      "timeDimensions": [],
      "filters": [
        {
          "member": "MvOrderTransactions.code",
          "operator": "equals",
          "values": [
            "sku_unit_sell_price",
            "property_unit_sell_price",
          ]
        },
        {
          "member": "MvOrderTransactions.commerceChannelCode",
          "operator": "notEquals",
          "values": [
            "membership_upgrade"
          ]
        },
        {
          "member": "ViewReportCategoryProductsFlatten.catType",
          "operator": "notSet",
        },
        {
          "member": "MvOrderTransactions.orderState",
          "operator": "equals",
          "values": [
            "completed"
          ]
        },
      ],
      "dimensions": [
      ],
      "limit": 50000,
      "renewQuery": true,
    }
  },
  TotalFoodGrossSalesDetail: {
   query: {
      "measures": [
        "MvOrderTransactions.sumLineTotal"
      ],
      "order": {
        "MvOrderTransactions.shipFromStoreCode": "asc",
        "MvOrderTransactions.commerceChannelCode": "asc",
      },
      "filters": [
        {
          "member": "MvOrderTransactions.code",
          "operator": "equals",
          "values": [
            "sku_unit_sell_price",
            "property_unit_sell_price",
          ]
        },
        {
          "member": "MvOrderTransactions.commerceChannelCode",
          "operator": "notEquals",
          "values": [
            "membership_upgrade"
          ]
        },
        {
          "member": "ViewReportCategoryProductsFlatten.catType",
          "operator": "notEquals",
          "values": [
            "drinks",
            "membership"
          ]
        },
        {
          "member": "MvOrderTransactions.orderState",
          "operator": "equals",
          "values": [
            "completed"
          ]
        },
      ],
      "dimensions": [
        "MvOrderTransactions.shipFromStoreCode",
        "MvOrderTransactions.commerceChannelCode",
        "Orders.orderType"
      ],
      "limit": 50000,
      "renewQuery": true,
    }
  },
  RtBeveragesSales: {
   query: {
      "measures": [
        "MvOrderTransactions.sumLineTotal",
        "MvOrderTransactions.sumQuantity"
      ],
      "order": {
      },
      "filters": [
        {
          "member": "ViewReportCategoryProductsFlatten.code",
          "operator": "equals",
          "values": [
            "red_wine",
            "refill_drinks",
            "soft_beverage",
            "tea_free_drink",
            "white_wine",
            "al_meta",
            "alcohlic",
            "bar_beverage",
            "beer",
            "cafe",
            "coffee_tea",
            "daily_special",
            "fruit_juice",
            "kids_menu_drinks_upgrade",
            "lunch_addon_drinks",
            "lunch_free_drinks",
            "lunch_refill_drinks",
            "non_alcohlic"
          ]
        },
        {
          "member": "MvOrderTransactions.orderState",
          "operator": "equals",
          "values": [
            "completed"
          ]
        },
      ],
      "dimensions": [
        "ViewReportCategoryProductsFlatten.code",
      ],
      "limit": 50000,
      "renewQuery": true,
    }
  },
  SKUSalesDetails: {
   query: {
      "measures": [
        "MvOrderTransactions.sumLineTotal",
        "MvOrderTransactions.sumQuantity"
      ],
      "order": {
        "MvOrderTransactions.skuCode": "asc",
      },
      "filters": [
        {
          "member": "MvOrderTransactions.code",
          "operator": "equals",
          "values": [
            "sku_unit_sell_price",
            "property_unit_sell_price",
            "shipping_fee"
          ]
        },
        {
          "member": "MvOrderTransactions.commerceChannelCode",
          "operator": "notEquals",
          "values": [
            "membership_upgrade"
          ]
        },
        {
          "member": "MvOrderTransactions.orderState",
          "operator": "equals",
          "values": [
            "completed"
          ]
        },
      ],
      "dimensions": [
        "MvOrderTransactions.shipFromStoreCode",
        "MvOrderTransactions.skuCode",
        "MvOrderTransactions.skuInternalUseTitle",
        "MvOrderTransactions.skuCost",
        "MvOrderTransactions.productTitleEnHk",
        "MvOrderTransactions.productTitleZhHk",
        "ViewReportCategoryProductsFlatten.code",
      ],
      "limit": 50000,
      "renewQuery": true,
    }
  },
  SKUSalesDetailsCHE: {
   query: {
      "measures": [
      ],
      "order": {
        "SkuSalesDetailCHE.skuCode": "asc",
      },
      "filters": [
      ],
      "dimensions": [
        "SkuSalesDetailCHE.skuCode",
        "SkuSalesDetailCHE.upcCode",
        "SkuSalesDetailCHE.internalUseTitle",
        "SkuSalesDetailCHE.productCode",
        "SkuSalesDetailCHE.productTitleEnHk",
        "SkuSalesDetailCHE.productTitleZhHk",
        "SkuSalesDetailCHE.productStatus",
        "SkuSalesDetailCHE.skuCost",
        "SkuSalesDetailCHE.currentPrice",
        "SkuSalesDetailCHE.skuMeasurementUnit",
        "SkuSalesDetailCHE.propertyAc",
        "SkuSalesDetailCHE.commerceChannelCodes",
        "SkuSalesDetailCHE.subcatCategoryCode",
        "SkuSalesDetailCHE.mainCategoryCode",
        "SkuSalesDetailCHE.rsmrCode",
        "SkuSalesDetailCHE.rsmCode",
        "SkuSalesDetailCHE.quantity",
        "SkuSalesDetailCHE.skuMeasure",
        "SkuSalesDetailCHE.skuMeasurementCode",
        "SkuSalesDetailCHE.lineTotal",
      ],
      "limit": 50000,
      "renewQuery": true,
    }
  },
  SKUSalesByDateDetails: {
   query: {
      "measures": [
        "MvOrderTransactions.sumLineTotal",
        "MvOrderTransactions.sumQuantity"
      ],
      "order": {},
      "filters": [
        {
          "member": "MvOrderTransactions.code",
          "operator": "equals",
          "values": [
            "sku_unit_sell_price",
            "property_unit_sell_price",
            "shipping_fee"
          ]
        },
        {
          "member": "MvOrderTransactions.commerceChannelCode",
          "operator": "notEquals",
          "values": [
            "membership_upgrade"
          ]
        },
        {
          "member": "MvOrderTransactions.orderState",
          "operator": "equals",
          "values": [
            "completed"
          ]
        },
      ],
      "limit": 50000,
      "renewQuery": true,
    }
  },
  HighlightCategorySales: {
   query: {
      "measures": [
        "MvOrderTransactions.sumLineTotal",
        "MvOrderTransactions.sumQuantity"
      ],
      "order": {
        "MvOrderTransactions.skuCode": "asc",
      },
      "filters": [
        {
          "member": "MvOrderTransactions.code",
          "operator": "equals",
          "values": [
            "sku_unit_sell_price",
            "property_unit_sell_price",
            "shipping_fee"
          ]
        },
        {
          "member": "MvOrderTransactions.commerceChannelCode",
          "operator": "notEquals",
          "values": [
            "membership_upgrade"
          ]
        },
        {
          "member": "MvOrderTransactions.orderState",
          "operator": "equals",
          "values": [
            "completed"
          ]
        },
        {
          "member": "Categories.code",
          "operator": "equals",
          "values": [
            "lunch_add-on", "join_membership", "membership_extension",
            "premium_steakhouse", "spc", "xmas_menu", "xmas_set"
          ]
        },
      ],
      "dimensions": [
        "MvOrderTransactions.shipFromStoreCode",
        "MvOrderTransactions.skuCode",
        "MvOrderTransactions.skuInternalUseTitle",
        "MvOrderTransactions.productTitleEnHk",
        "MvOrderTransactions.productTitleZhHk",
        "Categories.code",
        "ViewReportCategoryProductsFlatten.code",
      ],
      "limit": 50000,
      "renewQuery": true,
    }
  },
  MembershipSalesByType: {
   query: {
      "measures": [
        "MvOrderTransactions.sumLineTotal",
        "MvOrderTransactions.sumQuantity"
      ],
      "order": {
      },
      "filters": [
        {
          "member": "MvOrderTransactions.orderState",
          "operator": "equals",
          "values": [
            "completed"
          ]
        },
         {
          "member": "MvOrderTransactions.commerceChannelCode",
          "operator": "notEquals",
          "values": [
            "membership_upgrade"
          ]
        },
        {
          "member": "MvOrderTransactions.categoryCode",
          "operator": "contains",
          "values": [
            "membership"
          ]
        },
      ],
      "dimensions": [
        "MvOrderTransactions.shipFromStoreCode",
        "MvOrderTransactions.skuCode",
        "MvOrderTransactions.skuInternalUseTitle",
      ],
      "limit": 50000,
      "renewQuery": true,
    }
  },
  MembershipSalesDetails: {
   query: {
      "measures": [
      ],
      "order": {
      },
      "filters": [
        {
          "member": "MvOrderTransactions.orderState",
          "operator": "equals",
          "values": [
            "completed"
          ]
        },
         {
          "member": "MvOrderTransactions.commerceChannelCode",
          "operator": "notEquals",
          "values": [
            "membership_upgrade"
          ]
        },
        {
          "member": "MvOrderTransactions.categoryCode",
          "operator": "contains",
          "values": [
            "membership"
          ]
        },
      ],
      "dimensions": [
        "MvOrderTransactions.shipFromStoreCode",
        "MvOrderTransactions.skuCode",
        "MvOrderTransactions.skuInternalUseTitle",
        "MvOrderTransactions.staffNumber",
        "MvOrderTransactions.firstName",
        "MvOrderTransactions.lastName",
        "MvOrderTransactions.ordersCompletedAt",
        "MvOrderTransactions.unitAmount",
        "MvOrderTransactions.quantity",
        "MvOrderTransactions.lineTotal",
      ],
      "limit": 50000,
      "renewQuery": true,
    }
  },
  MembershipTenderSummaries: {
   query: {
      "measures": [
        "MvOrderTransactions.sumLineTotal",
        "MvOrderTransactions.sumQuantity"
      ],
      "order": {
      },
      "filters": [
        {
          "member": "MvOrderTransactions.orderState",
          "operator": "equals",
          "values": [
            "completed"
          ]
        },
        {
          "member": "MvPaymentRequests.state",
          "operator": "equals",
          "values": [
            "completed", "authorized"
          ]
        },
        {
          "member": "MvOrderTransactions.commerceChannelCode",
          "operator": "notEquals",
          "values": [
            "membership_upgrade"
          ]
        },
        {
          "member": "MvOrderTransactions.categoryCode",
          "operator": "contains",
          "values": [
            "membership"
          ]
        },
      ],
      "dimensions": [
        "MvOrderTransactions.shipFromStoreCode",
        "MvPaymentRequests.paymentProviderCode",
      ],
      "limit": 50000,
      "renewQuery": true,
    }
  },
  TotalBeverageGrossSalesByStorePerDayHist: {
    query: {
      "measures": [
        "MvOrderTransactions.sumLineTotal"
      ],
      "order": {
        "MvOrderTransactions.shipFromStoreCode": "asc"
      },
      "timeDimensions": [],
      "filters": [
        {
          "member": "MvOrderTransactions.code",
          "operator": "equals",
          "values": [
            "sku_unit_sell_price",
            "property_unit_sell_price",
          ]
        },
        {
          "member": "MvOrderTransactions.commerceChannelCode",
          "operator": "notEquals",
          "values": [
            "membership_upgrade"
          ]
        },
        {
          "member": "ViewReportCategoryProductsFlatten.catType",
          "operator": "equals",
          "values": [
            "drinks"
          ]
        },
        {
          "member": "MvOrderTransactions.orderState",
          "operator": "equals",
          "values": [
            "completed"
          ]
        },
      ],
      "dimensions": [
        "MvOrderTransactions.shipFromStoreCode"
      ],
      "limit": 50000,
      "renewQuery": true,
    }
  },
}

export default SalesQueries