import React, { useState, useContext } from "react";
import { CubeJSContext } from '../../context/CubeJSProvider';
import Header from '../../components/Header';
import TableComponent from '../../components/TableComponent';
import MultiSelectComponent from '../../components/MultiSelectComponent';
import DateRangePickerComponent from '../../components/DateRangePickerComponent';
import Dashboard from '../../components/Dashboard';
import PageHeaderComponent from '../../components/PageHeaderComponent';
import OmnitechQueries from '../../queries/OmnitechQueries'
import { useTranslation } from 'react-i18next';
import { useCubeQuery } from '@cubejs-client/react';
import { format } from 'date-fns'

const applyFilter = (query, storeCodeFilter) => {

  let storeCodeFilterObj = {
    member: "RepeatedMembers.shipFromStoreCode",
    operator: "contains",
    values: storeCodeFilter
  }
  query = {
    ...query,
    filters: (query.filters || []).concat(storeCodeFilterObj)
  }

  return query
}

const RepeatedMembersQuery = (storeCodeFilter, startDateFilter, endDateFilter, isRefresh, setRefresh) => {
  const { t } = useTranslation();
  let item = {
    name: t('page.membership.repeatedMembers.name'),
    tableHeaders: {
      "RepeatedMembers.shipFromStoreCode":  { name: t('page.membership.repeatedMembers.tableHeader.shipFromStoreCode'), type: "string" },
      "RepeatedMembers.membershipCode":  { name: t('page.membership.repeatedMembers.tableHeader.membershipCode'), type: "string" },
      "RepeatedMembers.firstName":  { name: t('page.membership.repeatedMembers.tableHeader.firstName'), type: "string" },
      "RepeatedMembers.lastName":  { name: t('page.membership.repeatedMembers.tableHeader.lastName'), type: "string" },
      "RepeatedMembers.phone":  { name: t('page.membership.repeatedMembers.tableHeader.phone'), type: "string" },
      "RepeatedMembers.email":  { name: t('page.membership.repeatedMembers.tableHeader.email'), type: "string" },
      "RepeatedMembers.sex":  { name: t('page.membership.repeatedMembers.tableHeader.sex'), type: "string" },
      "RepeatedMembers.rankCode":  { name: t('page.membership.repeatedMembers.tableHeader.rankCode'), type: "string" },
      "RepeatedMembers.count":  { name: t('page.membership.repeatedMembers.tableHeader.count'), type: "number" },
      "RepeatedMembers.sumTotalPrice":  { name: t('page.membership.repeatedMembers.tableHeader.sumTotalPrice'), type: "number" },
    },
    grid: {xs: 12, lg: 12},
    export: { allowExport: true, filename: 'repeated_member_' + format(new Date(), 'yyyyMMddHHmmss') }
  }
  let query = {
    ...OmnitechQueries.orders.RepeatedMembers.query,
    "timeDimensions": OmnitechQueries.orders.RepeatedMembers.query.timeDimensions.concat(
      {
        "dimension": "RepeatedMembers.orderCompletedAt",
        "dateRange": [ startDateFilter, endDateFilter ]
      })
  }
  query = applyFilter(query, storeCodeFilter)
  if(isRefresh)
    query = {}
  const result = useCubeQuery(query, {resetResultSetOnChange: true})
  if(isRefresh)
    setRefresh(false)
  return { item, result }
}

const RepeatedMembersPage = () => {
  const { t } = useTranslation();
  const context = useContext(CubeJSContext);
  let storeCodeSelect = []
  if(context.storeCodes)
    storeCodeSelect = context.storeCodes
  const [ storeCodeFilter, setStoreCodeFilter ] = useState([]);
  let filterStartDate = new Date()
  filterStartDate.setHours(0,0,0,0);
  let filterEndDate = new Date()
  filterEndDate.setHours(23,59,59,999);
  const [dateRangeFilter, setDateRangeFilter] = useState([filterStartDate, filterEndDate])
  const [startDateFilter, endDateFilter] = dateRangeFilter
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  const [ refresh1, setRefresh1 ] = useState(false);

  const { item, result }  = RepeatedMembersQuery(storeCodeFilter, startDateFilter, endDateFilter, refresh1, setRefresh1)

  const refresh = () => {
    setRefresh1(true)
    setPage(0)
  }

  return ([
    <Header />,
    <PageHeaderComponent onClickFunc={refresh} refreshlabel={t('component.refresh')} headerTitle={t('component.header.menu.repeatedMembers')} />,
    <Dashboard>
      <DateRangePickerComponent state={dateRangeFilter} stateChanger={setDateRangeFilter} 
      pageChangerArr={[setPage]}
      startDateLabel={t('page.membership.repeatedMembers.filter.orderCompletedAt.startDate')} 
      toLabel={t('page.membership.repeatedMembers.filter.orderCompletedAt.to')} 
      endDateLabel={t('page.membership.repeatedMembers.filter.orderCompletedAt.endDate')}/>
      <MultiSelectComponent id={"store_code_filter"} state={storeCodeFilter} pageChangerArr={[setPage]}
        stateChanger={setStoreCodeFilter} textLabel={t('page.membership.repeatedMembers.filter.storeCode')} selectArray={storeCodeSelect}/>
    </Dashboard>,
    <Dashboard>
      <TableComponent item={item} result={result} rowsPerPage={rowsPerPage} 
        setRowsPerPage={setRowsPerPage} page={page} setPage={setPage}/>
    </Dashboard>
  ])
};

export default RepeatedMembersPage;