import React from 'react';
import { useTranslation } from 'react-i18next';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { format } from 'date-fns'
import DashboardItem from './DashboardItem';
import ItemLoading from './ItemLoading';

const theme = createTheme({
  typography: {
    body1: {
      fontSize: 12,
      fontWeight: 700,
    },
    body2: {
      fontSize: 11,
      fontWeight: 600,
    },
  },
});

const MyNumberComponent = ({ resultSet, isLoading, error, progress, xs, sm, md, lg, height, titleName, fieldName, percentage}) => {
  let value = 0;
  if(resultSet){
    value = resultSet.totalRow()[fieldName]
  }

  const { t } = useTranslation();

  return (
    <Grid item xs={xs} sm={sm} md={md} lg={lg} key={fieldName}>
      <DashboardItem title={titleName}>
        {error && <div>{error.toString()}</div>}
        {isLoading && <div><center><ItemLoading progress={progress} /><Grid style={{padding: 10}}/></center></div>}
        {isLoading && <center><CircularProgress /></center>}
        {resultSet &&
          <Typography variant="h4" style={{ textAlign: 'center' }}>
            { percentage ? (value * 100).toFixed(2).toString()+'%'  : (Math.round(value * 100) / 100).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") || '0'}
          </Typography>
        }
        {
          resultSet && resultSet.loadResponse.slowQuery && (new Date().getTime() / 1000 - new Date(resultSet.loadResponse.results[0].lastRefreshTime).getTime() / 1000 > 300) &&
              <ThemeProvider theme={theme}><Typography style={{"marginTop": 10}} variant="body2" color="red" align="right" gutterBottom>{t('component.lastUpdatedAt')+": "+ format(new Date(resultSet.loadResponse.results[0].lastRefreshTime), 'yyyy/MM/dd HH:mm:ss')}</Typography></ThemeProvider>
        }
        {
          resultSet && (!resultSet.loadResponse.slowQuery || (resultSet.loadResponse.slowQuery && (new Date().getTime() / 1000 - new Date(resultSet.loadResponse.results[0].lastRefreshTime).getTime() / 1000 <= 300))) && 
          <ThemeProvider theme={theme}><Typography style={{"marginTop": 10}} variant="body2" color="textSecondary" align="right" gutterBottom>{t('component.lastUpdatedAt')+": "+ format(new Date(resultSet.loadResponse.results[0].lastRefreshTime), 'yyyy/MM/dd HH:mm:ss')}</Typography></ThemeProvider>
        }
      </DashboardItem>
    </Grid>
  )
}

const NumberComponent = ({ item, query, titleName, fieldName, percentage }) => {
  const grid = item.grid || {}
  const { xs = 12, sm, md, lg } = grid
  percentage = percentage ? percentage : false
  return <MyNumberComponent {...query} xs={xs} sm={sm} md={md} lg={lg} titleName={titleName} 
  fieldName={fieldName} percentage={percentage}/>
};

export default NumberComponent;