import React from 'react';
import { useTranslation } from 'react-i18next';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { format } from 'date-fns'
import DashboardItem from './DashboardItem';
import ItemLoading from './ItemLoading';

const theme = createTheme({
  typography: {
    body1: {
      fontSize: 12,
      fontWeight: 700,
    },
    body2: {
      fontSize: 11,
      fontWeight: 600,
    },
  },
});

const getLastRefreshTime = (resultSets) => {
  let lastRefreshTime = new Date(resultSets[0].resultSet.loadResponse.results[0].lastRefreshTime)
  for(let i=1; i<resultSets.length; i++){
    if(new Date(resultSets[i].resultSet.loadResponse.results[0].lastRefreshTime) > lastRefreshTime.getTime()){
      lastRefreshTime = new Date(resultSets[i].resultSet.loadResponse.results[0].lastRefreshTime)
    }
  }
  return lastRefreshTime
}

const isSlowQuery = (resultSets) => {
  for(let i = 0; i<resultSets.length; i++){
    if(resultSets[i].resultSet.loadResponse.slowQuery)
      return true
  }
  return false
}

const isresultSets = (resultSets) => {
  for(let i = 0; i<resultSets.length; i++){
    if(!resultSets[i].resultSet)
      return false
  }
  return true
}

const isLoading = (resultSets) => {
  for(let i = 0; i<resultSets.length; i++){
    if(resultSets[i].isLoading)
      return true
  }
  return false
}

const isError = (resultSets) => {
  for(let i = 0; i<resultSets.length; i++){
    if(resultSets[i].error)
      return resultSets[i].error
  }
  return null
}

const getProcess = (resultSets) => {
  for(let i = 0; i<resultSets.length; i++){
    if(resultSets[i].isLoading)
      return resultSets[i].progress
  }
  return null
}

const MyMultiNumberComponent = ({ resultSets, xs, sm, md, lg, height, titleName, fieldNames, percentage, operators}) => {
  let value = 0;
  if(isresultSets(resultSets)){
    // TODO: enchance operators to support different calculation
    value = resultSets[0].resultSet.totalRow()[fieldNames[0]]
    for(let i = 1; i<resultSets.length; i++){
      switch(operators[i-1]){
        case '-':
          value = value - resultSets[i].resultSet.totalRow()[fieldNames[i]]
          break;
        case '+':
          value = value + resultSets[i].resultSet.totalRow()[fieldNames[i]]
          break;
        case '*':
          value = value * resultSets[i].resultSet.totalRow()[fieldNames[i]]
          break;
        case '/':
          value = value / resultSets[i].resultSet.totalRow()[fieldNames[i]]
          break;
        default:
          value = value + resultSets[i].resultSet.totalRow()[fieldNames[i]]
      }
    }
  }

  const { t } = useTranslation();

  return (
    <Grid item xs={xs} sm={sm} md={md} lg={lg} key={titleName}>
      <DashboardItem title={titleName}>
        {isError(resultSets) && <div>{isError(resultSets).toString()}</div>}
        {isLoading(resultSets) && <div><center><ItemLoading progress={getProcess(resultSets)} /><Grid style={{padding: 10}}/></center></div>}
        {isLoading(resultSets) && <center><CircularProgress /></center>}
        {isresultSets(resultSets) &&
          <Typography variant="h4" style={{ textAlign: 'center' }}>
            { percentage ? (value * 100).toFixed(2).toString()+'%'  : (Math.round(value * 100) / 100).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") || '0'}
          </Typography>
        }
        {
          isresultSets(resultSets) && isSlowQuery(resultSets) && (new Date().getTime() / 1000 - getLastRefreshTime(resultSets).getTime() / 1000 > 300) &&
              <ThemeProvider theme={theme}><Typography style={{"marginTop": 10}} variant="body2" color="red" align="right" gutterBottom>{t('component.lastUpdatedAt')+": "+ format(getLastRefreshTime(resultSets), 'yyyy/MM/dd HH:mm:ss')}</Typography></ThemeProvider>
        }
        {
          isresultSets(resultSets) && (!isSlowQuery(resultSets) || (isSlowQuery(resultSets) && (new Date().getTime() / 1000 - getLastRefreshTime(resultSets).getTime() / 1000 <= 300))) && 
          <ThemeProvider theme={theme}><Typography style={{"marginTop": 10}} variant="body2" color="textSecondary" align="right" gutterBottom>{t('component.lastUpdatedAt')+": "+ format(getLastRefreshTime(resultSets), 'yyyy/MM/dd HH:mm:ss')}</Typography></ThemeProvider>
        }
      </DashboardItem>
    </Grid>
  )
}

const MultiNumberComponent = ({ item, queries, titleName, fieldNames, percentage, operators }) => {
  const grid = item.grid || {}
  const { xs = 12, sm, md, lg } = grid
  percentage = percentage ? percentage : false
  return <MyMultiNumberComponent resultSets={queries} xs={xs} sm={sm} md={md} lg={lg} titleName={titleName} 
  fieldNames={fieldNames} percentage={percentage} operators={operators}/>
};

export default MultiNumberComponent;