const VoidItemDetailQueries = {
  VoidItemDetail:{
    query: {
      "measures": [
        "VoidedCartLineProperties.sumQuantity"
      ],
      "timeDimensions": [
      ],
      "order": {
        "VoidedCartLineProperties.skuCode": "asc"
      },
      "filters": [
        {
          "member": "VoidedCartLineProperties.softDelete",
          "operator": "equals",
          "values": [
            "true"
          ]
        }
      ],
      "dimensions": [
        "VoidedCartLineProperties.storeCode",
        "VoidedCartLineProperties.skuCode",
        "VoidedCartLineProperties.skuInternalUseTitle",
        "VoidedCartLineProperties.productCode",
        "VoidedCartLineProperties.productType",
        "ProductTranslations.title"
      ],
      "limit": 50000,
      "renewQuery": true,
    }
  }
}

export default VoidItemDetailQueries