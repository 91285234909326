const DayEndQueries = {
  OrderAverageSummaryHist:{
    query: {
      "measures": [
        "MvOrdersSummaryEM.count",
        "MvOrdersSummaryEM.sumDineInNumberOfPeople",
        "MvOrdersSummaryEM.avgBillAmount",
        "MvOrdersSummaryEM.perPersonSpending"
      ],
      "timeDimensions": [
      ],
      "order": {
      },
      "dimensions": [
      ],
      "filters": [
        {
          "member": "MvOrdersSummaryEM.state",
          "operator": "equals",
          "values": [
            "completed"
          ]
        },
        {
          "member": "MvOrdersSummaryEM.commerceChannelCode",
          "operator": "notEquals",
          "values": [
            "membership_upgrade", "membership_upgrade_pos"
          ]
        },
        {
          "member": "MvOrdersSummaryEM.orderSource",
          "operator": "notEquals",
          "values": [
            "membership_upgrade"
          ]
        }
      ],
      "limit": 50000,
      "renewQuery": true,
    }
  },
  NetSalesByStoreEMHist:{
    query: {
      "measures": [
        "MvOrdersSummaryEM.count",
        "MvOrdersSummaryEM.sumNetSales"
      ],
      "timeDimensions": [
      ],
      "order": {
        "MvOrdersSummaryEM.shipFromStoreCode": "asc",
        "MvOrdersSummaryEM.orderSource": "asc"
      },
      "dimensions": [
        "MvOrdersSummaryEM.shipFromStoreCode",
        "MvOrdersSummaryEM.orderSource"
      ],
      "filters": [
        {
          "member": "MvOrdersSummaryEM.state",
          "operator": "equals",
          "values": [
            "completed"
          ]
        },
        {
          "member": "MvOrdersSummaryEM.commerceChannelCode",
          "operator": "notEquals",
          "values": [
            "membership_upgrade", "membership_upgrade_pos"
          ]
        },
        {
          "member": "MvOrdersSummaryEM.orderSource",
          "operator": "notEquals",
          "values": [
            "membership_upgrade"
          ]
        }
      ],
      "limit": 50000,
      "renewQuery": true,
    }
  },
  NetSalesByStorePerDayEMHist:{
    query: {
      "measures": [
        "MvOrdersSummaryEM.sumNetSales"
      ],
      "timeDimensions": [
      ],
      "order": {
        "MvOrdersSummaryEM.shipFromStoreCode": "asc",
      },
      "dimensions": [
        "MvOrdersSummaryEM.shipFromStoreCode",
      ],
      "filters": [
        {
          "member": "MvOrdersSummaryEM.state",
          "operator": "equals",
          "values": [
            "completed"
          ]
        },
        {
          "member": "MvOrdersSummaryEM.commerceChannelCode",
          "operator": "notEquals",
          "values": [
            "membership_upgrade", "membership_upgrade_pos"
          ]
        },
        {
          "member": "MvOrdersSummaryEM.orderSource",
          "operator": "notEquals",
          "values": [
            "membership_upgrade"
          ]
        }
      ],
      "limit": 50000,
      "renewQuery": true,
    }
  },
  PAXByStorePerDayEMHist:{
    query: {
      "measures": [
        "MvOrdersSummaryEM.sumDineInNumberOfPeople"
      ],
      "timeDimensions": [
      ],
      "order": {
        "MvOrdersSummaryEM.shipFromStoreCode": "asc",
      },
      "dimensions": [
        "MvOrdersSummaryEM.shipFromStoreCode",
      ],
      "filters": [
        {
          "member": "MvOrdersSummaryEM.state",
          "operator": "equals",
          "values": [
            "completed"
          ]
        },
        {
          "member": "MvOrdersSummaryEM.commerceChannelCode",
          "operator": "notEquals",
          "values": [
            "membership_upgrade", "membership_upgrade_pos"
          ]
        },
        {
          "member": "MvOrdersSummaryEM.orderSource",
          "operator": "notEquals",
          "values": [
            "membership_upgrade"
          ]
        }
      ],
      "limit": 50000,
      "renewQuery": true,
    }
  },
  PerPersonSpendingByStorePerDayEMHist:{
    query: {
      "measures": [
        "MvOrdersSummaryEM.perPersonSpending"
      ],
      "timeDimensions": [
      ],
      "order": {
        "MvOrdersSummaryEM.shipFromStoreCode": "asc",
      },
      "dimensions": [
        "MvOrdersSummaryEM.shipFromStoreCode",
      ],
      "filters": [
        {
          "member": "MvOrdersSummaryEM.state",
          "operator": "equals",
          "values": [
            "completed"
          ]
        },
        {
          "member": "MvOrdersSummaryEM.commerceChannelCode",
          "operator": "notEquals",
          "values": [
            "membership_upgrade", "membership_upgrade_pos"
          ]
        },
        {
          "member": "MvOrdersSummaryEM.orderSource",
          "operator": "notEquals",
          "values": [
            "membership_upgrade"
          ]
        }
      ],
      "limit": 50000,
      "renewQuery": true,
    }
  },
  NoOfOrderByStorePerDayEMHist:{
    query: {
      "measures": [
        "MvOrdersSummaryEM.count"
      ],
      "timeDimensions": [
      ],
      "order": {
        "MvOrdersSummaryEM.shipFromStoreCode": "asc",
      },
      "dimensions": [
        "MvOrdersSummaryEM.shipFromStoreCode",
      ],
      "filters": [
        {
          "member": "MvOrdersSummaryEM.state",
          "operator": "equals",
          "values": [
            "completed"
          ]
        },
        {
          "member": "MvOrdersSummaryEM.commerceChannelCode",
          "operator": "notEquals",
          "values": [
            "membership_upgrade", "membership_upgrade_pos"
          ]
        },
        {
          "member": "MvOrdersSummaryEM.orderSource",
          "operator": "notEquals",
          "values": [
            "membership_upgrade"
          ]
        }
      ],
      "limit": 50000,
      "renewQuery": true,
    }
  },
  PerOrderSpendingByStorePerDayEMHist:{
    query: {
      "measures": [
        "MvOrdersSummaryEM.avgBillAmount"
      ],
      "timeDimensions": [
      ],
      "order": {
        "MvOrdersSummaryEM.shipFromStoreCode": "asc",
      },
      "dimensions": [
        "MvOrdersSummaryEM.shipFromStoreCode",
      ],
      "filters": [
        {
          "member": "MvOrdersSummaryEM.state",
          "operator": "equals",
          "values": [
            "completed"
          ]
        },
        {
          "member": "MvOrdersSummaryEM.commerceChannelCode",
          "operator": "notEquals",
          "values": [
            "membership_upgrade", "membership_upgrade_pos"
          ]
        },
        {
          "member": "MvOrdersSummaryEM.orderSource",
          "operator": "notEquals",
          "values": [
            "membership_upgrade"
          ]
        }
      ],
      "limit": 50000,
      "renewQuery": true,
    }
  },
  DayPartAtHomePerDayEMHist:{
      query: {
      "measures": [
        "ByDaySpecialReport.breakfastNetSales",
        "ByDaySpecialReport.lunchNetSales",
        "ByDaySpecialReport.teaNetSales",
        "ByDaySpecialReport.dinnerNetSales",
        "ByDaySpecialReport.deliverooNetSales",
        "ByDaySpecialReport.openriceNetSales",
        "ByDaySpecialReport.foodpandaNetSales",
        "ByDaySpecialReport.brainfreezeNetSales",
        "ByDaySpecialReport.hktvmallNetSales",
        "ByDaySpecialReport.inlineNetSales",
        "ByDaySpecialReport.groupBuyNetSales",
        "ByDaySpecialReport.sumNetSales",
      ],
      "timeDimensions": [
      ],
      "order": {
      },
      "dimensions": [
      ],
      "filters": [
        {
          "member": "ByDaySpecialReport.paymentRequestsState",
          "operator": "equals",
          "values": [
            "completed"
          ]
        },
        {
          "member": "ByDaySpecialReport.state",
          "operator": "equals",
          "values": [
            "completed"
          ]
        },
        {
          "member": "ByDaySpecialReport.commerceChannelCode",
          "operator": "notEquals",
          "values": [
            "membership_upgrade", "membership_upgrade_pos"
          ]
        },
        {
          "member": "ByDaySpecialReport.orderSource",
          "operator": "notEquals",
          "values": [
            "membership_upgrade"
          ]
        }
      ],
      "limit": 50000,
      "renewQuery": true,
    }
  },
}

export default DayEndQueries