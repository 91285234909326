import DiscountBreakdownQueries from './DiscountBreakdownQueries'
import MembershipPurchaseQueries from './MembershipPurchaseQueries'
import SalesQueries from './SalesQueries'
import StoreStaffSalesPerformanceQueries from './StoreStaffSalesPerformanceQueries'

const mvOrderTransactions = {
  mvOrderTransactions: {
    ...DiscountBreakdownQueries,
    ...MembershipPurchaseQueries,
    ...SalesQueries,
    ...StoreStaffSalesPerformanceQueries,
  }
}

export default mvOrderTransactions