const MemberSpendingQueries = {
  memberNetSpending: {
    query: {
      "measures": [
        "MvMemberSpending.averageMembersAverageNetSpending",
        "MvMemberSpending.averageNonMembersAverageNetSpending"
      ],
      "timeDimensions": [
      ],
      "order": {},
      "dimensions": [],
      "filters": [],
      "limit": 50000,
      "renewQuery": true,
    }
  },
  memberSumNetSpending: {
    query: {
      "measures": [
        "MvMemberSpending.sumMembersSumNetSpending",
        "MvMemberSpending.sumNonMembersSumNetSpending"
      ],
      "timeDimensions": [
      ],
      "order": {},
      "dimensions": [],
      "filters": [],
      "limit": 50000,
      "renewQuery": true,
    }
  },
  memberGrossSpending: {
    query: {
      "measures": [
        "MvMemberSpending.averageMembersAverageGrossSpending",
        "MvMemberSpending.averageNonMembersAverageGrossSpending"
      ],
      "timeDimensions": [
      ],
      "order": {},
      "dimensions": [],
      "filters": [],
      "limit": 50000,
      "renewQuery": true,
    }
  },
  memberSumGrossSpending: {
    query: {
      "measures": [
        "MvMemberSpending.sumMembersSumGrossSpending",
        "MvMemberSpending.sumNonMembersSumGrossSpending"
      ],
      "timeDimensions": [
      ],
      "order": {},
      "dimensions": [],
      "filters": [],
      "limit": 50000,
      "renewQuery": true,
    }
  },
  memberDiscount: {
    query: {
      "measures": [
        "MvMemberSpending.averageMembersAverageDiscount",
        "MvMemberSpending.averageMembersDiscountRate",
        "MvMemberSpending.averageNonMembersAverageDiscount",
        "MvMemberSpending.averageNonMembersDiscountRate"
      ],
      "timeDimensions": [
      ],
      "order": {},
      "dimensions": [],
      "filters": [],
      "limit": 50000,
      "renewQuery": true,
    }
  },
  memberSumDiscount: {
    query: {
      "measures": [
        "MvMemberSpending.sumMembersSumDiscount",
        "MvMemberSpending.sumNonMembersSumDiscount",
      ],
      "timeDimensions": [
      ],
      "order": {},
      "dimensions": [],
      "filters": [],
      "limit": 50000,
      "renewQuery": true,
    }
  },
  memberDiscountCount: {
    query: {
      "measures": [
        "MvMemberSpending.averageMembersAverageDiscount",
        "MvMemberSpending.averageNonMembersAverageDiscount",
      ],
      "timeDimensions": [
      ],
      "order": {},
      "dimensions": [],
      "filters": [],
      "limit": 50000,
      "renewQuery": true,
    }
  },
  memberDiscountRatio: {
    query: {
      "measures": [
        "MvMemberSpending.averageMembersDiscountRate",
        "MvMemberSpending.averageNonMembersDiscountRate",
      ],
      "timeDimensions": [
      ],
      "order": {},
      "dimensions": [],
      "filters": [],
      "limit": 50000,
      "renewQuery": true,
    }
  },
}

export default MemberSpendingQueries