import './assets/stylesheets/body.css';
import './assets/stylesheets/chart.css';
import { makeStyles } from '@material-ui/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import React from 'react';
import { CubeProvider } from '@cubejs-client/react';
import { CubeJSProvider } from './context/CubeJSProvider'

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
  },
});

const AppLayout = ({ children }) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <CssBaseline />
      {children}
    </div>
  );
};

const App = ({ children }) => (
  <CubeProvider cubejsApi={null}>
    <CubeJSProvider companyName={""} cubeJSToken={""} cubeJSUrl={""} storeCodes={[]} login={false} acl={{}} expiredAt={null} expired={false}
      breakfastStart={6} breakfastEnd={10} lunchStart={11} lunchEnd={14} teaStart={15} teaEnd={17} 
      dinnerStart={18} dinnerEnd={23}>
      <AppLayout>{children}</AppLayout>
    </CubeJSProvider>
  </CubeProvider>
);

export default App;
