import React from "react";
import Button from '@material-ui/core/Button';

let ButtonComponent = ({onClickFunc=null, startIcon=null, endIcon=null, label="", color="default", disabled=false, toRight=false, ...rest}) => {
  let xs = {marginLeft: "24px"}
  if(toRight)
    xs['float'] = "right"
  return(
    <div style={xs}>
      <Button 
        variant="contained" 
        startIcon={startIcon} 
        endIcon={endIcon}
        onClick={onClickFunc}
      >
        {label}
      </Button>
    </div>
  )
}

export default ButtonComponent;