const SKUUploadTemplateQueries = {
  SKUUploadTemplate: {
    query: {
      "timeDimensions": [
      ],
      "order": {
        "SKUUploadTemplate.propertyAc": "asc"
      },
      "filters": [
      ],
      "dimensions": [
        "SKUUploadTemplate.propertyAc",
        "SKUUploadTemplate.productCode",
        "SKUUploadTemplate.skuCode",
        "SKUUploadTemplate.upcCode",
        "SKUUploadTemplate.skuCommerceChannelCodes",
        "SKUUploadTemplate.sizeOptionCode",
        "SKUUploadTemplate.sizeMasterCode",
        "SKUUploadTemplate.sizeOptionDisplaySequence",
        "SKUUploadTemplate.sizeOptionNameEn",
        "SKUUploadTemplate.sizeOptionNameZn",
        "SKUUploadTemplate.sizeOptionState",
        "SKUUploadTemplate.colorOptionHexValue",
        "SKUUploadTemplate.colorOptionColorCode",
        "SKUUploadTemplate.colorMasterCode",
        "SKUUploadTemplate.colorOptionNameEn",
        "SKUUploadTemplate.colorOptionNameZh",
        "SKUUploadTemplate.colorOptionState",
        "SKUUploadTemplate.productType",
        "SKUUploadTemplate.productStatus",
        "SKUUploadTemplate.skuInternalUseTitle",
        "SKUUploadTemplate.productTitleEn",
        "SKUUploadTemplate.productTitleZh",
        "SKUUploadTemplate.categoriesCode"
      ],
      "limit": 50000,
      "renewQuery": true,
    }
  },
  SKUFNBUploadTemplate: {
    query: {
      "measures": [
        "FNBSKUUpload.lastSkuPriceId"
      ],
      "timeDimensions": [
      ],
      "order": {
      },
      "filters": [
      ],
      "dimensions": [
      "FNBSKUUpload.skuCode",
      "FNBSKUUpload.upcCode",
      "FNBSKUUpload.productCode",
      "FNBSKUUpload.productType",
      "FNBSKUUpload.skuInternalUseTitle",
      "FNBSKUUpload.productTitleEn",
      "FNBSKUUpload.productTitleZh",
      "FNBSKUUpload.productStatus",
      "FNBSKUUpload.skuPriceId",
      "FNBSKUUpload.skuPrice",
      "FNBSKUUpload.priceActiveAt",
      "FNBSKUUpload.skuCost",
      "FNBSKUUpload.hasInventoryControl",
      "FNBSKUUpload.useMeasurement",
      "FNBSKUUpload.skuMeasurementId",
      "FNBSKUUpload.hasMeasurementInventoryControl",
      "FNBSKUUpload.measurementUnit",
      "FNBSKUUpload.skuError",
      "FNBSKUUpload.propertyAc",
      "FNBSKUUpload.skuPacking",
      "FNBSKUUpload.supplierProductCode",
      "FNBSKUUpload.memberPrice",
      "FNBSKUUpload.menuFilter",
      "FNBSKUUpload.menuNotFilter",
      "FNBSKUUpload.membershipTierLogic",
      "FNBSKUUpload.fnbPrintingGroup",
      "FNBSKUUpload.fnbPrintTo",
      "FNBSKUUpload.fnbItemType",
      "FNBSKUUpload.fnbDineInRemark",
      "FNBSKUUpload.fnbAdditionalPrintCopies",
      "FNBSKUUpload.fnbSkipModifier",
      "FNBSKUUpload.fnbTakeAwayRemark",
      "FNBSKUUpload.posRestaurantMenuButton",
      "FNBSKUUpload.pointsoftCode",
      "FNBSKUUpload.siblingsTitle",
      "FNBSKUUpload.siblingsProductCodes",
      "FNBSKUUpload.skuCommerceChannelCodes",
      "FNBSKUUpload.sizeOptionCode",
      "FNBSKUUpload.sizeOptionMasterCode",
      "FNBSKUUpload.sizeOptionNameEn",
      "FNBSKUUpload.sizeOptionNameZh",
      "FNBSKUUpload.sizeOptionState",
      "FNBSKUUpload.colorOptionHex",
      "FNBSKUUpload.colorOptionCode",
      "FNBSKUUpload.colorOptionMasterCode",
      "FNBSKUUpload.colorOptionNameEn",
      "FNBSKUUpload.colorOptionNameZh",
      "FNBSKUUpload.colorOptionState",
      "FNBSKUUpload.categoryCodes",
      "FNBSKUUpload.subcatCategoryCode",
      "FNBSKUUpload.rsmrCode",
      "FNBSKUUpload.rsmCode"
      ],
      "limit": 50000,
      "renewQuery": true,
    }
  }
}

export default SKUUploadTemplateQueries