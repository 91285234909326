import React, { useState, useContext } from "react";
import Header from '../../components/Header';
import TableComponent from '../../components/TableComponent';
import MultiSelectComponent from '../../components/MultiSelectComponent';
import DatePickerComponent from '../../components/DatePickerComponent';
import Dashboard from '../../components/Dashboard';
import PageHeaderComponent from '../../components/PageHeaderComponent';
import { CubeJSContext } from '../../context/CubeJSProvider';
import OmnitechQueries from '../../queries/OmnitechQueries'
import { useTranslation } from 'react-i18next';
import { useCubeQuery } from '@cubejs-client/react';
import { format } from 'date-fns'

const applyFilter = (query, storeFilter) => {
  let storeCodeFilterObj = {
    member: "OrderDayEndEM.shipmentStoreCode",
    operator: "contains",
    values: storeFilter
  }

  query = {
    ...query,
    filters: (query.filters || []).concat(storeCodeFilterObj)
  }

  return query
}

const DailySalesQuery = (storeFilter, startDateFilter, endDateFilter, isRefresh, setRefresh) => {
  const { t } = useTranslation();
  if(startDateFilter == null){
    startDateFilter = new Date()
    startDateFilter.setHours(0,0,0,0);
    endDateFilter = new Date()
    endDateFilter.setHours(23,59,59,999);
  }
  let item = {
    name: t('page.fnb.dailySalesPage.name'),
    tableHeaders: {
      "OrderDayEndEM.orderCompletedAt": { name: t('page.fnb.dailySalesPage.tableHeader.date'), type: 'dateCSV'},
      "OrderDayEndEM.orderCompletedAt2": { name: t('page.fnb.dailySalesPage.tableHeader.time'), type: 'timeCSV'},
      "OrderDayEndEM.referenceNumber": { name: t('page.fnb.dailySalesPage.tableHeader.referenceNumber'), type: 'string'},
      "OrderDayEndEM.netSalesSingle": { name: t('page.fnb.dailySalesPage.tableHeader.netSales'), type: 'numberCSV'},
    },
    grid: {xs: 12, lg: 12},
    export: { allowExport: true, filename: 'DT_xxxxxx_' + format(startDateFilter, 'yyyyMMdd') },
    skipHeader: true
  }
  let query = {
    ...OmnitechQueries.orders.DailySales.query,
    "timeDimensions": [
      {
        "dimension": "OrderDayEndEM.orderCompletedAt",
        "dateRange": [ startDateFilter, endDateFilter ]
      }
    ],
  }
  query = applyFilter(query, storeFilter)
  if(isRefresh)
    query = {}
  const result = useCubeQuery(query, {resetResultSetOnChange: true})
  if(isRefresh)
    setRefresh(false)
  return { item, result }
}

const DailySalesPage = () => {
  const { t } = useTranslation();
  const context = useContext(CubeJSContext);
  let storeCodeSelect = []
  if(context.storeCodes)
    storeCodeSelect = context.storeCodes
  const [ storeFilter, setStoreFilter ] = useState([]);
  let filterStartDate = new Date()
  filterStartDate.setHours(0,0,0,0);
  let filterEndDate = new Date()
  filterEndDate.setHours(23,59,59,999);
  const [dateRangeFilter, setDateRangeFilter] = useState([filterStartDate, filterEndDate])
  const [startDateFilter, endDateFilter] = dateRangeFilter
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  const [ refresh1, setRefresh1 ] = useState(false);

  const { item, result }  = DailySalesQuery(storeFilter, startDateFilter, endDateFilter, refresh1, setRefresh1)

  const refresh = () => {
    setRefresh1(true)
    setPage(0)
  }

  return ([
    <Header />,
    <PageHeaderComponent onClickFunc={refresh} refreshlabel={t('component.refresh')} headerTitle={t('component.header.menu.dailySales')} />,
    <Dashboard>
      <MultiSelectComponent id={"store_code_filter"} state={storeFilter} pageChangerArr={[setPage]}
          stateChanger={setStoreFilter} textLabel={t('page.fnb.dailySalesPage.filter.store.name')} selectArray={storeCodeSelect}/>
      <DatePickerComponent state={dateRangeFilter} stateChanger={setDateRangeFilter} dateLabel={t('page.fnb.dailySalesPage.filter.orderCompletedAt.date')}/>
    </Dashboard>,
    <Dashboard>
      <TableComponent item={item} result={result} rowsPerPage={rowsPerPage} 
        setRowsPerPage={setRowsPerPage} page={page} setPage={setPage}/>
    </Dashboard>
  ]) 
};

export default DailySalesPage;