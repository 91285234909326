const DiscountBreakdownQueries = {
  DiscountBreakdownByOrder: {
    query: {
      "measures": [
        "MvOrderTransactions.sumLineTotal",
        "MvOrderTransactions.sumQuantity",
        "MvOrdersSummary.sumNetSales"
      ],
      "order": {
        "MvOrdersSummary.ordersCompletedAt": "desc",
        "MvOrdersSummary.shipFromStoreCode": "asc"
      },
      "dimensions": [
        "MvOrderTransactions.discountCode",
        "MvOrderTransactions.discountNameEnHk",
        "MvOrderTransactions.discountNameZhHk",
        "MvOrderTransactions.couponRedeemablePoint",
        "MvOrderTransactions.couponRedeemablePrice",
        "MvOrderTransactions.couponTokensCreatedAt",
        "MvOrdersSummary.shipFromStoreCode",
        "Users.membershipCode",
        "MvOrdersSummary.referenceNumber",
        "Users.firstName",
        "Users.phone",
        "Users.userPreferDistrict",
        "CustomerRanks.code",
        "UserLoyaltyPointBalances.rankExpireAt",
        "MvOrdersSummary.ordersCompletedAt"
      ],
      "filters": [
        {
          "member": "MvOrderTransactions.code",
          "operator": "equals",
          "values": [
            "sku_catalog_promotion_calculated_unit_discount_amount",
            "sku_cart_discount_calculated_unit_discount_amount",
            "sku_coupon_calculated_unit_discount_amount",
            "cart_discount_calculated_total_discount_amount",
            "coupon_calculated_total_discount_amount",
            "shipping_cart_discount_calculated_unit_discount_amount",
            "shipping_cart_discount_calculated_total_discount_amount",
            "voucher_total_amount"
          ]
        },
        {
          "member": "MvOrderTransactions.commerceChannelCode",
          "operator": "notEquals",
          "values": [
            "membership_upgrade"
          ]
        },
        {
          "member": "MvOrdersSummary.state",
          "operator": "equals",
          "values": [
            "completed"
          ]
        }
      ],
      "limit": 50000,
      "renewQuery": true,
    }
  }
}

export default DiscountBreakdownQueries