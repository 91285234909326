const CurrentInventoryBalanceQueries = {
  CurrentInventoryBalance: {
    query: {
      "timeDimensions": [
      ],
      "order": {
        "CurrentInventoryBalance.propertyAc": "asc"
      },
      "filters": [
      ],
      "dimensions": [
        "CurrentInventoryBalance.propertyAc",
        "CurrentInventoryBalance.skuCode",
        "CurrentInventoryBalance.upcCode",
        "CurrentInventoryBalance.skuInternalUseTitle",
        "CurrentInventoryBalance.productCode",
        "CurrentInventoryBalance.productTitleEn",
        "CurrentInventoryBalance.productTitleZh",
        "CurrentInventoryBalance.categoryCodes",
        "CurrentInventoryBalance.storeCode",
        "CurrentInventoryBalance.stockCounterCacheId",
        "CurrentInventoryBalance.availableForSale",
        "CurrentInventoryBalance.instock",
        "CurrentInventoryBalance.holdCount",
        "CurrentInventoryBalance.available",
        "CurrentInventoryBalance.cost",
        "CurrentInventoryBalance.inventoryCost"
      ],
      "limit": 50000,
      "renewQuery": true,
    }
  },
  CurrentInventoryMeasureBalance: {
    query: {
      "timeDimensions": [
      ],
      "order": {
        "CurrentInventoryMeasureBalance.propertyAc": "asc"
      },
      "filters": [
      ],
      "dimensions": [
        "CurrentInventoryMeasureBalance.propertyAc",
        "CurrentInventoryMeasureBalance.skuCode",
        "CurrentInventoryMeasureBalance.upcCode",
        "CurrentInventoryMeasureBalance.skuInternalUseTitle",
        "CurrentInventoryMeasureBalance.productCode",
        "CurrentInventoryMeasureBalance.productTitleEn",
        "CurrentInventoryMeasureBalance.productTitleZh",
        "CurrentInventoryMeasureBalance.categoryCodes",
        "CurrentInventoryMeasureBalance.storeCode",
        "CurrentInventoryMeasureBalance.balance",
        "CurrentInventoryMeasureBalance.unit",
      ],
      "limit": 50000,
      "renewQuery": true,
    }
  },
}

export default CurrentInventoryBalanceQueries