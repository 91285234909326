import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import EnHK from './assets/i18n/en-HK';
import ZhHK from './assets/i18n/zh-HK';

const resources = {
  'en-HK': {
    translation: EnHK,
  },
  'zh-HK': {
    translation: ZhHK,
  },
};

i18n.use(LanguageDetector).use(initReactI18next).init({
  resources,
  // order and from where user language should be detected
  order: ['localStorage','querystring', 'cookie', 'sessionStorage', 'navigator', 'htmlTag', 'path', 'subdomain'],

  // keys or params to lookup language from
  lookupQuerystring: 'lng',
  lookupCookie: 'i18next',
  lookupLocalStorage: 'i18nextLng',
  lookupSessionStorage: 'i18nextLng',
  lookupFromPathIndex: 0,
  lookupFromSubdomainIndex: 0,

  // cache user language on
  caches: ['localStorage', 'cookie'],
  excludeCacheFor: ['cimode'], // languages to not persist (cookie, localStorage)

  // optional expire and domain for set cookie
  cookieMinutes: 10,
  cookieDomain: 'myDomain',

  // optional htmlTag with lang attribute, the default is:
  htmlTag: document.documentElement,

  // optional set cookie options, reference:[MDN Set-Cookie docs](https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie)
  cookieOptions: { path: '/', sameSite: 'strict' },
  interpolation: {
    escapeValue: false,
  },
  fallbackLng: {
    'default': ['en-HK']
  }
});

export default i18n;