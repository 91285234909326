import React from "react";
import { useTranslation } from 'react-i18next';
import LinearProgress from '@material-ui/core/LinearProgress';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Link from '@material-ui/core/Link';
import TablePagination from '@material-ui/core/TablePagination';
import TableContainer from "@material-ui/core/TableContainer";
import Typography from '@material-ui/core/Typography';
import { format } from 'date-fns'
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import CardActions from '@material-ui/core/CardActions';
import DashboardItem from './DashboardItem';
import ExportButtonComponent from "./ExportButtonComponent";

const theme = createTheme({
  typography: {
    body1: {
      fontSize: 12,
      fontWeight: 700,
    },
    body2: {
      fontSize: 11,
      fontWeight: 600,
    },
  },
});

const MyTableComponent = ({resultSet, isLoading, error, progress, xs, sm, md, lg, height, pivotConfig, ordering,
  name, tableHeaders, filename, allowExport, rowsPerPage, setRowsPerPage, page, setPage, pivotRename, 
  withDoW, doWHeader, doWHeaderName, withPivotSum, pivotSumHeaderName, withTotalSum, skipHeader, forcePivotNumber,
  withPercentageSum, percentageSumHeaderName, withPercentageSumColoum}) => {

  const { t } = useTranslation();

  let weekday = new Array(7);
  weekday[0] = t('component.table.sunday')
  weekday[1] = t('component.table.monday')
  weekday[2] = t('component.table.tuesday')
  weekday[3] = t('component.table.wednesday')
  weekday[4] = t('component.table.thursday')
  weekday[5] = t('component.table.friday')
  weekday[6] = t('component.table.saturday')

  const handlePageChange = (event, page) => {
    setPage(page);
  };

  const handleRowsPerPageChange = event => {
    setPage(0);
    setRowsPerPage(event.target.value);
  };
  let headers = [];
  let res = {};
  let exportRes = {};
  let percentageSum = 0;

  if(resultSet){
    isLoading = null
    progress = null
    if(pivotConfig)
    {
      res = resultSet.tablePivot(pivotConfig)
      exportRes = resultSet.tablePivot(pivotConfig)
    }
    else{
      res = resultSet.tablePivot()
      exportRes = resultSet.tablePivot()
    }
      
    if(res.length > 0){
      let totalSum = {}
      totalSum[doWHeader] = "Total"
      for(let i = 0; i < res.length; i++){
        if(withDoW && doWHeaderName && doWHeader){
          res[i][doWHeaderName] = weekday[new Date(res[i][doWHeader]+"+00:00").getDay()]
          exportRes[i][doWHeaderName] = weekday[new Date(res[i][doWHeader]+"+00:00").getDay()]
        }
        let count = 0
        Object.keys(res[i]).map((c) => {
          if(withPivotSum && 
            pivotSumHeaderName && 
            c !== doWHeaderName && 
            c !== doWHeader && !tableHeaders[c]){
              count += parseFloat(Math.round(exportRes[i][c] * 100) / 100)
              res[i][c] = (Math.round(res[i][c] * 100) / 100).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              exportRes[i][c] = (Math.round(exportRes[i][c] * 100) / 100)
            }
          else{
            if(tableHeaders[c] && tableHeaders[c].type){
              switch(tableHeaders[c].type) {
                case 'number':
                  if(res[i][c] !== null){
                    if (withTotalSum){
                      if(totalSum[c])
                        totalSum[c] += res[i][c]
                      else
                        totalSum[c] = res[i][c]
                    }
                    res[i][c] = (Math.round(res[i][c] * 100) / 100).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    exportRes[i][c] = (Math.round(exportRes[i][c] * 100) / 100)
                  }
                  break;
                case 'numberCSV':
                  if(res[i][c] !== null){
                    if (withTotalSum){
                      if(totalSum[c])
                        totalSum[c] += res[i][c]
                      else
                        totalSum[c] = res[i][c]
                    }
                    res[i][c] = parseFloat((Math.round(res[i][c] * 100) / 100)).toFixed(2).toString()
                    exportRes[i][c] =  parseFloat((Math.round(res[i][c] * 100) / 100)).toFixed(2)
                  }
                  break;
                case 'offsetNumber':
                  if(res[i][c] !== null)
                    res[i][c] = (res[i][c]-tableHeaders[c].offset)
                  if(res[i][c] >= 24)
                    res[i][c] = res[i][c] -24
                  else if (res[i][c] < 0)
                    res[i][c] = res[i][c] + 24
                  exportRes[i][c] = res[i][c]
                  if (withTotalSum && c !== doWHeaderName && c !== doWHeader){
                    if(totalSum[c])
                      totalSum[c] += res[i][c]
                    else
                      totalSum[c] = res[i][c]
                  }
                  break;
                case 'dateTime':
                  if(res[i][c] !== null)
                    res[i][c] = format(new Date(res[i][c]+"+00:00"), 'yyyy/MM/dd HH:mm:ss')
                  exportRes[i][c] = res[i][c]
                  break;
                case 'date':
                  if(res[i][c] !== null)
                    res[i][c] = format(new Date(res[i][c]+"+00:00"), 'yyyy/MM/dd')
                  exportRes[i][c] = res[i][c]
                  break;
                case 'dateMonth':
                  if(res[i][c] !== null)
                    res[i][c] = format(new Date(res[i][c]+"+00:00"), 'yyyy/MM')
                  exportRes[i][c] = res[i][c]
                  break;
                case 'dateYear':
                  if(res[i][c] !== null)
                    res[i][c] = format(new Date(res[i][c]+"+00:00"), 'yyyy')
                  exportRes[i][c] = res[i][c]
                  break;
                case 'hkHour':
                  if(res[i][c] !== null)
                    res[i][c] = format(new Date(res[i][c]+"+08:00"), 'HH')
                  exportRes[i][c] = res[i][c]
                  break;
                case 'dateCSV':
                  if(res[i][c] !== null)
                    res[i][c] = format(new Date(res[i][c]+"+00:00"), 'yyyyMMdd')
                  exportRes[i][c] = res[i][c]
                  break;
                case 'dateMonthCSV':
                  if(res[i][c] !== null)
                    res[i][c] = format(new Date(res[i][c]+"+00:00"), 'yyyyMM')
                  exportRes[i][c] = res[i][c]
                  break;
                case 'dateYearCSV':
                  if(res[i][c] !== null)
                    res[i][c] = format(new Date(res[i][c]+"+00:00"), 'yyyy')
                  exportRes[i][c] = res[i][c]
                  break;
                case 'timeCSV':
                  if(res[i][c] !== null)
                    res[i][c] = format(new Date(res[i][c]+"+00:00"), 'HH:mm:ss')
                  exportRes[i][c] = res[i][c]
                  break;
                case 'boolean':
                  if(res[i][c] !== null)
                    res[i][c] = (res[i][c]? "true" : "false")
                  exportRes[i][c] = res[i][c]
                  break;
                case 'array':
                  if(res[i][c] !== null)
                    res[i][c] = JSON.parse(res[i][c])
                  exportRes[i][c] = res[i][c]
                  break;
                default:
                  break;
              }
            }
            else if (forcePivotNumber){
              if(res[i][c] !== null){
                if (withTotalSum){
                  if(totalSum[c])
                    totalSum[c] += res[i][c]
                  else
                    totalSum[c] = res[i][c]
                }
                res[i][c] = (Math.round(res[i][c] * 100) / 100).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                exportRes[i][c] = (Math.round(exportRes[i][c] * 100) / 100)
              }
            }
          }
          return null
        })
        if(withPivotSum && pivotSumHeaderName){
          if (withTotalSum){
            if(totalSum[pivotSumHeaderName])
              totalSum[pivotSumHeaderName] += count
            else
              totalSum[pivotSumHeaderName] = count
          }
          res[i][pivotSumHeaderName] = (Math.round(count * 100) / 100).toString()
          exportRes[i][pivotSumHeaderName] = (Math.round(count * 100) / 100)
        }
        if(withPercentageSum && percentageSumHeaderName && withPercentageSumColoum){
          percentageSum += exportRes[i][withPercentageSumColoum]
        }
      }
      if(withTotalSum){
        res.push(totalSum)
        exportRes.push(totalSum)
      }
      
      if(withPercentageSum && percentageSumHeaderName && withPercentageSumColoum){
        for(let i = 0; i < res.length; i++){
          res[i][percentageSumHeaderName] = (exportRes[i][withPercentageSumColoum] / percentageSum * 100).toFixed(2).toString()+'%'
          exportRes[i][percentageSumHeaderName] = (exportRes[i][withPercentageSumColoum] / percentageSum * 100).toFixed(2)
        }
      }

      if(allowExport){
        if(!pivotConfig){
          if(ordering == null)
            resultSet.tableColumns().map((c) => (headers.push({label: tableHeaders[c.key].name, key: c.key})))
          else
            ordering.map((c) => (headers.push({label: tableHeaders[c].name, key: c})))
        } 
        else{
          Object.keys(res[0]).map((c) => {
            if(tableHeaders[c])
              headers.push({label: tableHeaders[c].name, key: c})
            else{
              let tmpHeader = c
              for(let i = 0; i < pivotRename.length; i=i+2)
                tmpHeader = tmpHeader.replace(pivotRename[i],pivotRename[i+1])
              headers.push({label: tmpHeader, key: c})
            }
            return null
          })
        }
      }
    }
  }

  return (
    <Grid item xs={xs} sm={sm} md={md} lg={lg} >
      <div style={{marginBottom: "10px"}}>
      <DashboardItem title={name} height={height}>
        {error && <div>{error.toString()}</div>}
        {
          isLoading && <div><center>{(progress && progress.stage && progress.stage.stage) || t('component.table.loading')}
          {(progress && progress.stage && progress.stage.timeElapsed && '... ' + progress.stage.timeElapsed/1000 + t('component.table.second'))|| ''}
          <Grid style={{padding: 10}}/></center></div>
        }
        {isLoading && <LinearProgress />}
        {resultSet && <TableContainer>
        <Table aria-label="simple table" size="small">
          <TableHead>
            <TableRow>
              {ordering !== null && res.length > 0 && ordering.map((c) => {
                if(tableHeaders[c])
                    return(<TableCell key={c}>{tableHeaders[c].name}</TableCell>)
                  else{
                    let header = c
                    for(let i = 0; i < pivotRename.length; i=i+2)
                      header = header.replace(pivotRename[i],pivotRename[i+1])
                    return(<TableCell key={c}>{header}</TableCell>)
                  }
              })}
              {ordering == null  && res.length > 0 &&
                Object.keys(res[0]).map((c) => {
                  if(tableHeaders[c])
                    return(<TableCell key={c}>{tableHeaders[c].name}</TableCell>)
                  else{
                    let header = c
                    for(let i = 0; i < pivotRename.length; i=i+2)
                      header = header.replace(pivotRename[i],pivotRename[i+1])
                    return(<TableCell key={c}>{header}</TableCell>)
                  }
                })
              }
            </TableRow>
          </TableHead>
          <TableBody>
            {res.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
              <TableRow key={index}>
                {ordering == null &&
                  Object.keys(row).map((c) => {
                    if(tableHeaders[c] && tableHeaders[c].type && tableHeaders[c].type === 'array')
                      return(<TableCell key={c}>{JSON.stringify(row[c])}</TableCell>)
                    else if(tableHeaders[c] && tableHeaders[c].function)
                      return(<TableCell key={c} onClick={tableHeaders[c].function}><Link href="#" variant="body2">{row[c]}</Link></TableCell>)
                    else
                      return(<TableCell key={c}>{row[c]}</TableCell>)
                  })}
                {ordering !== null &&
                  ordering.map((c) => {
                    if(tableHeaders[c] && tableHeaders[c].type && tableHeaders[c].type === 'array')
                      return(<TableCell key={c}>{JSON.stringify(row[c])}</TableCell>)
                    else if(tableHeaders[c] && tableHeaders[c].function)
                      return(<TableCell key={c} onClick={tableHeaders[c].function}><Link href="#" variant="body2">{row[c]}</Link></TableCell>)
                    else
                      return(<TableCell key={c}>{row[c]}</TableCell>)
                })}
              </TableRow>
            ))}
            {res.length === 0 && (<Typography align="center" variant="h5" component="h5" gutterBottom>{t('component.table.noRecordFound')}</Typography>)}
          </TableBody>
        </Table>
        </TableContainer>}
        <Grid style={{padding: 10}}/>
        {resultSet && <TablePagination
          component="div"
          count={res.length}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleRowsPerPageChange}
          labelRowsPerPage={t('component.table.rowPerPage')}
          labelDisplayedRows={function defaultLabelDisplayedRows({ from, to, count }) { return `${from}-${to} ${t('component.table.displayRows')} ${count !== -1 ? count : `more than ${to}`}`; }}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[10, 25, 50, 100, 250, 500, 1000]}
        />}
        { 
          allowExport && <CardActions>
            <ExportButtonComponent 
              headers={headers}
              data={exportRes}
              filename={filename}
              skipHeader={skipHeader}
            />
          </CardActions>
        }
        {
          resultSet && resultSet.loadResponse.slowQuery && (new Date().getTime() / 1000 - new Date(resultSet.loadResponse.results[0].lastRefreshTime).getTime() / 1000 > 300) &&
              <ThemeProvider theme={theme}><Typography style={{"marginTop": 10}} variant="body2" color="red" align="right" gutterBottom>{t('component.lastUpdatedAt')+": "+ format(new Date(resultSet.loadResponse.results[0].lastRefreshTime), 'yyyy/MM/dd HH:mm:ss')}</Typography></ThemeProvider>
        }
        {
          resultSet && (!resultSet.loadResponse.slowQuery || (resultSet.loadResponse.slowQuery && (new Date().getTime() / 1000 - new Date(resultSet.loadResponse.results[0].lastRefreshTime).getTime() / 1000 <= 300))) && 
          <ThemeProvider theme={theme}><Typography style={{"marginTop": 10}} variant="body2" color="textSecondary" align="right" gutterBottom>{t('component.lastUpdatedAt')+": "+ format(new Date(resultSet.loadResponse.results[0].lastRefreshTime), 'yyyy/MM/dd HH:mm:ss')}</Typography></ThemeProvider>
        }
      </DashboardItem>
      </div>
    </Grid>
  );
};

const TableComponent = ({ item, result, rowsPerPage, setRowsPerPage, page, setPage }) => {
  const grid = item.grid || {}
  const { xs = 12, sm, md, lg } = grid
  const height = (item.grid && item.grid.height) ? item.grid.height : null
  const tableHeaders = item.tableHeaders || {}
  const filename = (item.export && item.export.filename) ? item.export.filename : 'export.csv'
  const allowExport = (item.export && item.export.allowExport) ? item.export.allowExport : false
  const pivotConfig = item.pivotConfig || {}
  const pivotRename = item.pivotRename || []
  const ordering = item.ordering || null
  const withDoW = item.withDoW || false
  const doWHeader = item.doWHeader || null
  const doWHeaderName = item.doWHeaderName || null
  const withPivotSum = item.withPivotSum || false
  const withTotalSum = item.withTotalSum || false
  const withPercentageSum = item.withPercentageSum || false
  const percentageSumHeaderName = item.percentageSumHeaderName || null
  const withPercentageSumColoum = item.withPercentageSumColoum || null
  const pivotSumHeaderName = item.pivotSumHeaderName || null
  const skipHeader = item.skipHeader || false
  const forcePivotNumber = item.forcePivotNumber || false
  return <MyTableComponent {...result} xs={xs} sm={sm} md={md} lg={lg} height={height}  name={item.name} ordering={ordering}
  tableHeaders={tableHeaders} filename={filename} allowExport={allowExport} pivotConfig={pivotConfig}
  rowsPerPage={rowsPerPage} setRowsPerPage={setRowsPerPage} page={page} setPage={setPage} pivotRename={pivotRename} 
  doWHeader={doWHeader} withDoW={withDoW} doWHeaderName={doWHeaderName} withPivotSum={withPivotSum} 
  pivotSumHeaderName={pivotSumHeaderName} withTotalSum={withTotalSum} skipHeader={skipHeader} forcePivotNumber={forcePivotNumber}
  withPercentageSum={withPercentageSum} percentageSumHeaderName={percentageSumHeaderName} withPercentageSumColoum={withPercentageSumColoum}/>
};

export default TableComponent;
