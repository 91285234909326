const commonEnHK = {
  "userDeviceRegister": {
    "name": "User Device Register",
    "tableHeader": {
      "lastDeviceCreatedAt": "Last Device CreatedAt",
      "deviceCount": "Device Count",
      "membershipCode": "Membership Code",
      "firstName": "Customer Name",
      "rankCode": "Rank Code",
      "sex": "Sex",
      "email": "Email",
      "phone": "Phone",
      "dateOfBirth": "Date Of Birth",
      "isActive": "Is Active",
      "isDeleted": "Is Deleted",
      "remark": "Remark",
      "createdAt": "Created At",
      "updatedAt": "Updated At",
      "modifiedAt": "Modified At"
    },
    "filter": {
      "membershipCode": "Membership Code",
      "email": "Email",
      "phone": "Phone",
      "userName": "Customer Name",
    }
  },
  "takeawayDeliveryOrderApprovalTimeTrace": {
    "name": "Takeaway Delivery Order Approval Time Trace",
    "tableHeader": {
      "storeCode": "Store Code",
      "referenceNumber": "Order Reference Number",
      "orderState": "Order State",
      "createdAt": "Created At",
      "waitingForApprovalAt": "Order Place By Customer At",
      "confirmedAt": "Order Approved At",
      "orderType": "Order Type",
      "commerceChannelCode": "Commerce Channel Code",
      "ticketNumber": "Ticket Number",
    },
    "filter": {
      "orderCreatedAt": {
        "date": "Created At"
      },
      "referenceNumber": "Order Reference Number",
    }
  },
  "rtDiscountRelationsChecking": {
    "discountRelationsReport":{
      "name": "Discount Relations Report",
      "tableHeader": {
        "rsmCode": "RSM Code",
        "skuCode": "SKU Code",
        "discountCode": "Discount Code",
        "discountableType": "Discountable Type",
        "requiredManualTrigger": "Required Manual Trigger",
        "skuProductTitle": "SKU Product Title",
        "requiredCoupon": "Required Coupon",
        "allSkusByApply": "All SKUs By Apply",
        "propertyApplyNature": "Property Apply Nature",
        "cartDiscountsCode": "Cart Discounts Code",
        "cartDiscountsCouponCode": "Cart Discounts Coupon Code",
        "startAt": "Start At",
        "endAt": "End At",
        "mode": "Mode",
        "couponCode": "Coupon Code",
        "timingState": "Timing State"
      },
    },
    "discountRelationPivot":{
      "name": "Discount Relations Pivot",
      "tableHeader": {
        "skuCode": "SKU Code",
      }
    },
    "filter": {
      "StartsAt": {
        "startDate": "Starts At From Date",
        "to": "to",
        "endDate": "Starts At End Date"
      },
      "rsmCode": "RSM Code",
      "skuCode": "SKU Code",
      "discountCode": "Cart Discounts Code",
      "discountableType": "Discountable Type",
      "timingState": "Timing State",
      "requiredManualTrigger": "Required Manual Trigger"
    }
  },
  "rtBeveragesSalesPage": {
    "name": "Beverages Sales",
    "tableHeader": {
      "lineTotal": "Line Total",
      "quantity": "Quantity",
      "code": "Beverages RSM Code",
    },
    "filter": {
      "store": {
        "all": "All",
        "name": "Store Code"
      },
      "orderCompletedAt": {
        "startDate": "Order Completed At From Date",
        "to": "to",
        "endDate": "Order Completed At End Date"
      }
    }
  },
  "rtUserExport":{
    "name": "User Export",
    "tableHeader": {
      "totalSpend": "Total Spend",
      "orderCount": "Order Count",
      "membershipCode": "Membership Code",
      "firstName": "User Name",
      "rankCode": "User Rank Code",
      "sex": "Sex",
      "phone": "Phone",
      "email": "Email",
      "dateOfBirth": "Date Of Birth",
      "isActive": "Is Active",
      "isDeleted": "Is Deleted",
      "remark": "Remark",
      "createdAt": "Created At",
      "updatedAt": "Updated At",
      "modifiedAt": "Modified At",
      "rankExpireAt": "Rank Expire At",
      "userPreferDistrict": "User Prefere District",
      "district": "District",
      "salesamountPS": "Sales Amount From PS",
      "salescountPS": "Sales Count From PS",
      "dateJoinPS": "Date Join From PS",
      "dateContPS": "Date Cont From PS",
      "oldCodePS": "Old Code From PS",
      "zonePS": "Zone From PS",
      "userAgreementPics": "User Accept Receive Promotion",
      "userAgreementTerms": "User Accept Agreement Terms",
      "point": "Point"
    },
    "filter": {
      "updatedAt": {
        "startDate": "Updated At From Date",
        "to": "to",
        "endDate": "Updated At End Date"
      },
      "createdAt": {
        "startDate": "Created At From Date",
        "to": "to",
        "endDate": "Created At End Date"
      },
      "membershipCode": "Membership Code",
      "email": "Email",
      "phone": "Phone",
      "userName": "User Name",
      "oldCode": "Old Code From PS",
      "zoneFromPS": "Zone From PS",
      "userAgreementPics": "User Accept Receive Promotion",
      "userAgreementTerms": "User Accept Agreement Terms"
    },
  },
  "rtUserQuery":{
    "userExport":{
      "name": "User Information",
      "tableHeader": {
        "totalSpend": "Total Spend",
        "orderCount": "Order Count",
        "membershipCode": "Membership Code",
        "firstName": "User Name",
        "rankCode": "User Rank Code",
        "sex": "Sex",
        "phone": "Phone",
        "email": "Email",
        "dateOfBirth": "Date Of Birth",
        "isActive": "Is Active",
        "isDeleted": "Is Deleted",
        "remark": "Remark",
        "createdAt": "Created At",
        "updatedAt": "Updated At",
        "modifiedAt": "Modified At",
        "rankExpireAt": "Rank Expire At",
        "userPreferDistrict": "User Prefere District",
        "district": "District",
        "salesamountPS": "Sales Amount From PS",
        "salescountPS": "Sales Count From PS",
        "dateJoinPS": "Date Join From PS",
        "dateContPS": "Date Cont From PS",
        "oldCodePS": "Old Code From PS",
        "zonePS": "Zone From PS",
        "userAgreementPics": "User Accept Receive Promotion",
        "userAgreementTerms": "User Accept Agreement Terms",
        "point": "Point"
      },
    },
    "membershipTransaction":{
      "name": "Membership Transaction",
      "tableHeader": {
        "rankCode": "User Rank Code",
        "effectiveAt": "Effective At",
        "expireAt": "Expire At",
        "isDeleted": "Is Deleted",
        "firstName": "User Name",
      },
    },
    "orderHistory":{
      "name": "Order History",
      "tableHeader": {
        "ordersCompletedAt": "Orders Completed At",
        "shipFromStoreCode": "Store Code",
        "referenceNumber": "Reference Number",
        "grossSales": "Gross Sales",
        "discounts": "Discounts",
        "serviceCharges": "Service Charges",
        "orderAmountRounding": "Order Amount Rounding",
        "membershipCharges": "Membership Charges",
        "orderTotal": "Order Total",
      },
    },
    "loyaltyPointTransaction":{
      "name": "Loyalty Point Transaction",
      "tableHeader": {
      "id": "ID", 
        "createdAt": "Created At",
        "code": "Code",
        "opening": "Opening", 
        "points": "Points",
        "balance": "Balance",
        "isDeleted": "Is Deleted", 
        "isActive": "Is Active", 
        "activeAt": "Actived At",
        "expireAt": "Expired At", 
        "updatedAt": "Updated At",
      },
    },
    "usersMostConsumeStore": {
      "name": "User Most Consume Store",
      "tableHeader": {
        "storeCode": "Store Code",
        "count": "Count"
      }
    },
    "filter": {
      "membershipCode": "Membership Code",
    },
    "enterMembershipCode": "Please entere membership code and press enter to query the member.",
  },
  "rtOrderUserExport":{
    "name": "RT Order Export By Store With User Association",
    "tableHeader": {
      "referenceNumber": "Reference Number",
      "shipmentStoreCode": "Store Code",
      "completedAt": "Completed At",
      "totalPrice": "Total Price",
      "totalSpend": "Total Spend",
      "orderCount": "Order Count",
      "membershipCode": "Membership Code",
      "firstName": "User Name",
      "rankExpireAt": "Rank Expire At",
      "customerRankCode": "User Rank Code",
      "sex": "Sex",
      "phone": "Phone",
      "userEmail": "Email",
      "zonePS": "Zone From PS",
      "userAgreementPics": "User Accept Receive Promotion",
      "userAgreementTerms": "User Accept Agreement Terms",
    },
    "filter": {
      "orderCompletedAt": {
        "startDate": "Completed At From Date",
        "to": "to",
        "endDate": "Completed At End Date"
      },
      "membershipCode": "Membership Code",
      "email": "Email",
      "phone": "Phone",
      "userName": "User Name",
      "zoneFromPS": "Zone From PS",
      "storeCode": "Store Code",
      "userAgreementPics": "User Accept Receive Promotion",
      "userAgreementTerms": "User Accept Agreement Terms"
    },
  },
}

export default commonEnHK