const commonEnHK = {
  currentInventoryBalancePage: {
    name: "Current Inventory Balance",
    filter: {
      propertyAc: {
        name: "Property Account",
        all: "ALL"
      },
      storeCode: {
        name: "Store Code",
        all: "ALL"
      }
    },
    tableHeader: {
      propertyAc: "Property Acccount",
      skuCode: "SKU Code",
      upcCode: "UPC Code",
      skuInternalUseTitle: "SKU Internal Use Title",
      storeCode: "Store Code",
      availableForSale: "Available For Sale",
      instock: "Instock",
      holdCount: "Hold Count",
      available: "Available",
      cost: "Cost",
      inventoryCost: "Inventory Cost"
    }
  },
  supplierSummaryPage: {
    filter: {
      propertyAc: {
        name: "Property Account",
        all: "ALL"
      },
      storeCode: {
        name: "Store Code",
        all: "ALL"
      },
      orderCompletedAt: {
        "startDate": "Order Completed At From Date",
        "to": "to",
        "endDate": "Order Completed At End Date"
      }
    },
    titleMapping: {
      totalSkuQuantity: "Total Quantity",
      totalGrossSales: "Total Gross Sales",
      totalDiscount: "Total Discount",
      totalOrderAmount: "Total Net Sales",
    },
    orderQuantityBreakdownByPropertyAC: {
      name: "Order Quantity Breakdown",
      tableHeader: {
        propertyAc: "Property Acccount",
        referenceNumber: "Reference Number",
        shipFromStoreCode: "Store Code",
        skuCode: "SKU Code",
        upcCode: "UPC Code",
        skuInternalUseTitle: "SKU Internal Use Title",
        completedAt: "Order Completed At",
        txHour: "Transaction Time (Hour)",
        weekday: "Transaction Time (Week Day)",
        txDow: "Transaction Time (Day of Week)",
        unitAmount: "Unit Amount",
        quantity: "Quantity",
        orderAmount: "Order Amount",
      }
    },
    orderQuantityBreakdownByPropertyACSummary: {
      name: "Order Quantity Summary",
      tableHeader: {
        skuCode: "SKU Code",
        upcCode: "UPC Code",
        shipFromStoreCode: "Store Code",
        totalQuantity: "Total Quantity",
        totalOrderAmount: "Total Order Amount",
      }
    },
    discountQuantityBreakdownByPropertyAC: {
      name: "Discount Quantity Breakdown",
      tableHeader: {
        propertyAc: "Property Acccount",
        referenceNumber: "Reference Number",
        shipFromStoreCode: "Store Code",
        discountCode: "Discount Code",
        discountNameEn: "Discount Name (English)",
        discountNameZh: "Discount Name (Chinese)",
        orderTransactionType: "Discount Type",
        completedAt: "Order Completed At",
        txHour: "Transaction Time (Hour)",
        weekday: "Transaction Time (Week Day)",
        txDow: "Transaction Time (Day of Week)",
        unitAmount: "Unit Amount",
        quantity: "Quantity",
        orderAmount: "Order Amount",
      }
    },
    discountQuantityBreakdownByPropertyACSummary: {
      name: "Discount Quantity Summary",
      tableHeader: {
        discountCode: "Discount Code",
        discountNameEn: "Discount Name (English)",
        discountNameZh: "Discount Name (Chinese)",
        orderTransactionType: "Discount Type",
        shipFromStoreCode: "Store Code",
        totalQuantity: "Total Quantity",
        totalOrderAmount: "Total Order Amount",
      }
    },
    skuUploadTemplate: {
      name: "SKU Upload Template",
      tableHeader: {
        propertyAc: "skus[meta][property_ac](json)",
        productCode: "product[code]",
        skuCode: "sku[code]",
        upcCode: "sku[upc_code]",
        skuCommerceChannelCodes: "sku[commerce_channel_codes]",
        sizeOptionCode: "size_option[code]",
        sizeMasterCode: "size_option[size_master_code]",
        sizeOptionDisplaySequence: "size_option[display_sequence]",
        sizeOptionNameEn: "size_option[name]:en-HK",
        sizeOptionNameZn: "size_option[name]:zh-HK",
        sizeOptionState: "size_option[state]",
        colorOptionHexValue: "color_option[hex_value]",
        colorOptionColorCode: "color_option[color_code]",
        colorMasterCode: "color_option[color_master_code]",
        colorOptionNameEn: "color_option[name]:en-HK",
        colorOptionNameZh: "color_option[name]:zh-HK",
        colorOptionState: "color_option[state]",
        productType: "product[product_type]",
        productStatus: "product[status]",
        skuInternalUseTitle: "sku[internal_use_title]",
        productTitleEn: "product[title]:en-HK",
        productTitleZh: "product[title]:zh-HK",
        categoriesCode: "categories[1][code]",
      }
    },
    stockMovement: {
      name: "Stock Movement",
      tableHeader: {
        transactionTimestamp: "Transaction Timestamp",
        propertyAc: "Property Acccount",
        skuCode: "SKU Code",
        upcCode: "UPC Code",
        productCode: "Product Code",
        storeCode: "Store Code",
        txNumber: "Transction Number",
        txType: "Transaction Type",
        quantity: "quantity"
      }
    }
  },
  dailyOperation: {
    titleMapping: {
      orderCount: "Order Count",
      orderQuantity: "Order Quantity",
      netSales: "Net Sales",
      grossSales: "GrossSales",
      dpGrossSales: "DP Gross Sales",
      dpTotalQuantity: "DP Quantity",
      dpDiscount: "DP Discount",
      dpNetSales: "DP Net Sales",
      dpPercentage: "DP Sales Percentage",
      giftGrossSales: "Gift Gross Sales",
      giftTotalQuantity: "Gift Quantity",
      giftDiscount: "Gift Discount",
      giftNetSales: "Gift Net Sales",
      giftPercentage: "Gift Sales Percentage",
      con1GrossSales: "Con1 Gross Sales",
      con1TotalQuantity: "Con1 Quantity",
      con1Discount: "Con1 Discount",
      con1NetSales: "Con1 Net Sales",
      con1Percentage: "Con1 Sales Percentage",
      con2GrossSales: "Con2 Gross Sales",
      con2TotalQuantity: "Con2 Quantity",
      con2Discount: "Con2 Discount",
      con2NetSales: "Con2 Net Sales",
      con2Percentage: "Con2 Sales Percentage",
      rec1GrossSales: "Rec1 Gross Sales",
      rec1TotalQuantity: "Rec1 Quantity",
      rec1Discount: "Rec1 Discount",
      rec1NetSales: "Rec1 Net Sales",
      rec1Percentage: "Rec1 Sales Percentage",
      t2GrossSales: "T2 Gross Sales",
      t2TotalQuantity: "T2 Quantity",
      t2Discount: "T2 Discount",
      t2NetSales: "T2 Net Sales",
      t2Percentage: "T2 Sales Percentage",
      otherGrossSales: "Other Gross Sales",
      otherTotalQuantity: "Other Quantity",
      otherDiscount: "Other Discount",
      otherNetSales: "Other Net Sales",
      otherPercentage: "Other Sales Percentage",
      totalGrossSalesChart: "Total Net Sales Chart",
      totalRefund: "Total Refund",
      totalRefundAmount: "Total Refund Amount",
      netSalesDeductRefund: "Net Sales (Deducted Refund)"
    },
    totalGrossSalesChart:{
      labels: {
        OrderDayEndFS: {
          dpPercentage: "DP",
          giftPercentage: "Gift",
          con1Percentage: "Con1",
          con2Percentage: "Con2",
          rec1Percentage: "Rec1",
          t2Percentage: "T2",
          otherPercentage: "Other"
        }
      }
    },
    orderRefundSummaryDetail: {
      name: "Order Refund Summary Detail",
      tableHeader: {
        id: "ID",
        shipmentStoreCode: "Store Code",
        commerceChannelCode: "Commerce Channel",
        orderId: "Order ID",
        referenceNumber: "Reference Number",
        refundAmount: "Refund Amount",
        refundedAt: "Refunded At",
        refundState: "Refund State"
      }
    },
    filter: {
      storeCode: {
        name: "Store Code",
        all: "ALL"
      },
      orderConfirmedAt: {
        "startDate": "Order Confirmed At From Date",
        "to": "to",
        "endDate": "Order Confirmed At End Date"
      }
    },
  }
}

export default commonEnHK