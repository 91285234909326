import React, { useState, useContext } from "react";
import Header from '../../components/Header';
import TableComponent from '../../components/TableComponent';
import MultiSelectComponent from '../../components/MultiSelectComponent';
import DatePickerComponent from '../../components/DatePickerComponent';
import Dashboard from '../../components/Dashboard';
import PageHeaderComponent from '../../components/PageHeaderComponent';
import { CubeJSContext } from '../../context/CubeJSProvider';
import OmnitechQueries from '../../queries/OmnitechQueries'
import { useTranslation } from 'react-i18next';
import { useCubeQuery } from '@cubejs-client/react';
import { format } from 'date-fns'

const applyFilter = (query, storeFilter, storeFilterMember, startHour, endHour, languageFilter) => {
  let storeCodeFilterObj = {
    member: storeFilterMember,
    operator: "contains",
    values: storeFilter
  }

  query = {
    ...query,
    filters: (query.filters || []).concat(storeCodeFilterObj)
  }

  if(startHour && endHour){
    if(startHour > endHour){
      let tmp = endHour
      endHour = startHour
      startHour = tmp
    }
    let offset = (new Date().getTimezoneOffset())/60
    let startHourOver = false
    let endHourOver = false
    if(offset < 0){
      if(startHour + offset < 0){
        startHour = startHour + offset + 24
        startHourOver = true
      }
      else
        startHour = startHour + offset
      if(endHour + offset < 0){
        endHour = endHour + offset + 24
        endHourOver = true
      }
      else
        endHour = endHour + offset
    }
    else if(offset > 0){
      if(startHour + offset > 23){
        startHour = startHour + offset - 24
        startHourOver = true
      }
      else
        startHour = startHour + offset
      if(endHour + offset > 23){
        endHour = endHour + offset - 24
        endHourOver = true
      }
      else
        endHour = endHour + offset
    }
  
    let HourFilterObj={}
    if((startHourOver && endHourOver) || (!startHourOver && !endHourOver)){
      HourFilterObj = {
        and: [
          {
            member: "TimePeriodSummary.cartsCreatedAtHour",
            operator: "gte",
            values: [startHour.toString()]
          },
          {
            member: "TimePeriodSummary.cartsCreatedAtHour",
            operator: "lte",
            values: [endHour.toString()]
          }
        ]
      }
    }
    else{
      HourFilterObj = {
        or: [
          {
            and: [
              {
                member: "TimePeriodSummary.cartsCreatedAtHour",
                operator: "gte",
                values: [startHour.toString()]
              },
              {
                member: "TimePeriodSummary.cartsCreatedAtHour",
                operator: "lte",
                values: ["24"]
              }
            ]
          },
          {
            and: [
              {
                member: "TimePeriodSummary.cartsCreatedAtHour",
                operator: "gte",
                values: ["0"]
              },
              {
                member: "TimePeriodSummary.cartsCreatedAtHour",
                operator: "lte",
                values: [endHour.toString()]
              }
            ]
          }
        ]
      }
    }
    query = {
      ...query,
      filters: (query.filters || []).concat(HourFilterObj)
    }
  }

  let locateFilterObj = {
    member: "OrderTransactionsDiscount.discountLocale",
    operator: "equals",
    values: [languageFilter]
  }
  if (languageFilter){
    query = {
      ...query,
      filters: (query.filters || []).concat(locateFilterObj)
    }
  }

  return query
}

const TimePeriodSummaryBreakfastDineInQuery = (storeFilter, startDateFilter, endDateFilter, start, end, isRefresh, setRefresh) => {
  const { t } = useTranslation();
  if(startDateFilter == null){
    startDateFilter = new Date()
    startDateFilter.setHours(0,0,0,0);
    endDateFilter = new Date()
    endDateFilter.setHours(23,59,59,999);
  }
  let item = {
    name: t('page.fnb.timePeriodSummaryPage.timePeriodSummaryBreakfastDineIn.name'),
    tableHeaders: {
      "TimePeriodSummary.count": { name: t('page.fnb.timePeriodSummaryPage.timePeriodSummaryBreakfastDineIn.tableHeader.count'), type: 'number'},
      "TimePeriodSummary.sumDineInNumberOfPeople": { name: t('page.fnb.timePeriodSummaryPage.timePeriodSummaryBreakfastDineIn.tableHeader.sumDineInNumberOfPeople'), type: 'number'},
      "TimePeriodSummary.avgBillAmount": { name: t('page.fnb.timePeriodSummaryPage.timePeriodSummaryBreakfastDineIn.tableHeader.avgBillAmount'), type: 'number'},
      "TimePeriodSummary.sumOrderTotal": { name: t('page.fnb.timePeriodSummaryPage.timePeriodSummaryBreakfastDineIn.tableHeader.sumNetSales'), type: 'number'},
      "TimePeriodSummary.perPersonSpending": { name: t('page.fnb.timePeriodSummaryPage.timePeriodSummaryBreakfastDineIn.tableHeader.perPersonSpending'), type: 'number'}
    },
    grid: {sm: 12, md: 6},
    export: { allowExport: true, filename: 'time_period_summary_breakfast_export_' + format(startDateFilter, 'yyyyMMddHHmmss') +
      '_' + format(endDateFilter, 'yyyyMMddHHmmss') }
  }
  let query = {
    ...OmnitechQueries.timePeriodSummary.TimePeriodSummaryDineIn.query,
    "timeDimensions": [
      {
        "dimension": "TimePeriodSummary.cartsCreatedAt",
        "dateRange": [ startDateFilter, endDateFilter ]
      }
    ],
  }
  query = applyFilter(query, storeFilter, "TimePeriodSummary.shipFromStoreCode", start, end, null)
  if(isRefresh)
    query = {}
  const result = useCubeQuery(query, {resetResultSetOnChange: true})
  if(isRefresh)
    setRefresh(false)
  return { item, result }
}

const TimePeriodSummaryLunchDineInQuery = (storeFilter, startDateFilter, endDateFilter, start, end, isRefresh, setRefresh) => {
  const { t } = useTranslation();
  if(startDateFilter == null){
    startDateFilter = new Date()
    startDateFilter.setHours(0,0,0,0);
    endDateFilter = new Date()
    endDateFilter.setHours(23,59,59,999);
  }
  let item = {
    name: t('page.fnb.timePeriodSummaryPage.timePeriodSummaryLunchDineIn.name'),
    tableHeaders: {
      "TimePeriodSummary.count": { name: t('page.fnb.timePeriodSummaryPage.timePeriodSummaryLunchDineIn.tableHeader.count'), type: 'number'},
      "TimePeriodSummary.sumDineInNumberOfPeople": { name: t('page.fnb.timePeriodSummaryPage.timePeriodSummaryLunchDineIn.tableHeader.sumDineInNumberOfPeople'), type: 'number'},
      "TimePeriodSummary.avgBillAmount": { name: t('page.fnb.timePeriodSummaryPage.timePeriodSummaryLunchDineIn.tableHeader.avgBillAmount'), type: 'number'},
      "TimePeriodSummary.sumOrderTotal": { name: t('page.fnb.timePeriodSummaryPage.timePeriodSummaryLunchDineIn.tableHeader.sumNetSales'), type: 'number'},
      "TimePeriodSummary.perPersonSpending": { name: t('page.fnb.timePeriodSummaryPage.timePeriodSummaryLunchDineIn.tableHeader.perPersonSpending'), type: 'number'}
    },
    grid: {sm: 12, md: 6},
    export: { allowExport: true, filename: 'time_period_summary_lunch_export_' + format(startDateFilter, 'yyyyMMddHHmmss') +
      '_' + format(endDateFilter, 'yyyyMMddHHmmss') }
  }
  let query = {
    ...OmnitechQueries.timePeriodSummary.TimePeriodSummaryDineIn.query,
    "timeDimensions": [
      {
        "dimension": "TimePeriodSummary.cartsCreatedAt",
        "dateRange": [ startDateFilter, endDateFilter ]
      }
    ],
  }
  query = applyFilter(query, storeFilter, "TimePeriodSummary.shipFromStoreCode", start, end, null)
  if(isRefresh)
    query = {}
  const result = useCubeQuery(query, {resetResultSetOnChange: true})
  if(isRefresh)
    setRefresh(false)
  return { item, result }
}

const TimePeriodSummaryTeaDineInQuery = (storeFilter, startDateFilter, endDateFilter, start, end, isRefresh, setRefresh) => {
  const { t } = useTranslation();
  if(startDateFilter == null){
    startDateFilter = new Date()
    startDateFilter.setHours(0,0,0,0);
    endDateFilter = new Date()
    endDateFilter.setHours(23,59,59,999);
  }
  let item = {
    name: t('page.fnb.timePeriodSummaryPage.timePeriodSummaryTeaDineIn.name'),
    tableHeaders: {
      "TimePeriodSummary.count": { name: t('page.fnb.timePeriodSummaryPage.timePeriodSummaryTeaDineIn.tableHeader.count'), type: 'number'},
      "TimePeriodSummary.sumDineInNumberOfPeople": { name: t('page.fnb.timePeriodSummaryPage.timePeriodSummaryTeaDineIn.tableHeader.sumDineInNumberOfPeople'), type: 'number'},
      "TimePeriodSummary.avgBillAmount": { name: t('page.fnb.timePeriodSummaryPage.timePeriodSummaryTeaDineIn.tableHeader.avgBillAmount'), type: 'number'},
      "TimePeriodSummary.sumOrderTotal": { name: t('page.fnb.timePeriodSummaryPage.timePeriodSummaryTeaDineIn.tableHeader.sumNetSales'), type: 'number'},
      "TimePeriodSummary.perPersonSpending": { name: t('page.fnb.timePeriodSummaryPage.timePeriodSummaryTeaDineIn.tableHeader.perPersonSpending'), type: 'number'}
    },
    grid: {sm: 12, md: 6},
    export: { allowExport: true, filename: 'time_period_summary_tea_export_' + format(startDateFilter, 'yyyyMMddHHmmss') +
      '_' + format(endDateFilter, 'yyyyMMddHHmmss') }
  }
  let query = {
    ...OmnitechQueries.timePeriodSummary.TimePeriodSummaryDineIn.query,
    "timeDimensions": [
      {
        "dimension": "TimePeriodSummary.cartsCreatedAt",
        "dateRange": [ startDateFilter, endDateFilter ]
      }
    ],
  }
  query = applyFilter(query, storeFilter, "TimePeriodSummary.shipFromStoreCode", start, end, null)
  if(isRefresh)
    query = {}
  const result = useCubeQuery(query, {resetResultSetOnChange: true})
  if(isRefresh)
    setRefresh(false)
  return { item, result }
}

const TimePeriodSummaryDinnerDineInQuery = (storeFilter, startDateFilter, endDateFilter, start, end, isRefresh, setRefresh) => {
  const { t } = useTranslation();
  if(startDateFilter == null){
    startDateFilter = new Date()
    startDateFilter.setHours(0,0,0,0);
    endDateFilter = new Date()
    endDateFilter.setHours(23,59,59,999);
  }
  let item = {
    name: t('page.fnb.timePeriodSummaryPage.timePeriodSummaryDinnerDineIn.name'),
    tableHeaders: {
      "TimePeriodSummary.count": { name: t('page.fnb.timePeriodSummaryPage.timePeriodSummaryDinnerDineIn.tableHeader.count'), type: 'number'},
      "TimePeriodSummary.sumDineInNumberOfPeople": { name: t('page.fnb.timePeriodSummaryPage.timePeriodSummaryDinnerDineIn.tableHeader.sumDineInNumberOfPeople'), type: 'number'},
      "TimePeriodSummary.avgBillAmount": { name: t('page.fnb.timePeriodSummaryPage.timePeriodSummaryDinnerDineIn.tableHeader.avgBillAmount'), type: 'number'},
      "TimePeriodSummary.sumOrderTotal": { name: t('page.fnb.timePeriodSummaryPage.timePeriodSummaryDinnerDineIn.tableHeader.sumNetSales'), type: 'number'},
      "TimePeriodSummary.perPersonSpending": { name: t('page.fnb.timePeriodSummaryPage.timePeriodSummaryDinnerDineIn.tableHeader.perPersonSpending'), type: 'number'}
    },
    grid: {sm: 12, md: 6},
    export: { allowExport: true, filename: 'time_period_summary_dinner_export_' + format(startDateFilter, 'yyyyMMddHHmmss') +
      '_' + format(endDateFilter, 'yyyyMMddHHmmss') }
  }
  let query = {
    ...OmnitechQueries.timePeriodSummary.TimePeriodSummaryDineIn.query,
    "timeDimensions": [
      {
        "dimension": "TimePeriodSummary.cartsCreatedAt",
        "dateRange": [ startDateFilter, endDateFilter ]
      }
    ],
  }
  query = applyFilter(query, storeFilter, "TimePeriodSummary.shipFromStoreCode", start, end, null)
  if(isRefresh)
    query = {}
  const result = useCubeQuery(query, {resetResultSetOnChange: true})
  if(isRefresh)
    setRefresh(false)
  return { item, result }
}

const TimePeriodSummaryBreakfastTakeawayQuery = (storeFilter, startDateFilter, endDateFilter, start, end, isRefresh, setRefresh) => {
  const { t } = useTranslation();
  if(startDateFilter == null){
    startDateFilter = new Date()
    startDateFilter.setHours(0,0,0,0);
    endDateFilter = new Date()
    endDateFilter.setHours(23,59,59,999);
  }
  let item = {
    name: t('page.fnb.timePeriodSummaryPage.timePeriodSummaryBreakfastTakeaway.name'),
    tableHeaders: {
      "TimePeriodSummary.count": { name: t('page.fnb.timePeriodSummaryPage.timePeriodSummaryBreakfastTakeaway.tableHeader.count'), type: 'number'},
      "TimePeriodSummary.sumDineInNumberOfPeople": { name: t('page.fnb.timePeriodSummaryPage.timePeriodSummaryBreakfastTakeaway.tableHeader.sumDineInNumberOfPeople'), type: 'number'},
      "TimePeriodSummary.avgBillAmount": { name: t('page.fnb.timePeriodSummaryPage.timePeriodSummaryBreakfastTakeaway.tableHeader.avgBillAmount'), type: 'number'},
      "TimePeriodSummary.sumOrderTotal": { name: t('page.fnb.timePeriodSummaryPage.timePeriodSummaryBreakfastTakeaway.tableHeader.sumNetSales'), type: 'number'},
      "TimePeriodSummary.perPersonSpending": { name: t('page.fnb.timePeriodSummaryPage.timePeriodSummaryBreakfastTakeaway.tableHeader.perPersonSpending'), type: 'number'}
    },
    grid: {sm: 12, md: 6},
    export: { allowExport: true, filename: 'time_period_summary_breakfast_export_' + format(startDateFilter, 'yyyyMMddHHmmss') +
      '_' + format(endDateFilter, 'yyyyMMddHHmmss') }
  }
  let query = {
    ...OmnitechQueries.timePeriodSummary.TimePeriodSummaryTakeaway.query,
    "timeDimensions": [
      {
        "dimension": "TimePeriodSummary.cartsCreatedAt",
        "dateRange": [ startDateFilter, endDateFilter ]
      }
    ],
  }
  query = applyFilter(query, storeFilter, "TimePeriodSummary.shipFromStoreCode", start, end, null)
  if(isRefresh)
    query = {}
  const result = useCubeQuery(query, {resetResultSetOnChange: true})
  if(isRefresh)
    setRefresh(false)
  return { item, result }
}

const TimePeriodSummaryLunchTakeawayQuery = (storeFilter, startDateFilter, endDateFilter, start, end, isRefresh, setRefresh) => {
  const { t } = useTranslation();
  if(startDateFilter == null){
    startDateFilter = new Date()
    startDateFilter.setHours(0,0,0,0);
    endDateFilter = new Date()
    endDateFilter.setHours(23,59,59,999);
  }
  let item = {
    name: t('page.fnb.timePeriodSummaryPage.timePeriodSummaryLunchTakeaway.name'),
    tableHeaders: {
      "TimePeriodSummary.count": { name: t('page.fnb.timePeriodSummaryPage.timePeriodSummaryLunchTakeaway.tableHeader.count'), type: 'number'},
      "TimePeriodSummary.sumDineInNumberOfPeople": { name: t('page.fnb.timePeriodSummaryPage.timePeriodSummaryLunchTakeaway.tableHeader.sumDineInNumberOfPeople'), type: 'number'},
      "TimePeriodSummary.avgBillAmount": { name: t('page.fnb.timePeriodSummaryPage.timePeriodSummaryLunchTakeaway.tableHeader.avgBillAmount'), type: 'number'},
      "TimePeriodSummary.sumOrderTotal": { name: t('page.fnb.timePeriodSummaryPage.timePeriodSummaryLunchTakeaway.tableHeader.sumNetSales'), type: 'number'},
      "TimePeriodSummary.perPersonSpending": { name: t('page.fnb.timePeriodSummaryPage.timePeriodSummaryLunchTakeaway.tableHeader.perPersonSpending'), type: 'number'}
    },
    grid: {sm: 12, md: 6},
    export: { allowExport: true, filename: 'time_period_summary_lunch_export_' + format(startDateFilter, 'yyyyMMddHHmmss') +
      '_' + format(endDateFilter, 'yyyyMMddHHmmss') }
  }
  let query = {
    ...OmnitechQueries.timePeriodSummary.TimePeriodSummaryTakeaway.query,
    "timeDimensions": [
      {
        "dimension": "TimePeriodSummary.cartsCreatedAt",
        "dateRange": [ startDateFilter, endDateFilter ]
      }
    ],
  }
  query = applyFilter(query, storeFilter, "TimePeriodSummary.shipFromStoreCode", start, end, null)
  if(isRefresh)
    query = {}
  const result = useCubeQuery(query, {resetResultSetOnChange: true})
  if(isRefresh)
    setRefresh(false)
  return { item, result }
}

const TimePeriodSummaryTeaTakeawayQuery = (storeFilter, startDateFilter, endDateFilter, start, end, isRefresh, setRefresh) => {
  const { t } = useTranslation();
  if(startDateFilter == null){
    startDateFilter = new Date()
    startDateFilter.setHours(0,0,0,0);
    endDateFilter = new Date()
    endDateFilter.setHours(23,59,59,999);
  }
  let item = {
    name: t('page.fnb.timePeriodSummaryPage.timePeriodSummaryTeaTakeaway.name'),
    tableHeaders: {
      "TimePeriodSummary.count": { name: t('page.fnb.timePeriodSummaryPage.timePeriodSummaryTeaTakeaway.tableHeader.count'), type: 'number'},
      "TimePeriodSummary.sumDineInNumberOfPeople": { name: t('page.fnb.timePeriodSummaryPage.timePeriodSummaryTeaTakeaway.tableHeader.sumDineInNumberOfPeople'), type: 'number'},
      "TimePeriodSummary.avgBillAmount": { name: t('page.fnb.timePeriodSummaryPage.timePeriodSummaryTeaTakeaway.tableHeader.avgBillAmount'), type: 'number'},
      "TimePeriodSummary.sumOrderTotal": { name: t('page.fnb.timePeriodSummaryPage.timePeriodSummaryTeaTakeaway.tableHeader.sumNetSales'), type: 'number'},
      "TimePeriodSummary.perPersonSpending": { name: t('page.fnb.timePeriodSummaryPage.timePeriodSummaryTeaTakeaway.tableHeader.perPersonSpending'), type: 'number'}
    },
    grid: {sm: 12, md: 6},
    export: { allowExport: true, filename: 'time_period_summary_tea_export_' + format(startDateFilter, 'yyyyMMddHHmmss') +
      '_' + format(endDateFilter, 'yyyyMMddHHmmss') }
  }
  let query = {
    ...OmnitechQueries.timePeriodSummary.TimePeriodSummaryTakeaway.query,
    "timeDimensions": [
      {
        "dimension": "TimePeriodSummary.cartsCreatedAt",
        "dateRange": [ startDateFilter, endDateFilter ]
      }
    ],
  }
  query = applyFilter(query, storeFilter, "TimePeriodSummary.shipFromStoreCode", start, end, null)
  if(isRefresh)
    query = {}
  const result = useCubeQuery(query, {resetResultSetOnChange: true})
  if(isRefresh)
    setRefresh(false)
  return { item, result }
}

const TimePeriodSummaryDinnerTakeawayQuery = (storeFilter, startDateFilter, endDateFilter, start, end, isRefresh, setRefresh) => {
  const { t } = useTranslation();
  if(startDateFilter == null){
    startDateFilter = new Date()
    startDateFilter.setHours(0,0,0,0);
    endDateFilter = new Date()
    endDateFilter.setHours(23,59,59,999);
  }
  let item = {
    name: t('page.fnb.timePeriodSummaryPage.timePeriodSummaryDinnerTakeaway.name'),
    tableHeaders: {
      "TimePeriodSummary.count": { name: t('page.fnb.timePeriodSummaryPage.timePeriodSummaryDinnerTakeaway.tableHeader.count'), type: 'number'},
      "TimePeriodSummary.sumDineInNumberOfPeople": { name: t('page.fnb.timePeriodSummaryPage.timePeriodSummaryDinnerTakeaway.tableHeader.sumDineInNumberOfPeople'), type: 'number'},
      "TimePeriodSummary.avgBillAmount": { name: t('page.fnb.timePeriodSummaryPage.timePeriodSummaryDinnerTakeaway.tableHeader.avgBillAmount'), type: 'number'},
      "TimePeriodSummary.sumOrderTotal": { name: t('page.fnb.timePeriodSummaryPage.timePeriodSummaryDinnerTakeaway.tableHeader.sumNetSales'), type: 'number'},
      "TimePeriodSummary.perPersonSpending": { name: t('page.fnb.timePeriodSummaryPage.timePeriodSummaryDinnerTakeaway.tableHeader.perPersonSpending'), type: 'number'}
    },
    grid: {sm: 12, md: 6},
    export: { allowExport: true, filename: 'time_period_summary_dinner_export_' + format(startDateFilter, 'yyyyMMddHHmmss') +
      '_' + format(endDateFilter, 'yyyyMMddHHmmss') }
  }
  let query = {
    ...OmnitechQueries.timePeriodSummary.TimePeriodSummaryTakeaway.query,
    "timeDimensions": [
      {
        "dimension": "TimePeriodSummary.cartsCreatedAt",
        "dateRange": [ startDateFilter, endDateFilter ]
      }
    ],
  }
  query = applyFilter(query, storeFilter, "TimePeriodSummary.shipFromStoreCode", start, end, null)
  if(isRefresh)
    query = {}
  const result = useCubeQuery(query, {resetResultSetOnChange: true})
  if(isRefresh)
    setRefresh(false)
  return { item, result }
}


const TimePeriodSummaryDetailQuery = (storeFilter, startDateFilter, endDateFilter, isRefresh, setRefresh) => {
  const { t } = useTranslation();
  if(startDateFilter == null){
    startDateFilter = new Date()
    startDateFilter.setHours(0,0,0,0);
    endDateFilter = new Date()
    endDateFilter.setHours(23,59,59,999);
  }

  let startDateString = new Date(startDateFilter.getTime() - (startDateFilter.getTimezoneOffset() * 60000 ))
                    .toISOString()
                    .split("T")[0];
  let endDateString = new Date(endDateFilter.getTime() - (endDateFilter.getTimezoneOffset() * 60000 ))
                    .toISOString()
                    .split("T")[0];

  let item = {
    name: t('page.fnb.timePeriodSummaryPage.timePeriodSummaryDetail.name'),
    tableHeaders: {
      "TimePeriodSummary.shipFromStoreCode": { name: t('page.fnb.timePeriodSummaryPage.timePeriodSummaryDetail.tableHeader.shipFromStoreCode'), type: 'string'},
      "TimePeriodSummary.commerceChannelCode": { name: t('page.fnb.timePeriodSummaryHistPage.timePeriodSummaryDetail.tableHeader.commerceChannelCode'), type: 'strinng'},
      "TimePeriodSummary.cartsCreatedAtHour": { name: t('page.fnb.timePeriodSummaryPage.timePeriodSummaryDetail.tableHeader.cartsCreatedAtHour'), 
        type: 'offsetNumber', offset: (new Date().getTimezoneOffset())/60},
      "TimePeriodSummary.cartsCreatedAt.hour": { name: t('page.fnb.timePeriodSummaryPage.timePeriodSummaryDetail.tableHeader.cartsCreatedAtHour'), 
        type: 'hkHour'},
      "TimePeriodSummary.orderSource": { name: t('page.fnb.timePeriodSummaryPage.timePeriodSummaryDetail.tableHeader.orderSource'), type: 'strinng'},
      "TimePeriodSummary.sumGrossSales": { name: t('page.fnb.timePeriodSummaryPage.timePeriodSummaryDetail.tableHeader.sumGrossSales'), type: 'number'},
      "TimePeriodSummary.sumDiscounts": { name: t('page.fnb.timePeriodSummaryPage.timePeriodSummaryDetail.tableHeader.sumDiscounts'), type: 'number'},
      "TimePeriodSummary.sumShippingFees": { name: t('page.fnb.timePeriodSummaryPage.timePeriodSummaryDetail.tableHeader.sumShippingFees'), type: 'number'},
      "TimePeriodSummary.sumServiceCharges": { name: t('page.fnb.timePeriodSummaryPage.timePeriodSummaryDetail.tableHeader.sumServiceCharges'), type: 'number'},
      "TimePeriodSummary.sumOrderAmountRounding": { name: t('page.fnb.timePeriodSummaryPage.timePeriodSummaryDetail.tableHeader.sumOrderAmountRounding'), type: 'number'},
      "TimePeriodSummary.count": { name: t('page.fnb.timePeriodSummaryPage.timePeriodSummaryDetail.tableHeader.count'), type: 'number'},
      "TimePeriodSummary.sumDineInNumberOfPeople": { name: t('page.fnb.timePeriodSummaryPage.timePeriodSummaryDetail.tableHeader.sumDineInNumberOfPeople'), type: 'number'},
      "TimePeriodSummary.avgBillAmount": { name: t('page.fnb.timePeriodSummaryPage.timePeriodSummaryDetail.tableHeader.avgBillAmount'), type: 'number'},
      "TimePeriodSummary.sumOrderTotal": { name: t('page.fnb.timePeriodSummaryPage.timePeriodSummaryDetail.tableHeader.sumOrderTotal'), type: 'number'},
      "TimePeriodSummary.perPersonSpending": { name: t('page.fnb.timePeriodSummaryPage.timePeriodSummaryDetail.tableHeader.perPersonSpending'), type: 'number'}
    },
    grid: {xs: 12},
    export: { allowExport: true, filename: 'time_period_summary_detail_export_' + format(startDateFilter, 'yyyyMMddHHmmss') +
      '_' + format(endDateFilter, 'yyyyMMddHHmmss') }
  }
  let query = {
    ...OmnitechQueries.timePeriodSummary.TimePeriodSummaryDetail.query,
    "timeDimensions": [
      {
        "dimension": "TimePeriodSummary.cartsCreatedAt",
        "granularity": 'hour',
        "dateRange": [ startDateString + " 00:00:00",
         endDateString + " 23:59:59" ]
      }
    ],
    "timezone": "Asia/Hong_Kong",
  }
  query = applyFilter(query, storeFilter, "TimePeriodSummary.shipFromStoreCode", null, null, null)
  if(isRefresh)
    query = {}
  const result = useCubeQuery(query, {resetResultSetOnChange: true})
  if(isRefresh)
    setRefresh(false)
  return { item, result }
}

const FnBTotalAndDiscountBreakDownQuery = (storeFilter, startDateFilter, endDateFilter, isRefresh, setRefresh) => {
  const { t } = useTranslation();
  if(startDateFilter == null){
    startDateFilter = new Date()
    startDateFilter.setHours(0,0,0,0);
    endDateFilter = new Date()
    endDateFilter.setHours(23,59,59,999);
  }

  let startDateString = new Date(startDateFilter.getTime() - (startDateFilter.getTimezoneOffset() * 60000 ))
                    .toISOString()
                    .split("T")[0];
  let endDateString = new Date(endDateFilter.getTime() - (endDateFilter.getTimezoneOffset() * 60000 ))
                    .toISOString()
                    .split("T")[0];

  let item = {
    name: t('page.fnb.timePeriodSummaryPage.FnBTotalAndDiscountBreakDown.name'),
    tableHeaders: {
      "FnBTotalAndDiscountBreakDown.storeCode": { name: t('page.fnb.timePeriodSummaryPage.FnBTotalAndDiscountBreakDown.tableHeader.storeCode'), type: 'string'},
      "FnBTotalAndDiscountBreakDown.openTableTime.hour": { name: t('page.fnb.timePeriodSummaryPage.FnBTotalAndDiscountBreakDown.tableHeader.openTableTime'), 
        type: 'hkHour'},
      "FnBTotalAndDiscountBreakDown.dineInTableNumber": { name: t('page.fnb.timePeriodSummaryPage.FnBTotalAndDiscountBreakDown.tableHeader.dineInTableNumber'), type: 'string'},
      "FnBTotalAndDiscountBreakDown.serviceMode": { name: t('page.fnb.timePeriodSummaryPage.FnBTotalAndDiscountBreakDown.tableHeader.serviceMode'), type: 'string'},
      "FnBTotalAndDiscountBreakDown.commerceChannelCode": { name: t('page.fnb.timePeriodSummaryPage.FnBTotalAndDiscountBreakDown.tableHeader.commerceChannelCode'), type: 'string'},
      "FnBTotalAndDiscountBreakDown.orderGrossTotal": { name: t('page.fnb.timePeriodSummaryPage.FnBTotalAndDiscountBreakDown.tableHeader.orderGrossTotal'), type: 'number'},
      "FnBTotalAndDiscountBreakDown.foodGrossTotal": { name: t('page.fnb.timePeriodSummaryPage.FnBTotalAndDiscountBreakDown.tableHeader.foodGrossTotal'), type: 'number'},
      "FnBTotalAndDiscountBreakDown.bvGrossTotal": { name: t('page.fnb.timePeriodSummaryPage.FnBTotalAndDiscountBreakDown.tableHeader.bvGrossTotal'), type: 'number'},
      "FnBTotalAndDiscountBreakDown.rounding": { name: t('page.fnb.timePeriodSummaryPage.FnBTotalAndDiscountBreakDown.tableHeader.rounding'), type: 'number'},
      "FnBTotalAndDiscountBreakDown.serviceCharge": { name: t('page.fnb.timePeriodSummaryPage.FnBTotalAndDiscountBreakDown.tableHeader.serviceCharge'), type: 'number'},
      "FnBTotalAndDiscountBreakDown.discountTotal": { name: t('page.fnb.timePeriodSummaryPage.FnBTotalAndDiscountBreakDown.tableHeader.discountTotal'), type: 'number'},
      "FnBTotalAndDiscountBreakDown.allotedFoodDiscount": { name: t('page.fnb.timePeriodSummaryPage.FnBTotalAndDiscountBreakDown.tableHeader.allotedFoodDiscount'), type: 'number'},
      "FnBTotalAndDiscountBreakDown.allotedBvDiscount": { name: t('page.fnb.timePeriodSummaryPage.FnBTotalAndDiscountBreakDown.tableHeader.allotedBvDiscount'), type: 'number'},
      "FnBTotalAndDiscountBreakDown.unallottableDiscount": { name: t('page.fnb.timePeriodSummaryPage.FnBTotalAndDiscountBreakDown.tableHeader.unallottableDiscount'), type: 'number'},
      "FnBTotalAndDiscountBreakDown.orderNetTotal": { name: t('page.fnb.timePeriodSummaryPage.FnBTotalAndDiscountBreakDown.tableHeader.orderNetTotal'), type: 'number'},
    },
    grid: {xs: 12},
    export: { allowExport: true, filename: 'fnb_total_and_discount_breakdown_export_' + format(startDateFilter, 'yyyyMMddHHmmss') +
      '_' + format(endDateFilter, 'yyyyMMddHHmmss') }
  }
  let query = {
    ...OmnitechQueries.orders.FnBTotalAndDiscountBreakDown.query,
    "timeDimensions": [
      {
        "dimension": "FnBTotalAndDiscountBreakDown.openTableTime",
        "granularity": 'hour',
        "dateRange": [ startDateString + " 00:00:00",
         endDateString + " 23:59:59" ]
      }
    ],
    "timezone": "Asia/Hong_Kong",
  }
  query = applyFilter(query, storeFilter, "FnBTotalAndDiscountBreakDown.storeCode", null, null, null)
  if(isRefresh)
    query = {}
  const result = useCubeQuery(query, {resetResultSetOnChange: true})
  if(isRefresh)
    setRefresh(false)
  return { item, result }
}

const DiscountBreakdownByStaffQuery = (storeFilter, startDateFilter, endDateFilter, languageFilter, isRefresh, setRefresh) => {
  const { t } = useTranslation();
  if(startDateFilter == null){
    startDateFilter = new Date()
    startDateFilter.setHours(0,0,0,0);
    endDateFilter = new Date()
    endDateFilter.setHours(23,59,59,999);
  }
  let item = {
    name: t('page.fnb.timePeriodSummaryPage.discountBreakdownByStaff.name'),
    tableHeaders: {
      "OrderTransactionsDiscount.storeCode": { name: t('page.fnb.timePeriodSummaryPage.discountBreakdownByStaff.tableHeader.shipFromStoreCode'), type: 'string'},
      "InternalAppOperators.staffNumber": { name: t('page.fnb.timePeriodSummaryPage.discountBreakdownByStaff.tableHeader.staffNumber'), type: 'string'},
      "InternalAppOperators.firstName": { name: t('page.fnb.timePeriodSummaryPage.discountBreakdownByStaff.tableHeader.firstName'), type: 'string'},
      "InternalAppOperators.lastName": { name: t('page.fnb.timePeriodSummaryPage.discountBreakdownByStaff.tableHeader.lastName'), type: 'string'},
      "OrderTransactionsDiscount.discountCode": { name: t('page.fnb.timePeriodSummaryPage.discountBreakdownByStaff.tableHeader.discountCode'), type: 'string'},
      "OrderTransactionsDiscount.discountName": { name: t('page.fnb.timePeriodSummaryPage.discountBreakdownByStaff.tableHeader.discountName'), type: 'string'},
      "OrderTransactionsDiscount.totalAmount": { name: t('page.fnb.timePeriodSummaryPage.discountBreakdownByStaff.tableHeader.lineTotal'), type: 'number'},
      "OrderTransactionsDiscount.sumQuantity": { name: t('page.fnb.timePeriodSummaryPage.discountBreakdownByStaff.tableHeader.quantity'), type: 'number'},
    },
    grid: {xs: 12},
    export: { allowExport: true, filename: 'discount_breakdown_by_staff_export_' + format(startDateFilter, 'yyyyMMddHHmmss') +
      '_' + format(endDateFilter, 'yyyyMMddHHmmss') }
  }
  let query = {
    ...OmnitechQueries.orderTransactionsDiscount.DiscountBreakdownByStaff.query,
    "timeDimensions": [
      {
        "dimension": "OrderTransactionsDiscount.orderCompletedAt",
        "dateRange": [ startDateFilter, endDateFilter ]
      }
    ],
  }
  query = applyFilter(query, storeFilter, "OrderTransactionsDiscount.storeCode", null, null, languageFilter)
  if(isRefresh)
    query = {}
  const result = useCubeQuery(query, {resetResultSetOnChange: true})
  if(isRefresh)
    setRefresh(false)
  return { item, result }
}

const TimePeriodSummaryPage = () => {
  const { t, i18n } = useTranslation();
  let languageFilter = i18n.language
  if(languageFilter !== 'en-HK' && languageFilter !== 'zh-HK')
    languageFilter = 'en-HK'
  const context = useContext(CubeJSContext);
  let breakfastStart = context.breakfastStart ? context.breakfastStart : 6
  let breakfastEnd = context.breakfastEnd ? context.breakfastEnd : 10
  let lunchStart = context.lunchStart ? context.lunchStart : 11
  let lunchEnd = context.lunchEnd ? context.lunchEnd : 14
  let teaStart = context.teaStart ? context.teaStart : 15
  let teaEnd = context.teaEnd ? context.teaEnd : 17
  let dinnerStart = context.dinnerStart ? context.dinnerStart : 18
  let dinnerEnd = context.dinnerEnd ? context.dinnerEnd : 23
  let storeCodeSelect = []
  if(context.storeCodes)
    storeCodeSelect = context.storeCodes
  const [ storeFilter, setStoreFilter ] = useState([]);
  let filterStartDate = new Date()
  filterStartDate.setHours(0,0,0,0);
  let filterEndDate = new Date()
  filterEndDate.setHours(23,59,59,999);
  const [dateRangeFilter, setDateRangeFilter] = useState([filterStartDate, filterEndDate])
  const [startDateFilter, endDateFilter] = dateRangeFilter
  const [rowsPerPage1, setRowsPerPage1] = useState(10);
  const [page1, setPage1] = useState(0);
  const [rowsPerPage2, setRowsPerPage2] = useState(10);
  const [page2, setPage2] = useState(0);
  const [rowsPerPage3, setRowsPerPage3] = useState(10);
  const [page3, setPage3] = useState(0);
  const [rowsPerPage4, setRowsPerPage4] = useState(10);
  const [page4, setPage4] = useState(0);
  const [rowsPerPage5, setRowsPerPage5] = useState(10);
  const [page5, setPage5] = useState(0);
  const [rowsPerPage6, setRowsPerPage6] = useState(10);
  const [page6, setPage6] = useState(0);

  const [rowsPerPage7, setRowsPerPage7] = useState(10);
  const [page7, setPage7] = useState(0);
  const [rowsPerPage8, setRowsPerPage8] = useState(10);
  const [page8, setPage8] = useState(0);
  const [rowsPerPage9, setRowsPerPage9] = useState(10);
  const [page9, setPage9] = useState(0);
  const [rowsPerPage10, setRowsPerPage10] = useState(10);
  const [page10, setPage10] = useState(0);
  const [rowsPerPage11, setRowsPerPage11] = useState(10);
  const [page11, setPage11] = useState(0);

  const [ refresh1, setRefresh1 ] = useState(false);
  const [ refresh2, setRefresh2 ] = useState(false);
  const [ refresh3, setRefresh3 ] = useState(false);
  const [ refresh4, setRefresh4 ] = useState(false);
  const [ refresh5, setRefresh5 ] = useState(false);
  const [ refresh6, setRefresh6 ] = useState(false);
  const [ refresh7, setRefresh7 ] = useState(false);
  const [ refresh8, setRefresh8 ] = useState(false);
  const [ refresh9, setRefresh9 ] = useState(false);
  const [ refresh10, setRefresh10 ] = useState(false);
  const [ refresh11, setRefresh11 ] = useState(false);

  const query1 = TimePeriodSummaryBreakfastDineInQuery(storeFilter, startDateFilter, endDateFilter, breakfastStart, breakfastEnd, refresh1, setRefresh1)
  const query2 = TimePeriodSummaryLunchDineInQuery(storeFilter, startDateFilter, endDateFilter, lunchStart, lunchEnd, refresh2, setRefresh2)
  const query3 = TimePeriodSummaryTeaDineInQuery(storeFilter, startDateFilter, endDateFilter, teaStart, teaEnd, refresh3, setRefresh3)
  const query4 = TimePeriodSummaryDinnerDineInQuery(storeFilter, startDateFilter, endDateFilter, dinnerStart, dinnerEnd, refresh4, setRefresh4)
  const query5 = TimePeriodSummaryDetailQuery(storeFilter, startDateFilter, endDateFilter, refresh5, setRefresh5)
  const query6 = DiscountBreakdownByStaffQuery(storeFilter, startDateFilter, endDateFilter, languageFilter, refresh6, setRefresh6)
  const query7 = TimePeriodSummaryBreakfastTakeawayQuery(storeFilter, startDateFilter, endDateFilter, breakfastStart, breakfastEnd, refresh7, setRefresh7)
  const query8 = TimePeriodSummaryLunchTakeawayQuery(storeFilter, startDateFilter, endDateFilter, lunchStart, lunchEnd, refresh8, setRefresh8)
  const query9 = TimePeriodSummaryTeaTakeawayQuery(storeFilter, startDateFilter, endDateFilter, teaStart, teaEnd, refresh9, setRefresh9)
  const query10 = TimePeriodSummaryDinnerTakeawayQuery(storeFilter, startDateFilter, endDateFilter, dinnerStart, dinnerEnd, refresh10, setRefresh10)
  const query11 = FnBTotalAndDiscountBreakDownQuery(storeFilter, startDateFilter, endDateFilter, refresh11, setRefresh11)

  const refresh = () => {
    setRefresh1(true)
    setRefresh2(true)
    setRefresh3(true)
    setRefresh4(true)
    setRefresh5(true)
    setRefresh6(true)
    setRefresh7(true)
    setRefresh8(true)
    setRefresh9(true)
    setRefresh10(true)
    setRefresh11(true)
    setPage1(0)
    setPage2(0)
    setPage3(0)
    setPage4(0)
    setPage5(0)
    setPage6(0)
    setPage7(0)
    setPage8(0)
    setPage9(0)
    setPage10(0)
    setPage11(0)
  }

  return ([
    <Header />,
    <PageHeaderComponent onClickFunc={refresh} refreshlabel={t('component.refresh')} headerTitle={t('component.header.menu.timePeriodSummary')} />,
    <Dashboard>
      <MultiSelectComponent id={"store_code_filter"} state={storeFilter} pageChangerArr={[setPage1,setPage2,setPage3,setPage4,
        setPage5,setPage6,setPage7,setPage8,setPage9,setPage10,setPage11]}
        stateChanger={setStoreFilter} textLabel={t('page.fnb.timePeriodSummaryPage.filter.store.name')} selectArray={storeCodeSelect}/>
      <DatePickerComponent state={dateRangeFilter} stateChanger={setDateRangeFilter} pageChangerArr={[setPage1,setPage2,setPage3,setPage4,
        setPage5,setPage6,setPage7,setPage8,setPage9,setPage10,setPage11]} 
        dateLabel={t('page.fnb.timePeriodSummaryPage.filter.orderCompletedAt.date')}/>
    </Dashboard>,
    <Dashboard>
      <TableComponent item={query1.item} result={query1.result} rowsPerPage={rowsPerPage1} 
        setRowsPerPage={setRowsPerPage1} page={page1} setPage={setPage1}/>
      <TableComponent item={query2.item} result={query2.result} rowsPerPage={rowsPerPage2} 
        setRowsPerPage={setRowsPerPage2} page={page2} setPage={setPage2}/>
      <TableComponent item={query3.item} result={query3.result} rowsPerPage={rowsPerPage3} 
        setRowsPerPage={setRowsPerPage3} page={page3} setPage={setPage3}/>
      <TableComponent item={query4.item} result={query4.result} rowsPerPage={rowsPerPage4} 
        setRowsPerPage={setRowsPerPage4} page={page4} setPage={setPage4}/>
      <TableComponent item={query7.item} result={query7.result} rowsPerPage={rowsPerPage7} 
        setRowsPerPage={setRowsPerPage7} page={page7} setPage={setPage7}/>
      <TableComponent item={query8.item} result={query8.result} rowsPerPage={rowsPerPage8} 
        setRowsPerPage={setRowsPerPage8} page={page8} setPage={setPage8}/>
      <TableComponent item={query9.item} result={query9.result} rowsPerPage={rowsPerPage9} 
        setRowsPerPage={setRowsPerPage9} page={page9} setPage={setPage9}/>
      <TableComponent item={query10.item} result={query10.result} rowsPerPage={rowsPerPage10} 
        setRowsPerPage={setRowsPerPage10} page={page10} setPage={setPage10}/>
      <TableComponent item={query5.item} result={query5.result} rowsPerPage={rowsPerPage5} 
        setRowsPerPage={setRowsPerPage5} page={page5} setPage={setPage5}/>
      <TableComponent item={query11.item} result={query11.result} rowsPerPage={rowsPerPage11} 
        setRowsPerPage={setRowsPerPage11} page={page11} setPage={setPage11}/>
      <TableComponent item={query6.item} result={query6.result} rowsPerPage={rowsPerPage6} 
        setRowsPerPage={setRowsPerPage6} page={page6} setPage={setPage6}/>
    </Dashboard>
  ]) 
};

export default TimePeriodSummaryPage;