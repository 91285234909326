import TimePeriodSummaryQueries from './TimePeriodSummaryQueries'
import PerPersionSpendingQueries from './PerPersionSpendingQueries'

const timePeriodSummary = {
  timePeriodSummary: {
    ...TimePeriodSummaryQueries,
    ...PerPersionSpendingQueries
  }
}

export default timePeriodSummary