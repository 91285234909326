const TimePeriodSummaryQueries = {
  TimePeriodDineInSummary: {
    query: {
      "measures": [
        "MvOrdersSummaryEM.count",
        "MvOrdersSummaryEM.sumDineInNumberOfPeople",
        "MvOrdersSummaryEM.avgBillAmount",
        "MvOrdersSummaryEM.sumNetSales",
        "MvOrdersSummaryEM.perPersonSpending"
      ],
      "timeDimensions": [
      ],
      "order": {},
      "dimensions": [],
      "filters": [
        {
          "member": "MvOrdersSummaryEM.state",
          "operator": "equals",
          "values": [
            "completed"
          ]
        },
        {
          "member": "MvOrdersSummaryEM.orderSource",
          "operator": "equals",
          "values": [
            "dine_in"
          ]
        }
      ],
      "limit": 50000,
      "renewQuery": true,
    }
  },
  TimePeriodTakeawaySummary: {
    query: {
      "measures": [
        "MvOrdersSummaryEM.count",
        "MvOrdersSummaryEM.sumDineInNumberOfPeople",
        "MvOrdersSummaryEM.avgBillAmount",
        "MvOrdersSummaryEM.sumNetSales",
        "MvOrdersSummaryEM.perPersonSpending"
      ],
      "timeDimensions": [
      ],
      "order": {},
      "dimensions": [],
      "filters": [
        {
          "member": "MvOrdersSummaryEM.state",
          "operator": "equals",
          "values": [
            "completed"
          ]
        },
        {
          "member": "MvOrdersSummaryEM.orderSource",
          "operator": "equals",
          "values": [
            "takeaway_dine_in",
            "takeaway_in_house",
            "take_away_hkd",
            "take_away_pos_hkd",
            "self_pickup_hkd",
            "self_pickup_pos_hkd",
            "deliveroo_pos_hkd",
            "foodpanda_pos_hkd",
            "hktvmall_pos_hkd",
            "inline_pos_hkd",
            "openrice_pos_hkd",
            "rt_self_pickup_pos_hkd",
            "zeek_pos_hkd",
            "groupbuy_pos_hkd"
          ]
        }
      ],
      "limit": 50000,
      "renewQuery": true,
    }
  },
  TimePeriodSummaryByStorePerDayEMHist: {
    query: {
      "measures": [
        "MvOrdersSummaryEM.sumNetSales",
      ],
      "timeDimensions": [
      ],
      "order": {
        "MvOrdersSummaryEM.shipFromStoreCode": "asc",
      },
      "dimensions": [
        "MvOrdersSummaryEM.shipFromStoreCode",
      ],
      "filters": [
        {
          "member": "MvOrdersSummaryEM.state",
          "operator": "equals",
          "values": [
            "completed"
          ]
        },
        {
          "member": "MvOrdersSummaryEM.commerceChannelCode",
          "operator": "notEquals",
          "values": [
            "membership_upgrade", "membership_upgrade_pos"
          ]
        },
        {
          "member": "MvOrdersSummaryEM.orderSource",
          "operator": "notEquals",
          "values": [
            "membership_upgrade"
          ]
        },
      ],
      "limit": 50000,
      "renewQuery": true,
    }
  },
  TimePeriodSummaryDetail: {
    query: {
      "measures": [
        "MvOrdersSummaryEM.sumGrossSales",
        "MvOrdersSummaryEM.sumDiscounts",
        "MvOrdersSummaryEM.sumShippingFees",
        "MvOrdersSummaryEM.sumServiceCharges",
        "MvOrdersSummaryEM.sumOrderAmountRounding",
        "MvOrdersSummaryEM.sumMembershipCharges",
        "MvOrdersSummaryEM.sumOrderTotal",
        "MvOrdersSummaryEM.count",
        "MvOrdersSummaryEM.sumDineInNumberOfPeople",
        "MvOrdersSummaryEM.avgBillAmount",
        "MvOrdersSummaryEM.sumNetSales",
        "MvOrdersSummaryEM.perPersonSpending"
      ],
      "timeDimensions": [
      ],
      "order": {
        "MvOrdersSummaryEM.shipFromStoreCode": "asc",
      },
      "dimensions": [
        "MvOrdersSummaryEM.shipFromStoreCode",
        "MvOrdersSummaryEM.commerceChannelCode",
        "MvOrdersSummaryEM.orderSource",
      ],
      "filters": [
        {
          "member": "MvOrdersSummaryEM.state",
          "operator": "equals",
          "values": [
            "completed"
          ]
        },
        {
          "member": "MvOrdersSummaryEM.commerceChannelCode",
          "operator": "notEquals",
          "values": [
            "membership_upgrade", "membership_upgrade_pos"
          ]
        },
        {
          "member": "MvOrdersSummaryEM.orderSource",
          "operator": "notEquals",
          "values": [
            "membership_upgrade"
          ]
        }
      ],
      "limit": 50000,
      "renewQuery": true,
    }
  },
  FnBTotalAndDiscountBreakDown: {
    query: {
      "measures": [],
      "timeDimensions": [],
      "dimensions": [
        "FnBTotalAndDiscountBreakDownMV.storeCode",
        "FnBTotalAndDiscountBreakDownMV.dineInTableNumber",
        "FnBTotalAndDiscountBreakDownMV.serviceMode",
        "FnBTotalAndDiscountBreakDownMV.commerceChannelCode",
        "FnBTotalAndDiscountBreakDownMV.orderGrossTotal",
        "FnBTotalAndDiscountBreakDownMV.foodGrossTotal",
        "FnBTotalAndDiscountBreakDownMV.bvGrossTotal",
        "FnBTotalAndDiscountBreakDownMV.rounding",
        "FnBTotalAndDiscountBreakDownMV.serviceCharge",
        "FnBTotalAndDiscountBreakDownMV.discountTotal",
        "FnBTotalAndDiscountBreakDownMV.allotedFoodDiscount",
        "FnBTotalAndDiscountBreakDownMV.allotedBvDiscount",
        "FnBTotalAndDiscountBreakDownMV.unallottableDiscount",
        "FnBTotalAndDiscountBreakDownMV.orderNetTotal"
      ],
      "order": {
        "FnBTotalAndDiscountBreakDownMV.storeCode": "asc",
        "FnBTotalAndDiscountBreakDownMV.openTableTime": "asc"
      },
      "filters": [
      ],
      "limit": 50000,
      "renewQuery": true,
    }
  }
}

export default TimePeriodSummaryQueries