import React from "react";
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';

let ButtonGroupComponent = ({state, stateChanger, pageChangerArr, buttonGroupArray, title="", ...rest}) => {
    
    return(
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Typography variant="body">{title}</Typography>
            <ButtonGroup sx={{display: "table"}}>
              {buttonGroupArray.map(value => (
                <Button
                variant={value.id === state ? "contained" : ""}
                onClick={() => {
                  if(pageChangerArr){
                    for(let i = 0; i < pageChangerArr.length; i++){
                      pageChangerArr[i](0)
                    }
                  }
                  stateChanger(value.id)
                }}>
                {value.code.toUpperCase()}
                </Button>
              ))}
          </ButtonGroup>
        </Grid>
    )
}

export default ButtonGroupComponent;