import fnbZhHK from './fnb/zh-HK'
import rtZhHK from './rt/zh-HK'
import fsZhHK from './fs/zh-HK'
import cheZhHK from './che/zh-HK'
import kstZhHK from './kst/zh-HK'
import supplierZhHK from './supplier/zh-HK'
import membershipZhHK from './membership/zh-HK'
import stocktakeZhHK from './stocktake/zh-HK'

const pageZhHK = {
  page: {
    ...fnbZhHK,
    ...rtZhHK,
    ...fsZhHK,
    ...cheZhHK,
    ...kstZhHK,
    ...supplierZhHK,
    ...membershipZhHK,
    ...stocktakeZhHK,
    "dashboard": {
      "main": "請在導航欄上選擇報告"
    },
    "signIn": {
      "copyright": "版權 © ",
      "tofugear": "富基軟件有限公司",
      "wrongCompanyCode": "無效公司編號",
      "wrongUsernamePassword": "無效的電子郵件地址/密碼",
      "signIn": "登入",
      "companyCode": "公司編號",
      "email": "電子郵件地址",
      "password": "密碼",
      "remember": "記住我",
      "forgotPassword": "忘記密碼",
      "forgotPasswordDetail": "請聯繫您的管理員重置您的密碼",
      "ok": "確認",
      "internalError": "內部錯誤，請聯繫富基軟件",
      "expired": "您的驗證憑據已過期，請重新登錄",
      "authenticating": "登錄中 ...",
    }
  }
}

export default pageZhHK