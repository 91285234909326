const ActiveQueries = {
  ActiveMemberPoint: {
    query: {
      "measures": [
        "ActiveMemberPoint.count"
      ],
      "order": {
      },
      "filters": [
      ],
      "dimensions": [
      ],
      "limit": 50000,
      "renewQuery": true,
    }
  },
}

export default ActiveQueries