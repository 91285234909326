import React, { useState } from "react";
import Header from '../../components/Header';
import TableComponent from '../../components/TableComponent';
import Dashboard from '../../components/Dashboard';
import PageHeaderComponent from '../../components/PageHeaderComponent';
import OmnitechQueries from '../../queries/OmnitechQueries'
import { useTranslation } from 'react-i18next';
import { useCubeQuery } from '@cubejs-client/react';
import { format } from 'date-fns'
import ChartComponent from '../../components/ChartComponent';

const UserRankQuery = (isRefresh, setRefresh) => {
  const { t } = useTranslation();
  let item = {
    name: t('page.fnb.userRank.name'),
    tableHeaders: {
      "CustomerRanks.code":  { name: t('page.fnb.userRank.tableHeader.rankCode'), type: "string" },
      "UserLoyaltyPointBalances.count":  { name: t('page.fnb.userRank.tableHeader.count'), type: "number" },
    },
    grid: {xs: 12, lg: 12},
    export: { allowExport: true, filename: 'user_rank_export_' + format(new Date(), 'yyyyMMddHHmmss') }
  }
  let query = {
    ...OmnitechQueries.userLoyaltyPointBalances.UserRank.query
  }
  if(isRefresh)
    query = {}
  const result = useCubeQuery(query, {resetResultSetOnChange: true})
  if(isRefresh)
    setRefresh(false)
  return { item, result }
}

const getUserRankChartData = (resultSet) => {
  return {
    data: resultSet?.chartPivot(),
    dataSeries: resultSet?.seriesNames(),
  }
}

const UserRankPage = () => {
  const { t } = useTranslation();
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  const [ refresh1, setRefresh1 ] = useState(false);

  const { item, result }  = UserRankQuery(refresh1, setRefresh1)

  // cache the chart massaged data
  const userRankChart = React.useMemo(() => {
    if (result.resultSet === null) return {data: [], dataSeries: []}

    return getUserRankChartData(result.resultSet)
  }, [result.resultSet])

  const refresh = () => {
    setRefresh1(true)
    setPage(0)
  }

  return (
    <React.Fragment>
      <Header />
      <PageHeaderComponent onClickFunc={refresh} refreshlabel={t('component.refresh')} headerTitle={t('component.header.menu.membershipCount')} />,
      <Dashboard>
        <ChartComponent chartType="bar" item={item} data={userRankChart.data} 
          dataSeries={userRankChart.dataSeries} result={result} titleName={item.name} 
          enableTooltip />
        <TableComponent item={item} result={result} rowsPerPage={rowsPerPage} 
          setRowsPerPage={setRowsPerPage} page={page} setPage={setPage}/>
      </Dashboard>
    </React.Fragment>
  ) 
};

export default UserRankPage;