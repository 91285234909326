import React, { useState, useContext } from "react";
import Header from '../../components/Header';
import NumberComponent from '../../components/NumberComponent';
import MultiNumberComponent from '../../components/MultiNumberComponent';
import MultiSelectComponent from '../../components/MultiSelectComponent';
import DateRangePickerComponent from '../../components/DateRangePickerComponent';
import Dashboard from '../../components/Dashboard';
import PageHeaderComponent from '../../components/PageHeaderComponent';
import OmnitechQueries from '../../queries/OmnitechQueries'
import { CubeJSContext } from '../../context/CubeJSProvider';
import { useTranslation } from 'react-i18next';
import { useCubeQuery } from '@cubejs-client/react';
import { setHours, setMinutes, setSeconds, setMilliseconds } from 'date-fns'
import TableComponent from '../../components/TableComponent';
import { format } from 'date-fns'

const applyFilter = (query, storeFilter, storeMember) => {
  var storeFilterObj = {
    member: storeMember,
    operator: "contains",
    values: storeFilter
  }

  query = {
    ...query,
    filters: (query.filters || []).concat(storeFilterObj)
  }
  return query
}

const CHEDayEndSummaryQueries = (storeFilter, startDateFilter, endDateFilter, isRefresh, setRefresh) => {
  if(startDateFilter == null && endDateFilter == null){
    startDateFilter = new Date()
    startDateFilter.setHours(0,0,0,0);
    endDateFilter = new Date()
    endDateFilter.setHours(23,59,59,999);
  }
  else if(startDateFilter == null){
    startDateFilter = setHours(endDateFilter, 0)
    startDateFilter = setMinutes(startDateFilter, 0)
    startDateFilter = setSeconds(startDateFilter, 0)
    startDateFilter = setMilliseconds(startDateFilter, 0)
  }
  else if (endDateFilter == null){
    endDateFilter = setHours(startDateFilter, 23)
    endDateFilter = setMinutes(endDateFilter, 59)
    endDateFilter = setSeconds(endDateFilter, 59)
    endDateFilter = setMilliseconds(endDateFilter, 999)
  }
  var result = {}
  let query1 = {
    ...OmnitechQueries.orders.CHEOrderSummary.query,
    "timeDimensions": OmnitechQueries.orders.CHEOrderSummary.query.timeDimensions.concat(
      {
        "dimension": "OrderDayEndCHEV2.orderCompletedAt",
        "dateRange": [ startDateFilter, endDateFilter ]
      })
  }
  query1 = applyFilter(query1, storeFilter, "OrderDayEndCHEV2.shipmentStoreCode")
  if(isRefresh)
    query1 = {}
  result["summary"] = useCubeQuery(query1, {resetResultSetOnChange: true})

  let query2 = {
    ...OmnitechQueries.orderRefundRequest.OrderRefundSummary.query,
    "timeDimensions": OmnitechQueries.orderRefundRequest.OrderRefundSummary.query.timeDimensions.concat(
      {
        "dimension": "OrderRefundSummary.refundedAt",
        "dateRange": [ startDateFilter, endDateFilter ]
      })
  }
  query2 = applyFilter(query2, storeFilter, "OrderRefundSummary.shipmentStoreCode", null, null)
  if(isRefresh)
    query2 = {}
  result["refund"] = useCubeQuery(query2, {resetResultSetOnChange: true})

  if(isRefresh)
    setRefresh(false)
  return result
}

const CHEDayEndSummaryDetailQueries = (storeFilter, startDateFilter, endDateFilter, isRefresh, setRefresh) => {
  const { t } = useTranslation();
  if(startDateFilter == null){
    startDateFilter = new Date()
    startDateFilter.setHours(0,0,0,0);
    endDateFilter = new Date()
    endDateFilter.setHours(23,59,59,999);
  }

  let item = {
    name: t('page.che.dailyOperation.orderSummaryDetail.name'),
    tableHeaders: {
      "OrderDayEndCHEV2.catType": { name: t('page.che.dailyOperation.orderSummaryDetail.tableHeader.catType'), type: 'string'},
      "OrderDayEndCHEV2.grossSales": { name: t('page.che.dailyOperation.orderSummaryDetail.tableHeader.grossSales'), type: 'number'},
      "OrderDayEndCHEV2.sumSkuQuantity": { name: t('page.che.dailyOperation.orderSummaryDetail.tableHeader.sumSkuQuantity'), type: 'number'},
      "OrderDayEndCHEV2.discount": { name: t('page.che.dailyOperation.orderSummaryDetail.tableHeader.discount'), type: 'number'},
      "OrderDayEndCHEV2.netSales": { name: t('page.che.dailyOperation.orderSummaryDetail.tableHeader.netSales'), type: 'number'},
    },
    grid: { xs: 12 },
    export: { allowExport: true, filename: 'dayend_summary_detail_' + format(startDateFilter, 'yyyyMMddHHmmss') +
      '_' + format(endDateFilter, 'yyyyMMddHHmmss') },
    withPercentageSum: true,
    percentageSumHeaderName: t('page.che.dailyOperation.orderSummaryDetail.tableHeader.percentage'),
    withPercentageSumColoum: "OrderDayEndCHEV2.netSales"
  }
  let query = {
    ...OmnitechQueries.orders.CHEOrderSummaryDetail.query,
    "timeDimensions": OmnitechQueries.orders.CHEOrderSummaryDetail.query.timeDimensions.concat(
      {
        "dimension": "OrderDayEndCHEV2.orderCompletedAt",
        "dateRange": [ startDateFilter, endDateFilter ]
      })
  }
  query = applyFilter(query, storeFilter, "OrderDayEndCHEV2.shipmentStoreCode")
  if(isRefresh)
    query = {}
  const result = useCubeQuery(query, {resetResultSetOnChange: true})
  if(isRefresh)
    setRefresh(false)
  return { item, result }
}

const CHEDailyOperationPage = () => {
  const { t, i18n } = useTranslation();
  let languageFilter = i18n.language
  if(languageFilter !== 'en-HK' && languageFilter !== 'zh-HK')
    languageFilter = 'en-HK'
  const context = useContext(CubeJSContext);
  let storeCodeSelect = []
  if(context.storeCodes)
    storeCodeSelect = context.storeCodes
  const [ storeCodeFilter, setStoreCodeFilter ] = useState([]);
  let filterStartDate = new Date()
  filterStartDate.setHours(0,0,0,0);
  let filterEndDate = new Date()
  filterEndDate.setHours(23,59,59,999);
  const [dateRangeFilter, setDateRangeFilter] = useState([filterStartDate, filterEndDate])
  const [startDateFilter, endDateFilter] = dateRangeFilter
  const [rowsPerPage1, setRowsPerPage1] = useState(10);
  const [page1, setPage1] = useState(0);

  const [ refresh1, setRefresh1 ] = useState(false);
  const [ refresh2, setRefresh2 ] = useState(false);

  const resultSets = CHEDayEndSummaryQueries(storeCodeFilter, startDateFilter, endDateFilter, refresh1, setRefresh1)
  const query1 = CHEDayEndSummaryDetailQueries(storeCodeFilter, startDateFilter, endDateFilter, refresh2, setRefresh2)
  const item = {grid: {xs: 12, md: 6}}
  const item12= {grid: {xs: 12, md: 12}}

  const refresh = () => {
    setRefresh1(true)
  }

  return ([
    <Header />,
    <PageHeaderComponent onClickFunc={refresh} refreshlabel={t('component.refresh')} headerTitle={t('component.header.menu.cheDailyOperation')} />,
    <Dashboard>
      <MultiSelectComponent id={"store_code_filter"} state={storeCodeFilter} pageChangerArr={[]}
        stateChanger={setStoreCodeFilter} textLabel={t('page.che.dailyOperation.filter.storeCode.name')} selectArray={storeCodeSelect}/>
      <DateRangePickerComponent state={dateRangeFilter} stateChanger={setDateRangeFilter} 
      pageChangerArr={[]}
      startDateLabel={t('page.che.dailyOperation.filter.orderConfirmedAt.startDate')} 
      toLabel={t('page.che.dailyOperation.filter.orderConfirmedAt.to')} 
      endDateLabel={t('page.che.dailyOperation.filter.orderConfirmedAt.endDate')}/>
    </Dashboard>,
    <Dashboard>
      <NumberComponent item={item} query={resultSets["summary"]}
        titleName={t('page.che.dailyOperation.titleMapping.netSales')}
        fieldName="OrderDayEndCHEV2.netSales" />
      <NumberComponent item={item} query={resultSets["summary"]}
        titleName={t('page.che.dailyOperation.titleMapping.grossSales')}
        fieldName="OrderDayEndCHEV2.grossSales" />
      <NumberComponent item={item} query={resultSets["summary"]}
        titleName={t('page.che.dailyOperation.titleMapping.orderCount')}
        fieldName="OrderDayEndCHEV2.count" />
      <NumberComponent item={item} query={resultSets["summary"]}
        titleName={t('page.che.dailyOperation.titleMapping.orderQuantity')}
        fieldName="OrderDayEndCHEV2.sumSkuQuantity" />
      <NumberComponent item={item} query={resultSets["summary"]}
        titleName={t('page.che.dailyOperation.titleMapping.orderDiscount')}
        fieldName="OrderDayEndCHEV2.discount" />
      <NumberComponent item={item} query={resultSets["summary"]}
        titleName={t('page.che.dailyOperation.titleMapping.orderRounding')}
        fieldName="OrderDayEndCHEV2.orderRounding" />
      <NumberComponent item={item} query={resultSets["refund"]}
        titleName={t('page.che.dailyOperation.titleMapping.totalRefund')}
        fieldName="OrderRefundSummary.count" />
      <NumberComponent item={item} query={resultSets["refund"]}
        titleName={t('page.che.dailyOperation.titleMapping.totalRefundAmount')}
        fieldName="OrderRefundSummary.totalRefundAmount" />
      <MultiNumberComponent item={item12} queries={[resultSets["summary"],resultSets["refund"]]}
        titleName={t('page.che.dailyOperation.titleMapping.netSalesDeductRefund')}
        fieldNames={["OrderDayEndCHEV2.netSales","OrderRefundSummary.totalRefundAmount"]}
        operators={["-"]} />
      <TableComponent item={query1.item} result={query1.result} rowsPerPage={rowsPerPage1} 
        setRowsPerPage={setRowsPerPage1} page={page1} setPage={setPage1}/>
    </Dashboard>
  ]) 
};

export default CHEDailyOperationPage;