import React, { useState, useContext } from "react";
import Header from '../../components/Header';
import TableComponent from '../../components/TableComponent';
import DateRangePickerComponent from '../../components/DateRangePickerComponent';
import MultiSelectComponent from '../../components/MultiSelectComponent';
import Dashboard from '../../components/Dashboard';
import PageHeaderComponent from '../../components/PageHeaderComponent';
import OmnitechQueries from '../../queries/OmnitechQueries'
import { useTranslation } from 'react-i18next';
import { useCubeQuery } from '@cubejs-client/react';
import { CubeJSContext } from '../../context/CubeJSProvider';
import { setHours, setMinutes, setSeconds, setMilliseconds, format } from 'date-fns'

const applyFilter = (query, stateFilter, storeCodeFilter) => {
  let stateFilterObj = {
    member: "SkuInventoryAdjustment.state",
    operator: "contains",
    values: stateFilter
  }

  query = {
    ...query,
    filters: (query.filters || []).concat(stateFilterObj)
  }

  if(storeCodeFilter !== null){
    let storeCodeFilterObj = {
      member: "SkuInventoryAdjustment.storeCode",
      operator: "contains",
      values: storeCodeFilter
    }

    query = {
      ...query,
      filters: (query.filters || []).concat(storeCodeFilterObj)
    }
  }

  return query
}

const SkuInventoryAdjustmentQuery = (startDateFilter, endDateFilter, stateFilter, storeCodeFilter, isRefresh, setRefresh) => {
  const { t } = useTranslation();
  if(startDateFilter == null && endDateFilter == null){
    startDateFilter = new Date()
    startDateFilter.setHours(0,0,0,0);
    endDateFilter = new Date()
    endDateFilter.setHours(23,59,59,999);
  }
  else if(startDateFilter == null){
    startDateFilter = setHours(endDateFilter, 0)
    startDateFilter = setMinutes(startDateFilter, 0)
    startDateFilter = setSeconds(startDateFilter, 0)
    startDateFilter = setMilliseconds(startDateFilter, 0)
  }
  else if (endDateFilter == null){
    endDateFilter = setHours(startDateFilter, 23)
    endDateFilter = setMinutes(endDateFilter, 59)
    endDateFilter = setSeconds(endDateFilter, 59)
    endDateFilter = setMilliseconds(endDateFilter, 999)
  }
  let item = {
    name: t('page.stocktake.skuInventoryAdjustmentReportPage.skuInventoryAdjustment.name'),
    tableHeaders: {
      "SkuInventoryAdjustment.adjustmentReferenceNumber": { name: t('page.stocktake.skuInventoryAdjustmentReportPage.skuInventoryAdjustment.tableHeader.adjustmentReferenceNumber'), type: 'string'},
      "SkuInventoryAdjustment.storeCode": { name: t('page.stocktake.skuInventoryAdjustmentReportPage.skuInventoryAdjustment.tableHeader.storeCode'), type: 'string'},
      "SkuInventoryAdjustment.state": { name: t('page.stocktake.skuInventoryAdjustmentReportPage.skuInventoryAdjustment.tableHeader.state'), type: 'string'},
      "SkuInventoryAdjustment.skuCode":  { name: t('page.stocktake.skuInventoryAdjustmentReportPage.skuInventoryAdjustment.tableHeader.skuCode'), type: "string" },
      "SkuInventoryAdjustment.productTitleEn": { name: t('page.stocktake.skuInventoryAdjustmentReportPage.skuInventoryAdjustment.tableHeader.productTitleEn'), type: 'string'},
      "SkuInventoryAdjustment.productTitleZh":  { name: t('page.stocktake.skuInventoryAdjustmentReportPage.skuInventoryAdjustment.tableHeader.productTitleZh'), type: "string" },
      "SkuInventoryAdjustment.quantityMovement": { name: t('page.stocktake.skuInventoryAdjustmentReportPage.skuInventoryAdjustment.tableHeader.quantityMovement'), type: 'number'},
      "SkuInventoryAdjustment.skuMeasureMovement": { name: t('page.stocktake.skuInventoryAdjustmentReportPage.skuInventoryAdjustment.tableHeader.skuMeasureMovement'), type: 'number'},
      "SkuInventoryAdjustment.skuMeasurementCode": { name: t('page.stocktake.skuInventoryAdjustmentReportPage.skuInventoryAdjustment.tableHeader.skuMeasurementCode'), type: 'string'},
      "SkuInventoryAdjustment.staffNumber": { name: t('page.stocktake.skuInventoryAdjustmentReportPage.skuInventoryAdjustment.tableHeader.staffNumber'), type: 'string'},
      "SkuInventoryAdjustment.firstName": { name: t('page.stocktake.skuInventoryAdjustmentReportPage.skuInventoryAdjustment.tableHeader.firstName'), type: 'string'},
      "SkuInventoryAdjustment.lastName": { name: t('page.stocktake.skuInventoryAdjustmentReportPage.skuInventoryAdjustment.tableHeader.lastName'), type: 'string'},
      "SkuInventoryAdjustment.createdAt": { name: t('page.stocktake.skuInventoryAdjustmentReportPage.skuInventoryAdjustment.tableHeader.createdAt'), type: 'string'},
      "SkuInventoryAdjustment.queueingProcessAt": { name: t('page.stocktake.skuInventoryAdjustmentReportPage.skuInventoryAdjustment.tableHeader.queueingProcessAt'), type: 'dateTime'},
      "SkuInventoryAdjustment.processingAt": { name: t('page.stocktake.skuInventoryAdjustmentReportPage.skuInventoryAdjustment.tableHeader.processingAt'), type: 'dateTime'},
      "SkuInventoryAdjustment.processedAt": { name: t('page.stocktake.skuInventoryAdjustmentReportPage.skuInventoryAdjustment.tableHeader.processedAt'), type: 'dateTime'},
      "SkuInventoryAdjustment.remark": { name: t('page.stocktake.skuInventoryAdjustmentReportPage.skuInventoryAdjustment.tableHeader.remark'), type: 'string'},
    },
    grid: {xs: 12},
    export: { allowExport: true, filename: 'sku_inventory_adjusment_' + format(startDateFilter, 'yyyyMMddHHmmss') +
      '_' + format(endDateFilter, 'yyyyMMddHHmmss') }
  }
  let query = {
    ...OmnitechQueries.stocktake.SkuInventoryAdjustment.query,
     "timeDimensions": [
      {
        "dimension": "SkuInventoryAdjustment.createdAt",
        "dateRange": [ startDateFilter, endDateFilter ]
      }
    ],
  }
  query = applyFilter(query, stateFilter, storeCodeFilter)
  if(isRefresh)
    query = {}
  const result = useCubeQuery(query, {resetResultSetOnChange: true})
  if(isRefresh)
    setRefresh(false)
  return { item, result }
}

const SkuInventoryAdjustmentReportPage = () => {
  const { t } = useTranslation();
  const context = useContext(CubeJSContext);
  let storeCodeSelect = []
  if(context.storeCodes)
    storeCodeSelect = context.storeCodes
  const [ storeCodeFilter, setStoreCodeFilter ] = useState([]);
  let stateSelect = ['pending', 'queueing_process', 'processing', 'processed_with_error', 'processed']
  const [ stateFilter, setStateFilter ] = useState([]);
  let filterStartDate = new Date()
  filterStartDate.setHours(0,0,0,0);
  let filterEndDate = new Date()
  filterEndDate.setHours(23,59,59,999);
  const [dateRangeFilter, setDateRangeFilter] = useState([filterStartDate, filterEndDate])
  const [startDateFilter, endDateFilter] = dateRangeFilter
  const [rowsPerPage1, setRowsPerPage1] = useState(10);
  const [page1, setPage1] = useState(0);

  const [ refresh1, setRefresh1 ] = useState(false);

  const query1  = SkuInventoryAdjustmentQuery(startDateFilter, endDateFilter, stateFilter, storeCodeFilter, refresh1, setRefresh1)

  const refresh = () => {
    setRefresh1(true)
    setPage1(0)
  }

  return ([
    <Header />,
    <PageHeaderComponent onClickFunc={refresh} refreshlabel={t('component.refresh')} headerTitle={t('component.header.menu.inventoryAdjustmentReport')} />,
    <Dashboard>
      <DateRangePickerComponent state={dateRangeFilter} stateChanger={setDateRangeFilter} pageChangerArr={[setPage1]}
        startDateLabel={t('page.stocktake.skuInventoryAdjustmentReportPage.filter.createdAt.startDate')} 
        toLabel={t('page.stocktake.skuInventoryAdjustmentReportPage.filter.createdAt.to')} 
        endDateLabel={t('page.stocktake.skuInventoryAdjustmentReportPage.filter.createdAt.endDate')}/>
      <MultiSelectComponent id={"store_code_filter"} state={storeCodeFilter} pageChangerArr={[setPage1]}
        stateChanger={setStoreCodeFilter} textLabel={t('page.stocktake.skuInventoryAdjustmentReportPage.filter.storeCode.name')} selectArray={storeCodeSelect}/>
      <MultiSelectComponent id={"state_filter"} state={stateFilter} pageChangerArr={[setPage1]}
        stateChanger={setStateFilter} textLabel={t('page.stocktake.skuInventoryAdjustmentReportPage.filter.state.name')} selectArray={stateSelect}/>
    </Dashboard>
    ,
    <Dashboard>
      <TableComponent item={query1.item} result={query1.result} rowsPerPage={rowsPerPage1} 
        setRowsPerPage={setRowsPerPage1} page={page1} setPage={setPage1}/>
    </Dashboard>
  ]) 
};

export default SkuInventoryAdjustmentReportPage;