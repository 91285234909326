const OrderUserQueries = {
  RtOrderUser: {
    query: {
      "measures": [
      ],
      "timeDimensions":[],
      "dimensions": [
        "OrderUsers.referenceNumber",
        "OrderUsers.shipmentStoreCode",
        "OrderUsers.completedAt",
        "OrderUsers.totalPrice",
        "OrderUsers.membershipCode",
        "OrderUsers.firstName",
        "OrderUsers.sex",
        "OrderUsers.userEmail",
        "OrderUsers.phone",
        "OrderUsers.customerRankCode",
        "OrderUsers.rankExpireAt",
        "OrderUsers.zonePS",
        "OrderUsers.userAgreementPics",
        "OrderUsers.userAgreementTerms",
      ],
      "filters": [
        {
          "member": "OrderUsers.state",
          "operator": "equals",
          "values": [
            "completed"
          ]
        },
        {
          "member": "OrderUsers.commerceChannelCode",
          "operator": "notEquals",
          "values": [
            "membership_upgrade"
          ]
        },
      ],
      "limit": 50000,
      "renewQuery": true,
    }
  },
  OrderUser: {
    query: {
      "measures": [
      ],
      "timeDimensions":[],
      "dimensions": [
        "OrderUsers.referenceNumber",
        "OrderUsers.shipmentStoreCode",
        "OrderUsers.completedAt",
        "OrderUsers.totalPrice",
        "OrderUsers.membershipCode",
        "OrderUsers.userCreatedAt",
        "OrderUsers.customerRankCode",
        "OrderUsers.rankExpireAt",
        "OrderUsers.firstName",
        "OrderUsers.lastName",
        "OrderUsers.sex",
        "OrderUsers.userEmail",
        "OrderUsers.phone",
        "OrderUsers.pointsBurn",
        "OrderUsers.pointsEarn",
        "OrderUsers.dineInNumberOfPeople",
        "OrderUsers.dineInDuration",
        "OrderUsers.orderType",
        "OrderUsers.paymentMethod",
      ],
      "filters": [
        {
          "member": "OrderUsers.state",
          "operator": "equals",
          "values": [
            "completed"
          ]
        },
        {
          "member": "OrderUsers.commerceChannelCode",
          "operator": "notEquals",
          "values": [
            "membership_upgrade"
          ]
        },
      ],
      "limit": 50000,
      "renewQuery": true,
    }
  },
  ActiveMemberOrder: {
    query: {
      "measures": [
        "ActiveMemberOrder.count"
      ],
      "order": {
      },
      "filters": [
      ],
      "dimensions": [
      ],
      "limit": 50000,
      "renewQuery": true,
    }
  },
}

export default OrderUserQueries