import OrderEntriesAuditTrailQueries from './OrderEntriesAuditTrailQueries.js'
import SalesQueries from './SalesQueries.js'

const orderTransactions = {
  orderTransactions: {
    ...OrderEntriesAuditTrailQueries,
    ...SalesQueries,
  }
}

export default orderTransactions