const commonEnHK = {
  "discountBreakDownHistPage": {
    "name": "Discount Breakdown",
    "tableHeader": {
      "shipFromStoreCode": "Store Code",
      "discountCode": "Discount Code",
      "discountNameEnHk": "Discount Name (English)",
      "discountNameZhHk": "Discount Name (Chinese)",
      "lineTotal": "Line Total",
      "quantity": "Quantity",
      "totalPrice": "Revenue Brought"
    },
    "filter": {
      "store": {
        "all": "ALL",
        "name": "Store Code"
      },
      "orderCompletedAt": {
        "startDate": "Order Completed At From Date",
        "to": "to",
        "endDate": "Order Completed At End Date"
      }
    }
  },
  "discountBreakdownByOrderPage": {
    "name": "Discount Breakdown By Order",
    "tableHeader": {
      "shipFromStoreCode": "Store Code",
      "discountCode": "Discount Code",
      "discountNameEnHk": "Discount Name (English)",
      "discountNameZhHk": "Discount Name (Chinese)",
      "lineTotal": "Line Total",
      "quantity": "Quantity",
      "membershipCode": "Membersip Code",
      "referenceNumber": "Order Reference Number",
      "firstName": "User Name",
      "phone": "Customer Phone",
      "userPreferDistrict": "User Prefere District",
      "code": "Rank",
      "rankExpireAt": "Rank Expire At",
      "ordersCompletedAt": "Order Completed At",
      "sumNetSales": "Net Sales"
    },
    "filter": {
      "store": {
        "all": "ALL",
        "name": "Store Code"
      },
      "orderCompletedAt": {
        "startDate": "Order Completed At From Date",
        "to": "to",
        "endDate": "Order Completed At End Date"
      }
    }
  },
  "discountBreakdownByMemberPage": {
    "name": "Discount Breakdown By Member",
    "tableHeader": {
      "shipFromStoreCode": "Store Code",
      "discountCode": "Discount Code",
      "discountNameEnHk": "Discount Name (English)",
      "discountNameZhHk": "Discount Name (Chinese)",
      "lineTotal": "Line Total",
      "quantity": "Quantity",
      "membershipCode": "Membersip Code",
      "referenceNumber": "Order Reference Number",
      "firstName": "User Name",
      "phone": "Customer Phone",
      "userPreferDistrict": "User Prefere District",
      "code": "Rank",
      "rankExpireAt": "Rank Expire At",
      "ordersCompletedAt": "Order Completed At",
      "sumNetSales": "Net Sales",
      "couponRedeemablePoint": "Redeemable Point",
      "couponRedeemablePrice": "Redeemable Price",
      "couponTokensCreatedAt": "Redeemable Date"
    },
    "filter": {
      "store": {
        "all": "ALL",
        "name": "Store Code"
      },
      "orderCompletedAt": {
        "startDate": "Order Completed At From Date",
        "to": "to",
        "endDate": "Order Completed At End Date"
      }
    }
  },
  "discountBreakdownByItemPage": {
    "name": "Discount Breakdown By Item",
    "tableHeader": {
      "discountCode": "Discount Code",
      "discountNameEnHk": "Discount Name (English)",
      "discountNameZhHk": "Discount Name (Chinese)",
      "lineTotal": "Line Total",
      "quantity": "Quantity",
      "skuCode": "SKU Code",
      "skuInternalUseTitle": "SKU Internal Use Title",
    },
    "filter": {
      "store": {
        "all": "ALL",
        "name": "Store Code"
      },
      "orderCompletedAt": {
        "startDate": "Order Completed At From Date",
        "to": "to",
        "endDate": "Order Completed At End Date"
      },
      "discountCode": "Discount Code"
    }
  },
  "dailyOperation": {
    "filter": {
      "store": {
        "all": "ALL",
        "name": "Store Code"
      },
      "orderCompletedAt": {
        "date": "Order Completed At",
      }
    },
    "titleMapping": {
      "totalRevenue": "Total Revenue",
      "totalOrder": "Total Order",
      "totalMembershipSales": "Total Membership Sales",
      "totalNetSales": "Total Net Sales",
      "totalGrossSales": "Total Gross Sales",
      "totalDiscounts": "Total Discounts",
      "totalVoidOrder": "Total Void Order",
      "totalVoidOrderAmount": "Total Void Order Amount",
      "totalOrderAmountRounding": "Total Order Amount Rounding",
      "avgBillAmount": "Average Order Amount",
      "perPersonSpending": "Per Person Spending",
      "totalPax": "Total PAX",
      "totalTip": "Total Tips",
      "totalShippingFees": "Total Shipping Fees",
      "totalServiceCharges": "Total Service Charges",
      "totalVoidItem": "Total Void Item",
      "outStandingNetsales": "Outstanding Table Net Sales",
      "totalFoodGrossSales": "Total Food Gross Sales",
      "totalBeverageGrossSales": "Total Beverage Gross Sales",
      "totalOtherGrossSales": "Total Other Gross Sales",
    },
    "netSalesByStore":{
      "name": "Net Sales By Store",
      "tableHeader": {
        "count": "Order Count",
        "orderTotal": "Order Total",
        "shipFromStoreCode": "Store Code",
        "orderSource": "Order Source"
      }
    },
    "netSalesByStoreEM":{
      "name": "Net Sales By Store Exclude Membership",
      "tableHeader": {
        "count": "Order Count",
        "netSales": "Order Total",
        "shipFromStoreCode": "Store Code",
        "orderSource": "Order Source"
      }
    },
    "paymentByStoreType":{
      "name": "Payment By Store and Type",
      "tableHeader": {
        "sumAmount": "Amount",
        "sumTip": "Tip",
        "grandTotal": "Grand Total",
        "countOrderId": "Order Count",
        "shipmentsFromStoreCode": "Store Code",
        "paymentProviderCode": "Payment Provider Code",
        "name": "Payment Provider Name",
        "currencyCode": "Currency Code",
        "state": "State"
      }
    },
    "tenderByStoreStaff":{
      "name": "Tender By Store and Staff",
      "tableHeader": {
        "sumAmount": "Amount",
        "sumTip": "Tip",
        "countOrderId": "Order Count",
        "shipmentsFromStoreCode": "Store Code",
        "paymentProviderCode": "Payment Provider Code",
        "name": "Payment Provider Name",
        "currencyCode": "Currency Code",
        "state": "State",
        "staffNumber": "Staff Number",
        "firstName": "First Name",
        "lastName": "Last Name",
      }
    },
    "discountBreakdown":{
      "name": "Discount Breakdown",
      "tableHeader": {
        "shipFromStoreCode": "Store Code",
        "discountCode": "Discount Code",
        "discountName": "Discount Name",
        "lineTotal": "Line Total",
        "quantity": "Quantity",
        "totalPrice": "Revenue Brought"
      },
    },
    "SKUSalesDetails":{
      "name": "SKU Sales Details",
      "tableHeader": {
        "shipFromStoreCode": "Store Code",
        "skuCode": "SKU Code",
        "skuInternalUseTitle": "SKU Internal Use Title",
        "skuCost": "SKU Cost",
        "productTitle": "Product Title",
        "code": "RSM Code",
        "lineTotal": "Line Total",
        "quantity": "Quantity"
      },
    },
    "HighlightCategorySales":{
      "name": "Highlight Category Sales",
      "tableHeader": {
        "shipFromStoreCode": "Store Code",
        "skuCode": "SKU Code",
        "skuInternalUseTitle": "SKU Internal Use Title",
        "productTitle": "Product Title",
        "code": "RSM Code",
        "type": "Category Code",
        "lineTotal": "Line Total",
        "quantity": "Quantity"
      },
    },
    "MembershipSalesByType":{
      "name": "Membership Sales By Type",
      "tableHeader": {
        "shipFromStoreCode": "Store Code",
        "skuCode": "SKU Code",
        "skuInternalUseTitle": "SKU Internal Use Title",
        "lineTotal": "Line Total",
        "quantity": "Quantity"
      },
    },
    "MembershipSalesDetails":{
      "name": "Membership Sales Details",
      "tableHeader": {
        "shipFromStoreCode": "Store Code",
        "skuCode": "SKU Code",
        "skuInternalUseTitle": "SKU Internal Use Title",
        "staffNumber": "Staff Number",
        "firstName": "First Name",
        "lastName": "Last Name",
        "ordersCompletedAt": "Order Completed At",
        "unitAmount": "Unit Amount",
        "quantity": "Quantity",
        "lineTotal": "Line Total",
      },
    },
    "MembershipTenderSummaries":{
      "name": "Membership Tender Summaries",
      "tableHeader": {
        "shipFromStoreCode": "Store Code",
        "paymentProviderCode": "Payment Provider Code",
        "lineTotal": "Line Total",
        "quantity": "Quantity"
      },
    },
    "text":{
      "paymentCodeMapping":{
        "vsm": "vsm - VISA (Manual);",
        "msm": "msm - MASTER (Manual);",
        "CV": "CV - Cash Voucher;",
        "CU": "CU - UNION PAY (Manual);",
        "aem": "aem - AMERICAN EXPRESS (Manual);",
        "CASH_HKD": "CASH_HKD - Cash(HKD);",
        "octopus": "octopus - Octopus (Manual);",
        "alipay_hkd_manual": "alipay_hkd_manual - Alipay HKD (Manual);",
        "wechat_pay_hkd_manual": "wechat_pay_hkd_manual - WeChat Pay HKD (Manual);",
      },
      "tenderWarning": "WARNING: Inaccurate if have mixed tender orders"
    }
  },
  "dailyOperationPrint": {
    "filter": {
      "store": {
        "all": "ALL",
        "name": "Store Code"
      },
      "orderCompletedAt": {
        "date": "Order Completed At",
      }
    },
    "titleMapping": {
      "totalRevenue": "Total Revenue",
      "totalOrder": "Total Order",
      "totalMembershipSales": "Total Membership Sales",
      "totalNetSales": "Total Net Sales",
      "totalGrossSales": "Total Gross Sales",
      "totalDiscounts": "Total Discounts",
      "totalVoidOrder": "Total Void Order",
      "totalVoidOrderAmount": "Total Void Order Amount",
      "avgBillAmount": "Average Order Amount",
      "perPersonSpending": "Per Person Spending",
      "totalPax": "Total PAX",
      "totalTip": "Total Tips",
      "totalShippingFees": "Total Shipping Fees",
      "totalServiceCharges": "Total Service Charges",
      "totalOrderAmountRounding": "Total Order Amount Rounding",
      "totalVoidItem": "Total Void Item",
      "outStandingNetsales": "Outstanding Table Net Sales",
      "totalFoodGrossSales": "Total Food Gross Sales",
      "totalBeverageGrossSales": "Total Beverage Gross Sales",
      "foodPercentage": "Total Food Gross Sales Percentage",
      "beveragePercentage": "Total Beverage Gross Sales Percentage",
      "otherPercentage": "Total Other Gross Sales Percentage",
      "totalGrossSalesChart": "Total Gross Sales Breakdown",
      "totalOtherGrossSales": "Total Other Gross Sales",
    },
    "totalGrossSalesChart": {
      "labels": {
        "OrderDayEnd.foodPercentage": "Food",
        "OrderDayEnd.beveragePercentage": "Beverage",
        "OrderDayEnd.otherPercentage": "Other",
      },
    },  
    "netSalesByStoreEM":{
      "name": "Net Sales By Store Exclude Membership",
      "tableHeader": {
        "count": "Order Count",
        "netSales": "Order Total",
        "shipFromStoreCode": "Store Code",
        "orderSource": "Order Source"
      }
    },
    "paymentByStoreType":{
      "name": "Payment By Store and Type",
      "tableHeader": {
        "sumAmount": "Amount",
        "sumTip": "Tip",
        "grandTotal": "Grand Total",
        "countOrderId": "Order Count",
        "shipmentsFromStoreCode": "Store Code",
        "paymentProviderCode": "Payment Provider Code",
        "name": "Payment Provider Name",
        "currencyCode": "Currency Code",
        "state": "State"
      }
    },
    "discountBreakdown":{
      "name": "Discount Breakdown",
      "tableHeader": {
        "shipFromStoreCode": "Store Code",
        "discountCode": "Discount Code",
        "discountName": "Discount Name",
        "lineTotal": "Line Total",
        "quantity": "Quantity",
        "totalPrice": "Revenue Brought"
      },
    },
    "MembershipSalesByType":{
      "name": "Membership Sales By Type",
      "tableHeader": {
        "shipFromStoreCode": "Store Code",
        "skuCode": "SKU Code",
        "skuInternalUseTitle": "SKU Internal Use Title",
        "lineTotal": "Line Total",
        "quantity": "Quantity"
      },
    },
    "MembershipTenderSummaries":{
      "name": "Membership Tender Summaries",
      "tableHeader": {
        "shipFromStoreCode": "Store Code",
        "paymentProviderCode": "Payment Provider Code",
        "lineTotal": "Line Total",
        "quantity": "Quantity"
      },
    },
    "text":{
      "paymentCodeMapping":{
        "vsm": "vsm - VISA (Manual);",
        "msm": "msm - MASTER (Manual);",
        "CV": "CV - Cash Voucher;",
        "CU": "CU - UNION PAY (Manual);",
        "aem": "aem - AMERICAN EXPRESS (Manual);",
        "CASH_HKD": "CASH_HKD - Cash(HKD);",
        "octopus": "octopus - Octopus (Manual);",
        "alipay_hkd_manual": "alipay_hkd_manual - Alipay HKD (Manual);",
        "wechat_pay_hkd_manual": "wechat_pay_hkd_manual - WeChat Pay HKD (Manual);",
      },
      "tenderWarning": "WARNING: Inaccurate if have mixed tender orders",
      "salesBalances": "Sales Balances",
      "OperatingStatistics": "Operating Statistics",
      "auditTrail": "Audit Trail",
      "salesBreakdown": "Sales Breakdown",
    }
  },
  "voidItemDetailHist": {
    "name": "Void Item Detail",
    "filter": {
      "store": {
        "all": "ALL",
        "name": "Store Code"
      },
      "softDeleteAt": {
        "startDate": "Soft Deleted At From Date",
        "to": "to",
        "endDate": "Soft Deleted At End Date"
      },
    },
    "tableHeader": {
      "skuCode": "SKU Code",
      "sukTitle": "SKU Internal Title",
      "productCode": "Product Code",
      "productTitle": "Product Title",
      "productType": "Product Type",
      "storeCode": "Store Code",
      "quantity": "Quantity"
    }
  },
  "totalFoodGrossSales": {
    "name": "Total Food Gross Sales",
    "filter": {
      "store": {
        "all": "ALL",
        "name": "Store Code"
      },
      "orderCompletedAt": {
        "startDate": "Order Completed At From Date",
        "to": "to",
        "endDate": "Order Completed At End Date"
      },
    },
    "tableHeader": {
      "sumLineTotal": "Line Total",
      "orderType": "Order Type",
      "shipFromStoreCode": "Store Code",
      "commerceChannelCode": "Commerce Channel Code"
    }
  },
  "discountBreakdownByCommerceChannel": {
    "name": "Discount Breakdown By Commerce Channel",
    "tableHeader": {
      "shipFromStoreCode": "Store Code",
      "discountCode": "Discount Code",
      "discountNameEnHk": "Discount Name (English)",
      "discountNameZhHk": "Discount Name (Chinese)",
      "lineTotal": "Line Total",
      "quantity": "Quantity",
      "commerceChannelCode": "Commerce Channel Code",
      "couponRedeemablePoint": "Redeemable Point",
      "couponRedeemablePrice": "Redeemable Price",
      "orderCount": "Order Count",
      "orderTotalPrice": "Net Sales"
    },
    "filter": {
      "store": {
        "all": "ALL",
        "name": "Store Code"
      },
      "orderCompletedAt": {
        "startDate": "Order Completed At From Date",
        "to": "to",
        "endDate": "Order Completed At End Date"
      }
    }
  },
  "orderEntriesAuditTrail": {
    "name": "Order Entries Audit Trail",
    "tableHeader": {
      "orderId": "Order Id",
      "otGroupId": "Order Transaction Group Id",
      "lv1Ordering": "Level 1 Ordering",
      "lv2Ordering": "Level 2 Ordering",
      "referenceNumber": "Order Reference Number",
      "storeId": "Store Id",
      "testOrder": "Test Order",
      "shipmentStoreId": "Shipment Store Id",
      "shipmentStoreCode": "Shipment Store Code",
      "commerceChannelsCode": "Commerce Channels Code",
      "orderState": "Order State",
      "orderCompletedAt": "Order Completed At",
      "cartCreatedAt": "Cart Created At",
      "cartClosedAt": "Cart Closed At",
      "orderTransactionsId": "Order Transactions Id",
      "internalAppOperatorsId": "Internal App Operators Id",
      "staffNumber": "Staff Number",
      "firstName": "First Name",
      "lastName": "Last Name",
      "unitAmount": "Unit Amount",
      "quantity": "Quantity",
      "lineTotal": "Line Total",
      "orderTransactionsCode": "Order Transactions Code",
      "calculateUnitAmountTargetType": "Calculate Unit Amount Target Type",
      "calculateUnitAmountTargetId": "Calculate Unit Amount Target Id",
      "couponTokensId": "Coupon Tokens Id",
      "couponsCode": "Coupons Code",
      "skuCode": "SKU Code",
      "productTitleZh": "Product Title(Chinese)",
      "productTitleEn": "Product Title(English)",
      "cartLinePropertyFnbOperatorsId": "Cart Line Property Fnb Operators Id",
      "orderLinePropertyStaffNumber": "Order Line Property Staff Number",
      "orderLinePropertyOrderTransactionsId": "Order Line Property Order Transactions Id",
      "orderManualCartDiscountOrderLinePropertiesId": "Order Manual Cart Discount Order Line Properties Id",
      "orderManualCartDiscountsId": "Order Manual Cart Discounts Id",
      "omcdOrderLevelId": "Order Manual Cart Discount Order Level Id",
      "itemCreatedAt": "Item Created At"
    },
    "filter": {
      "store": {
        "all": "ALL",
        "name": "Store Code"
      },
      "orderCompletedAt": {
        "date": "Order Completed At",
      },
      "referenceNumber": "Order Reference Number"
    }
  },
  "orderSettlement": {
    "orderSettlementByPaymentType":{
      "name": "Order Settlement By Payment Type",
      "tableHeader": {
        "shipmentStoresCode": "Stores Code",
        "referenceNumber": "Order Reference Number",
        "completedAt": "Order Completed At",
        "confirmedAt": "Order Confirmed At",
        "createdAt": "Order Created At",
        "state": "Order State",
        "paymentState": "Payment State",
        "testOrder": "Test Order",
        "received": "Received",
        "tipOrCharge": "Tip/Charge",
        "amount": "Amount",
        "currenciesCode": "Currencies Code",
        "paymentProviderCode": "Payment Provider Code",
        "paymentProviderName": "Payment Provider Name",
        "providerPaymentType": "Provider Payment Type",
        "staffNumber": "Staff Number",
        "firstName": "First Name",
        "lastName": "Last Name",
        "commerceChannelsCode": "Commerce Channels Code",
        "holdAt": "Order Hold At",
        "reservedAt": "Order Reserved At",
        "declinedAt": "Order Declined At",
        "waitingForApprovalAt": "Order Waiting For Approval At",
        "cancelledAt": "Order Cancelled At",
        "errorAt": "Order Error At",
        "voidInProgressAt": "Order Void In Progress At",
        "voidCompletedAt": "Order Void Completed At"
      },
    },
    "orderSettlementByOrder":{
      "name": "Order Settlement By Order",
      "tableHeader": {
        "shipmentStoresCode": "Stores Code",
        "referenceNumber": "Order Reference Number",
        "completedAt": "Order Completed At",
        "confirmedAt": "Order Confirmed At",
        "createdAt": "Order Created At",
        "billNumber": "Order Bill Number",
        "state": "Order State",
        "paymentState": "Payment State",
        "testOrder": "Test Order",
        "received": "Received",
        "tipOrCharge": "Tip/Charge",
        "amount": "Amount",
        "currenciesCode": "Currencies Code",
        "staffNumber": "Staff Number",
        "firstName": "First Name",
        "lastName": "Last Name",
        "commerceChannelsCode": "Commerce Channels Code",
        "holdAt": "Order Hold At",
        "reservedAt": "Order Reserved At",
        "declinedAt": "Order Declined At",
        "waitingForApprovalAt": "Order Waiting For Approval At",
        "cancelledAt": "Order Cancelled At",
        "errorAt": "Order Error At",
        "voidInProgressAt": "Order Void In Progress At",
        "voidCompletedAt": "Order Void Completed At"
      },
    },
    "filter": {
      "store": {
        "all": "ALL",
        "name": "Store Code"
      },
      "orderCreatedAt": {
        "startDate": "Order Created At From Date",
        "to": "to",
        "endDate": "Order Created At End Date"
      },
      "paymentProviderCode": "Payment Provider Code",
      "staffNumber": "Staff Number",
      "orderState": "Order State",
      "paymentState": "Payment State"
    },
    "paymentCodeMapping":{
      "vsm": "vsm - VISA (Manual);",
      "msm": "msm - MASTER (Manual);",
      "CV": "CV - Cash Voucher;",
      "CU": "CU - UNION PAY (Manual);",
      "aem": "aem - AMERICAN EXPRESS (Manual);",
      "CASH_HKD": "CASH_HKD - Cash(HKD);",
      "octopus": "octopus - Octopus (Manual);",
      "alipay_hkd_manual": "alipay_hkd_manual - Alipay HKD (Manual);",
      "wechat_pay_hkd_manual": "wechat_pay_hkd_manual - WeChat Pay HKD (Manual);",
    }
  },
  "storeStaffSalesPerformanceSummaryPage": {
    "name": "Store Staff Sales Performance Summary",
    "tableHeader": {
      "shipFromStoreCode": "Store Code",
      "lineTotal": "Line Total",
      "quantity": "Quantity",
      "PPA": "Per Person Average",
      "staffNumber": "Staff Number",
      "firstName": "First Name",
      "lastName": "Last Name",
      "dineInNumberOfPeople": "Dine In Number Of People",
    },
    "filter": {
      "store": {
        "all": "ALL",
        "name": "Store Code"
      },
      "orderCompletedAt": {
        "startDate": "Order Completed At From Date",
        "to": "to",
        "endDate": "Order Completed At End Date"
      }
    }
  },
  "SKUSalesDetailsHistPage": {
    "SKUSalesDetails": {
      "name": "SKU Sales Details",
      "tableHeader": {
        "shipFromStoreCode": "Store Code",
        "skuCode": "SKU Code",
        "skuInternalUseTitle": "SKU Internal Use Title",
        "skuCost": "SKU Cost",
        "productTitleEnHk": "Product Title (English)",
        "productTitleZhHk": "Product Title (Chinese)",
        "code": "RSM Code",
        "lineTotal": "Line Total",
        "quantity": "Quantity"
      }
    },
    "SKUSalesByDateDetails": {
      "name": "SKU Sales By Date Details",
      "tableHeader": {
        "ordersCompletedAt": "Order Complated Date",
        "lineTotal": "Line Total",
        "quantity": "Quantity"
      }
    },
    "filter": {
      "store": {
        "all": "ALL",
        "name": "Store Code"
      },
      "orderCompletedAt": {
        "startDate": "Order Completed At From Date",
        "to": "to",
        "endDate": "Order Completed At End Date"
      },
      "rsmCode": "RSM Code",
      "skuCode": "SKU Code"
    }
  },
  "SKUSalesDetailsPage": {
    "name": "SKU Sales Details",
    "tableHeader": {
      "shipFromStoreCode": "Store Code",
      "skuCode": "SKU Code",
      "skuCost": "SKU Cost",
      "skuInternalUseTitle": "SKU Internal Use Title",
      "productTitle": "Product Title",
      "code": "RSM Code",
      "lineTotal": "Line Total",
      "quantity": "Quantity"
    },
    "filter": {
      "store": {
        "all": "ALL",
        "name": "Store Code"
      },
      "orderCompletedAt": {
        "date": "Order Completed At",
      },
      "rsmCode": "RSM Code",
      "skuCode": "SKU Code"
    }
  },
  "timePeriodSummaryHistPage": {
    "timePeriodSummaryBreakfastDineIn": {
      "name": "Time Period Summary - Breakfast(DineIn)",
      "tableHeader": {
        "count": "Order Count",
        "sumDineInNumberOfPeople": "Dine In Number Of People",
        "avgBillAmount": "Average Bill Amount",
        "sumNetSales": "Net Sales",
        "perPersonSpending": "Per Person Spending",
        "cartsCreatedAt": "Cart Created At"
      }
    },
    "timePeriodSummaryLunchDineIn": {
      "name": "Time Period Summary - Lunch(DineIn)",
      "tableHeader": {
        "count": "Order Count",
        "sumDineInNumberOfPeople": "Dine In Number Of People",
        "avgBillAmount": "Average Bill Amount",
        "sumNetSales": "Net Sales",
        "perPersonSpending": "Per Person Spending",
        "cartsCreatedAt": "Cart Created At"
      }
    },
    "timePeriodSummaryTeaDineIn": {
      "name": "Time Period Summary - Tea(DineIn)",
      "tableHeader": {
        "count": "Order Count",
        "sumDineInNumberOfPeople": "Dine In Number Of People",
        "avgBillAmount": "Average Bill Amount",
        "sumNetSales": "Net Sales",
        "perPersonSpending": "Per Person Spending",
        "cartsCreatedAt": "Cart Created At"
      }
    },
    "timePeriodSummaryDinnerDineIn": {
      "name": "Time Period Summary - Dinner(DineIn)",
      "tableHeader": {
        "count": "Order Count",
        "sumDineInNumberOfPeople": "Dine In Number Of People",
        "avgBillAmount": "Average Bill Amount",
        "sumNetSales": "Net Sales",
        "perPersonSpending": "Per Person Spending",
        "cartsCreatedAt": "Cart Created At"
      }
    },
    "timePeriodSummaryBreakfastTakeaway": {
      "name": "Time Period Summary - Breakfast(Takeaway)",
      "tableHeader": {
        "count": "Order Count",
        "sumDineInNumberOfPeople": "Dine In Number Of People",
        "avgBillAmount": "Average Bill Amount",
        "sumNetSales": "Net Sales",
        "perPersonSpending": "Per Person Spending",
        "cartsCreatedAt": "Cart Created At"
      }
    },
    "timePeriodSummaryLunchTakeaway": {
      "name": "Time Period Summary - Lunch(Takeaway)",
      "tableHeader": {
        "count": "Order Count",
        "sumDineInNumberOfPeople": "Dine In Number Of People",
        "avgBillAmount": "Average Bill Amount",
        "sumNetSales": "Net Sales",
        "perPersonSpending": "Per Person Spending",
        "cartsCreatedAt": "Cart Created At"
      }
    },
    "timePeriodSummaryTeaTakeaway": {
      "name": "Time Period Summary - Tea(Takeaway)",
      "tableHeader": {
        "count": "Order Count",
        "sumDineInNumberOfPeople": "Dine In Number Of People",
        "avgBillAmount": "Average Bill Amount",
        "sumNetSales": "Net Sales",
        "perPersonSpending": "Per Person Spending",
        "cartsCreatedAt": "Cart Created At"
      }
    },
    "timePeriodSummaryDinnerTakeaway": {
      "name": "Time Period Summary - Dinner(Takeaway)",
      "tableHeader": {
        "count": "Order Count",
        "sumDineInNumberOfPeople": "Dine In Number Of People",
        "avgBillAmount": "Average Bill Amount",
        "sumNetSales": "Net Sales",
        "perPersonSpending": "Per Person Spending",
        "cartsCreatedAt": "Cart Created At"
      }
    },
    "timePeriodSummaryDetail": {
      "name": "Time Period Summary Detail",
      "tableHeader": {
        "shipFromStoreCode": "Store Code",
        "commerceChannelCode": "Commerce Channel Code",
        "cartsCreatedAtHour": "Open Table At Hour",
        "sumGrossSales": "Gross Sales",
        "sumDiscounts": "Discounts",
        "sumShippingFees": "Shipping Fees",
        "sumServiceCharges": "Service Charges",
        "sumMembershipCharges": "Membership Charges",
        "sumOrderAmountRounding": "Order Rounding Amount",
        "sumOrderTotal": "Order Total",
        "count": "Order Count",
        "sumDineInNumberOfPeople": "Dine In Number Of People",
        "avgBillAmount": "Average Bill Amount",
        "sumNetSales": "Net Sales",
        "perPersonSpending": "Per Person Spending",
        "orderSource": "Order Source"
      }
    },
    "FnBTotalAndDiscountBreakDown": {
      "name": "FnB Total And Discount Breakdown",
      "tableHeader": {
        "storeCode": "Store Code",
        "openTableTime": "Open Table Time",
        "closeBillTime": "Close Bill Time",
        "dineInTableNumber": "Dine In Table Number",
        "serviceMode": "Service Mode",
        "commerceChannelCode": "Commerce Channel Code",
        "orderGrossTotal": "Order Gross Total",
        "foodGrossTotal": "Food Gross Total",
        "bvGrossTotal": "BV Gross Total",
        "rounding": "Rounding",
        "serviceCharge": "Service Charge",
        "discountTotal": "Discount Total",
        "allotedFoodDiscount": "Alloted Food Discount",
        "allotedBvDiscount": "Alloted BV Discount",
        "unallottableDiscount": "Unallottable Discount",
        "orderNetTotal": "Order Net Total"
      }
    },
    "discountBreakdown": {
      "name": "Discount Breakdown",
      "tableHeader": {
        "shipFromStoreCode": "Store Code",
        "discountCode": "Discount Code",
        "discountNameEnHk": "Discount Name (English)",
        "discountNameZhHk": "Discount Name (Chinese)",
        "lineTotal": "Line Total",
        "quantity": "Quantity",
        "totalPrice": "Revenue Brought"
      }
    },
    "filter": {
      "store": {
        "all": "ALL",
        "name": "Store Code"
      },
      "orderCompletedAt": {
        "startDate": "Start Date",
        "to": "to",
        "endDate": "End Date"
      }
    }
  },
  "paymentByStoreTypeBreakdownPage":{
    "name": "Payment By Store and Type Breakdown",
    "tableHeader": {
      "referenceNumber": "Order Reference Number",
      "cartReferenceNumber": "Order Cart Reference Number",
      "orderCompletedAt": "Order Completed At",
      "code": "Payment Provider Coder",
      "internalUseTitle": "Payment Provider Name",
      "state": "Payment State",
      "amount": "Amount",
      "tip": "Tip",
      "grandTotal": "Grand Total",
      "fullRefundedAt": "Payment Refund At",
      "paymentCompletedAt": "Payment Completed At"
    },
    "filter": {
      "store": {
        "all": "ALL",
        "name": "Store Code"
      },
      "orderCompletedAt": {
        "date": "Order Completed At",
      },
      "paymentProviderCode": "Payment Provider Code",
    },
    "paymentCodeMapping":{
      "vsm": "vsm - VISA (Manual);",
      "msm": "msm - MASTER (Manual);",
      "CV": "CV - Cash Voucher;",
      "CU": "CU - UNION PAY (Manual);",
      "aem": "aem - AMERICAN EXPRESS (Manual);",
      "CASH_HKD": "CASH_HKD - Cash(HKD);",
      "octopus": "octopus - Octopus (Manual);",
      "alipay_hkd_manual": "alipay_hkd_manual - Alipay HKD (Manual);",
      "wechat_pay_hkd_manual": "wechat_pay_hkd_manual - WeChat Pay HKD (Manual);",
    }
  },
  "outstandingTablePage":{
    "detail": {
      "name": "Outstanding Table Detail",
      "tableHeader": {
        "sumLineTotal": "Net Sales",
        "storeCode": "Store Code",
        "dineInTableNumber": "Dine In Table Number",
        "dineInTableSubNumber": "Dine In Table Sub Number",
        "staffNumber": "Staff Number",
        "firstName": "Staff First Name",
        "lastName": "Staff Last Name"
      }
    },
    "netSales": {
      "name": "Outstanding Table Net Sales",
    },
    "filter": {
      "store": {
        "all": "ALL",
        "name": "Store Code"
      },
      "cartCreatedAt": {
        "date": "Open Table At",
      }
    },
  },
  "voidItemDetail": {
    "name": "Void Item Detail",
    "filter": {
      "store": {
        "all": "ALL",
        "name": "Store Code"
      },
      "softDeleteAt": {
        "date": "Soft Deleted At",
      },
    },
    "tableHeader": {
      "skuCode": "SKU Code",
      "sukTitle": "SKU Internal Title",
      "productCode": "Product Code",
      "productTitle": "Product Title",
      "productType": "Product Type",
      "storeCode": "Store Code",
      "quantity": "Quantity"
    }
  },
  "timePeriodSummaryPage": {
    "timePeriodSummaryBreakfastDineIn": {
      "name": "Time Period Summary - Breakfast(DineIn)",
      "tableHeader": {
        "count": "Order Count",
        "sumDineInNumberOfPeople": "Dine In Number Of People",
        "avgBillAmount": "Average Bill Amount",
        "sumNetSales": "Net Sales",
        "perPersonSpending": "Per Person Spending",
        "cartsCreatedAt": "Cart Created At"
      }
    },
    "timePeriodSummaryLunchDineIn": {
      "name": "Time Period Summary - Lunch(DineIn)",
      "tableHeader": {
        "count": "Order Count",
        "sumDineInNumberOfPeople": "Dine In Number Of People",
        "avgBillAmount": "Average Bill Amount",
        "sumNetSales": "Net Sales",
        "perPersonSpending": "Per Person Spending",
        "cartsCreatedAt": "Cart Created At"
      }
    },
    "timePeriodSummaryTeaDineIn": {
      "name": "Time Period Summary - Tea(DineIn)",
      "tableHeader": {
        "count": "Order Count",
        "sumDineInNumberOfPeople": "Dine In Number Of People",
        "avgBillAmount": "Average Bill Amount",
        "sumNetSales": "Net Sales",
        "perPersonSpending": "Per Person Spending",
        "cartsCreatedAt": "Cart Created At"
      }
    },
    "timePeriodSummaryDinnerDineIn": {
      "name": "Time Period Summary - Dinner(DineIn)",
      "tableHeader": {
        "count": "Order Count",
        "sumDineInNumberOfPeople": "Dine In Number Of People",
        "avgBillAmount": "Average Bill Amount",
        "sumNetSales": "Net Sales",
        "perPersonSpending": "Per Person Spending",
        "cartsCreatedAt": "Cart Created At"
      }
    },
    "timePeriodSummaryBreakfastTakeaway": {
      "name": "Time Period Summary - Breakfast(Takeaway)",
      "tableHeader": {
        "count": "Order Count",
        "sumDineInNumberOfPeople": "Dine In Number Of People",
        "avgBillAmount": "Average Bill Amount",
        "sumNetSales": "Net Sales",
        "perPersonSpending": "Per Person Spending",
        "cartsCreatedAt": "Cart Created At"
      }
    },
    "timePeriodSummaryLunchTakeaway": {
      "name": "Time Period Summary - Lunch(Takeaway)",
      "tableHeader": {
        "count": "Order Count",
        "sumDineInNumberOfPeople": "Dine In Number Of People",
        "avgBillAmount": "Average Bill Amount",
        "sumNetSales": "Net Sales",
        "perPersonSpending": "Per Person Spending",
        "cartsCreatedAt": "Cart Created At"
      }
    },
    "timePeriodSummaryTeaTakeaway": {
      "name": "Time Period Summary - Tea(Takeaway)",
      "tableHeader": {
        "count": "Order Count",
        "sumDineInNumberOfPeople": "Dine In Number Of People",
        "avgBillAmount": "Average Bill Amount",
        "sumNetSales": "Net Sales",
        "perPersonSpending": "Per Person Spending",
        "cartsCreatedAt": "Cart Created At"
      }
    },
    "timePeriodSummaryDinnerTakeaway": {
      "name": "Time Period Summary - Dinner(Takeaway)",
      "tableHeader": {
        "count": "Order Count",
        "sumDineInNumberOfPeople": "Dine In Number Of People",
        "avgBillAmount": "Average Bill Amount",
        "sumNetSales": "Net Sales",
        "perPersonSpending": "Per Person Spending",
        "cartsCreatedAt": "Cart Created At"
      }
    },
    "timePeriodSummaryDetail": {
      "name": "Time Period Summary Detail",
      "tableHeader": {
        "shipFromStoreCode": "Store Code",
        "commerceChannelCode": "Commerce Channel Code",
        "cartsCreatedAtHour": "Open Table At Hour",
        "sumGrossSales": "Gross Sales",
        "sumDiscounts": "Discounts",
        "sumShippingFees": "Shipping Fees",
        "sumServiceCharges": "Service Charges",
        "sumMembershipCharges": "Membership Charges",
        "sumOrderAmountRounding": "Order Rounding Amount",
        "sumOrderTotal": "Order Total",
        "count": "Order Count",
        "sumDineInNumberOfPeople": "Dine In Number Of People",
        "avgBillAmount": "Average Bill Amount",
        "sumNetSales": "Net Sales",
        "perPersonSpending": "Per Person Spending",
        "orderSource": "Order Source"
      }
    },
    "FnBTotalAndDiscountBreakDown": {
      "name": "FnB Total And Discount Breakdown",
      "tableHeader": {
        "storeCode": "Store Code",
        "openTableTime": "Open Table Time",
        "closeBillTime": "Close Bill Time",
        "dineInTableNumber": "Dine In Table Number",
        "serviceMode": "Service Mode",
        "commerceChannelCode": "Commerce Channel Code",
        "orderGrossTotal": "Order Gross Total",
        "foodGrossTotal": "Food Gross Total",
        "bvGrossTotal": "BV Gross Total",
        "rounding": "Rounding",
        "serviceCharge": "Service Charge",
        "discountTotal": "Discount Total",
        "allotedFoodDiscount": "Alloted Food Discount",
        "allotedBvDiscount": "Alloted BV Discount",
        "unallottableDiscount": "Unallottable Discount",
        "orderNetTotal": "Order Net Total"
      }
    },
    "discountBreakdownByStaff": {
      "name": "Discount Breakdown By Staff",
      "tableHeader": {
        "shipFromStoreCode": "Store Code",
        "staffNumber": "Staff Number",
        "firstName": "First Name",
        "lastName": "Last Name",
        "discountCode": "Discount Code",
        "discountName": "Discount Name",
        "lineTotal": "Line Total",
        "quantity": "Quantity"
      }
    },
    "filter": {
      "store": {
        "all": "ALL",
        "name": "Store Code"
      },
      "orderCompletedAt": {
        "date": "Date",
      }
    }
  },
  "discountBreakdownByStaff": {
    "name": "Discount Breakdown By Staff",
    "tableHeader": {
      "shipFromStoreCode": "Store Code",
      "staffNumber": "Staff Number",
      "firstName": "First Name",
      "lastName": "Last Name",
      "discountCode": "Discount Code",
      "discountName": "Discount Name",
      "lineTotal": "Line Total",
      "quantity": "Quantity"
    },
    "filter": {
      "store": {
        "all": "ALL",
        "name": "Store Code"
      },
      "orderCompletedAt": {
        "date": "Date",
      },
      "discountCode": "Discount Code"
    }
  },
  "staffStatisticsPage": {
    "name": "Per Person Spending by Staff",
    "tableHeader": {
      "staffNumber": "Staff Number",
      "firstName": "First Name",
      "lastName": "Last Name",
      "perPersonSpending": "Per Person Spending"
    },
    "filter": {
      "store": {
        "all": "ALL",
        "name": "Store Code"
      },
      "orderCompletedAt": {
        "date": "Date",
      },
      "staffNumber": "Staff Number"
    }
  },
  "staffTenderSummaryPage": {
    "paymentByStoreStaff": {
      "name": "Payment By Store and Staff",
      "tableHeader": {
        "storeCode": "Store Code",
        "paymentProviderCode": "Payment Provider Code",
        "staffNumber": "Staff Number",
        "firstName": "First Name",
        "lastName": "Last Name",
        "orderCount": "Order Count",
        "sumAmount": "Amount",
        "sumTip": "Tip"
      }
    },
    "discountBreakdownByStaff": {
      "name": "Discount Breakdown By Staff",
      "tableHeader": {
        "shipFromStoreCode": "Store Code",
        "staffNumber": "Staff Number",
        "firstName": "First Name",
        "lastName": "Last Name",
        "discountCode": "Discount Code",
        "discountName": "Discount Name",
        "lineTotal": "Line Total",
        "quantity": "Quantity"
      }
    },
    "membershipTenderDetailsByStaff": {
      "name": "Membership Tender Details By Staff",
      "tableHeader": {
        "shipFromStoreCode": "Store Code",
        "staffNumber": "Staff Number",
        "firstName": "First Name",
        "lastName": "Last Name",
        "paymentProviderCode": "Payment Provider Code",
        "lineTotal": "Line Total",
        "quantity": "Quantity"
      }
    },
    "filter": {
      "store": {
        "all": "ALL",
        "name": "Store Code"
      },
      "orderCompletedAt": {
        "date": "Date",
      },
      "staffNumber": "Staff Number"
    }
  },
  "userExport":{
    "name": "User Export",
    "tableHeader": {
      "totalSpend": "Total Spend",
      "orderCount": "Order Count",
      "membershipCode": "Membership Code",
      "firstName": "User First Name",
      "lastName": "User Last Name",
      "rankCode": "User Rank Code",
      "sex": "Sex",
      "phone": "Phone",
      "email": "Email",
      "dateOfBirth": "Date Of Birth",
      "isActive": "Is Active",
      "isDeleted": "Is Deleted",
      "remark": "Remark",
      "createdAt": "Created At",
      "updatedAt": "Updated At",
      "modifiedAt": "Modified At",
      "rankExpireAt": "Rank Expire At",
      "userPreferDistrict": "User Prefere District",
      "userAgreementPics": "User Accept Receive Promotion",
      "userAgreementTerms": "User Accept Agreement Terms",
      "point": "Point",
      "lastOrderCompletedAt": "Last Purchase Date",
      "referredBy": "Referred By",
      "averageTotalPrice": "Average Spending"
    },
    "filter": {
      "updatedAt": {
        "startDate": "Updated At From Date",
        "to": "to",
        "endDate": "Updated At End Date"
      },
      "createdAt": {
        "startDate": "Created At From Date",
        "to": "to",
        "endDate": "Created At End Date"
      },
      "membershipCode": "Membership Code",
      "email": "Email",
      "phone": "Phone",
      "userFirstName": "User First Name",
      "userLastName": "User Last Name",
      "userAgreementPics": "User Accept Receive Promotion",
      "userAgreementTerms": "User Accept Agreement Terms"
    },
  },
  "userQuery":{
    "userExport":{
      "name": "User Information",
      "tableHeader": {
        "totalSpend": "Total Spend",
      "orderCount": "Order Count",
      "membershipCode": "Membership Code",
      "firstName": "User First Name",
      "lastName": "User Last Name",
      "rankCode": "User Rank Code",
      "sex": "Sex",
      "phone": "Phone",
      "email": "Email",
      "dateOfBirth": "Date Of Birth",
      "isActive": "Is Active",
      "isDeleted": "Is Deleted",
      "remark": "Remark",
      "createdAt": "Created At",
      "updatedAt": "Updated At",
      "modifiedAt": "Modified At",
      "rankExpireAt": "Rank Expire At",
      "userPreferDistrict": "User Prefere District",
      "userAgreementPics": "User Accept Receive Promotion",
      "userAgreementTerms": "User Accept Agreement Terms",
      "point": "Point",
      "lastOrderCompletedAt": "Last Purchase Date",
      "referredBy": "Referred By",
      "averageTotalPrice": "Average Spending"
      },
    },
    "membershipTransaction":{
      "name": "Membership Transaction",
      "tableHeader": {
        "rankCode": "User Rank Code",
        "effectiveAt": "Effective At",
        "expireAt": "Expire At",
        "isDeleted": "Is Deleted",
        "firstName": "User Name",
      },
    },
    "orderHistory":{
      "name": "Order History",
      "tableHeader": {
        "ordersCompletedAt": "Orders Completed At",
        "shipFromStoreCode": "Store Code",
        "referenceNumber": "Reference Number",
        "grossSales": "Gross Sales",
        "discounts": "Discounts",
        "serviceCharges": "Service Charges",
        "orderAmountRounding": "Order Amount Rounding",
        "membershipCharges": "Membership Charges",
        "orderTotal": "Order Total",
      },
    },
    "loyaltyPointTransaction":{
      "name": "Loyalty Point Transaction",
      "tableHeader": {
      "id": "ID", 
        "createdAt": "Created At",
        "code": "Code",
        "opening": "Opening", 
        "points": "Points",
        "balance": "Balance",
        "isDeleted": "Is Deleted", 
        "isActive": "Is Active", 
        "activeAt": "Actived At",
        "expireAt": "Expired At", 
        "updatedAt": "Updated At",
      },
    },
    "usersMostConsumeStore": {
      "name": "User Most Consume Store",
      "tableHeader": {
        "storeCode": "Store Code",
        "count": "Count"
      }
    },
    "filter": {
      "membershipCode": "Membership Code",
    },
    "enterMembershipCode": "Please entere membership code and press enter to query the member.",
  },
  "dailyOperationHist": {
    "titleMapping": {
      "sumGrossSales": "Total Gross Sales",
      "sumDiscounts": "Total Discounts",
      "sumShippingFees": "Total Shipping Fees",
      "sumServiceCharges": "Total Service Charges",
      "sumOrderAmountRounding": "Total Order Amount Rounding",
      "sumMembershipCharges": "Total Membership Sales",
      "sumOrderTotal": "Total Revenue",
      "sumNetSales": "Total Net Sales",
      "orderCount": "Total Order Count",
      "sumDineInNumberOfPeople": "Total PAX",
      "avgBillAmount": "Average Order Amount",
      "perPersonSpending": "Per Person Spending",
      "voidOrderCount": "Total Void Order Count",
      "voidSumOrderTotal": "Total Void Order Amount",
      "voidItemCount": "Void Item Counts",
      "sumTip": "Total Tips",
      "outStandingNetsales": "Outstanding Table Net Sales",
      "totalFoodGrossSales": "Total Food Gross Sales",
      "totalBeverageGrossSales": "Total Beverage Gross Sales",
      "totalOtherGrossSales": "Total Other Gross Sales",
    },
    "netSalesByStore":{
      "name": "Net Sales By Store",
      "tableHeader": {
        "count": "Order Count",
        "sumOrderTotal": "Order Total",
        "shipFromStoreCode": "Store Code",
        "orderSource": "Order Source"
      }
    },
    "netSalesByStoreEM":{
      "name": "Net Sales By Store Exclude Membership",
      "tableHeader": {
        "count": "Order Count",
        "sumNetSales": "Order Total",
        "shipFromStoreCode": "Store Code",
        "orderSource": "Order Source"
      }
    },
    "paymentByStoreType":{
      "name": "Payment By Store and Type",
      "tableHeader": {
        "sumAmount": "Amount",
        "sumTip": "Tip",
        "grandTotal": "Grand Total",
        "countOrderId": "Order Count",
        "shipmentsFromStoreCode": "Store Code",
        "paymentProviderCode": "Payment Provider Code",
        "name": "Payment Provider Name",
        "currencyCode": "Currency Code",
        "state": "State"
      }
    },
    "tenderByStoreStaff":{
      "name": "Tender By Store and Staff",
      "tableHeader": {
        "sumAmount": "Amount",
        "sumTip": "Tip",
        "countOrderId": "Order Count",
        "shipmentsFromStoreCode": "Store Code",
        "paymentProviderCode": "Payment Provider Code",
        "name": "Payment Provider Name",
        "currencyCode": "Currency Code",
        "state": "State",
        "staffNumber": "Staff Number",
        "firstName": "First Name",
        "lastName": "Last Name",
      }
    },
    "discountBreakdown":{
      "name": "Discount Breakdown",
      "tableHeader": {
        "shipFromStoreCode": "Store Code",
        "discountCode": "Discount Code",
        "discountNameEnHk": "Discount Name (English)",
        "discountNameZhHk": "Discount Name (Chinese)",
        "lineTotal": "Line Total",
        "quantity": "Quantity",
        "totalPrice": "Revenue Brought"
      },
    },
    "SKUSalesDetails":{
      "name": "SKU Sales Details",
      "tableHeader": {
        "skuCode": "SKU Code",
        "skuInternalUseTitle": "SKU Internal Use Title",
        "skuCost": "SKU Cost",
        "productTitleEnHk": "Product Title (English)",
        "productTitleZhHk": "Product Title (Chinese)",
        "code": "RSM Code",
        "lineTotal": "Line Total",
        "quantity": "Quantity"
      },
    },
    "HighlightCategorySales":{
      "name": "Highlight Category Sales",
      "tableHeader": {
        "shipFromStoreCode": "Store Code",
        "skuCode": "SKU Code",
        "skuInternalUseTitle": "SKU Internal Use Title",
        "productTitleEnHk": "Product Title (English)",
        "productTitleZhHk": "Product Title (Chinese)",
        "code": "RSM Code",
        "type": "Category Code",
        "lineTotal": "Line Total",
        "quantity": "Quantity"
      },
    },
    "MembershipSalesByType":{
      "name": "Membership Sales By Type",
      "tableHeader": {
        "shipFromStoreCode": "Store Code",
        "skuCode": "SKU Code",
        "skuInternalUseTitle": "SKU Internal Use Title",
        "lineTotal": "Line Total",
        "quantity": "Quantity"
      },
    },
    "MembershipSalesDetails":{
      "name": "Membership Sales Details",
      "tableHeader": {
        "shipFromStoreCode": "Store Code",
        "skuCode": "SKU Code",
        "skuInternalUseTitle": "SKU Internal Use Title",
        "staffNumber": "Staff Number",
        "firstName": "First Name",
        "lastName": "Last Name",
        "ordersCompletedAt": "Order Completed At",
        "unitAmount": "Unit Amount",
        "quantity": "Quantity",
        "lineTotal": "Line Total",
      },
    },
    "MembershipTenderSummaries":{
      "name": "Membership Tender Summaries",
      "tableHeader": {
        "shipFromStoreCode": "Store Code",
        "paymentProviderCode": "Payment Provider Code",
        "lineTotal": "Line Total",
        "quantity": "Quantity"
      },
    },
    "filter": {
      "store": {
        "all": "ALL",
        "name": "Store Code"
      },
      "orderCompletedAt": {
        "startDate": "Order Completed At From Date",
        "to": "to",
        "endDate": "Order Completed At End Date"
      }
    },
    "text":{
      "paymentCodeMapping":{
        "vsm": "vsm - VISA (Manual);",
        "msm": "msm - MASTER (Manual);",
        "CV": "CV - Cash Voucher;",
        "CU": "CU - UNION PAY (Manual);",
        "aem": "aem - AMERICAN EXPRESS (Manual);",
        "CASH_HKD": "CASH_HKD - Cash(HKD);",
        "octopus": "octopus - Octopus (Manual);",
        "alipay_hkd_manual": "alipay_hkd_manual - Alipay HKD (Manual);",
        "wechat_pay_hkd_manual": "wechat_pay_hkd_manual - WeChat Pay HKD (Manual);",
      },
      "tenderWarning": "WARNING: Inaccurate if have mixed tender orders"
    }
  },
  "dailyOperationHistPrint": {
    "titleMapping": {
      "sumGrossSales": "Total Gross Sales",
      "sumDiscounts": "Total Discounts",
      "sumShippingFees": "Total Shipping Fees",
      "sumServiceCharges": "Total Service Charges",
      "sumOrderAmountRounding": "Total Order Amount Rounding",
      "sumMembershipCharges": "Total Membership Sales",
      "sumNetSales": "Total Net Sales",
      "orderCount": "Total Order Count",
      "sumDineInNumberOfPeople": "Total PAX",
      "avgBillAmount": "Average Order Amount",
      "perPersonSpending": "Per Person Spending",
      "voidOrderCount": "Total Void Order Count",
      "voidSumOrderTotal": "Total Void Order Amount",
      "voidItemCount": "Void Item Counts",
      "sumTip": "Total Tips",
      "totalFoodGrossSales": "Total Food Gross Sales",
      "totalBeverageGrossSales": "Total Beverage Gross Sales",
      "totalOtherGrossSales": "Total Other Gross Sales",
      "foodPercentage": "Total Food Gross Sales Percentage",
      "beveragePercentage": "Total Beverage Gross Sales Percentage",
      "otherPercentage": "Total Other Gross Sales Percentage",
      "totalGrossSalesChart": "Total Gross Sales Breakdown",
    },
    "totalGrossSalesChart": {
      "labels": {
        "MvOrderTransactions.foodPercentage": "Food",
        "MvOrderTransactions.beveragePercentage": "Beverage",
        "MvOrderTransactions.otherPercentage": "Other",
      },
    },
    "netSalesByStoreEM":{
      "name": "Net Sales By Store Exclude Membership",
      "tableHeader": {
        "count": "Order Count",
        "sumNetSales": "Order Total",
        "shipFromStoreCode": "Store Code",
        "orderSource": "Order Source"
      }
    },
    "paymentByStoreType":{
      "name": "Payment By Store and Type",
      "tableHeader": {
        "sumAmount": "Amount",
        "sumTip": "Tip",
        "grandTotal": "Grand Total",
        "countOrderId": "Order Count",
        "shipmentsFromStoreCode": "Store Code",
        "paymentProviderCode": "Payment Provider Code",
        "name": "Payment Provider Name",
        "currencyCode": "Currency Code",
        "state": "State"
      }
    },
    "discountBreakdown":{
      "name": "Discount Breakdown",
      "tableHeader": {
        "shipFromStoreCode": "Store Code",
        "discountCode": "Discount Code",
        "discountNameEnHk": "Discount Name (English)",
        "discountNameZhHk": "Discount Name (Chinese)",
        "lineTotal": "Line Total",
        "quantity": "Quantity",
        "totalPrice": "Revenue Brought"
      },
    },
    "MembershipSalesByType":{
      "name": "Membership Sales By Type",
      "tableHeader": {
        "shipFromStoreCode": "Store Code",
        "skuCode": "SKU Code",
        "skuInternalUseTitle": "SKU Internal Use Title",
        "lineTotal": "Line Total",
        "quantity": "Quantity"
      },
    },
    "MembershipTenderSummaries":{
      "name": "Membership Tender Summaries",
      "tableHeader": {
        "shipFromStoreCode": "Store Code",
        "paymentProviderCode": "Payment Provider Code",
        "lineTotal": "Line Total",
        "quantity": "Quantity"
      },
    },
    "filter": {
      "store": {
        "all": "ALL",
        "name": "Store Code"
      },
      "orderCompletedAt": {
        "startDate": "Order Completed At From Date",
        "to": "to",
        "endDate": "Order Completed At End Date"
      }
    },
    "text":{
      "paymentCodeMapping":{
        "vsm": "vsm - VISA (Manual);",
        "msm": "msm - MASTER (Manual);",
        "CV": "CV - Cash Voucher;",
        "CU": "CU - UNION PAY (Manual);",
        "aem": "aem - AMERICAN EXPRESS (Manual);",
        "CASH_HKD": "CASH_HKD - Cash(HKD);",
        "octopus": "octopus - Octopus (Manual);",
        "alipay_hkd_manual": "alipay_hkd_manual - Alipay HKD (Manual);",
        "wechat_pay_hkd_manual": "wechat_pay_hkd_manual - WeChat Pay HKD (Manual);",
      },
      "tenderWarning": "WARNING: Inaccurate if have mixed tender orders",
      "salesBalances": "Sales Balances",
      "OperatingStatistics": "Operating Statistics",
      "auditTrail": "Audit Trail",
      "salesBreakdown": "Sales Breakdown",
    }
  },
  "userRank": {
    "name": "Membership Count",
    "tableHeader": {
      "rankCode": "Rank Code",
      "count": "Count"
    },
  },
  "StaffAttendancePage": {
    "StaffAttendanceName": "Staff Attendance",
    "StaffAttendanceByDayName": "Staff Attendance By Day",
    "titleMapping": {
      "storeCode": "Store Code",
      "staffNumber": "Staff Number",
      "staffFirstName": "Staff First Name",
      "staffLastName": "Staff Last Name",
      "onDutyStartAt": "On Duty Start At",
      "onDutyEndAt": "On Duty End At",
      "dutyTime": "Duty Time",
      "date": "Date",
    },
    "filter": {
      "store": {
        "all": "ALL",
        "name": "Store Code"
      },
      "onDutyStartAt": {
        "startDate": "On Duty Start At From Date",
        "to": "to",
        "endDate": "On Duty Start At End Date"
      }
    },
  },
  "perDayPage": {
    "netSalesPerDayName": "Net Sales Per Day",
    "membershipSalesPerDayName": "Membership Sales Per Day",
    "paxPerDayName": "PAX Per Day",
    "perPersonSpendingPerDayName": "Per Person Spending Per Day",
    "noOfOrderPerDayName": "Number of Order Per Day",
    "perOrderSpendingPerDayName": "Per Order Spending Per Day",
    "discountPerDayName": "Discount Per Day",
    "beverageGrossSalesPerDayName": "Beverage Gross Sales Per Day",
    "deliverooNetSalesPerDayName": "Deliveroo Net Sales Per Day",
    "openriceNetSalesPerDayName": "Open Rice Net Sales Per Day",
    "foodpandaNetSalesPerDayName": "Foodpanda Net Sales Per Day",
    "brainFreezeNetSalesPerDayName": "BrainFreez Net Sales Per Day",
    "inlineNetSalesPerDayName": "Inline Net Sales Per Day",
    "hktvmallNetSalesPerDayName": "HKTVmall Net Sales Per Day",
    "groupBuyNetSalesPerDayName": "Group Buyer Net Sales Per Day",
    "deliverooServiceChargePerDayName": "Deliveroo Service Charge Per Day",
    "openriceServiceChargePerDayName": "Open Rice Service Charge Per Day",
    "foodpandaServiceChargePerDayName": "Foodpanda Service Charge Per Day",
    "brainFreezeServiceChargePerDayName": "BrainFreez Service Charge Per Day",
    "inlineServiceChargePerDayName": "Inline Service Charge Per Day",
    "hktvmallServiceChargePerDayName": "HKTVmall Service Charge Per Day",
    "groupBuyServiceChargePerDayName": "Group Buyer Service Charge Per Day",
    "timePeriodSummaryBreakfastPerDayName": "Breakfast Net Sales Per Day",
    "timePeriodSummaryLunchPerDayName": "Lunch Net Sales Per Day",
    "timePeriodSummaryTeaPerDayName": "Tea Net Sales Per Day",
    "timePeriodSummaryDinnerPerDayName": "Dinner Net Sales Per Day",
    "daypartAtHomePerDayName": "Day Part And At Home Per Day",
    "tableHeader": {
      "date": "Date",
      "dow": "DoW",
      "netSales": "Net Sales",
      "total": "Total",
      "breakfastNetSales": "Breakfast Net Sales",
      "lunchNetSales": "Lunch Net Sales",
      "teaNetSales": "Tea Net Sales",
      "dinnerNetSales": "Dinner Net Sales",
      "deliverooNetSales": "Deliveroo Net Sales",
      "openriceNetSales": "Open Rice Net Sales",
      "foodpandaNetSales": "Foodpanda Net Sales",
      "brainfreezeNetSales": "BrainFreeze Net Sales",
      "hktvmallNetSales": "HKTVmall Net Sales",
      "inlineNetSales": "Inline Net Sales",
      "sumNetSales": "Total Net Sales",
      "groupBuyNetSales": "Group Buyer Net Sales"
    },
    "filter": {
      "orderCompletedAt": {
        "startDate": "Order Completed At From Date",
        "to": "to",
        "endDate": "Order Completed At End Date"
      }
    }
  },
  "rsmCheckingPage": {
    "name": "RSM Checking",
    "tableHeader": {
      "id": "ID",
      "code": "Code",
      "status": "Status",
      "rsmCategoryCount": "RSM Category Count",
      "rsmCategory": "RSM Category"
    }
  },
  "rsmrCheckingPage": {
    "name": "RSMR Checking",
    "tableHeader": {
      "id": "ID",
      "code": "Code",
      "status": "Status",
      "rsmrCategoryCount": "RSMR Category Count",
      "rsmrCategory": "RSMR Category"
    }
  },
  "discountBreakdownByStaffHist": {
    "name": "Discount Breakdown By Staff",
    "tableHeader": {
      "discountCode": "Discount Code",
      "discountNameEnHk": "Discount Name (English)",
      "discountNameZhHk": "Discount Name (Chinese)",
      "lineTotal": "Line Total",
      "quantity": "Quantity",
      "shipFromStoreCode": "Store Code",
      "staffNumber": "Staff Number",
      "firstName": "First Name",
      "lastName": "Last Name"
    },
    "filter": {
      "store": {
        "all": "ALL",
        "name": "Store Code"
      },
      "orderCompletedAt": {
        "startDate": "Order Completed At From Date",
        "to": "to",
        "endDate": "Order Completed At End Date"
      },
      "discountCode": "Discount Code"
    }
  },
  "skuCheckingPage": {
    "name": "SKU Checking",
    "filter": {
      "showNull": "Show Null Property Ac",
      "showSkuError": "Show SKU Error"
    }
  },
  "cartDiscountCheckingPage": {
    "name": "Cart Discount Checking",
    "tableHeader": {
      "code": "Discount Code",
      "propertyAc": "Property AC",
      "rsmCode": "RSM Code",
      "rsmrCode": "RSMR Code",
      "startAt": "Start At",
      "endAt": "End At"
    },
    "filter": {
      "showRSMNull": "Show Null RSM Code",
      "showRSMRNull": "Show Null RSMR Code",
      "showPropertyAc": "Show Null Property Ac"
    }
  },
  "dailySalesPage": {
    "name": "Daily Sales",
    "tableHeader": {
      "date": "Date",
      "time": "Time",
      "referenceNumber": "Order Reference Number",
      "netSales": "Net Sales"
    },
    "filter": {
      "store": {
        "all": "ALL",
        "name": "Store Code"
      },
      "orderCompletedAt": {
        "date": "Order Completed At",
      },
    }
  },
  "orderUserExport":{
    "name": "Order Export By Store With User Association",
    "tableHeader": {
      "referenceNumber": "Reference Number",
      "shipmentStoreCode": "Store Code",
      "completedAt": "Completed At",
      "totalPrice": "Total Price",
      "totalSpend": "Total Spend",
      "orderCount": "Order Count",
      "membershipCode": "Membership Code",
      "firstName": "User First Name",
      "lastName": "User Last Name",
      "rankExpireAt": "Rank Expire At",
      "customerRankCode": "User Rank Code",
      "sex": "Sex",
      "phone": "Phone",
      "userEmail": "Email",
      "userCreatedAt": "Member Join Date",
      "pointsBurn": "Point Used",
      "pointsEarn": "Point Earn",
      "dineInNumberOfPeople": "Number of People",
      "dineInDuration": "Dine-in Duration(Minute)",
      "orderType": "Order Type",
      "paymentMethod": "Payment Method"
    },
    "filter": {
      "orderCompletedAt": {
        "startDate": "Completed At From Date",
        "to": "to",
        "endDate": "Completed At End Date"
      },
      "membershipCode": "Membership Code",
      "email": "Email",
      "phone": "Phone",
      "userName": "User Name",
      "storeCode": "Store Code",
    },
  },
  "paymentDayEnd": {
    "name": "Payment Day End",
    "filter": {
      "ordersCompleteDateHkt": {
        "startDate": "Completed At From Date",
        "to": "to",
        "endDate": "Completed At End Date"
      },
      "store": {
        "name": "Store Code"
      }
    },
     "tableHeader": {
      "date": "Date",
      "total": "Total"
    }
  },
  "mPosSelfOrder": {
    "name": "mPOS VS Self Order By Store",
    "tableHeader": {
      "storeCode": "Store Code",
      "mpos": "mPOS",
      "selfOrder": "Self Order"
    },
    "filter": {
      "cartCreatedAt": {
        "startDate": "Cart At From Date",
        "to": "to",
        "endDate": "Cart At End Date"
      },
      "store": {
        "name": "Store Code"
      }
    },
  },
  "cartAuditTrail": {
    "name": "Cart Audit Trailc",
    "tableHeader": {
      "cartId": "Cart ID",
      "cartCommerceChannelCode": "Cart Commerce Channel Code",
      "storeCode": "Store Code",
      "orderId": "Order ID",
      "orderReferenceNumber": "Order Reference Number",
      "orderState": "Order State",
      "orderUpdatedAt": "Order Update dAt",
      "dineInTableNumber": "Dine In Table Number",
      "dineInNumberOfPeople": "Dine In Number Of People",
      "dineInTableSubNumber": "Dine In Table Sub Number",
      "staffFirstName": "Staff First Name",
      "staffLastName": "Staff Last Name",
      "staffNumber": "Staff Number",
      "totalPrice": "Total Price",
      "cartUpdatedAt": "Cart Updated At",
      "cartCreatedAt": "Cart Created At",
      "cartClosedAt": "Cart Closed At",
    },
    "filter": {
      "store": {
        "all": "ALL",
        "name": "Store Code"
      },
      "cartCreatedAt": {
        "startDate": "Cart Created At From Date",
        "to": "to",
        "endDate": "Cart Created At End Date"
      }
    }
  },
}

export default commonEnHK