import React, { useState } from "react";
import Header from '../../components/Header';
import TableComponent from '../../components/TableComponent';
import TextFieldComponent from '../../components/TextFieldComponent';
import Dashboard from '../../components/Dashboard';
import PageHeaderComponent from '../../components/PageHeaderComponent';
import DatePickerComponent from '../../components/DatePickerComponent';
import OmnitechQueries from '../../queries/OmnitechQueries'
import { useTranslation } from 'react-i18next';
import { useCubeQuery } from '@cubejs-client/react';
import { format } from 'date-fns'

const applyFilter = (query, referenceNumberFilter) => {

  let referenceNumberFilterObj = {
    member: "Orders.referenceNumber",
    operator: "contains",
    values: [referenceNumberFilter]
  }


  if (referenceNumberFilter !== '') {
    query = {
      ...query,
      filters: (query.filters || []).concat(referenceNumberFilterObj)
    }
  }

  return query
}

const RtTakeawayDeliveryOrderApprovalTimeTraceQuery = (referenceNumberFilter, startDateFilter, endDateFilter, isRefresh, setRefresh) => {
  const { t } = useTranslation();
  if(startDateFilter == null){
    startDateFilter = new Date()
    startDateFilter.setHours(0,0,0,0);
    endDateFilter = new Date()
    endDateFilter.setHours(23,59,59,999);
  }
  let item = {
    name: t('page.rt.takeawayDeliveryOrderApprovalTimeTrace.name'),
    tableHeaders: {
      "Stores.code": {name: t('page.rt.takeawayDeliveryOrderApprovalTimeTrace.tableHeader.storeCode'), type: "string" },
      "Orders.referenceNumber":  { name: t('page.rt.takeawayDeliveryOrderApprovalTimeTrace.tableHeader.referenceNumber'), type: "string" },
      "Orders.state":  { name: t('page.rt.takeawayDeliveryOrderApprovalTimeTrace.tableHeader.orderState'), type: "string" },
      "Orders.createdAt":  { name: t('page.rt.takeawayDeliveryOrderApprovalTimeTrace.tableHeader.createdAt'), type: "dateTime" },
      "Orders.waitingForApprovalAt":  { name: t('page.rt.takeawayDeliveryOrderApprovalTimeTrace.tableHeader.waitingForApprovalAt'), type: "dateTime" },
      "Orders.confirmedAt":  { name: t('page.rt.takeawayDeliveryOrderApprovalTimeTrace.tableHeader.confirmedAt'), type: "dateTime" },
      "Orders.orderType":  { name: t('page.rt.takeawayDeliveryOrderApprovalTimeTrace.tableHeader.orderType'), type: "string" },
      "CommerceChannels.code":  { name: t('page.rt.takeawayDeliveryOrderApprovalTimeTrace.tableHeader.commerceChannelCode'), type: "string" },
      "Orders.ticketNumber":  { name: t('page.rt.takeawayDeliveryOrderApprovalTimeTrace.tableHeader.ticketNumber'), type: "string" },
    },
    grid: {xs: 12},
    export: { allowExport: true, filename: 'rt_takeaway_delivery_order_approval_time_trace_export_' + format(startDateFilter, 'yyyyMMddHHmmss') + 
      '_' + format(endDateFilter, 'yyyyMMddHHmmss') }
  }
  let query = {
    ...OmnitechQueries.orders.TakeawayDeliveryOrderApprovalTimeTrace.query,
    "timeDimensions": OmnitechQueries.orders.TakeawayDeliveryOrderApprovalTimeTrace.query.timeDimensions.concat(
    {
      "dimension": "Orders.createdAt",
      "dateRange": [ startDateFilter, endDateFilter ]
    })
  }
  query = applyFilter(query, referenceNumberFilter)
  if(isRefresh)
    query = {}
  const result = useCubeQuery(query, {resetResultSetOnChange: true})
  if(isRefresh)
    setRefresh(false)
  return { item, result }
}

const RtTakeawayDeliveryOrderApprovalTimeTracePage = () => {
  const { t } = useTranslation();
  const [ referenceNumberFilter, setReferenceNumberFilter ] = useState('');
  let filterStartDate = new Date()
  filterStartDate.setHours(0,0,0,0);
  let filterEndDate = new Date()
  filterEndDate.setHours(23,59,59,999);
  const [dateRangeFilter, setDateRangeFilter] = useState([filterStartDate, filterEndDate])
  const [startDateFilter, endDateFilter] = dateRangeFilter
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  
  const [ refresh1, setRefresh1 ] = useState(false);

  const { item, result }  = RtTakeawayDeliveryOrderApprovalTimeTraceQuery(referenceNumberFilter, startDateFilter, endDateFilter, refresh1, setRefresh1)

  const refresh = () => {
    setRefresh1(true)
    setPage(0)
  }

  return ([
    <Header />,
    <PageHeaderComponent onClickFunc={refresh} refreshlabel={t('component.refresh')} headerTitle={t('component.header.menu.rtTakeawayDeliveryOrderApprovalTimeTraceReport')} />,
    <Dashboard>
      <DatePickerComponent state={dateRangeFilter} stateChanger={setDateRangeFilter} pageChangerArr={[setPage]}
        dateLabel={t('page.rt.takeawayDeliveryOrderApprovalTimeTrace.filter.orderCreatedAt.date')}/>
      <TextFieldComponent id={"reference_number_filter"} state={referenceNumberFilter} pageChangerArr={[setPage]}
        stateChanger={setReferenceNumberFilter} textLabel={t('page.rt.takeawayDeliveryOrderApprovalTimeTrace.filter.referenceNumber')}/>
    </Dashboard>
    ,
    <Dashboard>
      <TableComponent item={item} result={result} rowsPerPage={rowsPerPage} 
        setRowsPerPage={setRowsPerPage} page={page} setPage={setPage}/>
    </Dashboard>
  ]) 
};

export default RtTakeawayDeliveryOrderApprovalTimeTracePage;