import React, { useState, useContext } from "react";
import Header from '../../components/Header';
import NumberComponent from '../../components/NumberComponent';
import TableComponent from '../../components/TableComponent';
import MultiSelectComponent from '../../components/MultiSelectComponent';
import SelectComponentV2 from '../../components/SelectComponentV2';
import DatePickerComponent from '../../components/DatePickerComponent';
import TextComponent from '../../components/TextComponent';
import Dashboard from '../../components/Dashboard';
import PageHeaderComponent from '../../components/PageHeaderComponent';
import OmnitechQueries from '../../queries/OmnitechQueries'
import { CubeJSContext } from '../../context/CubeJSProvider';
import { useTranslation } from 'react-i18next';
import { useCubeQuery } from '@cubejs-client/react';
import { format } from 'date-fns'

const applyFilter = (query, storeFilter, storeMember, languageFilter, languageMember, timeMember, start, end) => {
  let storeCodeFilterObj = {
    member: storeMember,
    operator: "contains",
    values: storeFilter
  }

  query = {
    ...query,
    filters: (query.filters || []).concat(storeCodeFilterObj)
  }

  if (languageFilter && languageMember){
    let locateFilterObj = {
      member: languageMember,
      operator: "equals",
      values: [languageFilter]
    }
    query = {
      ...query,
      filters: (query.filters || []).concat(locateFilterObj)
    }
  }

  if (timeMember && start){
    let starFilterObj = {
      member: timeMember,
      operator: "gte",
      values: [start]
    }
    query = {
      ...query,
      filters: (query.filters || []).concat(starFilterObj)
    }
  }

  if (timeMember && end){
    let endFilterObj = {
      member: timeMember,
      operator: "lte",
      values: [end]
    }
    query = {
      ...query,
      filters: (query.filters || []).concat(endFilterObj)
    }
  }

  return query
}

const DayEndSummaryQueries = (storeFilter, startDateFilter, endDateFilter, isRefresh, setRefresh, periodFilter, lunchStart, lunchEnd, dinnerStart, dinnerEnd) => {
  if(startDateFilter == null){
    startDateFilter = new Date()
    startDateFilter.setHours(0,0,0,0);
    endDateFilter = new Date()
    endDateFilter.setHours(23,59,59,999);
  }

  var result = {}
  let query1 = {
    ...OmnitechQueries.orders.OrderTotalDayEnd.query,
    "timeDimensions": OmnitechQueries.orders.OrderTotalDayEnd.query.timeDimensions.concat(
      {
        "dimension": "OrderDayEnd.orderCompletedAt",
        "dateRange": [ startDateFilter, endDateFilter ]
      })
  }

  if (periodFilter === 'lunch'){
    let newStartDateFilter = new Date(startDateFilter)
    newStartDateFilter.setHours(lunchStart,0,0,0);
    let newEndDateFilter = new Date(endDateFilter)
    newEndDateFilter.setHours(lunchEnd,59,59,999);
    query1 = applyFilter(query1, storeFilter, "OrderDayEnd.shipmentStoreCode", null, null, "OrderDayEnd.orderCompletedAt", newStartDateFilter, newEndDateFilter)
  }
  else if (periodFilter === 'dinner'){
    let newStartDateFilter =  new Date(startDateFilter) 
    newStartDateFilter.setHours(dinnerStart,0,0,0);
    let newEndDateFilter = new Date(endDateFilter) 
    newEndDateFilter.setHours(dinnerEnd,59,59,999);
    query1 = applyFilter(query1, storeFilter, "OrderDayEnd.shipmentStoreCode", null, null, "OrderDayEnd.orderCompletedAt", newStartDateFilter, newEndDateFilter)
  }
  else{
    query1 = applyFilter(query1, storeFilter, "OrderDayEnd.shipmentStoreCode", null, null, null, null, null)
  }
    
  if(isRefresh)
    query1 = {}
  result["order"] = useCubeQuery(query1, {resetResultSetOnChange: true})

  let query2 = {
    ...OmnitechQueries.orders.VoidOrderTotalDayEnd.query,
    "timeDimensions": OmnitechQueries.orders.VoidOrderTotalDayEnd.query.timeDimensions.concat(
      {
        "dimension": "OrderDayEnd.orderCompletedAt",
        "dateRange": [ startDateFilter, endDateFilter ]
      })
  }
  if (periodFilter === 'lunch'){
    let newStartDateFilter = new Date(startDateFilter)
    newStartDateFilter.setHours(lunchStart,0,0,0);
    let newEndDateFilter = new Date(endDateFilter)
    newEndDateFilter.setHours(lunchEnd,59,59,999);
    query2 = applyFilter(query2, storeFilter, "OrderDayEnd.shipmentStoreCode", null, null, "OrderDayEnd.orderCompletedAt", newStartDateFilter, newEndDateFilter)
  }
  else if (periodFilter === 'dinner'){
    let newStartDateFilter =  new Date(startDateFilter) 
    newStartDateFilter.setHours(dinnerStart,0,0,0);
    let newEndDateFilter = new Date(endDateFilter) 
    newEndDateFilter.setHours(dinnerEnd,59,59,999);
    query2 = applyFilter(query2, storeFilter, "OrderDayEnd.shipmentStoreCode", null, null, "OrderDayEnd.orderCompletedAt", newStartDateFilter, newEndDateFilter)
  }
  else{
    query2 = applyFilter(query2, storeFilter, "OrderDayEnd.shipmentStoreCode", null, null, null, null, null)
  }
  if(isRefresh)
    query2 = {}
  result["void_order"] = useCubeQuery(query2, {resetResultSetOnChange: true})

  let query3 = {
    ...OmnitechQueries.orders.TipDayEnd.query,
    "timeDimensions": OmnitechQueries.orders.TipDayEnd.query.timeDimensions.concat(
      {
        "dimension": "Orders.completedAt",
        "dateRange": [ startDateFilter, endDateFilter ]
      })
  }
  if (periodFilter === 'lunch'){
    let newStartDateFilter = new Date(startDateFilter)
    newStartDateFilter.setHours(lunchStart,0,0,0);
    let newEndDateFilter = new Date(endDateFilter)
    newEndDateFilter.setHours(lunchEnd,59,59,999);
    query3 = applyFilter(query3, storeFilter, "Orders.shipmentStoreCode", null, null, "Orders.completedAt", newStartDateFilter, newEndDateFilter)
  }
  else if (periodFilter === 'dinner'){
    let newStartDateFilter =  new Date(startDateFilter) 
    newStartDateFilter.setHours(dinnerStart,0,0,0);
    let newEndDateFilter = new Date(endDateFilter) 
    newEndDateFilter.setHours(dinnerEnd,59,59,999);
    query3 = applyFilter(query3, storeFilter, "Orders.shipmentStoreCode", null, null, "Orders.completedAt", newStartDateFilter, newEndDateFilter)
  }
  else{
    query3 = applyFilter(query3, storeFilter, "Orders.shipmentStoreCode", null, null, null, null, null)
  }
  if(isRefresh)
    query3 = {}
  result["tip"] = useCubeQuery(query3, {resetResultSetOnChange: true})

  let query4 = {
    ...OmnitechQueries.orders.VoidItemDayEnd.query,
    "timeDimensions": OmnitechQueries.orders.VoidItemDayEnd.query.timeDimensions.concat(
      {
        "dimension": "CartLinePropertiesDayEnd.softDeletedAt",
        "dateRange": [ startDateFilter, endDateFilter ]
      })
  }
  if (periodFilter === 'lunch'){
    let newStartDateFilter = new Date(startDateFilter)
    newStartDateFilter.setHours(lunchStart,0,0,0);
    let newEndDateFilter = new Date(endDateFilter)
    newEndDateFilter.setHours(lunchEnd,59,59,999);
    query4 = applyFilter(query4, storeFilter, "CartLinePropertiesDayEnd.storeCode", null, null, "CartLinePropertiesDayEnd.softDeletedAt", newStartDateFilter, newEndDateFilter)
  }
  else if (periodFilter === 'dinner'){
    let newStartDateFilter =  new Date(startDateFilter) 
    newStartDateFilter.setHours(dinnerStart,0,0,0);
    let newEndDateFilter = new Date(endDateFilter) 
    newEndDateFilter.setHours(dinnerEnd,59,59,999);
    query4 = applyFilter(query4, storeFilter, "CartLinePropertiesDayEnd.storeCode", null, null, "CartLinePropertiesDayEnd.softDeletedAt", newStartDateFilter, newEndDateFilter)
  }
  else{
    query4 = applyFilter(query4, storeFilter, "CartLinePropertiesDayEnd.storeCode", null, null, null, null, null)
  }
  if(isRefresh)
    query4 = {}
  result["void_item"] = useCubeQuery(query4, {resetResultSetOnChange: true})

  let query5 = {
    ...OmnitechQueries.orders.OrderTotalDayEndEM.query,
    "timeDimensions": OmnitechQueries.orders.OrderTotalDayEndEM.query.timeDimensions.concat(
      {
        "dimension": "OrderDayEndEM.orderCompletedAt",
        "dateRange": [ startDateFilter, endDateFilter ]
      })
  }
  if (periodFilter === 'lunch'){
    let newStartDateFilter = new Date(startDateFilter)
    newStartDateFilter.setHours(lunchStart,0,0,0);
    let newEndDateFilter = new Date(endDateFilter)
    newEndDateFilter.setHours(lunchEnd,59,59,999);
    query5 = applyFilter(query5, storeFilter, "OrderDayEndEM.shipmentStoreCode", null, null, "OrderDayEndEM.orderCompletedAt", newStartDateFilter, newEndDateFilter)
  }
  else if (periodFilter === 'dinner'){
    let newStartDateFilter =  new Date(startDateFilter) 
    newStartDateFilter.setHours(dinnerStart,0,0,0);
    let newEndDateFilter = new Date(endDateFilter) 
    newEndDateFilter.setHours(dinnerEnd,59,59,999);
    query5 = applyFilter(query5, storeFilter, "OrderDayEndEM.shipmentStoreCode", null, null, "OrderDayEndEM.orderCompletedAt", newStartDateFilter, newEndDateFilter)
  }
  else{
    query5 = applyFilter(query5, storeFilter, "OrderDayEndEM.shipmentStoreCode", null, null, null, null, null)
  }
  if(isRefresh)
    query5 = {}
  result["average"] = useCubeQuery(query5, {resetResultSetOnChange: true})

  if(isRefresh)
    setRefresh(false)
  return result
}

const RSMSalesDetailsQuery = (storeFilter, startDateFilter, endDateFilter, languageFilter, isRefresh, setRefresh, periodFilter, lunchStart, lunchEnd, dinnerStart, dinnerEnd) => {
  const { t } = useTranslation();
  if(startDateFilter == null){
    startDateFilter = new Date()
    startDateFilter.setHours(0,0,0,0);
    endDateFilter = new Date()
    endDateFilter.setHours(23,59,59,999);
  }
  let item = {
    name: t('page.che.dailyOperationFnb.RSMSalesDetails.name'),
    tableHeaders: {
      "ViewReportCategoryProductsFlatten.code":  { name: t('page.che.dailyOperationFnb.RSMSalesDetails.tableHeader.code'), type: "string" },
      "SKUSalesTransactions.totalAmount": { name: t('page.che.dailyOperationFnb.RSMSalesDetails.tableHeader.lineTotal'), type: 'number'},
      "SKUSalesTransactions.sumQuantity": { name: t('page.che.dailyOperationFnb.RSMSalesDetails.tableHeader.quantity'), type: 'number'},
    },
    grid: { xs: 12 },
    export: { allowExport: true, filename: 'rsm_sales_details_export_' + format(startDateFilter, 'yyyyMMddHHmmss') +
      '_' + format(endDateFilter, 'yyyyMMddHHmmss') }
  }
  let query = {
    ...OmnitechQueries.orderTransactions.RSMSalesDetails.query,
    "timeDimensions": [
      {
        "dimension": "SKUSalesTransactions.orderCompletedAt",
        "dateRange": [ startDateFilter, endDateFilter ]
      }
    ],
  }
  if (periodFilter === 'lunch'){
    let newStartDateFilter = new Date(startDateFilter)
    newStartDateFilter.setHours(lunchStart,0,0,0);
    let newEndDateFilter = new Date(endDateFilter)
    newEndDateFilter.setHours(lunchEnd,59,59,999);
    query = applyFilter(query, storeFilter, "SKUSalesTransactions.shipFromStoreCode", languageFilter, "ProductTranslations.locale", "SKUSalesTransactions.orderCompletedAt", newStartDateFilter, newEndDateFilter)
  }
  else if (periodFilter === 'dinner'){
    let newStartDateFilter =  new Date(startDateFilter) 
    newStartDateFilter.setHours(dinnerStart,0,0,0);
    let newEndDateFilter = new Date(endDateFilter) 
    newEndDateFilter.setHours(dinnerEnd,59,59,999);
    query = applyFilter(query, storeFilter, "SKUSalesTransactions.shipFromStoreCode", languageFilter, "ProductTranslations.locale", "SKUSalesTransactions.orderCompletedAt", newStartDateFilter, newEndDateFilter)
  }
  else{
    query = applyFilter(query, storeFilter, "SKUSalesTransactions.shipFromStoreCode", languageFilter, "ProductTranslations.locale", "SKUSalesTransactions.orderCompletedAt", null, null)
  }
  if(isRefresh)
    query = {}
  const result = useCubeQuery(query, {resetResultSetOnChange: true})
  if(isRefresh)
    setRefresh(false)
  return { item, result }
}

const DiscountBreakdownQuery = (storeFilter, startDateFilter, endDateFilter, languageFilter, isRefresh, setRefresh, periodFilter, lunchStart, lunchEnd, dinnerStart, dinnerEnd) => {
  const { t } = useTranslation();
  if(startDateFilter == null){
    startDateFilter = new Date()
    startDateFilter.setHours(0,0,0,0);
    endDateFilter = new Date()
    endDateFilter.setHours(23,59,59,999);
  }

  let item = {
    name: t('page.che.dailyOperationFnb.discountBreakdown.name'),
    tableHeaders: {
      "OrderTransactionsDiscount.storeCode": { name: t('page.che.dailyOperationFnb.discountBreakdown.tableHeader.shipFromStoreCode'), type: 'string'},
      "OrderTransactionsDiscount.discountCode": { name: t('page.che.dailyOperationFnb.discountBreakdown.tableHeader.discountCode'), type: 'string'},
      "OrderTransactionsDiscount.discountName": { name: t('page.che.dailyOperationFnb.discountBreakdown.tableHeader.discountName'), type: 'string'},
      "OrderTransactionsDiscount.totalAmount": { name: t('page.che.dailyOperationFnb.discountBreakdown.tableHeader.lineTotal'), type: 'number'},
      "OrderTransactionsDiscount.sumQuantity": { name: t('page.che.dailyOperationFnb.discountBreakdown.tableHeader.quantity'), type: 'number'},
    },
    grid: { xs: 12 },
    export: { allowExport: true, filename: 'discount_breakdown_export_' + format(startDateFilter, 'yyyyMMddHHmmss') +
      '_' + format(endDateFilter, 'yyyyMMddHHmmss') }
  }
  let query = {
    ...OmnitechQueries.orders.DiscountBreakdown.query,
    "timeDimensions": [
      {
        "dimension": "OrderTransactionsDiscount.orderCompletedAt",
        "dateRange": [ startDateFilter, endDateFilter ]
      }
    ],
  }

  if (periodFilter === 'lunch'){
    let newStartDateFilter = new Date(startDateFilter)
    newStartDateFilter.setHours(lunchStart,0,0,0);
    let newEndDateFilter = new Date(endDateFilter)
    newEndDateFilter.setHours(lunchEnd,59,59,999);
    query = applyFilter(query, storeFilter, "OrderTransactionsDiscount.storeCode", languageFilter, "OrderTransactionsDiscount.discountLocale", "OrderTransactionsDiscount.orderCompletedAt", newStartDateFilter, newEndDateFilter)
  }
  else if (periodFilter === 'dinner'){
    let newStartDateFilter =  new Date(startDateFilter) 
    newStartDateFilter.setHours(dinnerStart,0,0,0);
    let newEndDateFilter = new Date(endDateFilter) 
    newEndDateFilter.setHours(dinnerEnd,59,59,999);
    query = applyFilter(query, storeFilter, "OrderTransactionsDiscount.storeCode", languageFilter, "OrderTransactionsDiscount.discountLocale", "OrderTransactionsDiscount.orderCompletedAt", newStartDateFilter, newEndDateFilter)
  }
  else{
    query = applyFilter(query, storeFilter, "OrderTransactionsDiscount.storeCode", languageFilter, "OrderTransactionsDiscount.discountLocale", null, null, null)
  }
  if(isRefresh)
    query = {}
  const result = useCubeQuery(query, {resetResultSetOnChange: true})
  if(isRefresh)
    setRefresh(false)
  return { item, result }
}

const PaymentByStoreTypeQuery = (storeFilter, startDateFilter, endDateFilter, languageFilter, isRefresh, setRefresh, periodFilter, lunchStart, lunchEnd, dinnerStart, dinnerEnd) => {
  const { t } = useTranslation();
  if(startDateFilter == null){
    startDateFilter = new Date()
    startDateFilter.setHours(0,0,0,0);
    endDateFilter = new Date()
    endDateFilter.setHours(23,59,59,999);
  }

  let item = {
    name: t('page.che.dailyOperationFnb.paymentByStoreType.name'),
    tableHeaders: {
      "PaymentRequests.sumAmount": { name: t('page.che.dailyOperationFnb.paymentByStoreType.tableHeader.sumAmount'), type: "number" },
      "PaymentRequests.sumTip": { name: t('page.che.dailyOperationFnb.paymentByStoreType.tableHeader.sumTip'), type: "number" },
      "PaymentRequests.sumGrandTotal": { name: t('page.che.dailyOperationFnb.paymentByStoreType.tableHeader.grandTotal'), type: "number" },
      "Orders.count": { name: t('page.che.dailyOperationFnb.paymentByStoreType.tableHeader.countOrderId'), type: "number" },
      "Orders.shipmentStoreCode": { name: t('page.che.dailyOperationFnb.paymentByStoreType.tableHeader.shipmentsFromStoreCode'), type: "string" },
      "PaymentProviders.code": { name: t('page.che.dailyOperationFnb.paymentByStoreType.tableHeader.paymentProviderCode'), type: "string" },
      "PaymentProviderTranslations.name": { name: t('page.che.dailyOperationFnb.paymentByStoreType.tableHeader.name'), type: "string" },
      "Currencies.code": { name: t('page.che.dailyOperationFnb.paymentByStoreType.tableHeader.currencyCode'), type: "string" },
      "PaymentRequests.state": { name: t('page.che.dailyOperationFnb.paymentByStoreType.tableHeader.state'), type: "string" },
    },
    grid: { xs: 12 },
    export: { allowExport: true, filename: 'payment_by_store_and_type_export_' + format(startDateFilter, 'yyyyMMddHHmmss') + 
      '_' + format(endDateFilter, 'yyyyMMddHHmmss') }
  }
  let query = {
    ...OmnitechQueries.orders.PaymentByStoreType.query,
    "timeDimensions": [
      {
        "dimension": "Orders.completedAt",
        "dateRange": [ startDateFilter, endDateFilter ]
      }
    ],
  }
  if (periodFilter === 'lunch'){
    let newStartDateFilter = new Date(startDateFilter)
    newStartDateFilter.setHours(lunchStart,0,0,0);
    let newEndDateFilter = new Date(endDateFilter)
    newEndDateFilter.setHours(lunchEnd,59,59,999);
    query = applyFilter(query, storeFilter, "Orders.shipmentStoreCode", languageFilter, "PaymentProviderTranslations.locale", "Orders.completedAt", newStartDateFilter, newEndDateFilter)
  }
  else if (periodFilter === 'dinner'){
    let newStartDateFilter =  new Date(startDateFilter) 
    newStartDateFilter.setHours(dinnerStart,0,0,0);
    let newEndDateFilter = new Date(endDateFilter) 
    newEndDateFilter.setHours(dinnerEnd,59,59,999);
    query = applyFilter(query, storeFilter, "Orders.shipmentStoreCode", languageFilter, "PaymentProviderTranslations.locale", "Orders.completedAt", newStartDateFilter, newEndDateFilter)
  }
  else{
    query = applyFilter(query, storeFilter, "Orders.shipmentStoreCode", languageFilter, "PaymentProviderTranslations.locale", "Orders.completedAt", null, null)
  }
  if(isRefresh)
    query = {}
  const result = useCubeQuery(query, {resetResultSetOnChange: true})
  if(isRefresh)
    setRefresh(false)
  return { item, result }
}

const CHEDailyOperationFnbPage = () => {
  const { t, i18n } = useTranslation();
  let languageFilter = i18n.language
  if(languageFilter !== 'en-HK' && languageFilter !== 'zh-HK')
    languageFilter = 'en-HK'
  const context = useContext(CubeJSContext);
  let storeCodeSelect = []
  if(context.storeCodes)
    storeCodeSelect = context.storeCodes
  const [ storeFilter, setStoreFilter ] = useState([]);
  let lunchStart = context.lunchStart ? context.lunchStart : 10
  let lunchEnd = context.lunchEnd ? context.lunchEnd : 17
  let dinnerStart = context.dinnerStart ? context.dinnerStart : 18
  let dinnerEnd = context.dinnerEnd ? context.dinnerEnd : 23
  let filterStartDate = new Date()
  filterStartDate.setHours(0,0,0,0);
  let filterEndDate = new Date()
  filterEndDate.setHours(23,59,59,999);
  const [dateRangeFilter, setDateRangeFilter] = useState([filterStartDate, filterEndDate])
  const [startDateFilter, endDateFilter] = dateRangeFilter
  const item = { grid: { xs: 12, sm: 6, md: 4 }}
  const item12 = { grid: { xs: 12, sm: 12, md: 12 }}
  const item6 = { grid: { xs: 12, sm: 6, md: 6 }}
  const [rowsPerPage1, setRowsPerPage1] = useState(10);
  const [page1, setPage1] = useState(0);
  const [rowsPerPage2, setRowsPerPage2] = useState(10);
  const [page2, setPage2] = useState(0);
  const [rowsPerPage3, setRowsPerPage3] = useState(10);
  const [page3, setPage3] = useState(0);

  const [periodFilter, setPeriodFilter] = useState('all');
  const periodSelect = [
    {value: "all", name: t('page.che.dailyOperationFnb.filter.period.all')},
    {value: "lunch", name: t('page.che.dailyOperationFnb.filter.period.lunch')},
    {value: "dinner", name: t('page.che.dailyOperationFnb.filter.period.dinner')},
  ]

  const [ refresh1, setRefresh1 ] = useState(false);
  const [ refresh2, setRefresh2 ] = useState(false);
  const [ refresh3, setRefresh3 ] = useState(false);
  const [ refresh4, setRefresh4 ] = useState(false);

  const resultSets = DayEndSummaryQueries(storeFilter, startDateFilter, endDateFilter, refresh1, setRefresh1, periodFilter, lunchStart, lunchEnd, dinnerStart, dinnerEnd)
  const query1 = RSMSalesDetailsQuery(storeFilter, startDateFilter, endDateFilter, languageFilter, refresh2, setRefresh2, periodFilter, lunchStart, lunchEnd, dinnerStart, dinnerEnd)
  const query2 = DiscountBreakdownQuery(storeFilter, startDateFilter, endDateFilter, languageFilter, refresh3, setRefresh3, periodFilter, lunchStart, lunchEnd, dinnerStart, dinnerEnd)
  const query3 = PaymentByStoreTypeQuery(storeFilter, startDateFilter, endDateFilter, languageFilter, refresh4, setRefresh4, periodFilter, lunchStart, lunchEnd, dinnerStart, dinnerEnd)

  const refresh = () => {
    setRefresh1(true)
    setRefresh2(true)
    setRefresh3(true)
    setRefresh4(true)
    setPage1(0)
    setPage2(0)
    setPage3(0)
  }

  return ([
    <Header />,
    <PageHeaderComponent onClickFunc={refresh} refreshlabel={t('component.refresh')} headerTitle={t('component.header.menu.cheDailyOperationFnb')} />,
    <Dashboard>
      <MultiSelectComponent id={"store_code_filter"} state={storeFilter} pageChangerArr={[setPage1,setPage2,setPage3]}
          stateChanger={setStoreFilter} textLabel={t('page.che.dailyOperationFnb.filter.store.name')} selectArray={storeCodeSelect}/>
      <DatePickerComponent state={dateRangeFilter} stateChanger={setDateRangeFilter} 
        pageChangerArr={[setPage1,setPage2,setPage3]} 
        dateLabel={t('page.che.dailyOperationFnb.filter.orderCompletedAt.date')}/>
      <SelectComponentV2 id={"period_filter"} state={periodFilter} pageChangerArr={[setPage1,setPage2,setPage3]}
          stateChanger={setPeriodFilter} textLabel={t('page.che.dailyOperationFnb.filter.period.name')} selectArray={periodSelect}/>
    </Dashboard>,
    <Dashboard>
      <TextComponent title={t('page.che.dailyOperationFnb.text.summary')} xs={12} lg={12} transparent={true}/>
      <NumberComponent item={item} query={resultSets["order"]}
        titleName={t('page.che.dailyOperationFnb.titleMapping.totalFoodGrossSales')}
        fieldName="OrderDayEnd.foodGrossSales" />
      <NumberComponent item={item} query={resultSets["order"]}
        titleName={t('page.che.dailyOperationFnb.titleMapping.totalBeverageGrossSales')}
        fieldName="OrderDayEnd.beverageGrossSales" />
      <NumberComponent item={item} query={resultSets["order"]}
        titleName={t('page.che.dailyOperationFnb.titleMapping.totalOtherGrossSales')}
        fieldName="OrderDayEnd.otherGrossSales" />
      <NumberComponent item={item12} query={resultSets["order"]}
        titleName={t('page.che.dailyOperationFnb.titleMapping.totalGrossSales')}
        fieldName="OrderDayEnd.grossSales" />
      <NumberComponent item={item} query={resultSets["order"]}
        titleName={t('page.che.dailyOperationFnb.titleMapping.totalServiceCharges')}
        fieldName="OrderDayEnd.serviceCharges" />
      <NumberComponent item={item} query={resultSets["order"]}
        titleName={t('page.che.dailyOperationFnb.titleMapping.totalShippingFees')}
        fieldName="OrderDayEnd.shippingFees" />
      <NumberComponent item={item} query={resultSets["tip"]}
        titleName={t('page.che.dailyOperationFnb.titleMapping.totalTip')}
        fieldName="PaymentRequests.sumTip" />
      <NumberComponent item={item6} query={resultSets["order"]}
        titleName={t('page.che.dailyOperationFnb.titleMapping.totalDiscounts')}
        fieldName="OrderDayEnd.discounts" />
      <NumberComponent item={item6} query={resultSets["order"]}
        titleName={t('page.che.dailyOperationFnb.titleMapping.totalOrderAmountRounding')}
        fieldName="OrderDayEnd.orderAmountRounding" />
      <NumberComponent item={item12} query={resultSets["order"]}
        titleName={t('page.che.dailyOperationFnb.titleMapping.totalNetSales')}
        fieldName="OrderDayEnd.netSales" />
      <TextComponent title={t('page.che.dailyOperationFnb.text.others')} xs={12} lg={12} transparent={true}/>
      <NumberComponent item={item6} query={resultSets["average"]}
        titleName={t('page.che.dailyOperationFnb.titleMapping.totalOrder')}
        fieldName="OrderDayEndEM.count" />
      <NumberComponent item={item6} query={resultSets["average"]}
        titleName={t('page.che.dailyOperationFnb.titleMapping.avgBillAmount')}
        fieldName="OrderDayEndEM.avgBillAmount" />
      <NumberComponent item={item6} query={resultSets["average"]}
        titleName={t('page.che.dailyOperationFnb.titleMapping.totalPax')}
        fieldName="OrderDayEndEM.pax" />
      <NumberComponent item={item6} query={resultSets["average"]}
        titleName={t('page.che.dailyOperationFnb.titleMapping.perPersonSpending')}
        fieldName="OrderDayEndEM.perPersonSpending" />
      <TextComponent title={t('page.che.dailyOperationFnb.text.void')} xs={12} lg={12} transparent={true}/>
      <NumberComponent item={item} query={resultSets["void_order"]}
        titleName={t('page.che.dailyOperationFnb.titleMapping.totalVoidOrder')}
        fieldName="OrderDayEnd.count" />
      <NumberComponent item={item} query={resultSets["void_order"]}
        titleName={t('page.che.dailyOperationFnb.titleMapping.totalVoidOrderAmount')}
        fieldName="OrderDayEnd.orderTotal" />
      <NumberComponent item={item} query={resultSets["void_item"]}
        titleName={t('page.che.dailyOperationFnb.titleMapping.totalVoidItem')}
        fieldName="CartLinePropertiesDayEnd.count" />
      
      <TextComponent title={""} xs={12} lg={12} transparent={true}/>
      <TextComponent title={t('page.che.dailyOperationFnb.text.breakdown')} xs={12} lg={12} transparent={true}/>
      <TableComponent item={query1.item} result={query1.result} rowsPerPage={rowsPerPage1} 
        setRowsPerPage={setRowsPerPage1} page={page1} setPage={setPage1}/>
      <TableComponent item={query2.item} result={query2.result} rowsPerPage={rowsPerPage2} 
        setRowsPerPage={setRowsPerPage2} page={page2} setPage={setPage2}/>
      <TextComponent title={t('component.text.paymentBreakdownMapping')} 
        contentArray={[t('page.che.dailyOperationFnb.text.paymentCodeMapping.vsm'),
        t('page.che.dailyOperationFnb.text.paymentCodeMapping.msm'),
        t('page.che.dailyOperationFnb.text.paymentCodeMapping.CV'),
        t('page.che.dailyOperationFnb.text.paymentCodeMapping.CU'),
        t('page.che.dailyOperationFnb.text.paymentCodeMapping.aem'),
        t('page.che.dailyOperationFnb.text.paymentCodeMapping.CASH_HKD'),
        t('page.che.dailyOperationFnb.text.paymentCodeMapping.octopus'),
        t('page.che.dailyOperationFnb.text.paymentCodeMapping.alipay_hkd_manual'),
        t('page.che.dailyOperationFnb.text.paymentCodeMapping.wechat_pay_hkd_manual')]} xs={12} lg={12}/>
      <TableComponent item={query3.item} result={query3.result} rowsPerPage={rowsPerPage3} 
        setRowsPerPage={setRowsPerPage3} page={page3} setPage={setPage3}/>
    </Dashboard>
  ]) 
};

export default CHEDailyOperationFnbPage;