const UserRankQueries = {
  UserRank: {
    query: {
      "measures": [
        "UserLoyaltyPointBalances.count"
      ],
      "order": {
        "CustomerRanks.code": "asc"
      },
      "filters": [
        {
          "member": "Users.roles",
          "operator": "equals",
          "values": [
            "consumer"
          ]
        },
        {
          "member": "Users.email",
          "operator": "notContains",
          "values": [
            "@example.com"
          ]
        },
      ],
      "dimensions": [
        "CustomerRanks.code"
      ],
      "limit": 50000,
      "renewQuery": true,
    }
  },
}

export default UserRankQueries