const commonEnHK = {
  currentInventoryBalancePage: {
    name: "Current Inventory Balance",
    measureName: "Current Inventory Measure Balance",
    filter: {
      propertyAc: {
        name: "Property Account",
        all: "ALL"
      },
      categoryCodes: "Category Codes",
      storeCode: {
        name: "Store Code",
        all: "ALL"
      }
    },
    tableHeader: {
      propertyAc: "Property Acccount",
      skuCode: "SKU Code",
      upcCode: "UPC Code",
      skuInternalUseTitle: "SKU Internal Use Title",
      storeCode: "Store Code",
      availableForSale: "Available For Sale",
      instock: "Instock",
      holdCount: "Hold Count",
      available: "Available",
      cost: "Cost",
      inventoryCost: "Inventory Cost",
      balance: "Balance",
      unit: "Unit",
      productCode: "Product Code",
      productTitleEn: "Product Title (English)",
      productTitleZh: "Product Title (Chinese)",
      categoryCodes: "Category Codes",
      stockCounterCacheId: "Stock Counter Cache Id"
    }
  },
  supplierSummaryPage: {
    text: {
      orderCompleted: "Completed Order",
      orderConfirmed: "Confirmed Order",
      orderCompletedDetail: "Completed Order Summary",
    },
    filter: {
      propertyAc: {
        name: "Property Account",
        all: "ALL"
      },
      storeCode: {
        name: "Store Code",
        all: "ALL"
      },
      orderCompletedAt: {
        "startDate": "Order Completed At From Date",
        "to": "to",
        "endDate": "Order Completed At End Date"
      }
    },
    titleMapping: {
      totalSkuQuantity: "Total Quantity",
      totalGrossSales: "Total Gross Sales",
      totalDiscount: "Total Discount",
      totalOrderAmount: "Total Net Sales",
    },
    orderQuantityBreakdownByPropertyAC: {
      name: "Order Quantity Breakdown",
      tableHeader: {
        propertyAc: "Property Acccount",
        referenceNumber: "Reference Number",
        shipFromStoreCode: "Store Code",
        skuCode: "SKU Code",
        upcCode: "UPC Code",
        skuInternalUseTitle: "SKU Internal Use Title",
        completedAt: "Order Completed At",
        txHour: "Transaction Time (Hour)",
        weekday: "Transaction Time (Week Day)",
        txDow: "Transaction Time (Day of Week)",
        unitAmount: "Unit Amount",
        quantity: "Quantity",
        orderAmount: "Order Amount",
      }
    },
    orderQuantityBreakdownByPropertyACSummary: {
      name: "Order Quantity Summary",
      tableHeader: {
        skuCode: "SKU Code",
        upcCode: "UPC Code",
        shipFromStoreCode: "Store Code",
        totalQuantity: "Total Quantity",
        totalOrderAmount: "Total Order Amount",
      }
    },
    discountQuantityBreakdownByPropertyAC: {
      name: "Discount Quantity Breakdown",
      tableHeader: {
        propertyAc: "Property Acccount",
        referenceNumber: "Reference Number",
        shipFromStoreCode: "Store Code",
        discountCode: "Discount Code",
        discountNameEn: "Discount Name (English)",
        discountNameZh: "Discount Name (Chinese)",
        orderTransactionType: "Discount Type",
        completedAt: "Order Completed At",
        txHour: "Transaction Time (Hour)",
        weekday: "Transaction Time (Week Day)",
        txDow: "Transaction Time (Day of Week)",
        unitAmount: "Unit Amount",
        quantity: "Quantity",
        orderAmount: "Order Amount",
      }
    },
    discountQuantityBreakdownByPropertyACSummary: {
      name: "Discount Quantity Summary",
      tableHeader: {
        discountCode: "Discount Code",
        discountNameEn: "Discount Name (English)",
        discountNameZh: "Discount Name (Chinese)",
        orderTransactionType: "Discount Type",
        shipFromStoreCode: "Store Code",
        totalQuantity: "Total Quantity",
        totalOrderAmount: "Total Order Amount",
      }
    },
    skuUploadTemplate: {
      name: "SKU Upload Template",
      tableHeader: {
        propertyAc: "skus[meta][property_ac](json)",
        productCode: "product[code]",
        skuCode: "sku[code]",
        upcCode: "sku[upc_code]",
        skuCommerceChannelCodes: "sku[commerce_channel_codes]",
        sizeOptionCode: "size_option[code]",
        sizeMasterCode: "size_option[size_master_code]",
        sizeOptionDisplaySequence: "size_option[display_sequence]",
        sizeOptionNameEn: "size_option[name]:en-HK",
        sizeOptionNameZn: "size_option[name]:zh-HK",
        sizeOptionState: "size_option[state]",
        colorOptionHexValue: "color_option[hex_value]",
        colorOptionColorCode: "color_option[color_code]",
        colorMasterCode: "color_option[color_master_code]",
        colorOptionNameEn: "color_option[name]:en-HK",
        colorOptionNameZh: "color_option[name]:zh-HK",
        colorOptionState: "color_option[state]",
        productType: "product[product_type]",
        productStatus: "product[status]",
        skuInternalUseTitle: "sku[internal_use_title]",
        productTitleEn: "product[title]:en-HK",
        productTitleZh: "product[title]:zh-HK",
        categoriesCode: "categories[1][code]",
      }
    },
    stockMovement: {
      name: "Stock Movement",
      tableHeader: {
        transactionTimestamp: "Transaction Timestamp",
        propertyAc: "Property Acccount",
        skuCode: "SKU Code",
        upcCode: "UPC Code",
        productCode: "Product Code",
        storeCode: "Store Code",
        txNumber: "Transction Number",
        txType: "Transaction Type",
        quantity: "quantity"
      }
    }
  }
}

export default commonEnHK