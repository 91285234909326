const TimePeriodSummaryQueries = {
  TimePeriodSummaryDineIn: {
    query: {
      "measures": [
        "TimePeriodSummary.count",
        "TimePeriodSummary.sumDineInNumberOfPeople",
        "TimePeriodSummary.avgBillAmount",
        "TimePeriodSummary.sumOrderTotal",
        "TimePeriodSummary.perPersonSpending"
      ],
      "timeDimensions": [
      ],
      "order": {},
      "dimensions": [],
      "filters": [
        {
          "member": "TimePeriodSummary.state",
          "operator": "equals",
          "values": [
            "completed"
          ]
        },
        {
          "member": "TimePeriodSummary.orderSource",
          "operator": "equals",
          "values": [
            "dine_in",
          ]
        }
      ],
      "limit": 50000,
      "renewQuery": true,
    }
  },
  TimePeriodSummaryTakeaway: {
    query: {
      "measures": [
        "TimePeriodSummary.count",
        "TimePeriodSummary.sumDineInNumberOfPeople",
        "TimePeriodSummary.avgBillAmount",
        "TimePeriodSummary.sumOrderTotal",
        "TimePeriodSummary.perPersonSpending"
      ],
      "timeDimensions": [
      ],
      "order": {},
      "dimensions": [],
      "filters": [
        {
          "member": "TimePeriodSummary.state",
          "operator": "equals",
          "values": [
            "completed"
          ]
        },
        {
          "member": "TimePeriodSummary.orderSource",
          "operator": "equals",
          "values": [
            "takeaway_dine_in",
            "takeaway_in_house",
            "take_away_hkd",
            "take_away_pos_hkd",
            "self_pickup_hkd",
            "self_pickup_pos_hkd",
            "deliveroo_pos_hkd",
            "foodpanda_pos_hkd",
            "hktvmall_pos_hkd",
            "inline_pos_hkd",
            "openrice_pos_hkd",
            "rt_self_pickup_pos_hkd",
            "zeek_pos_hkd",
            "groupbuy_pos_hkd"
          ]
        }
      ],
      "limit": 50000,
      "renewQuery": true,
    }
  },
  TimePeriodSummaryDetail: {
    query: {
      "measures": [
        "TimePeriodSummary.sumGrossSales",
        "TimePeriodSummary.sumDiscounts",
        "TimePeriodSummary.sumShippingFees",
        "TimePeriodSummary.sumServiceCharges",
        "TimePeriodSummary.sumOrderAmountRounding",
        "TimePeriodSummary.count",
        "TimePeriodSummary.sumDineInNumberOfPeople",
        "TimePeriodSummary.avgBillAmount",
        "TimePeriodSummary.sumOrderTotal",
        "TimePeriodSummary.perPersonSpending"
      ],
      "timeDimensions": [
      ],
      "order": {
        "TimePeriodSummary.shipFromStoreCode": "asc",
      },
      "dimensions": [
        "TimePeriodSummary.shipFromStoreCode",
        "TimePeriodSummary.commerceChannelCode",
        "TimePeriodSummary.orderSource",
      ],
      "filters": [
        {
          "member": "TimePeriodSummary.state",
          "operator": "equals",
          "values": [
            "completed"
          ]
        },
        {
          "member": "TimePeriodSummary.commerceChannelCode",
          "operator": "notEquals",
          "values": [
            "membership_upgrade"
          ]
        }
      ],
      "limit": 50000,
      "renewQuery": true,
    }
  }
}

export default TimePeriodSummaryQueries