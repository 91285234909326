import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';

const TransparentDashboardItem = ({ children, title, height, styles={}}) => (
  <Card style={{ backgroundColor: 'transparent', boxShadow: 'none', height: height}}>
    <CardContent>
      {title && (
        <Typography color="textSecondary" style={styles} gutterBottom>
          {title}
        </Typography>
      )}
      {children}
    </CardContent>
  </Card>
);

export default TransparentDashboardItem;
