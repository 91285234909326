import React, { useState, useMemo } from "react";
import Header from '../../components/Header';
import TableComponent from '../../components/TableComponent';
import DateRangePickerComponent from '../../components/DateRangePickerComponent';
import TextComponent from '../../components/TextComponent';
import ButtonGroupComponent from '../../components/ButtonGroupComponent';
import ChartComponent from '../../components/ChartComponent';
import Dashboard from '../../components/Dashboard';
import PageHeaderComponent from '../../components/PageHeaderComponent';
import OmnitechQueries from '../../queries/OmnitechQueries'
import { useTranslation } from 'react-i18next';
import { useCubeQuery } from '@cubejs-client/react';
import { setHours, setMinutes, setSeconds, setMilliseconds, format } from 'date-fns'

const MemberNetSpendingQuery = (startDateFilter, endDateFilter, granularity, isRefresh, setRefresh) => {
  const { t } = useTranslation();
  if(startDateFilter == null && endDateFilter == null){
    startDateFilter = new Date()
    startDateFilter.setHours(0,0,0,0);
    endDateFilter = new Date()
    endDateFilter.setHours(23,59,59,999);
  }
  else if(startDateFilter == null){
    startDateFilter = setHours(endDateFilter, 0)
    startDateFilter = setMinutes(startDateFilter, 0)
    startDateFilter = setSeconds(startDateFilter, 0)
    startDateFilter = setMilliseconds(startDateFilter, 0)
  }
  else if (endDateFilter == null){
    endDateFilter = setHours(startDateFilter, 23)
    endDateFilter = setMinutes(endDateFilter, 59)
    endDateFilter = setSeconds(endDateFilter, 59)
    endDateFilter = setMilliseconds(endDateFilter, 999)
  }
  let startDateString = new Date(startDateFilter.getTime() - (startDateFilter.getTimezoneOffset() * 60000 ))
                    .toISOString()
                    .split("T")[0];
  let endDateString = new Date(endDateFilter.getTime() - (endDateFilter.getTimezoneOffset() * 60000 ))
                    .toISOString()
                    .split("T")[0];
  let item = {
    name: t('page.membership.MemberSpendingPage.memberNetSpending.name'),
    tableHeaders: {
      "MvMemberSpending.averageMembersAverageNetSpending": { name: t('page.membership.MemberSpendingPage.memberNetSpending.tableHeader.member'), type: 'number'},
      "MvMemberSpending.averageNonMembersAverageNetSpending": { name: t('page.membership.MemberSpendingPage.memberNetSpending.tableHeader.nonMember'), type: 'number'},
      "MvMemberSpending.ordersCompleteDate.day": { name: t('page.membership.MemberSpendingPage.memberNetSpending.tableHeader.ordersCompleteDate'), type: 'date'},
      "MvMemberSpending.ordersCompleteDate.month": { name: t('page.membership.MemberSpendingPage.memberNetSpending.tableHeader.ordersCompleteDate'), type: 'dateMonth'},
      "MvMemberSpending.ordersCompleteDate.year": { name: t('page.membership.MemberSpendingPage.memberNetSpending.tableHeader.ordersCompleteDate'), type: 'dateYear'}
    },
    grid: {sm: 12, md: 12},
    export: { allowExport: true, filename: 'member_net_spending_export_by_' + granularity + '_' + format(startDateFilter, 'yyyyMMddHHmmss') +
      '_' + format(endDateFilter, 'yyyyMMddHHmmss') }
  }
  let query = {
    ...OmnitechQueries.mvMemberSpending.memberNetSpending.query,
    "timeDimensions": [
      {
        "dimension": "MvMemberSpending.ordersCompleteDate",
        "granularity": granularity,
        "dateRange": [ startDateString + " 00:00:00",
         endDateString + " 23:59:59" ]
      }
    ],
    "timezone": "Asia/Hong_Kong"
  }

  if(isRefresh)
    query = {}
  const result = useCubeQuery(query, {resetResultSetOnChange: true})
  if(isRefresh)
    setRefresh(false)
  return { item, result }
}

const MemberGrossSpendingQuery = (startDateFilter, endDateFilter, granularity, isRefresh, setRefresh) => {
  const { t } = useTranslation();
  if(startDateFilter == null && endDateFilter == null){
    startDateFilter = new Date()
    startDateFilter.setHours(0,0,0,0);
    endDateFilter = new Date()
    endDateFilter.setHours(23,59,59,999);
  }
  else if(startDateFilter == null){
    startDateFilter = setHours(endDateFilter, 0)
    startDateFilter = setMinutes(startDateFilter, 0)
    startDateFilter = setSeconds(startDateFilter, 0)
    startDateFilter = setMilliseconds(startDateFilter, 0)
  }
  else if (endDateFilter == null){
    endDateFilter = setHours(startDateFilter, 23)
    endDateFilter = setMinutes(endDateFilter, 59)
    endDateFilter = setSeconds(endDateFilter, 59)
    endDateFilter = setMilliseconds(endDateFilter, 999)
  }
  let startDateString = new Date(startDateFilter.getTime() - (startDateFilter.getTimezoneOffset() * 60000 ))
                    .toISOString()
                    .split("T")[0];
  let endDateString = new Date(endDateFilter.getTime() - (endDateFilter.getTimezoneOffset() * 60000 ))
                    .toISOString()
                    .split("T")[0];
  let item = {
    name: t('page.membership.MemberSpendingPage.memberGrossSpending.name'),
    tableHeaders: {
      "MvMemberSpending.averageMembersAverageGrossSpending": { name: t('page.membership.MemberSpendingPage.memberGrossSpending.tableHeader.member'), type: 'number'},
      "MvMemberSpending.averageNonMembersAverageGrossSpending": { name: t('page.membership.MemberSpendingPage.memberGrossSpending.tableHeader.nonMember'), type: 'number'},
      "MvMemberSpending.ordersCompleteDate.day": { name: t('page.membership.MemberSpendingPage.memberGrossSpending.tableHeader.ordersCompleteDate'), type: 'date'},
      "MvMemberSpending.ordersCompleteDate.month": { name: t('page.membership.MemberSpendingPage.memberGrossSpending.tableHeader.ordersCompleteDate'), type: 'dateMonth'},
      "MvMemberSpending.ordersCompleteDate.year": { name: t('page.membership.MemberSpendingPage.memberGrossSpending.tableHeader.ordersCompleteDate'), type: 'dateYear'}
    },
    grid: {sm: 12, md: 12},
    export: { allowExport: true, filename: 'member_gross_spending_export_by_' + granularity + '_' + format(startDateFilter, 'yyyyMMddHHmmss') +
      '_' + format(endDateFilter, 'yyyyMMddHHmmss') }
  }
  let query = {
    ...OmnitechQueries.mvMemberSpending.memberGrossSpending.query,
    "timeDimensions": [
      {
        "dimension": "MvMemberSpending.ordersCompleteDate",
        "granularity": granularity,
        "dateRange": [ startDateString + " 00:00:00",
         endDateString + " 23:59:59" ]
      }
    ],
    "timezone": "Asia/Hong_Kong"
  }

  if(isRefresh)
    query = {}
  const result = useCubeQuery(query, {resetResultSetOnChange: true})
  if(isRefresh)
    setRefresh(false)
  return { item, result }
}

const MemberDiscountQuery = (startDateFilter, endDateFilter, granularity, isRefresh, setRefresh, type) => {
  const { t } = useTranslation();
  if(startDateFilter == null && endDateFilter == null){
    startDateFilter = new Date()
    startDateFilter.setHours(0,0,0,0);
    endDateFilter = new Date()
    endDateFilter.setHours(23,59,59,999);
  }
  else if(startDateFilter == null){
    startDateFilter = setHours(endDateFilter, 0)
    startDateFilter = setMinutes(startDateFilter, 0)
    startDateFilter = setSeconds(startDateFilter, 0)
    startDateFilter = setMilliseconds(startDateFilter, 0)
  }
  else if (endDateFilter == null){
    endDateFilter = setHours(startDateFilter, 23)
    endDateFilter = setMinutes(endDateFilter, 59)
    endDateFilter = setSeconds(endDateFilter, 59)
    endDateFilter = setMilliseconds(endDateFilter, 999)
  }
  let startDateString = new Date(startDateFilter.getTime() - (startDateFilter.getTimezoneOffset() * 60000 ))
                    .toISOString()
                    .split("T")[0];
  let endDateString = new Date(endDateFilter.getTime() - (endDateFilter.getTimezoneOffset() * 60000 ))
                    .toISOString()
                    .split("T")[0];
  let item = {
    name: type === 'ratio' ? t('page.membership.MemberSpendingPage.memberDiscountRatio.name') :
      t('page.membership.MemberSpendingPage.memberDiscount.name'),
    tableHeaders: {
      "MvMemberSpending.averageMembersAverageDiscount": { name: t('page.membership.MemberSpendingPage.memberDiscount.tableHeader.member'), type: 'number'},
      "MvMemberSpending.averageNonMembersAverageDiscount": { name: t('page.membership.MemberSpendingPage.memberDiscount.tableHeader.nonMember'), type: 'number'},
      "MvMemberSpending.averageMembersDiscountRate": { name: t('page.membership.MemberSpendingPage.memberDiscount.tableHeader.memberRatio'), type: 'number'},
      "MvMemberSpending.averageNonMembersDiscountRate": { name: t('page.membership.MemberSpendingPage.memberDiscount.tableHeader.nonMemberRatio'), type: 'number'},
      "MvMemberSpending.ordersCompleteDate.day": { name: t('page.membership.MemberSpendingPage.memberDiscount.tableHeader.ordersCompleteDate'), type: 'date'},
      "MvMemberSpending.ordersCompleteDate.month": { name: t('page.membership.MemberSpendingPage.memberDiscount.tableHeader.ordersCompleteDate'), type: 'dateMonth'},
      "MvMemberSpending.ordersCompleteDate.year": { name: t('page.membership.MemberSpendingPage.memberDiscount.tableHeader.ordersCompleteDate'), type: 'dateYear'}
    },
    grid: {sm: 12, md: 12},
    export: { allowExport: true, filename: 'member_discount_export_by_' + granularity + '_' + format(startDateFilter, 'yyyyMMddHHmmss') +
      '_' + format(endDateFilter, 'yyyyMMddHHmmss') }
  }

  let query

  if(type === 'count')
    query = {
      ...OmnitechQueries.mvMemberSpending.memberDiscountCount.query,
    }
  else if(type === 'ratio')
    query = {
      ...OmnitechQueries.mvMemberSpending.memberDiscountRatio.query,
    }
  else
    query = {
        ...OmnitechQueries.mvMemberSpending.memberDiscount.query,
      }
  query = {
    ...query,
    "timeDimensions": [
      {
        "dimension": "MvMemberSpending.ordersCompleteDate",
        "granularity": granularity,
        "dateRange": [ startDateString + " 00:00:00",
         endDateString + " 23:59:59" ]
      }
    ],
    "timezone": "Asia/Hong_Kong"
  }

  if(isRefresh)
    query = {}
  const result = useCubeQuery(query, {resetResultSetOnChange: true})
  if(isRefresh)
    setRefresh(false)
  return { item, result }
}

const MemberOrderQuery = (startDateFilter, endDateFilter, granularity, isRefresh, setRefresh, type) => {
  const { t } = useTranslation();
  if(startDateFilter == null && endDateFilter == null){
    startDateFilter = new Date()
    startDateFilter.setHours(0,0,0,0);
    endDateFilter = new Date()
    endDateFilter.setHours(23,59,59,999);
  }
  else if(startDateFilter == null){
    startDateFilter = setHours(endDateFilter, 0)
    startDateFilter = setMinutes(startDateFilter, 0)
    startDateFilter = setSeconds(startDateFilter, 0)
    startDateFilter = setMilliseconds(startDateFilter, 0)
  }
  else if (endDateFilter == null){
    endDateFilter = setHours(startDateFilter, 23)
    endDateFilter = setMinutes(endDateFilter, 59)
    endDateFilter = setSeconds(endDateFilter, 59)
    endDateFilter = setMilliseconds(endDateFilter, 999)
  }
  let startDateString = new Date(startDateFilter.getTime() - (startDateFilter.getTimezoneOffset() * 60000 ))
                    .toISOString()
                    .split("T")[0];
  let endDateString = new Date(endDateFilter.getTime() - (endDateFilter.getTimezoneOffset() * 60000 ))
                    .toISOString()
                    .split("T")[0];
  let item = {
    name: type === 'ratio' ? t('page.membership.MemberSpendingPage.memberOrderRatio.name') :
      t('page.membership.MemberSpendingPage.memberOrder.name'),
    tableHeaders: {
      "MvMemberStore.averageMembersCount": { name: t('page.membership.MemberSpendingPage.memberOrder.tableHeader.member'), type: 'number'},
      "MvMemberStore.averageNonMembersCount": { name: t('page.membership.MemberSpendingPage.memberOrder.tableHeader.nonMember'), type: 'number'},
      "MvMemberStore.averageMembersRatio": { name: t('page.membership.MemberSpendingPage.memberOrder.tableHeader.memberRatio'), type: 'number'},
      "MvMemberStore.averageNonMembersRatio": { name: t('page.membership.MemberSpendingPage.memberOrder.tableHeader.nonMemberRatio'), type: 'number'},
      "MvMemberStore.ordersCompleteDate.day": { name: t('page.membership.MemberSpendingPage.memberOrder.tableHeader.ordersCompleteDate'), type: 'date'},
      "MvMemberStore.ordersCompleteDate.month": { name: t('page.membership.MemberSpendingPage.memberOrder.tableHeader.ordersCompleteDate'), type: 'dateMonth'},
      "MvMemberStore.ordersCompleteDate.year": { name: t('page.membership.MemberSpendingPage.memberOrder.tableHeader.ordersCompleteDate'), type: 'dateYear'}
    },
    grid: {sm: 12, md: 12},
    export: { allowExport: true, filename: 'member_order_export_by_' + granularity + '_' + format(startDateFilter, 'yyyyMMddHHmmss') +
      '_' + format(endDateFilter, 'yyyyMMddHHmmss') }
  }

  let query

  if(type === 'count')
    query = {
      ...OmnitechQueries.mvMemberStore.memberCount.query,
    }
  else if(type === 'ratio')
    query = {
      ...OmnitechQueries.mvMemberStore.memberRatio.query,
    }
  else
    query = {
        ...OmnitechQueries.mvMemberStore.member.query,
      }
  query = {
    ...query,
    "timeDimensions": [
      {
        "dimension": "MvMemberStore.ordersCompleteDate",
        "granularity": granularity,
        "dateRange": [ startDateString + " 00:00:00",
         endDateString + " 23:59:59" ]
      }
    ],
    "timezone": "Asia/Hong_Kong"
  }

  if(isRefresh)
    query = {}
  const result = useCubeQuery(query, {resetResultSetOnChange: true})
  if(isRefresh)
    setRefresh(false)
  return { item, result }
}


const MemberSumNetSpendingQuery = (startDateFilter, endDateFilter, granularity, isRefresh, setRefresh) => {
  const { t } = useTranslation();
  if(startDateFilter == null && endDateFilter == null){
    startDateFilter = new Date()
    startDateFilter.setHours(0,0,0,0);
    endDateFilter = new Date()
    endDateFilter.setHours(23,59,59,999);
  }
  else if(startDateFilter == null){
    startDateFilter = setHours(endDateFilter, 0)
    startDateFilter = setMinutes(startDateFilter, 0)
    startDateFilter = setSeconds(startDateFilter, 0)
    startDateFilter = setMilliseconds(startDateFilter, 0)
  }
  else if (endDateFilter == null){
    endDateFilter = setHours(startDateFilter, 23)
    endDateFilter = setMinutes(endDateFilter, 59)
    endDateFilter = setSeconds(endDateFilter, 59)
    endDateFilter = setMilliseconds(endDateFilter, 999)
  }
  let startDateString = new Date(startDateFilter.getTime() - (startDateFilter.getTimezoneOffset() * 60000 ))
                    .toISOString()
                    .split("T")[0];
  let endDateString = new Date(endDateFilter.getTime() - (endDateFilter.getTimezoneOffset() * 60000 ))
                    .toISOString()
                    .split("T")[0];
  let item = {
    name: t('page.membership.MemberSpendingPage.memberSumNetSpending.name'),
    tableHeaders: {
      "MvMemberSpending.sumMembersSumNetSpending": { name: t('page.membership.MemberSpendingPage.memberSumNetSpending.tableHeader.member'), type: 'number'},
      "MvMemberSpending.sumNonMembersSumNetSpending": { name: t('page.membership.MemberSpendingPage.memberSumNetSpending.tableHeader.nonMember'), type: 'number'},
      "MvMemberSpending.ordersCompleteDate.day": { name: t('page.membership.MemberSpendingPage.memberSumNetSpending.tableHeader.ordersCompleteDate'), type: 'date'},
      "MvMemberSpending.ordersCompleteDate.month": { name: t('page.membership.MemberSpendingPage.memberSumNetSpending.tableHeader.ordersCompleteDate'), type: 'dateMonth'},
      "MvMemberSpending.ordersCompleteDate.year": { name: t('page.membership.MemberSpendingPage.memberSumNetSpending.tableHeader.ordersCompleteDate'), type: 'dateYear'}
    },
    grid: {sm: 12, md: 12},
    export: { allowExport: true, filename: 'member_sum_net_spending_export_by_' + granularity + '_' + format(startDateFilter, 'yyyyMMddHHmmss') +
      '_' + format(endDateFilter, 'yyyyMMddHHmmss') }
  }
  let query = {
    ...OmnitechQueries.mvMemberSpending.memberSumNetSpending.query,
    "timeDimensions": [
      {
        "dimension": "MvMemberSpending.ordersCompleteDate",
        "granularity": granularity,
        "dateRange": [ startDateString + " 00:00:00",
         endDateString + " 23:59:59" ]
      }
    ],
    "timezone": "Asia/Hong_Kong"
  }

  if(isRefresh)
    query = {}
  const result = useCubeQuery(query, {resetResultSetOnChange: true})
  if(isRefresh)
    setRefresh(false)
  return { item, result }
}

const MemberSumGrossSpendingQuery = (startDateFilter, endDateFilter, granularity, isRefresh, setRefresh) => {
  const { t } = useTranslation();
  if(startDateFilter == null && endDateFilter == null){
    startDateFilter = new Date()
    startDateFilter.setHours(0,0,0,0);
    endDateFilter = new Date()
    endDateFilter.setHours(23,59,59,999);
  }
  else if(startDateFilter == null){
    startDateFilter = setHours(endDateFilter, 0)
    startDateFilter = setMinutes(startDateFilter, 0)
    startDateFilter = setSeconds(startDateFilter, 0)
    startDateFilter = setMilliseconds(startDateFilter, 0)
  }
  else if (endDateFilter == null){
    endDateFilter = setHours(startDateFilter, 23)
    endDateFilter = setMinutes(endDateFilter, 59)
    endDateFilter = setSeconds(endDateFilter, 59)
    endDateFilter = setMilliseconds(endDateFilter, 999)
  }
  let startDateString = new Date(startDateFilter.getTime() - (startDateFilter.getTimezoneOffset() * 60000 ))
                    .toISOString()
                    .split("T")[0];
  let endDateString = new Date(endDateFilter.getTime() - (endDateFilter.getTimezoneOffset() * 60000 ))
                    .toISOString()
                    .split("T")[0];
  let item = {
    name: t('page.membership.MemberSpendingPage.memberSumGrossSpending.name'),
    tableHeaders: {
      "MvMemberSpending.sumMembersSumGrossSpending": { name: t('page.membership.MemberSpendingPage.memberSumGrossSpending.tableHeader.member'), type: 'number'},
      "MvMemberSpending.sumNonMembersSumGrossSpending": { name: t('page.membership.MemberSpendingPage.memberSumGrossSpending.tableHeader.nonMember'), type: 'number'},
      "MvMemberSpending.ordersCompleteDate.day": { name: t('page.membership.MemberSpendingPage.memberSumGrossSpending.tableHeader.ordersCompleteDate'), type: 'date'},
      "MvMemberSpending.ordersCompleteDate.month": { name: t('page.membership.MemberSpendingPage.memberSumGrossSpending.tableHeader.ordersCompleteDate'), type: 'dateMonth'},
      "MvMemberSpending.ordersCompleteDate.year": { name: t('page.membership.MemberSpendingPage.memberSumGrossSpending.tableHeader.ordersCompleteDate'), type: 'dateYear'}
    },
    grid: {sm: 12, md: 12},
    export: { allowExport: true, filename: 'member_sum_gross_spending_export_by_' + granularity + '_' + format(startDateFilter, 'yyyyMMddHHmmss') +
      '_' + format(endDateFilter, 'yyyyMMddHHmmss') }
  }
  let query = {
    ...OmnitechQueries.mvMemberSpending.memberSumGrossSpending.query,
    "timeDimensions": [
      {
        "dimension": "MvMemberSpending.ordersCompleteDate",
        "granularity": granularity,
        "dateRange": [ startDateString + " 00:00:00",
         endDateString + " 23:59:59" ]
      }
    ],
    "timezone": "Asia/Hong_Kong"
  }

  if(isRefresh)
    query = {}
  const result = useCubeQuery(query, {resetResultSetOnChange: true})
  if(isRefresh)
    setRefresh(false)
  return { item, result }
}

const MemberSumDiscountQuery = (startDateFilter, endDateFilter, granularity, isRefresh, setRefresh) => {
  const { t } = useTranslation();
  if(startDateFilter == null && endDateFilter == null){
    startDateFilter = new Date()
    startDateFilter.setHours(0,0,0,0);
    endDateFilter = new Date()
    endDateFilter.setHours(23,59,59,999);
  }
  else if(startDateFilter == null){
    startDateFilter = setHours(endDateFilter, 0)
    startDateFilter = setMinutes(startDateFilter, 0)
    startDateFilter = setSeconds(startDateFilter, 0)
    startDateFilter = setMilliseconds(startDateFilter, 0)
  }
  else if (endDateFilter == null){
    endDateFilter = setHours(startDateFilter, 23)
    endDateFilter = setMinutes(endDateFilter, 59)
    endDateFilter = setSeconds(endDateFilter, 59)
    endDateFilter = setMilliseconds(endDateFilter, 999)
  }
  let startDateString = new Date(startDateFilter.getTime() - (startDateFilter.getTimezoneOffset() * 60000 ))
                    .toISOString()
                    .split("T")[0];
  let endDateString = new Date(endDateFilter.getTime() - (endDateFilter.getTimezoneOffset() * 60000 ))
                    .toISOString()
                    .split("T")[0];
  let item = {
    name: t('page.membership.MemberSpendingPage.memberSumDiscount.name'),
    tableHeaders: {
      "MvMemberSpending.sumMembersSumDiscount": { name: t('page.membership.MemberSpendingPage.memberSumDiscount.tableHeader.member'), type: 'number'},
      "MvMemberSpending.sumNonMembersSumDiscount": { name: t('page.membership.MemberSpendingPage.memberSumDiscount.tableHeader.nonMember'), type: 'number'},
      "MvMemberSpending.ordersCompleteDate.day": { name: t('page.membership.MemberSpendingPage.memberSumDiscount.tableHeader.ordersCompleteDate'), type: 'date'},
      "MvMemberSpending.ordersCompleteDate.month": { name: t('page.membership.MemberSpendingPage.memberSumDiscount.tableHeader.ordersCompleteDate'), type: 'dateMonth'},
      "MvMemberSpending.ordersCompleteDate.year": { name: t('page.membership.MemberSpendingPage.memberSumDiscount.tableHeader.ordersCompleteDate'), type: 'dateYear'}
    },
    grid: {sm: 12, md: 12},
    export: { allowExport: true, filename: 'member_sum_discount_export_by_' + granularity + '_' + format(startDateFilter, 'yyyyMMddHHmmss') +
      '_' + format(endDateFilter, 'yyyyMMddHHmmss') }
  }

  let query = {
    ...OmnitechQueries.mvMemberSpending.memberSumDiscount.query,
  }

  query = {
    ...query,
    "timeDimensions": [
      {
        "dimension": "MvMemberSpending.ordersCompleteDate",
        "granularity": granularity,
        "dateRange": [ startDateString + " 00:00:00",
         endDateString + " 23:59:59" ]
      }
    ],
    "timezone": "Asia/Hong_Kong"
  }

  if(isRefresh)
    query = {}
  const result = useCubeQuery(query, {resetResultSetOnChange: true})
  if(isRefresh)
    setRefresh(false)
  return { item, result }
}

const getChart = (resultSet, granularity, averageDiscountMemberName, averageDiscountNonMemberName, 
  averageDiscountRatioMemberName, averageDiscountRatioNonMemberName, averageNetMemberName, averageNetNonMemberName,
  averageGrossMemberName, averageGrossNonMemberName, averageMembersCount, averageNonMembersCount,
  averageMembersRatio, averageNonMembersRatio, sumNetMemberName, sumNetNonMemberName,
  sumGrossMemberName, sumGrossNonMemberName, sumDiscountMemberName, sumDiscountNonMemberName,) => {
  const renameLabels = {
    "MvMemberSpending.averageMembersAverageDiscount": averageDiscountMemberName,
    "MvMemberSpending.averageMembersDiscountRate": averageDiscountRatioMemberName,
    "MvMemberSpending.averageNonMembersAverageDiscount": averageDiscountNonMemberName,
    "MvMemberSpending.averageNonMembersDiscountRate": averageDiscountRatioNonMemberName,
    "MvMemberSpending.averageMembersAverageGrossSpending": averageGrossMemberName,
    "MvMemberSpending.averageNonMembersAverageGrossSpending": averageGrossNonMemberName,
    "MvMemberSpending.averageMembersAverageNetSpending": averageNetMemberName,
    "MvMemberSpending.averageNonMembersAverageNetSpending": averageNetNonMemberName,
    "MvMemberSpending.sumMembersSumNetSpending": sumNetMemberName,
    "MvMemberSpending.sumNonMembersSumNetSpending": sumNetNonMemberName,
    "MvMemberStore.averageMembersCount": averageMembersCount,
    "MvMemberStore.averageNonMembersCount": averageNonMembersCount,
    "MvMemberStore.averageMembersRatio": averageMembersRatio,
    "MvMemberStore.averageNonMembersRatio": averageNonMembersRatio,
    "MvMemberSpending.sumMembersSumGrossSpending": sumGrossMemberName,
    "MvMemberSpending.sumNonMembersSumGrossSpending": sumGrossNonMemberName,
    "MvMemberSpending.sumMembersSumDiscount": sumDiscountMemberName,
    "MvMemberSpending.sumNonMembersSumDiscount": sumDiscountNonMemberName
  };
  return {
    data: resultSet?.chartPivot().map((data) => {
      return {
        ...data,
        x: granularity === 'day' ? format(new Date(`${data.x}+00:00`), 'yyyy/MM/dd') : 
          granularity === 'month' ? format(new Date(`${data.x}+00:00`), 'yyyy/MM') : 
          format(new Date(`${data.x}+00:00`), 'yyyy'),
      }
    }),
    dataSeries: resultSet?.seriesNames().map((seriesName) => {
      return {
        ...seriesName,
        title: renameLabels[seriesName.key] ? renameLabels[seriesName.key]: seriesName.yValues[0],
      }
    }),
  }
}

const MemberSpendingPage = () => {
  const { t } = useTranslation();
  let filterStartDate = new Date()
  filterStartDate.setHours(0,0,0,0);
  let filterEndDate = new Date()
  filterEndDate.setHours(23,59,59,999);
  let groupArr = [{id: 'day', code: t('component.day')},{id: 'month', code: t('component.month')},{id: 'year', code: t('component.year')}]
  const [ granularity, setGranularity ] = useState('day');

  const [dateRangeFilter, setDateRangeFilter] = useState([filterStartDate, filterEndDate])
  const [startDateFilter, endDateFilter] = dateRangeFilter
  const [rowsPerPage1, setRowsPerPage1] = useState(10);
  const [page1, setPage1] = useState(0);
  const [rowsPerPage2, setRowsPerPage2] = useState(10);
  const [page2, setPage2] = useState(0);
  const [rowsPerPage3, setRowsPerPage3] = useState(10);
  const [page3, setPage3] = useState(0);
  const [rowsPerPage4, setRowsPerPage4] = useState(10);
  const [page4, setPage4] = useState(0);
  const [rowsPerPage5, setRowsPerPage5] = useState(10);
  const [page5, setPage5] = useState(0);
  const [rowsPerPage6, setRowsPerPage6] = useState(10);
  const [page6, setPage6] = useState(0);
  const [rowsPerPage7, setRowsPerPage7] = useState(10);
  const [page7, setPage7] = useState(0);

  const [ refresh1, setRefresh1 ] = useState(false);
  const [ refresh2, setRefresh2 ] = useState(false);
  const [ refresh3, setRefresh3 ] = useState(false);
  const [ refresh4, setRefresh4 ] = useState(false);
  const [ refresh5, setRefresh5 ] = useState(false);
  const [ refresh6, setRefresh6 ] = useState(false);
  const [ refresh7, setRefresh7 ] = useState(false);
  const [ refresh8, setRefresh8 ] = useState(false);
  const [ refresh9, setRefresh9 ] = useState(false);
  const [ refresh10, setRefresh10 ] = useState(false);
  const [ refresh11, setRefresh11 ] = useState(false);

  const query1 = MemberNetSpendingQuery(startDateFilter, endDateFilter, granularity, refresh1, setRefresh1)
  const query2 = MemberGrossSpendingQuery(startDateFilter, endDateFilter, granularity, refresh2, setRefresh2)
  const query3 = MemberDiscountQuery(startDateFilter, endDateFilter, granularity, refresh3, setRefresh3, "all")
  const query4 = MemberDiscountQuery(startDateFilter, endDateFilter, granularity, refresh4, setRefresh4, "count")
  const query5 = MemberDiscountQuery(startDateFilter, endDateFilter, granularity, refresh5, setRefresh5, "ratio")
  const query6 = MemberOrderQuery(startDateFilter, endDateFilter, granularity, refresh6, setRefresh6, "all")
  const query7 = MemberOrderQuery(startDateFilter, endDateFilter, granularity, refresh7, setRefresh7, "count")
  const query8 = MemberOrderQuery(startDateFilter, endDateFilter, granularity, refresh8, setRefresh8, "ratio")
  const query9 = MemberSumNetSpendingQuery(startDateFilter, endDateFilter, granularity, refresh9, setRefresh9)
  const query10 = MemberSumGrossSpendingQuery(startDateFilter, endDateFilter, granularity, refresh10, setRefresh10)
  const query11 = MemberSumDiscountQuery(startDateFilter, endDateFilter, granularity, refresh11, setRefresh11)

  const refresh = () => {
    setRefresh1(true)
    setPage1(0)
    setRefresh2(true)
    setPage2(0)
    setRefresh3(true)
    setPage3(0)
    setRefresh4(true)
    setPage4(0)
    setRefresh5(true)
    setPage5(0)
    setRefresh6(true)
    setPage6(0)
    setRefresh7(true)
    setPage7(0)
    setRefresh8(true)
    setRefresh9(true)
    setRefresh10(true)
    setRefresh11(true)
  }

  const averageDiscountMemberName = t('page.membership.MemberSpendingPage.memberDiscount.tableHeader.member')
  const averageDiscountNonMemberName = t('page.membership.MemberSpendingPage.memberDiscount.tableHeader.nonMember')
  const averageDiscountRatioMemberName = t('page.membership.MemberSpendingPage.memberDiscount.tableHeader.memberRatio')
  const averageDiscountRatioNonMemberName = t('page.membership.MemberSpendingPage.memberDiscount.tableHeader.nonMemberRatio')
  const averageNetMemberName = t('page.membership.MemberSpendingPage.memberNetSpending.tableHeader.member')
  const averageNetNonMemberName = t('page.membership.MemberSpendingPage.memberNetSpending.tableHeader.nonMember')
  const averageGrossMemberName = t('page.membership.MemberSpendingPage.memberGrossSpending.tableHeader.member')
  const averageGrossNonMemberName = t('page.membership.MemberSpendingPage.memberGrossSpending.tableHeader.nonMember')
  const averageMembersCount = t('page.membership.MemberSpendingPage.memberOrder.tableHeader.member')
  const averageNonMembersCount = t('page.membership.MemberSpendingPage.memberOrder.tableHeader.nonMember')
  const averageMembersRatio = t('page.membership.MemberSpendingPage.memberOrder.tableHeader.memberRatio')
  const averageNonMembersRatio = t('page.membership.MemberSpendingPage.memberOrder.tableHeader.nonMemberRatio')
  const sumNetMemberName = t('page.membership.MemberSpendingPage.memberSumNetSpending.tableHeader.member')
  const sumNetNonMemberName = t('page.membership.MemberSpendingPage.memberSumNetSpending.tableHeader.nonMember')
  const sumGrossMemberName = t('page.membership.MemberSpendingPage.memberSumGrossSpending.tableHeader.member')
  const sumGrossNonMemberName = t('page.membership.MemberSpendingPage.memberSumGrossSpending.tableHeader.nonMember')
  const sumDiscountMemberName = t('page.membership.MemberSpendingPage.memberSumDiscount.tableHeader.member')
  const sumDiscountNonMemberName = t('page.membership.MemberSpendingPage.memberSumDiscount.tableHeader.nonMember')

  const memberNetSpendingChartResultSet = query1.result?.resultSet
  const memberNetSpendingChart = useMemo(() => {
    if (memberNetSpendingChartResultSet === null) return {data: [], dataSeries: []}
    
    return getChart(memberNetSpendingChartResultSet, granularity, averageDiscountMemberName, averageDiscountNonMemberName, 
  averageDiscountRatioMemberName, averageDiscountRatioNonMemberName, averageNetMemberName, averageNetNonMemberName,
  averageGrossMemberName, averageGrossNonMemberName, averageMembersCount, averageNonMembersCount, averageMembersRatio,
  averageNonMembersRatio, sumNetMemberName, sumNetNonMemberName, sumGrossMemberName, sumGrossNonMemberName,
  sumDiscountMemberName, sumDiscountNonMemberName)
  }, [memberNetSpendingChartResultSet, granularity, 
  averageDiscountMemberName, averageDiscountNonMemberName, 
  averageDiscountRatioMemberName, averageDiscountRatioNonMemberName, averageNetMemberName, averageNetNonMemberName,
  averageGrossMemberName, averageGrossNonMemberName, averageMembersCount, averageNonMembersCount, averageMembersRatio,
  averageNonMembersRatio, sumNetMemberName, sumNetNonMemberName, sumGrossMemberName, sumGrossNonMemberName,
  sumDiscountMemberName, sumDiscountNonMemberName])

  const memberGrossSpendingChartResultSet = query2.result?.resultSet
  const memberGrosSpendingChart = useMemo(() => {
    if (memberGrossSpendingChartResultSet === null) return {data: [], dataSeries: []}
    
    return getChart(memberGrossSpendingChartResultSet, granularity, averageDiscountMemberName, averageDiscountNonMemberName, 
  averageDiscountRatioMemberName, averageDiscountRatioNonMemberName, averageNetMemberName, averageNetNonMemberName,
  averageGrossMemberName, averageGrossNonMemberName, averageMembersCount, averageNonMembersCount, averageMembersRatio,
  averageNonMembersRatio, sumNetMemberName, sumNetNonMemberName, sumGrossMemberName, sumGrossNonMemberName,
  sumDiscountMemberName, sumDiscountNonMemberName)
  }, [memberGrossSpendingChartResultSet, granularity, 
  averageDiscountMemberName, averageDiscountNonMemberName, 
  averageDiscountRatioMemberName, averageDiscountRatioNonMemberName, averageNetMemberName, averageNetNonMemberName,
  averageGrossMemberName, averageGrossNonMemberName, averageMembersCount, averageNonMembersCount, averageMembersRatio,
  averageNonMembersRatio, sumNetMemberName, sumNetNonMemberName, sumGrossMemberName, sumGrossNonMemberName,
  sumDiscountMemberName, sumDiscountNonMemberName])
  
  const memberDiscountCountChartResultSet = query4.result?.resultSet
  const memberDiscountCountChart = useMemo(() => {
    if (memberDiscountCountChartResultSet === null) return {data: [], dataSeries: []}
    
    return getChart(memberDiscountCountChartResultSet, granularity, 
      averageDiscountMemberName, averageDiscountNonMemberName, 
  averageDiscountRatioMemberName, averageDiscountRatioNonMemberName, averageNetMemberName, averageNetNonMemberName,
  averageGrossMemberName, averageGrossNonMemberName, averageMembersCount, averageNonMembersCount, averageMembersRatio,
  averageNonMembersRatio, sumNetMemberName, sumNetNonMemberName, sumGrossMemberName, sumGrossNonMemberName,
  sumDiscountMemberName, sumDiscountNonMemberName)
  }, [memberDiscountCountChartResultSet, granularity, 
  averageDiscountMemberName, averageDiscountNonMemberName, 
  averageDiscountRatioMemberName, averageDiscountRatioNonMemberName, averageNetMemberName, averageNetNonMemberName,
  averageGrossMemberName, averageGrossNonMemberName, averageMembersCount, averageNonMembersCount, averageMembersRatio,
  averageNonMembersRatio, sumNetMemberName, sumNetNonMemberName, sumGrossMemberName, sumGrossNonMemberName,
  sumDiscountMemberName, sumDiscountNonMemberName])

  const memberDiscountRatioChartResultSet = query5.result?.resultSet
  const memberDiscountRatioChart = useMemo(() => {
    if (memberDiscountRatioChartResultSet === null) return {data: [], dataSeries: []}
    
    return getChart(memberDiscountRatioChartResultSet, granularity, 
      averageDiscountMemberName, averageDiscountNonMemberName, 
  averageDiscountRatioMemberName, averageDiscountRatioNonMemberName, averageNetMemberName, averageNetNonMemberName,
  averageGrossMemberName, averageGrossNonMemberName, averageMembersCount, averageNonMembersCount, averageMembersRatio,
  averageNonMembersRatio, sumNetMemberName, sumNetNonMemberName, sumGrossMemberName, sumGrossNonMemberName,
  sumDiscountMemberName, sumDiscountNonMemberName)
  }, [memberDiscountRatioChartResultSet, granularity, 
  averageDiscountMemberName, averageDiscountNonMemberName, 
  averageDiscountRatioMemberName, averageDiscountRatioNonMemberName, averageNetMemberName, averageNetNonMemberName,
  averageGrossMemberName, averageGrossNonMemberName, averageMembersCount, averageNonMembersCount, averageMembersRatio,
  averageNonMembersRatio, sumNetMemberName, sumNetNonMemberName, sumGrossMemberName, sumGrossNonMemberName,
  sumDiscountMemberName, sumDiscountNonMemberName])

  const memberOrderCountChartResultSet = query7.result?.resultSet
  const memberOrderCountChart = useMemo(() => {
    if (memberOrderCountChartResultSet === null) return {data: [], dataSeries: []}
    
    return getChart(memberOrderCountChartResultSet, granularity, 
      averageDiscountMemberName, averageDiscountNonMemberName, 
  averageDiscountRatioMemberName, averageDiscountRatioNonMemberName, averageNetMemberName, averageNetNonMemberName,
  averageGrossMemberName, averageGrossNonMemberName, averageMembersCount, averageNonMembersCount, averageMembersRatio,
  averageNonMembersRatio, sumNetMemberName, sumNetNonMemberName, sumGrossMemberName, sumGrossNonMemberName,
  sumDiscountMemberName, sumDiscountNonMemberName)
  }, [memberOrderCountChartResultSet, granularity, 
  averageDiscountMemberName, averageDiscountNonMemberName, 
  averageDiscountRatioMemberName, averageDiscountRatioNonMemberName, averageNetMemberName, averageNetNonMemberName,
  averageGrossMemberName, averageGrossNonMemberName, averageMembersCount, averageNonMembersCount, averageMembersRatio,
  averageNonMembersRatio, sumNetMemberName, sumNetNonMemberName, sumGrossMemberName, sumGrossNonMemberName,
  sumDiscountMemberName, sumDiscountNonMemberName])

  const memberOrderRatioChartResultSet = query8.result?.resultSet
  const memberOrderRatioChart = useMemo(() => {
    if (memberOrderRatioChartResultSet === null) return {data: [], dataSeries: []}
    
    return getChart(memberOrderRatioChartResultSet, granularity, 
      averageDiscountMemberName, averageDiscountNonMemberName, 
  averageDiscountRatioMemberName, averageDiscountRatioNonMemberName, averageNetMemberName, averageNetNonMemberName,
  averageGrossMemberName, averageGrossNonMemberName, averageMembersCount, averageNonMembersCount, averageMembersRatio,
  averageNonMembersRatio, sumNetMemberName, sumNetNonMemberName, sumGrossMemberName, sumGrossNonMemberName,
  sumDiscountMemberName, sumDiscountNonMemberName)
  }, [memberOrderRatioChartResultSet, granularity, 
  averageDiscountMemberName, averageDiscountNonMemberName, 
  averageDiscountRatioMemberName, averageDiscountRatioNonMemberName, averageNetMemberName, averageNetNonMemberName,
  averageGrossMemberName, averageGrossNonMemberName, averageMembersCount, averageNonMembersCount, averageMembersRatio,
  averageNonMembersRatio, sumNetMemberName, sumNetNonMemberName, sumGrossMemberName, sumGrossNonMemberName,
  sumDiscountMemberName, sumDiscountNonMemberName])

  const memberSumNetSpendingChartResultSet = query9.result?.resultSet
  const memberSumNetSpendingChart = useMemo(() => {
    if (memberSumNetSpendingChartResultSet === null) return {data: [], dataSeries: []}
    
    return getChart(memberSumNetSpendingChartResultSet, granularity, 
      averageDiscountMemberName, averageDiscountNonMemberName, 
  averageDiscountRatioMemberName, averageDiscountRatioNonMemberName, averageNetMemberName, averageNetNonMemberName,
  averageGrossMemberName, averageGrossNonMemberName, averageMembersCount, averageNonMembersCount, averageMembersRatio,
  averageNonMembersRatio, sumNetMemberName, sumNetNonMemberName, sumGrossMemberName, sumGrossNonMemberName,
  sumDiscountMemberName, sumDiscountNonMemberName)
  }, [memberSumNetSpendingChartResultSet, granularity, 
  averageDiscountMemberName, averageDiscountNonMemberName, 
  averageDiscountRatioMemberName, averageDiscountRatioNonMemberName, averageNetMemberName, averageNetNonMemberName,
  averageGrossMemberName, averageGrossNonMemberName, averageMembersCount, averageNonMembersCount, averageMembersRatio,
  averageNonMembersRatio, sumNetMemberName, sumNetNonMemberName, sumGrossMemberName, sumGrossNonMemberName,
  sumDiscountMemberName, sumDiscountNonMemberName])

  const memberSumGrossSpendingChartResultSet = query10.result?.resultSet
  const memberSumGrosSpendingChart = useMemo(() => {
    if (memberSumGrossSpendingChartResultSet === null) return {data: [], dataSeries: []}
    
    return getChart(memberSumGrossSpendingChartResultSet, granularity, averageDiscountMemberName, averageDiscountNonMemberName, 
  averageDiscountRatioMemberName, averageDiscountRatioNonMemberName, averageNetMemberName, averageNetNonMemberName,
  averageGrossMemberName, averageGrossNonMemberName, averageMembersCount, averageNonMembersCount, averageMembersRatio,
  averageNonMembersRatio, sumNetMemberName, sumNetNonMemberName, sumGrossMemberName, sumGrossNonMemberName,
  sumDiscountMemberName, sumDiscountNonMemberName)
  }, [memberSumGrossSpendingChartResultSet, granularity, 
  averageDiscountMemberName, averageDiscountNonMemberName, 
  averageDiscountRatioMemberName, averageDiscountRatioNonMemberName, averageNetMemberName, averageNetNonMemberName,
  averageGrossMemberName, averageGrossNonMemberName, averageMembersCount, averageNonMembersCount, averageMembersRatio,
  averageNonMembersRatio, sumNetMemberName, sumNetNonMemberName, sumGrossMemberName, sumGrossNonMemberName,
  sumDiscountMemberName, sumDiscountNonMemberName])

  const memberSumDiscountCountChartResultSet = query11.result?.resultSet
  const memberSumDiscountCountChart = useMemo(() => {
    if (memberSumDiscountCountChartResultSet === null) return {data: [], dataSeries: []}
    
    return getChart(memberSumDiscountCountChartResultSet, granularity, 
      averageDiscountMemberName, averageDiscountNonMemberName, 
  averageDiscountRatioMemberName, averageDiscountRatioNonMemberName, averageNetMemberName, averageNetNonMemberName,
  averageGrossMemberName, averageGrossNonMemberName, averageMembersCount, averageNonMembersCount, averageMembersRatio,
  averageNonMembersRatio, sumNetMemberName, sumNetNonMemberName, sumGrossMemberName, sumGrossNonMemberName,
  sumDiscountMemberName, sumDiscountNonMemberName)
  }, [memberSumDiscountCountChartResultSet, granularity, 
  averageDiscountMemberName, averageDiscountNonMemberName, 
  averageDiscountRatioMemberName, averageDiscountRatioNonMemberName, averageNetMemberName, averageNetNonMemberName,
  averageGrossMemberName, averageGrossNonMemberName, averageMembersCount, averageNonMembersCount, averageMembersRatio,
  averageNonMembersRatio, sumNetMemberName, sumNetNonMemberName, sumGrossMemberName, sumGrossNonMemberName,
  sumDiscountMemberName, sumDiscountNonMemberName])

  return ([
    <Header />,
    <PageHeaderComponent onClickFunc={refresh} refreshlabel={t('component.refresh')} headerTitle={t('component.header.menu.memberSpending')} />,
    <Dashboard>
      <DateRangePickerComponent state={dateRangeFilter} stateChanger={setDateRangeFilter} 
        pageChangerArr={[setPage1,setPage2,setPage3,setPage4,setPage5,setPage6,setPage7]}
        startDateLabel={t('page.membership.MemberSpendingPage.filter.ordersCompleteDate.startDate')} 
        toLabel={t('page.membership.MemberSpendingPage.filter.ordersCompleteDate.to')} 
        endDateLabel={t('page.membership.MemberSpendingPage.filter.ordersCompleteDate.endDate')}/>
      <ButtonGroupComponent state={granularity} stateChanger={setGranularity} pageChangerArr={[setPage1,setPage2,setPage3,setPage4,setPage5,setPage6,setPage7]} buttonGroupArray={groupArr} title={t('component.dateGranularity')}/>
    </Dashboard>,
    <Dashboard>
      <TextComponent title={t('component.text.historical')} xs={12} />
      <TableComponent item={query1.item} result={query1.result} rowsPerPage={rowsPerPage1} 
        setRowsPerPage={setRowsPerPage1} page={page1} setPage={setPage1}/>
      <ChartComponent chartType="line" data={memberNetSpendingChart.data} dataSeries={memberNetSpendingChart.dataSeries} 
        item={query1.item} result={query1.result} titleName={query1.item.name} enableTooltip />
      <TableComponent item={query9.item} result={query9.result} rowsPerPage={rowsPerPage5} 
        setRowsPerPage={setRowsPerPage5} page={page5} setPage={setPage5}/>
      <ChartComponent chartType="line" data={memberSumNetSpendingChart.data} dataSeries={memberSumNetSpendingChart.dataSeries} 
        item={query9.item} result={query9.result} titleName={query9.item.name} enableTooltip />
      <TableComponent item={query2.item} result={query2.result} rowsPerPage={rowsPerPage2} 
        setRowsPerPage={setRowsPerPage2} page={page2} setPage={setPage2}/>
      <ChartComponent chartType="line" data={memberGrosSpendingChart.data} dataSeries={memberGrosSpendingChart.dataSeries} 
        item={query2.item} result={query2.result} titleName={query2.item.name} enableTooltip />
      <TableComponent item={query10.item} result={query10.result} rowsPerPage={rowsPerPage6} 
        setRowsPerPage={setRowsPerPage6} page={page6} setPage={setPage6}/>
      <ChartComponent chartType="line" data={memberSumGrosSpendingChart.data} dataSeries={memberSumGrosSpendingChart.dataSeries} 
        item={query10.item} result={query10.result} titleName={query10.item.name} enableTooltip />
      <TableComponent item={query3.item} result={query3.result} rowsPerPage={rowsPerPage3} 
        setRowsPerPage={setRowsPerPage3} page={page3} setPage={setPage3}/>
      <ChartComponent chartType="line" data={memberDiscountCountChart.data} dataSeries={memberDiscountCountChart.dataSeries} 
        item={query4.item} result={query4.result} titleName={query4.item.name} enableTooltip />
      <ChartComponent chartType="line" data={memberDiscountRatioChart.data} dataSeries={memberDiscountRatioChart.dataSeries} 
        item={query5.item} result={query5.result} titleName={query5.item.name} enableTooltip />
      <TableComponent item={query11.item} result={query11.result} rowsPerPage={rowsPerPage7} 
        setRowsPerPage={setRowsPerPage7} page={page7} setPage={setPage7}/>
      <ChartComponent chartType="line" data={memberSumDiscountCountChart.data} dataSeries={memberSumDiscountCountChart.dataSeries} 
        item={query11.item} result={query11.result} titleName={query11.item.name} enableTooltip />
      <TableComponent item={query6.item} result={query6.result} rowsPerPage={rowsPerPage4} 
        setRowsPerPage={setRowsPerPage4} page={page4} setPage={setPage4}/>
      <ChartComponent chartType="line" data={memberOrderCountChart.data} dataSeries={memberOrderCountChart.dataSeries} 
        item={query7.item} result={query7.result} titleName={query7.item.name} enableTooltip />
      <ChartComponent chartType="line" data={memberOrderRatioChart.data} dataSeries={memberOrderRatioChart.dataSeries} 
        item={query8.item} result={query8.result} titleName={query8.item.name} enableTooltip />
    </Dashboard>
  ]) 
};

export default MemberSpendingPage;