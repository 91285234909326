import React, { useState, useContext } from "react";
import Header from '../../components/Header';
import TableComponent from '../../components/TableComponent';
import MultiSelectComponent from '../../components/MultiSelectComponent';
import NumberComponent from '../../components/NumberComponent';
import DateRangePickerComponent from '../../components/DateRangePickerComponent';
import TextComponent from '../../components/TextComponent';
import Dashboard from '../../components/Dashboard';
import PageHeaderComponent from '../../components/PageHeaderComponent';
import OmnitechQueries from '../../queries/OmnitechQueries'
import { useTranslation } from 'react-i18next';
import { CubeJSContext } from '../../context/CubeJSProvider';
import { useCubeQuery } from '@cubejs-client/react';
import { setHours, setMinutes, setSeconds, setMilliseconds, format } from 'date-fns'

const applyFilter = (query, propertyAcFilter, properAcMember, storeCodeFilter, storeCodeMember) => {
  let propertyAcFilterObj = {
    member: properAcMember,
    operator: "contains",
    values: propertyAcFilter
  }

  query = {
    ...query,
    filters: (query.filters || []).concat(propertyAcFilterObj)
  }

  if(storeCodeFilter !== null && storeCodeMember !== null){
    let storeCodeFilterObj = {
      member: storeCodeMember,
      operator: "contains",
      values: storeCodeFilter
    }

    query = {
      ...query,
      filters: (query.filters || []).concat(storeCodeFilterObj)
    }
  }

  return query
}

const OrderQuantityBreakdownByPropertyACQuery = (propertyAcFilter, storeCodeFilter, startDateFilter, endDateFilter, isRefresh, setRefresh) => {
  const { t } = useTranslation();
  if(startDateFilter == null && endDateFilter == null){
    startDateFilter = new Date()
    startDateFilter.setHours(0,0,0,0);
    endDateFilter = new Date()
    endDateFilter.setHours(23,59,59,999);
  }
  else if(startDateFilter == null){
    startDateFilter = setHours(endDateFilter, 0)
    startDateFilter = setMinutes(startDateFilter, 0)
    startDateFilter = setSeconds(startDateFilter, 0)
    startDateFilter = setMilliseconds(startDateFilter, 0)
  }
  else if (endDateFilter == null){
    endDateFilter = setHours(startDateFilter, 23)
    endDateFilter = setMinutes(endDateFilter, 59)
    endDateFilter = setSeconds(endDateFilter, 59)
    endDateFilter = setMilliseconds(endDateFilter, 999)
  }
  let item = {
    name: t('page.supplier.supplierSummaryPage.orderQuantityBreakdownByPropertyAC.name'),
    tableHeaders: {
      "OQBBPAC.uuid":  { name: 'UUID', type: "string" },
      "OQBBPAC.propertyAc":  { name: t('page.supplier.supplierSummaryPage.orderQuantityBreakdownByPropertyAC.tableHeader.propertyAc'), type: "string" },
      "OQBBPAC.referenceNumber":  { name: t('page.supplier.supplierSummaryPage.orderQuantityBreakdownByPropertyAC.tableHeader.referenceNumber'), type: "string" },
      "OQBBPAC.shipFromStoreCode":  { name: t('page.supplier.supplierSummaryPage.orderQuantityBreakdownByPropertyACSummary.tableHeader.shipFromStoreCode'), type: "string" },
      "OQBBPAC.skuCode":  { name: t('page.supplier.supplierSummaryPage.orderQuantityBreakdownByPropertyAC.tableHeader.skuCode'), type: "string" },
      "OQBBPAC.upcCode":  { name: t('page.supplier.supplierSummaryPage.orderQuantityBreakdownByPropertyAC.tableHeader.upcCode'), type: "string" },
      "OQBBPAC.skuInternalUseTitle":  { name: t('page.supplier.supplierSummaryPage.orderQuantityBreakdownByPropertyAC.tableHeader.skuInternalUseTitle'), type: "string" },
      "OQBBPAC.completedAt":  { name: t('page.supplier.supplierSummaryPage.orderQuantityBreakdownByPropertyAC.tableHeader.completedAt'), type: "dateTime" },
      "OQBBPAC.txHour":  { name: t('page.supplier.supplierSummaryPage.orderQuantityBreakdownByPropertyAC.tableHeader.txHour'), type: "number" },
      "OQBBPAC.weekday":  { name: t('page.supplier.supplierSummaryPage.orderQuantityBreakdownByPropertyAC.tableHeader.weekday'), type: "string" },
      "OQBBPAC.txDow":  { name: t('page.supplier.supplierSummaryPage.orderQuantityBreakdownByPropertyAC.tableHeader.txDow'), type: "number" },
      "OQBBPAC.unitAmount":  { name: t('page.supplier.supplierSummaryPage.orderQuantityBreakdownByPropertyAC.tableHeader.unitAmount'), type: "number" },
      "OQBBPAC.quantity":  { name: t('page.supplier.supplierSummaryPage.orderQuantityBreakdownByPropertyAC.tableHeader.quantity'), type: "number" },
      "OQBBPAC.orderAmount":  { name: t('page.supplier.supplierSummaryPage.orderQuantityBreakdownByPropertyAC.tableHeader.orderAmount'), type: "number" },
    },
    grid: {xs: 12, lg: 12},
    export: { allowExport: true, filename: 'order_quantity_breakdown_'  + format(startDateFilter, 'yyyyMMddHHmmss') + 
      '_' + format(endDateFilter, 'yyyyMMddHHmmss') }
  }
  let query = {
    ...OmnitechQueries.mvOqbbpa.OrderQuantityBreakdownByPropertyAC.query,
    "timeDimensions": OmnitechQueries.mvOqbbpa.OrderQuantityBreakdownByPropertyAC.query.timeDimensions.concat(
      {
        "dimension": "OQBBPAC.completedAt",
        "dateRange": [ startDateFilter, endDateFilter ]
      })
  }
  query = applyFilter(query, propertyAcFilter, "OQBBPAC.propertyAc", storeCodeFilter, "OQBBPAC.shipFromStoreCode")
  if(isRefresh)
    query = {}
  const result = useCubeQuery(query, {resetResultSetOnChange: true})
  if(isRefresh)
    setRefresh(false)
  return { item, result }
}


const DiscountQuantityBreakdownByPropertyACQuery = (propertyAcFilter, storeCodeFilter, startDateFilter, endDateFilter, isRefresh, setRefresh) => {
  const { t } = useTranslation();
  if(startDateFilter == null && endDateFilter == null){
    startDateFilter = new Date()
    startDateFilter.setHours(0,0,0,0);
    endDateFilter = new Date()
    endDateFilter.setHours(23,59,59,999);
  }
  else if(startDateFilter == null){
    startDateFilter = setHours(endDateFilter, 0)
    startDateFilter = setMinutes(startDateFilter, 0)
    startDateFilter = setSeconds(startDateFilter, 0)
    startDateFilter = setMilliseconds(startDateFilter, 0)
  }
  else if (endDateFilter == null){
    endDateFilter = setHours(startDateFilter, 23)
    endDateFilter = setMinutes(endDateFilter, 59)
    endDateFilter = setSeconds(endDateFilter, 59)
    endDateFilter = setMilliseconds(endDateFilter, 999)
  }
  let item = {
    name: t('page.supplier.supplierSummaryPage.discountQuantityBreakdownByPropertyAC.name'),
    tableHeaders: {
      "OQBBPAC.uuid":  { name: 'UUID', type: "string" },
      "OQBBPAC.propertyAc":  { name: t('page.supplier.supplierSummaryPage.discountQuantityBreakdownByPropertyAC.tableHeader.propertyAc'), type: "string" },
      "OQBBPAC.referenceNumber":  { name: t('page.supplier.supplierSummaryPage.discountQuantityBreakdownByPropertyAC.tableHeader.referenceNumber'), type: "string" },
      "OQBBPAC.shipFromStoreCode":  { name: t('page.supplier.supplierSummaryPage.discountQuantityBreakdownByPropertyAC.tableHeader.shipFromStoreCode'), type: "string" },
      "OQBBPAC.discountCode":  { name: t('page.supplier.supplierSummaryPage.discountQuantityBreakdownByPropertyAC.tableHeader.discountCode'), type: "string" },
      "OQBBPAC.discountNameEn":  { name: t('page.supplier.supplierSummaryPage.discountQuantityBreakdownByPropertyACSummary.tableHeader.discountNameEn'), type: "string" },
      "OQBBPAC.discountNameZh":  { name: t('page.supplier.supplierSummaryPage.discountQuantityBreakdownByPropertyACSummary.tableHeader.discountNameZh'), type: "string" },
      "OQBBPAC.orderTransactionType":  { name: t('page.supplier.supplierSummaryPage.discountQuantityBreakdownByPropertyAC.tableHeader.orderTransactionType'), type: "string" },
      "OQBBPAC.completedAt":  { name: t('page.supplier.supplierSummaryPage.discountQuantityBreakdownByPropertyAC.tableHeader.completedAt'), type: "dateTime" },
      "OQBBPAC.txHour":  { name: t('page.supplier.supplierSummaryPage.discountQuantityBreakdownByPropertyAC.tableHeader.txHour'), type: "number" },
      "OQBBPAC.weekday":  { name: t('page.supplier.supplierSummaryPage.discountQuantityBreakdownByPropertyAC.tableHeader.weekday'), type: "string" },
      "OQBBPAC.txDow":  { name: t('page.supplier.supplierSummaryPage.discountQuantityBreakdownByPropertyAC.tableHeader.txDow'), type: "number" },
      "OQBBPAC.unitAmount":  { name: t('page.supplier.supplierSummaryPage.discountQuantityBreakdownByPropertyAC.tableHeader.unitAmount'), type: "number" },
      "OQBBPAC.quantity":  { name: t('page.supplier.supplierSummaryPage.discountQuantityBreakdownByPropertyAC.tableHeader.quantity'), type: "number" },
      "OQBBPAC.orderAmount":  { name: t('page.supplier.supplierSummaryPage.discountQuantityBreakdownByPropertyAC.tableHeader.orderAmount'), type: "number" },
    },
    grid: {xs: 12, lg: 12},
    export: { allowExport: true, filename: 'discount_quantity_breakdown_'  + format(startDateFilter, 'yyyyMMddHHmmss') + 
      '_' + format(endDateFilter, 'yyyyMMddHHmmss') }
  }
  let query = {
    ...OmnitechQueries.mvOqbbpa.DiscountQuantityBreakdownByPropertyAC.query,
    "timeDimensions": OmnitechQueries.mvOqbbpa.DiscountQuantityBreakdownByPropertyAC.query.timeDimensions.concat(
      {
        "dimension": "OQBBPAC.completedAt",
        "dateRange": [ startDateFilter, endDateFilter ]
      })
  }
  query = applyFilter(query, propertyAcFilter, "OQBBPAC.propertyAc", storeCodeFilter, "OQBBPAC.shipFromStoreCode")
  if(isRefresh)
    query = {}
  const result = useCubeQuery(query, {resetResultSetOnChange: true})
  if(isRefresh)
    setRefresh(false)
  return { item, result }
}

const OrderQuantityBreakdownByPropertyACSummaryQuery = (propertyAcFilter, storeCodeFilter, startDateFilter, endDateFilter, isRefresh, setRefresh) => {
  const { t } = useTranslation();
  if(startDateFilter == null && endDateFilter == null){
    startDateFilter = new Date()
    startDateFilter.setHours(0,0,0,0);
    endDateFilter = new Date()
    endDateFilter.setHours(23,59,59,999);
  }
  else if(startDateFilter == null){
    startDateFilter = setHours(endDateFilter, 0)
    startDateFilter = setMinutes(startDateFilter, 0)
    startDateFilter = setSeconds(startDateFilter, 0)
    startDateFilter = setMilliseconds(startDateFilter, 0)
  }
  else if (endDateFilter == null){
    endDateFilter = setHours(startDateFilter, 23)
    endDateFilter = setMinutes(endDateFilter, 59)
    endDateFilter = setSeconds(endDateFilter, 59)
    endDateFilter = setMilliseconds(endDateFilter, 999)
  }
  let item = {
    name: t('page.supplier.supplierSummaryPage.orderQuantityBreakdownByPropertyACSummary.name'),
    tableHeaders: {
      "OQBBPAC.skuCode":  { name: t('page.supplier.supplierSummaryPage.orderQuantityBreakdownByPropertyACSummary.tableHeader.skuCode'), type: "string" },
      "OQBBPAC.upcCode":  { name: t('page.supplier.supplierSummaryPage.orderQuantityBreakdownByPropertyACSummary.tableHeader.upcCode'), type: "string" },
      "OQBBPAC.shipFromStoreCode":  { name: t('page.supplier.supplierSummaryPage.orderQuantityBreakdownByPropertyACSummary.tableHeader.shipFromStoreCode'), type: "string" },
      "OQBBPAC.totalQuantity":  { name: t('page.supplier.supplierSummaryPage.orderQuantityBreakdownByPropertyACSummary.tableHeader.totalQuantity'), type: "number" },
      "OQBBPAC.totalOrderAmount":  { name: t('page.supplier.supplierSummaryPage.orderQuantityBreakdownByPropertyACSummary.tableHeader.totalOrderAmount'), type: "number" },
    },
    grid: {xs: 12, lg: 12},
    export: { allowExport: true, filename: 'order_quantity_summary_'  + format(startDateFilter, 'yyyyMMddHHmmss') + 
      '_' + format(endDateFilter, 'yyyyMMddHHmmss') }
  }
  let query = {
    ...OmnitechQueries.mvOqbbpa.OrderQuantityBreakdownByPropertyACSummary.query,
    "timeDimensions": OmnitechQueries.mvOqbbpa.OrderQuantityBreakdownByPropertyACSummary.query.timeDimensions.concat(
      {
        "dimension": "OQBBPAC.completedAt",
        "dateRange": [ startDateFilter, endDateFilter ]
      })
  }
  query = applyFilter(query, propertyAcFilter, "OQBBPAC.propertyAc", storeCodeFilter, "OQBBPAC.shipFromStoreCode")
  if(isRefresh)
    query = {}
  const result = useCubeQuery(query, {resetResultSetOnChange: true})
  if(isRefresh)
    setRefresh(false)
  return { item, result }
}

const DiscountQuantityBreakdownByPropertyACSummaryQuery = (propertyAcFilter, storeCodeFilter, startDateFilter, endDateFilter, isRefresh, setRefresh) => {
  const { t } = useTranslation();
  if(startDateFilter == null && endDateFilter == null){
    startDateFilter = new Date()
    startDateFilter.setHours(0,0,0,0);
    endDateFilter = new Date()
    endDateFilter.setHours(23,59,59,999);
  }
  else if(startDateFilter == null){
    startDateFilter = setHours(endDateFilter, 0)
    startDateFilter = setMinutes(startDateFilter, 0)
    startDateFilter = setSeconds(startDateFilter, 0)
    startDateFilter = setMilliseconds(startDateFilter, 0)
  }
  else if (endDateFilter == null){
    endDateFilter = setHours(startDateFilter, 23)
    endDateFilter = setMinutes(endDateFilter, 59)
    endDateFilter = setSeconds(endDateFilter, 59)
    endDateFilter = setMilliseconds(endDateFilter, 999)
  }
  let item = {
    name: t('page.supplier.supplierSummaryPage.discountQuantityBreakdownByPropertyACSummary.name'),
    tableHeaders: {
      "OQBBPAC.discountCode":  { name: t('page.supplier.supplierSummaryPage.discountQuantityBreakdownByPropertyACSummary.tableHeader.discountCode'), type: "string" },
      "OQBBPAC.discountNameEn":  { name: t('page.supplier.supplierSummaryPage.discountQuantityBreakdownByPropertyACSummary.tableHeader.discountNameEn'), type: "string" },
      "OQBBPAC.discountNameZh":  { name: t('page.supplier.supplierSummaryPage.discountQuantityBreakdownByPropertyACSummary.tableHeader.discountNameZh'), type: "string" },
      "OQBBPAC.orderTransactionType":  { name: t('page.supplier.supplierSummaryPage.discountQuantityBreakdownByPropertyACSummary.tableHeader.orderTransactionType'), type: "string" },
      "OQBBPAC.shipFromStoreCode":  { name: t('page.supplier.supplierSummaryPage.discountQuantityBreakdownByPropertyACSummary.tableHeader.shipFromStoreCode'), type: "string" },
      "OQBBPAC.totalQuantity":  { name: t('page.supplier.supplierSummaryPage.discountQuantityBreakdownByPropertyACSummary.tableHeader.totalQuantity'), type: "number" },
      "OQBBPAC.totalOrderAmount":  { name: t('page.supplier.supplierSummaryPage.discountQuantityBreakdownByPropertyACSummary.tableHeader.totalOrderAmount'), type: "number" },
    },
    grid: {xs: 12, lg: 12},
    export: { allowExport: true, filename: 'order_quantity_summary_'  + format(startDateFilter, 'yyyyMMddHHmmss') + 
      '_' + format(endDateFilter, 'yyyyMMddHHmmss') }
  }
  let query = {
    ...OmnitechQueries.mvOqbbpa.DiscountQuantityBreakdownByPropertyACSummary.query,
    "timeDimensions": OmnitechQueries.mvOqbbpa.DiscountQuantityBreakdownByPropertyACSummary.query.timeDimensions.concat(
      {
        "dimension": "OQBBPAC.completedAt",
        "dateRange": [ startDateFilter, endDateFilter ]
      })
  }
  query = applyFilter(query, propertyAcFilter, "OQBBPAC.propertyAc", storeCodeFilter, "OQBBPAC.shipFromStoreCode")
  if(isRefresh)
    query = {}
  const result = useCubeQuery(query, {resetResultSetOnChange: true})
  if(isRefresh)
    setRefresh(false)
  return { item, result }
}

const TotalOrderQuantityBreakdownByPropertyACQuery = (propertyAcFilter, storeCodeFilter, startDateFilter, endDateFilter, isRefresh, setRefresh) => {
  if(startDateFilter == null && endDateFilter == null){
    startDateFilter = new Date()
    startDateFilter.setHours(0,0,0,0);
    endDateFilter = new Date()
    endDateFilter.setHours(23,59,59,999);
  }
  else if(startDateFilter == null){
    startDateFilter = setHours(endDateFilter, 0)
    startDateFilter = setMinutes(startDateFilter, 0)
    startDateFilter = setSeconds(startDateFilter, 0)
    startDateFilter = setMilliseconds(startDateFilter, 0)
  }
  else if (endDateFilter == null){
    endDateFilter = setHours(startDateFilter, 23)
    endDateFilter = setMinutes(endDateFilter, 59)
    endDateFilter = setSeconds(endDateFilter, 59)
    endDateFilter = setMilliseconds(endDateFilter, 999)
  }

  var result = {}
  let query1 = {
    ...OmnitechQueries.mvOqbbpa.TotalOrderQuantityBreakdownByPropertyAC.query,
    "timeDimensions": OmnitechQueries.mvOqbbpa.TotalOrderQuantityBreakdownByPropertyAC.query.timeDimensions.concat(
      {
        "dimension": "OQBBPAC.completedAt",
        "dateRange": [ startDateFilter, endDateFilter ]
      })
  }
  query1 = applyFilter(query1, propertyAcFilter, "OQBBPAC.propertyAc", storeCodeFilter, "OQBBPAC.shipFromStoreCode")
  if(isRefresh)
    query1 = {}
  result["total"] = useCubeQuery(query1, {resetResultSetOnChange: true})

  let query2 = {
    ...OmnitechQueries.mvOqbbpa.TotalOrderQuantityBreakdownByPropertyACConfirm.query,
    "timeDimensions": OmnitechQueries.mvOqbbpa.TotalOrderQuantityBreakdownByPropertyACConfirm.query.timeDimensions.concat(
      {
        "dimension": "OQBBPAC.confirmedAt",
        "dateRange": [ startDateFilter, endDateFilter ]
      })
  }
  query2 = applyFilter(query2, propertyAcFilter, "OQBBPAC.propertyAc", storeCodeFilter, "OQBBPAC.shipFromStoreCode")
  if(isRefresh)
    query2 = {}
  result["total_confirm"] = useCubeQuery(query2, {resetResultSetOnChange: true})
  if(isRefresh)
    setRefresh(false)
  return result
}

const SKUUploadTemplateQuery = (propertyAcFilter, isRefresh, setRefresh) => {
  const { t } = useTranslation();

  let item = {
    name: t('page.supplier.supplierSummaryPage.skuUploadTemplate.name'),
    tableHeaders: {
      "SKUUploadTemplate.propertyAc":  { name: t('page.supplier.supplierSummaryPage.skuUploadTemplate.tableHeader.propertyAc'), type: "string" },
      "SKUUploadTemplate.productCode":  { name: t('page.supplier.supplierSummaryPage.skuUploadTemplate.tableHeader.productCode'), type: "string" },
      "SKUUploadTemplate.skuCode":  { name: t('page.supplier.supplierSummaryPage.skuUploadTemplate.tableHeader.skuCode'), type: "string" },
      "SKUUploadTemplate.upcCode":  { name: t('page.supplier.supplierSummaryPage.skuUploadTemplate.tableHeader.upcCode'), type: "string" },
      "SKUUploadTemplate.skuCommerceChannelCodes":  { name: t('page.supplier.supplierSummaryPage.skuUploadTemplate.tableHeader.skuCommerceChannelCodes'), type: "string" },
      "SKUUploadTemplate.sizeOptionCode":  { name: t('page.supplier.supplierSummaryPage.skuUploadTemplate.tableHeader.sizeOptionCode'), type: "string" },
      "SKUUploadTemplate.sizeMasterCode":  { name: t('page.supplier.supplierSummaryPage.skuUploadTemplate.tableHeader.sizeMasterCode'), type: "string" },
      "SKUUploadTemplate.sizeOptionDisplaySequence":  { name: t('page.supplier.supplierSummaryPage.skuUploadTemplate.tableHeader.sizeOptionDisplaySequence'), type: "string" },
      "SKUUploadTemplate.sizeOptionNameEn":  { name: t('page.supplier.supplierSummaryPage.skuUploadTemplate.tableHeader.sizeOptionNameEn'), type: "string" },
      "SKUUploadTemplate.sizeOptionNameZn":  { name: t('page.supplier.supplierSummaryPage.skuUploadTemplate.tableHeader.sizeOptionNameZn'), type: "string" },
      "SKUUploadTemplate.sizeOptionState":  { name: t('page.supplier.supplierSummaryPage.skuUploadTemplate.tableHeader.sizeOptionState'), type: "string" },
      "SKUUploadTemplate.colorOptionHexValue":  { name: t('page.supplier.supplierSummaryPage.skuUploadTemplate.tableHeader.colorOptionHexValue'), type: "string" },
      "SKUUploadTemplate.colorOptionColorCode":  { name: t('page.supplier.supplierSummaryPage.skuUploadTemplate.tableHeader.colorOptionColorCode'), type: "string" },
      "SKUUploadTemplate.colorMasterCode":  { name: t('page.supplier.supplierSummaryPage.skuUploadTemplate.tableHeader.colorMasterCode'), type: "string" },
      "SKUUploadTemplate.colorOptionNameEn":  { name: t('page.supplier.supplierSummaryPage.skuUploadTemplate.tableHeader.colorOptionNameEn'), type: "string" },
      "SKUUploadTemplate.colorOptionNameZh":  { name: t('page.supplier.supplierSummaryPage.skuUploadTemplate.tableHeader.colorOptionNameZh'), type: "string" },
      "SKUUploadTemplate.colorOptionState":  { name: t('page.supplier.supplierSummaryPage.skuUploadTemplate.tableHeader.colorOptionState'), type: "string" },
      "SKUUploadTemplate.productType":  { name: t('page.supplier.supplierSummaryPage.skuUploadTemplate.tableHeader.productType'), type: "string" },
      "SKUUploadTemplate.productStatus":  { name: t('page.supplier.supplierSummaryPage.skuUploadTemplate.tableHeader.productStatus'), type: "string" },
      "SKUUploadTemplate.skuInternalUseTitle":  { name: t('page.supplier.supplierSummaryPage.skuUploadTemplate.tableHeader.skuInternalUseTitle'), type: "string" },
      "SKUUploadTemplate.productTitleEn":  { name: t('page.supplier.supplierSummaryPage.skuUploadTemplate.tableHeader.productTitleEn'), type: "string" },
      "SKUUploadTemplate.productTitleZh":  { name: t('page.supplier.supplierSummaryPage.skuUploadTemplate.tableHeader.productTitleZh'), type: "string" },
      "SKUUploadTemplate.categoriesCode":  { name: t('page.supplier.supplierSummaryPage.skuUploadTemplate.tableHeader.categoriesCode'), type: "string" },
    },
    grid: {xs: 12, lg: 12},
    export: { allowExport: true, filename: 'sku_upload_template_'  + format(new Date(), 'yyyyMMddHHmmss') }
  }
  let query = {
    ...OmnitechQueries.skus.SKUUploadTemplate.query,
  }
  
  query = applyFilter(query, propertyAcFilter, "SKUUploadTemplate.propertyAc", null, null)
  if(isRefresh)
    query = {}
  const result = useCubeQuery(query, {resetResultSetOnChange: true})
  if(isRefresh)
    setRefresh(false)
  return { item, result }
}


const StockMovementQuery = (propertyAcFilter, storeCodeFilter, startDateFilter, endDateFilter, isRefresh, setRefresh) => {
  const { t } = useTranslation();
  if(startDateFilter == null && endDateFilter == null){
    startDateFilter = new Date()
    startDateFilter.setHours(0,0,0,0);
    endDateFilter = new Date()
    endDateFilter.setHours(23,59,59,999);
  }
  else if(startDateFilter == null){
    startDateFilter = setHours(endDateFilter, 0)
    startDateFilter = setMinutes(startDateFilter, 0)
    startDateFilter = setSeconds(startDateFilter, 0)
    startDateFilter = setMilliseconds(startDateFilter, 0)
  }
  else if (endDateFilter == null){
    endDateFilter = setHours(startDateFilter, 23)
    endDateFilter = setMinutes(endDateFilter, 59)
    endDateFilter = setSeconds(endDateFilter, 59)
    endDateFilter = setMilliseconds(endDateFilter, 999)
  }
  let item = {
    name: t('page.supplier.supplierSummaryPage.stockMovement.name'),
    tableHeaders: {
      "StockMovement.transactionTimestamp":  { name: t('page.supplier.supplierSummaryPage.stockMovement.tableHeader.transactionTimestamp'), type: "dateTime" },
      "StockMovement.propertyAc":  { name: t('page.supplier.supplierSummaryPage.stockMovement.tableHeader.propertyAc'), type: "string" },
      "StockMovement.skuCode":  { name: t('page.supplier.supplierSummaryPage.stockMovement.tableHeader.skuCode'), type: "string" },
      "StockMovement.upcCode":  { name: t('page.supplier.supplierSummaryPage.stockMovement.tableHeader.upcCode'), type: "string" },
      "StockMovement.productCode":  { name: t('page.supplier.supplierSummaryPage.stockMovement.tableHeader.productCode'), type: "string" },
      "StockMovement.storeCode":  { name: t('page.supplier.supplierSummaryPage.stockMovement.tableHeader.storeCode'), type: "string" },
      "StockMovement.txNumber":  { name: t('page.supplier.supplierSummaryPage.stockMovement.tableHeader.txNumber'), type: "string" },
      "StockMovement.txType":  { name: t('page.supplier.supplierSummaryPage.stockMovement.tableHeader.txType'), type: "string" },
      "StockMovement.quantity":  { name: t('page.supplier.supplierSummaryPage.stockMovement.tableHeader.quantity'), type: "number" },
    },
    grid: {xs: 12, lg: 12},
    export: { allowExport: true, filename: 'stock_movement_'  + format(startDateFilter, 'yyyyMMddHHmmss') + 
      '_' + format(endDateFilter, 'yyyyMMddHHmmss') }
  }
  let query = {
    ...OmnitechQueries.mvStockMovement.StockMovement.query,
    "timeDimensions": OmnitechQueries.mvStockMovement.StockMovement.query.timeDimensions.concat(
      {
        "dimension": "StockMovement.transactionTimestamp",
        "dateRange": [ startDateFilter, endDateFilter ]
      })
  }
  query = applyFilter(query, propertyAcFilter, "StockMovement.propertyAc", storeCodeFilter, "StockMovement.storeCode")
  if(isRefresh)
    query = {}
  const result = useCubeQuery(query, {resetResultSetOnChange: true})
  if(isRefresh)
    setRefresh(false)
  return { item, result }
}

const SupplierSummaryPage = () => {
  const { t } = useTranslation();
  const context = useContext(CubeJSContext);
  let propertyAcSelect = []
  if(context.propertyAc)
    propertyAcSelect = context.propertyAc
  const [ propertyAcFilter, setPropertyAcFilter ] = useState([]);
  let storeCodeSelect = []
  if(context.storeCodes)
    storeCodeSelect = context.storeCodes
  const [ storeCodeFilter, setStoreCodeFilter ] = useState([]);
  const [rowsPerPage1, setRowsPerPage1] = useState(10);
  const [page1, setPage1] = useState(0);
  const [rowsPerPage2, setRowsPerPage2] = useState(10);
  const [page2, setPage2] = useState(0);
  const [rowsPerPage3, setRowsPerPage3] = useState(10);
  const [page3, setPage3] = useState(0);
  const [rowsPerPage4, setRowsPerPage4] = useState(10);
  const [page4, setPage4] = useState(0);
  const [rowsPerPage5, setRowsPerPage5] = useState(10);
  const [page5, setPage5] = useState(0);
  const [rowsPerPage6, setRowsPerPage6] = useState(10);
  const [page6, setPage6] = useState(0);
  let filterStartDate = new Date()
  filterStartDate.setHours(0,0,0,0);
  let filterEndDate = new Date()
  filterEndDate.setHours(23,59,59,999);
  const [dateRangeFilter, setDateRangeFilter] = useState([filterStartDate, filterEndDate])
  const [startDateFilter, endDateFilter] = dateRangeFilter

  const item = {grid: {xs: 6, lg: 6}}

  const [ refresh1, setRefresh1 ] = useState(false);
  const [ refresh2, setRefresh2 ] = useState(false);
  const [ refresh3, setRefresh3 ] = useState(false);
  const [ refresh4, setRefresh4 ] = useState(false);
  const [ refresh5, setRefresh5 ] = useState(false);
  const [ refresh6, setRefresh6 ] = useState(false);
  const [ refresh7, setRefresh7 ] = useState(false);

  const resultSets = TotalOrderQuantityBreakdownByPropertyACQuery(propertyAcFilter, storeCodeFilter, startDateFilter, endDateFilter, refresh1, setRefresh1)
  const query1  = OrderQuantityBreakdownByPropertyACQuery(propertyAcFilter, storeCodeFilter, startDateFilter, endDateFilter, refresh2, setRefresh2)
  const query2  = OrderQuantityBreakdownByPropertyACSummaryQuery(propertyAcFilter, storeCodeFilter, startDateFilter, endDateFilter, refresh3, setRefresh3)
  const query3  = SKUUploadTemplateQuery(propertyAcFilter, refresh4, setRefresh4)
  const query4  = StockMovementQuery(propertyAcFilter, storeCodeFilter, startDateFilter, endDateFilter, refresh5, setRefresh5)
  const query5  = DiscountQuantityBreakdownByPropertyACQuery(propertyAcFilter, storeCodeFilter, startDateFilter, endDateFilter, refresh6, setRefresh6)
  const query6  = DiscountQuantityBreakdownByPropertyACSummaryQuery(propertyAcFilter, storeCodeFilter, startDateFilter, endDateFilter, refresh7, setRefresh7)

  const refresh = () => {
    setRefresh1(true)
    setRefresh2(true)
    setRefresh3(true)
    setRefresh4(true)
    setRefresh5(true)
    setRefresh6(true)
    setRefresh7(true)
    setPage1(0)
    setPage2(0)
    setPage3(0)
    setPage4(0)
    setPage5(0)
    setPage6(0)
  }

  return ([
    <Header />,
    <PageHeaderComponent onClickFunc={refresh} refreshlabel={t('component.refresh')} headerTitle={t('component.header.menu.supplierSummary')} />,
    <Dashboard>
      <MultiSelectComponent id={"property_ac_filter"} state={propertyAcFilter} pageChangerArr={[setPage1,setPage2,setPage3,setPage4,setPage5,setPage6]}
        stateChanger={setPropertyAcFilter} textLabel={t('page.supplier.supplierSummaryPage.filter.propertyAc.name')} selectArray={propertyAcSelect}/>
      <MultiSelectComponent id={"store_code_filter"} state={storeCodeFilter} pageChangerArr={[setPage1,setPage2,setPage3,setPage4,setPage5,setPage6]}
        stateChanger={setStoreCodeFilter} textLabel={t('page.supplier.supplierSummaryPage.filter.storeCode.name')} selectArray={storeCodeSelect}/>
      <DateRangePickerComponent state={dateRangeFilter} stateChanger={setDateRangeFilter} 
        pageChangerArr={[setPage1,setPage2,setPage3,setPage4,setPage5,setPage6]}
        startDateLabel={t('page.supplier.supplierSummaryPage.filter.orderCompletedAt.startDate')} 
        toLabel={t('page.supplier.supplierSummaryPage.filter.orderCompletedAt.to')} 
        endDateLabel={t('page.supplier.supplierSummaryPage.filter.orderCompletedAt.endDate')}/>
    </Dashboard>
    ,
    <Dashboard>
      <TextComponent title={t('component.text.historical')} xs={12} lg={12}/>
      <TextComponent title={t('page.supplier.supplierSummaryPage.text.orderCompleted')} xs={12} lg={12} transparent={true}/>
      <NumberComponent item={item} query={resultSets["total"]}
        titleName={t('page.supplier.supplierSummaryPage.titleMapping.totalSkuQuantity')}
        fieldName="OQBBPAC.totalSkuQuantity" />
      <NumberComponent item={item} query={resultSets["total"]}
        titleName={t('page.supplier.supplierSummaryPage.titleMapping.totalGrossSales')}
        fieldName="OQBBPAC.totalGrossSales" />
      <NumberComponent item={item} query={resultSets["total"]}
        titleName={t('page.supplier.supplierSummaryPage.titleMapping.totalDiscount')}
        fieldName="OQBBPAC.totalDiscount" />
      <NumberComponent item={item} query={resultSets["total"]}
        titleName={t('page.supplier.supplierSummaryPage.titleMapping.totalOrderAmount')}
        fieldName="OQBBPAC.totalOrderAmount" />
      <TextComponent title={t('page.supplier.supplierSummaryPage.text.orderConfirmed')} xs={12} lg={12} transparent={true}/>
      <NumberComponent item={item} query={resultSets["total_confirm"]}
        titleName={t('page.supplier.supplierSummaryPage.titleMapping.totalSkuQuantity')}
        fieldName="OQBBPAC.totalSkuQuantity" />
      <NumberComponent item={item} query={resultSets["total_confirm"]}
        titleName={t('page.supplier.supplierSummaryPage.titleMapping.totalGrossSales')}
        fieldName="OQBBPAC.totalGrossSales" />
      <NumberComponent item={item} query={resultSets["total_confirm"]}
        titleName={t('page.supplier.supplierSummaryPage.titleMapping.totalDiscount')}
        fieldName="OQBBPAC.totalDiscount" />
      <NumberComponent item={item} query={resultSets["total_confirm"]}
        titleName={t('page.supplier.supplierSummaryPage.titleMapping.totalOrderAmount')}
        fieldName="OQBBPAC.totalOrderAmount" />
      
      <TextComponent title={t('page.supplier.supplierSummaryPage.text.orderCompletedDetail')} xs={12} lg={12} transparent={true}/>
      <TableComponent item={query1.item} result={query1.result} rowsPerPage={rowsPerPage1} 
        setRowsPerPage={setRowsPerPage1} page={page1} setPage={setPage1}/>
      <TableComponent item={query2.item} result={query2.result} rowsPerPage={rowsPerPage2} 
        setRowsPerPage={setRowsPerPage2} page={page2} setPage={setPage2}/>
      <TableComponent item={query5.item} result={query5.result} rowsPerPage={rowsPerPage5} 
        setRowsPerPage={setRowsPerPage5} page={page5} setPage={setPage5}/>
      <TableComponent item={query6.item} result={query6.result} rowsPerPage={rowsPerPage6} 
        setRowsPerPage={setRowsPerPage6} page={page6} setPage={setPage6}/>
      <TableComponent item={query3.item} result={query3.result} rowsPerPage={rowsPerPage3} 
        setRowsPerPage={setRowsPerPage3} page={page3} setPage={setPage3}/>
      <TableComponent item={query4.item} result={query4.result} rowsPerPage={rowsPerPage4} 
        setRowsPerPage={setRowsPerPage4} page={page4} setPage={setPage4}/>
    </Dashboard>
  ]) 
};

export default SupplierSummaryPage;