const CartDiscountCheckingQueries = {
  CartDiscountChecking: {
    query: {
      "timeDimensions": [
      ],
      "order": {
      },
      "filters": [
      ],
      "dimensions": [
        "CartsDiscountChecking.code",
        "CartsDiscountChecking.rsmCode",
        "CartsDiscountChecking.rsmrCode",
        "CartsDiscountChecking.propertyAc",
        "CartsDiscountChecking.startAt",
        "CartsDiscountChecking.endAt",
      ],
      "limit": 50000,
      "renewQuery": true,
    }
  },
}

export default CartDiscountCheckingQueries