const VoidItemDetailQueries = {
  VoidItemDetail:{
    query: {
      "measures": [
        "MvCartLineProperties.sumQuantity",
      ],
      "order": {
        "MvCartLineProperties.softDeletedAt": "desc",
        "Skus.code": "asc"
      },
      "filters": [
        {
          "member": "MvCartLineProperties.softDelete",
          "operator": "equals",
          "values": [
            "true"
          ]
        },
        {
          "member": "MvCartLineProperties.mergeToCartId",
          "operator": "notSet"
        },
      ],
      "dimensions": [
        "Skus.code",
        "Skus.internalUseTitle",
        "Products.code",
        "ProductTranslations.title",
        "Products.productType",
        "MvCartLineProperties.storeCode",
      ],
      "limit": 50000,
      "renewQuery": true,
    }
  },
  VoidItemCount:{
    query: {
      "measures": [
        "MvCartLineProperties.sumCalculationQuantity",
      ],
      "order": {
      },
      "timeDimensions": [],
      "filters": [
        {
          "member": "MvCartLineProperties.softDelete",
          "operator": "equals",
          "values": [
            "true"
          ]
        },
        {
          "member": "MvCartLineProperties.mergeToCartId",
          "operator": "notSet"
        },
      ],
      "dimensions": [
      ],
      "limit": 50000,
      "renewQuery": true,
    }
  }
}

export default VoidItemDetailQueries