const commonZhHK = {
  stockTakeReportPage: {
    filter: {
      createdAt: {
        startDate: "庫存盤點開始日期",
        to: "至",
        endDate: "庫存盤點結束日期"
      }
    },
    stockTake: {
      name: "庫存盤點概覽",
      tableHeader: {
        id: "庫存盤點編號",
        storeCode: "分店編號",
        stocktakeReferenceNumber: "庫存盤點參考編號",
        skuInventoryType: "SKU庫存類別",
        state: "狀態",
        staffNumber: "員工編號",
        firstName: "員工名",
        lastName: "員工姓",
        createdAt: "創建日期",
        waitingForComparisonAt: "等待比較日期",
        comparingBalanceAt: "比較餘額日期",
        completedAt: "完成日期",
        adjustmentReferenceNumber: "庫存調整參考編號",
      }
    },
    stockTakeLine: {
      name: "庫存盤點細明",
      tableHeader: {
        stockTakeSessionId: "庫存盤點編號",
        stocktakeReferenceNumber: "庫存盤點參考編號",
        adjustmentReferenceNumber: "庫存調整參考編號",
        skuCode: "SKU編號",
        productTitleEn: "產品名稱（英文）",
        productTitleZh: "產品名稱（中文）",
        quantity: "數量",
        snapshotQuantity: "數量(快照)",
        quantityMovement: "數量調整",
        skuMeasure: "SKU重量",
        snapshotSkuMeasure: "SKU重量(快照)",
        skuMeasureMovement: "SKU重量調整",
        skuMeasurementCode: "單位",
        state: "狀態",
        processedAt: "已處理日期"
      }
    },
    skuInventoryAdjustment: {
      name: "庫存調整細明",
      tableHeader: {
        storeCode: "分店編號",
        state: "狀態",
        staffNumber: "員工編號",
        firstName: "員工名",
        lastName: "員工姓",
        userType: "員工類別",
        stocktakeReferenceNumber: "庫存盤點參考編號",
        adjustmentReferenceNumber: "庫存調整參考編號",
        skuCode: "SKU編號",
        productTitleEn: "產品名稱（英文）",
        productTitleZh: "產品名稱（中文）",
        quantityMovement: "數量調整",
        skuMeasureMovement: "SKU重量調整",
        skuMeasurementCode: "單位",
        createdAt: "創建日期",
        queueingProcessAt: "已排程處理日期",
        processingAt: "處理中日期",
        processedAt: "已處理日期",
        remark: "備註"
      }
    }
  },
  skuInventoryAdjustmentReportPage: {
    filter: {
      createdAt: {
        startDate: "庫存調整開始日期",
        to: "至",
        endDate: "庫存調整結束日期"
      },
      state: {
        name: "狀態"
      },
      storeCode: {
        name: "分店編號"
      }
    },
    skuInventoryAdjustment: {
      name: "庫存調整細明",
      tableHeader: {
        storeCode: "分店編號",
        state: "狀態",
        staffNumber: "員工編號",
        firstName: "員工名",
        lastName: "員工姓",
        userType: "員工類別",
        stocktakeReferenceNumber: "庫存盤點參考編號",
        adjustmentReferenceNumber: "庫存調整參考編號",
        skuCode: "SKU編號",
        productTitleEn: "產品名稱（英文）",
        productTitleZh: "產品名稱（中文）",
        quantityMovement: "數量調整",
        skuMeasureMovement: "SKU重量調整",
        skuMeasurementCode: "單位",
        createdAt: "創建日期",
        queueingProcessAt: "已排程處理日期",
        processingAt: "處理中日期",
        processedAt: "已處理日期",
        remark: "備註"
      }
    }
  }
}

export default commonZhHK