import React, { useState } from "react";
import Header from '../../components/Header';
import TableComponent from '../../components/TableComponent';
import Dashboard from '../../components/Dashboard';
import OmnitechQueries from '../../queries/OmnitechQueries'
import PageHeaderComponent from '../../components/PageHeaderComponent';
import { useTranslation } from 'react-i18next';
import { useCubeQuery } from '@cubejs-client/react';
import { format } from 'date-fns'

const PublicCouponUsageQuery = (isRefresh, setRefresh) => {
  const { t } = useTranslation();
  let item = {
    name: t('page.membership.couponUsagePage.publicCouponUsage.name'),
    tableHeaders: {
      "PublicCouponUsage.code":  { name: t('page.membership.couponUsagePage.publicCouponUsage.tableHeader.code'), type: "string" },
      "PublicCouponUsage.used":  { name: t('page.membership.couponUsagePage.publicCouponUsage.tableHeader.used'), type: "number" },
    },
    grid: {xs: 12, lg: 12},
    export: { allowExport: true, filename: 'public_coupon_usage_export' + format(new Date(), 'yyyyMMddHHmmss') }
  }
  let query = {
    ...OmnitechQueries.coupons.PublicCouponUsage.query
  }
  if(isRefresh)
    query = {}
  const result = useCubeQuery(query, {resetResultSetOnChange: true})
  if(isRefresh)
    setRefresh(false)
  return { item, result }
}

const MemberCouponUsageQuery = (isRefresh, setRefresh) => {
  const { t } = useTranslation();
  let item = {
    name: t('page.membership.couponUsagePage.memberCouponUsage.name'),
    tableHeaders: {
      "MemberCouponUsage.code":  { name: t('page.membership.couponUsagePage.memberCouponUsage.tableHeader.code'), type: "string" },
      "MemberCouponUsage.used":  { name: t('page.membership.couponUsagePage.memberCouponUsage.tableHeader.used'), type: "number" },
      "MemberCouponUsage.redeemed":  { name: t('page.membership.couponUsagePage.memberCouponUsage.tableHeader.redeemed'), type: "number" },
      "MemberCouponUsage.usedRatio":  { name: t('page.membership.couponUsagePage.memberCouponUsage.tableHeader.usedRatio'), type: "number" },
    },
    grid: {xs: 12, lg: 12},
    export: { allowExport: true, filename: 'member_coupon_usage_export' + format(new Date(), 'yyyyMMddHHmmss') }
  }
  let query = {
    ...OmnitechQueries.coupons.MemberCouponUsage.query
  }
  if(isRefresh)
    query = {}
  const result = useCubeQuery(query, {resetResultSetOnChange: true})
  if(isRefresh)
    setRefresh(false)
  return { item, result }
}

const CouponUsagePage = () => {
  const { t } = useTranslation();
  const [rowsPerPage1, setRowsPerPage1] = useState(10);
  const [page1, setPage1] = useState(0);
  const [rowsPerPage2, setRowsPerPage2] = useState(10);
  const [page2, setPage2] = useState(0);

  const [ refresh1, setRefresh1 ] = useState(false);
  const [ refresh2, setRefresh2 ] = useState(false);

  const query1  = PublicCouponUsageQuery(refresh1, setRefresh1)
  const query2  = MemberCouponUsageQuery(refresh2, setRefresh2)

  const refresh = () => {
    setRefresh1(true)
    setPage1(0)
    setRefresh2(true)
    setPage2(0)
  }

  return ([
    <Header />,
    <PageHeaderComponent onClickFunc={refresh} refreshlabel={t('component.refresh')} headerTitle={t('component.header.menu.couponUsage')} />,
    <Dashboard>
      <TableComponent item={query1.item} result={query1.result} rowsPerPage={rowsPerPage1} 
        setRowsPerPage={setRowsPerPage1} page={page1} setPage={setPage1}/>
      <TableComponent item={query2.item} result={query2.result} rowsPerPage={rowsPerPage2} 
        setRowsPerPage={setRowsPerPage2} page={page2} setPage={setPage2}/>
    </Dashboard>
  ]) 
};

export default CouponUsagePage;