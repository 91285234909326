import React from "react";
import Header from '../components/Header';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';

const DashboardPage = () => {
  const { t } = useTranslation();

  return (
    <>
      <Header />
      <div
        style={{
          textAlign: 'center',
          padding: 12,
        }}
      >
        <Typography variant="h5" color="inherit">
          {t('page.dashboard.main')}
        </Typography>
      </div>
    </>
  );
};

export default DashboardPage;
