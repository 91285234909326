const OrderQuantityBreakdownByPropertyACQueries = {
  OrderQuantityBreakdownByPropertyAC: {
    query: {
      "timeDimensions": [
      ],
      "order": {
        "OQBBPAC.propertyAc": "asc"
      },
      "filters": [
        {
          "member": "OQBBPAC.orderTransactionType",
          "operator": "equals",
          "values": [
            "sku"
          ]
        },
        {
          "member": "OQBBPAC.orderState",
          "operator": "equals",
          "values": [
            "completed"
          ]
        }
      ],
      "dimensions": [
        "OQBBPAC.uuid",
        "OQBBPAC.propertyAc",
        "OQBBPAC.referenceNumber",
        "OQBBPAC.shipFromStoreCode",
        "OQBBPAC.skuCode",
        "OQBBPAC.upcCode",
        "OQBBPAC.skuInternalUseTitle",
        "OQBBPAC.completedAt",
        "OQBBPAC.txHour",
        "OQBBPAC.weekday",
        "OQBBPAC.txDow",
        "OQBBPAC.unitAmount",
        "OQBBPAC.quantity",
        "OQBBPAC.orderAmount",
      ],
      "limit": 50000,
      "renewQuery": true,
      "ungrouped": true
    }
  },
  TotalOrderQuantityBreakdownByPropertyAC: {
    query: {
      "measures": [
        "OQBBPAC.totalSkuQuantity",
        "OQBBPAC.totalOrderAmount",
        "OQBBPAC.totalGrossSales",
        "OQBBPAC.totalDiscount",
      ],
      "timeDimensions": [
      ],
      "order": {
      },
      "filters": [
        {
          "member": "OQBBPAC.orderState",
          "operator": "equals",
          "values": [
            "completed"
          ]
        }
      ],
      "dimensions": [
      ],
      "limit": 50000,
      "renewQuery": true,
    }
  },
  TotalOrderQuantityBreakdownByPropertyACConfirm: {
    query: {
      "measures": [
        "OQBBPAC.totalSkuQuantity",
        "OQBBPAC.totalOrderAmount",
        "OQBBPAC.totalGrossSales",
        "OQBBPAC.totalDiscount",
      ],
      "timeDimensions": [
      ],
      "order": {
      },
      "filters": [
        {
          "member": "OQBBPAC.orderState",
          "operator": "equals",
          "values": [
            "confirmed"
          ]
        }
      ],
      "dimensions": [
      ],
      "limit": 50000,
      "renewQuery": true,
    }
  },
  OrderQuantityBreakdownByPropertyACSummary: {
    query: {
      "measures": [
        "OQBBPAC.totalQuantity",
        "OQBBPAC.totalOrderAmount"
      ],
      "timeDimensions": [
      ],
      "order": {
      },
      "filters": [
        {
          "member": "OQBBPAC.orderTransactionType",
          "operator": "equals",
          "values": [
            "sku"
          ]
        },
        {
          "member": "OQBBPAC.orderState",
          "operator": "equals",
          "values": [
            "completed"
          ]
        }
      ],
      "dimensions": [
        "OQBBPAC.shipFromStoreCode",
        "OQBBPAC.skuCode",
        "OQBBPAC.upcCode",
      ],
      "limit": 50000,
      "renewQuery": true,
    }
  },
  DiscountQuantityBreakdownByPropertyAC: {
    query: {
      "timeDimensions": [
      ],
      "order": {
        "OQBBPAC.propertyAc": "asc"
      },
      "filters": [
        {
          "member": "OQBBPAC.orderTransactionType",
          "operator": "equals",
          "values": [
            "promotion",
            "discount"
          ]
        },
        {
          "member": "OQBBPAC.orderState",
          "operator": "equals",
          "values": [
            "completed"
          ]
        }
      ],
      "dimensions": [
        "OQBBPAC.uuid",
        "OQBBPAC.propertyAc",
        "OQBBPAC.referenceNumber",
        "OQBBPAC.shipFromStoreCode",
        "OQBBPAC.discountCode",
        "OQBBPAC.discountNameEn",
        "OQBBPAC.discountNameZh",
        "OQBBPAC.orderTransactionType",
        "OQBBPAC.completedAt",
        "OQBBPAC.txHour",
        "OQBBPAC.weekday",
        "OQBBPAC.txDow",
        "OQBBPAC.unitAmount",
        "OQBBPAC.quantity",
        "OQBBPAC.orderAmount",
      ],
      "limit": 50000,
      "renewQuery": true,
      "ungrouped": true
    }
  },
  DiscountQuantityBreakdownByPropertyACSummary: {
    query: {
      "measures": [
        "OQBBPAC.totalQuantity",
        "OQBBPAC.totalOrderAmount"
      ],
      "timeDimensions": [
      ],
      "order": {
      },
      "filters": [
        {
          "member": "OQBBPAC.orderTransactionType",
          "operator": "equals",
          "values": [
            "promotion",
            "discount"
          ]
        },
        {
          "member": "OQBBPAC.orderState",
          "operator": "equals",
          "values": [
            "completed"
          ]
        }
      ],
      "dimensions": [
        "OQBBPAC.shipFromStoreCode",
        "OQBBPAC.discountCode",
        "OQBBPAC.discountNameEn",
        "OQBBPAC.discountNameZh",
        "OQBBPAC.orderTransactionType",
      ],
      "limit": 50000,
      "renewQuery": true,
    }
  },
}

export default OrderQuantityBreakdownByPropertyACQueries