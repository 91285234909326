import React, { useState } from "react";
import Header from '../../components/Header';
import TableComponent from '../../components/TableComponent';
import DateRangePickerComponent from '../../components/DateRangePickerComponent';
import Dashboard from '../../components/Dashboard';
import PageHeaderComponent from '../../components/PageHeaderComponent';
import OmnitechQueries from '../../queries/OmnitechQueries'
import { useTranslation } from 'react-i18next';
import { useCubeQuery } from '@cubejs-client/react';
import { setHours, setMinutes, setSeconds, setMilliseconds, format } from 'date-fns'

const StockTakeQuery = (startDateFilter, endDateFilter, isRefresh, setRefresh, setStocktakeReferenceNumber, setSkuAdjustmentReferenceNumber) => {
  const { t } = useTranslation();
  if(startDateFilter == null && endDateFilter == null){
    startDateFilter = new Date()
    startDateFilter.setHours(0,0,0,0);
    endDateFilter = new Date()
    endDateFilter.setHours(23,59,59,999);
  }
  else if(startDateFilter == null){
    startDateFilter = setHours(endDateFilter, 0)
    startDateFilter = setMinutes(startDateFilter, 0)
    startDateFilter = setSeconds(startDateFilter, 0)
    startDateFilter = setMilliseconds(startDateFilter, 0)
  }
  else if (endDateFilter == null){
    endDateFilter = setHours(startDateFilter, 23)
    endDateFilter = setMinutes(endDateFilter, 59)
    endDateFilter = setSeconds(endDateFilter, 59)
    endDateFilter = setMilliseconds(endDateFilter, 999)
  }
  let item = {
    name: t('page.stocktake.stockTakeReportPage.stockTake.name'),
    tableHeaders: {
      "StockTake.stocktakeReferenceNumber": { name: t('page.stocktake.stockTakeReportPage.stockTake.tableHeader.stocktakeReferenceNumber'), type: 'string', function: setStocktakeReferenceNumber},
      "StockTake.storeCode": { name: t('page.stocktake.stockTakeReportPage.stockTake.tableHeader.storeCode'), type: 'string'},
      "StockTake.skuInventoryType":  { name: t('page.stocktake.stockTakeReportPage.stockTake.tableHeader.skuInventoryType'), type: "string" },
      "StockTake.state": { name: t('page.stocktake.stockTakeReportPage.stockTake.tableHeader.state'), type: 'string'},
      "StockTake.staffNumber":  { name: t('page.stocktake.stockTakeReportPage.stockTake.tableHeader.staffNumber'), type: "string" },
      "StockTake.firstName": { name: t('page.stocktake.stockTakeReportPage.stockTake.tableHeader.firstName'), type: 'string'},
      "StockTake.lastName": { name: t('page.stocktake.stockTakeReportPage.stockTake.tableHeader.lastName'), type: 'string'},
      "StockTake.createdAt": { name: t('page.stocktake.stockTakeReportPage.stockTake.tableHeader.createdAt'), type: 'dateTime'},
      "StockTake.waitingForComparisonAt": { name: t('page.stocktake.stockTakeReportPage.stockTake.tableHeader.waitingForComparisonAt'), type: 'dateTime'},
      "StockTake.comparingBalanceAt": { name: t('page.stocktake.stockTakeReportPage.stockTake.tableHeader.comparingBalanceAt'), type: 'dateTime'},
      "StockTake.completedAt": { name: t('page.stocktake.stockTakeReportPage.stockTake.tableHeader.completedAt'), type: 'dateTime'},
      "StockTake.adjustmentReferenceNumber": { name: t('page.stocktake.stockTakeReportPage.stockTake.tableHeader.adjustmentReferenceNumber'), type: 'string', function: setSkuAdjustmentReferenceNumber},
    },
    grid: {xs: 12},
    export: { allowExport: true, filename: 'stocktake_summary_' + format(startDateFilter, 'yyyyMMddHHmmss') +
      '_' + format(endDateFilter, 'yyyyMMddHHmmss') }
  }
  let query = {
    ...OmnitechQueries.stocktake.Stocktake.query,
    "timeDimensions": [
      {
        "dimension": "StockTake.createdAt",
        "dateRange": [ startDateFilter, endDateFilter ]
      }
    ],
  }
  if(isRefresh)
    query = {}
  const result = useCubeQuery(query, {resetResultSetOnChange: true})
  if(isRefresh)
    setRefresh(false)
  return { item, result }
}

const StockTakeLineQuery = (stocktakeFilter, isRefresh, setRefresh, setSkuAdjustmentReferenceNumber) => {
  const { t } = useTranslation();
  let item = {
    name: t('page.stocktake.stockTakeReportPage.stockTakeLine.name') + ' - ' + stocktakeFilter,
    tableHeaders: {
      "StockTakeLine.stocktakeReferenceNumber": { name: t('page.stocktake.stockTakeReportPage.stockTakeLine.tableHeader.stocktakeReferenceNumber'), type: 'string'},
      "StockTakeLine.skuCode": { name: t('page.stocktake.stockTakeReportPage.stockTakeLine.tableHeader.skuCode'), type: 'string'},
      "StockTakeLine.productTitleEn": { name: t('page.stocktake.stockTakeReportPage.stockTakeLine.tableHeader.productTitleEn'), type: 'string'},
      "StockTakeLine.productTitleZh":  { name: t('page.stocktake.stockTakeReportPage.stockTakeLine.tableHeader.productTitleZh'), type: "string" },
      "StockTakeLine.quantity": { name: t('page.stocktake.stockTakeReportPage.stockTakeLine.tableHeader.quantity'), type: 'number'},
      "StockTakeLine.snapshotQuantity":  { name: t('page.stocktake.stockTakeReportPage.stockTakeLine.tableHeader.snapshotQuantity'), type: "number" },
      "StockTakeLine.quantityMovement": { name: t('page.stocktake.stockTakeReportPage.stockTakeLine.tableHeader.quantityMovement'), type: 'number'},
      "StockTakeLine.skuMeasure": { name: t('page.stocktake.stockTakeReportPage.stockTakeLine.tableHeader.skuMeasure'), type: 'number'},
      "StockTakeLine.snapshotSkuMeasure": { name: t('page.stocktake.stockTakeReportPage.stockTakeLine.tableHeader.snapshotSkuMeasure'), type: 'number'},
      "StockTakeLine.skuMeasureMovement": { name: t('page.stocktake.stockTakeReportPage.stockTakeLine.tableHeader.skuMeasureMovement'), type: 'number'},
      "StockTakeLine.skuMeasurementCode": { name: t('page.stocktake.stockTakeReportPage.stockTakeLine.tableHeader.skuMeasurementCode'), type: 'string'},
      "StockTakeLine.state": { name: t('page.stocktake.stockTakeReportPage.stockTakeLine.tableHeader.state'), type: 'string'},
      "StockTakeLine.adjustmentReferenceNumber": { name: t('page.stocktake.stockTakeReportPage.stockTakeLine.tableHeader.adjustmentReferenceNumber'), type: 'string', function: setSkuAdjustmentReferenceNumber},
      "StockTakeLine.processedAt": { name: t('page.stocktake.stockTakeReportPage.stockTakeLine.tableHeader.processedAt'), type: 'dateTime'},
    },
    grid: {xs: 12},
    export: { allowExport: true, filename: 'stocktake_detail_' + stocktakeFilter }
  }
  let query = {
    ...OmnitechQueries.stocktake.StocktakeLine.query,
    "filters": [
      {
        member: "StockTakeLine.stocktakeReferenceNumber",
        operator: "contains",
        values: [stocktakeFilter]
      }
    ],
  }
  if(isRefresh || stocktakeFilter === '')
    query = {}
  const result = useCubeQuery(query, {resetResultSetOnChange: true})
  if(isRefresh)
    setRefresh(false)
  return { item, result }
}

const SkuInventoryAdjustmentQuery = (adjustmentFilter, isRefresh, setRefresh) => {
  const { t } = useTranslation();
  let item = {
    name: t('page.stocktake.stockTakeReportPage.skuInventoryAdjustment.name') + ' - ' + adjustmentFilter,
    tableHeaders: {
      "SkuInventoryAdjustment.adjustmentReferenceNumber": { name: t('page.stocktake.stockTakeReportPage.skuInventoryAdjustment.tableHeader.adjustmentReferenceNumber'), type: 'string'},
      "SkuInventoryAdjustment.storeCode": { name: t('page.stocktake.stockTakeReportPage.skuInventoryAdjustment.tableHeader.storeCode'), type: 'string'},
      "SkuInventoryAdjustment.state": { name: t('page.stocktake.stockTakeReportPage.skuInventoryAdjustment.tableHeader.state'), type: 'string'},
      "SkuInventoryAdjustment.skuCode":  { name: t('page.stocktake.stockTakeReportPage.skuInventoryAdjustment.tableHeader.skuCode'), type: "string" },
      "SkuInventoryAdjustment.productTitleEn": { name: t('page.stocktake.stockTakeReportPage.skuInventoryAdjustment.tableHeader.productTitleEn'), type: 'string'},
      "SkuInventoryAdjustment.productTitleZh":  { name: t('page.stocktake.stockTakeReportPage.skuInventoryAdjustment.tableHeader.productTitleZh'), type: "string" },
      "SkuInventoryAdjustment.quantityMovement": { name: t('page.stocktake.stockTakeReportPage.skuInventoryAdjustment.tableHeader.quantityMovement'), type: 'number'},
      "SkuInventoryAdjustment.skuMeasureMovement": { name: t('page.stocktake.stockTakeReportPage.skuInventoryAdjustment.tableHeader.skuMeasureMovement'), type: 'number'},
      "SkuInventoryAdjustment.skuMeasurementCode": { name: t('page.stocktake.stockTakeReportPage.skuInventoryAdjustment.tableHeader.skuMeasurementCode'), type: 'string'},
      "SkuInventoryAdjustment.staffNumber": { name: t('page.stocktake.stockTakeReportPage.skuInventoryAdjustment.tableHeader.staffNumber'), type: 'string'},
      "SkuInventoryAdjustment.firstName": { name: t('page.stocktake.stockTakeReportPage.skuInventoryAdjustment.tableHeader.firstName'), type: 'string'},
      "SkuInventoryAdjustment.lastName": { name: t('page.stocktake.stockTakeReportPage.skuInventoryAdjustment.tableHeader.lastName'), type: 'string'},
      "SkuInventoryAdjustment.createdAt": { name: t('page.stocktake.stockTakeReportPage.skuInventoryAdjustment.tableHeader.createdAt'), type: 'string'},
      "SkuInventoryAdjustment.queueingProcessAt": { name: t('page.stocktake.stockTakeReportPage.skuInventoryAdjustment.tableHeader.queueingProcessAt'), type: 'dateTime'},
      "SkuInventoryAdjustment.processingAt": { name: t('page.stocktake.stockTakeReportPage.skuInventoryAdjustment.tableHeader.processingAt'), type: 'dateTime'},
      "SkuInventoryAdjustment.processedAt": { name: t('page.stocktake.stockTakeReportPage.skuInventoryAdjustment.tableHeader.processedAt'), type: 'dateTime'},
      "SkuInventoryAdjustment.remark": { name: t('page.stocktake.stockTakeReportPage.skuInventoryAdjustment.tableHeader.remark'), type: 'string'},
    },
    grid: {xs: 12},
    export: { allowExport: true, filename: 'sku_inventory_adjusment_detail_' + adjustmentFilter }
  }
  let query = {
    ...OmnitechQueries.stocktake.SkuInventoryAdjustment.query,
    "filters": [
      {
        member: "SkuInventoryAdjustment.adjustmentReferenceNumber",
        operator: "contains",
        values: [adjustmentFilter]
      }
    ],
  }
  if(isRefresh || adjustmentFilter === '')
    query = {}
  const result = useCubeQuery(query, {resetResultSetOnChange: true})
  if(isRefresh)
    setRefresh(false)
  return { item, result }
}

const StockTakeReportPage = () => {
  const { t } = useTranslation();
  let filterStartDate = new Date()
  filterStartDate.setHours(0,0,0,0);
  let filterEndDate = new Date()
  filterEndDate.setHours(23,59,59,999);
  const [dateRangeFilter, setDateRangeFilter] = useState([filterStartDate, filterEndDate])
  const [startDateFilter, endDateFilter] = dateRangeFilter
  const [stocktakeFilter, setStocktakeFilter ] = useState('');
  const [adjustmentFilter, setadjustmentFilter ] = useState('');
  const [rowsPerPage1, setRowsPerPage1] = useState(10);
  const [page1, setPage1] = useState(0);
  const [rowsPerPage2, setRowsPerPage2] = useState(10);
  const [page2, setPage2] = useState(0);
  const [rowsPerPage3, setRowsPerPage3] = useState(10);
  const [page3, setPage3] = useState(0);

  const [ refresh1, setRefresh1 ] = useState(false);
  const [ refresh2, setRefresh2 ] = useState(false);
  const [ refresh3, setRefresh3 ] = useState(false);

  const handleStockTakeReferenceNumber = (e) => {
    setStocktakeFilter(e.target.textContent)
    setPage2(0)
  }

  const handleSkuAdjumentRefereceNumber = (e) => {
    setadjustmentFilter(e.target.textContent)
    setPage3(0)
  }

  const query1  = StockTakeQuery(startDateFilter, endDateFilter, refresh1, setRefresh1, handleStockTakeReferenceNumber, handleSkuAdjumentRefereceNumber)
  const query2  = StockTakeLineQuery(stocktakeFilter, refresh2, setRefresh2, handleSkuAdjumentRefereceNumber)
  const query3  = SkuInventoryAdjustmentQuery(adjustmentFilter, refresh3, setRefresh3)

  const refresh = () => {
    setRefresh1(true)
    setPage1(0)
    setRefresh2(true)
    setPage2(0)
    setRefresh3(true)
    setPage3(0)
  }

  return ([
    <Header />,
    <PageHeaderComponent onClickFunc={refresh} refreshlabel={t('component.refresh')} headerTitle={t('component.header.menu.stocktakeReport')} />,
    <Dashboard>
      <DateRangePickerComponent state={dateRangeFilter} stateChanger={setDateRangeFilter} pageChangerArr={[setPage1]}
        startDateLabel={t('page.stocktake.stockTakeReportPage.filter.createdAt.startDate')} 
        toLabel={t('page.stocktake.stockTakeReportPage.filter.createdAt.to')} 
        endDateLabel={t('page.stocktake.stockTakeReportPage.filter.createdAt.endDate')}/>
    </Dashboard>
    ,
    <Dashboard>
      <TableComponent item={query1.item} result={query1.result} rowsPerPage={rowsPerPage1} 
        setRowsPerPage={setRowsPerPage1} page={page1} setPage={setPage1}/>
      {(stocktakeFilter !== '') && <TableComponent item={query2.item} result={query2.result} rowsPerPage={rowsPerPage2} 
        setRowsPerPage={setRowsPerPage2} page={page2} setPage={setPage2}/>}
      {(adjustmentFilter !== '') && <TableComponent item={query3.item} result={query3.result} rowsPerPage={rowsPerPage3} 
        setRowsPerPage={setRowsPerPage3} page={page3} setPage={setPage3}/>}
    </Dashboard>
  ]) 
};

export default StockTakeReportPage;