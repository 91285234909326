const commonEnHK = {
  dailyOperation: {
    orderSummaryDetail:{
      name: "Order Summary Detail",
      tableHeader: {
        catType: "Category Type",
        grossSales: "Gross Sales",
        sumSkuQuantity: "Quantity",
        discount: "Discount",
        netSales: "Net Sales",
        percentage: "Sales Percentage"
      }
    },
    titleMapping: {
      orderCount: "Order Count",
      orderQuantity: "Order Quantity",
      netSales: "Net Sales",
      grossSales: "GrossSales",
      orderRounding: "Rounding",
      orderDiscount: "Discount",
      shelfGrossSales: "Shelf Gross Sales",
      shelfTotalQuantity: "Shelf Quantity",
      shelfDiscount: "Shelf Discount",
      shelfNetSales: "Shelf Net Sales",
      shelfPercentage: "Shelf Sales Percentage",
      saladBarGrossSales: "Salad Bar Gross Sales",
      saladBarTotalQuantity: "Salad Bar Quantity",
      saladBarDiscount: "Salad Bar Discount",
      saladBarNetSales: "Salad Bar Net Sales",
      saladBarPercentage: "Salad Bar Sales Percentage",
      fridgeGrossSales: "Fridge Gross Sales",
      fridgeTotalQuantity: "Fridge Quantity",
      fridgeDiscount: "Fridge Discount",
      fridgeNetSales: "Fridge Net Sales",
      fridgePercentage: "Fridge Sales Percentage",
      meatCounterGrossSales: "Meat Counter Gross Sales",
      meatCounterTotalQuantity: "Meat Counter Quantity",
      meatCounterDiscount: "Meat Counter Discount",
      meatCounterNetSales: "Meat Counter Net Sales",
      meatCounterPercentage: "Meat Counter Sales Percentage",
      eshopGrossSales: "E-Shop Gross Sales",
      eshopTotalQuantity: "E-Shop Quantity",
      eshopDiscount: "E-Shop Discount",
      eshopNetSales: "E-Shop Net Sales",
      eshopPercentage: "E-Shop Sales Percentage",
      hongyuteaGrossSales: "Consignment - Hong Yu Tea House Gross Sales",
      hongyuteaTotalQuantity: "Consignment - Hong Yu Tea House Quantity",
      hongyuteaDiscount: "Consignment - Hong Yu Tea House Discount",
      hongyuteaNetSales: "Consignment - Hong Yu Tea House Net Sales",
      hongyuteaPercentage: "Consignment - Hong Yu Tea House Sales Percentage",
      jebsenGrossSales: "Consignment - Jebsen Beverage Gross Sales",
      jebsenTotalQuantity: "Consignment - Jebsen Beverage Quantity",
      jebsenDiscount: "Consignment - Jebsen Beverage Discount",
      jebsenNetSales: "Consignment - Jebsen Beverage Net Sales",
      jebsenPercentage: "Consignment - Jebsen Beverage Sales Percentage",
      vomfassGrossSales: "Consignment - VomFass Gross Sales",
      vomfassTotalQuantity: "Consignment - VomFass Quantity",
      vomfassDiscount: "Consignment - VomFass Discount",
      vomfassNetSales: "Consignment - VomFass Net Sales",
      vomfassPercentage: "Consignment - VomFass Sales Percentage",
      pastrybarGrossSales: "Pastry Bar Gross Sales",
      pastrybarTotalQuantity: "Pastry Bar Quantity",
      pastrybarDiscount: "Pastry Bar Discount",
      pastrybarNetSales: "Pastry Bar Net Sales",
      pastrybarPercentage: "Pastry Bar Sales Percentage",
      pastrybarDrinksGrossSales: "Pastry Bar Drinks Gross Sales",
      pastrybarDrinksTotalQuantity: "Pastry Bar Drinks Quantity",
      pastrybarDrinksDiscount: "Pastry Bar Drinks Discount",
      pastrybarDrinksNetSales: "Pastry Bar Drinks Net Sales",
      pastrybarDrinksPercentage: "Pastry Bar Drinks Sales Percentage",
      dairyCounterGrossSales: "Dairy Counter Gross Sales",
      dairyCounterTotalQuantity: "Dairy Counter Quantity",
      dairyCounterDiscount: "Dairy Counter Discount",
      dairyCounterNetSales: "Dairy Counter Net Sales",
      dairyCounterPercentage: "Dairy Counter Sales Percentage",
      organicWarehouseGrossSales: "Organic Warehouse Gross Sales",
      organicWarehouseTotalQuantity: "Organic Warehouse Quantity",
      organicWarehouseDiscount: "Organic Warehouse Discount",
      organicWarehouseNetSales: "Organic Warehouse Net Sales",
      organicWarehousePercentage: "Organic Warehouse Sales Percentage",
      dryAgedGrossSales: "Dry Aged Gross Sales",
      dryAgedTotalQuantity: "Dry Aged Quantity",
      dryAgedDiscount: "Dry Aged Discount",
      dryAgedNetSales: "Dry Aged Net Sales",
      dryAgedPercentage: "Dry Aged Sales Percentage",
      kojiGrossSales: "Koji Gross Sales",
      kojiTotalQuantity: "Koji Quantity",
      kojiDiscount: "Koji Discount",
      kojiNetSales: "Koji Net Sales",
      kojiPercentage: "Koji Sales Percentage",
      otherGrossSales: "Other Gross Sales",
      otherTotalQuantity: "Other Quantity",
      otherDiscount: "Other Discount",
      otherNetSales: "Other Net Sales",
      otherPercentage: "Other Sales Percentage",
      totalGrossSalesChart: "Total Net Sales Chart",
      totalRefund: "Total Refund",
      totalRefundAmount: "Total Refund Amount",
      netSalesDeductRefund: "Net Sales (Deducted Refund)"
    },
    filter: {
      storeCode: {
        name: "Store Code",
        all: "ALL"
      },
      orderConfirmedAt: {
        "startDate": "Order Confirmed At From Date",
        "to": "to",
        "endDate": "Order Confirmed At End Date"
      }
    },
  },
  "dailyOperationFnb": {
    "filter": {
      "store": {
        "all": "ALL",
        "name": "Store Code"
      },
      "period": {
        "name": "Period",
        "all": "All",
        "lunch": "Lunch",
        "dinner": "Dinner"
      },
      "orderCompletedAt": {
        "date": "Order Completed At",
      }
    },
    "titleMapping": {
      "totalRevenue": "Total Revenue",
      "totalOrder": "Total Order",
      "totalMembershipSales": "Total Membership Sales",
      "totalNetSales": "Total Net Sales",
      "totalGrossSales": "Total Gross Sales",
      "totalDiscounts": "Total Discounts",
      "totalVoidOrder": "Total Void Order",
      "totalVoidOrderAmount": "Total Void Order Amount",
      "totalOrderAmountRounding": "Total Order Amount Rounding",
      "avgBillAmount": "Average Order Amount",
      "perPersonSpending": "Per Person Spending",
      "totalPax": "Total PAX",
      "totalTip": "Total Tips",
      "totalShippingFees": "Total Shipping Fees",
      "totalServiceCharges": "Total Service Charges",
      "totalVoidItem": "Total Void Item",
      "outStandingNetsales": "Outstanding Table Net Sales",
      "totalFoodGrossSales": "Total Food Gross Sales",
      "totalBeverageGrossSales": "Total Beverage Gross Sales",
      "totalOtherGrossSales": "Total Other Gross Sales",
    },
    "paymentByStoreType":{
      "name": "Payment By Store and Type",
      "tableHeader": {
        "sumAmount": "Amount",
        "sumTip": "Tip",
        "grandTotal": "Grand Total",
        "countOrderId": "Order Count",
        "shipmentsFromStoreCode": "Store Code",
        "paymentProviderCode": "Payment Provider Code",
        "name": "Payment Provider Name",
        "currencyCode": "Currency Code",
        "state": "State"
      }
    },
    "discountBreakdown":{
      "name": "Discount Breakdown",
      "tableHeader": {
        "shipFromStoreCode": "Store Code",
        "discountCode": "Discount Code",
        "discountName": "Discount Name",
        "lineTotal": "Line Total",
        "quantity": "Quantity"
      },
    },
    "RSMSalesDetails": {
      "name": "RSM Sales Details",
      "tableHeader": {
        "code": "RSM Code",
        "lineTotal": "Line Total",
        "quantity": "Quantity"
      },
    },
    "text":{
      "paymentCodeMapping":{
        "vsm": "vsm - VISA (Manual);",
        "msm": "msm - MASTER (Manual);",
        "CV": "CV - Cash Voucher;",
        "CU": "CU - UNION PAY (Manual);",
        "aem": "aem - AMERICAN EXPRESS (Manual);",
        "CASH_HKD": "CASH_HKD - Cash(HKD);",
        "octopus": "octopus - Octopus (Manual);",
        "alipay_hkd_manual": "alipay_hkd_manual - Alipay HKD (Manual);",
        "wechat_pay_hkd_manual": "wechat_pay_hkd_manual - WeChat Pay HKD (Manual);",
      },
      "breakdown": "Sales Breakdown",
      "summary": "Sales Summary",
      "others": "Other Summary",
      "void": "Void Summary"
    }
  },
  "SKUSalesKSTDetailsHistPage": {
    "SKUSalesDetails": {
      "name": "SKU Sales Details",
      "tableHeader": {
        "skuCode": "SKU Code",
        "upcCode": "UPC Code",
        "productCode": "Product Code",
        "productStatus": "Product Status",
        "skuCost": "SKU Cost",
        "propertyAc": "Supplier Code",
        "commerceChannelCodes": "Commerce Channel Code",
        "skuMeasurementUnit": "SKU Measurement Unit",
        "skuInternalUseTitle": "SKU Internal Use Title",
        "productTitleEnHk": "Product Title (English)",
        "productTitleZhHk": "Product Title (Chinese)",
        "rsmrCode": "RSMR Code",
        "rsmCode": "RSM Code",
        "lineTotal": "Line Total",
        "quantity": "Quantity",
        "subcatCategoryCode": "Sub-Category Code",
        "skuMeasure": "Measure",
        "skuMeasurementCode": "Measurement Code"
      }
    },
    "filter": {
      "store": {
        "all": "ALL",
        "name": "Store Code"
      },
      "orderCompletedAt": {
        "startDate": "Order Completed At From Date",
        "to": "to",
        "endDate": "Order Completed At End Date"
      },
    }
  },
  "discountBreakDownKSTHistPage": {
    "name": "Discount Breakdown",
    "tableHeader": {
      "shipFromStoreCode": "Store Code",
      "discountCode": "Discount Code",
      "discountNameEnHk": "Discount Name (English)",
      "discountNameZhHk": "Discount Name (Chinese)",
      "rsmrCode": "RSMR Code",
      "subcatCategoryCode": "Sub-Category Code",
      "lineTotal": "Line Total",
      "quantity": "Quantity"
    },
    "filter": {
      "store": {
        "all": "ALL",
        "name": "Store Code"
      },
      "orderCompletedAt": {
        "startDate": "Order Completed At From Date",
        "to": "to",
        "endDate": "Order Completed At End Date"
      }
    }
  },
}

export default commonEnHK