import React, { useState } from "react";
import Header from '../../components/Header';
import TableComponent from '../../components/TableComponent';
import SelectComponent from '../../components/SelectComponent';
import Dashboard from '../../components/Dashboard';
import PageHeaderComponent from '../../components/PageHeaderComponent';
import OmnitechQueries from '../../queries/OmnitechQueries'
import { useTranslation } from 'react-i18next';
import { useCubeQuery } from '@cubejs-client/react';
import { format } from 'date-fns'

const applyFilter = (query, showNullFilter, showSkuErrorFilter) => {
  if(showNullFilter === 'yes'){
    let showNullFilterObj = {
      member: "FNBSKUUpload.propertyAc",
      operator: "notSet"
    }
    query = {
      ...query,
      filters: (query.filters || []).concat(showNullFilterObj)
    }
  }
  else if(showNullFilter === 'no'){
    let showNullFilterObj = {
      member: "FNBSKUUpload.propertyAc",
      operator: "set"
    }
    query = {
      ...query,
      filters: (query.filters || []).concat(showNullFilterObj)
    }
  }

  if(showSkuErrorFilter === 'yes'){
    let showSkuErrorFilterObj = {
      member: "FNBSKUUpload.skuError",
      "operator": "equals",
      "values": [
        "TRUE"
      ]
    }
    query = {
      ...query,
      filters: (query.filters || []).concat(showSkuErrorFilterObj)
    }
  }
  else if(showSkuErrorFilter === 'no'){
    let showSkuErrorFilterObj = {
      member: "FNBSKUUpload.skuError",
      "operator": "equals",
      "values": [
        "FALSE"
      ]
    }
    query = {
      ...query,
      filters: (query.filters || []).concat(showSkuErrorFilterObj)
    }
  }
  return query
}

const SKUFNBCheckingQuery = (isRefresh, setRefresh, dateFilter, showNullFilter, showSkuErrorFilter) => {
  const { t } = useTranslation();

  let item = {
    name: t('page.fnb.skuCheckingPage.name'),
    tableHeaders: {
      "FNBSKUUpload.skuCode": { name: "sku[code]", type: 'string'},
      "FNBSKUUpload.upcCode": { name: "sku[upc_code]", type: 'string'},
      "FNBSKUUpload.productCode": { name: "product[code]", type: 'string'},
      "FNBSKUUpload.productType": { name: "product[product_type]", type: 'string'},
      "FNBSKUUpload.skuInternalUseTitle": { name: "sku[internal_use_title]", type: 'string'},
      "FNBSKUUpload.productTitleEn": { name: "product[title]:en-HK", type: 'string'},
      "FNBSKUUpload.productTitleZh": { name: "product[title]:zh-HK", type: 'string'},
      "FNBSKUUpload.productStatus": { name: "product[status]", type: 'string'},
      "FNBSKUUpload.skuPriceId": { name: "sku_price[id]", type: 'string'},
      "FNBSKUUpload.lastSkuPriceId": { name: "sku_price[id]", type: 'string'},
      "FNBSKUUpload.skuPrice": { name: "sku_price[price]", type: 'number'},
      "FNBSKUUpload.priceActiveAt": { name: "sku_price[actived_at]", type: 'dateTime'},
      "FNBSKUUpload.skuCost": { name: "skus[meta][cost]", type: 'number'},
      "FNBSKUUpload.hasInventoryControl": { name: "product[has_inventory_control]", type: 'boolean'},
      "FNBSKUUpload.useMeasurement": { name: "sku[use_measurement]", type: 'boolean'},
      "FNBSKUUpload.skuMeasurementId": { name: "sku[sku_measurement_id]", type: 'number'},
      "FNBSKUUpload.hasMeasurementInventoryControl": { name: "sku[has_measurement_inventory_control]", type: 'boolean'},
      "FNBSKUUpload.measurementUnit": { name: "sku[measurement_unit]", type: 'string'},
      "FNBSKUUpload.skuError": { name: "sku_error", type: 'boolean'},
      "FNBSKUUpload.propertyAc": { name: "skus[meta][property_ac]", type: 'string'},
      "FNBSKUUpload.supplierProductCode": { name: "skus[meta][supplier_product_code]", type: 'string'},
      "FNBSKUUpload.skuPacking": { name: "skus[meta][packing]", type: 'string'},
      "FNBSKUUpload.memberPrice": { name: "skus[meta][member_price](json)", type: 'string'},
      "FNBSKUUpload.menuFilter": { name: "skus[meta][menu_filter](json)", type: 'array'},
      "FNBSKUUpload.menuNotFilter": { name: "skus[meta][menu_not_filter](json)", type: 'array'},
      "FNBSKUUpload.membershipTierLogic": { name: "skus[meta][membership_tier_logic](json)", type: 'string'},
      "FNBSKUUpload.fnbPrintingGroup": { name: "skus[meta][fnb_printing_group](json)", type: 'string'},
      "FNBSKUUpload.fnbPrintTo": { name: "skus[meta][fnb_print_to](json)", type: 'array'},
      "FNBSKUUpload.fnbItemType": { name: "skus[meta][fnb_item_type](json)", type: 'string'},
      "FNBSKUUpload.fnbDineInRemark": { name: "skus[meta][fnb_dine_in_remark](json)", type: 'string'},
      "FNBSKUUpload.fnbAdditionalPrintCopies": { name: "skus[meta][fnb_additional_print_copies](json)", type: 'string'},
      "FNBSKUUpload.fnbSkipModifier": { name: "skus[meta][fnb_skip_modifier](json)", type: 'string'},
      "FNBSKUUpload.fnbTakeAwayRemark": { name: "skus[meta][fnb_take_away_remark](json)", type: 'string'},
      "FNBSKUUpload.posRestaurantMenuButton": { name: "skus[meta][pos_restaurant_menu_button](json)", type: 'string'},
      "FNBSKUUpload.pointsoftCode": { name: "skus[meta][pointsoft_code](json)", type: 'string'},
      "FNBSKUUpload.siblingsTitle": { name: "products[meta][siblings_title](json)", type: 'string'},
      "FNBSKUUpload.siblingsProductCodes": { name: "products[meta][siblings_product_codes](json)", type: 'string'},
      "FNBSKUUpload.skuCommerceChannelCodes": { name: "skus[commerce_channel_codes]", type: 'string'},
      "FNBSKUUpload.sizeOptionCode": { name: "size_option[code]", type: 'string'},
      "FNBSKUUpload.sizeOptionMasterCode": { name: "size_option[size_master_code]", type: 'string'},
      "FNBSKUUpload.sizeOptionNameEn": { name: "size_option[name]:en-HK", type: 'string'},
      "FNBSKUUpload.sizeOptionNameZh": { name: "size_option[name]:zh-HK", type: 'string'},
      "FNBSKUUpload.sizeOptionState": { name: "size_option[state]", type: 'string'},
      "FNBSKUUpload.colorOptionHex": { name: "color_option[hex_value]", type: 'string'},
      "FNBSKUUpload.colorOptionCode": { name: "color_option[color_code]", type: 'string'},
      "FNBSKUUpload.colorOptionMasterCode": { name: "color_option[color_master_code]", type: 'string'},
      "FNBSKUUpload.colorOptionNameEn": { name: "color_option[name]:en-HK", type: 'string'},
      "FNBSKUUpload.colorOptionNameZh": { name: "color_option[name]:zh-HK", type: 'string'},
      "FNBSKUUpload.colorOptionState": { name: "color_option[state]", type: 'string'},
      "FNBSKUUpload.categoryCodes": { name: "products[category_codes]", type: 'string'},
      "FNBSKUUpload.subcatCategoryCode": { name: "products[subcat_category_codes]", type: 'string'},
      "FNBSKUUpload.rsmrCode": { name: "products[rsmr_code]", type: 'string'},
      "FNBSKUUpload.rsmCode": { name: "products[rsm_code]", type: 'string'},
    },
    grid: {xs: 12, lg: 12},
    export: { allowExport: true, filename: 'sku_checking_export_' + format(new Date(), 'yyyyMMddHHmmss') }
  }
  let query = {
    ...OmnitechQueries.skus.SKUFNBUploadTemplate.query,
    "filters": (OmnitechQueries.skus.SKUFNBUploadTemplate.query.filters || []).concat(
     {
       "member": "FNBSKUUpload.priceActiveAt",
       "operator": "lte",
       "values": [dateFilter]
     }
    ),
  }
  query = applyFilter(query, showNullFilter, showSkuErrorFilter)
  if(isRefresh)
    query = {}
  const result = useCubeQuery(query, {resetResultSetOnChange: true})
  if(isRefresh)
    setRefresh(false)
  return { item, result }
}

const SKUFNBCheckingPage = () => {
  const { t } = useTranslation();
  let filterStartDate = new Date()
  const [dateFilter, ] = useState(filterStartDate)

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  const [ refresh1, setRefresh1 ] = useState(false);

  const [showNullFilter, setShowNullFilter] = useState('ALL');
  const showNullSelect = ['ALL','yes','no']

  const [showSkuErrorFilter, setShowSkuErrorFilter] = useState('ALL');
  const showSkuErrorSelect = ['ALL','yes','no']

  const { item, result }  = SKUFNBCheckingQuery(refresh1, setRefresh1, dateFilter, showNullFilter, showSkuErrorFilter)

  const refresh = () => {
    setRefresh1(true)
    setPage(0)
  }

  return ([
    <Header />,
    <PageHeaderComponent onClickFunc={refresh} refreshlabel={t('component.refresh')} headerTitle={t('component.header.menu.skuChecking')} />,
    <Dashboard>
      <SelectComponent id={"show_null_filter"} state={showNullFilter} pageChangerArr={[setPage]}
          stateChanger={setShowNullFilter} textLabel={t('page.fnb.skuCheckingPage.filter.showNull')} selectArray={showNullSelect}/>
      <SelectComponent id={"show_sku_error_filter"} state={showSkuErrorFilter} pageChangerArr={[setPage]}
          stateChanger={setShowSkuErrorFilter} textLabel={t('page.fnb.skuCheckingPage.filter.showSkuError')} selectArray={showSkuErrorSelect}/>
    </Dashboard>,
    <Dashboard>
      <TableComponent item={item} result={result} rowsPerPage={rowsPerPage} 
        setRowsPerPage={setRowsPerPage} page={page} setPage={setPage}/>
    </Dashboard>
  ]) 
};

export default SKUFNBCheckingPage;