import React, { useState, useContext, useMemo } from "react";
import { withRouter } from 'react-router';
import { useHistory, useLocation } from "react-router-dom";
import { isEmpty, omitBy, toLower, trim } from 'lodash';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import { Link } from 'react-router-dom'; 
import Box from '@material-ui/core/Box';
import Drawer from '@material-ui/core/Drawer';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListSubheader from '@material-ui/core/ListSubheader';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import TranslateIcon from '@material-ui/icons/Translate';
import { ListItemText, Divider } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { CubeJSContext } from '../context/CubeJSProvider';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';
import { makeStyles } from '@material-ui/styles';
import { CubeContext } from '@cubejs-client/react';
import CompositionTextField from './CompositionTextField';

const useStyles = makeStyles(() => ({
  drawerRoot: {
    display: 'flex',
    gap: '1rem',
    justifyContent: 'space-between',
    padding: '4px 16px',
  },
  translateIcon: {
    paddingRight: '10px'
  },
}));

const NavMenuListItem = ({
  name,
  path,
  onClick
}) => {
  const location = useLocation();
  const isSelected = useMemo(() => {
    return path === location.pathname
  }, [path, location.pathname])
  return (
    <ListItem 
      button
      component={Link}
      selected={isSelected}
      sx={{ pl: 4 }}
      to={path}
      onClick={onClick}
    >
      <ListItemText primary={name} />
    </ListItem>
  );
}

const Header = () => {
  const { t, i18n } = useTranslation();
  const styles = useStyles();
  const history = useHistory();
  const context = useContext(CubeContext);
  const context2 = useContext(CubeJSContext);
  const [logoutBox, setLogoutBox] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [menuSearchText, setMenuSearchText] = useState('');

  /**
   * Define available menu items
   * Using useMemo to wrap the initial of the variable, ensure its reference will 
   * not be changed.
   * It is required for the dependencies change checking when building the menu tree
   */
  const fnbMenuReal = useMemo(() => [
    { acl: 'fnb.cart_audit_trail', key: 'cart_audit_trail', name: t('component.header.menu.cartAuditTrailReport'), path: '/cart_audit_trail' },
    { acl: 'fnb.cart_discount_checking', key: 'cart_discount_checking', name: t('component.header.menu.cartDiscountChecking'), path: '/cart_discount_checking' },
    { acl: 'fnb.daily_operation', key: 'daily_operation', name: t('component.header.menu.dailyOperation'), path: '/daily_operation' },
    { acl: 'fnb.daily_operation_print', key: 'daily_operation_print', name: t('component.header.menu.dailyOperationPrint'), path: '/daily_operation_print' },
    { acl: 'fnb.daily_sales', key: 'daily_sales', name: t('component.header.menu.dailySales'), path: '/daily_sales' },
    { acl: 'fnb.discount_breakdown_by_staff', key: 'discount_breakdown_by_staff', name: t('component.header.menu.discountBreakdownByStaffReport'), path: '/discount_breakdown_by_staff' },
    { acl: 'fnb.mpos_vs_self_order', key: 'mpos_vs_self_order', name: t('component.header.menu.mPosSelfOrder'), path: '/mpos_vs_self_order'},
    { acl: 'fnb.order_user_export', key: 'order_user_export', name: t('component.header.menu.orderUserExport'), path: '/order_user_export' },
    { acl: 'fnb.order_entries_audit_trail', key: 'order_entries_audit_trail', name: t('component.header.menu.orderEntriesAuditTrailReport'), path: '/order_entries_audit_trail' },
    { acl: 'fnb.order_settlement', key: 'order_settlement', name: t('component.header.menu.orderSettlementReport'), path: '/order_settlement' },
    { acl: 'fnb.outstanding_table', key: 'outstanding_table', name: t('component.header.menu.outstandingTable'), path: '/outstanding_table' },
    { acl: 'fnb.payment_by_store_type_breakdown', key: 'payment_by_store_type_breakdown', name: t('component.header.menu.paymentByStoreTypeBreakdown'), path: '/payment_by_store_type_breakdown' },
    { acl: 'fnb.rsm_checking', key: 'rsm_checking', name: t('component.header.menu.rsmChecking'), path: '/rsm_checking'},
    { acl: 'fnb.rsmr_checking', key: 'rsmr_checking', name: t('component.header.menu.rsmrChecking'), path: '/rsmr_checking'},
    { acl: 'fnb.sku_checking', key: 'fnb_sku_checking', name: t('component.header.menu.skuChecking'), path: '/fnb_sku_checking'},
    { acl: 'fnb.sku_sales_details', key: 'sku_sales_details', name: t('component.header.menu.skuSalesDetails'), path: '/sku_sales_details' },
    { acl: 'fnb.staff_attendance', key: 'staff_attendance', name: t('component.header.menu.staffAttendance'), path: '/staff_attendance'},
    { acl: 'fnb.staff_statistics', key: 'staff_statistics', name: t('component.header.menu.staffStatistics'), path: '/staff_statistics' },
    { acl: 'fnb.staff_tender_summary', key: 'staff_tender_summary', name: t('component.header.menu.staffTenderSummary'), path: '/staff_tender_summary' },
    { acl: 'fnb.time_period_summary', key: 'time_period_summary', name: t('component.header.menu.timePeriodSummary'), path: '/time_period_summary' },
    { acl: 'fnb.user_export', key: 'user_export', name: t('component.header.menu.userExport'), path: '/user_export' },
    { acl: 'fnb.user_rank', key: 'user_rank', name: t('component.header.menu.membershipCount'), path: '/user_rank' },
    { acl: 'fnb.void_item_detail', key: 'void_item_detail', name: t('component.header.menu.voidItemDetailReport'), path: '/void_item_detail'},
    
  // eslint-disable-next-line react-hooks/exhaustive-deps
  ], [i18n.language]);

  const fnbMenuHist = useMemo(() => [
    { acl: 'fnb.daily_operation_hist', key: 'daily_operation_hist', name: t('component.header.menu.dailyOperationHist'), path: '/daily_operation_hist' },
    { acl: 'fnb.daily_operation_hist_print', key: 'daily_operation_hist_print', name: t('component.header.menu.dailyOperationHistPrint'), path: '/daily_operation_hist_print' },
    { acl: 'fnb.discount_breakdown_hist', key: 'discount_breakdown_hist', name: t('component.header.menu.discountBreakdownReport'), path: '/discount_breakdown_hist' },
    { acl: 'fnb.discount_breakdown_by_commerce_channel', key: 'discount_breakdown_by_commerce_channel', name: t('component.header.menu.discountBreakdownByCommerceChannelReport'), path: '/discount_breakdown_by_commerce_channel' },
    { acl: 'fnb.discount_breakdown_by_item', key: 'discount_breakdown_by_item', name: t('component.header.menu.discountBreakdownByItemReport'), path: '/discount_breakdown_by_item' },
    { acl: 'fnb.discount_breakdown_by_member', key: 'discount_breakdown_by_member', name: t('component.header.menu.discountBreakdownByMemberReport'), path: '/discount_breakdown_by_member' },
    { acl: 'fnb.discount_breakdown_by_order', key: 'discount_breakdown_by_order', name: t('component.header.menu.discountBreakdownByOrderReport'), path: '/discount_breakdown_by_order' },
    { acl: 'fnb.discount_breakdown_by_staff_hist', key: 'discount_breakdown_by_staff_hist', name: t('component.header.menu.discountBreakdownByStaffHistReport'), path: '/discount_breakdown_by_staff_hist' },
    { acl: 'fnb.payment_day_end', key: 'payment_day_end', name: t('component.header.menu.paymentDayEnd'), path: '/payment_day_end'},
    { acl: 'fnb.per_day_report', key: 'per_day_report', name: t('component.header.menu.perDayReport'), path: '/per_day_report' },
    { acl: 'fnb.store_staff_sales_performance_summary', key: 'store_staff_sales_performance_summary', name: t('component.header.menu.storeStaffSalesPerformanceSummary'), path: '/store_staff_sales_performance_summary' },
    { acl: 'fnb.sku_sales_details_hist', key: 'sku_sales_details_hist', name: t('component.header.menu.skuSalesDetailsHist'), path: '/sku_sales_details_hist' },
    { acl: 'fnb.time_period_summary_hist', key: 'time_period_summary_hist', name: t('component.header.menu.timePeriodSummaryHist'), path: '/time_period_summary_hist' },
    { acl: 'fnb.total_food_gross_sales', key: 'total_food_gross_sales', name: t('component.header.menu.totalFoodGrossSalesReport'), path: '/total_food_gross_sales'},
    { acl: 'fnb.user_search', key: 'user_search', name: t('component.header.menu.userQuery'), path: '/user_search' },
    { acl: 'fnb.void_item_detail_hist', key: 'void_item_detail_hist', name: t('component.header.menu.voidItemDetailHistReport'), path: '/void_item_detail_hist'},
    // eslint-disable-next-line react-hooks/exhaustive-deps
  ], [i18n.language]);

  const rtMenu = useMemo(() => [
    { acl: 'rt.rt_beverages_sales', key: 'rt_beverages_sales', name: t('component.header.menu.rtBeveragesSales'), path: '/rt_beverages_sales' },
    { acl: 'rt.rt_discount_reloations_checking', key: 'rt_discount_reloations_checking', name: t('component.header.menu.rtDiscountRelationsChecking'), path: '/rt_discount_reloations_checking' },
    { acl: 'rt.rt_order_user_export', key: 'rt_order_user_export', name: t('component.header.menu.rtOrderUserExport'), path: '/rt_order_user_export' },
    { acl: 'rt.rt_takeaway_delivery_order_approval_time_trace', key: 'rt_takeaway_delivery_order_approval_time_trace', name: t('component.header.menu.rtTakeawayDeliveryOrderApprovalTimeTraceReport'), path: '/rt_takeaway_delivery_order_approval_time_trace' },
    { acl: 'rt.rt_user_device_register_report', key: 'rt_user_device_register_report', name: t('component.header.menu.rtUserDeviceRegisterReport'), path: '/rt_user_device_register_report' },
    { acl: 'rt.rt_user_export', key: 'rt_user_export', name: t('component.header.menu.rtUserExport'), path: '/rt_user_export' },
    { acl: 'rt.rt_user_search', key: 'rt_user_search', name: t('component.header.menu.rtUserQuery'), path: '/rt_user_search' },
    // eslint-disable-next-line react-hooks/exhaustive-deps
  ], [i18n.language]);

  const cheMenu = useMemo(() => [
    { acl: 'che.daily_opeation', key: 'che_daily_opeation', name: t('component.header.menu.cheDailyOperation'), path: '/che_daily_opeation' },
    { acl: 'che.daily_opeation_fnb', key: 'che_daily_opeation_fnb', name: t('component.header.menu.cheDailyOperationFnb'), path: '/che_daily_opeation_fnb' },
    { acl: 'che.che_discount_breakdown_hist', key: 'che_discount_breakdown_hist', name: t('component.header.menu.discountBreakdownCHEReport'), path: '/che_discount_breakdown_hist' },
    { acl: 'che.che_sku_sales_details_hist', key: 'che_sku_sales_details_hist', name: t('component.header.menu.skuSalesDetailsCHEHist'), path: '/che_sku_sales_details_hist' },
    // eslint-disable-next-line react-hooks/exhaustive-deps
  ], [i18n.language]);

  const kstMenu = useMemo(() => [
    { acl: 'kst.daily_opeation', key: 'kst_daily_opeation', name: t('component.header.menu.kstDailyOperation'), path: '/kst_daily_opeation' },
    { acl: 'kst.daily_opeation_fnb', key: 'kst_daily_opeation_fnb', name: t('component.header.menu.kstDailyOperationFnb'), path: '/kst_daily_opeation_fnb' },
    { acl: 'kst.kst_discount_breakdown_hist', key: 'kst_discount_breakdown_hist', name: t('component.header.menu.discountBreakdownKSTReport'), path: '/kst_discount_breakdown_hist' },
    { acl: 'kst.kst_sku_sales_details_hist', key: 'kst_sku_sales_details_hist', name: t('component.header.menu.skuSalesDetailsKSTHist'), path: '/kst_sku_sales_details_hist' },
    // eslint-disable-next-line react-hooks/exhaustive-deps
  ], [i18n.language]);

  const fsMenu = useMemo(() => [
    { acl: 'fs.daily_opeation', key: 'fs_daily_opeation', name: t('component.header.menu.fsDailyOperation'), path: '/fs_daily_opeation' },
    // eslint-disable-next-line react-hooks/exhaustive-deps
  ], [i18n.language]);

  const stocktakeMenu = useMemo(() => [
    { acl: 'stocktake.stocktake_report', key: 'stocktake_report', name: t('component.header.menu.stocktakeReport'), path: '/stocktake_report' },
    { acl: 'stocktake.inventory_adjustment_report', key: 'inventory_adjustment_report', name: t('component.header.menu.inventoryAdjustmentReport'), path: '/inventory_adjustment_report' },
    // eslint-disable-next-line react-hooks/exhaustive-deps
  ], [i18n.language]);

  const supplierMenu = useMemo(() => [
    { acl: 'supplier.current_inventory_balance', key: 'current_inventory_balance', name: t('component.header.menu.currentInventoryBalance'), path: '/current_inventory_balance' },
    { acl: 'supplier.supplier_summary', key: 'supplier_summary', name: t('component.header.menu.supplierSummary'), path: '/supplier_summary' },
    // eslint-disable-next-line react-hooks/exhaustive-deps
  ], [i18n.language]);

  const membershipMenu = useMemo(() => [
    { acl: 'membership.active_member', key: 'active_member', name: t('component.header.menu.activeMember'), path: '/active_member' },
    { acl: 'membership.coupon_usage', key: 'coupon_usage', name: t('component.header.menu.couponUsage'), path: '/coupon_usage' },
    { acl: 'membership.coupon_usage_by_date_range', key: 'coupon_usage_by_date_range', name: t('component.header.menu.couponUsageByDateRange'), path: '/coupon_usage_by_date_range' },
    { acl: 'membership.new_member', key: 'new_member', name: t('component.header.menu.newMember'), path: '/new_member' },
    { acl: 'membership.membership_purchase_exporter', key: 'membership_purchase_exporter', name: t('component.header.menu.membershipPurchaseExporter'), path: '/membership_purchase_exporter' },
    { acl: 'membership.member_spending', key: 'member_spending', name: t('component.header.menu.memberSpending'), path: '/member_spending' },
    { acl: 'membership.member_store', key: 'member_store', name: t('component.header.menu.memberStore'), path: '/member_store' },
    { acl: 'membership.pm_campaign_rate', key: 'pm_campaign_rate', name: t('component.header.menu.PMCampaignRate'), path: '/pm_campaign_rate' },
    { acl: 'membership.repeated_members', key: 'repeated_members', name: t('component.header.menu.repeatedMembers'), path: '/repeated_members' },
    // eslint-disable-next-line react-hooks/exhaustive-deps
  ], [i18n.language]);

  const handleLogoutOpen = () => {
    setLogoutBox(true);
  };

  const handleLogoutClose = () => {
    setLogoutBox(false);
  };

  const handleLogout = () => {
    context2.storeCodes = []
    context2.cubeJSToken = ""
    context2.cubeJSUrl = ""
    context2.login = false
    context2.acl = {}
    context2.companyName = ""
    context2.expiredAt = null
    context2.expired = false
    context.cubejsApi = null
    localStorage.removeItem("storeCodes")
    localStorage.removeItem("cubeJSToken")
    localStorage.removeItem("cubeJSUrl")
    localStorage.removeItem("login")
    localStorage.removeItem("acl")
    localStorage.removeItem("companyName")
    localStorage.removeItem('propertyAc')
    localStorage.removeItem("rememberMe")
    localStorage.removeItem("expiredAt")
    handleLogoutClose()
    history.push("/sign_in")
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleToggleDraw = (open) => {
    setOpenDrawer(open)
    // reset the search text when draw is closed
    if (!open) setMenuSearchText('')
  }

  const handleSearchTextChange = (value) => {
    setMenuSearchText(value);
  }

  const changeLanguage = (lng) => {
    localStorage.setItem('i18nextLng',lng)
    i18n.changeLanguage(lng);
  };

  const isAllowAccess = (pageACL) => {
    let pageACLArr = pageACL.split(".")
    let tmpHash = context2.acl
    for(let i = 0; i < pageACLArr.length; i++){
      if(tmpHash['*'])
        return true
      else if(tmpHash[pageACLArr[i]] && i === (pageACLArr.length - 1))
        return true
      else if(tmpHash[pageACLArr[i]])
        tmpHash = tmpHash[pageACLArr[i]]
      else
        return false
    }
    return false
  }

  const matchMenuName = (name, keyword) => {
    return toLower(name).indexOf(toLower(keyword)) !== -1;
  }

  // build the menu tree
  const menuTree = useMemo(() => {
    let result = {};
    const keyword = trim(menuSearchText);
    const isMenuSearh = !isEmpty(keyword);

    const filterMenuItem = (item) => {
      return isMenuSearh
      ? isAllowAccess(item.acl) && matchMenuName(item.name, keyword)
      : isAllowAccess(item.acl)
    }
 
    if (isAllowAccess('fnb')) {
      result.fnbReal = fnbMenuReal.filter(filterMenuItem);      
      result.fnbHist = fnbMenuHist.filter(filterMenuItem);
    }

    if (isAllowAccess('rt')) {
      result.rt = rtMenu.filter(filterMenuItem);
    }

    if (isAllowAccess('che')) {
      result.che = cheMenu.filter(filterMenuItem);
    }

    if (isAllowAccess('kst')) {
      result.kst = kstMenu.filter(filterMenuItem);
    }

    if (isAllowAccess('fs')) {
      result.fs = fsMenu.filter(filterMenuItem);
    }

    if (isAllowAccess('stocktake')) {
      result.stocktake = stocktakeMenu.filter(filterMenuItem);
    }

    if (isAllowAccess('supplier')) {
      result.supplier = supplierMenu.filter(filterMenuItem);
    }

    if (isAllowAccess('membership')) {
      result.membership = membershipMenu.filter(filterMenuItem);
    }

    return omitBy(result, isEmpty);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fnbMenuReal, fnbMenuHist, rtMenu, cheMenu, kstMenu, fsMenu, stocktakeMenu, supplierMenu, membershipMenu, menuSearchText, i18n.language]);

  return (
  <AppBar position="static">
    <Toolbar variant="dense">
      { context2.login && <IconButton edge="start" color="inherit" aria-label="menu" onClick={handleToggleDraw.bind(null, !openDrawer)}>
        <MenuIcon/>
      </IconButton>}
      {/* showing different title between desktop and mobile device */}
      { context2.companyName !== undefined && context2.companyName !== "" && context2.companyName !== "undefined" && (
        <Typography variant="h6" color="inherit" sx={{ display: { xs: 'none', sm: 'block'}}}>
          {context2.companyName + " - " + t('component.header.title')}
        </Typography>
      ) }
      { context2.companyName !== undefined && context2.companyName !== "" && context2.companyName !== "undefined" && (
        <Typography variant="h6" color="inherit" sx={{ display: { xs: 'block', sm: 'none'}}}>
          {context2.companyName + " - " + t('component.header.titleMobile')}
        </Typography>
      ) }
      { (context2.companyName === undefined || context2.companyName === "" || context2.companyName === "undefined") && (
        <Typography variant="h6" color="inherit" sx={{ display: { xs: 'none', sm: 'block'}}}>
          {t('component.header.title')}
        </Typography>
       ) }
      { (context2.companyName === undefined || context2.companyName === "" || context2.companyName === "undefined") && (
        <Typography variant="h6" color="inherit" sx={{ display: { xs: 'block', sm: 'none'}}}>
          {t('component.header.titleMobile')}
        </Typography>
       ) }
      <Box sx={{ flexGrow: 1 }} />
      <Button variant="h6" color="inherit" aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
        <TranslateIcon className={styles.translateIcon} /> {t('component.header.lanuage')} <ArrowDropDownIcon/>
      </Button>
      { context2.login && <IconButton onClick={handleLogoutOpen} edge="start" color="inherit" aria-label="logout">
        <ExitToAppIcon />
      </IconButton> }
      <Dialog
        open={logoutBox}
        onClose={handleLogoutClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{t('component.header.logout')}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {t('component.header.logoutDetail')}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleLogoutClose} color="primary" autoFocus>
            {t('component.header.no')}
          </Button>
          <Button onClick={handleLogout} color="primary">
            {t('component.header.yes')}
          </Button>
        </DialogActions>
      </Dialog>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={()=> {handleClose(); changeLanguage('en-HK');}}>English (Hong Kong)</MenuItem>
        <MenuItem onClick={()=> {handleClose(); changeLanguage('zh-HK');}}>中文 (香港)</MenuItem>
      </Menu>
    </Toolbar>
    <Drawer containerStyle={{height: 'calc(100% - 64px)', top: 64}} width={200} open={openDrawer} onClose={handleToggleDraw.bind(null, false)} zdepth={2}>
      <div className={styles.drawerRoot}>
        <CompositionTextField
          fullWidth
          autoComplete="nav-menu-search"
          id="nav-menu-search-text"
          margin="none"
          placeholder={t('component.header.searchInMenu')}
          size="small"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
            endAdornment: (
              menuSearchText === ''
              ? null
              : (
                <InputAdornment position="end">
                  <IconButton size="small" onClick={() => { setMenuSearchText('') }}>
                    <ClearIcon />
                  </IconButton>
                </InputAdornment>
              )
            )            
          }}
          onChange={handleSearchTextChange}
        />
        <IconButton onClick={handleToggleDraw.bind(null, false)}>
          <ChevronLeftIcon />
        </IconButton>
      </div>
      {
        isEmpty(menuTree) && !isEmpty(menuSearchText) && (
          <List>
            <ListSubheader>{t('component.header.searchNoResult')}</ListSubheader>
          </List>
        )
      }
      {
        // Loop out the menu tree
        Object
          .keys(menuTree)
          .map((menuKey) => {
            if (isEmpty(menuTree[menuKey])) return null
            
            return (
              <React.Fragment key={`menu-group-${menuKey}`}>
                <List>
                  <ListSubheader>{t(`component.header.${menuKey}`)}</ListSubheader>
                  {
                    menuTree[menuKey].map((item) => (
                      <NavMenuListItem
                        key={item.key}
                        name={item.name}
                        path={item.path}
                        onClick={handleToggleDraw.bind(null, !openDrawer)}
                      />
                    ))
                  }      
                </List>
                <Divider />
              </React.Fragment>
            )
          })
      }
    </Drawer>
  </AppBar>
  )
};

export default withRouter(Header);
