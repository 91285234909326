const commonZhHK = {
  "discountBreakDownHistPage": {
    "name": "折扣明細報告",
    "tableHeader": {
      "shipFromStoreCode": "分店編號",
      "discountCode": "折扣編號",
      "discountNameEnHk": "折扣名稱 (英文)",
      "discountNameZhHk": "折扣名稱 (中文)",
      "lineTotal": "行總計",
      "quantity": "數量",
      "totalPrice": "帶來收入"
    },
    "filter": {
      "store": {
        "all": "全部",
        "name": "分店編號",
      },
      "orderCompletedAt": {
        "startDate": "訂單完成開始日期",
        "to": "至",
        "endDate": "訂單完成結束日期"
      }
    }
  },
  "discountBreakdownByOrderPage": {
    "name": "訂單折扣明細報告",
    "tableHeader": {
      "shipFromStoreCode": "分店編號",
      "discountCode": "折扣編號",
      "discountNameEnHk": "折扣名稱 (英文)",
      "discountNameZhHk": "折扣名稱 (中文)",
      "lineTotal": "行總計",
      "quantity": "數量",
      "membershipCode": "會員編號",
      "referenceNumber": "訂單參考編號",
      "firstName": "顧客姓名",
      "userPreferDistrict": "顧客首選地區",
      "phone": "客戶電話",
      "code": "會員等級",
      "rankExpireAt": "會員等級過期日期",
      "ordersCompletedAt": "訂單完成時間",
      "sumNetSales": "淨銷售額"
    },
    "filter": {
      "store": {
        "all": "全部",
        "name": "分店編號",
      },
      "orderCompletedAt": {
        "startDate": "訂單完成開始日期",
        "to": "至",
        "endDate": "訂單完成結束日期"
      }
    }
  },
  "discountBreakdownByMemberPage": {
    "name": "會員折扣明細報告",
    "tableHeader": {
      "shipFromStoreCode": "分店編號",
      "discountCode": "折扣編號",
      "discountNameEnHk": "折扣名稱 (英文)",
      "discountNameZhHk": "折扣名稱 (中文)",
      "lineTotal": "行總計",
      "quantity": "數量",
      "membershipCode": "會員編號",
      "referenceNumber": "訂單參考編號",
      "firstName": "顧客姓名",
      "userPreferDistrict": "顧客首選地區",
      "phone": "客戶電話",
      "code": "會員等級",
      "rankExpireAt": "會員等級過期日期",
      "ordersCompletedAt": "訂單完成時間",
      "sumNetSales": "淨銷售額",
      "couponRedeemablePoint": "兌換分數",
      "couponRedeemablePrice": "兌換金額",
      "couponTokensCreatedAt": "兌換日期"
    },
    "filter": {
      "store": {
        "all": "全部",
        "name": "分店編號",
      },
      "orderCompletedAt": {
        "startDate": "訂單完成開始日期",
        "to": "至",
        "endDate": "訂單完成結束日期"
      }
    }
  },
  "discountBreakdownByItemPage": {
    "name": "物品折扣明細報告",
    "tableHeader": {
      "discountCode": "折扣編號",
      "discountNameEnHk": "折扣名稱 (英文)",
      "discountNameZhHk": "折扣名稱 (中文)",
      "lineTotal": "行總計",
      "quantity": "數量",
      "skuCode": "SKU編號",
      "skuInternalUseTitle": "SKU內部名稱",
    },
    "filter": {
      "store": {
        "all": "全部",
        "name": "分店編號",
      },
      "orderCompletedAt": {
        "startDate": "訂單完成開始日期",
        "to": "至",
        "endDate": "訂單完成結束日期"
      },
      "discountCode": "折扣編號"
    }
  },
  "dailyOperation": {
    "filter": {
      "store": {
        "all": "全部",
        "name": "分店編號",
      },
      "orderCompletedAt": {
        "date": "訂單完成日期",
      }
    },
    "titleMapping": {
      "totalRevenue": "總收入",
      "totalOrder": "總訂單",
      "totalMembershipSales": "會員銷售總額",
      "totalNetSales": "總淨銷售額",
      "totalGrossSales": "總銷售額",
      "totalDiscounts": "總折扣",
      "totalVoidOrder": "總取消訂單",
      "totalVoidOrderAmount": "總取消訂單銷售額",
      "avgBillAmount": "平均訂單銷售額",
      "perPersonSpending": "人均消費",
      "totalTip": "總小費",
      "totalPax": "總人數",
      "totalOrderAmountRounding": "總訂單捨入金額",
      "totalShippingFees": "總運費",
      "totalServiceCharges": "總服務費",
      "totalVoidItem": "總取消物品",
      "outStandingNetsales": "未完成檯銷售額",
      "totalFoodGrossSales": "總食品銷售額",
      "totalBeverageGrossSales": "總飲料銷售額",
      "totalOtherGrossSales": "總其他銷售額",
    },
    "netSalesByStore":{
      "name": "店鋪淨銷售額",
      "tableHeader": {
        "count": "訂單總數",
        "orderTotal": "訂單合計",
        "shipFromStoreCode": "分店編號",
        "orderSource": "訂單來源"
      }
    },
    "netSalesByStoreEM":{
      "name": "店鋪淨銷售額 (不包括會員銷售)",
      "tableHeader": {
        "count": "訂單總數",
        "netSales": "訂單合計",
        "shipFromStoreCode": "分店編號",
        "orderSource": "訂單來源"
      }
    },
    "paymentByStoreType":{
      "name": "商店和支付類型明細付款",
      "tableHeader": {
        "sumAmount": "金額",
        "sumTip": "小費",
        "grandTotal": "累計",
        "countOrderId": "訂單總數",
        "shipmentsFromStoreCode": "分店編號",
        "paymentProviderCode": "支付提供商編號",
        "name": "支付提供商名稱",
        "currencyCode": "貨幣編號",
        "state": "狀態"
      }
    },
    "tenderByStoreStaff":{
      "name": "商店和支付類型明細付款(員工分類)",
      "tableHeader": {
        "sumAmount": "金額",
        "sumTip": "小費",
        "countOrderId": "訂單總數",
        "shipmentsFromStoreCode": "分店編號",
        "paymentProviderCode": "支付提供商編號",
        "name": "支付提供商名稱",
        "currencyCode": "貨幣編號",
        "state": "狀態",
        "staffNumber": "員工編號",
        "firstName": "員工名字",
        "lastName": "員工姓氏",
      }
    },
    "discountBreakdown":{
      "name": "折扣明細",
      "tableHeader": {
        "shipFromStoreCode": "分店編號",
        "discountCode": "折扣編號",
        "discountName": "折扣名稱",
        "lineTotal": "行總計",
        "quantity": "數量",
        "totalPrice": "帶來收入"
      },
    },
    "SKUSalesDetails":{
      "name": "SKU銷售詳情",
      "tableHeader": {
        "shipFromStoreCode": "分店編號",
        "skuCode": "SKU編號",
        "skuInternalUseTitle": "SKU內部使用標題",
        "skuCost": "SKU成本",
        "productTitle": "產品名稱",
        "code": "RSM編號",
        "lineTotal": "行總計",
        "quantity": "數量"
      },
    },
    "HighlightCategorySales":{
      "name": "突出品類銷售",
      "tableHeader": {
        "shipFromStoreCode": "分店編號",
        "skuCode": "SKU編號",
        "skuInternalUseTitle": "SKU內部使用標題",
        "productTitle": "產品名稱",
        "code": "RSM編號",
        "type": "類別編號",
        "lineTotal": "行總計",
        "quantity": "數量"
      },
    },
    "MembershipSalesByType":{
      "name": "會籍銷售摘要",
      "tableHeader": {
        "shipFromStoreCode": "分店編號",
        "skuCode": "SKU編號",
        "skuInternalUseTitle": "SKU內部使用標題",
        "lineTotal": "行總計",
        "quantity": "數量"
      },
    },
    "MembershipSalesDetails":{
      "name": "會籍銷售細明",
      "tableHeader": {
        "shipFromStoreCode": "分店編號",
        "skuCode": "SKU編號",
        "skuInternalUseTitle": "SKU內部使用標題",
        "staffNumber": "員工編號",
        "firstName": "員工名字",
        "lastName": "員工姓氏",
        "ordersCompletedAt": "訂單完成時間",
        "unitAmount": "單位金額",
        "quantity": "數量",
        "lineTotal": "行總計",
      },
    },
    "MembershipTenderSummaries":{
      "name": "會籍銷售(支付類型)",
      "tableHeader": {
        "shipFromStoreCode": "分店編號",
        "paymentProviderCode": "支付提供商編號",
        "lineTotal": "行總計",
        "quantity": "數量"
      },
    },
    "text":{
      "paymentCodeMapping":{
        "vsm": "vsm - VISA (Manual) - VISA (手動);",
        "msm": "msm - MASTER (Manual) - 萬事達 (手動);",
        "CV": "CV - Cash Voucher - 現金券;",
        "CU": "CU - UNION PAY (Manual) - 銀聯 (手動);",
        "aem": "aem - AMERICAN EXPRESS (Manual) - 美國運通 (手動);",
        "CASH_HKD": "CASH_HKD - Cash(HKD) - 現金(港元);",
        "octopus": "octopus - Octopus (Manual) - 八達通 (手動);",
        "alipay_hkd_manual": "alipay_hkd_manual - Alipay HKD (Manual) - 支付寶香港 (手動);",
        "wechat_pay_hkd_manual": "wechat_pay_hkd_manual - WeChat Pay HKD (Manual) - 微信支付香港 (手動);",
      },
      "tenderWarning": "注意：如果訂單有混合收款，則不准確"
    }
  },
  "dailyOperationPrint": {
    "filter": {
      "store": {
        "all": "全部",
        "name": "分店編號",
      },
      "orderCompletedAt": {
        "date": "訂單完成日期",
      }
    },
    "titleMapping": {
      "totalRevenue": "總收入",
      "totalOrder": "總訂單",
      "totalMembershipSales": "會員銷售總額",
      "totalNetSales": "總淨銷售額",
      "totalGrossSales": "總銷售額",
      "totalDiscounts": "總折扣",
      "totalVoidOrder": "總取消訂單",
      "totalVoidOrderAmount": "總取消訂單銷售額",
      "avgBillAmount": "平均訂單銷售額",
      "perPersonSpending": "人均消費",
      "totalTip": "總小費",
      "totalPax": "總人數",
      "totalShippingFees": "總運費",
      "totalServiceCharges": "總服務費",
      "totalVoidItem": "總取消物品",
      "totalOrderAmountRounding": "總訂單捨入金額",
      "outStandingNetsales": "未完成檯銷售額",
      "totalFoodGrossSales": "總食品銷售額",
      "totalBeverageGrossSales": "飲料總銷售額",
      "foodPercentage": "食品銷售額百分比",
      "beveragePercentage": "飲料銷售額百分比",
      "otherPercentage": "其他銷售額百分比",
      "totalGrossSalesChart": "總銷售額明細",
      "totalOtherGrossSales": "其他總銷售額",
    },
    "totalGrossSalesChart": {
      "labels": {
        "OrderDayEnd.foodPercentage": "食品",
        "OrderDayEnd.beveragePercentage": "飲料",
        "OrderDayEnd.otherPercentage": "其他",
      },
    },     
    "netSalesByStoreEM":{
      "name": "店鋪淨銷售額 (不包括會員銷售)",
      "tableHeader": {
        "count": "訂單總數",
        "netSales": "訂單合計",
        "shipFromStoreCode": "分店編號",
        "orderSource": "訂單來源"
      }
    },
    "paymentByStoreType":{
      "name": "商店和支付類型明細付款",
      "tableHeader": {
        "sumAmount": "金額",
        "sumTip": "小費",
        "grandTotal": "累計",
        "countOrderId": "訂單總數",
        "shipmentsFromStoreCode": "分店編號",
        "paymentProviderCode": "支付提供商編號",
        "name": "支付提供商名稱",
        "currencyCode": "貨幣編號",
        "state": "狀態"
      }
    },
    "discountBreakdown":{
      "name": "折扣明細",
      "tableHeader": {
        "shipFromStoreCode": "分店編號",
        "discountCode": "折扣編號",
        "discountName": "折扣名稱",
        "lineTotal": "行總計",
        "quantity": "數量",
        "totalPrice": "帶來收入"
      },
    },
    "MembershipSalesByType":{
      "name": "會籍銷售摘要",
      "tableHeader": {
        "shipFromStoreCode": "分店編號",
        "skuCode": "SKU編號",
        "skuInternalUseTitle": "SKU內部使用標題",
        "lineTotal": "行總計",
        "quantity": "數量"
      },
    },
    "MembershipTenderSummaries":{
      "name": "會籍銷售(支付類型)",
      "tableHeader": {
        "shipFromStoreCode": "分店編號",
        "paymentProviderCode": "支付提供商編號",
        "lineTotal": "行總計",
        "quantity": "數量"
      },
    },
    "text":{
      "paymentCodeMapping":{
        "vsm": "vsm - VISA (Manual) - VISA (手動);",
        "msm": "msm - MASTER (Manual) - 萬事達 (手動);",
        "CV": "CV - Cash Voucher - 現金券;",
        "CU": "CU - UNION PAY (Manual) - 銀聯 (手動);",
        "aem": "aem - AMERICAN EXPRESS (Manual) - 美國運通 (手動);",
        "CASH_HKD": "CASH_HKD - Cash(HKD) - 現金(港元);",
        "octopus": "octopus - Octopus (Manual) - 八達通 (手動);",
        "alipay_hkd_manual": "alipay_hkd_manual - Alipay HKD (Manual) - 支付寶香港 (手動);",
        "wechat_pay_hkd_manual": "wechat_pay_hkd_manual - WeChat Pay HKD (Manual) - 微信支付香港 (手動);",
      },
      "tenderWarning": "注意：如果訂單有混合收款，則不准確",
      "salesBalances": "銷售餘額",
      "OperatingStatistics": "經營統計",
      "auditTrail": "審計追踪",
      "salesBreakdown": "銷售明細",
    }
  },
  "voidItemDetailHist": {
    "name": "取消物品詳情報告",
    "filter": {
      "store": {
        "all": "全部",
        "name": "分店編號",
      },
      "softDeleteAt": {
        "startDate": "取消物品開始日期",
        "to": "至",
        "endDate": "取消物品結束日期"
      },
    },
    "tableHeader": {
      "skuCode": "SKU編號",
      "sukTitle": "SKU內部名稱",
      "productCode": "物品編號",
      "productTitle": "物品名稱",
      "productType": "物品類別",
      "storeCode": "分店編號",
      "quantity": "數量"
    }
  },
  "totalFoodGrossSales": {
    "name": "總食品銷售額報告",
    "filter": {
      "store": {
        "all": "全部",
        "name": "分店編號",
      },
      "orderCompletedAt": {
        "startDate": "訂單完成開始日期",
        "to": "至",
        "endDate": "訂單完成結束日期"
      },
    },
    "tableHeader": {
      "sumLineTotal": "總計",
      "orderType": "訂單類別",
      "shipFromStoreCode": "分店編號",
      "commerceChannelCode": "商業頻道編號"
    }
  },
  "discountBreakdownByCommerceChannel": {
    "name": "商業頻道折扣明細報告",
    "tableHeader": {
      "shipFromStoreCode": "分店編號",
      "discountCode": "折扣編號",
      "discountNameEnHk": "折扣名稱 (英文)",
      "discountNameZhHk": "折扣名稱 (中文)",
      "lineTotal": "行總計",
      "quantity": "數量",
      "commerceChannelCode": "商業頻道編號",
      "couponRedeemablePoint": "兌換分數",
      "couponRedeemablePrice": "兌換金額",
      "orderCount": "訂單數量",
      "orderTotalPrice": "淨銷售額"
    },
    "filter": {
      "store": {
        "all": "全部",
        "name": "分店編號",
      },
      "orderCompletedAt": {
        "startDate": "訂單完成開始日期",
        "to": "至",
        "endDate": "訂單完成結束日期"
      }
    }
  },
  "orderEntriesAuditTrail": {
    "name": "訂單項目審計追踪報告",
    "tableHeader": {
      "orderId": "訂單Id",
      "otGroupId": "訂單交易組Id",
      "lv1Ordering": "第一層訂購",
      "lv2Ordering": "第二層訂購",
      "referenceNumber": "訂單參考編號",
      "storeId": "分店Id",
      "testOrder": "測試訂單",
      "shipmentStoreId": "發貨分店Id",
      "shipmentStoreCode": "發貨分店編號",
      "commerceChannelsCode": "商業頻道編號",
      "orderState": "訂單狀態",
      "orderCompletedAt": "訂單完成時間",
      "cartCreatedAt": "購物車創建時間",
      "cartClosedAt": "購物車關閉時間",
      "orderTransactionsId": "訂單交易Id",
      "internalAppOperatorsId": "內部應用操作者Id",
      "staffNumber": "員工編號",
      "firstName": "員工名字",
      "lastName": "員工姓氏",
      "unitAmount": "單位金額",
      "quantity": "數量",
      "lineTotal": "行總計",
      "orderTransactionsCode": "訂單交易代碼",
      "calculateUnitAmountTargetType": "計算單位金額目標類型",
      "calculateUnitAmountTargetId": "計算單位金額目標Id",
      "couponTokensId": "優惠券代幣Id",
      "couponsCode": "優惠券編號",
      "skuCode": "SKU編號",
      "productTitleZh": "物品名稱 (中文)",
      "productTitleEn": "物品名稱 (英文)",
      "cartLinePropertyFnbOperatorsId": "購物車行屬性餐飲操作者Id",
      "orderLinePropertyStaffNumber": "購物車行屬性餐飲員工編號",
      "orderLinePropertyOrderTransactionsId": "訂單行屬性訂單交易Id",
      "orderManualCartDiscountOrderLinePropertiesId": "手動訂購購物車折扣訂單行屬性Id",
      "orderManualCartDiscountsId": "手動訂購購物車折扣Id",
      "omcdOrderLevelId": "手動訂購購物車折扣訂單級別Id",
      "itemCreatedAt": "項目創建時間"
    },
    "filter": {
      "store": {
        "all": "全部",
        "name": "分店編號",
      },
       "orderCompletedAt": {
        "date": "訂單完成日期",
      },
      "referenceNumber": "訂單參考編號"
    }
  },
  "orderSettlement": {
    "orderSettlementByPaymentType":{
      "name": "訂單結算報告(付款方式)",
      "tableHeader": {
        "shipmentStoresCode": "分店編號",
        "referenceNumber": "訂單參考編號",
        "completedAt": "訂單完成日期",
        "state": "訂單狀態",
        "testOrder": "測試訂單",
        "received": "已收取",
        "tipOrCharge": "小費",
        "amount": "合計",
        "currenciesCode": "貨幣編號",
        "paymentProviderCode": "支付供應商編號",
        "paymentProviderName": "支付供應商名稱",
        "providerPaymentType": "支付供應商付款類型",
        "staffNumber": "員工編號",
        "firstName": "員工名字",
        "lastName": "員工姓氏",
        "commerceChannelsCode": "商業頻道編號",
        "holdAt": "訂單處理日期",
        "reservedAt": "訂單保留日期",
        "declinedAt": "訂單拒絕日期",
        "waitingForApprovalAt": "訂單等待批准日期",
        "cancelledAt": "訂單取消日期",
        "errorAt": "訂單錯誤日期",
        "voidInProgressAt": "訂單取消中日期",
        "voidCompletedAt": "訂單取消日期"
      },
    },
    "orderSettlementByOrder":{
      "name": "訂單結算報告(訂單)",
      "tableHeader": {
        "shipmentStoresCode": "分店編號",
        "referenceNumber": "訂單參考編號",
        "completedAt": "訂單完成日期",
        "confirmedAt": "訂單確認日期",
        "createdAt": "訂單創建日期",
        "billNumber": "訂單號",
        "state": "訂單狀態",
        "testOrder": "測試訂單",
        "received": "已收取",
        "tipOrCharge": "小費",
        "amount": "合計",
        "currenciesCode": "貨幣編號",
        "staffNumber": "員工編號",
        "firstName": "員工名字",
        "lastName": "員工姓氏",
        "commerceChannelsCode": "商業頻道編號",
        "holdAt": "訂單處理日期",
        "reservedAt": "訂單保留日期",
        "declinedAt": "訂單拒絕日期",
        "waitingForApprovalAt": "訂單等待批准日期",
        "cancelledAt": "訂單取消日期",
        "errorAt": "訂單錯誤日期",
        "voidInProgressAt": "訂單取消中日期",
        "voidCompletedAt": "訂單取消日期"
      },
    },
    "filter": {
      "store": {
        "all": "全部",
        "name": "分店編號",
      },
      "orderCreatedAt": {
        "startDate": "訂單創建開始日期",
        "to": "至",
        "endDate": "訂單創建結束日期"
      },
      "paymentProviderCode": "支付供應商編號",
      "staffNumber": "員工編號",
      "orderState": "訂單狀態",
      "paymentState": "付款狀態",
    },
    "paymentCodeMapping":{
      "vsm": "vsm - VISA (Manual) - VISA (手動);",
      "msm": "msm - MASTER (Manual) - 萬事達 (手動);",
      "CV": "CV - Cash Voucher - 現金券;",
      "CU": "CU - UNION PAY (Manual) - 銀聯 (手動);",
      "aem": "aem - AMERICAN EXPRESS (Manual) - 美國運通 (手動);",
      "CASH_HKD": "CASH_HKD - Cash(HKD) - 現金(港元);",
      "octopus": "octopus - Octopus (Manual) - 八達通 (手動);",
      "alipay_hkd_manual": "alipay_hkd_manual - Alipay HKD (Manual) - 支付寶香港 (手動);",
      "wechat_pay_hkd_manual": "wechat_pay_hkd_manual - WeChat Pay HKD (Manual) - 微信支付香港 (手動);",
    }
  },
  "storeStaffSalesPerformanceSummaryPage": {
    "name": "分店員工銷售業績總結",
    "tableHeader": {
      "shipFromStoreCode": "分店編號",
      "lineTotal": "行總計",
      "quantity": "數量",
      "PPA": "人均消費",
      "staffNumber": "員工編號",
      "firstName": "員工名字",
      "lastName": "員工姓氏",
      "dineInNumberOfPeople": "用餐人數",
    },
    "filter": {
      "store": {
        "all": "全部",
        "name": "分店編號",
      },
      "orderCompletedAt": {
        "startDate": "訂單完成開始日期",
        "to": "至",
        "endDate": "訂單完成結束日期"
      }
    }
  },
  "SKUSalesDetailsHistPage": {
    "SKUSalesDetails": {
      "name": "SKU銷售明細報告",
      "tableHeader": {
        "shipFromStoreCode": "分店編號",
        "skuCode": "SKU編號",
        "skuInternalUseTitle": "SKU內部名稱",
        "skuCost": "SKU成本",
        "productTitleEnHk": "物品名稱 (英文)",
        "productTitleZhHk": "物品名稱 (中文)",
        "code": "RSM編號",
        "lineTotal": "行總計",
        "quantity": "數量"
      }
    },
    "SKUSalesByDateDetails": {
      "name": "SKU銷售每日明細報告",
      "tableHeader": {
        "ordersCompletedAt": "訂單完成日期",
        "lineTotal": "行總計",
        "quantity": "數量"
      }
    },
    "filter": {
      "store": {
        "all": "全部",
        "name": "分店編號",
      },
      "orderCompletedAt": {
        "startDate": "訂單完成開始日期",
        "to": "至",
        "endDate": "訂單完成結束日期"
      },
      "rsmCode": "RSM編號",
      "skuCode": "SKU編號"
    }
  },
  "SKUSalesDetailsPage": {
    "name": "SKU銷售明細報告",
    "tableHeader": {
      "storeCode": "分店編號",
      "skuCode": "SKU編號",
      "skuInternalUseTitle": "SKU內部名稱",
      "skuCost": "SKU成本",
      "productTitle": "物品名稱",
      "code": "RSM編號",
      "lineTotal": "行總計",
      "quantity": "數量"
    },
    "filter": {
      "store": {
        "all": "全部",
        "name": "分店編號",
      },
      "orderCompletedAt": {
        "date": "訂單完成日期",
      },
      "rsmCode": "RSM編號",
      "skuCode": "SKU編號"
    }
  },
  "timePeriodSummaryHistPage": {
    "timePeriodSummaryBreakfastDineIn": {
      "name": "時段銷售 - 早餐(堂食)",
      "tableHeader": {
        "count": "訂單數量",
        "sumDineInNumberOfPeople": "用餐人數",
        "avgBillAmount": "平均訂單銷售額",
        "sumNetSales": "淨銷售額",
        "perPersonSpending": "人均消費",
        "ordersCompletedAt": "訂單完成日期",
        "cartsCreatedAt": "訂單日期"
      }
    },
    "timePeriodSummaryLunchDineIn": {
      "name": "時段銷售 - 午市(堂食)",
      "tableHeader": {
        "count": "訂單數量",
        "sumDineInNumberOfPeople": "用餐人數",
        "avgBillAmount": "平均訂單銷售額",
        "sumNetSales": "淨銷售額",
        "perPersonSpending": "人均消費",
        "ordersCompletedAt": "訂單完成日期",
        "cartsCreatedAt": "訂單日期"
      }
    },
    "timePeriodSummaryTeaDineIn": {
      "name": "時段銷售 - 下午茶(堂食)",
      "tableHeader": {
        "count": "訂單數量",
        "sumDineInNumberOfPeople": "用餐人數",
        "avgBillAmount": "平均訂單銷售額",
        "sumNetSales": "淨銷售額",
        "perPersonSpending": "人均消費",
        "ordersCompletedAt": "訂單完成日期",
        "cartsCreatedAt": "訂單日期"
      }
    },
    "timePeriodSummaryDinnerDineIn": {
      "name": "時段銷售 - 晚市(堂食)",
      "tableHeader": {
        "count": "訂單數量",
        "sumDineInNumberOfPeople": "用餐人數",
        "avgBillAmount": "平均訂單銷售額",
        "sumNetSales": "淨銷售額",
        "perPersonSpending": "人均消費",
        "ordersCompletedAt": "訂單完成日期",
        "cartsCreatedAt": "訂單日期"
      }
    },
    "timePeriodSummaryBreakfastTakeaway": {
      "name": "時段銷售 - 早餐(外賣)",
      "tableHeader": {
        "count": "訂單數量",
        "sumDineInNumberOfPeople": "用餐人數",
        "avgBillAmount": "平均訂單銷售額",
        "sumNetSales": "淨銷售額",
        "perPersonSpending": "人均消費",
        "ordersCompletedAt": "訂單完成日期",
        "cartsCreatedAt": "訂單日期"
      }
    },
    "timePeriodSummaryLunchTakeaway": {
      "name": "時段銷售 - 午市(外賣)",
      "tableHeader": {
        "count": "訂單數量",
        "sumDineInNumberOfPeople": "用餐人數",
        "avgBillAmount": "平均訂單銷售額",
        "sumNetSales": "淨銷售額",
        "perPersonSpending": "人均消費",
        "ordersCompletedAt": "訂單完成日期",
        "cartsCreatedAt": "訂單日期"
      }
    },
    "timePeriodSummaryTeaTakeaway": {
      "name": "時段銷售 - 下午茶(外賣)",
      "tableHeader": {
        "count": "訂單數量",
        "sumDineInNumberOfPeople": "用餐人數",
        "avgBillAmount": "平均訂單銷售額",
        "sumNetSales": "淨銷售額",
        "perPersonSpending": "人均消費",
        "ordersCompletedAt": "訂單完成日期",
        "cartsCreatedAt": "訂單日期"
      }
    },
    "timePeriodSummaryDinnerTakeaway": {
      "name": "時段銷售 - 晚市(外賣)",
      "tableHeader": {
        "count": "訂單數量",
        "sumDineInNumberOfPeople": "用餐人數",
        "avgBillAmount": "平均訂單銷售額",
        "sumNetSales": "淨銷售額",
        "perPersonSpending": "人均消費",
        "ordersCompletedAt": "訂單完成日期",
        "cartsCreatedAt": "訂單日期"
      }
    },
    "timePeriodSummaryDetail": {
      "name": "時段銷售明細",
      "tableHeader": {
        "shipFromStoreCode": "分店編號",
        "commerceChannelCode": "商業頻道編號",
        "cartsCreatedAtHour": "開枱時段",
        "sumGrossSales": "銷售總額",
        "sumDiscounts": "折扣",
        "sumShippingFees": "運送費",
        "sumServiceCharges": "服務費",
        "sumMembershipCharges": "會員費",
        "sumOrderAmountRounding": "訂單捨入金額",
        "sumOrderTotal": "合計訂單",
        "count": "訂單數量",
        "sumDineInNumberOfPeople": "用餐人數",
        "avgBillAmount": "平均訂單銷售額",
        "sumNetSales": "淨銷售額",
        "perPersonSpending": "人均消費",
        "orderSource": "訂單來源",
      }
    },
    "FnBTotalAndDiscountBreakDown": {
      "name": "餐飲總數折扣細明",
      "tableHeader": {
        "storeCode": "分店編號",
        "openTableTime": "開枱時間",
        "closeBillTime": "收枱時間",
        "dineInTableNumber": "枱號",
        "serviceMode": "服務模式",
        "commerceChannelCode": "商業頻道編號",
        "orderGrossTotal": "訂單總額",
        "foodGrossTotal": "食品總額",
        "bvGrossTotal": "飲品總額",
        "rounding": "捨數",
        "serviceCharge": "服務費",
        "discountTotal": "折扣總額",
        "allotedFoodDiscount": "分配食品折扣",
        "allotedBvDiscount": "分配飲品折扣",
        "unallottableDiscount": "不可分配折扣",
        "orderNetTotal": "訂單淨總額"
      }
    },
    "discountBreakdown":{
      "name": "折扣明細報告",
      "tableHeader": {
        "shipFromStoreCode": "分店編號",
        "discountCode": "折扣編號",
        "discountNameEnHk": "折扣名稱 (英文)",
        "discountNameZhHk": "折扣名稱 (中文)",
        "lineTotal": "行總計",
        "quantity": "數量",
        "totalPrice": "帶來收入"
      }
    },
    "filter": {
      "store": {
        "all": "全部",
        "name": "分店編號",
      },
      "orderCompletedAt": {
        "startDate": "訂單完成開始日期",
        "to": "至",
        "endDate": "訂單完成結束日期"
      }
    }
  },
  "paymentByStoreTypeBreakdownPage":{
    "name": "商店和支付類型明細付款",
    "tableHeader": {
      "referenceNumber": "訂單參考編號",
      "cartReferenceNumber": "訂購車參考編號",
      "orderCompletedAt": "訂單完成時間",
      "code": "支付供應商編號",
      "internalUseTitle": "支付供應商內部名稱",
      "state": "付款狀態",
      "amount": "合計",
      "tip": "小費",
      "grandTotal": "總合計",
      "fullRefundedAt": "付款退款時間",
      "paymentCompletedAt": "付款完成時間"
    },
    "filter": {
      "store": {
        "all": "全部",
        "name": "分店編號",
      },
      "orderCompletedAt": {
        "date": "訂單完成日期",
      },
      "paymentProviderCode": "支付供應商編號",
    },
    "paymentCodeMapping":{
      "vsm": "vsm - VISA (Manual) - VISA (手動);",
      "msm": "msm - MASTER (Manual) - 萬事達 (手動);",
      "CV": "CV - Cash Voucher - 現金券;",
      "CU": "CU - UNION PAY (Manual) - 銀聯 (手動);",
      "aem": "aem - AMERICAN EXPRESS (Manual) - 美國運通 (手動);",
      "CASH_HKD": "CASH_HKD - Cash(HKD) - 現金(港元);",
      "octopus": "octopus - Octopus (Manual) - 八達通 (手動);",
      "alipay_hkd_manual": "alipay_hkd_manual - Alipay HKD (Manual) - 支付寶香港 (手動);",
      "wechat_pay_hkd_manual": "wechat_pay_hkd_manual - WeChat Pay HKD (Manual) - 微信支付香港 (手動);",
    }
  },
  "outstandingTablePage":{
    "detail": {
      "name": "未完成檯明細報告",
      "tableHeader": {
        "sumLineTotal": "淨銷售額",
        "storeCode": "分店編號",
        "dineInTableNumber": "檯號",
        "dineInTableSubNumber": "分檯",
        "staffNumber": "員工編號",
        "firstName": "員工名字",
        "lastName": "員工姓氏"
      }
    },
    "netSales": {
      "name": "未完成檯淨銷售額",
    },
    "filter": {
      "store": {
        "all": "全部",
        "name": "分店編號",
      },
      "cartCreatedAt": {
        "date": "開檯日期",
      }
    },
  },
  "voidItemDetail": {
    "name": "取消物品詳情報告",
    "filter": {
      "store": {
        "all": "全部",
        "name": "分店編號",
      },
      "softDeleteAt": {
        "date": "取消物品日期",
      },
    },
    "tableHeader": {
      "skuCode": "SKU編號",
      "sukTitle": "SKU內部名稱",
      "productCode": "物品編號",
      "productTitle": "物品名稱",
      "productType": "物品類別",
      "storeCode": "分店編號",
      "quantity": "數量"
    }
  },
  "timePeriodSummaryPage": {
    "timePeriodSummaryBreakfastDineIn": {
      "name": "時段銷售 - 早餐(堂食)",
      "tableHeader": {
        "count": "訂單數量",
        "sumDineInNumberOfPeople": "用餐人數",
        "avgBillAmount": "平均訂單銷售額",
        "sumNetSales": "淨銷售額",
        "perPersonSpending": "人均消費",
        "ordersCompletedAt": "訂單完成日期"
      }
    },
    "timePeriodSummaryLunchDineIn": {
      "name": "時段銷售 - 午市(堂食)",
      "tableHeader": {
        "count": "訂單數量",
        "sumDineInNumberOfPeople": "用餐人數",
        "avgBillAmount": "平均訂單銷售額",
        "sumNetSales": "淨銷售額",
        "perPersonSpending": "人均消費",
        "ordersCompletedAt": "訂單完成日期"
      }
    },
    "timePeriodSummaryTeaDineIn": {
      "name": "時段銷售 - 下午茶(堂食)",
      "tableHeader": {
        "count": "訂單數量",
        "sumDineInNumberOfPeople": "用餐人數",
        "avgBillAmount": "平均訂單銷售額",
        "sumNetSales": "淨銷售額",
        "perPersonSpending": "人均消費",
        "ordersCompletedAt": "訂單完成日期"
      }
    },
    "timePeriodSummaryDinnerDineIn": {
      "name": "時段銷售 - 晚市(堂食)",
      "tableHeader": {
        "count": "訂單數量",
        "sumDineInNumberOfPeople": "用餐人數",
        "avgBillAmount": "平均訂單銷售額",
        "sumNetSales": "淨銷售額",
        "perPersonSpending": "人均消費",
        "ordersCompletedAt": "訂單完成日期"
      }
    },
    "timePeriodSummaryBreakfastTakeaway": {
      "name": "時段銷售 - 早餐(外賣)",
      "tableHeader": {
        "count": "訂單數量",
        "sumDineInNumberOfPeople": "用餐人數",
        "avgBillAmount": "平均訂單銷售額",
        "sumNetSales": "淨銷售額",
        "perPersonSpending": "人均消費",
        "ordersCompletedAt": "訂單完成日期"
      }
    },
    "timePeriodSummaryLunchTakeaway": {
      "name": "時段銷售 - 午市(外賣)",
      "tableHeader": {
        "count": "訂單數量",
        "sumDineInNumberOfPeople": "用餐人數",
        "avgBillAmount": "平均訂單銷售額",
        "sumNetSales": "淨銷售額",
        "perPersonSpending": "人均消費",
        "ordersCompletedAt": "訂單完成日期"
      }
    },
    "timePeriodSummaryTeaTakeaway": {
      "name": "時段銷售 - 下午茶(外賣)",
      "tableHeader": {
        "count": "訂單數量",
        "sumDineInNumberOfPeople": "用餐人數",
        "avgBillAmount": "平均訂單銷售額",
        "sumNetSales": "淨銷售額",
        "perPersonSpending": "人均消費",
        "ordersCompletedAt": "訂單完成日期"
      }
    },
    "timePeriodSummaryDinnerTakeaway": {
      "name": "時段銷售 - 晚市(外賣)",
      "tableHeader": {
        "count": "訂單數量",
        "sumDineInNumberOfPeople": "用餐人數",
        "avgBillAmount": "平均訂單銷售額",
        "sumNetSales": "淨銷售額",
        "perPersonSpending": "人均消費",
        "ordersCompletedAt": "訂單完成日期"
      }
    },
    "timePeriodSummaryDetail": {
      "name": "時段銷售明細",
      "tableHeader": {
        "shipFromStoreCode": "分店編號",
        "commerceChannelCode": "商業頻道編號",
        "cartsCreatedAtHour": "開枱時段",
        "sumGrossSales": "銷售總額",
        "sumDiscounts": "折扣",
        "sumShippingFees": "運送費",
        "sumServiceCharges": "服務費",
        "sumMembershipCharges": "會員費",
        "sumOrderAmountRounding": "訂單捨入金額",
        "sumOrderTotal": "合計訂單",
        "count": "訂單數量",
        "sumDineInNumberOfPeople": "用餐人數",
        "avgBillAmount": "平均訂單銷售額",
        "sumNetSales": "淨銷售額",
        "perPersonSpending": "人均消費",
        "orderSource": "訂單來源",
      }
    },
    "FnBTotalAndDiscountBreakDown": {
      "name": "餐飲總數折扣細明",
      "tableHeader": {
        "storeCode": "分店編號",
        "openTableTime": "開枱時間",
        "closeBillTime": "收枱時間",
        "dineInTableNumber": "枱號",
        "serviceMode": "服務模式",
        "commerceChannelCode": "商業頻道編號",
        "orderGrossTotal": "訂單總額",
        "foodGrossTotal": "食品總額",
        "bvGrossTotal": "飲品總額",
        "rounding": "捨數",
        "serviceCharge": "服務費",
        "discountTotal": "折扣總額",
        "allotedFoodDiscount": "分配食品折扣",
        "allotedBvDiscount": "分配飲品折扣",
        "unallottableDiscount": "不可分配折扣",
        "orderNetTotal": "訂單淨總額"
      }
    },
    "discountBreakdownByStaff":{
      "name": "員工折扣明細報告",
      "tableHeader": {
        "shipFromStoreCode": "分店編號",
        "staffNumber": "員工編號",
        "firstName": "員工名字",
        "lastName": "員工姓氏",
        "discountCode": "折扣編號",
        "discountName": "折扣名稱",
        "lineTotal": "行總計",
        "quantity": "數量"
      }
    },
    "filter": {
      "store": {
        "all": "全部",
        "name": "分店編號",
      },
      "orderCompletedAt": {
        "date": "訂單完成日期",
      }
    }
  },
  "discountBreakdownByStaff":{
    "name": "員工折扣明細報告",
    "tableHeader": {
      "shipFromStoreCode": "分店編號",
      "staffNumber": "員工編號",
      "firstName": "員工名字",
      "lastName": "員工姓氏",
      "discountCode": "折扣編號",
      "discountName": "折扣名稱",
      "lineTotal": "行總計",
      "quantity": "數量"
    },
    "filter": {
      "store": {
        "all": "全部",
        "name": "分店編號",
      },
      "orderCompletedAt": {
        "date": "訂單完成日期",
      },
      "discountCode": "折扣編號"
    }
  },
  "staffStatisticsPage":{
    "name": "人均消費(員工分類)",
    "tableHeader": {
      "staffNumber": "員工編號",
      "firstName": "員工名字",
      "lastName": "員工姓氏",
      "perPersonSpending": "人均消費"
    },
    "filter": {
      "store": {
        "all": "全部",
        "name": "分店編號",
      },
      "orderCompletedAt": {
        "date": "訂單完成日期",
      },
      "staffNumber": "員工編號"
    }
  },
  "staffTenderSummaryPage": {
    "paymentByStoreStaff": {
      "name": "商店和支付類型明細付款(員工分類)",
      "tableHeader": {
        "storeCode": "分店編號",
        "paymentProviderCode": "支付供應商編號",
        "staffNumber": "員工編號",
        "firstName": "員工名字",
        "lastName": "員工姓氏",
        "orderCount": "訂單數量",
        "sumAmount": "合計",
        "sumTip": "小費"
      }
    },
    "discountBreakdownByStaff": {
      "name": "員工折扣明細報告",
      "tableHeader": {
        "shipFromStoreCode": "分店編號",
        "staffNumber": "員工編號",
        "firstName": "員工名字",
        "lastName": "員工姓氏",
        "discountCode": "折扣編號",
        "discountName": "折扣名稱",
        "lineTotal": "行總計",
        "quantity": "數量"
      }
    },
    "membershipTenderDetailsByStaff": {
      "name": "會籍支付類型明細 (員工分類)",
      "tableHeader": {
        "shipFromStoreCode": "分店編號",
        "staffNumber": "員工編號",
        "firstName": "員工名字",
        "lastName": "員工姓氏",
        "paymentProviderCode": "支付供應商編號",
        "lineTotal": "行總計",
        "quantity": "數量"
      }
    },
    "filter": {
      "store": {
        "all": "全部",
        "name": "分店編號",
      },
      "orderCompletedAt": {
        "date": "訂單完成日期",
      },
      "staffNumber": "員工編號"
    }
  },
  "userExport":{
    "name": "會員匯出",
    "tableHeader": {
      "totalSpend": "總消費",
      "orderCount": "訂單總數",
      "membershipCode": "會員編號",
      "firstName": "會員名字",
      "lastName": "會員姓氏",
      "rankCode": "會員等級編號",
      "sex": "性別",
      "email": "電郵地址",
      "phone": "電話",
      "dateOfBirth": "出生日期",
      "isActive": "已激活",
      "isDeleted": "已刪除",
      "remark": "備註",
      "createdAt": "創建時間",
      "updatedAt": "更新時間",
      "modifiedAt": "修改時間",
      "rankExpireAt": "會員有效日期",
      "userPreferDistrict": "會員首選地區",
      "userAgreementPics": "接收推廣資訊指示",
      "userAgreementTerms": "用戶條款",
      "point": "積分",
      "lastOrderCompletedAt": "最近消費日期",
      "referredBy": "推薦人",
      "averageTotalPrice": "平均消費"
    },
    "filter": {
      "updatedAt": {
        "startDate": "更新日期開始",
        "to": "至",
        "endDate": "更新日期結束"
      },
      "createdAt": {
        "startDate": "創建日期開始",
        "to": "至",
        "endDate": "創建日期結束"
      },
      "membershipCode": "會員編號",
      "email": "電郵地址",
      "phone": "電話",
      "userFirstName": "顧客名字",
      "userLastName": "顧客姓氏",
      "userAgreementPics": "接收推廣資訊指示",
      "userAgreementTerms": "同意用戶條款"
    },
  },
  "userQuery":{
    "userExport":{
      "name": "用戶資料",
      "tableHeader": {
        "totalSpend": "總消費",
        "orderCount": "訂單總數",
        "membershipCode": "會員編號",
        "firstName": "會員名字",
        "lastName": "會員姓氏",
        "rankCode": "會員等級編號",
        "sex": "性別",
        "email": "電郵地址",
        "phone": "電話",
        "dateOfBirth": "出生日期",
        "isActive": "已激活",
        "isDeleted": "已刪除",
        "remark": "備註",
        "createdAt": "創建時間",
        "updatedAt": "更新時間",
        "modifiedAt": "修改時間",
        "rankExpireAt": "會員有效日期",
        "userPreferDistrict": "會員首選地區",
        "userAgreementPics": "接收推廣資訊指示",
        "userAgreementTerms": "用戶條款",
        "point": "積分",
        "lastOrderCompletedAt": "最近消費日期",
        "referredBy": "推薦人",
        "averageTotalPrice": "平均消費"
      },
    },
    "membershipTransaction":{
      "name": "會籍資料",
      "tableHeader": {
        "rankCode": "會員等級編號",
        "effectiveAt": "生效時間",
        "expireAt": "過期時間",
        "isDeleted": "已刪除",
        "firstName": "會員名字",
      },
    },
    "orderHistory":{
      "name": "訂單歷史",
      "tableHeader": {
        "ordersCompletedAt": "訂單完成時間",
        "shipFromStoreCode": "分店編號",
        "referenceNumber": "訂單參考編號",
        "grossSales": "銷售總額",
        "discounts": "折扣",
        "serviceCharges": "服務費",
        "orderAmountRounding": "訂單捨入金額",
        "membershipCharges": "會籍費",
        "orderTotal": "合計",
      },
    },
    "loyaltyPointTransaction":{
      "name": "會員積分交易",
      "tableHeader": {
      "id": "ID", 
        "createdAt": "創建時間",
        "code": "編號",
        "opening": "上一個結餘", 
        "points": "積分",
        "balance": "現在結餘",
        "isDeleted": "已刪除", 
        "isActive": "已激活", 
        "activeAt": "激活時間",
        "expireAt": "過期時間", 
        "updatedAt": "更新時間",
      },
    },
    "usersMostConsumeStore": {
      "name": "用戶最常消費分店",
      "tableHeader": {
        "storeCode": "分店編號",
        "count": "數量"
      }
    },
    "filter": {
      "membershipCode": "會員編號",
    },
    "enterMembershipCode": "請輸入會員編號，按ENTER鍵查詢會員。",
  },
  "dailyOperationHist": {
    "titleMapping": {
      "sumGrossSales": "總銷售額",
      "sumDiscounts": "總折扣",
      "sumShippingFees": "總運費",
      "sumServiceCharges": "總服務費",
      "sumOrderAmountRounding": "總訂單捨入金額",
      "sumMembershipCharges": "會籍銷售總額",
      "sumOrderTotal": "總收入",
      "sumNetSales": "總淨銷售額",
      "orderCount": "訂單總數",
      "sumDineInNumberOfPeople": "總人數",
      "avgBillAmount": "平均訂單銷售額",
      "perPersonSpending": "人均消費",
      "voidOrderCount": "取消訂單總數",
      "voidSumOrderTotal": "取消訂單總金額",
      "voidItemCount": "取消物品總數",
      "sumTip": "總小費",
      "outStandingNetsales": "未完成檯銷售額",
      "totalFoodGrossSales": "總食品銷售額",
      "totalBeverageGrossSales": "總飲料銷售額",
      "totalOtherGrossSales": "總其他銷售額",
    },
    "netSalesByStore":{
      "name": "店鋪淨銷售額",
      "tableHeader": {
        "count": "訂單總數",
        "sumOrderTotal": "訂單合計",
        "shipFromStoreCode": "分店編號",
        "orderSource": "訂單來源"
      }
    },
    "netSalesByStoreEM":{
      "name": "店鋪淨銷售額 (不包括會員銷售)",
      "tableHeader": {
        "count": "訂單總數",
        "sumNetSales": "訂單合計",
        "shipFromStoreCode": "分店編號",
        "orderSource": "訂單來源"
      }
    },
    "paymentByStoreType":{
      "name": "商店和支付類型明細付款",
      "tableHeader": {
        "sumAmount": "金額",
        "sumTip": "小費",
        "grandTotal": "累計",
        "countOrderId": "訂單總數",
        "shipmentsFromStoreCode": "分店編號",
        "paymentProviderCode": "支付提供商編號",
        "name": "支付提供商名稱",
        "currencyCode": "貨幣編號",
        "state": "狀態"
      }
    },
    "tenderByStoreStaff":{
      "name": "商店和支付類型明細付款(員工分類)",
      "tableHeader": {
        "sumAmount": "金額",
        "sumTip": "小費",
        "countOrderId": "訂單總數",
        "shipmentsFromStoreCode": "分店編號",
        "paymentProviderCode": "支付提供商編號",
        "name": "支付提供商名稱",
        "currencyCode": "貨幣編號",
        "state": "狀態",
        "staffNumber": "員工編號",
        "firstName": "員工名字",
        "lastName": "員工姓氏",
      }
    },
    "discountBreakdown":{
      "name": "折扣明細",
      "tableHeader": {
        "shipFromStoreCode": "分店編號",
        "discountCode": "折扣編號",
        "discountNameEnHk": "折扣名稱（英文）",
        "discountNameZhHk": "折扣名稱（中文）",
        "lineTotal": "行總計",
        "quantity": "數量",
        "totalPrice": "帶來收入"
      },
    },
    "SKUSalesDetails":{
      "name": "SKU銷售詳情",
      "tableHeader": {
        "skuCode": "SKU編號",
        "skuInternalUseTitle": "SKU內部使用標題",
        "skuCost": "SKU成本",
        "productTitleEnHk": "產品名稱（英文）",
        "productTitleZhHk": "產品名稱（中文）",
        "code": "RSM編號",
        "lineTotal": "行總計",
        "quantity": "數量"
      },
    },
    "HighlightCategorySales":{
      "name": "突出品類銷售",
      "tableHeader": {
        "shipFromStoreCode": "分店編號",
        "skuCode": "SKU編號",
        "skuInternalUseTitle": "SKU內部使用標題",
        "productTitleEnHk": "產品名稱（英文）",
        "productTitleZhHk": "產品名稱（中文）",
        "code": "RSM編號",
        "type": "類別編號",
        "lineTotal": "行總計",
        "quantity": "數量"
      },
    },
    "MembershipSalesByType":{
      "name": "會籍銷售摘要",
      "tableHeader": {
        "shipFromStoreCode": "分店編號",
        "skuCode": "SKU編號",
        "skuInternalUseTitle": "SKU內部使用標題",
        "lineTotal": "行總計",
        "quantity": "數量"
      },
    },
    "MembershipSalesDetails":{
      "name": "會籍銷售細明",
      "tableHeader": {
        "shipFromStoreCode": "分店編號",
        "skuCode": "SKU編號",
        "skuInternalUseTitle": "SKU內部使用標題",
        "staffNumber": "員工編號",
        "firstName": "員工名字",
        "lastName": "員工姓氏",
        "ordersCompletedAt": "訂單完成時間",
        "unitAmount": "單位金額",
        "quantity": "數量",
        "lineTotal": "行總計",
      },
    },
    "MembershipTenderSummaries":{
      "name": "會籍銷售(支付類型)",
      "tableHeader": {
        "shipFromStoreCode": "分店編號",
        "paymentProviderCode": "支付提供商編號",
        "lineTotal": "行總計",
        "quantity": "數量"
      },
    },
    "filter": {
      "store": {
        "name": "分店編號",
        "all": "全部"
      },
      "orderCompletedAt": {
        "startDate": "訂單完成開始日期",
        "to": "至",
        "endDate": "訂單完成結束日期"
      }
    },
    "text":{
      "paymentCodeMapping":{
        "vsm": "vsm - VISA (Manual) - VISA (手動);",
        "msm": "msm - MASTER (Manual) - 萬事達 (手動);",
        "CV": "CV - Cash Voucher - 現金券;",
        "CU": "CU - UNION PAY (Manual) - 銀聯 (手動);",
        "aem": "aem - AMERICAN EXPRESS (Manual) - 美國運通 (手動);",
        "CASH_HKD": "CASH_HKD - Cash(HKD) - 現金(港元);",
        "octopus": "octopus - Octopus (Manual) - 八達通 (手動);",
        "alipay_hkd_manual": "alipay_hkd_manual - Alipay HKD (Manual) - 支付寶香港 (手動);",
        "wechat_pay_hkd_manual": "wechat_pay_hkd_manual - WeChat Pay HKD (Manual) - 微信支付香港 (手動);",
      },
      "tenderWarning": "注意：如果訂單有混合收款，則不准確"
    }
  },
  "dailyOperationHistPrint": {
    "titleMapping": {
      "sumGrossSales": "總銷售額",
      "sumDiscounts": "總折扣",
      "sumShippingFees": "總運費",
      "sumServiceCharges": "總服務費",
      "sumOrderAmountRounding": "總訂單捨入金額",
      "sumMembershipCharges": "會員銷售總額",
      "sumNetSales": "總淨銷售額",
      "orderCount": "訂單總數",
      "sumDineInNumberOfPeople": "總人數",
      "avgBillAmount": "平均訂單銷售額",
      "perPersonSpending": "人均消費",
      "voidOrderCount": "取消訂單總數",
      "voidSumOrderTotal": "取消訂單總金額",
      "voidItemCount": "取消物品總數",
      "sumTip": "總小費",
      "totalFoodGrossSales": "總食品銷售額",
      "totalBeverageGrossSales": "總飲料銷售額",
      "foodPercentage": "食品銷售額百分比",
      "beveragePercentage": "飲料銷售額百分比",
      "otherPercentage": "其他銷售額百分比",
      "totalGrossSalesChart": "總銷售額明細",
      "totalOtherGrossSales": "總其他銷售額",
    },
    "totalGrossSalesChart": {
      "labels": {
        "MvOrderTransactions.foodPercentage": "食品",
        "MvOrderTransactions.beveragePercentage": "飲料",
        "MvOrderTransactions.otherPercentage": "其他",
      },
    },    
    "netSalesByStoreEM":{
      "name": "店鋪淨銷售額 (不包括會員銷售)",
      "tableHeader": {
        "count": "訂單總數",
        "sumNetSales": "訂單合計",
        "shipFromStoreCode": "分店編號",
        "orderSource": "訂單來源"
      }
    },
    "paymentByStoreType":{
      "name": "商店和支付類型明細付款",
      "tableHeader": {
        "sumAmount": "金額",
        "sumTip": "小費",
        "grandTotal": "累計",
        "countOrderId": "訂單總數",
        "shipmentsFromStoreCode": "分店編號",
        "paymentProviderCode": "支付提供商編號",
        "name": "支付提供商名稱",
        "currencyCode": "貨幣編號",
        "state": "狀態"
      }
    },
    "discountBreakdown":{
      "name": "折扣明細",
      "tableHeader": {
        "shipFromStoreCode": "分店編號",
        "discountCode": "折扣編號",
        "discountNameEnHk": "折扣名稱（英文）",
        "discountNameZhHk": "折扣名稱（中文）",
        "lineTotal": "行總計",
        "quantity": "數量",
        "totalPrice": "帶來收入"
      },
    },
    "MembershipSalesByType":{
      "name": "會籍銷售摘要",
      "tableHeader": {
        "shipFromStoreCode": "分店編號",
        "skuCode": "SKU編號",
        "skuInternalUseTitle": "SKU內部使用標題",
        "lineTotal": "行總計",
        "quantity": "數量"
      },
    },
    "MembershipTenderSummaries":{
      "name": "會籍銷售(支付類型)",
      "tableHeader": {
        "shipFromStoreCode": "分店編號",
        "paymentProviderCode": "支付提供商編號",
        "lineTotal": "行總計",
        "quantity": "數量"
      },
    },
    "filter": {
      "store": {
        "all": "全部",
        "name": "分店編號",
      },
      "orderCompletedAt": {
        "startDate": "訂單完成開始日期",
        "to": "至",
        "endDate": "訂單完成結束日期"
      }
    },
    "text":{
      "paymentCodeMapping":{
        "vsm": "vsm - VISA (Manual) - VISA (手動);",
        "msm": "msm - MASTER (Manual) - 萬事達 (手動);",
        "CV": "CV - Cash Voucher - 現金券;",
        "CU": "CU - UNION PAY (Manual) - 銀聯 (手動);",
        "aem": "aem - AMERICAN EXPRESS (Manual) - 美國運通 (手動);",
        "CASH_HKD": "CASH_HKD - Cash(HKD) - 現金(港元);",
        "octopus": "octopus - Octopus (Manual) - 八達通 (手動);",
        "alipay_hkd_manual": "alipay_hkd_manual - Alipay HKD (Manual) - 支付寶香港 (手動);",
        "wechat_pay_hkd_manual": "wechat_pay_hkd_manual - WeChat Pay HKD (Manual) - 微信支付香港 (手動);",
      },
      "tenderWarning": "注意：如果訂單有混合收款，則不准確",
      "salesBalances": "銷售餘額",
      "OperatingStatistics": "經營統計",
      "auditTrail": "審計追踪",
      "salesBreakdown": "銷售明細",
    }
  },
  "userRank": {
    "name": "會員數量",
    "tableHeader": {
      "rankCode": "會員等級編號",
      "count": "數量"
    },
  },
  "StaffAttendancePage": {
    "StaffAttendanceName": "員工出勤記錄",
    "StaffAttendanceByDayName": "員工出勤記錄(日期)",
    "titleMapping": {
      "storeCode": "分店編號",
      "staffNumber": "員工編號",
      "staffFirstName": "員工名字",
      "staffLastName": "員工姓氏",
      "onDutyStartAt": "值班開始時間",
      "onDutyEndAt": "值班結束時間",
      "dutyTime": "值班時間",
      "date": "日期",
    },
    "filter": {
      "store": {
        "all": "全部",
        "name": "分店編號",
      },
      "onDutyStartAt": {
        "startDate": "值班開始時間開始日期",
        "to": "至",
        "endDate": "值班開始時間結束日期"
      }
    },
  },
  "perDayPage": {
    "netSalesPerDayName": "每日淨銷售額",
    "membershipSalesPerDayName": "每日會籍銷售額",
    "paxPerDayName": "每日用餐人數",
    "perPersonSpendingPerDayName": "每日人均消費",
    "noOfOrderPerDayName": "每日訂單總數",
    "perOrderSpendingPerDayName": "每日訂單平均消費",
    "discountPerDayName": "每日折扣",
    "beverageGrossSalesPerDayName": "每日飲料銷售總額",
    "deliverooNetSalesPerDayName": "戶戶送每日淨銷售額",
    "openriceNetSalesPerDayName": "開飯喇每日淨銷售額",
    "foodpandaNetSalesPerDayName": "Foodpanda每日總淨銷售額",
    "brainFreezeNetSalesPerDayName": "BrainFreeze每日總淨銷售額",
    "inlineNetSalesPerDayName": "Inline每日總淨銷售額",
    "hktvmallNetSalesPerDayName": "HKTVmall每日總淨銷售額",
    "groupBuyNetSalesPerDayName": "Group Buyer每日總淨銷售額",
    "deliverooServiceChargePerDayName": "Deliveroo每日服務費",
    "openriceServiceChargePerDayName": "Open Rice每日服務費",
    "foodpandaServiceChargePerDayName": "Foodpanda每日服務費",
    "brainFreezeServiceChargePerDayName": "BrainFreez每日服務費",
    "inlineServiceChargePerDayName": "Inline每日服務費",
    "hktvmallServiceChargePerDayName": "HKTVmall每日服務費",
    "groupBuyServiceChargePerDayName": "Group Buyer每日服務費",
    "timePeriodSummaryBreakfastPerDayName": "早餐每日總淨銷售額",
    "timePeriodSummaryLunchPerDayName": "午市每日總淨銷售額",
    "timePeriodSummaryTeaPerDayName": "下午茶每日總淨銷售額",
    "timePeriodSummaryDinnerPerDayName": "晚市每日總淨銷售額",
    "daypartAtHomePerDayName": "每日時段及外賣總淨銷售額",
    "tableHeader": {
      "date": "日期",
      "dow": "星期",
      "netSales": "淨銷售額",
      "total": "合計",
      "breakfastNetSales": "早餐淨銷售額",
      "lunchNetSales": "午市淨銷售額",
      "teaNetSales": "下午茶淨銷售額",
      "dinnerNetSales": "晚市淨銷售額",
      "deliverooNetSales": "戶戶送淨銷售額",
      "openriceNetSales": "開飯喇淨銷售額",
      "foodpandaNetSales": "Foodpanda淨銷售額",
      "brainfreezeNetSales": "BrainFreeze淨銷售額",
      "hktvmallNetSales": "HKTVmall淨銷售額",
      "inlineNetSales": "Inline淨銷售額",
      "sumNetSales": "總淨銷售額",
      "groupBuyNetSales": "Group Buyer淨銷售額"
    },
    "filter": {
      "orderCompletedAt": {
        "startDate": "訂單完成開始日期",
        "to": "至",
        "endDate": "訂單完成結束日期"
      }
    }
  },
  "rsmCheckingPage": {
    "name": "RSM 檢查",
    "tableHeader": {
      "id": "ID",
      "code": "編號",
      "status": "狀態",
      "rsmCategoryCount": "RSM類別數量",
      "rsmCategory": "RSM類別"
    }
  },
  "rsmrCheckingPage": {
    "name": "RSMR 檢查",
    "tableHeader": {
      "id": "ID",
      "code": "編號",
      "status": "狀態",
      "rsmrCategoryCount": "RSMR類別數量",
      "rsmrCategory": "RSMR類別"
    }
  },
  "discountBreakdownByStaffHist": {
    "name": "員工折扣明細報告",
    "tableHeader": {
      "discountCode": "折扣編號",
      "discountNameEnHk": "折扣名稱 (英文)",
      "discountNameZhHk": "折扣名稱 (中文)",
      "lineTotal": "行總計",
      "quantity": "數量",
      "shipFromStoreCode": "分店編號",
      "staffNumber": "員工編號",
      "firstName": "員工名字",
      "lastName": "員工姓氏",
    },
    "filter": {
      "store": {
        "all": "全部",
        "name": "分店編號",
      },
      "orderCompletedAt": {
        "startDate": "訂單完成開始日期",
        "to": "至",
        "endDate": "訂單完成結束日期"
      },
      "discountCode": "折扣編號"
    }
  },
  "skuCheckingPage": {
    "name": "SKU 檢查",
    "filter": {
      "showNull": "顯示無Property Ac",
      "showSkuError": "顯示SKU錯誤"
    }
  },
  "cartDiscountCheckingPage": {
    "name": "購物車折扣檢查",
    "tableHeader": {
      "code": "折扣編號",
      "propertyAc": "Property AC",
      "rsmCode": "RSM編號",
      "rsmrCode": "RSMR編號",
      "startAt": "開始日期",
      "endAt": "完結日期"
    },
    "filter": {
      "showRSMNull": "顯示無RSM編號",
      "showRSMRNull": "顯示無RSMR編號",
      "showPropertyAc": "顯示無Property Ac"
    }
  },
  "dailySalesPage": {
    "name": "每日銷售",
    "tableHeader": {
      "date": "日期",
      "time": "時間",
      "referenceNumber": "訂單參考編號",
      "netSales": "淨銷售額"
    },
    "filter": {
      "store": {
        "all": "全部",
        "name": "分店編號",
      },
      "orderCompletedAt": {
        "date": "訂單完成日期",
      },
    }
  },
  "orderUserExport":{
    "name": "按用戶關聯的商店訂購匯出",
    "tableHeader": {
      "referenceNumber": "訂單參考編號",
      "shipmentStoreCode": "分店編號",
      "completedAt": "訂單完成時間",
      "totalPrice": "總額",
      "membershipCode": "會員編號",
      "firstName": "會員名",
      "lastName": "會員姓",
      "rankExpireAt": "會員等級過期時間",
      "customerRankCode": "會員等級編號",
      "sex": "性別",
      "phone": "電話",
      "userEmail": "電郵地址",
      "userCreatedAt": "會員加入時間",
      "pointsBurn": "消費積分",
      "pointsEarn": "贈送積分",
      "dineInNumberOfPeople": "用餐人數",
      "dineInDuration": "用餐時間(分鐘)",
      "orderType": "訂單類別",
      "paymentMethod": "付款方法"
    },
    "filter": {
      "orderCompletedAt": {
        "startDate": "訂單完成開始時間",
        "to": "至",
        "endDate": "訂單完成結束時間"
      },
      "membershipCode": "會員編號",
      "email": "電郵地址",
      "phone": "電話",
      "userName": "會員姓名",
      "storeCode": "分店編號",
    },
  },
  "paymentDayEnd": {
    "name": "支付類型每日結算",
    "filter": {
      "ordersCompleteDateHkt": {
        "startDate": "訂單完成開始時間",
        "to": "至",
        "endDate": "訂單完成結束時間"
      },
      "store": {
        "name": "分店編號"
      }
    },
     "tableHeader": {
      "date": "日期",
      "total": "合計"
    }
  },
  "mPosSelfOrder": {
    "name": "職員點餐VS自助點餐",
    "tableHeader": {
      "storeCode": "分店編號",
      "mpos": "職員點餐",
      "selfOrder": "自助點餐"
    },
    "filter": {
      "cartCreatedAt": {
        "startDate": "開枱開始時間",
        "to": "至",
        "endDate": "開枱結束時間"
      },
      "store": {
        "name": "分店編號"
      }
    },
  },
  "cartAuditTrail": {
    "name": "購物車審計追踪報告",
    "tableHeader": {
      "cartId": "購物車ID",
      "cartCommerceChannelCode": "購物車商業頻道編號",
      "storeCode": "分店編號",
      "orderId": "訂單ID",
      "orderReferenceNumber": "訂單參考編號",
      "orderState": "訂單狀態",
      "orderUpdatedAt": "訂單更新時間",
      "dineInTableNumber": "檯號",
      "dineInNumberOfPeople": "用餐人數",
      "dineInTableSubNumber": "分檯",
      "staffFirstName": "員工姓氏",
      "staffLastName": "員工名字",
      "staffNumber": "員工編號",
      "totalPrice": "價錢",
      "cartUpdatedAt": "購物車更新時間",
      "cartCreatedAt": "購物車創立時間",
      "cartClosedAt": "購物車關閉時間",
    },
    "filter": {
      "store": {
        "all": "全部",
        "name": "分店編號"
      },
      "cartCreatedAt": {
        "startDate": "購物車開始時間",
        "to": "至",
        "endDate": "購物車結束時間"
      }
    }
  },
}

export default commonZhHK