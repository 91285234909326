const CouponUsageQueries = {
  PublicCouponUsage: {
    query: {
      "timeDimensions": [
      ],
      "order": {
        "PublicCouponUsage.code": "asc"
      },
      "filters": [
      ],
      "dimensions": [
        "PublicCouponUsage.code",
        "PublicCouponUsage.used",
      ],
      "limit": 50000,
      "renewQuery": true,
    }
  },
  MemberCouponUsage: {
    query: {
      "timeDimensions": [
      ],
      "order": {
        "MemberCouponUsage.code": "asc"
      },
      "filters": [
      ],
      "dimensions": [
        "MemberCouponUsage.code",
        "MemberCouponUsage.redeemed",
        "MemberCouponUsage.used",
        "MemberCouponUsage.usedRatio"
      ],
      "limit": 50000,
      "renewQuery": true,
    }
  },
  CouponUsagesV2:{
    query: {
      "timeDimensions": [
      ],
      "order": {
        "CouponUsagesV2.couponCode": "asc",
      },
      "measures": [
        "CouponUsagesV2.countUsage",
      ],
      "filters": [
        {
          "member": "CouponUsagesV2.state",
          "operator": "equals",
          "values": [
            "active"
          ]
        }
      ],
      "dimensions": [
        "CouponUsagesV2.couponCode",
      ],
      "limit": 50000,
      "renewQuery": true,
    }
  },
}

export default CouponUsageQueries