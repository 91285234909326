const DayEndQueries = {
  Tip:{
    query: {
      "measures": [
        "MvPaymentRequests.sumTip",
      ],
      "timeDimensions": [
      ],
      "order": {
      },
      "dimensions": [
      ],
      "filters": [
        {
          "member": "MvPaymentRequests.state",
          "operator": "equals",
          "values": [
            "completed", "authorized"
          ]
        },
        {
          "member": "MvPaymentRequests.ordersState",
          "operator": "equals",
          "values": [
            "completed"
          ]
        },
        {
          "member": "MvPaymentRequests.commerceChannelCode",
          "operator": "notEquals",
          "values": [
            "membership_upgrade"
          ]
        },
        {
          "member": "MvPaymentRequests.orderSource",
          "operator": "notEquals",
          "values": [
            "membership_upgrade"
          ]
        }
      ],
      "limit": 50000,
      "renewQuery": true,
    }
  },
  PaymentByStoreTypeHist: {
    query: {
      "measures": [
        "MvPaymentRequests.sumAmount",
        "MvPaymentRequests.sumTip",
        "MvPaymentRequests.grandTotal",
        "MvPaymentRequests.countOrderId",
      ],
      "timeDimensions": [
      ],
      "order": {
        "MvPaymentRequests.shipmentsFromStoreCode": "asc",
        "MvPaymentRequests.paymentProviderCode": "asc",
        "MvPaymentRequests.state": "asc"
      },
      "dimensions": [
        "MvPaymentRequests.shipmentsFromStoreCode",
        "MvPaymentRequests.paymentProviderCode",
        "PaymentProviderTranslations.name",
        "MvPaymentRequests.currencyCode",
        "MvPaymentRequests.state"
      ],
      "filters": [
        {
          "member": "MvPaymentRequests.state",
          "operator": "equals",
          "values": [
            "completed", "refunded", "authorized"
          ]
        },
        {
          "member": "MvPaymentRequests.ordersState",
          "operator": "equals",
          "values": [
            "completed"
          ]
        },
        {
          "member": "MvPaymentRequests.commerceChannelCode",
          "operator": "notEquals",
          "values": [
            "membership_upgrade"
          ]
        },
        {
          "member": "MvPaymentRequests.orderSource",
          "operator": "notEquals",
          "values": [
            "membership_upgrade"
          ]
        }
      ],
      "limit": 50000,
      "renewQuery": true,
    }
  },
  TenderByStoreStaffHist: {
    query: {
      "measures": [
        "MvPaymentRequests.sumAmount",
        "MvPaymentRequests.sumTip",
        "MvPaymentRequests.countOrderId",
      ],
      "timeDimensions": [
      ],
      "order": {
        "MvPaymentRequests.shipmentsFromStoreCode": "asc",
        "MvPaymentRequests.staffNumber": "asc",
        "MvPaymentRequests.paymentProviderCode": "asc",
        "MvPaymentRequests.state": "asc"
      },
      "dimensions": [
        "MvPaymentRequests.shipmentsFromStoreCode",
        "MvPaymentRequests.staffNumber",
        "MvPaymentRequests.firstName",
        "MvPaymentRequests.lastName",
        "MvPaymentRequests.paymentProviderCode",
        "PaymentProviderTranslations.name",
        "MvPaymentRequests.currencyCode",
        "MvPaymentRequests.state"
      ],
      "filters": [
        {
          "member": "MvPaymentRequests.state",
          "operator": "equals",
          "values": [
            "completed", "refunded", "authorized"
          ]
        },
        {
          "member": "MvPaymentRequests.ordersState",
          "operator": "equals",
          "values": [
            "completed"
          ]
        },
        {
          "member": "MvPaymentRequests.commerceChannelCode",
          "operator": "notEquals",
          "values": [
            "membership_upgrade"
          ]
        },
        {
          "member": "MvPaymentRequests.orderSource",
          "operator": "notEquals",
          "values": [
            "membership_upgrade"
          ]
        }
      ],
      "limit": 50000,
      "renewQuery": true,
    }
  },
  DeliveryNetSalesByStorePerDayEMHist:{
    query: {
      "measures": [
        "MvPaymentRequests.sumAmount",
      ],
      "timeDimensions": [
      ],
      "order": {
        "MvPaymentRequests.shipmentsFromStoreCode": "asc",
      },
      "dimensions": [
        "MvPaymentRequests.shipmentsFromStoreCode",
      ],
      "filters": [
        {
          "member": "MvPaymentRequests.state",
          "operator": "equals",
          "values": [
            "completed", "authorized"
          ]
        },
        {
          "member": "MvPaymentRequests.ordersState",
          "operator": "equals",
          "values": [
            "completed"
          ]
        },
        {
          "member": "MvPaymentRequests.orderSource",
          "operator": "notEquals",
          "values": [
            "membership_upgrade"
          ]
        }
      ],
      "limit": 50000,
      "renewQuery": true,
    }
  },
}

export default DayEndQueries