import React, { useState } from "react";
import Header from '../../components/Header';
import TableComponent from '../../components/TableComponent';
import TextFieldComponent from '../../components/TextFieldComponent';
import SelectComponent from '../../components/SelectComponent';
import DateRangePickerComponent from '../../components/DateRangePickerComponent';
import Dashboard from '../../components/Dashboard';
import PageHeaderComponent from '../../components/PageHeaderComponent';
import OmnitechQueries from '../../queries/OmnitechQueries'
import { useTranslation } from 'react-i18next';
import { useCubeQuery } from '@cubejs-client/react';
import { format } from 'date-fns'

const defaultDate = new Date(1970, 0, 1, 0, 0, 0)

const applyFilter = (query, membershipCodeFilter, emailFilter, phoneFilter, 
  userFirstNameFilter, userLastNameFilter, userAgreementPicsFilter, userAgreementTermsFilter,
  updateStartDateFilter, updateEndDateFilter, createStartDateFilter, createEndDateFilter) => {

  let membershipCodeFilterObj = {
    member: "Users.membershipCode",
    operator: "contains",
    values: [membershipCodeFilter]
  }


  if (membershipCodeFilter !== '') {
    query = {
      ...query,
      filters: (query.filters || []).concat(membershipCodeFilterObj)
    }
  }

  let emailFilterObj = {
    member: "Users.email",
    operator: "contains",
    values: [emailFilter]
  }


  if (emailFilter !== '') {
    query = {
      ...query,
      filters: (query.filters || []).concat(emailFilterObj)
    }
  }

  let phoneFilterObj = {
    member: "Users.phone",
    operator: "contains",
    values: [phoneFilter]
  }


  if (phoneFilter !== '') {
    query = {
      ...query,
      filters: (query.filters || []).concat(phoneFilterObj)
    }
  }

  let userFirstNameFilterObj = {
    member: "Users.firstName",
    operator: "contains",
    values: [userFirstNameFilter]
  }


  if (userFirstNameFilter !== '') {
    query = {
      ...query,
      filters: (query.filters || []).concat(userFirstNameFilterObj)
    }
  }

  let userLastNameFilterObj = {
    member: "Users.lastName",
    operator: "contains",
    values: [userLastNameFilter]
  }


  if (userLastNameFilter !== '') {
    query = {
      ...query,
      filters: (query.filters || []).concat(userLastNameFilterObj)
    }
  }

  if(userAgreementPicsFilter !== 'ALL'){
    let userAgreementPicsFilterObj = {
      member: "Users.userAgreementPics",
      operator: "equals",
      values: [userAgreementPicsFilter]
    }
    query = {
      ...query,
      filters: (query.filters || []).concat(userAgreementPicsFilterObj)
    }
  }


  if(userAgreementTermsFilter !== 'ALL'){
    let userAgreementTermsFilterObj = {
      member: "Users.userAgreementTerms",
      operator: "equals",
      values: [userAgreementTermsFilter]
    }
    query = {
      ...query,
      filters: (query.filters || []).concat(userAgreementTermsFilterObj)
    }
  }

  if( updateStartDateFilter.getTime() !== defaultDate.getTime() || updateEndDateFilter.getTime() !== defaultDate.getTime()){
    let updateDateFilterObj = {
      "dimension": "Users.updatedAt",
      "dateRange": [ updateStartDateFilter, updateEndDateFilter ]
    }
    query = {
      ...query,
      timeDimensions: (query.timeDimensions || []).concat(updateDateFilterObj)
    }
  }

  if( createStartDateFilter.getTime() !== defaultDate.getTime() || createEndDateFilter.getTime() !== defaultDate.getTime()){
     let createDateFilterObj = {
      "dimension": "Users.createdAt",
      "dateRange": [ createStartDateFilter, createEndDateFilter ]
    }
    query = {
      ...query,
      timeDimensions: (query.timeDimensions || []).concat(createDateFilterObj)
    }
  }

  return query
}

const UserExportQuery = (membershipCodeFilter, emailFilter, phoneFilter, userFirstNameFilter, userLastNameFilter, 
  userAgreementPicsFilter, userAgreementTermsFilter, updateStartDateFilter, updateEndDateFilter,
  createStartDateFilter, createEndDateFilter, isRefresh, setRefresh) => {
  const { t } = useTranslation();
  let item = {
    name: t('page.fnb.userExport.name'),
    tableHeaders: {
      "UserOrders.totalPrice":  { name: t('page.fnb.userExport.tableHeader.totalSpend'), type: "number" },
      "UserOrders.count":  { name: t('page.fnb.userExport.tableHeader.orderCount'), type: "number" },
      "Users.membershipCode":  { name: t('page.fnb.userExport.tableHeader.membershipCode'), type: "string" },
      "Users.firstName":  { name: t('page.fnb.userExport.tableHeader.firstName'), type: "string" },
      "Users.lastName":  { name: t('page.fnb.userExport.tableHeader.lastName'), type: "string" },
      "CustomerRanks.code":  { name: t('page.fnb.userExport.tableHeader.rankCode'), type: "string" },
      "Users.sex":  { name: t('page.fnb.userExport.tableHeader.sex'), type: "string" },
      "Users.email":  { name: t('page.fnb.userExport.tableHeader.email'), type: "string" },
      "Users.phone":  { name: t('page.fnb.userExport.tableHeader.phone'), type: "string" },
      "Users.dateOfBirth":  { name: t('page.fnb.userExport.tableHeader.dateOfBirth'), type: "date" },
      "Users.isActive":  { name: t('page.fnb.userExport.tableHeader.isActive'), type: "string" },
      "Users.isDeleted":  { name: t('page.fnb.userExport.tableHeader.isDeleted'), type: "string" },
      "Users.remark":  { name: t('page.fnb.userExport.tableHeader.remark'), type: "string" },
      "Users.createdAt":  { name: t('page.fnb.userExport.tableHeader.createdAt'), type: "dateTime" },
      "Users.updatedAt":  { name: t('page.fnb.userExport.tableHeader.updatedAt'), type: "dateTime" },
      "Users.modifiedAt":  { name: t('page.fnb.userExport.tableHeader.modifiedAt'), type: "dateTime" },
      "UserLoyaltyPointBalances.rankExpireAt":  { name: t('page.fnb.userExport.tableHeader.rankExpireAt'), type: "dateTime" },
      "UserLoyaltyPointBalances.balance":  { name: t('page.fnb.userExport.tableHeader.point'), type: "number" },
      "Users.userPreferDistrict":  { name: t('page.fnb.userExport.tableHeader.userPreferDistrict'), type: "string" },
      "Users.userAgreementPics":  { name: t('page.fnb.userExport.tableHeader.userAgreementPics'), type: "string" },
      "Users.userAgreementTerms":  { name: t('page.fnb.userExport.tableHeader.userAgreementTerms'), type: "string" },
      "UserOrders.averageTotalPrice": { name: t('page.fnb.userExport.tableHeader.averageTotalPrice'), type: "number" },
      "Users.lastOrderCompletedAt": { name: t('page.fnb.userExport.tableHeader.lastOrderCompletedAt'), type: "dateTime" },
      "Users.referredBy": { name: t('page.fnb.userExport.tableHeader.referredBy'), type: "string" },
    },
    grid: {xs: 12, lg: 12},
    export: { allowExport: true, filename: 'user_export_' + format(new Date(), 'yyyyMMddHHmmss') }
  }
  let query = {
    ...OmnitechQueries.users.UserExport.query
  }
  query = applyFilter(query, membershipCodeFilter, emailFilter, phoneFilter, 
    userFirstNameFilter, userLastNameFilter, userAgreementPicsFilter, userAgreementTermsFilter,
    updateStartDateFilter, updateEndDateFilter, createStartDateFilter, createEndDateFilter)
  if(isRefresh)
    query = {}
  const result = useCubeQuery(query, {resetResultSetOnChange: true})
  if(isRefresh)
    setRefresh(false)
  return { item, result }
}

const UserExportPage = () => {
  const { t } = useTranslation();
  const [ membershipCodeFilter, setMembershipCodeFilter ] = useState('');
  const [ emailFilter, setEmailFilter ] = useState('');
  const [ phoneFilter, setPhoneFilter ] = useState('');
  const [ userFirstNameFilter, setUserFirstNameFilter ] = useState('');
  const [ userLastNameFilter, setUserLastNameFilter ] = useState('');
  const [userAgreementPicsFilter, setUserAgreementPicsFilter] = useState('ALL');
  const userAgreementPicsSelect = ['ALL','yes','no']
  const [userAgreementTermsFilter, setUserAgreementTermsFilter] = useState('ALL');
  const userAgreementTermsSelect = ['ALL','true','false']
  let defaultDateFilter = new Date(1970, 0, 1, 0, 0, 0)
  const [updateDateRangeFilter, setUpdateDateRangeFilter] = useState([defaultDateFilter, defaultDateFilter])
  const [updateStartDateFilter, updateEndDateFilter] = updateDateRangeFilter
  const [createDateRangeFilter, setCreateDateRangeFilter] = useState([defaultDateFilter, defaultDateFilter])
  const [createStartDateFilter, createEndDateFilter] = createDateRangeFilter
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  const [ refresh1, setRefresh1 ] = useState(false);

  const { item, result }  = UserExportQuery(membershipCodeFilter, emailFilter, phoneFilter, 
    userFirstNameFilter, userLastNameFilter, userAgreementPicsFilter, userAgreementTermsFilter,
    updateStartDateFilter, updateEndDateFilter, createStartDateFilter, createEndDateFilter, refresh1, setRefresh1)

  const refresh = () => {
    setRefresh1(true)
    setPage(0)
  }

  return ([
    <Header />,
    <PageHeaderComponent onClickFunc={refresh} refreshlabel={t('component.refresh')} headerTitle={t('component.header.menu.userExport')} />,
    <Dashboard>
      <DateRangePickerComponent state={updateDateRangeFilter} stateChanger={setUpdateDateRangeFilter} pageChangerArr={[setPage]}
      startDateLabel={t('page.fnb.userExport.filter.updatedAt.startDate')} 
      toLabel={t('page.fnb.userExport.filter.updatedAt.to')} 
      endDateLabel={t('page.fnb.userExport.filter.updatedAt.endDate')}/>
      <DateRangePickerComponent state={createDateRangeFilter} stateChanger={setCreateDateRangeFilter} pageChangerArr={[setPage]}
      startDateLabel={t('page.fnb.userExport.filter.createdAt.startDate')} 
      toLabel={t('page.fnb.userExport.filter.createdAt.to')} 
      endDateLabel={t('page.fnb.userExport.filter.createdAt.endDate')}/>
      <TextFieldComponent id={"membership_code_filter"} state={membershipCodeFilter} pageChangerArr={[setPage]}
        stateChanger={setMembershipCodeFilter} textLabel={t('page.fnb.userExport.filter.membershipCode')}/>
      <TextFieldComponent id={"email_filter"} state={emailFilter} pageChangerArr={[setPage]}
        stateChanger={setEmailFilter} textLabel={t('page.fnb.userExport.filter.email')}/>
      <TextFieldComponent id={"phone_filter"} state={phoneFilter} pageChangerArr={[setPage]}
        stateChanger={setPhoneFilter} textLabel={t('page.fnb.userExport.filter.phone')}/>
      <TextFieldComponent id={"first_name_filter"} state={userFirstNameFilter} pageChangerArr={[setPage]}
        stateChanger={setUserFirstNameFilter} textLabel={t('page.fnb.userExport.filter.userFirstName')}/>
       <TextFieldComponent id={"last_name_filter"} state={userLastNameFilter} pageChangerArr={[setPage]}
        stateChanger={setUserLastNameFilter} textLabel={t('page.fnb.userExport.filter.userLastName')}/>
      <SelectComponent id={"user_agreement_pics_filter"} state={userAgreementPicsFilter} pageChangerArr={[setPage]}
        stateChanger={setUserAgreementPicsFilter} textLabel={t('page.fnb.userExport.filter.userAgreementPics')} selectArray={userAgreementPicsSelect}/>
      <SelectComponent id={"user_agreement_terms_filter"} state={userAgreementTermsFilter} pageChangerArr={[setPage]}
        stateChanger={setUserAgreementTermsFilter} textLabel={t('page.fnb.userExport.filter.userAgreementTerms')} selectArray={userAgreementTermsSelect}/>
    </Dashboard>
    ,
    <Dashboard>
      <TableComponent item={item} result={result} rowsPerPage={rowsPerPage} 
        setRowsPerPage={setRowsPerPage} page={page} setPage={setPage}/>
    </Dashboard>
  ]) 
};

export default UserExportPage;