import DayEndQueries from './DayEndQueries'
import TimePeriodSummaryQueries from './TimePeriodSummaryQueries'

const mvOrdersSummaryEM = {
  mvOrdersSummaryEM: {
    ...DayEndQueries,
    ...TimePeriodSummaryQueries
  }
}

export default mvOrdersSummaryEM