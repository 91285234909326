import DayEndQueries from './DayEndQueries'
import TakeawayDeliveryOrderApprovalTimeTraceQueries from './TakeawayDeliveryOrderApprovalTimeTraceQueries'
import OrderUserQueries from './OrderUserQueries'
import RepeatedMembersQueries from './RepeatedMembersQueries'

const orders = {
  orders: {
    ...DayEndQueries,
    ...TakeawayDeliveryOrderApprovalTimeTraceQueries,
    ...OrderUserQueries,
    ...RepeatedMembersQueries,
  }
}

export default orders