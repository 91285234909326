const PMCampaignRateQueries = {
  PMCampaignRate: {
    query: {
      "measures": [
      ],
      "order": {
        "PMCampaignRate.campaignStartDate": "desc"
      },
      "filters": [
      ],
      "dimensions": [
        "PMCampaignRate.pushMessageCampaignCode",
        "PMCampaignRate.campaignStartDate",
        "PMCampaignRate.scheduleHour",
        "PMCampaignRate.totalMessages",
        "PMCampaignRate.readCount",
        "PMCampaignRate.readPercentage",
      ],
      "limit": 50000,
      "renewQuery": true,
    }
  }
}

export default PMCampaignRateQueries