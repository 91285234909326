const SalesQueries = {
  SKUSalesDetails: {
   query: {
      "measures": [
        "SKUSalesTransactions.sumQuantity",
        "SKUSalesTransactions.totalAmount"
      ],
      "order": {
        "SKUSalesTransactions.skuCode": "asc",
      },
      "filters": [
        {
          "member": "SKUSalesTransactions.code",
          "operator": "equals",
          "values": [
            "sku_unit_sell_price",
            "property_unit_sell_price",
            "shipping_fee"
          ]
        },
        {
          "member": "SKUSalesTransactions.code",
          "operator": "notEquals",
          "values": [
            "membership_upgrade"
          ]
        },
        {
          "member": "SKUSalesTransactions.orderState",
          "operator": "equals",
          "values": [
            "completed"
          ]
        },
      ],
      "dimensions": [
        "SKUSalesTransactions.shipFromStoreCode",
        "SKUSalesTransactions.skuCode",
        "SKUSalesTransactions.skuInternalUseTitle",
        "SKUSalesTransactions.skuCost",
        "ProductTranslations.title",
        "ViewReportCategoryProductsFlatten.code",
      ],
      "limit": 50000,
      "renewQuery": true,
    }
  },
  RSMSalesDetails: {
   query: {
      "measures": [
        "SKUSalesTransactions.sumQuantity",
        "SKUSalesTransactions.totalAmount"
      ],
      "order": {
        "SKUSalesTransactions.skuCode": "asc",
      },
      "filters": [
        {
          "member": "SKUSalesTransactions.code",
          "operator": "equals",
          "values": [
            "sku_unit_sell_price",
            "property_unit_sell_price",
            "shipping_fee"
          ]
        },
        {
          "member": "SKUSalesTransactions.code",
          "operator": "notEquals",
          "values": [
            "membership_upgrade"
          ]
        },
        {
          "member": "SKUSalesTransactions.orderState",
          "operator": "equals",
          "values": [
            "completed"
          ]
        },
      ],
      "dimensions": [
        "ViewReportCategoryProductsFlatten.code",
      ],
      "limit": 50000,
      "renewQuery": true,
    }
  },
  HighlightCategorySales: {
   query: {
      "measures": [
        "SKUSalesTransactions.sumQuantity",
        "SKUSalesTransactions.totalAmount"
      ],
      "order": {
        "SKUSalesTransactions.skuCode": "asc",
      },
      "filters": [
        {
          "member": "SKUSalesTransactions.code",
          "operator": "equals",
          "values": [
            "sku_unit_sell_price",
            "property_unit_sell_price",
            "shipping_fee"
          ]
        },
        {
          "member": "SKUSalesTransactions.code",
          "operator": "notEquals",
          "values": [
            "membership_upgrade"
          ]
        },
        {
          "member": "SKUSalesTransactions.orderState",
          "operator": "equals",
          "values": [
            "completed"
          ]
        },
        {
          "member": "Categories.code",
          "operator": "equals",
          "values": [
            "lunch_add-on", "join_membership", "membership_extension",
            "premium_steakhouse", "spc", "xmas_menu", "xmas_set"
          ]
        },
      ],
      "dimensions": [
        "SKUSalesTransactions.skuCode",
        "SKUSalesTransactions.skuInternalUseTitle",
        "ProductTranslations.title",
        "ViewReportCategoryProductsFlatten.code",
        "Categories.code"
      ],
      "limit": 50000,
      "renewQuery": true,
    }
  },
  MembershipSalesByType:{
    query: {
      "measures": [
        "OrderTransactionsWOPayment.sumQuantity",
        "OrderTransactionsWOPayment.totalAmount"
      ],
      "order": {
        "OrderTransactionsWOPayment.shipFromStoreCode": "asc",
        "OrderTransactionsWOPayment.skuCode": "asc",
      },
      "filters": [
        {
          "member": "OrderTransactionsWOPayment.code",
          "operator": "equals",
          "values": [
            "sku_unit_sell_price",
            "property_unit_sell_price",
            "shipping_fee"
          ]
        },
        {
          "member": "OrderTransactionsWOPayment.code",
          "operator": "notEquals",
          "values": [
            "membership_upgrade"
          ]
        },
        {
          "member": "OrderTransactionsWOPayment.orderState",
          "operator": "equals",
          "values": [
            "completed"
          ]
        },
        {
          "member": "OrderTransactionsWOPayment.categoryCode",
          "operator": "contains",
          "values": [
            "membership"
          ]
        },
      ],
      "dimensions": [
        "OrderTransactionsWOPayment.shipFromStoreCode",
        "OrderTransactionsWOPayment.skuCode",
        "OrderTransactionsWOPayment.skuInternalUseTitle",
      ],
      "limit": 50000,
      "renewQuery": true,
    }
  },
  MembershipSalesDetailQuery:{
    query: {
      "measures": [
      ],
      "dimensions": [
        "OrderTransactionsSales.shipFromStoreCode",
        "OrderTransactionsSales.skuCode",
        "OrderTransactionsSales.skuInternalUseTitle",
        "InternalAppOperators.staffNumber",
        "InternalAppOperators.firstName",
        "InternalAppOperators.lastName",
        "OrderTransactionsSales.orderCompletedAt",
        "OrderTransactionsSales.unitAmount",
        "OrderTransactionsSales.quantity",
        "OrderTransactionsSales.lineTotal"
      ],
      "filters": [
        {
          "member": "OrderTransactionsSales.orderState",
          "operator": "equals",
          "values": [
            "completed"
          ]
        },
        {
          "member": "OrderTransactionsSales.commerceChannelCode",
          "operator": "notEquals",
          "values": [
            "membership_upgrade"
          ]
        },
        {
          "member": "OrderTransactionsSales.paymentRequestState",
          "operator": "equals",
          "values": [
          "authorized","completed"
          ]
        },
        {
          "member": "OrderTransactionsSales.categoryCode",
          "operator": "contains",
          "values": [
            "membership"
          ]
        },
      ],
      "order": {
        "OrderTransactionsSales.shipFromStoreCode": "asc",
        "InternalAppOperators.staffNumber": "asc"
      },
      "limit": 50000,
      "renewQuery": true,
    }
  },
  MembershipTenderSummaries: {
    query: {
      "measures": [
        "OrderTransactionsSales.sumQuantity",
        "OrderTransactionsSales.totalAmount"
      ],
      "order": {
        "OrderTransactionsSales.shipFromStoreCode": "asc",
        "PaymentProviders.code": "asc",
      },
      "filters": [
        {
          "member": "OrderTransactionsSales.code",
          "operator": "equals",
          "values": [
            "sku_unit_sell_price",
            "property_unit_sell_price",
            "shipping_fee"
          ]
        },
        {
          "member": "OrderTransactionsSales.paymentRequestState",
          "operator": "equals",
          "values": [
            "authorized","completed"
          ]
        },
        {
          "member": "OrderTransactionsSales.code",
          "operator": "notEquals",
          "values": [
            "membership_upgrade"
          ]
        },
        {
          "member": "OrderTransactionsSales.orderState",
          "operator": "equals",
          "values": [
            "completed"
          ]
        },
        {
          "member": "OrderTransactionsSales.categoryCode",
          "operator": "contains",
          "values": [
            "membership"
          ]
        },
      ],
      "dimensions": [
        "OrderTransactionsSales.shipFromStoreCode",
        "PaymentProviders.code"
      ],
      "limit": 50000,
      "renewQuery": true,
    }
  },
  MembershipTenderDetailsByStaff:{
    query: {
      "measures": [
        "OrderTransactionsSales.totalAmount",
        "OrderTransactionsSales.sumQuantity",
      ],
      "dimensions": [
        "OrderTransactionsSales.shipFromStoreCode",
        "PaymentProviders.code",
        "InternalAppOperators.staffNumber",
        "InternalAppOperators.firstName",
        "InternalAppOperators.lastName"
      ],
      "filters": [
        {
          "member": "OrderTransactionsSales.orderState",
          "operator": "equals",
          "values": [
            "completed"
          ]
        },
        {
          "member": "OrderTransactionsSales.paymentRequestState",
          "operator": "equals",
          "values": [
            "authorized", "completed"
          ]
        },
        {
          "member": "OrderTransactionsSales.commerceChannelCode",
          "operator": "notEquals",
          "values": [
            "membership_upgrade"
          ]
        },
        {
          "member": "OrderTransactionsSales.categoryCode",
          "operator": "contains",
          "values": [
            "membership"
          ]
        },
      ],
      "order": {
        "OrderTransactionsSales.shipFromStoreCode": "asc",
        "InternalAppOperators.staffNumber": "asc"
      },
      "limit": 50000,
      "renewQuery": true,
    }
  },
}

export default SalesQueries