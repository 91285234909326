import React, { useState } from "react";
import Header from '../../components/Header';
import TableComponent from '../../components/TableComponent';
import Dashboard from '../../components/Dashboard';
import OmnitechQueries from '../../queries/OmnitechQueries'
import PageHeaderComponent from '../../components/PageHeaderComponent';
import DateRangePickerComponent from '../../components/DateRangePickerComponent';
import { useTranslation } from 'react-i18next';
import { useCubeQuery } from '@cubejs-client/react';
import { setHours, setMinutes, setSeconds, setMilliseconds, format } from 'date-fns'

const CouponUsageQuery = (startDateFilter, endDateFilter, isRefresh, setRefresh) => {
  const { t } = useTranslation();
  if(startDateFilter == null && endDateFilter == null){
    startDateFilter = new Date()
    startDateFilter.setHours(0,0,0,0);
    endDateFilter = new Date()
    endDateFilter.setHours(23,59,59,999);
  }
  else if(startDateFilter == null){
    startDateFilter = setHours(endDateFilter, 0)
    startDateFilter = setMinutes(startDateFilter, 0)
    startDateFilter = setSeconds(startDateFilter, 0)
    startDateFilter = setMilliseconds(startDateFilter, 0)
  }
  else if (endDateFilter == null){
    endDateFilter = setHours(startDateFilter, 23)
    endDateFilter = setMinutes(endDateFilter, 59)
    endDateFilter = setSeconds(endDateFilter, 59)
    endDateFilter = setMilliseconds(endDateFilter, 999)
  }
  let item = {
    name: t('page.membership.couponUsageByDateRangePage.name'),
    tableHeaders: {
      "CouponUsagesV2.couponCode":  { name: t('page.membership.couponUsageByDateRangePage.tableHeader.code'), type: "string" },
      "CouponUsagesV2.countUsage":  { name: t('page.membership.couponUsageByDateRangePage.tableHeader.used'), type: "number" },
    },
    grid: {xs: 12, lg: 12},
    export: { allowExport: true, filename: 'coupon_usage_by_date_range_export' + format(startDateFilter, 'yyyyMMddHHmmss') +
      '_' + format(endDateFilter, 'yyyyMMddHHmmss') }
  }
  let query = {
    ...OmnitechQueries.coupons.CouponUsagesV2.query,
     "timeDimensions": [
      {
        "dimension": "CouponUsagesV2.createdAt",
        "dateRange": [ startDateFilter, endDateFilter ]
      }
    ],
  }
  if(isRefresh)
    query = {}
  const result = useCubeQuery(query, {resetResultSetOnChange: true})
  if(isRefresh)
    setRefresh(false)
  return { item, result }
}

const CouponUsageByDateRangePage = () => {
  const { t } = useTranslation();
  let filterStartDate = new Date()
  filterStartDate.setHours(0,0,0,0);
  let filterEndDate = new Date()
  filterEndDate.setHours(23,59,59,999);
  const [dateRangeFilter, setDateRangeFilter] = useState([filterStartDate, filterEndDate])
  const [startDateFilter, endDateFilter] = dateRangeFilter
  const [rowsPerPage1, setRowsPerPage1] = useState(10);
  const [page1, setPage1] = useState(0);

  const [ refresh1, setRefresh1 ] = useState(false);

  const query1  = CouponUsageQuery(startDateFilter, endDateFilter, refresh1, setRefresh1)

  const refresh = () => {
    setRefresh1(true)
    setPage1(0)
  }

  return ([
    <Header />,
    <PageHeaderComponent onClickFunc={refresh} refreshlabel={t('component.refresh')} headerTitle={t('component.header.menu.couponUsageByDateRange')} />,
    <Dashboard>
      <DateRangePickerComponent state={dateRangeFilter} stateChanger={setDateRangeFilter} pageChangerArr={[setPage1]}
        startDateLabel={t('page.membership.couponUsageByDateRangePage.filter.createdAt.startDate')} 
        toLabel={t('page.membership.couponUsageByDateRangePage.filter.createdAt.to')} 
        endDateLabel={t('page.membership.couponUsageByDateRangePage.filter.createdAt.endDate')}/>
    </Dashboard>,
    <Dashboard>
      <TableComponent item={query1.item} result={query1.result} rowsPerPage={rowsPerPage1} 
        setRowsPerPage={setRowsPerPage1} page={page1} setPage={setPage1}/>
    </Dashboard>
  ]) 
};

export default CouponUsageByDateRangePage;