const UserQueries = {
  RtUserExport: {
    query: {
      "measures": [
        "UserOrders.totalPrice",
        "UserOrders.count"
      ],
      "order": {
      },
      "filters": [
        {
          "member": "Users.roles",
          "operator": "equals",
          "values": [
            "consumer"
          ]
        },
        {
          "member": "Users.email",
          "operator": "notContains",
          "values": [
            "@example.com"
          ]
        },
      
      ],
      "dimensions": [
        "Users.membershipCode",
        "Users.firstName",
        "Users.sex",
        "Users.email",
        "Users.phone",
        "CustomerRanks.code",
        "UserLoyaltyPointBalances.rankExpireAt",
        "UserLoyaltyPointBalances.balance",
        "Users.dateOfBirth",
        "Users.isActive",
        "Users.isDeleted",
        "Users.userPreferDistrict",
        "Users.district",
        "Users.salesamountPS",
        "Users.salescountPS",
        "Users.dateJoinPS",
        "Users.dateContPS",
        "Users.oldCodePS",
        "Users.zonePS",
        "Users.userAgreementPics",
        "Users.userAgreementTerms",
        "Users.remark",
        "Users.createdAt",
        "Users.updatedAt",
        "Users.modifiedAt",
      ],
      "limit": 50000,
      "renewQuery": true,
    }
  },
  UserExport: {
    query: {
      "measures": [
        "UserOrders.totalPrice",
        "UserOrders.count",
        "UserOrders.averageTotalPrice"
      ],
      "order": {
      },
      "filters": [
        {
          "member": "Users.roles",
          "operator": "equals",
          "values": [
            "consumer"
          ]
        },
        {
          "member": "Users.email",
          "operator": "notContains",
          "values": [
            "@example.com"
          ]
        },
      
      ],
      "dimensions": [
        "Users.membershipCode",
        "Users.firstName",
        "Users.sex",
        "Users.email",
        "Users.phone",
        "CustomerRanks.code",
        "UserLoyaltyPointBalances.rankExpireAt",
        "UserLoyaltyPointBalances.balance",
        "Users.dateOfBirth",
        "Users.isActive",
        "Users.isDeleted",
        "Users.userPreferDistrict",
        "Users.userAgreementPics",
        "Users.userAgreementTerms",
        "Users.remark",
        "Users.lastOrderCompletedAt",
        "Users.createdAt",
        "Users.updatedAt",
        "Users.modifiedAt",
        "Users.referredBy",
      ],
      "limit": 50000,
      "renewQuery": true,
    }
  },
  MembershipTransaction:{
    query: {
      "measures": [
      ],
      "order": {
        "UserCustomerRankTransactions.effectiveAt": "desc"
      },
      "filters": [
        {
          "member": "Users.isGuest",
          "operator": "equals",
          "values": [
            "false"
          ]
        },
        {
          "member": "Users.roles",
          "operator": "equals",
          "values": [
            "consumer"
          ]
        },
        {
          "member": "Users.email",
          "operator": "notContains",
          "values": [
            "@example.com"
          ]
        },
      ],
      "dimensions": [
        "CustomerRanks.code",
        "UserCustomerRankTransactions.effectiveAt",
        "UserCustomerRankTransactions.expireAt",
        "UserCustomerRankTransactions.isDeletedString"
      ],
      "limit": 50000,
      "renewQuery": true,
    }
  },
  OrderHistory:{
    query: {
      "measures": [
      ],
      "order": {
        "MvOrdersSummaryUnlimited.ordersCompletedAt": "desc"
      },
      "filters": [
        {
          "member": "Users.isGuest",
          "operator": "equals",
          "values": [
            "false"
          ]
        },
        {
          "member": "Users.roles",
          "operator": "equals",
          "values": [
            "consumer"
          ]
        },
        {
          "member": "Users.email",
          "operator": "notContains",
          "values": [
            "@example.com"
          ]
        },
        {
          "member": "MvOrdersSummaryUnlimited.state",
          "operator": "equals",
          "values": [
            "completed"
          ]
        },
      ],
      "dimensions": [
        "MvOrdersSummaryUnlimited.ordersCompletedAt",
        "MvOrdersSummaryUnlimited.shipFromStoreCode",
        "MvOrdersSummaryUnlimited.referenceNumber",
        "MvOrdersSummaryUnlimited.grossSales",
        "MvOrdersSummaryUnlimited.discounts",
        "MvOrdersSummaryUnlimited.serviceCharges",
        "MvOrdersSummaryUnlimited.orderAmountRounding",
        "MvOrdersSummaryUnlimited.membershipCharges",
        "MvOrdersSummaryUnlimited.orderTotal",
      ],
      "limit": 50000,
      "renewQuery": true,
    }
  },
  LoyaltyPointTransaction:{
    query: {
      "measures": [
        "LoyaltyPointTransactions.opening",
        "LoyaltyPointTransactions.balance",
      ],
      "order": {
        "LoyaltyPointTransactions.createdAt": "asc"
      },
      "filters": [
        {
          "member": "Users.isGuest",
          "operator": "equals",
          "values": [
            "false"
          ]
        },
        {
          "member": "Users.roles",
          "operator": "equals",
          "values": [
            "consumer"
          ]
        },
        {
          "member": "Users.email",
          "operator": "notContains",
          "values": [
            "@example.com"
          ]
        },
        {
          "member": "LoyaltyPointTransactions.isDeleted",
          "operator": "equals",
          "values": [
            "false"
          ]
        },
        {
          "member": "LoyaltyPointTransactions.isActive",
          "operator": "equals",
          "values": [
            "true"
          ]
        }
      ],
      "dimensions": [
        "LoyaltyPointTransactions.id",
        "LoyaltyPointTransactions.createdAt",
        "LoyaltyPointTransactions.code",
        "LoyaltyPointTransactions.points",
        "LoyaltyPointTransactions.activeAt",
        "LoyaltyPointTransactions.expireAt",
        "LoyaltyPointTransactions.updatedAt",
      ],
      "limit": 50000,
      "renewQuery": true,
    }
  },
  ActiveMemberLogin: {
    query: {
      "measures": [
        "ActiveMemberLogin.count"
      ],
      "order": {
      },
      "filters": [
      ],
      "dimensions": [
      ],
      "limit": 50000,
      "renewQuery": true,
    }
  },
  UsersMostConsumeStore: {
    query: {
      "measures": [
      ],
      "order": {
      },
      "filters": [
      ],
      "dimensions": [
        "UsersMostConsumeStore.shipFromStoreCode",
        "UsersMostConsumeStore.orderCount"
      ],
      "limit": 50000,
      "renewQuery": true,
    }
  }
}

export default UserQueries