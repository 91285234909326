import React, { useState, useContext } from "react";
import Header from '../components/Header';
import { useHistory } from "react-router-dom";
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Alert from '@material-ui/lab/Alert';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useTranslation } from 'react-i18next';
import { CubeJSContext } from '../context/CubeJSProvider';
import { CubeContext } from '@cubejs-client/react';
import cubejs from '@cubejs-client/core';
import { set } from 'lodash';

const { 
  v1: uuidv1
} = require('uuid');

const Copyright = (props) => {
  const { t } = useTranslation();
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {t('page.signIn.copyright')}
      <Link color="inherit" href="https://tofugear.com/">
        {t('page.signIn.tofugear')}
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

export default function SignIn() {
  const { t } = useTranslation();
  const history = useHistory();
  const [ wrongCompanyCode, setWrongCompanyCode ] = useState(false);
  const [ wrongUsernamePassword, setWrongUsernamePassword ] = useState(false);
  const [ internalError, setInternalError] = useState(false);
  const [ rememberMe, setRememberMe ] = useState(false);
  const [ authenticating, setAuthenticating ] = useState(false);
  const [open, setOpen] = useState(false);
  const context = useContext(CubeContext);
  const context2 = useContext(CubeJSContext);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const triggerRemberMe = (event) => {
    setRememberMe(!rememberMe)
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    setWrongCompanyCode(false)
    setWrongUsernamePassword(false)
    setInternalError(false)
    setAuthenticating(true)
    const data = new FormData(event.currentTarget);

    let sessionUrl
    if(data.get('companyCode') === null || data.get('companyCode') === "")
      sessionUrl = "http://127.0.0.1:5000/api/v2/en-HK/ecom_report/sessions"
    else if(data.get('companyCode') === "demo")
      sessionUrl = "https://demo-fnb-api.tgpie.com/api/v2/en-HK/ecom_report/sessions"
    else
      sessionUrl = "https://"+data.get('companyCode')+"-prd-api.tgpie.com/api/v2/en-HK/ecom_report/sessions"
    let udid = uuidv1()
    fetch(sessionUrl, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "X-DEVICE-UDID": udid
      },
      body: JSON.stringify({
        "schema_version": "2019-08-18",
        "data":{
          "session":{
            "approach": "email",
            "email": data.get('email'),
            "password": data.get('password')
          }
        }
      })
    })
    .then(async response => {
      if(response.ok){
        let isJson = response.headers.get('content-type')?.includes('application/json');
        let resData = isJson && await response.json();
        let authToken = resData.data.session.auth_token
        let userId = resData.data.session.user_id
        let userUrl
        if(data.get('companyCode') === null || data.get('companyCode') === "")
          userUrl = "http://127.0.0.1:5000/api/v2/en-HK/ecom_report/users/"+userId+"?schema_version=2021-08-16"
        else if(data.get('companyCode') === "demo")
          userUrl = "https://demo-fnb-api.tgpie.com/api/v2/en-HK/ecom_report/users/"+userId+"?schema_version=2021-08-16"
        else
          userUrl = "https://"+data.get('companyCode')+"-prd-api.tgpie.com/api/v2/en-HK/ecom_report/users/"+userId+"?schema_version=2021-08-16"

        fetch(userUrl, {
          method: "GET",
          headers: {
            Accept: "application/json",
            "X-DEVICE-UDID": udid,
            "X-AUTH-TOKEN": authToken
          },
        })
        .then(async response => {
          let isJson = response.headers.get('content-type')?.includes('application/json');
          let userData = isJson && await response.json();

          if (!response.ok) {
            return Promise.reject();
          }

          let storeCodes
          let cubeJSToken
          let companyName
          let cubeJSUrl
          let expiredAt
          let propertyAc = []
          let acl = {}
          let breakfastStart = 6
          let breakfastEnd = 10
          let lunchStart = 11
          let lunchEnd = 14
          let teaStart = 15
          let teaEnd = 17
          let dinnerStart = 18
          let dinnerEnd = 23
          if(userData.data.user.cubejs){
            storeCodes = userData.data.user.cubejs.stores_code
            if(userData.data.user.cubejs.additional_meta && userData.data.user.cubejs.additional_meta.company_name)
              companyName = userData.data.user.cubejs.additional_meta.company_name
            if(userData.data.user.cubejs.additional_meta && userData.data.user.cubejs.additional_meta.property_ac)
              propertyAc = userData.data.user.cubejs.additional_meta.property_ac
            if(userData.data.user.cubejs.additional_meta && userData.data.user.cubejs.additional_meta.breakfast_start)
              breakfastStart = parseInt(userData.data.user.cubejs.additional_meta.breakfast_start)
            if(userData.data.user.cubejs.additional_meta && userData.data.user.cubejs.additional_meta.breakfast_end)
              breakfastEnd = parseInt(userData.data.user.cubejs.additional_meta.breakfast_end)
            if(userData.data.user.cubejs.additional_meta && userData.data.user.cubejs.additional_meta.lunch_start)
              lunchStart = parseInt(userData.data.user.cubejs.additional_meta.lunch_start)
            if(userData.data.user.cubejs.additional_meta && userData.data.user.cubejs.additional_meta.lunch_end)
              lunchEnd = parseInt(userData.data.user.cubejs.additional_meta.lunch_end)
            if(userData.data.user.cubejs.additional_meta && userData.data.user.cubejs.additional_meta.tea_start)
              teaStart = parseInt(userData.data.user.cubejs.additional_meta.tea_start)
            if(userData.data.user.cubejs.additional_meta && userData.data.user.cubejs.additional_meta.tea_end)
              teaEnd = parseInt(userData.data.user.cubejs.additional_meta.tea_end)
            if(userData.data.user.cubejs.additional_meta && userData.data.user.cubejs.additional_meta.dinner_start)
              dinnerStart = parseInt(userData.data.user.cubejs.additional_meta.dinner_start)
            if(userData.data.user.cubejs.additional_meta && userData.data.user.cubejs.additional_meta.dinner_end)
              dinnerEnd = parseInt(userData.data.user.cubejs.additional_meta.dinner_end)
            cubeJSToken = userData.data.user.cubejs.jwt
            cubeJSUrl = userData.data.user.cubejs.url
            expiredAt = new Date(parseInt(userData.data.user.cubejs.jwt_expired_at) * 1000)
            if(!rememberMe){
              expiredAt = new Date();
              expiredAt.setHours(expiredAt.getHours()+1)
            }
            for(let i=0; i< userData.data.user.cubejs.dashboard.length; i++){
              acl = set(acl, userData.data.user.cubejs.dashboard[i], 1);
            }
          }
          else{
            storeCodes = userData.data.user.cubejs_stores_code
            if(userData.data.user.cubejs_additional_meta && userData.data.user.cubejs_additional_meta.company_name)
              companyName = userData.data.user.cubejs_additional_meta.company_name
            if(userData.data.user.cubejs_additional_meta && userData.data.user.cubejs_additional_meta.property_ac)
              propertyAc = userData.data.user.cubejs_additional_meta.property_ac
            if(userData.data.user.cubejs_additional_meta && userData.data.user.cubejs_additional_meta.breakfast_start)
              breakfastStart = parseInt(userData.data.user.cubejs_additional_meta.breakfast_start)
            if(userData.data.user.cubejs_additional_meta && userData.data.user.cubejs_additional_meta.breakfast_end)
              breakfastEnd = parseInt(userData.data.user.cubejs_additional_meta.breakfast_end)
            if(userData.data.user.cubejs_additional_meta && userData.data.user.cubejs_additional_meta.lunch_start)
              lunchStart = parseInt(userData.data.user.cubejs_additional_meta.lunch_start)
            if(userData.data.user.cubejs_additional_meta && userData.data.user.cubejs_additional_meta.lunch_end)
              lunchEnd = parseInt(userData.data.user.cubejs_additional_meta.lunch_end)
            if(userData.data.user.cubejs_additional_meta && userData.data.user.cubejs_additional_meta.tea_start)
              teaStart = parseInt(userData.data.user.cubejs_additional_meta.tea_start)
            if(userData.data.user.cubejs_additional_meta && userData.data.user.cubejs_additional_meta.tea_end)
              teaEnd = parseInt(userData.data.user.cubejs_additional_meta.tea_end)
            if(userData.data.user.cubejs_additional_meta && userData.data.user.cubejs_additional_meta.dinner_start)
              dinnerStart = parseInt(userData.data.user.cubejs_additional_meta.dinner_start)
            if(userData.data.user.cubejs_additional_meta && userData.data.user.cubejs_additional_meta.dinner_end)
              dinnerEnd = parseInt(userData.data.user.cubejs_additional_meta.dinner_end)
            cubeJSToken = userData.data.user.cubejs_jwt
            cubeJSUrl = userData.data.user.cubejs_url
            expiredAt = new Date(parseInt(userData.data.user.cubejs_jwt_expired_at) * 1000)
            if(!rememberMe){
              expiredAt = new Date();
              expiredAt.setHours(expiredAt.getHours()+1)
            }
            for(let i=0; i< userData.data.user.cubejs_dashboard.length; i++){
              acl = set(acl, userData.data.user.cubejs_dashboard[i], 1);
            }
          }

          for(let i=0; i < storeCodes.length; i++) {
            storeCodes[i] = storeCodes[i].replace(/'/g, '');
          }
          for(let i=0; i < propertyAc.length; i++) {
            propertyAc[i] = propertyAc[i].replace(/'/g, '');
          }
          
          context2.expired = false
          context2.storeCodes = storeCodes
          context2.propertyAc = propertyAc
          context2.cubeJSToken = cubeJSToken
          context2.companyName = companyName
          context2.cubeJSUrl = cubeJSUrl
          context2.acl = acl
          context.cubejsApi = cubejs(context2.cubeJSToken, {
            apiUrl: `${context2.cubeJSUrl}/cubejs-api/v1`,
          });
          context2.expiredAt = expiredAt
          context2.login = true
          context2.breakfastStart = breakfastStart
          context2.breakfastEnd = breakfastEnd
          context2.lunchStart = lunchStart
          context2.lunchEnd = lunchEnd
          context2.teaStart = teaStart
          context2.teaEnd = teaEnd
          context2.dinnerStart = dinnerStart
          context2.dinnerEnd = dinnerEnd
          localStorage.setItem('rememberMe', rememberMe);
          localStorage.setItem('storeCodes', JSON.stringify(storeCodes));
          localStorage.setItem('propertyAc', JSON.stringify(propertyAc));
          localStorage.setItem('cubeJSToken', cubeJSToken);
          localStorage.setItem('companyName', companyName);
          localStorage.setItem('cubeJSUrl', cubeJSUrl);
          localStorage.setItem('login', true);
          localStorage.setItem('acl', JSON.stringify(acl));
          localStorage.setItem('expiredAt', expiredAt);
          localStorage.setItem('breakfastStart', breakfastStart);
          localStorage.setItem('breakfastEnd', breakfastEnd);
          localStorage.setItem('lunchStart', lunchStart);
          localStorage.setItem('lunchEnd', lunchEnd);
          localStorage.setItem('teaStart', teaStart);
          localStorage.setItem('teaEnd', teaEnd);
          localStorage.setItem('dinnerStart', dinnerStart);
          localStorage.setItem('dinnerEnd', dinnerEnd);

          history.push("/dashboard");
        })
        .catch(error => {
          console.log(error)
          setAuthenticating(false)
          setInternalError(true)
        })
      }
      else if(response.status === 401 || response.status === 422) {
        setAuthenticating(false)
        setWrongUsernamePassword(true)
      }
      else {
        setAuthenticating(false)
        setInternalError(true)
      }
    })
    .catch(error => {
      console.log(error)
      setAuthenticating(false)
      setWrongCompanyCode(true)
    })
  };

  return (
    [<Header />,
    <Container component="main" maxWidth="xs">
      {context2.expired && !wrongCompanyCode && !wrongUsernamePassword && 
        !internalError && !authenticating && (
          <Alert variant="filled" severity="error">
          {t('page.signIn.expired')}
        </Alert>
      )}
      { authenticating && (<Alert variant="filled" severity="info">
        {t('page.signIn.authenticating')}
      </Alert>)}
      {wrongCompanyCode && (<Alert variant="filled" severity="error">
        {t('page.signIn.wrongCompanyCode')}
      </Alert>)}
      {wrongUsernamePassword && (<Alert variant="filled" severity="error">
        {t('page.signIn.wrongUsernamePassword')}
      </Alert>)}
      {internalError && (<Alert variant="filled" severity="error">
        {t('page.signIn.internalError')}
      </Alert>)}
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          {t('page.signIn.signIn')}
        </Typography>
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="companyCode"
            label={t('page.signIn.companyCode')}
            name="companyCode"
            autoComplete="company-code"
            autoFocus
          />
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label={t('page.signIn.email')}
            name="email"
            autoComplete="email"
            autoFocus
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label={t('page.signIn.password')}
            type="password"
            id="password"
            autoComplete="current-password"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={rememberMe}
                onChange={triggerRemberMe}
                name="remember"
                color="primary"
              />
            }
            label={t('page.signIn.remember')}
          />
          <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
            {t('page.signIn.signIn')}
          </Button>
          <Grid container>
            <Grid item xs>
              <Link href="#" onClick={handleClickOpen} variant="body2">
                {t('page.signIn.forgotPassword')}?
              </Link>
              <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle id="alert-dialog-title">{t('page.signIn.forgotPassword')}</DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    {t('page.signIn.forgotPasswordDetail')}
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleClose} color="primary" autoFocus>
                    {t('page.signIn.ok')}
                  </Button>
                </DialogActions>
              </Dialog>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Copyright sx={{ mt: 8, mb: 4 }} />
    </Container>
    ]
  );
}