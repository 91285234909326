import DiscountBreakdownQueries from './DiscountBreakdownQueries'
import DayEndQueries from './DayEndQueries'

const mvOrdersSummary = {
  mvOrdersSummary: {
    ...DiscountBreakdownQueries,
    ...DayEndQueries
  }
}

export default mvOrdersSummary