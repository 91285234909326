import React from 'react';
import { useTranslation } from 'react-i18next';

const ItemLoading = ({ progress }) => {
  const { t } = useTranslation();
  const stage = progress?.stage?.stage;
  const timeElapsed = progress?.stage?.timeElapsed;

  return (
    <>
      { stage && stage }
      { timeElapsed && `... ${progress.stage.timeElapsed/1000} ${t('component.itemLoading.second')}`}
    </>
  )
};

export default ItemLoading;
