const DiscountBreakdownQueries = {
  DiscountBreakdown: {
    query: {
      "measures": [
        "OrderTransactionsDiscount.totalAmount",
        "OrderTransactionsDiscount.sumQuantity"
      ],
      "timeDimensions": [
      ],
      "order": [
        [
          "OrderTransactionsDiscount.storeCode",
          "asc"
        ],
        [
          "OrderTransactionsDiscount.discountCode",
          "asc"
        ]
      ],
      "filters": [
        {
          "member": "OrderTransactionsDiscount.orderState",
          "operator": "equals",
          "values": [
            "completed"
          ]
        },
        {
          "member": "OrderTransactionsDiscount.commerceChannelCode",
          "operator": "notEquals",
          "values": [
            "membership_upgrade"
          ]
        },
      ],
      "dimensions": [
        "OrderTransactionsDiscount.storeCode",
        "OrderTransactionsDiscount.discountCode",
        "OrderTransactionsDiscount.discountName"
      ],
      "limit": 50000,
      "renewQuery": true,
    }
  },
  DiscountBreakdownByStaff: {
    query: {
      "measures": [
        "OrderTransactionsDiscount.totalAmount",
        "OrderTransactionsDiscount.sumQuantity"
      ],
      "timeDimensions": [
      ],
      "order": [
        [
          "OrderTransactionsDiscount.storeCode",
          "asc"
        ],
        [
          "InternalAppOperators.staffNumber",
          "asc"
        ],
        [
          "OrderTransactionsDiscount.discountCode",
          "asc"
        ]
      ],
      "filters": [
        {
          "member": "OrderTransactionsDiscount.orderState",
          "operator": "equals",
          "values": [
            "completed"
          ]
        },
        {
          "member": "OrderTransactionsDiscount.commerceChannelCode",
          "operator": "notEquals",
          "values": [
            "membership_upgrade"
          ]
        },
        {
          "member": "InternalAppOperators.staffNumber",
          "operator": "set"
        },
      ],
      "dimensions": [
        "OrderTransactionsDiscount.storeCode",
        "InternalAppOperators.staffNumber",
        "InternalAppOperators.firstName",
        "InternalAppOperators.lastName",
        "OrderTransactionsDiscount.discountCode",
        "OrderTransactionsDiscount.discountName"
      ],
      "limit": 50000,
      "renewQuery": true,
    }
  }
}

export default DiscountBreakdownQueries