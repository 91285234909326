import React from "react";
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

let SelectComponentV2 = ({state, stateChanger, textLabel, selectArray, pageChangerArr, ...rest}) => {

  const handleChange = (event) => {
    stateChanger(event.target.value);
    if(pageChangerArr){
      for(let i = 0; i < pageChangerArr.length; i++){
        pageChangerArr[i](0)
      }
    }
  };

  return(
    <div style={{marginLeft: "24px", marginBottom: "20px"}}>
      <FormControl sx={{width: 200, marginTop: '20', marginBottom: '20', marginLeft: '30', marginRight: '30'}}>
        <InputLabel id={textLabel+"-select-label"}>{textLabel}</InputLabel>
        <Select
          labelId={textLabel+"-select-label"}
          id={textLabel+"-select"}
          value={state}
          onChange={handleChange}
          label={textLabel}
        >
          {selectArray.map((select) => (
            <MenuItem key={select.value} value={select.value}>
              {select.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  )
}

export default SelectComponentV2;