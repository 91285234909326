const NewMemberQueries = {
  NewMember: {
    query: {
      "measures": [
        "MvNewMember.sumCount",
      ],
      "timeDimensions": [
      ],
      "order": {},
      "dimensions": [],
      "filters": [],
      "limit": 50000,
      "renewQuery": true,
    }
  },
}

export default NewMemberQueries