import React, { useState } from "react";
import Header from '../../components/Header';
import TableComponent from '../../components/TableComponent';
import TextFieldComponent from '../../components/TextFieldComponent';
import Dashboard from '../../components/Dashboard';
import PageHeaderComponent from '../../components/PageHeaderComponent';
import OmnitechQueries from '../../queries/OmnitechQueries'
import { useTranslation } from 'react-i18next';
import { useCubeQuery } from '@cubejs-client/react';
import { format } from 'date-fns'

const applyFilter = (query, membershipCodeFilter, emailFilter, phoneFilter, userNameFilter) => {

  let membershipCodeFilterObj = {
    member: "Users.membershipCode",
    operator: "contains",
    values: [membershipCodeFilter]
  }


  if (membershipCodeFilter !== '') {
    query = {
      ...query,
      filters: (query.filters || []).concat(membershipCodeFilterObj)
    }
  }

  let emailFilterObj = {
    member: "Users.email",
    operator: "contains",
    values: [emailFilter]
  }


  if (emailFilter !== '') {
    query = {
      ...query,
      filters: (query.filters || []).concat(emailFilterObj)
    }
  }

  let phoneFilterObj = {
    member: "Users.phone",
    operator: "contains",
    values: [phoneFilter]
  }


  if (phoneFilter !== '') {
    query = {
      ...query,
      filters: (query.filters || []).concat(phoneFilterObj)
    }
  }

  let userNameFilterObj = {
    member: "Users.firstName",
    operator: "contains",
    values: [userNameFilter]
  }


  if (userNameFilter !== '') {
    query = {
      ...query,
      filters: (query.filters || []).concat(userNameFilterObj)
    }
  }

  return query
}

const RtUserDeviceRegisterQuery = (membershipCodeFilter, emailFilter, phoneFilter, userNameFilter, isRefresh, setRefresh) => {
  const { t } = useTranslation();
  let item = {
    name: t('page.rt.userDeviceRegister.name'),
    tableHeaders: {
      "Devices.lastDeviceCreatedAt":  { name: t('page.rt.userDeviceRegister.tableHeader.lastDeviceCreatedAt'), type: "dateTime" },
      "Devices.count":  { name: t('page.rt.userDeviceRegister.tableHeader.deviceCount'), type: "number" },
      "Users.membershipCode":  { name: t('page.rt.userDeviceRegister.tableHeader.membershipCode'), type: "string" },
      "Users.firstName":  { name: t('page.rt.userDeviceRegister.tableHeader.firstName'), type: "string" },
      "CustomerRanks.code":  { name: t('page.rt.userDeviceRegister.tableHeader.rankCode'), type: "string" },
      "Users.sex":  { name: t('page.rt.userDeviceRegister.tableHeader.sex'), type: "string" },
      "Users.email":  { name: t('page.rt.userDeviceRegister.tableHeader.email'), type: "string" },
      "Users.phone":  { name: t('page.rt.userDeviceRegister.tableHeader.phone'), type: "string" },
      "Users.dateOfBirth":  { name: t('page.rt.userDeviceRegister.tableHeader.dateOfBirth'), type: "date" },
      "Users.isActive":  { name: t('page.rt.userDeviceRegister.tableHeader.isActive'), type: "string" },
      "Users.isDeleted":  { name: t('page.rt.userDeviceRegister.tableHeader.isDeleted'), type: "string" },
      "Users.remark":  { name: t('page.rt.userDeviceRegister.tableHeader.remark'), type: "string" },
      "Users.createdAt":  { name: t('page.rt.userDeviceRegister.tableHeader.createdAt'), type: "dateTime" },
      "Users.updatedAt":  { name: t('page.rt.userDeviceRegister.tableHeader.updatedAt'), type: "dateTime" },
      "Users.modifiedAt":  { name: t('page.rt.userDeviceRegister.tableHeader.modifiedAt'), type: "dateTime" }
    },
    grid: {xs: 12, lg: 12},
    export: { allowExport: true, filename: 'rt_user_device_register_export_' + format(new Date(), 'yyyyMMddHHmmss') }
  }
  let query = {
    ...OmnitechQueries.users.RtDeviceRegister.query
  }
  query = applyFilter(query, membershipCodeFilter, emailFilter, phoneFilter, userNameFilter)
  if(isRefresh)
    query = {}
  const result = useCubeQuery(query, {resetResultSetOnChange: true})
  if(isRefresh)
    setRefresh(false)
  return { item, result }
}

const RtUserDeviceRegisterPage = () => {
  const { t } = useTranslation();
  const [ membershipCodeFilter, setMembershipCodeFilter ] = useState('');
  const [ emailFilter, setEmailFilter ] = useState('');
  const [ phoneFilter, setPhoneFilter ] = useState('');
  const [ userNameFilter, setUserNameFilter ] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  const [ refresh1, setRefresh1 ] = useState(false);

  const { item, result }  = RtUserDeviceRegisterQuery(membershipCodeFilter, emailFilter, phoneFilter, userNameFilter, refresh1, setRefresh1)

  const refresh = () => {
    setRefresh1(true)
    setPage(0)
  }

  return ([
    <Header />,
    <PageHeaderComponent onClickFunc={refresh} refreshlabel={t('component.refresh')} headerTitle={t('component.header.menu.rtUserDeviceRegisterReport')} />,
    <Dashboard>
      <TextFieldComponent id={"membership_code_filter"} state={membershipCodeFilter} pageChangerArr={[setPage]}
        stateChanger={setMembershipCodeFilter} textLabel={t('page.rt.userDeviceRegister.filter.membershipCode')}/>
      <TextFieldComponent id={"email_filter"} state={emailFilter} pageChangerArr={[setPage]}
        stateChanger={setEmailFilter} textLabel={t('page.rt.userDeviceRegister.filter.email')}/>
      <TextFieldComponent id={"phone_filter"} state={phoneFilter} pageChangerArr={[setPage]}
        stateChanger={setPhoneFilter} textLabel={t('page.rt.userDeviceRegister.filter.phone')}/>
      <TextFieldComponent id={"name_filter"} state={userNameFilter} pageChangerArr={[setPage]}
        stateChanger={setUserNameFilter} textLabel={t('page.rt.userDeviceRegister.filter.userName')}/>
    </Dashboard>
    ,
    <Dashboard>
      <TableComponent item={item} result={result} rowsPerPage={rowsPerPage} 
        setRowsPerPage={setRowsPerPage} page={page} setPage={setPage}/>
    </Dashboard>
  ]) 
};

export default RtUserDeviceRegisterPage;