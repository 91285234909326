const OrderEntriesAuditTrailQueries = {
  OrderEntriesAuditTrail:{
    query: {
      "order": [
        [
          "OrderTransactionsAudit.orderId",
          "asc"
        ],
        [
          "OrderTransactionsAudit.lv1Ordering",
          "asc"
        ],
        [
          "OrderTransactionsAudit.otGroupId",
          "asc"
        ],
        [
          "OrderTransactionsAudit.lv2Ordering",
          "asc"
        ]
      ],
      "dimensions": [
        "OrderTransactionsAudit.orderId",
        "OrderTransactionsAudit.otGroupId",
        "OrderTransactionsAudit.lv1Ordering",
        "OrderTransactionsAudit.lv2Ordering",
        "OrderTransactionsAudit.referenceNumber",
        "OrderTransactionsAudit.storeId",
        "OrderTransactionsAudit.testOrder",
        "OrderTransactionsAudit.shipmentStoreId",
        "OrderTransactionsAudit.shipFromStoreCode",
        "OrderTransactionsAudit.commerceChannelCode",
        "OrderTransactionsAudit.orderState",
        "OrderTransactionsAudit.orderCompletedAt",
        "OrderTransactionsAudit.cartCreatedAt",
        "OrderTransactionsAudit.cartClosedAt",
        "OrderTransactionsAudit.orderTransactionsId",
        "OrderTransactionsAudit.internalAppOperatorId",
        "OrderTransactionsAudit.internalAppOperatorStaffNumber",
        "OrderTransactionsAudit.internalAppOperatorFirstName",
        "OrderTransactionsAudit.internalAppOperatorLastName",
        "OrderTransactionsAudit.unitAmount",
        "OrderTransactionsAudit.quantity",
        "OrderTransactionsAudit.lineTotal",
        "OrderTransactionsAudit.code",
        "OrderTransactionsAudit.calculateUnitAmountTargetType",
        "OrderTransactionsAudit.calculateUnitAmountTargetId",
        "OrderTransactionsAudit.couponTokenId",
        "OrderTransactionsAudit.couponCode",
        "OrderTransactionsAudit.skuCode",
        "OrderTransactionsAudit.productTitleZh",
        "OrderTransactionsAudit.productTitleEn",
        "OrderTransactionsAudit.orderLinePropertyUserId",
        "OrderTransactionsAudit.orderLinePropertyStaffNumber",
        "OrderTransactionsAudit.olpOtId",
        "OrderTransactionsAudit.omcdolpId",
        "OrderTransactionsAudit.omcdId",
        "OrderTransactionsAudit.omcdOrderLevelId",
        "OrderTransactionsAudit.itemCreatedAt",
      ],
      "limit": 50000,
      "renewQuery": true,
    }
  }
}

export default OrderEntriesAuditTrailQueries