const commonZhHK = {
  "exportButton": {
    "csv": "CSV",
    "export": "匯出",
    "txt": "TXT",
    "xlsx": "Excel"
  },    
  "header": {
    "lanuage": "中文 (香港)",  
    "menu": {
      "dailyOperation": "日常運營報告 (實時)",
      "dailyOperationPrint": "日常運營報告 (列印) (實時)",
      "dailyOperationHist": "日常運營報告 (歷史)",
      "dailyOperationHistPrint": "日常運營報告 (列印) (歷史)",
      "discountBreakdownReport": "折扣明細報告 (歷史)",
      "discountBreakdownByOrderReport": "訂單折扣明細報告",
      "discountBreakdownByItemReport": "物品折扣明細報告",
      "discountBreakdownByCommerceChannelReport": "商業頻道折扣明細報告",
      "discountBreakdownByStaffReport": "員工折扣明細報告 (實時)",
      "rtUserDeviceRegisterReport": "用戶設備註冊報告",
      "rtTakeawayDeliveryOrderApprovalTimeTraceReport": "外賣配送訂單審批時間追溯報告",
      "membershipPurchaseExporter": "會員購買記錄報告",
      "voidItemDetailHistReport": "取消物品詳情報告 (歷史)",
      "totalFoodGrossSalesReport": "總食品銷售總額報告",
      "orderEntriesAuditTrailReport": "訂單項目審計追踪報告",
      "orderSettlementReport": "訂單結算報告",
      "storeStaffSalesPerformanceSummary": "分店員工銷售業績總結",
      "rtDiscountRelationsChecking": "折扣關係檢查",
      "rtBeveragesSales": "飲品銷售總額報告",
      "skuSalesDetailsHist": "SKU銷售明細報告 (歷史)",
      "skuSalesDetails": "SKU銷售明細報告 (實時)",
      "timePeriodSummaryHist": "時段銷售報告 (歷史)",
      "timePeriodSummary": "時段銷售報告 (實時)",
      "paymentByStoreTypeBreakdown": "商店和支付類型明細付款報告",
      "outstandingTable": "未完成檯報告",
      "voidItemDetailReport": "取消物品詳情報告 (實時)",
      "staffStatistics": "員工統計報告",
      "staffTenderSummary": "員工收取付款摘要報告",
      "rtUserExport": "會員匯出",
      "userExport": "會員匯出",
      "rtUserQuery": "會員查詢",
      "userQuery": "會員查詢",
      "membershipCount": "會員數量",
      "staffAttendance": "員工出勤記錄",
      "currentInventoryBalance": "當前庫存記錄",
      "supplierSummary":  "供應商總結報告",
      "perDayReport": "每日運營總結報告",
      "rsmChecking": "RSM 檢查",
      "rsmrChecking": "RSMR 檢查",
      "discountBreakdownByStaffHistReport": "員工折扣明細報告 (歷史)",
      "skuChecking": "SKU 檢查",
      "fsDailyOperation": "日常運營報告 (實時)",
      "dailySales": "每日銷售報告",
      "rtOrderUserExport": "RT按用戶關聯的商店訂購匯出",
      "orderUserExport": "按用戶關聯的商店訂購匯出",
      "paymentDayEnd": "支付類型每日結算報告",
      "newMember": "新會員統計",
      "PMCampaignRate": "活動推送消息讀取率統計",
      "memberSpending": "會員消費統計",
      "activeMember": "活躍會員統計",
      "memberStore": "會員分店消費統計",
      "couponUsage": "優惠卷使用統計",
      "discountBreakdownByMemberReport": "會員折扣明細報告",
      "cartDiscountChecking": "購物車折扣檢查",
      "cheDailyOperation": "零售日常運營報告 (實時)",
      "cheDailyOperationFnb": "餐飲日常運營報告 (實時)",
      "skuSalesDetailsCHEHist": "SKU銷售明細報告 (歷史)",
      "mPosSelfOrder": "職員點餐VS自助點餐",
      "discountBreakdownCHEReport": "折扣明細報告 (歷史)",
      "kstDailyOperation": "零售日常運營報告 (實時)",
      "kstDailyOperationFnb": "餐飲日常運營報告 (實時)",
      "skuSalesDetailsKSTHist": "SKU銷售明細報告 (歷史)",
      "discountBreakdownKSTReport": "折扣明細報告 (歷史)",
      "couponUsageByDateRange": "優惠卷使用統計 (按日期範圍)",
      "stocktakeReport": "庫存盤點報告",
      "inventoryAdjustmentReport": "庫存調整報告",
      "repeatedMembers": "會員重複消費報告",
      "cartAuditTrailReport": "購物車審計追踪報告"
    },
    "title": "Omnilytics",
    "titleMobile": "Omnilytics",
    "fnbReal": "餐飲 (實時)",
    "fnbHist": "餐飲 (歷史)",
    "rt": "Ruby Tuesday",
    "fs": "友心",
    "che": "尚廚坊",
    "kst": "韓嘢",
    "supplier": "供應商",
    "membership": "會員統計",
    "stocktake": "庫存盤點",
    "yes": "是",
    "no": "否",
    "logout": "登出",
    "logoutDetail": "你確定登出?",
    "searchInMenu": "搜索菜單",
    "searchNoResult": "抱歉，沒有匹配菜單項目",
  },
  "itemLoading": {
    "second": "秒",
  },
  "lastUpdatedAt": "最後更新",
  "refresh": "刷新",
  "day": "日",
  "month": "月",
  "year": "年",
  "dateGranularity": "日期",
  "table": {
    "rowPerPage": "每頁行數",
    "displayRows": "的",
    "loading": "加載中...",
    "second": " 秒",
    "sunday": "星期日",
    "monday": "星期一",
    "tuesday": "星期二",
    "wednesday": "星期三",
    "thursday": "星期四",
    "friday": "星期五",
    "saturday": "星期六",
    "noRecordFound": "沒有紀錄",
  },
  "text": {
    "historical": "為提升效能，數據延遲 1 天； 每天凌晨 3:30 更新數據",
    "longQuery": "由於計算複雜，這份報告需要超過一分鐘計算",
    "paymentBreakdownMapping": "支付供應商編號對照",
    "mobileIgnoreStore": "所有有關網上渠道紀錄將會無視分店編號篩選"
  },
}

export default commonZhHK