import React, { useState, useContext } from "react";
import Header from '../../components/Header';
import TableComponent from '../../components/TableComponent';
import MultiSelectComponent from '../../components/MultiSelectComponent';
import DateRangePickerComponent from '../../components/DateRangePickerComponent';
import Dashboard from '../../components/Dashboard';
import PageHeaderComponent from '../../components/PageHeaderComponent';
import { CubeJSContext } from '../../context/CubeJSProvider';
import OmnitechQueries from '../../queries/OmnitechQueries'
import { useTranslation } from 'react-i18next';
import { useCubeQuery } from '@cubejs-client/react';
import { format } from 'date-fns'

const applyFilter = (query, storeFilter, storeMember, stafffMember) => {
  let storeCodeFilterObj = {
    member: storeMember,
    operator: "contains",
    values: storeFilter
  }

  query = {
    ...query,
    filters: (query.filters || []).concat(storeCodeFilterObj)
  }

  let staffFilterObj = {
    member: stafffMember,
    operator: "notEquals",
    values: ["SSTST2021a","SKCB2021a","SKCL2021a",
      "SKCL2021","SKCB2021","S444","TFG011","S445",
      "SSTST2021","TFG012","SKPE2021","0000","SKPE2021a"]
  }

   query = {
      ...query,
      filters: (query.filters || []).concat(staffFilterObj)
    }

  return query
}

const StaffAttendanceQuery = (storeFilter, startDateFilter, endDateFilter, isRefresh, setRefresh) => {
  const { t } = useTranslation();
  if(startDateFilter == null){
    startDateFilter = new Date()
    startDateFilter.setHours(0,0,0,0);
    endDateFilter = new Date()
    endDateFilter.setHours(23,59,59,999);
  }
  let item = {
    name: t('page.fnb.StaffAttendancePage.StaffAttendanceName'),
    tableHeaders: {
      "StaffAttendance.storeCode": { name: t('page.fnb.StaffAttendancePage.titleMapping.storeCode'), type: 'string'},
      "StaffAttendance.staffNumber": { name: t('page.fnb.StaffAttendancePage.titleMapping.staffNumber'), type: 'string'},
      "StaffAttendance.staffFirstName": { name: t('page.fnb.StaffAttendancePage.titleMapping.staffFirstName'), type: 'string'},
      "StaffAttendance.staffLastName":  { name: t('page.fnb.StaffAttendancePage.titleMapping.staffLastName'), type: "string" },
      "StaffAttendance.onDutyStartAt": { name: t('page.fnb.StaffAttendancePage.titleMapping.onDutyStartAt'), type: 'dateTime'},
      "StaffAttendance.onDutyEndAt": { name: t('page.fnb.StaffAttendancePage.titleMapping.onDutyEndAt'), type: 'dateTime'},
      "StaffAttendance.dutyTime": { name: t('page.fnb.StaffAttendancePage.titleMapping.dutyTime'), type: 'string'},
    },
    grid: {xs: 12},
    export: { allowExport: true, filename: 'staff_attendance_export_' + format(startDateFilter, 'yyyyMMddHHmmss') +
      '_' + format(endDateFilter, 'yyyyMMddHHmmss') }
  }
  let query = {
    ...OmnitechQueries.staffAttendance.StaffAttendance.query,
    "timeDimensions": [
      {
        "dimension": "StaffAttendance.onDutyStartAt",
        "dateRange": [ startDateFilter, endDateFilter ]
      }
    ],
  }
  query = applyFilter(query, storeFilter, "StaffAttendance.storeCode", "StaffAttendance.staffNumber")
  if(isRefresh)
    query = {}
  const result = useCubeQuery(query, {resetResultSetOnChange: true})
  if(isRefresh)
    setRefresh(false)
  return { item, result }
}

const StaffAttendanceByDayQuery = (storeFilter, startDateFilter, endDateFilter, isRefresh, setRefresh) => {
  const { t } = useTranslation();
  if(startDateFilter == null){
    startDateFilter = new Date()
    startDateFilter.setHours(0,0,0,0);
    endDateFilter = new Date()
    endDateFilter.setHours(23,59,59,999);
  }
  let item = {
    name: t('page.fnb.StaffAttendancePage.StaffAttendanceByDayName'),
    tableHeaders: {
      "StaffAttendanceByDay.date": { name: t('page.fnb.StaffAttendancePage.titleMapping.date'), type: 'date'},
      "StaffAttendanceByDay.storeCode": { name: t('page.fnb.StaffAttendancePage.titleMapping.storeCode'), type: 'string'},
      "StaffAttendanceByDay.staffNumber": { name: t('page.fnb.StaffAttendancePage.titleMapping.staffNumber'), type: 'string'},
      "StaffAttendanceByDay.staffFirstName": { name: t('page.fnb.StaffAttendancePage.titleMapping.staffFirstName'), type: 'string'},
      "StaffAttendanceByDay.staffLastName":  { name: t('page.fnb.StaffAttendancePage.titleMapping.staffLastName'), type: "string" },
      "StaffAttendanceByDay.onDutyStartAt": { name: t('page.fnb.StaffAttendancePage.titleMapping.onDutyStartAt'), type: 'dateTime'},
      "StaffAttendanceByDay.onDutyEndAt": { name: t('page.fnb.StaffAttendancePage.titleMapping.onDutyEndAt'), type: 'dateTime'},
      "StaffAttendanceByDay.dutyTime": { name: t('page.fnb.StaffAttendancePage.titleMapping.dutyTime'), type: 'string'},
    },
    grid: {xs: 12},
    export: { allowExport: true, filename: 'staff_attendance_by_day_export_' + format(startDateFilter, 'yyyyMMddHHmmss') +
      '_' + format(endDateFilter, 'yyyyMMddHHmmss') }
  }
  let query = {
    ...OmnitechQueries.staffAttendance.StaffAttendanceByDay.query,
    "timeDimensions": [
      {
        "dimension": "StaffAttendanceByDay.onDutyStartAt",
        "dateRange": [ startDateFilter, endDateFilter ]
      }
    ],
  }
  query = applyFilter(query, storeFilter, "StaffAttendanceByDay.storeCode", "StaffAttendanceByDay.staffNumber")
  if(isRefresh)
    query = {}
  const result = useCubeQuery(query, {resetResultSetOnChange: true})
  if(isRefresh)
    setRefresh(false)
  return { item, result }
}

const StaffAttendancePage = () => {
  const { t } = useTranslation();
  const context = useContext(CubeJSContext);
  let storeCodeSelect = []
  if(context.storeCodes)
    storeCodeSelect = context.storeCodes
  const [ storeFilter, setStoreFilter ] = useState([]);
  let filterStartDate = new Date()
  filterStartDate.setHours(0,0,0,0);
  let filterEndDate = new Date()
  filterEndDate.setHours(23,59,59,999);
  const [dateRangeFilter, setDateRangeFilter] = useState([filterStartDate, filterEndDate])
  const [startDateFilter, endDateFilter] = dateRangeFilter
  const [rowsPerPage1, setRowsPerPage1] = useState(10);
  const [page1, setPage1] = useState(0);
  const [rowsPerPage2, setRowsPerPage2] = useState(10);
  const [page2, setPage2] = useState(0);

  const [ refresh1, setRefresh1 ] = useState(false);
  const [ refresh2, setRefresh2 ] = useState(false);

  const query1  = StaffAttendanceQuery(storeFilter, startDateFilter, endDateFilter, refresh1, setRefresh1)
  const query2  = StaffAttendanceByDayQuery(storeFilter, startDateFilter, endDateFilter, refresh2, setRefresh2)

  const refresh = () => {
    setRefresh1(true)
    setRefresh2(true)
    setPage1(0)
    setPage2(0)
  }
  
  return ([
    <Header />,
    <PageHeaderComponent onClickFunc={refresh} refreshlabel={t('component.refresh')} headerTitle={t('component.header.menu.staffAttendance')} />,
    <Dashboard>
      <MultiSelectComponent id={"store_code_filter"} state={storeFilter} pageChangerArr={[setPage1,setPage2]}
          stateChanger={setStoreFilter} textLabel={t('page.fnb.StaffAttendancePage.filter.store.name')} selectArray={storeCodeSelect}/>
      <DateRangePickerComponent state={dateRangeFilter} stateChanger={setDateRangeFilter} 
        pageChangerArr={[setPage1,setPage2]}
        startDateLabel={t('page.fnb.StaffAttendancePage.filter.onDutyStartAt.startDate')} 
        toLabel={t('page.fnb.StaffAttendancePage.filter.onDutyStartAt.to')} 
        endDateLabel={t('page.fnb.StaffAttendancePage.filter.onDutyStartAt.endDate')}/>
    </Dashboard>,
    <Dashboard>
      <TableComponent item={query1.item} result={query1.result} rowsPerPage={rowsPerPage1} 
        setRowsPerPage={setRowsPerPage1} page={page1} setPage={setPage1}/>
      <TableComponent item={query2.item} result={query2.result} rowsPerPage={rowsPerPage2} 
        setRowsPerPage={setRowsPerPage2} page={page2} setPage={setPage2}/>
    </Dashboard>
  ]) 
};

export default StaffAttendancePage;