const MemberStoreQueries = {
  memberStore: {
    query: {
      "measures": [
        "MvMemberStore.averageMembersCount",
        "MvMemberStore.averageNonMembersCount",
        "MvMemberStore.averageMembersRatio",
        "MvMemberStore.averageNonMembersRatio"
      ],
      "timeDimensions": [
      ],
      "order": {},
      "dimensions": [
        "MvMemberStore.shipFromStoreCode",
      ],
      "filters": [],
      "limit": 50000,
      "renewQuery": true,
    }
  },
  memberStoreCount: {
    query: {
      "measures": [
        "MvMemberStore.averageMembersCount",
        "MvMemberStore.averageNonMembersCount",
      ],
      "timeDimensions": [
      ],
      "order": {},
      "dimensions": [
        "MvMemberStore.shipFromStoreCode",
      ],
      "filters": [],
      "limit": 50000,
      "renewQuery": true,
    }
  },
  memberStoreRatio: {
    query: {
      "measures": [
        "MvMemberStore.averageMembersRatio",
        "MvMemberStore.averageNonMembersRatio"
      ],
      "timeDimensions": [
      ],
      "order": {},
      "dimensions": [
        "MvMemberStore.shipFromStoreCode",
      ],
      "filters": [],
      "limit": 50000,
      "renewQuery": true,
    }
  },
  memberCount: {
    query: {
      "measures": [
        "MvMemberStore.averageMembersCount",
        "MvMemberStore.averageNonMembersCount",
      ],
      "timeDimensions": [
      ],
      "order": {},
      "dimensions": [
      ],
      "filters": [],
      "limit": 50000,
      "renewQuery": true,
    }
  },
  member: {
    query: {
      "measures": [
        "MvMemberStore.averageMembersCount",
        "MvMemberStore.averageNonMembersCount",
        "MvMemberStore.averageMembersRatio",
        "MvMemberStore.averageNonMembersRatio"
      ],
      "timeDimensions": [
      ],
      "order": {},
      "dimensions": [
      ],
      "filters": [],
      "limit": 50000,
      "renewQuery": true,
    }
  },
  memberRatio: {
    query: {
      "measures": [
       "MvMemberStore.averageMembersRatio",
        "MvMemberStore.averageNonMembersRatio"
      ],
      "timeDimensions": [
      ],
      "order": {},
      "dimensions": [
      ],
      "filters": [],
      "limit": 50000,
      "renewQuery": true,
    }
  },
}

export default MemberStoreQueries