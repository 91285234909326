const commonEnHK = {
  stockTakeReportPage: {
    filter: {
      createdAt: {
        startDate: "Stocktake Start Date",
        to: "To",
        endDate: "Stocktake End Date"
      }
    },
    stockTake: {
      name: "Stocktake Overview",
      tableHeader: {
        id: "ID",
        storeCode: "Store Code",
        referenceNumber: "Reference Number",
        skuInventoryType: "SKU Inventory Type",
        state: "State",
        staffNumber: "Staff Number",
        firstName: "First Name",
        lastName: "Last Name",
        createdAt: "Created At",
        waitingForComparisonAt: "Waiting For Comparison At",
        comparingBalanceAt: "Comparing Balance At",
        completedAt: "Completed At",
        skuInventoryAdjustmentId: "SKU Inventory Adjustment ID",
        stocktakeReferenceNumber: "Stocktake Session Reference Number",
        adjustmentReferenceNumber: "SKU Inventory Adjustment Reference Number",
      }
    },
    stockTakeLine: {
      name: "Stocktake Detail",
      tableHeader: {
        stockTakeSessionId: "Stocktake Session ID",
        stocktakeReferenceNumber: "Stocktake Session Reference Number",
        adjustmentReferenceNumber: "SKU Inventory Adjustment Reference Number",
        skuCode: "SKU Code",
        productTitleEn: "Product Title(English)",
        productTitleZh: "Product Title(Chinese)",
        quantity: "Quantity",
        snapshotQuantity: "Snapshot Quantity",
        quantityMovement: "Quantity Movement",
        skuMeasure: "SKU Measure",
        snapshotSkuMeasure: "Snapshot SKU Measure",
        skuMeasureMovement: "SKU Measure Movement",
        skuMeasurementCode: "Measure",
        state: "State",
        skuInventoryAdjustmentId: "SKU Inventory Adjustment ID",
        processedAt: "Processed At"
      }
    },
    skuInventoryAdjustment: {
      name: "SKU Inventory Adjustment",
      tableHeader: {
        storeCode: "Store Code",
        state: "State",
        staffNumber: "Staff Number",
        firstName: "First Name",
        lastName: "Last Name",
        userType: "User Type",
        stocktakeReferenceNumber: "Stocktake Session Reference Number",
        adjustmentReferenceNumber: "SKU Inventory Adjustment Reference Number",
        skuCode: "SKU Code",
        productTitleEn: "Product Title(English)",
        productTitleZh: "Product Title(Chinese)",
        quantityMovement: "Quantity Movement",
        skuMeasureMovement: "SKU Measure Movement",
        skuMeasurementCode: "Measure",
        createdAt: "Created At",
        queueingProcessAt: "Queueing Process At",
        processingAt: "Processing At",
        processedAt: "Processed At",
        remark: "Remark"
      }
    }
  },
  skuInventoryAdjustmentReportPage: {
    filter: {
      createdAt: {
        startDate: "Inventory Adjustment Start Date",
        to: "To",
        endDate: "Inventory Adjustment End Date"
      },
      state: {
        name: "State"
      },
      storeCode: {
        name: "Store Code"
      }
    },
    skuInventoryAdjustment: {
      name: "SKU Inventory Adjustment",
      tableHeader: {
        storeCode: "Store Code",
        state: "State",
        staffNumber: "Staff Number",
        firstName: "First Name",
        lastName: "Last Name",
        userType: "User Type",
        stocktakeReferenceNumber: "Stocktake Session Reference Number",
        adjustmentReferenceNumber: "SKU Inventory Adjustment Reference Number",
        skuCode: "SKU Code",
        productTitleEn: "Product Title(English)",
        productTitleZh: "Product Title(Chinese)",
        quantityMovement: "Quantity Movement",
        skuMeasureMovement: "SKU Measure Movement",
        skuMeasurementCode: "Measure",
        createdAt: "Created At",
        queueingProcessAt: "Queueing Process At",
        processingAt: "Processing At",
        processedAt: "Processed At",
        remark: "Remark"
      }
    }
  }
}

export default commonEnHK