const commonEnHK = {
  "exportButton": {
    "csv": "CSV",
    "export": "Export",
    "txt": "TXT",
    "xlsx": "Excel"
  },
  "header": {
    "lanuage": "English (Hong Kong)",
    "menu": {
      "dailyOperation": "Daily Operation Report (Realtime)",
      "dailyOperationPrint": "Daily Operation Report (Print) (Realtime)",
      "dailyOperationHist": "Daily Operation Report (Historical)",
      "dailyOperationHistPrint": "Daily Operation Report (Print) (Historical)",
      "discountBreakdownReport": "Discount Breakdown Report (Historical)",
      "discountBreakdownByOrderReport": "Discount Breakdown By Order Report",
      "discountBreakdownByItemReport": "Discount Breakdown By Item Report",
      "discountBreakdownByCommerceChannelReport": "Discount Breakdown By Commerce Channel Report",
      "discountBreakdownByStaffReport": "Discount Breakdown By Staff Report (Realtime)",
      "rtUserDeviceRegisterReport": "User Device Register Report",
      "rtTakeawayDeliveryOrderApprovalTimeTraceReport": "Takeaway Delivery Order Approval Time Trace Report",
      "membershipPurchaseExporter": "Membership Purchase Exporter",
      "voidItemDetailHistReport": "Void Item Detail Report (Historical)",
      "totalFoodGrossSalesReport": "Total Food Gross Sales Report",
      "orderEntriesAuditTrailReport": "Order Entries Audit Trail Report",
      "orderSettlementReport": "Order Settlement Report",
      "storeStaffSalesPerformanceSummary": "Store Staff Sales Performance Summary",
      "rtDiscountRelationsChecking": "Discount Relations Checking",
      "rtBeveragesSales": "Beverages Sales Report",
      "skuSalesDetailsHist": "SKU Sales Detail (Historical)",
      "skuSalesDetails": "SKU Sales Detail (Realtime)",
      "timePeriodSummaryHist": "Time Period Summary (Historical)",
      "timePeriodSummary": "Time Period Summary (Realtime)",
      "paymentByStoreTypeBreakdown": "Payment By Store and Type Breakdown",
      "outstandingTable": "Outstanding Table",
      "voidItemDetailReport": "Void Item Detail Report (Realtime)",
      "staffStatistics": "Staff Statistics",
      "staffTenderSummary": "Staff Tender Summary",
      "rtUserExport": "User Export",
      "userExport": "User Export",
      "rtUserQuery": "User Query",
      "userQuery": "User Query",
      "membershipCount": "Membership Count",
      "staffAttendance": "Staff Attendance",
      "currentInventoryBalance": "Current Inventory Balance",
      "supplierSummary": "Supplier Summary",
      "perDayReport": "Per Day Report",
      "rsmChecking": "RSM Checking",
      "rsmrChecking": "RSMR Checking",
      "discountBreakdownByStaffHistReport": "Discount Breakdown By Staff Report (Historical)",
      "skuChecking": "SKU Checking",
      "fsDailyOperation": "Daily Operation Report (Realtime)",
      "dailySales": "Daily Sales Report",
      "rtOrderUserExport": "RT Order Export By Store With User Association",
      "orderUserExport": "Order Export By Store With User Association",
      "paymentDayEnd": "Payment Day End Report",
      "newMember": "New Members",
      "PMCampaignRate": "Push Message Campaign Read Rate",
      "memberSpending": "Member Spending Report",
      "activeMember": "Active Member Report",
      "memberStore": "Members Spend Store Report",
      "couponUsage": "Coupon Usage Report",
      "discountBreakdownByMemberReport": "Discount Breakdown By Member Report",
      "cartDiscountChecking": "Cart Discount Checking",
      "cheDailyOperation": "Retail Daily Operation Report (Realtime)",
      "cheDailyOperationFnb": "Food & Beverage Daily Operation Report (Realtime)",
      "skuSalesDetailsCHEHist": "SKU Sales Detail (Historical)",
      "mPosSelfOrder": "mPOS VS Self Order By Store",
      "discountBreakdownCHEReport": "Discount Breakdown Report (Historical)",
      "kstDailyOperation": "Retail Daily Operation Report (Realtime)",
      "kstDailyOperationFnb": "Food & Beverage Daily Operation Report (Realtime)",
      "skuSalesDetailsKSTHist": "SKU Sales Detail (Historical)",
      "discountBreakdownKSTReport": "Discount Breakdown Report (Historical)",
      "couponUsageByDateRange": "Coupon Usage By Date Range",
      "stocktakeReport": "Stocktake Report",
      "inventoryAdjustmentReport": "Inventory Adjustment Report",
      "repeatedMembers": "Repeated Members Report",
      "cartAuditTrailReport": "Cart Audit Trail Report"
    },
    "title": "Omnilytics",
    "titleMobile": "Omnilytics",
    "fnbReal": "Food & Beverage (Realtime)",
    "fnbHist": "Food & Beverage (Historical)",
    "rt": "Ruby Tuesday",
    "fs": "FriendSmart",
    "che": "Chef's Market",
    "kst": "K STUFF",
    "supplier": "Supplier",
    "membership": "Member Statistic",
    "stocktake": "Stocktake",
    "yes": "YES",
    "no": "NO",
    "logout": "Logout",
    "logoutDetail": "Are you sure logout?",
    "searchInMenu": "Search in menu",
    "searchNoResult": "Sorry, no matches"
  },
  "itemLoading": {
    "second": "Seconds",
  },
  "lastUpdatedAt": "Last Update",
  "refresh": "Refresh",
  "day": "Day",
  "month": "Month",
  "year": "Year",
  "dateGranularity": "Date Granularity",
  "table": {
    "rowPerPage": "Rows Per Page",
    "displayRows": "of",
    "loading": "Loading...",
    "second": " Seconds",
    "sunday": "Sunday",
    "monday": "Monday",
    "tuesday": "Tuesday",
    "wednesday": "Wednesday",
    "thursday": "Thursday",
    "friday": "Friday",
    "saturday": "Saturday",
    "noRecordFound": "No Record Found",
  },
  "text": {
    "historical": "Data Delay for 1 Day for performance reason; Data refresh at 3:30am everyday",
    "longQuery": "Due to complicated calculation, this report takes more than one minute to compute",
    "paymentBreakdownMapping": "Paymenet Provider Code Mapping",
    "mobileIgnoreStore": "All record related to mobile channel will ignore store code filter"
  }
}


export default commonEnHK