const OutstandingTableQueries = {
  OutstandingTableDetail:{
    query: {
      "measures": [
        "CartTransactions.sumLineTotal"
      ],
      "timeDimensions": [
      ],
      "order": {
        "Carts.storeCode": "asc",
        "Carts.dineInTableNumber": "asc",
        "Carts.dineInTableSubNumber": "asc"
      },
      "filters": [
        {
          "member": "Carts.state",
          "operator": "equals",
          "values": [
            "isolated"
          ]
        },
        {
          "and": [
            {
              "member": "Carts.dineInTableNumber",
              "operator": "notContains",
              "values": [
                "test"
              ]
            },
            {
            "member": "Carts.dineInTableNumber",
              "operator": "set"
            }
          ]
        },
        {
          "member": "Carts.commerceChannelCode",
          "operator": "equals",
          "values": [
            "dine_in_pos_hkd",
            "dine_in_hkd"
          ]
        }
      ],
      "dimensions": [
        "Carts.storeCode",
        "Carts.dineInTableNumber",
        "Carts.dineInTableSubNumber",
        "InternalAppOperators.staffNumber",
        "InternalAppOperators.firstName",
        "InternalAppOperators.lastName"
      ],
      "limit": 50000,
      "renewQuery": true,
    }
  },
  OutstandingTableNetSales:{
    query: {
      "measures": [
        "CartTransactions.sumLineTotal"
      ],
      "timeDimensions": [
      ],
      "order": {
      },
      "filters": [
        {
          "member": "Carts.state",
          "operator": "equals",
          "values": [
            "isolated"
          ]
        },
        {
          "and": [
            {
              "member": "Carts.dineInTableNumber",
              "operator": "notContains",
              "values": [
                "test"
              ]
            },
            {
            "member": "Carts.dineInTableNumber",
              "operator": "set"
            }
          ]
        },
        {
          "member": "Carts.commerceChannelCode",
          "operator": "equals",
          "values": [
            "dine_in_pos_hkd",
            "dine_in_hkd"
          ]
        }
      ],
      "dimensions": [
      ],
      "limit": 50000,
      "renewQuery": true,
    }
  },
  CartAuditTrail:{
    query: {
      "measures": [
      ],
      "timeDimensions": [
      ],
      "order": {
      },
      "filters": [
        {
          "and": [
            {
              "member": "CartAuditTrail.dineInTableNumber",
              "operator": "notContains",
              "values": [
                "test","測試"
              ]
            },
            {
            "member": "CartAuditTrail.dineInTableNumber",
              "operator": "set"
            }
          ]
        },
        {
          "member": "CartAuditTrail.cartCommerceChannelCode",
          "operator": "equals",
          "values": [
            "dine_in_pos_hkd",
            "dine_in_hkd"
          ]
        }
      ],
      "dimensions": [
        "CartAuditTrail.cartId",
        "CartAuditTrail.cartCommerceChannelCode",
        "CartAuditTrail.storeCode",
        "CartAuditTrail.orderId",
        "CartAuditTrail.orderReferenceNumber",
        "CartAuditTrail.orderState",
        "CartAuditTrail.orderUpdatedAt",
        "CartAuditTrail.dineInTableNumber",
        "CartAuditTrail.dineInNumberOfPeople",
        "CartAuditTrail.dineInTableSubNumber",
        "CartAuditTrail.staffFirstName",
        "CartAuditTrail.staffLastName",
        "CartAuditTrail.staffNumber",
        "CartAuditTrail.totalPrice",
        "CartAuditTrail.cartUpdatedAt",
        "CartAuditTrail.cartCreatedAt",
        "CartAuditTrail.cartClosedAt",
      ],
      "limit": 50000,
      "renewQuery": true,
    }
  }
}

export default OutstandingTableQueries