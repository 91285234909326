import React, { useState } from "react";
import Header from '../../components/Header';
import TableComponent from '../../components/TableComponent';
import SelectComponent from '../../components/SelectComponent';
import Dashboard from '../../components/Dashboard';
import PageHeaderComponent from '../../components/PageHeaderComponent';
import OmnitechQueries from '../../queries/OmnitechQueries'
import { useTranslation } from 'react-i18next';
import { useCubeQuery } from '@cubejs-client/react';
import { format } from 'date-fns'

const applyFilter = (query, dateFilter, showRSMNullFilter, showRSMRNullFilter, showPropertyAcNullFilter) => {
  if(showRSMNullFilter === 'yes'){
    let showRSMNullFilterObj = {
      member: "CartsDiscountChecking.rsmCode",
      operator: "notSet"
    }
    query = {
      ...query,
      filters: (query.filters || []).concat(showRSMNullFilterObj)
    }
  }
  else if(showRSMNullFilter === 'no'){
    let showRSMNullFilterObj = {
      member: "CartsDiscountChecking.rsmCode",
      operator: "set"
    }
    query = {
      ...query,
      filters: (query.filters || []).concat(showRSMNullFilterObj)
    }
  }

  if(showRSMRNullFilter === 'yes'){
    let showRSMRNullFilterObj = {
      member: "CartsDiscountChecking.rsmrCode",
      operator: "notSet"
    }
    query = {
      ...query,
      filters: (query.filters || []).concat(showRSMRNullFilterObj)
    }
  }
  else if(showRSMRNullFilter === 'no'){
    let showRSMRNullFilterObj = {
      member: "CartsDiscountChecking.rsmrCode",
      operator: "set"
    }
    query = {
      ...query,
      filters: (query.filters || []).concat(showRSMRNullFilterObj)
    }
  }

  if(showPropertyAcNullFilter === 'yes'){
    let showPropertyAcNullFilterObj = {
      member: "CartsDiscountChecking.propertyAc",
      operator: "notSet"
    }
    query = {
      ...query,
      filters: (query.filters || []).concat(showPropertyAcNullFilterObj)
    }
  }
  else if(showPropertyAcNullFilter === 'no'){
    let showPropertyAcNullFilterObj = {
      member: "CartsDiscountChecking.propertyAc",
      operator: "set"
    }
    query = {
      ...query,
      filters: (query.filters || []).concat(showPropertyAcNullFilterObj)
    }
  }

  let startAtObj = {
    "member": "CartsDiscountChecking.startAt",
    "operator": "lte",
    "values": [dateFilter]
  }
  query = {
    ...query,
    filters: (query.filters || []).concat(startAtObj)
  }

  let endAtObj = {
    "member": "CartsDiscountChecking.endAt",
    "operator": "gte",
    "values": [dateFilter]
  }
  query = {
    ...query,
    filters: (query.filters || []).concat(endAtObj)
  }
  return query
}

const CartDiscountCheckingQuery = (isRefresh, setRefresh, dateFilter, showRSMNullFilter, showRSMRNullFilter, showPropertyAcNullFilter) => {
  const { t } = useTranslation();

  let item = {
    name: t('page.fnb.cartDiscountCheckingPage.name'),
    tableHeaders: {
      "CartsDiscountChecking.code": { name: t('page.fnb.cartDiscountCheckingPage.tableHeader.code'), type: 'string'},
      "CartsDiscountChecking.rsmCode": { name: t('page.fnb.cartDiscountCheckingPage.tableHeader.rsmCode'), type: 'string'},
      "CartsDiscountChecking.rsmrCode": { name: t('page.fnb.cartDiscountCheckingPage.tableHeader.rsmrCode'), type: 'string'},
      "CartsDiscountChecking.propertyAc": { name: t('page.fnb.cartDiscountCheckingPage.tableHeader.propertyAc'), type: 'string'},
      "CartsDiscountChecking.startAt": { name:t('page.fnb.cartDiscountCheckingPage.tableHeader.startAt'), type: 'date'},
      "CartsDiscountChecking.endAt": { name: t('page.fnb.cartDiscountCheckingPage.tableHeader.endAt'), type: 'date'},
    },
    grid: {xs: 12, lg: 12},
    export: { allowExport: true, filename: 'cart_discount_export_' + format(new Date(), 'yyyyMMddHHmmss') }
  }
  let query = {
    ...OmnitechQueries.cartDiscounts.CartDiscountChecking.query,
  }
  query = applyFilter(query, dateFilter, showRSMNullFilter, showRSMRNullFilter, showPropertyAcNullFilter)
  if(isRefresh)
    query = {}
  const result = useCubeQuery(query, {resetResultSetOnChange: true})
  if(isRefresh)
    setRefresh(false)
  return { item, result }
}

const CartDiscountCheckingPage = () => {
  const { t } = useTranslation();
  let filterStartDate = new Date()
  const [dateFilter, ] = useState(filterStartDate)

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  const [ refresh1, setRefresh1 ] = useState(false);

  const [showRSMNullFilter, setShowRSMNullFilter] = useState('ALL');
  const showRSMNullSelect = ['ALL','yes','no']
  const [showRSMRNullFilter, setShowRSMRNullFilter] = useState('ALL');
  const showRSMRNullSelect = ['ALL','yes','no']
  const [showPropertyAcNullFilter, setShowPropertyAcNullFilter] = useState('ALL');
  const showPropertyAcNullSelect = ['ALL','yes','no']

  const { item, result }  = CartDiscountCheckingQuery(refresh1, setRefresh1, dateFilter, showRSMNullFilter, showRSMRNullFilter, showPropertyAcNullFilter)

  const refresh = () => {
    setRefresh1(true)
    setPage(0)
  }

  return ([
    <Header />,
    <PageHeaderComponent onClickFunc={refresh} refreshlabel={t('component.refresh')} headerTitle={t('component.header.menu.cartDiscountChecking')} />,
    <Dashboard>
      <SelectComponent id={"show_rsm_null_filter"} state={showRSMNullFilter} pageChangerArr={[setPage]}
          stateChanger={setShowRSMNullFilter} textLabel={t('page.fnb.cartDiscountCheckingPage.filter.showRSMNull')} selectArray={showRSMNullSelect}/>
       <SelectComponent id={"show_rsmr_null_filter"} state={showRSMRNullFilter} pageChangerArr={[setPage]}
          stateChanger={setShowRSMRNullFilter} textLabel={t('page.fnb.cartDiscountCheckingPage.filter.showRSMRNull')} selectArray={showRSMRNullSelect}/>
      <SelectComponent id={"show_property_ac_null_filter"} state={showPropertyAcNullFilter} pageChangerArr={[setPage]}
          stateChanger={setShowPropertyAcNullFilter} textLabel={t('page.fnb.cartDiscountCheckingPage.filter.showPropertyAc')} selectArray={showPropertyAcNullSelect}/>
    </Dashboard>,
    <Dashboard>
      <TableComponent item={item} result={result} rowsPerPage={rowsPerPage} 
        setRowsPerPage={setRowsPerPage} page={page} setPage={setPage}/>
    </Dashboard>
  ]) 
};

export default CartDiscountCheckingPage;