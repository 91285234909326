import React from "react";

import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/styles';
import AdapterDateFns from '@material-ui/lab/AdapterDateFns';
import LocalizationProvider from '@material-ui/lab/LocalizationProvider';
import DatePicker from '@material-ui/lab/DatePicker';
import {addHours, addMinutes, addSeconds, addMilliseconds} from 'date-fns';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginTop: '20',
    marginLeft: '30',
    marginRight: '30',
    width: 200,
  },

}));

const ModifyDate = (stateChanger, date) => {
  let newDate = [date, date]
  newDate[1] = addHours(newDate[1], 23)
  newDate[1] = addMinutes(newDate[1], 59)
  newDate[1] = addSeconds(newDate[1], 59)
  newDate[1] = addMilliseconds(newDate[1], 999)
  stateChanger(newDate)
}


let DatePickerComponent = ({state, stateChanger, dateLabel, pageChangerArr, ...rest}) => {
  const classes = useStyles();
  const startDate = state[0]

  return(
    <form style={{marginLeft: "24px"}} className={classes.container} noValidate>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DatePicker
          label={dateLabel}
          value={startDate}
          onChange={(date) => {
            if(pageChangerArr){
              for(let i = 0; i < pageChangerArr.length; i++){
                pageChangerArr[i](0)
              }
            }
            ModifyDate(stateChanger, date)
          }}
          renderInput={(params) => <TextField {...params} />}
        />
      </LocalizationProvider>
    </form>
  )
}

export default DatePickerComponent;